/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-check
import React, { Fragment as F } from 'react';
import json from 'c/ItemsModalContent/items.json';
import { addItemToScene } from 'c/Modal/ItemsModal';
import Storage from 'scr/utilitiesStorage';
import style from './index.styl';
import { getObjectForDrag } from './getObjectForDrag';

/**
 * @typedef {{
 *  name: string;
 *  image: import('@npmrost/utils').img;
 *  chooseCabinet: Tabs['chooseCabinet'];
 *  id: number;
 *  chosenCabinet: State['chosenCabinet']
 * }} VariantProps
 */
/** @augments { React.PureComponent< VariantProps > } */

class Variant extends React.PureComponent {
  onDragStart = ( /** @type { React.DragEvent<HTMLDivElement> } */e ) => {
    e.preventDefault();
    const viewer = Storage.get( 'viewer' );
    const id = /** @type { keyof typeof json.hash } */( String( this.props.id ) );


    const item = getObjectForDrag(
      /** @type { import('./getObjectForDrag')._IItemFromJson } */(
        /** @type { unknown } */ ( json.hash[ id ] )
      )
    );
    if ( !item._config ) {
      item._config = { id };
    }

    viewer.scene.dragDropManager.startDragFromUI(item);
  }

  render() {
    const {
      // img, name, chosen: c, chooseVariant, comp
      name,
      image,
      id,
      chosenCabinet,
      chooseCabinet

    } = this.props;
    const className = `variant ${ chosenCabinet === String( id ) ? 'chosen' : '' }`;

    return (
      <div
        className={ className }
        onDragStart={ this.onDragStart }
        onDoubleClick={ chooseCabinet }
        data-id={ id }
      >
        <img src={ `/${ image }` } alt='' />
        <p title={ name }>{name}</p>
      </div>
    );
  }
}

/**
 * @typedef {{
 *  uiName: Props['tabs'][0]['name'],
 *  chosenTab: State['chosenTab'];
 *  onHeadingClick: Tabs['chooseTab'];
 *  cabinets: Props['tabs'][0]['ids'];
 *  chosenCabinet: State['chosenCabinet'];
 *  chooseCabinet: Tabs['chooseCabinet'];
 * }} TabProps
 */
/** @augments { React.PureComponent<TabProps> } */
class Tab extends React.PureComponent {
  render() {
    const {
        uiName,
        chosenTab,
        onHeadingClick,
        cabinets,
        chosenCabinet,
        chooseCabinet
      } = this.props,
      cl = chosenTab === uiName ? 'chosen' : '';

    return (
      <div className={ `${ style.tab } ${ cl }` }>
        <div className='heading' onClick={ onHeadingClick } data-name={ uiName }>
          <span>{uiName}</span>
        </div>
        {chosenTab === uiName && (
          <div className='variants'>
            {cabinets.map( ( i ) => (
              <F key={ json.hash[ i ].name }>
                <Variant
                  { ...{
                    ...json.hash[ i ],
                    chosenCabinet,
                    chooseCabinet,
                    image: /** @type { import('@npmrost/utils').img } */( json.hash[ i ].image )
                  } }
                />
              </F>
            ) )}
            {
              cabinets.length % 2
                ? <div className='variant disabled' style={ { opacity: 0 } } />
                : null
            }
          </div>
        )}
      </div>
    );
  }
}
/**
 * @typedef {{
 *  tabs: Array<{
 *    name: 'Upper' | 'Base' | 'Tall' | 'Appliances' | 'Corner Cabinets',
 *    ids: Array<keyof typeof json.hash>;
 *  }>,
 *  hidden: boolean;
 * }} Props
 */
/**
 * @typedef {{
 *  chosenTab: Props['tabs'][0]['name'] | '';
 *  chosenCabinet: keyof typeof json.hash | null;
 * }} State
 */

/** @augments { React.PureComponent<Props, State> } */
export default class Tabs extends React.PureComponent {
  /** @type { Partial<Tabs['props']> } */
  static defaultProps = {
    tabs: [
      {
        name: 'Upper',
        ids:
        /** @type { Props['tabs'][0]['ids'] } */ ( /** @type { unknown } */ ( json
          .placement.upperCabinets ) )
      },
      {
        name: 'Base',
        ids:
        /** @type { Props['tabs'][0]['ids'] } */ ( /** @type { unknown } */ ( json
          .placement.baseCabinets ) )
      },
      {
        name: 'Tall',
        ids:
        /** @type { Props['tabs'][0]['ids'] } */ ( /** @type { unknown } */ ( json
          .placement.tallCabinets ) )
      },
      {
        name: 'Corner Cabinets',
        ids:
        /** @type { Props['tabs'][0]['ids'] } */ ( /** @type { unknown } */ ( json
          .placement.cornerCabinets ) )
      },
      {
        name: 'Appliances',
        ids:
        /** @type { Props['tabs'][0]['ids'] } */ ( /** @type { unknown } */ ( json
          .placement.appliances ) )
      }
    ]
  };

  constructor( /** @type { Tabs['props'] } */ p ) {
    super( p );

    /** @type { State } */
    this.state = {
      chosenTab: p.tabs[ 0 ].name,
      chosenCabinet: null
    };
  }

  chooseTab = (
    /** @type { React.MouseEvent<HTMLDivElement> & { currentTarget: { dataset: { name: Tabs['props']['tabs'][0]['name'] } } } } */
    {
      currentTarget: {
        dataset: { name: chosenTab }
      }
    }
  ) => {
    this.setState( ( prevState ) => ( {
      chosenTab: prevState.chosenTab === chosenTab ? '' : chosenTab
    } ) );
  };

  chooseCabinet = (
    /** @type { React.MouseEvent<HTMLDivElement> & { currentTarget: { dataset: { id: keyof typeof json.hash } } } } */
    {
      currentTarget: {
        dataset: { id: chosenCabinet }
      }
    }
  ) => {
    this.setState( { chosenCabinet } );
    // @ts-ignore
    addItemToScene( json.hash[ chosenCabinet ] );
  };

  render() {
    const {
      props: { tabs, hidden },
      state: { chosenTab, chosenCabinet },
      chooseTab,
      chooseCabinet
    } = this;

    return (
      <div className={ `${ style.tabs }${ hidden ? ' hidden' : '' }` }>
        <div className='fullHeight'>
          {tabs.map( ( tab ) => (
            <F key={ tab.name }>
              <Tab
                { ...{
                  uiName: tab.name,
                  cabinets: tab.ids,
                  chosenTab,
                  chosenCabinet,
                  onHeadingClick: chooseTab,
                  chooseCabinet
                } }
              />
            </F>
          ) )}
        </div>
      </div>
    );
  }
}
