import { getTopLevelObject } from 'c/ThreeJsWrap/Viewer/core/helpers/object3d';
import { GenericCabinet } from '../../core/GenericCabinet';
import { UpperFiller } from '../UpperFiller';
import { UpperFinishPanel } from '../UpperFinishPanel';
import { getCabinetFromConfig } from '../../core/cabinetTypes';
import { Vector3, Matrix4, Shape, Math as TMath } from 'three';
import Storage from 'scr/utilitiesStorage';
import CrownMolding from
  'c/Cabinet/cabinetdesigner/src/structured/singletonCabinet/core/CrownMolding';

export class WallCabinet extends GenericCabinet {
  constructor() {
    super();
    this.setType('upper');

    this.crownMolding = new CrownMolding(this,
      Storage.get('namesToShapes').crownMoldings[Storage.get('crownMoldings').defaultShape],
      false,
      false
    );
    this.crownMolding.rotation.set(0, -Math.PI / 2, 0);

    if (Storage.get('crownMoldings').isPresented) {
      this.addCrownMolding()
    }

  }

  isCrownMoldingPresent() {
    if (this.crownMolding.parent) {
      return true;
    }

    return false;
  }

  removeCrownMolding() {

    if (this.crownMolding.parent) {
      this.crownMolding.parent.remove(this.crownMolding);

    }
  }

  addCrownMolding() {
    if (!this.crownMolding.parent) {
      this.add(this.crownMolding);
    }
  }

  changeDepth(d: number) {
    super.changeDepth(d);
    this.origin.position.z = d / 2;
    this.crownMolding.changeDepth();
    if (this.crownMolding) { this.crownMolding.position.set(this.getSizes().width / 2, this.getSizes().height / 2, this.getSizes().depth - 0.5) }
  }

  changeHeight(h: number) {
    super.changeHeight(h);
    this.origin.position.y = -h / 2;
    if (this.crownMolding) { this.crownMolding.position.set(this.getSizes().width / 2, this.getSizes().height / 2, this.getSizes().depth - 0.5) }

  }

  changeWidth(w: number) {
    super.changeWidth(w);
    if (this.crownMolding) {
      this.crownMolding.changeWidth();
      this.crownMolding.position.set(this.getSizes().width / 2, this.getSizes().height / 2, this.getSizes().depth - 0.5)
    }
  }

  initVestaObject() {
    super.initVestaObject();

    this.vestaObject.getDistanceToWallEnds = (
      toPointHori: 'left' | 'center' | 'right',
      toPointVert: 'bottom' | 'center' | 'top') => {
      if (!this.parent ||
        ['left', 'center', 'right'].indexOf(toPointHori) === -1 ||
        ['bottom', 'center', 'top'].indexOf(toPointVert) === -1) {
        return null;
      }

      const result = {

      };

      const topLevel = getTopLevelObject(this.parent);

      let leftCorrectionMergedWalls2D = 0;
      let rightCorrectionMergedWalls2D = 0;
      if (topLevel.relatedWalls2D) {
        const index = topLevel.relatedWalls2D.indexOf(topLevel);
        for (let i = 0; i < index; i += 1) {
          leftCorrectionMergedWalls2D += topLevel.relatedWalls2D[i].width;
        }
        for (let i = index + 1, l = topLevel.relatedWalls2D.length; i < l; i += 1) {
          rightCorrectionMergedWalls2D += topLevel.relatedWalls2D[i].width;
        }
      }
      if (toPointHori === 'center') {
        result.minusX = this.position.x + leftCorrectionMergedWalls2D;
        result.plusX = topLevel.width - this.position.x + rightCorrectionMergedWalls2D;
      }
      if (toPointHori === 'left') {
        result.minusX = this.position.x - this.getSizes().width / 2 + leftCorrectionMergedWalls2D;
        result.plusX = topLevel.width - this.position.x + this.getSizes().width / 2 + rightCorrectionMergedWalls2D;
      }
      if (toPointHori === 'right') {
        result.minusX = this.position.x + this.getSizes().width / 2 + leftCorrectionMergedWalls2D;
        result.plusX = topLevel.width - this.position.x - this.getSizes().width / 2 + rightCorrectionMergedWalls2D;
      }

      if (toPointVert === 'center') {
        result.minusY = this.position.y;
        result.plusY = topLevel.height - this.position.y;
      }
      if (toPointVert === 'bottom') {
        result.minusY = this.position.y - this.getSizes().height / 2;
        result.plusY = topLevel.height - this.position.y + this.getSizes().height / 2;
      }
      if (toPointVert === 'top') {
        result.minusY = this.position.y + this.getSizes().height / 2;
        result.plusY = topLevel.height - this.position.y - this.getSizes().height / 2;
      }

      return result;
    };

    this.vestaObject.setDistanceToWallEnd = (
      side: 'left' | 'right' | 'bottom' | 'top',
      toPoint: 'center' | 'left' | 'right' | 'bottom',
      distance: number
    ) => {
      const topLevel = getTopLevelObject(this.parent);
      if ((['left', 'right', 'bottom', 'top'].indexOf(side) === -1) ||
        distance < 0 ||
        !this.parent) {
        return;
      }
      if (side === 'left') {
        if (toPoint === 'center') {
          this.position.x = distance;
        }
        if (toPoint === 'left') {
          this.position.x = distance + this.getSizes().width / 2;
        }
        if (toPoint === 'right') {
          this.position.x = distance - this.getSizes().width / 2;
        }
      }

      if (side === 'bottom') {
        if (toPoint === 'center') {
          this.position.y = distance;
        }
        if (toPoint === 'bottom') {
          this.position.y = distance + this.getSizes().height / 2;
        }
        if (toPoint === 'top') {
          this.position.y = distance - this.getSizes().height / 2;
        }

      }

      if (side === 'top') {
        if (toPoint === 'center') {
          this.position.y = topLevel.height - distance;
        }
        if (toPoint === 'bottom') {
          this.position.y = topLevel.height - distance + this.getSizes().height / 2;
        }
        if (toPoint === 'top') {
          this.position.y = topLevel.height - distance - this.getSizes().height / 2;
        }
      }

      if (side === 'right') {
        if (toPoint === 'center') {
          this.position.x = topLevel.width - distance;
        }
        if (toPoint === 'right') {
          this.position.x = topLevel.width - distance - this.getSizes().width / 2;
        }
        if (toPoint === 'left') {
          this.position.x = topLevel.width - distance + this.getSizes().width / 2;
        }
      }
    };
  }

  _canMoveVertically = null;

  setCanMoveVertically(v: boolean) {
    this._canMoveVertically = v;
  }

  getCanMoveVertically() {
    return this._canMoveVertically;
  }

  addFiller(
    /** @type { 'left' | 'right' } */side,
    /** @type { import('@npmrost/utils').inches } */width
  ) {
    let filler = getCabinetFromConfig({ config: UpperFiller });
    filler.vestaObject.changeWidth(width);
    filler.vestaObject.changeDepth(this.getSizes().depth);
    filler.vestaObject.changeHeight(this.getSizes().height);


    if (side === 'left') {
      filler.position.copy(
        this.position.clone()
          .add(
            new Vector3(-this.getSizes().width / 2 - width / 2, 0, 0)
              .applyMatrix4(new Matrix4()
                .makeRotationFromEuler(this.rotation)
              )
          )
      );
    }
    if (side === 'right') {
      filler.position.copy(
        this.position.clone()
          .add(
            new Vector3(this.getSizes().width / 2 + width / 2, 0, 0)
              .applyMatrix4(new Matrix4()
                .makeRotationFromEuler(this.rotation)
              )
          )
      );
    }

    filler.rotation.copy(this.rotation);
    this.parent.add(filler);
  }

  addFillerEnd(
    /** @type { 'left' | 'right' } */side,
    /** @type { import('@npmrost/utils').inches } */width
  ) {
    let finishPanel = getCabinetFromConfig({ config: UpperFinishPanel });
    finishPanel.vestaObject.changeWidth(width);
    finishPanel.vestaObject.changeDepth(this.getSizes().depth);
    finishPanel.vestaObject.changeHeight(this.getSizes().height);

    if (side === 'left') {
      finishPanel.position.copy(
        this.position.clone()
          .add(
            new Vector3(-this.getSizes().width / 2 - width / 2, 0, 0)
              .applyMatrix4(new Matrix4()
                .makeRotationFromEuler(this.rotation)
              )
          )
      );
    }
    if (side === 'right') {
      finishPanel.position.copy(
        this.position.clone()
          .add(
            new Vector3(this.getSizes().width / 2 + width / 2, 0, 0)
              .applyMatrix4(new Matrix4()
                .makeRotationFromEuler(this.rotation)
              )
          )
      );
    }

    finishPanel.rotation.copy(this.rotation);
    this.parent.add(finishPanel);
  }
}
