import React, { PureComponent } from 'react';
import { RadioSelect } from 'c/CalculationLayer/utils/RadioSelect';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Storage from 'scr/utilitiesStorage';
import {
  WallCabinet
} from 'cSrc/structured/singletonCabinet/cabinets/helpers/WallCabinet';

interface _IStateProps {
  side: redux._IStore['selectedCabinet']['crownMolding']['side'];
  present: redux._IStore['selectedCabinet']['crownMolding']['present'];
}

interface _IDispatchProps {
  dispatch: Dispatch<
    redux._IActions['SET_SELECTED_CABINET_CROWN_MOLDING_PRESENT'] |
    redux._IActions['SET_SELECTED_CABINET_CROWN_MOLDING_SIDE']
  >;
}

type PresenceVal = 'on' | 'off';

interface _IVariant<V> {
  label: string;
  value: V;
  chosen: boolean;
}
export const CrownMolding = connect<
  _IStateProps, _IDispatchProps, {}, redux._IStore
>(
  ( s ) => ( {
    side: s.selectedCabinet.crownMolding.side,
    present: s.selectedCabinet.crownMolding.present
  } )
)(
  class CrownMolding extends PureComponent< _IStateProps & _IDispatchProps > {
    constructor( p: CrownMolding['props'] ) {
      super( p );

      const wallCabinet = this.getSelected();
      this.props.dispatch( {
        type: 'SET_SELECTED_CABINET_CROWN_MOLDING_PRESENT',
        value: wallCabinet.isCrownMoldingPresent()
      } );
    }

    getSelected() {
      return Storage.get( 'selectedObject' )[ 0 ] as WallCabinet;
    }

    choosePresence = ( v: _IVariant< PresenceVal >['value'] ) => {
      this.props.dispatch( {
        type: 'SET_SELECTED_CABINET_CROWN_MOLDING_PRESENT',
        value: v === 'on'
      } );

      const wallCabinet = this.getSelected();
      if( v === 'on' ) wallCabinet.addCrownMolding();
      else wallCabinet.removeCrownMolding();

      Storage.get( 'viewer' ).renderOnDemand.set();
    };

    render() {
      const {
        props: {
          present
        },
        choosePresence
      } = this;

      const variantsForPresence: _IVariant< PresenceVal >[] = [
        { label: 'On', value: 'on', chosen: present },
        { label: 'Off', value: 'off', chosen: !present }
      ];

      return (
        <div>
          <RadioSelect< _IVariant<PresenceVal> > { ...{
            variants: variantsForPresence,
            chooseVariant: choosePresence
          } }
          />
        </div>
      );
    }
  }
);
