import React, { PureComponent } from "react";
import { IconButton } from "c/shared/icon-button";
import Viewport from "./viewer/Viewport";

import MyStyle from './index.styl';
import './main.css';
import './drawing-toolbar/drawing-toolbar.css';
import DrawingToolbar from './drawing-toolbar/drawing-toolbar';

import { BaseCmd } from "./command/BaseCmd";
import LineCmd from "./command/LineCmd";
import EraseCmd from "./command/EraseCmd";
import Arc1Cmd from "./command/Arc1Cmd";
import Arc2Cmd from "./command/Arc2Cmd";
import Arc3Cmd from "./command/Arc3Cmd";
import CubicBezierCmd from "./command/CubicBezierCmd";
import QuadraticBezierCmd from "./command/QuadraticBezierCmd";
import RectCmd from "./command/RectCmd";

import { createObjectFromConfig } from 'c/ThreeJsWrap/Viewer/core/cabinetdesigner/helpers';

class ShapeEditor extends PureComponent {
    editorRef = React.createRef();
    entityMgr;

    onClose = () => {
        const {
            itemJson
            ,shapeNode
            ,onSubmitShape
        } = this.props;

        if (onSubmitShape) {
            let result = itemJson;
            const shape = this.entityMgr.toJson();

            if (shape) {
                // result = {...itemJson}; // lodash.cloneDeep(itemJson);
                const wrapper = wrapperShape(result, shapeNode);
                if (wrapper) wrapper['shape'] = shape;
            }

            onSubmitShape(result);
        }
    }

    componentDidMount() {
        const {
            itemJson
            ,shapeNode
        } = this.props;

        const editorRef = this.editorRef.current;

        const viewport = new Viewport();
        this.entityMgr = viewport.entityMgr;
        editorRef.appendChild(viewport.dom);
        viewport.onWindowResize();

        // initialize entities from itemJson & shapeNode.
        if (shapeNode) {
            const wrapper = wrapperShape(itemJson, shapeNode);
            if (wrapper) {
                const shapeItem = getShapeItem(itemJson, shapeNode);
                this.entityMgr.fromJson(wrapper, shapeItem, {camera: viewport.camera, controls: viewport.controls});
            }
        }

        // drawing toolbar
        DrawingToolbar({
            data: [
                {id: 'eraser', icon: "drawing-erase.svg"},
                {id: 'rectangle', icon: "drawing-rect.svg"},
                {id: 'polyline', icon: "drawing-line.svg"}, // ,onclick: method
                {id: 0, icon: "drawing-arc.svg", submenu: [
                    {id: 'arc', icon: "drawing-arc.svg"},
                    {id: 'arc2p', icon: "drawing-arc2.svg"},
                    {id: 'arc3p', icon: "drawing-arc3.svg"},
                ]},
                {id: 0, icon: "drawing-bezier3.svg", submenu: [
                    {id: '2-Bezier', icon: "drawing-bezier2.svg"},
                    {id: '3-Bezier', icon: "drawing-bezier3.svg"},
                ]},
            ],
            onclick: (id) => {
                switch (id) {
                    case 'eraser': {
                        viewport.cmdMgr.activateCmd(new EraseCmd);
                        break;
                    }
                    case 'rectangle': {
                        viewport.cmdMgr.activateCmd(new RectCmd);
                        break;
                    }
                    case 'polyline': {
                        viewport.cmdMgr.activateCmd(new LineCmd);
                        break;
                    }
                    case 'arc': {
                        viewport.cmdMgr.activateCmd(new Arc1Cmd);
                        break;
                    }
                    case 'arc2p': {
                        viewport.cmdMgr.activateCmd(new Arc2Cmd);
                        break;
                    }
                    case 'arc3p': {
                        viewport.cmdMgr.activateCmd(new Arc3Cmd);
                        break;
                    }
                    case '2-Bezier': {
                        viewport.cmdMgr.activateCmd(new QuadraticBezierCmd);
                        break;
                    }
                    case '3-Bezier': {
                        viewport.cmdMgr.activateCmd(new CubicBezierCmd);
                        break;
                    }
                    default: {
                        viewport.cmdMgr.activateCmd(new BaseCmd); // empty cmd
                        break;
                    }
                }
                viewport.dom.focus();
            }
        });
        viewport.dom.focus();
    }
    componentWillUnmount() {
    }
    componentDidUpdate() {
    }

    render() {
        return (
            <div ref={this.editorRef} className={MyStyle.modalReact}>
                <IconButton title='Close' variant='close' className={MyStyle.close} onClick={this.onClose} />
                <div id='drawingbar' className='drawing-toolbar'></div>
            </div>
        );
    }
}

function getShapeItem(itemJson, shapeNode) {
    const cabinet = createObjectFromConfig(itemJson);
    const path = shapeNode.path;

    let container = cabinet;
    let component = null;
    for (let i = 0; i < path.length; i++) {
        const field = path[i];
        if (field==='children') {
            container = container.components;
        } else if (Number.isInteger(field)) {
            container = container[field];
        } else if (field==='shape') {
            component = container;
            break;
        } else {
            console.error('invalid path: %s, %s', path, field);
        }
    }

    return component;
}

function wrapperShape(itemJson, shapeNode) {
    const path = shapeNode.path;
    if (!path) return null;

    let node = itemJson;
    let wrapper = null;
    for (let i = 0; i < path.length; i++) {
        const field = path[i];
        if (field==='shape') {
            wrapper = node;
            break;
        }
        node = node[field];
    }

    return wrapper;
}

export default ShapeEditor;