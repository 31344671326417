
import {
  Group,
  Box3,
  Vector3,
  Matrix4,
  Euler,
} from 'three';
import Storage from 'scr/utilitiesStorage';
import { getFromGroup, getFromModel } from './helpers';
import { getTopLevelObject } from '../helpers/object3d';


export default class WallAppliance extends Group {
  constructor( source, dimensions, meta ) {
    super();

    if (meta.name === 'Window') {
      this.vestaObject.setType('item/window');
    } else {
      this.vestaObject.setType('item');
    }

    this.userData.source = source;
    this.userData.rotation = new Euler( 0, 0, 0 );
    this.userData.dimensions = { width: dimensions.width, height: dimensions.height, depth: dimensions.depth };
    this.userData.center = { x: 0, y: 0, z: 0.5 };
    this.userData.addBox = true;
    this.mountTypes = ['wall'];
    this.initialRotation = 0;// rotation.x;
    this.isTopLevel = true;
    this.isWallAppliance = true;
    this.isAppliance = true;
    this.init();
  }

  getSizes() {
    if ( this.userData.bBox ) {
      return {
        width: this.scale.x * ( this.userData.bBox.max.x - this.userData.bBox.min.x ),
        height: this.scale.y * ( this.userData.bBox.max.y - this.userData.bBox.min.y ),
        depth: this.scale.z * ( this.userData.bBox.max.z - this.userData.bBox.min.z )

      };
    }

    return {
      width: 1,
      depth: 1,
      height: 1
    };
  }

  getHoleShape() {
    // Return hole-shape in BCS.
    const width = this.userData.bBox.max.x - this.userData.bBox.min.x;
    const height = this.userData.bBox.max.y - this.userData.bBox.min.y;
    const depth = this.userData.bBox.max.z - this.userData.bBox.min.z;
    // Use Vector3 with z: 0(automatically) for further processing to make hole in Wall3D.
    let hole = [
      new Vector3(-width/2, height/2),
      new Vector3(-width/2, -height/2),
      new Vector3(width/2, -height/2),
      new Vector3(width/2, height/2),
    ];
    return hole;
  }

  _canMoveVertically = null;

  setCanMoveVertically( v ) {
    this._canMoveVertically = v;
  }

  getCanMoveVertically() {
    return this._canMoveVertically;
  }

  init() {

    if ( this.userData.source.isGroup ) {
      this.rotation.copy( this.userData.rotation );
      getFromGroup(
        this.userData.source,
        this.userData.rotation,
        this.userData.center,
        this.userData.addBox )
        .then( ( obj ) => {

          obj.rotation.copy( new Euler( 0, 0, 0 ) );

          this.add( obj );
          this.initParameters();

          obj.traverse( ( o ) => {
            if ( o.isMesh ) {
              o.castShadow = true;
              o.receiveShadow = true;
            }
          } );

          this.vestaObject.changeWidth( this.userData.dimensions.width );
          this.vestaObject.changeDepth( this.userData.dimensions.depth );
          this.vestaObject.changeHeight( this.userData.dimensions.height );

          Storage.get( 'viewer' ).renderOnDemand.set();
        } );
    } else {
      this.rotation.copy( this.userData.rotation );
      getFromModel(
        this.userData.source,
        this.userData.rotation,
        this.userData.center,
        this.userData.addBox )
        .then( ( obj ) => {

          obj.rotation.copy( new Euler( 0, 0, 0 ) );

          this.add( obj );

          this.initParameters();

          obj.traverse( ( o ) => {
            if ( o.isMesh ) {
              o.castShadow = true;
              o.receiveShadow = true;
            }
          } );

          this.vestaObject.changeWidth( this.userData.dimensions.width );
          this.vestaObject.changeDepth( this.userData.dimensions.depth );
          this.vestaObject.changeHeight( this.userData.dimensions.height );
          
          if ( this.userData.matrix ) {
            new Matrix4().fromArray( this.userData.matrix ).decompose( this.position, this.quaternion, new Vector3() );
          }

          Storage.get( 'viewer' ).renderOnDemand.set();
        } );
    }
  }

  initParameters() {
    this.isDragable = true;
    const parent = this.parent;
    this.parent = null;
    this.userData.bBox = new Box3().setFromObject( this );
    this.parent = parent;

    this.vestaObject.getSizes = () => {
      return {
        width: this.scale.x * ( this.userData.bBox.max.x - this.userData.bBox.min.x ),
        height: this.scale.y * ( this.userData.bBox.max.y - this.userData.bBox.min.y ),
        depth: this.scale.z * ( this.userData.bBox.max.z - this.userData.bBox.min.z )
      };
    };


    this.vestaObject.changeWidth = ( width ) => {
      if ( width > 0 ) {
        this.scale.x = width / ( this.userData.bBox.max.x - this.userData.bBox.min.x );
      }

    };
    this.vestaObject.changeHeight = ( height ) => {
      if ( height > 0 ) {
        this.scale.y = height / ( this.userData.bBox.max.y - this.userData.bBox.min.y );
      }

    };
    this.vestaObject.changeDepth = ( depth ) => {
      if ( depth > 0 ) {
        this.scale.z = depth / ( this.userData.bBox.max.z - this.userData.bBox.min.z );
      }
    };

    this.vestaObject.getDistanceToWallEnds = (
      toPointHori, // : 'left' | 'center' | 'right',
      toPointVert ) => { // : 'bottom' | 'center' | 'top') => {
      if ( !this.parent ||
        ['left', 'center', 'right'].indexOf( toPointHori ) === -1 ||
        ['bottom', 'center', 'top'].indexOf( toPointVert ) === -1 ) {
        return null;
      }

      const result = {

      };

      const topLevel = getTopLevelObject( this.parent );

      let leftCorrectionMergedWalls2D = 0;
      let rightCorrectionMergedWalls2D = 0;
      if ( topLevel.relatedWalls2D ) {
        const index = topLevel.relatedWalls2D.indexOf( topLevel );
        for ( let i = 0; i < index; i += 1 ) {
          leftCorrectionMergedWalls2D += topLevel.relatedWalls2D[ i ].width;
        }

        for ( let i = index + 1, l = topLevel.relatedWalls2D.length; i < l; i += 1 ) {
          rightCorrectionMergedWalls2D += topLevel.relatedWalls2D[ i ].width;
        }
      }
      if ( toPointHori === 'center' ) {
        result.minusX = this.position.x + leftCorrectionMergedWalls2D;
        result.plusX = topLevel.width - this.position.x + rightCorrectionMergedWalls2D;
      }
      if ( toPointHori === 'left' ) {
        result.minusX = this.position.x - this.getSizes().width / 2 + leftCorrectionMergedWalls2D;
        result.plusX = topLevel.width - this.position.x + this.getSizes().width / 2 + rightCorrectionMergedWalls2D;
      }
      if ( toPointHori === 'right' ) {
        result.minusX = this.position.x + this.getSizes().width / 2 + leftCorrectionMergedWalls2D;
        result.plusX = topLevel.width - this.position.x - this.getSizes().width / 2 + rightCorrectionMergedWalls2D;
      }

      if ( toPointVert === 'center' ) {
        result.minusY = this.position.y;
        result.plusY = topLevel.height - this.position.y;
      }
      if ( toPointVert === 'bottom' ) {
        result.minusY = this.position.y - this.getSizes().height / 2;
        result.plusY = topLevel.height - this.position.y + this.getSizes().height / 2;
      }
      if ( toPointVert === 'top' ) {
        result.minusY = this.position.y + this.getSizes().height / 2;
        result.plusY = topLevel.height - this.position.y - this.getSizes().height / 2;
      }

      return result;
    };

    this.vestaObject.setDistanceToWallEnd = (
      side, // : 'left' | 'right' | 'bottom' | 'top',
      toPoint, // : 'center' | 'left' | 'right' | 'bottom',
      distance// : number
    ) => {
      const topLevel = getTopLevelObject( this.parent );
      if ( ( ['left', 'right', 'bottom', 'top'].indexOf( side ) === -1 ) ||
        distance < 0 ||
        !this.parent ) {
        return;
      }
      if ( side === 'left' ) {
        if ( toPoint === 'center' ) {
          this.position.x = distance;
        }
        if ( toPoint === 'left' ) {
          this.position.x = distance + this.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          this.position.x = distance - this.getSizes().width / 2;
        }
      }

      if ( side === 'bottom' ) {
        if ( toPoint === 'center' ) {
          this.position.y = distance;
        }
        if ( toPoint === 'bottom' ) {
          this.position.y = distance + this.getSizes().height / 2;
        }
        if ( toPoint === 'top' ) {
          this.position.y = distance - this.getSizes().height / 2;
        }

      }

      if ( side === 'top' ) {
        if ( toPoint === 'center' ) {
          this.position.y = topLevel.height - distance;
        }
        if ( toPoint === 'bottom' ) {
          this.position.y = topLevel.height - distance + this.getSizes().height / 2;
        }
        if ( toPoint === 'top' ) {
          this.position.y = topLevel.height - distance - this.getSizes().height / 2;
        }
      }

      if ( side === 'right' ) {
        if ( toPoint === 'center' ) {
          this.position.x = topLevel.width - distance;
        }
        if ( toPoint === 'right' ) {
          this.position.x = topLevel.width - distance - this.getSizes().width / 2;
        }
        if ( toPoint === 'left' ) {
          this.position.x = topLevel.width - distance + this.getSizes().width / 2;
        }
      }
    };


    this.getType = () => {
      return 'wallAppliance';
    };

  }
}
