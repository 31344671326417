import { simpleSet } from '@r/reducers/utils';
import {
  getTreeViewStructure, groupData, getTreeViewDefaultValue
} from '@r/reducers/storeParts/calculationMode/utils';

export const materialsSets = simpleSet(
  'SET_CALCULATION_MODE_ADMIN_SETTINGS_MATERIALS_SETS',
  {
    data: [],
    counter: 0,
    keys: [],
    coreOptions: [],
    doorsDrawersOptions: [],
    edgebandingOptions: []
  }
);

export function setDefaultMaterialsSets() {
  return ( dispatch, getStore ) => {
    const { calculationMode: { materialsFromDb } } = getStore();

    const coreOptions = getTreeViewStructure(
      groupData( materialsFromDb.Core, ['type', 'company'] ),
      'name'
    );
    const coreValue = {
      ...getTreeViewDefaultValue( coreOptions )
    };

    const doorsDrawersOptions = getTreeViewStructure(
      groupData( materialsFromDb[ 'Doors/Drawers' ], ['type', 'company'] ),
      'name'
    );
    const doorsDrawersValue = {
      ...getTreeViewDefaultValue( doorsDrawersOptions )
    };

    const edgebandingOptions = getTreeViewStructure(
      groupData( materialsFromDb.Edgebanding, ['type', 'company'] ),
      'name'
    );
    const edgebandingValue = {
      ...getTreeViewDefaultValue( edgebandingOptions )
    };

    dispatch( {
      type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_MATERIALS_SETS',
      value: {
        data: [{
          core: coreValue,
          doorsDrawers: doorsDrawersValue,
          doorGrainDirection: 'vertical',
          drawerGrainDirection: 'vertical',
          edgebanding: edgebandingValue,
          coreSearchText: '',
          doorsDrawersSearchText: '',
          edgebandingSearchText: ''
        }],
        counter: 1,
        keys: [1],
        coreOptions,
        doorsDrawersOptions,
        edgebandingOptions
      }
    } );
  };
}
