/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import React, { Fragment as F, PureComponent } from 'react';
import ThemeButton from 'c/shared/ThemeButton';
import Storage from 'scr/utilitiesStorage';
import { connect } from 'react-redux';
import { d } from '@r/storeUtils';
import { Materials } from './CabinetMenuWidgets/Materials';
import General from './CabinetMenuWidgets/General';
import Json from './CabinetMenuWidgets/Json';
import style from './index.styl';
import Position from './CabinetMenuWidgets/Position';

interface _ITabProps {
  chosen: boolean;
  uiName: string;
  name: 'general' | 'position' | 'materials' | 'json';
  chooseTab: ( e: chooseTabArg ) => void;
}


class Tab extends PureComponent<_ITabProps> {
  render() {
    const {
        chosen: c, uiName, name, chooseTab
      } = this.props,
      className = `tab${ c ? ' chosen' : '' }`;

    return (
      <div className={ className } onClick={ chooseTab } data-name={ name }>
        <div className='img' />
        <span>{ c ? uiName : '' }</span>
      </div>
    );
  }
}

interface _IProps {
  dispatch: d<
    redux._IActions['setRightSideCabinetmenuMode']
  >;
  hidden: boolean;
  tabs: Array<{
    uiName: string;
    name: _ITabProps['name'];
    img: string;
    comp: React.ComponentClass;
  }>;
}

type chooseTabArg = React.MouseEvent<HTMLDivElement> & {
  currentTarget: { dataset: { name: _ITabProps['name'] } };
};

export default connect(
)(
  class Tabs extends React.PureComponent<
    _IProps,
    { tab: _IProps['tabs'][0]['name'] }
  > {
    static defaultProps: Pick<_IProps, 'tabs'> = {
      tabs: [
        {
          uiName: 'General',
          name: 'general',
          img: '/img/info/general.svg',
          comp: General
        },
        {
          uiName: 'Position',
          name: 'position',
          img: '/img/info/position.svg',
          comp: Position
        },
        {
          uiName: 'Materials',
          name: 'materials',
          img: '/img/info/materials.svg',
          comp: Materials
        },
        {
          uiName: 'Json',
          name: 'json',
          img: '/img/info/json.svg',
          comp: Json
        }
      ]
    }

    constructor( p: Tabs['props'] ) {
      super( p );

      this.state = {
        tab: p.tabs[ 0 ].name
      };
    }

    chooseTab = (
      { currentTarget: { dataset: { name: tab } } }:
      chooseTabArg
    ): void => void ( this.state.tab !== tab && this.setState( { tab } ) );

    deleteCabinet = () => {
      const r = confirm( 'Delete this item?' );
      if( !r ) return false;

      const [cabinet] = Storage.get( 'selectedObject' );
      if( cabinet === null ) {
        throw new Error( 'Selected cabinet is null.' );
      }
      Storage.set( 'selectedObject', [null] );

      const viewer = Storage.get('viewer');
      viewer.toDoRemoveObject(cabinet);

      const { dispatch } = this.props;
      dispatch( {
        type: 'SET_RIGHT_SIDE_CABINET_MENU_MODE',
        value: 'addCabinet'
      } );
      return false;
    }

    render() {
      const {
        props: { tabs, hidden },
        state: { tab },
        chooseTab, deleteCabinet
      } = this;

      const [
        { comp: General },
        { comp: Position },
        { comp: Materials },
        { comp: Json }
      ] = tabs;


      return (
        <div
          style={
            hidden ? { display: 'none' } : {}
          }
          className={ style.cabinetMenu }
        >
          <div className='controls'>
            <ThemeButton onClick={ deleteCabinet }>
              Delete
            </ThemeButton>
          </div>
          <div className='tabs'>
            {
              tabs.map( ( it ) => (
                <F key={ it.name }>
                  <Tab { ...{
                    chooseTab,
                    chosen: tab === it.name,
                    ...it
                  } }
                  />
                </F>
              ) )
            }
          </div>
          <div className='content'>
            { tab === 'general' && !hidden && <General /> }
            { tab === 'position' && <Position /> }
            <div style={ tab === 'materials' ? {} : { display: 'none' } }>
              <Materials />
            </div>
            { tab === 'json' && <Json /> }
          </div>
        </div>
      );
    }
  }
);
