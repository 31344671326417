import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  LabeledReactSelectMulti
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledReactSelectMulti';
import { changeValue } from '../../../utils';

class Accessories extends PureComponent {
  render() {

    const {
      props: {
        dispatch,
        dataKey,
        accessoriesText,
        data,
        accessories,
        subGroup
      }
    } = this;

    const accessoriesOptions = accessories[ subGroup ];

    if ( !accessoriesOptions || !accessoriesOptions.length ) {
      return ( <></> );
    }

    return (
      <LabeledReactSelectMulti
        name={ accessoriesText }
        options={ accessoriesOptions }
        chosen={ data }
        choose={ changeValue( dispatch, dataKey, 'accessories', 'multiSelect' ) }
      />
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        estimationPreview: {
          table: {
            materials: {
              accessories: accessoriesText
            }
          }
        }
      }
    },
    calculationMode: {
      materialsFromDb: {
        Accessories: accessories
      }
    }
  } ) => {
    return {
      accessoriesText,
      accessories
    };
  }
)( Accessories );
