import React from 'react';
import Select, { components } from 'react-select';
import style from './index.styl';

function CustomOption( props ) {
  const { optionClass, data: { baseMap: img, label } } = props;

  return (
    <div className={ `${ style.option } ${ optionClass || '' }` }>
      <img src={ img } alt='' />
      <span>{ label }</span>
    </div>
  );
}

const Option = ( optionClass ) => ( props ) => (
  <components.Option { ...props }>
    <CustomOption optionClass={ optionClass } { ...props } />
  </components.Option>
);

const SingleValue = ( props ) => (
  <components.SingleValue { ...props }>
    <CustomOption { ...props } />
  </components.SingleValue>
);

export default class ImageInput extends React.PureComponent {
  render() {
    const {
      styles, value, options, onChange, optionClass
    } = this.props;

    return (
      <Select
        components={ { Option: Option( optionClass ), SingleValue } }
        { ...{
          styles, value, options, onChange
        } }
      />
    );
  }
}
