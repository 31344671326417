import { simpleSet } from '@r/reducers/utils';

export const materialsSets = simpleSet(
  'SET_CALCULATION_MODE_CURRENT_PROJECT_MATERIALS_SETS',
  {
    data: [],
    counter: 0,
    keys: [],
    coreOptions: [],
    doorsDrawersOptions: [],
    edgebandingOptions: []
  }
);
