import {
  updateCabinet
} from '@r/reducers/storeParts/calculationMode/projects/currentProject/cabinets';

export const changeValue = ( dispatch, dataKey, propName, type, options ) => ( value ) => {
  let newValue = value;

  switch ( type ) {
    case 'unit':
    case 'percent':
      newValue = { label: value, value: Number( value ) };
      break;

    case 'number':
      newValue = Number( value );
      break;

    case 'multiSelect':
      if ( !value ) newValue = [];

      break;

    case 'setsSelect':
    case 'select':
      newValue = value[ 0 ].value;
      break;

    case 'selectWithOptions':
      newValue = { options, value };
      break;

    default:
      break;
  }

  dispatch( updateCabinet( dataKey, propName, newValue ) );
};

export const search = ( dispatch, dataKey, propName ) => ( searchText ) => {
  dispatch(
    updateCabinet( dataKey, propName, { treeViewSearchText: searchText } )
  );
};
