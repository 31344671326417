import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Header from './Header';
import Cabinets from './Cabinets';

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
  height: 70%;
  ${ ( { theme: t } ) => `
    border-bottom: 1px solid ${ t.darkGreyColor };
  ` }
  .row {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .column:not(:first-child) {
    margin-left: 10px;
  }
  .columnNumber {
    width: 3%;
  }
  .columnName {
    width: 22%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .columnSetOfMaterials {
    width: 25%;
  }
  .columnUnit {
    width: 6%;
  }
  .columnComment {
    width: 15%;
  }
  .columnCost {
    width: 8%;
    font-weight: 700;
  }
  .columnActions {
    width: 6%;
  }
`;

export default class Table extends PureComponent {
  render() {
    const { fullDepthIsShown } = this.props;

    return (
      <Wrapper>
        <Header fullDepthIsShown={ fullDepthIsShown } />
        <Cabinets { ...this.props } />
      </Wrapper>
    );
  }
}
