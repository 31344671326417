/* eslint-disable no-warning-comments */
/* eslint-disable line-comment-position */

import {
  // eslint-disable-next-line import/named
  getEdgebanding,
  getCountertop,
  getHinges,
  getPulls,
  getLegs,
  getToeKickFillers,
  getValance,
  getDrawerSystems
} from './parts';

import {
  getCabinetParts,
  getMaterialCost,
  getDrawers,
  calcCabinetCost,
  getAdditionalPartsCost
} from './middlewares';

export const pieCutCarcasses = [
  'Pie Cut Corner Base',
  'Open Pie Cut Corner Base',
  'Pie Cut Corner Upper'
];

const openCarcasses = [
  'Open Base',
  'Open Tall',
  'Open Upper'
];

export const calculateCost = ( {
  cabinets,
  materialsSets,
  fixLabourCost,
  doorLabourUnit,
  drawerLabourUnit,
  cabinetsLabourUnit
} ) => {

  const { data: cabinetsData, keys: cabinetsKeys } = cabinets;
  const { data: materialsSetsData, keys: materialsSetsKeys } = materialsSets;
  const lossPercent = 15;
  const grainLossPercent = 25;

  let totalMaterialsCost = 0;
  let totalLabourCost = 0;
  let totalDoorsDrawersCost = 0;
  let totalEdgebanding = [];
  let totalCountertop = [];
  let totalHinges = [];
  let totalPulls = [];
  let totalLegs = [];
  let totalToeKickFillers = [];
  let totalValance = [];
  let totalDrawerSystems = [];

  const cabinetsCostData = {};

  let totalMaterials = {};

  console.log( '------------------------' );
  console.log( 'CALC START' );
  console.log( '------------------------' );

  for ( let i = 0; i < cabinetsData.length; i++ ) {
    let {
      materialsSetKey,
      drawerHeight,
      doubleDrawerHeight,
      height: { value: height },
      leftFinEnd,
      rightFinEnd,
      carcasse,
      accessories,
      group,
      width: { value: width },
      depth: { value: depth },
      fullDepth: { value: fullDepth },
      countertop: { value: countertop },
      markup: { value: markup },
      countertopOverhangLeft: { value: countertopOverhangLeft },
      countertopOverhangRight: { value: countertopOverhangRight },
      countertopOverhangBack: { value: countertopOverhangBack },
      hingeQty,
      doorQty,
      hinges: { value: hinge },
      pullQty,
      pulls: { value: pull },
      legPackagesQty,
      legs: { value: leg },
      drawerSlideQty,
      drawerSystems: { value: drawerSystem },
      toeKickHeight: { value: toeKickHeight },
      toeKickType,
      leftSideFillerWidth: { value: leftSideFillerWidth },
      rightSideFillerWidth: { value: rightSideFillerWidth },
      valanceHeight: { value: valanceHeight },
      name
    } = cabinetsData[ i ];

    console.log( 'CABINET START' );
    console.log( 'Cabinet:', name );

    const materialsChosenSet =
      materialsSetsData[ materialsSetsKeys.indexOf( materialsSetKey ) ];

    const {
      cost: coreCost,
      name: coreName,
      thickness: coreThickness,
      length: coreLength,
      width: coreWidth,
      sheetCost: coreSheetCost
    } = getMaterialCost( {
      materialsChosenSet,
      field: openCarcasses.includes( carcasse ) ? 'doorsDrawers' : 'core',
      grainLossPercent,
      lossPercent
    } );

    const coreSheetArea = Number( ( coreLength * coreWidth / 144 ).toFixed( 2 ) );

    console.log( `Core material:
      name: ${ coreName }
      cost: ${ coreCost }
      thickness: ${ coreThickness }
      length: ${ coreLength }
      width: ${ coreWidth }
      sheetCost: ${ coreSheetCost }
    ` );

    const {
      cost: otherCost,
      name: otherName,
      thickness: otherThickness,
      length: otherLength,
      width: otherWidth,
      sheetCost: otherSheetCost
    } = getMaterialCost( {
      materialsChosenSet, field: 'doorsDrawers', grainLossPercent, lossPercent
    } );

    const otherSheetArea = Number( ( otherLength * otherWidth / 144 ).toFixed( 2 ) );

    console.log( `Doors / Drawers material:
      name: ${ otherName }
      cost: ${ otherCost }
      thickness: ${ otherThickness }
      length: ${ otherLength }
      width: ${ otherWidth }
      sheetCost: ${ otherSheetCost }
    ` );

    const drawers = getDrawers( {
      drawerHeight, doubleDrawerHeight, height, toeKickHeight
    } );

    console.log( `Drawers: ${ JSON.stringify( drawers ) }` );

    console.log( 'CABINET PARTS START' );

    const { core: coreParts, other: otherParts } = getCabinetParts( {
      ...cabinetsData[ i ],
      drawers,
      coreThickness,
      otherThickness
    } );

    console.log( 'CABINET PARTS END' );

    console.log( '%cCALC CABINET COST START', 'color: green;' );

    const {
      cost,
      doorsDrawersCost,
      totalMaterials: newTotalMaterials
    } = calcCabinetCost( {
      leftFinEnd,
      rightFinEnd,
      coreParts,
      coreCost,
      coreName,
      coreSheetArea,
      coreSheetCost,
      otherParts,
      otherCost,
      otherName,
      otherSheetArea,
      otherSheetCost,
      totalMaterials
    } );

    totalDoorsDrawersCost = Number(
      ( totalDoorsDrawersCost + 2 * doorsDrawersCost )
        .toFixed( 2 )
    );

    console.log( `%cCabinet cost (without labour and accessories): %c${ cost }`, 'color: purple;', 'color: blue;' );

    console.log( '%cCALC CABINET COST END', 'color: green;' );

    totalMaterials = { ...newTotalMaterials };

    const edgebanding = materialsChosenSet.edgebanding;

    console.log( 'EDGEBANDING START' );

    totalEdgebanding = getEdgebanding( {
      group,
      edgebanding,
      coreParts,
      otherParts,
      drawers,
      totalEdgebanding: totalEdgebanding.slice()
    } );

    console.log( 'EDGEBANDING END' );

    console.log( 'COUNTERTOP START' );

    totalCountertop = getCountertop( {
      group,
      carcasse,
      countertop,
      markup,
      width,
      height,
      depth,
      fullDepth,
      countertopOverhangLeft,
      countertopOverhangRight,
      countertopOverhangBack,
      pieCutCarcasses,
      totalCountertop: totalCountertop.slice()
    } );

    console.log( 'COUNTERTOP END' );

    console.log( 'HINGE START' );

    const { newTotalHinges, hingeLabourCost } = getHinges( {
      hingeQty,
      hinge,
      doorQty,
      totalHinges: totalHinges.slice(),
      fixLabourCost
    } );

    console.log( 'HINGE END' );

    totalHinges = newTotalHinges;
    totalLabourCost = Number(
      ( totalLabourCost + hingeLabourCost ).toFixed( 2 )
    );

    console.log( 'PULL START' );

    const { newTotalPulls, pullLabourCost } = getPulls( {
      pullQty,
      pull,
      totalPulls: totalPulls.slice(),
      fixLabourCost
    } );

    console.log( 'PULL END' );

    totalPulls = newTotalPulls;
    totalLabourCost = Number(
      ( totalLabourCost + pullLabourCost ).toFixed( 2 )
    );

    const { newTotalLegs, legLabourCost } = getLegs( {
      legPackagesQty,
      toeKickType,
      leg,
      totalLegs: totalLegs.slice(),
      fixLabourCost
    } );

    console.log( 'PULL END' );

    totalLegs = newTotalLegs;
    totalLabourCost = Number(
      ( totalLabourCost + legLabourCost ).toFixed( 2 )
    );

    console.log( 'TOE KICK FILLER START' );

    const {
      totalToeKickFillers: newTotalToeKickFillers,
      totalMaterials: newTotalMaterialsFromToeKickFillers
    } = getToeKickFillers( {
      group,
      width,
      height,
      toeKickHeight,
      leftSideFillerWidth,
      rightSideFillerWidth,
      materialCost: otherCost,
      materialName: otherName,
      materialSheetArea: otherSheetArea,
      materialSheetCost: otherSheetCost,
      totalMaterials,
      totalToeKickFillers: totalToeKickFillers.slice()
    } );

    totalToeKickFillers = newTotalToeKickFillers;
    totalMaterials = { ...newTotalMaterialsFromToeKickFillers };

    console.log( 'TOE KICK FILLER END' );

    console.log( 'VALANCE START' );

    const {
      totalValance: newTotalValance,
      totalMaterials: newTotalMaterialsFromValance
    } = getValance( {
      group,
      width,
      valanceHeight,
      materialCost: otherCost,
      materialName: otherName,
      materialSheetArea: otherSheetArea,
      materialSheetCost: otherSheetCost,
      totalMaterials,
      totalValance: totalValance.slice()
    } );

    totalValance = newTotalValance;
    totalMaterials = { ...newTotalMaterialsFromValance };

    console.log( 'VALANCE END' );

    console.log( 'DRAWER SYSTEM START' );

    const {
      newTotalDrawerSystems,
      drawerSystemLabourCost
    } = getDrawerSystems( {
      drawerSlideQty,
      drawerSystem,
      totalDrawerSystems: totalDrawerSystems.slice(),
      fixLabourCost
    } );

    console.log( 'DRAWER SYSTEM END' );

    totalDrawerSystems = newTotalDrawerSystems;
    totalLabourCost = Number(
      ( totalLabourCost + drawerSystemLabourCost ).toFixed( 2 )
    );

    const accessoriesCost = Number(
      accessories
        .reduce(
          ( acc, value ) => {
            console.log( `Accessory:
              name: ${ value.name }
              cost: ${ value.cost }
              labour unit: ${ value.labourUnit }
            ` );

            return acc + Number( value.cost );
          },
          0
        )
        .toFixed( 2 )
    );

    console.log( `Accessories cost: ${ accessoriesCost }` );

    const accessoriesLabourCost = Number(
      accessories
        .reduce(
          ( acc, value ) => acc + fixLabourCost * Number( value.labourUnit ),
          0
        )
        .toFixed( 2 )
    );

    console.log( `Accessories labour cost: ${ accessoriesLabourCost }` );

    const doorsLabourCost = Number(
      // ( doorQty * otherCost * doorLabourUnit ).toFixed( 2 )
      ( doorQty * fixLabourCost * doorLabourUnit ).toFixed( 2 )
    );

    console.log( `Doors quantity: ${ doorQty }` );
    console.log( `Doors fix labour cost: ${ fixLabourCost }` );
    console.log( `Doors labour unit: ${ doorLabourUnit }` );
    console.log( `Doors labour cost = quantity * fix labour cost * labour unit = ${ doorsLabourCost }` );

    const drawersLabourCost = Number(
      // ( drawers.length * otherCost * drawerLabourUnit ).toFixed( 2 )
      ( drawers.length * fixLabourCost * drawerLabourUnit ).toFixed( 2 )
    );

    console.log( `Drawers quantity: ${ drawers.length }` );
    console.log( `Drawers fix labour cost: ${ fixLabourCost }` );
    console.log( `Drawers labour unit: ${ drawerLabourUnit }` );
    console.log( `Drawers labour cost = quantity * fix labour cost * labour unit = ${ drawersLabourCost }` );

    const cabinetLabourCostNotDoubled = Number(
      (
        fixLabourCost * ( cabinetsLabourUnit[ carcasse ] || 0 ) +
        accessoriesLabourCost +
        doorsLabourCost +
        drawersLabourCost
      )
        .toFixed( 2 )
    );

    console.log( `Cabinet labour cost = fixLabourCost * cabinet labour unit + accessories labour cost + doors labour cost + drawers labour cost = ${ cabinetLabourCostNotDoubled }` );

    // doubled prices
    const cabinetLabourCost = 2 * cabinetLabourCostNotDoubled;

    totalLabourCost = Number(
      ( totalLabourCost + cabinetLabourCost ).toFixed( 2 )
    );

    const cabinetCostNotDoubled = Number( ( cost + accessoriesCost ).toFixed( 2 ) );

    console.log( `Cabinet cost (without labour) = cabinet cost (without labour and accessories) + accessories cost = ${ cabinetCostNotDoubled }` );

    // doubled prices
    const cabinetCost = 2 * cabinetCostNotDoubled;

    cabinetsCostData[ cabinetsKeys[ i ] ] = Number(
      ( cabinetCost + cabinetLabourCost ).toFixed( 2 )
    );

    console.log( 'CABINET END' );
  }

  console.log( 'LESS THAN ONE SHEET START' );

  const totalLessThanOneSheet = [];

  // eslint-disable-next-line no-restricted-syntax
  for ( let name in totalMaterials ) {
    const {
      area, cost, sheetArea, sheetCost
    } = totalMaterials[ name ];

    console.log( `Material:
      name: ${ name }
      area: ${ area }
      cost: ${ cost }
      sheet area: ${ sheetArea }
      sheet cost: ${ sheetCost }
    ` );

    let totalCost = area * cost;

    if ( area < sheetArea ) {
      console.log( 'Material area less than one sheet area' );

      totalCost = sheetCost;
      totalLessThanOneSheet.push( {
        name,
        area: Number( ( sheetArea - area ).toFixed( 2 ) ),
        cost: Number( ( 2 * ( sheetCost - area * cost ) ).toFixed( 2 ) )
      } );
    }

    console.log( `Total material cost: ${ totalCost }` );

    totalMaterialsCost = Number(
      (
        // doubled prices
        totalMaterialsCost + 2 * totalCost
      )
        .toFixed( 2 )
    );
  }

  console.log( 'LESS THAN ONE SHEET END' );

  console.log( 'ADDITIONAL PARTS START' );

  const {
    totalCost: additionalPartsCost,
    edgebandingCost,
    countertopCost,
    hingesCost,
    pullsCost,
    legsCost,
    toeKickFillersCost,
    valanceCost,
    drawerSystemsCost
  } = getAdditionalPartsCost( {
    totalEdgebanding,
    totalCountertop,
    totalHinges,
    totalPulls,
    totalLegs,
    totalToeKickFillers,
    totalValance,
    totalDrawerSystems,
    totalLessThanOneSheet
  } );

  console.log( 'ADDITIONAL PARTS END' );

  totalMaterialsCost = Number(
    ( totalMaterialsCost + additionalPartsCost ).toFixed( 2 )
  );

  console.log( '------------------------' );
  console.log( 'CALC END' );
  console.log( '------------------------' );

  return {
    totalMaterialsCost,
    totalLabourCost,
    totalDoorsDrawersCost,
    cabinetsCostData,
    totalEdgebanding,
    totalCountertop,
    totalHinges,
    totalPulls,
    totalLegs,
    totalToeKickFillers,
    totalValance,
    totalDrawerSystems,
    totalLessThanOneSheet,
    edgebandingCost,
    countertopCost,
    hingesCost,
    pullsCost,
    legsCost,
    toeKickFillersCost,
    valanceCost,
    drawerSystemsCost
  };
};
