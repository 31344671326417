import React, { PureComponent } from 'react';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import modalStyl from 'c/CalculationLayer/Modal/index.styl';
import TripletHolder from 'c/CalculationLayer/utils/TripletHolder';
import { UnitsInputv2 } from 'c/CalculationLayer/utils/UnitsInputv2';
import { connect } from 'react-redux';
import { IntInput, Props as IntProps } from 'c/CalculationLayer/utils/IntInput';
import { batchActions } from 'redux-batched-actions';
import { unitMapping } from 'scr/metricsConversion';
import { quantity } from 'decl/general/units';
import { UnitsInputv2Value } from 'decl/general/html';
import { baseOf } from 'decl/general/core';
import { d, batchActionsWithTempl } from '@r/storeUtils';

type base = redux._IStore['projectFor3d']['cabinetsSettings']['base'];
type a = redux._IActions;

type labelActionTypes = a['setCabinetsSettingsBaseDepthLabel']['type'] |
  a['setCabinetsSettingsBaseHeightLabel']['type'];

type valueActionTypes = a['setCabinetsSettingsBaseDepthValue']['type'] |
a['setCabinetsSettingsBaseHeightValue']['type']

type batchAction = batchActionsWithTempl<
  a['setCabinetsSettingsBaseDepthLabel'] |
  a['setCabinetsSettingsBaseDepthValue'] |
  a['setCabinetsSettingsBaseHeightLabel'] |
  a['setCabinetsSettingsBaseHeightValue'] |
  a['setCabinetsSettingsBaseShelvesQty']
>;

interface _IProps {
  units: redux._IStore['projectFor3d']['units'];
  height: base['height'];
  depth: base['depth'];
  shelvesQty: base['shelvesQty'];
  dispatch: d<ReturnType<batchAction>>;
}

export const Base = connect(
  ( {
    projectFor3d: {
      units,
      cabinetsSettings: { base }
    }
  }: redux._IStore ): Omit<_IProps, 'dispatch'> => ( {
    units,
    height: base.height,
    depth: base.depth,
    shelvesQty: base.shelvesQty
  } )
)(
  class Base extends PureComponent<_IProps> {
    changeMetric = (
      labelAction: labelActionTypes,
      valueAction: valueActionTypes
    ) => ( value: UnitsInputv2Value ) => {
      const { units, dispatch } = this.props;

      const val = units === 'ftAndInch'
        ? unitMapping.ftAndInch( value )
        : unitMapping[ units ]( Number( value ) );

      dispatch(
        ( batchActions as batchAction )( [
          { type: labelAction, value },
          { type: valueAction, value: val }
        ] )
      );
    }

    changeQty = ( n: number ) => {
      this.props.dispatch(
        ( batchActions as batchAction )( [
          {
            type: 'SET_CABINETS_SETTINGS_BASE_SHELVES_QTY',
            value: n as quantity
          }
        ] )
      );
    }

    render() {
      const {
        props: {
          units,
          height: { label: hLabel },
          shelvesQty,
          depth: { label: dLabel }
        },
        changeMetric,
        changeQty
      } = this;

      return (
        <LabeledField
          className={
            `${ modalStyl.commonLabeledField } wider` +
          ' shortLabel label100px fontSize14px'
          }
          name='Base cabinet'
        >
          <TripletHolder>
            <UnitsInputv2
              units={ units }
              value={ hLabel }
              onChange={ changeMetric(
                'SET_CABINETS_SETTINGS_BASE_HEIGHT_LABEL',
                'SET_CABINETS_SETTINGS_BASE_HEIGHT_VALUE'
              ) }
              className={ modalStyl.commonInput }
            />
            <UnitsInputv2
              units={ units }
              value={ dLabel }
              onChange={ changeMetric(
                'SET_CABINETS_SETTINGS_BASE_DEPTH_LABEL',
                'SET_CABINETS_SETTINGS_BASE_DEPTH_VALUE'
              ) }
              className={ modalStyl.commonInput }
            />
            <IntInput
              value={
                shelvesQty as
                  baseOf<_IProps['shelvesQty']> as
                    IntProps['value']
              }
              onChange={ changeQty }
              className={ modalStyl.commonInput }
            />
          </TripletHolder>
        </LabeledField>
      );
    }
  }
);
