/* eslint-disable no-restricted-syntax */
// import groupBy from 'lodash/groupBy';
import { simpleSet } from '@r/reducers/utils';
import { getMaterials } from 'services/materials/materials.service';

export const materialsFromDb = simpleSet(
  'SET_CALCULATION_MODE_MATERIALS_FROM_DB',
  {}
);

function groupMaterials( materials, lang ) {
  let groupedMaterials = materials.map( ( item ) => {
    return {
      ...item,
      ...item[ lang ],
      label: item[ lang ].name,
      value: item[ lang ].name
    };
  } );

  const materialsCategories = [
    'Core',
    'Doors/Drawers',
    'Countertop',
    'Pull',
    'Hinge',
    'Leg',
    'Edgebanding',
    'Accessories',
    'Drawer system'
  ];

  const responseMaterials = {};

  // const responseMaterials = groupBy( groupedMaterials, 'categories' );

  for ( let i = 0; i < materialsCategories.length; i++ ) {
    const category = materialsCategories[ i ];
    responseMaterials[ category ] = groupedMaterials
      .filter( ( item ) => item.categories.includes( category ) );
  }

  return responseMaterials;
}

export const loadMaterialsFromDb = () => {
  return async ( dispatch, getStore ) => {
    const { language } = getStore();

    try {
      const data = await getMaterials( language );

      const materials = groupMaterials(
        data,
        language
      );

      const accessories = materials.Accessories.slice();

      const newAccessories = {};

      for ( let i = 0; i < accessories.length; i++ ) {
        const { cabinetSubGroups } = accessories[ i ];

        for ( let j = 0; j < cabinetSubGroups.length; j++ ) {
          const subGroup = cabinetSubGroups[ j ];

          if ( newAccessories[ subGroup ] ) {
            newAccessories[ subGroup ].push( { ...accessories[ i ] } );
          } else {
            newAccessories[ subGroup ] = [{ ...accessories[ i ] }];
          }
        }
      }

      materials.Accessories = newAccessories;

      dispatch( {
        type: 'SET_CALCULATION_MODE_MATERIALS_FROM_DB',
        value: materials
      } );

      Promise.resolve();
    } catch ( err ) {
      Promise.reject( err );
    }
  };
};
