// @ts-check
import React, { PureComponent } from 'react';
import style from './index.styl';

/**
 * @typedef {{
 *  chosen: boolean;
 *  overrides: Props<any>['overrides'];
 * }} CircleProps
 */
/** @augments { PureComponent<CircleProps>} */
class Circle extends PureComponent {
  render() {
    const { chosen, overrides: { icon } } = this.props;

    return (
      <div
        className={ `icon ${ chosen ? 'chosen' : undefined } ${ icon }` }
      />
    );
  }
}

/**
 * @typedef {{
 *  label: string;
 *  chosen: boolean;
 *  overrides: Props<any>['overrides'];
 * }} CircleWithLabelProps
 */
/** @augments { PureComponent<CircleWithLabelProps> } */
class CircleWithLabel extends PureComponent {
  render() {
    const {
      label,
      chosen,
      overrides
    } = this.props;

    return (
      <>
        <Circle { ... { chosen, overrides } } />
        { label }
      </>
    );
  }
}

/**
 * @typedef {{
 *   label: string;
 *   value: string;
 *   chosen: boolean;
 * }} commonVariant
 */

/**
 * @template { commonVariant } T
 * @typedef {{
 *  variants: T[],
 *  chooseVariant: (value: T['value']) => void;
 *  overrides: {
 *    holder?: import('@npmrost/utils').classNameDiv,
 *    variant?: import('@npmrost/utils').classNameDiv,
 *    icon?: import('@npmrost/utils').classNameDiv
 *  }
 * }} Props
 */
/**
 * @template { commonVariant } T
 * @augments { PureComponent< Props<T> > }
 */
export class RadioSelect extends PureComponent {
  /** @type {Partial< Props< any > >} */
  static defaultProps = {
    overrides: {
      icon: /** @type { import('@npmrost/utils').classNameDiv } */ ( '' ),
      holder: /** @type { import('@npmrost/utils').classNameDiv } */ ( '' ),
      variant: /** @type { import('@npmrost/utils').classNameDiv } */ ( '' )
    }
  }

  chooseVariant = (
    /**
     * @type { React.MouseEvent<HTMLDivElement> & {
     *    currentTarget: { dataset: { value: T['value'] } }
     *  }
     * }
     */ { currentTarget: { dataset: { value } } }
  ) => {
    this.props.chooseVariant( value );
  }

  render() {
    const {
      props: {
        variants,
        overrides,
        overrides: { holder, variant }
      },
      chooseVariant
    } = this;

    return (
      <div className={ `${ style.radioSelect } ${ holder }` }>
        {
          variants.map( ( { value, label, chosen } ) => (
            <div
              className={ `variant ${ variant }` }
              onClick={ chooseVariant }
              data-value={ value }
              key={ value }
            >
              <CircleWithLabel { ...{ chosen, label, overrides } } />
            </div>
          ) )
        }
      </div>
    );
  }
}
