import DoAction from "./DoAction";

export default class DoSetEntityMaterial extends DoAction {
    constructor(vestaObject, part, info) {
        super();

        this.vestaObject = vestaObject; // vestaObject
        this.part = part;
        this.materialBefore = info.materialBefore;
        this.materialAfter = info.materialAfter;
    }
	redo() {
        this.setMaterial(this.materialAfter);
	}
	undo() {
        this.setMaterial(this.materialBefore);
    }
    setMaterial(material) {
        this.vestaObject.setMaterialByName({[this.part]: material});
        this.viewer.renderOnDemand.set();
    }
}