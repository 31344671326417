import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Button from 'c/shared/ThemeButton';
import host from '@@/apiHost.json';

import {
  loadMaterialsFromDb
} from '@r/reducers/storeParts/calculationMode/materialsFromDb';
import {
  setDefaultAdminSettings
} from '@r/reducers/storeParts/calculationMode/adminSettings';
import {
  setDefaultSettings
} from '@r/reducers/storeParts/calculationMode/settings';
import { setCabinets } from '@r/reducers/storeParts/calculationMode/cabinets';

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

class ExcelControls extends PureComponent {

  download = () => {
    const { excelUrl } = this.props;

    window.open( `${ host.replace( /\/+$/, '' ) }${ excelUrl }` );
  }

  upload = () => {
    const { dispatch } = this.props;
    const materialExcel = document.querySelector( '#materialExcel' );
    const [file] = materialExcel.files;

    if ( !file ) {
      alert( 'Choose file you want to upload.' );

      return;
    }

    if ( !file.name.match( /\.xlsx$/i ) ) {
      alert( 'Choosen file should be a\the *.xlsx file.' );

      return;
    }

    const formData = new FormData();
    formData.append( 'file', file );
    const xhr = new XMLHttpRequest();
    xhr.onload = ( e ) => {
      if ( xhr.readyState === 4 ) {
        if ( xhr.status !== 200 ) {
          alert( xhr.statusText );

          return;
        }

        const { fileUrl } = JSON.parse( xhr.response );

        if ( !fileUrl ) {
          alert( 'No files.' );

          return;
        }

        dispatch( loadMaterialsFromDb() )
          .then( () => dispatch( setDefaultAdminSettings( true ) ) )
          .then( () => dispatch( setDefaultSettings() ) )
          .then( () => dispatch( setCabinets() ) )
          .catch( console.error );

        alert( 'File has been uploaded successfuly.' );
      }
    };
    xhr.open( 'POST', `${ host }loadMaterialExcel` );
    xhr.send( formData );
  }

  render() {
    const {
      props: {
        texts: {
          excelFile: excelFileText,
          download: downloadText,
          upload: uploadText
        }
      },
      download,
      upload
    } = this;

    return (
      <Wrapper>
        <div>{ excelFileText }</div>
        <Button
          inverse
          noShadow
          type={ null }
          text={ downloadText }
          onClick={ download }
        />
        <input type='file' id='materialExcel' />
        <Button
          noShadow
          text={ uploadText }
          onClick={ upload }
        />
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    calculationMode: {
      adminSettings: {
        excelUrl
      }
    },
    texts: {
      calculationMode: {
        adminMode: texts
      }
    }
  } ) => ( {
    excelUrl,
    texts
  } )
)( ExcelControls );
