import React, { PureComponent } from 'react';
import { Filler } from '../Filler';
import { FinishEnd } from '../FinishEnd';

export class Base extends PureComponent {
  render() {

    return (
      <>
        <Filler />
        <FinishEnd />
      </>
    );
  }
}
