/**
 * 
 * @param {object} c cabinet from which we start recursively 
 *  look up for kitchen
 * @returns {string} path to img texture e.g
 *  /img/cabinetTextures/K30_Spectra_w4918xh5129.jpg
 */
export function findKitchenTexture(c){
  let buf = c;

  while(!buf._isKitchen)
    buf = buf.parent;
 
  return buf.__texture;
}

export function findCabinets(obj, func){
  if(obj._isCabinet)
    return void func(obj);

  if(!obj.component) return;
  if(!Object.keys(obj.component).length) return;

  for(let key in obj.component)
    findCabinets(obj.component[ key ], func);
}


// array of cabinet`s names that should be 24in wide
const twoDoorNames = [
  "DoubleDoorUtilityCabinet",
  "OneDoubleDoorTall",
  "DoubleDoorWallCabinet",
  "TwoDoorBaseCabinet",
  "OneDrawerTwoDoorBaseCabinet",
  "TwoDrawersTwoDoorBaseCabinet"
];

/**
 * Gets required width for cabinet based on doors amount
 * @param {object|string} c constructor class of the cabinet or it`s name
 * @returns {number} width in cm of the cabinet based on 
 *  amount of doors (1 door - 18in, 2 doors - 24in)
 * 
 */
export function getWidthDependingOnDoors(c){
  const innerName = typeof c == "string" ? c  : c.name;
  if(innerName.match(new RegExp(twoDoorNames.join("|")))) 
    return 24 * 2.54;

  return 18 * 2.54;
}

/**
 * Sets width of the c according to the amount of doors
 * 
 * @param {object} c cabinet instance that needs width constraint set
 *  NOTE that c is BoxedCabinet and so we can\`t just take it\`s 
 *  constructor to understand how many doors this cabinet has
 * @param {function} constr constructor class of the c
 * 
 */
export function setWidthDependingOnDoors(c, constr){
  c.setWidthConstraint(getWidthDependingOnDoors(constr));
}

export function getCabinetPathArr(c, k){
  const cabIndex = c.parent.getComponentOrder().indexOf(c.name);

  if(c.name.match(/col/))
    return [ cabIndex ];

  // placement is "upperSpace" or "groundSpace"
  const placement = c.parent.name;
  const rowBasedIndex = k.getComponentOrder().indexOf(c.parent.parent.name)
  
  return [ rowBasedIndex, placement, cabIndex ];
}


export function reassignCubesAndHighlight(c){
  if(c._isCabinet){
    const wasHighlighted = c._cube.highlighted;
    c.addCube();
    if(wasHighlighted) c.setSelected();

    return;
  }

  for( let comp in c.component)
    reassignCubesAndHighlight(c.component[ comp ]);
}