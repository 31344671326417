// @ts-check
import {
  ExtrudeGeometry, Group, Mesh, Shape, Matrix4, Path
} from 'three';
import Storage from 'scr/utilitiesStorage';
import { getTopLevelObject } from 'c/ThreeJsWrap/Viewer/core/helpers/object3d';
import { createGeometry } from 'c/ThreeJsWrap/Viewer/core/helpers/geometry';
import { textureSize } from 'c/ThreeJsWrap/Viewer/core/helpers/constants';
import MountPoint from '../../../../../../ThreeJsWrap/Viewer/MountPoint';
// import ('three').then(({Sp})=>{})
const defaultCountertopHeight = 1;
export default class Countertop extends Group {
  constructor(
    cabinet,
    height = defaultCountertopHeight,
    depthDisplacementFront = 0,
    depthDisplacementBack = 0,
    depthDisplacementLeft = 0,
    depthDisplacementRight = 0
  ) {
    super();
    this.isCountertop = true;
    this.cabinet = cabinet;
    this.cabinet.add( this );
    this.depthDisplacementFront = depthDisplacementFront;
    this.depthDisplacementBack = depthDisplacementBack;
    this.depthDisplacementLeft = depthDisplacementLeft;
    this.depthDisplacementRight = depthDisplacementRight;
    this.width = this.cabinet.getSizes().width;
    this.height = height;
    this.depth = this.cabinet.getSizes().height + depthDisplacementBack + depthDisplacementFront;
    this.vestaObject.setMaterialByName = function setMaterialByName( mat ) {
      const viewer = Storage.get( 'viewer' );
      const newMaterial = Storage.get( 'namesToMaterials' ).countertop[
        mat.countertop
      ];

      if ( !newMaterial ) {
        return;
      }

      for ( let i = 0; i < Storage.get( 'materials' ).countertop.length; i += 1 ) {

        if ( Storage.get( 'materials' ).countertop[ i ].material.map ) {
          Storage.get( 'materials' ).countertop[ i ].material.map.dispose();
        }
        if ( Storage.get( 'materials' ).countertop[ i ].material.roughnessMap ) {
          Storage.get( 'materials' ).countertop[ i ].material.roughnessMap.dispose();
        }
        if ( Storage.get( 'materials' ).countertop[ i ].material.normalMap ) {
          Storage.get( 'materials' ).countertop[ i ].material.normalMap.dispose();
        }

        Storage.get( 'materials' ).countertop[ i ].material.dispose();

      }

      this.getParent().boxPart.material = newMaterial;
      viewer.renderOnDemand.set();

      if ( this.getParent().cabinet.updateMaterialInConfig ) {
        this.getParent().cabinet
          .updateMaterialInConfig( 'countertopMaterialName', mat.countertop );
      }
    };

    this.vestaObject.getMaterialName = function getMaterialName() {
      return this.getParent().cabinet.getConfig().countertopMaterialName;
    };

    let geometry;
    if ( this.cabinet.isCornerWithCountertop ) {
      const shape = new Shape();
      shape.moveTo(
        -this.cabinet.getSizes().width / 2,
        -this.cabinet.getSizes().height / 2
      );
      shape.lineTo(
        this.cabinet.getSizes().width / 2,
        -this.cabinet.getSizes().height / 2
      );
      shape.lineTo(
        this.cabinet.getSizes().width / 2,
        this.cabinet.getSizes().height / 2
      );
      shape.lineTo(
        -this.cabinet.getSizes().width / 2,
        this.cabinet.getSizes().height / 2
      );
      shape.lineTo(
        -this.cabinet.getSizes().width / 2,
        -this.cabinet.getSizes().height / 2
      );

      geometry = createGeometry(
        new ExtrudeGeometry( shape, {
          steps: 1,
          depth: this.height,
          bevelEnabled: false
        } ).rotateX( Math.PI / 2 )
      );
      this.position.set( 0, this.cabinet.getSizes().depth + this.height, 0 );
    } else {
      this.shape = new Shape()
        .moveTo( -this.width / 2, -this.depth / 2 )
        .lineTo( this.width / 2, -this.depth / 2 )
        .lineTo( this.width / 2, this.depth / 2 )
        .lineTo( -this.width / 2, this.depth / 2 )
        .lineTo( -this.width / 2, -this.depth / 2 );

      geometry = createGeometry(
        // new BoxGeometry( this.width, this.height, this.depth )
        new ExtrudeGeometry(
          this.shape,
          {
            steps: 1,
            depth: this.height,
            bevelEnabled: false
          }
        ).rotateX( -Math.PI / 2 )
      );
      this.position.set(
        0,
        this.cabinet.getSizes().depth + 0 * this.height / 2,
        -this.cabinet.getSizes().height / 2 +
        this.depth / 2 -
        this.depthDisplacementBack
      );

      this.mountPoint = new MountPoint( ['sink'] );
      if ( this.cabinet.getSizes().width >= 36 && this.cabinet.getSizes().height >= 24 ) {
        this.add( this.mountPoint );
      }

      this.mountPoint.addEventListener( 'childAdded', function ( event ) {
        if ( this.parent ) {
          this.parent.holes = [
            new Path()
              .moveTo( this.parent.depthDisplacementLeft / 2 - this.parent.depthDisplacementRight / 2 - ( event.data.getSizes().width - 1 ) / 2,
                this.parent.depthDisplacementFront / 2 - this.parent.depthDisplacementBack / 2 - ( event.data.getSizes().depth - 1 ) / 2 )
              .lineTo( this.parent.depthDisplacementLeft / 2 - this.parent.depthDisplacementRight / 2 + ( event.data.getSizes().width - 1 ) / 2,
                this.parent.depthDisplacementFront / 2 - this.parent.depthDisplacementBack / 2 - ( event.data.getSizes().depth - 1 ) / 2 )
              .lineTo( this.parent.depthDisplacementLeft / 2 - this.parent.depthDisplacementRight / 2 + ( event.data.getSizes().width - 1 ) / 2,
                this.parent.depthDisplacementFront / 2 - this.parent.depthDisplacementBack / 2 + ( event.data.getSizes().depth - 1 ) / 2 )
              .lineTo( this.parent.depthDisplacementLeft / 2 - this.parent.depthDisplacementRight / 2 - ( event.data.getSizes().width - 1 ) / 2,
                this.parent.depthDisplacementFront / 2 - this.parent.depthDisplacementBack / 2 + ( event.data.getSizes().depth - 1 ) / 2 )
              .lineTo( this.parent.depthDisplacementLeft / 2 - this.parent.depthDisplacementRight / 2 - ( event.data.getSizes().width - 1 ) / 2,
                this.parent.depthDisplacementFront / 2 - this.parent.depthDisplacementBack / 2 - ( event.data.getSizes().depth - 1 ) / 2 )
          ];
          this.parent.rebuildGeometry();
        }
      } );
      this.mountPoint.addEventListener( 'childRemoved', function ( event ) {
        if ( this.parent ) {
          this.parent.holes = [];
          this.parent.rebuildGeometry();
        }
      } );
    }

    this.boxPart = new Mesh(
      geometry,
      Storage.get( 'materials' ).countertop[ 3 ].material
    );

    this.boxPart.isCollidable = true;
    this.boxPart.isSnapable = true;
    this.boxPart.castShadow = true;
    this.boxPart.receiveShadow = true;
    this.add( this.boxPart );
    // this.figurePart = null;

    // this.ownGeometry = null;
    // this.sharedGeometry = null;

    this.vestaObject.setType( 'countertop' );


  }

  changeWidth() {
    this.width = this.cabinet.getSizes().width;
    this.rebuildGeometry();
  }

  changeDepth( depth ) {
    this.depth = this.cabinet.getSizes().height + this.depthDisplacementFront + this.depthDisplacementBack;
    this.rebuildGeometry();
  }

  setDepthOverhang( front, back ) {
    const oldDepthDisplacementFront = this.depthDisplacementFront;
    const oldDepthDisplacementBack = this.depthDisplacementBack;
    this.depthDisplacementFront = front;
    this.depthDisplacementBack = back;
    this.depth = this.cabinet.getSizes().height + front + back;
    this.correctHoles( 0, ( this.depthDisplacementFront - this.depthDisplacementBack - oldDepthDisplacementFront + oldDepthDisplacementBack ) / 2 );
    this.rebuildGeometry();
  }

  correctHoles( width, depth ) {
    if ( this.holes ) {
      for ( let i = 0; i < this.holes.length; i += 1 ) {
        for ( let j = 0; j < this.holes[ i ].curves.length; j += 1 ) {
          this.holes[ i ].curves[ j ].v1.x += width;
          this.holes[ i ].curves[ j ].v2.x += width;
          this.holes[ i ].curves[ j ].v1.y += depth;
          this.holes[ i ].curves[ j ].v2.y += depth;
        }
      }
    }
  }

  setWidthOverhang( left, right ) {
    const oldDepthDisplacementLeft = this.depthDisplacementLeft;
    const oldDepthDisplacementRight = this.depthDisplacementRight;
    this.depthDisplacementLeft = left;
    this.depthDisplacementRight = right;
    this.width = this.cabinet.getSizes().width + left + right;
    this.correctHoles( ( this.depthDisplacementLeft - this.depthDisplacementRight - oldDepthDisplacementLeft + oldDepthDisplacementRight ) / 2, 0 );
    this.rebuildGeometry();
  }

  getOverhang() {
    return {
      left: this.depthDisplacementLeft,
      right: this.depthDisplacementRight,
      top: this.depthDisplacementFront,
      bottom: this.depthDisplacementBack
    };
  }

  changeHeight( height ) {
    this.height = height;
    this.rebuildGeometry();
  }

  resize( height, depth ) {
    this.width = this.cabinet.getSizes().width;
    this.depth = this.cabinet.getSizes().height + this.depthDisplacementFront + this.depthDisplacementBack;
    this.height = height;
    this.rebuildGeometry();
  }

  rebuildGeometry() {
    this.depth = this.cabinet.getSizes().height + this.depthDisplacementBack + this.depthDisplacementFront;
    this.width = this.cabinet.getSizes().width + this.depthDisplacementLeft + this.depthDisplacementRight;

    this.boxPart.geometry.dispose();
    this.shape = new Shape()
      .moveTo( -this.width / 2, -this.depth / 2 )
      // .bezierCurveTo(this.width / 2, -this.depth / 2, this.width / 2, this.depth / 2, this.width / 2, this.depth / 2)
      // .bezierCurveTo(-this.width / 2, this.depth / 2, -this.width / 2, -this.depth / 2, -this.width / 2, -this.depth / 2)
      // .lineTo(-this.width / 2, -this.depth / 2);
      .lineTo( this.width / 2, -this.depth / 2 )
      .lineTo( this.width / 2, this.depth / 2 )
      .lineTo( -this.width / 2, this.depth / 2 )
      .lineTo( -this.width / 2, -this.depth / 2 );

    this.shape.holes = this.holes ? this.holes : [];

    this.position.set(
      -this.depthDisplacementLeft / 2 + this.depthDisplacementRight / 2,
      this.cabinet.getSizes().depth + 0 * this.height / 2,
      -this.cabinet.getSizes().height / 2 +
      this.depth / 2 -
      this.depthDisplacementBack
    );

    if ( this.cabinet.getSizes().width >= 36 && this.cabinet.getSizes().height >= 24 ) {
      this.add( this.mountPoint );
      this.mountPoint.position.set( this.depthDisplacementLeft / 2 - this.depthDisplacementRight / 2, this.height + 0.0001, this.depthDisplacementBack / 2 - this.depthDisplacementFront / 2 );
    } else {
      for ( let i = 1; i < this.mountPoint.children.length; i += 1 ) {
        this.mountPoint.remove( this.mountPoint.children[ i ] );
      }

      this.remove( this.mountPoint );
    }


    this.boxPart.geometry = createGeometry(
      // new BoxGeometry( this.width, this.height, this.depth )
      new ExtrudeGeometry(
        this.shape,
        {
          steps: 1,
          depth: this.height,
          bevelEnabled: false
        }
      ).rotateX( -Math.PI / 2 )
    );


    this.updateUVsOffset( this.baseCountertop || this );
    Storage.get( 'viewer' ).renderOnDemand.set();
  }

  updateUVsOffset( base ) {
    if ( !base ) {
      base = this;
    }

    const BP = this.boxPart;
    const g = BP.geometry;
    const v = g.vertices;
    const f = g.faces;
    const fvuvs = g.faceVertexUvs;

    const baseBP = base.boxPart;
    const bv = baseBP.geometry.vertices;
    let VP;
    for ( let i = 0; i < f.length; i += 1 ) {
      if ( Math.abs( f[ i ].normal.y ) > 0.999 ) {
        if ( base.isNewCountertop ) {
          VP = [
            v[ f[ i ].a ].clone()
              .applyMatrix4( BP.matrixWorld )
              .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
              // .sub( bv[ 4 ].clone() )
              .multiplyScalar( 1 / textureSize ),
            v[ f[ i ].b ].clone()
              .applyMatrix4( BP.matrixWorld )
              .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
              // .sub( bv[ 4 ].clone() )
              .multiplyScalar( 1 / textureSize ),
            v[ f[ i ].c ].clone()
              .applyMatrix4( BP.matrixWorld )
              .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
              // .sub( bv[ 4 ].clone() )
              .multiplyScalar( 1 / textureSize )
          ];

          fvuvs[ 0 ][ i ][ 0 ].set( VP[ 0 ].x, VP[ 0 ].z );
          fvuvs[ 0 ][ i ][ 1 ].set( VP[ 1 ].x, VP[ 1 ].z );
          fvuvs[ 0 ][ i ][ 2 ].set( VP[ 2 ].x, VP[ 2 ].z );
        } else {
          VP = [
            v[ f[ i ].a ].clone()
              .applyMatrix4( BP.matrixWorld )
              .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
              .sub( bv[ 4 ].clone() )
              .multiplyScalar( 1 / textureSize ),
            v[ f[ i ].b ].clone()
              .applyMatrix4( BP.matrixWorld )
              .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
              .sub( bv[ 4 ].clone() )
              .multiplyScalar( 1 / textureSize ),
            v[ f[ i ].c ].clone()
              .applyMatrix4( BP.matrixWorld )
              .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
              .sub( bv[ 4 ].clone() )
              .multiplyScalar( 1 / textureSize )
          ];

          fvuvs[ 0 ][ i ][ 0 ].set( VP[ 0 ].x, -VP[ 0 ].z );
          fvuvs[ 0 ][ i ][ 1 ].set( VP[ 1 ].x, -VP[ 1 ].z );
          fvuvs[ 0 ][ i ][ 2 ].set( VP[ 2 ].x, -VP[ 2 ].z );
        }
        /* let VP = [
          v[ f[ i ].a ].clone()
            .applyMatrix4( BP.matrixWorld )
            .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
            .sub( bv[ 4 ].clone() )
            .multiplyScalar( 1 / textureSize ),
          v[ f[ i ].b ].clone()
            .applyMatrix4( BP.matrixWorld )
            .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
            .sub( bv[ 4 ].clone() )
            .multiplyScalar( 1 / textureSize ),
          v[ f[ i ].c ].clone()
            .applyMatrix4( BP.matrixWorld )
            .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
            .sub( bv[ 4 ].clone() )
            .multiplyScalar( 1 / textureSize )
        ];

        fvuvs[ 0 ][ i ][ 0 ].set( VP[ 0 ].x, -VP[ 0 ].z );
        fvuvs[ 0 ][ i ][ 1 ].set( VP[ 1 ].x, -VP[ 1 ].z );
        fvuvs[ 0 ][ i ][ 2 ].set( VP[ 2 ].x, -VP[ 2 ].z ); */
      }
    }

    g.uvsNeedUpdate = true;
  }

  updateAllMergedUvsOffset( base ) {
    const mergedCountertops = this.getMergedCountertops();

    for ( let i = 0; i < mergedCountertops.length; i += 1 ) {
      mergedCountertops[ i ].baseCountertop = base;
      mergedCountertops[ i ].updateUVsOffset( base );
    }
  }

  getMergedCountertops( arr ) {
    let mergedCountertops = arr || [this];

    const snapping = this.cabinet.vestaObject.updateSnapping( 0.1 );

    if ( snapping ) {
      if ( snapping.minusX && snapping.minusX.object ) {
        let topLevelObject = getTopLevelObject( snapping.minusX.object );
        if ( topLevelObject.countertop ) {
          if ( mergedCountertops.indexOf( topLevelObject.countertop ) === -1 ) {
            mergedCountertops.push( topLevelObject.countertop );
            mergedCountertops = topLevelObject.countertop.getMergedCountertops( mergedCountertops );
          }
        }
      }
      if ( snapping.plusX && snapping.plusX.object ) {
        let topLevelObject = getTopLevelObject( snapping.plusX.object );
        if ( topLevelObject.countertop ) {
          if ( mergedCountertops.indexOf( topLevelObject.countertop ) === -1 ) {
            mergedCountertops.push( topLevelObject.countertop );
            mergedCountertops = topLevelObject.countertop.getMergedCountertops( mergedCountertops );
          }
        }
      }
      if ( snapping.minusY && snapping.minusY.object ) {
        let topLevelObject = getTopLevelObject( snapping.minusY.object );
        if ( topLevelObject.countertop ) {
          if ( mergedCountertops.indexOf( topLevelObject.countertop ) === -1 ) {
            mergedCountertops.push( topLevelObject.countertop );
            mergedCountertops = topLevelObject.countertop.getMergedCountertops( mergedCountertops );
          }
        }
      }
      if ( snapping.plusY && snapping.plusY.object ) {
        let topLevelObject = getTopLevelObject( snapping.plusY.object );
        if ( topLevelObject.countertop ) {
          if ( mergedCountertops.indexOf( topLevelObject.countertop ) === -1 ) {
            mergedCountertops.push( topLevelObject.countertop );
            mergedCountertops = topLevelObject.countertop.getMergedCountertops( mergedCountertops );
          }
        }
      }
    }

    return mergedCountertops;
  }
}
