import Storage from 'scr/utilitiesStorage';

export function updateUISelectedObject(newSelectedObject) {
  const viewer = Storage.get('viewer');
  const selectedObject = Storage.get('selectedObject');
  viewer.dispatch({
    type: 'objectselected',
    data: {
      object: newSelectedObject || selectedObject,
      mouse: {
        x: 0,
        y: 0
      }
    }
  });
}