export default ( {
  materialsChosenSet, field, grainLossPercent, lossPercent
} ) => {

  const {
    grain, costPerSqFt
  } = materialsChosenSet[ field ];

  const loss = grain ? grainLossPercent : lossPercent;

  const {
    thickness,
    length,
    width,
    sheetCost
  } = materialsChosenSet[ field ];

  return {
    ...materialsChosenSet[ field ],
    thickness: Number( thickness ),
    length: Number( length ),
    width: Number( width ),
    sheetCost: Number( sheetCost ),
    cost: Number( ( costPerSqFt / ( 1 - loss / 100 ) ).toFixed( 4 ) )
  };
};
