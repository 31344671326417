import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { visible } from './visible';
import { x } from './x';
import { y } from './y';

const object3dModalComb = combineReducers as
  combRed<redux._IStore['modals']['object3dModal'], AllActions>;

export const object3dModal = object3dModalComb( {
  visible,
  x,
  y
} );
