import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { assetsLoaded } from './storeParts/assetsLoaded';
import { user } from './storeParts/user';
import { modals } from './storeParts/modals';
import { initHappened } from './storeParts/initHappened';
import { projectFor3d } from './storeParts/projectFor3d';
import { sceneInitialized } from './storeParts/sceneInitialized';
import { flags } from './storeParts/flags';
import { floorPlanTools } from './storeParts/floorPlanTools';
import { calculationMode } from './storeParts/calculationMode';
import { texts } from './storeParts/texts';
import { selectedCabinet } from './storeParts/selectedCabinet';
import { language } from './storeParts/language';
import { selectedFloorPlanObject } from './storeParts/selectedFloorPlanObject';
import { projectsFor3d } from './projectsFor3d';

const rootComb = combineReducers as combRed< redux._IStore, AllActions >;

export const rootReducer = rootComb( {
  assetsLoaded,
  modals,
  user,
  initHappened,
  selectedFloorPlanObject,
  projectFor3d,
  sceneInitialized,
  flags,
  floorPlanTools,
  calculationMode,
  texts,
  selectedCabinet,
  language,
  projectsFor3d
} );
