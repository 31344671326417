/* eslint-disable no-console */
const calcCountertopCost = ( {
  carcasse,
  countertop: {
    name, code, thickness, costPerSqFt, usedMarkup, labourUnit, lossFactor
  },
  markup,
  width,
  depth: innerDepth,
  fullDepth,
  countertopOverhangLeft,
  countertopOverhangRight,
  countertopOverhangBack,
  pieCutCarcasses
} ) => {

  console.log( `Countertop:
    name: ${ name }
    code: ${ code }
    thickness: ${ thickness }
    cost per sq ft: ${ costPerSqFt }
    used markup: ${ usedMarkup ? 'yes' : 'no' }
    labour unit: ${ labourUnit }
    loss factor: ${ lossFactor }
  ` );

  console.log( `Overhang Left: ${ countertopOverhangLeft }` );
  console.log( `Overhang Right: ${ countertopOverhangRight }` );
  console.log( `Overhang Back: ${ countertopOverhangBack }` );
  console.log( `Cabinet Width: ${ width }` );
  console.log( `Cabinet Depth: ${ innerDepth }` );

  const qty = 1;
  const length = width + countertopOverhangLeft + countertopOverhangRight;
  const depth = pieCutCarcasses.includes( carcasse )
    ? fullDepth
    : innerDepth + 2 + countertopOverhangBack;
  // parseFloat( thickness ) +
  // countertopOverhangBack;

  console.log( `Length = cabinet width + overhang left + overhang right = ${ length }` );
  console.log( `Depth = ${ pieCutCarcasses.includes( carcasse ) ? 'cabinet full depth' : 'cabinet depth + 2 + overhang back' } = ${ depth }` );

  let totalCost = 0;

  const area = qty * length * depth;

  console.log( `Area: ${ area }` );

  if ( usedMarkup ) {
    console.log( 'Used markup' );
    const cost = Number( costPerSqFt );

    totalCost = Number(
      (
        ( area ) / 144 * cost + cost * markup
      )
        .toFixed( 2 )
    );
  } else {
    console.log( 'Not used markup' );

    let loss = 1;

    if ( lossFactor && Number( lossFactor ) ) {
      loss = Number( lossFactor );
    }

    console.log( `Loss: ${ loss }` );
    console.log( `Labour unit: ${ labourUnit }` );

    const cost = ( Number( costPerSqFt ) +
      ( labourUnit ? Number( labourUnit ) : 0 ) ) / loss;

    console.log( `Cost: ${ cost }` );

    totalCost = Number( ( ( area ) / 144 * cost )
      .toFixed( 2 )
    );
  }

  console.log( `Total cost: ${ totalCost }` );

  return {
    name: `${ name } - ${ code } - ${ thickness }`,
    depth: Number( depth.toFixed( 3 ) ),
    length: Number( length.toFixed( 3 ) ),
    // doubled prices
    cost: 2 * totalCost
  };
};

export default ( {
  group,
  carcasse,
  countertop,
  markup,
  width,
  height,
  depth,
  fullDepth,
  countertopOverhangLeft,
  countertopOverhangRight,
  countertopOverhangBack,
  pieCutCarcasses,
  totalCountertop
} ) => {

  if ( group !== 'Base' || !countertop.value ) return totalCountertop;

  const newTotalCountertop = totalCountertop.slice();

  const newCountertop = calcCountertopCost( {
    carcasse,
    countertop,
    markup,
    width,
    height,
    depth,
    fullDepth,
    countertopOverhangLeft,
    countertopOverhangRight,
    countertopOverhangBack,
    pieCutCarcasses
  } );

  const {
    name: newName,
    length: newLength,
    depth: newDepth,
    cost: newCost
  } = newCountertop;

  const countertopExists = newTotalCountertop
    .find( ( item ) => item.name === newName );

  if ( countertopExists ) {
    let newItem = false;

    for ( let i = 0; i < newTotalCountertop.length; i++ ) {
      const { name, depth, cost } = newTotalCountertop[ i ];

      if ( name === newName ) {
        if ( newDepth === depth ) {
          newTotalCountertop[ i ].length += newLength;
          newTotalCountertop[ i ].cost = Number(
            ( cost + newCost ).toFixed( 2 )
          );
        } else {
          newItem = true;
        }

        break;
      }
    }

    if ( newItem ) {
      newTotalCountertop.push( newCountertop );
    }
  } else {
    newTotalCountertop.push( newCountertop );
  }

  return newTotalCountertop;
};
