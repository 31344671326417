/* eslint-disable no-unused-expressions */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
// @ts-check
import { batchActions, BatchAction } from 'redux-batched-actions';
import { batchActionsWithTempl } from '@r/storeUtils';
import { UtilitiesStorage } from 'scr/utilitiesStorage';
import { px } from 'decl/general/units';
import { Dispatch } from 'redux';
import Info from './Info';
import { tab } from './interfaces';

const b = batchActions as batchActionsWithTempl<
  redux._IActions['setObject3dModalVisible'] |
  redux._IActions['setObject3dModalY'] |
  redux._IActions['setObject3dModalX']
>;

const tabs: tab[] = [
  {
    name: 'info',
    uiName: 'Info',
    comp: Info,
    afterFunc: () => 'inverse'
  },
  {
    name: 'delete',
    uiName: 'Delete',
    comp: null,
    afterFunc(
      Storage: UtilitiesStorage,
      dispatch: Dispatch< BatchAction >
    ) {
      const r = confirm( 'Delete this kitchen?' );
      if( !r ) return false;

      const [item] = Storage.get( 'selectedObject' );
      if( item === null ) throw new Error( 'Selected object is null.' );
      if( item.parent === null ) return false;

      const viewer = Storage.get('viewer');
      viewer.toDoRemoveObject(item);

      dispatch(
        b( [
          { type: 'SET_OBJECT_3D_MODAL_VISIBLE', value: false },
          { type: 'SET_OBJECT_3D_MODAL_X', value: 0 as px },
          { type: 'SET_OBJECT_3D_MODAL_Y', value: 0 as px }
        ] )
      );

      return false;
    }
  }
];

export default tabs;
