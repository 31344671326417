import React, { PureComponent } from 'react';
import Button from 'c/shared/ThemeButton';
import SimpleInput from 'c/CalculationLayer/utils/SimpleInput';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import Storage from 'scr/utilitiesStorage';
import { connect } from 'react-redux';
import CheckChangedValue from 'c/shared/borderForChangedValue';
import style from './index.styl';
import {
  regExForNumber,
  replaceValue,
  replaceValueWithDot
} from './utils';

interface _IProps {
  selectedFloorPlanObject: redux._IStore['selectedFloorPlanObject'];
}

interface _IState {
  oldX: string;
  oldY: string;
  x: string;
  y: string;
}

class RightMenuForCorner extends PureComponent<_IProps> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    oldX: '0',
    oldY: '0',
    x: '0',
    y: '0'
  } as _IState;

  componentDidMount(): void {
    const {
      selectedFloorPlanObject: {
        x,
        y
      }
    } = this.props;

    this.setState( {
      oldX: String( x ),
      oldY: String( y ),
      x: String( x ),
      y: String( y )
    } );
  }

  componentDidUpdate(): void {
    const {
      selectedFloorPlanObject
    } = this.props;

    const {
      oldX,
      oldY
    } = this.state;

    if(
      oldX !== String( selectedFloorPlanObject.x ) ||
      oldY !== String( selectedFloorPlanObject.y )
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState( {
        oldX: String( selectedFloorPlanObject.x ),
        x: String( selectedFloorPlanObject.x ),
        oldY: String( selectedFloorPlanObject.y ),
        y: String( selectedFloorPlanObject.y )
      } );
    }
  }

  handleChangeX = ( e: string ) => {
    if( e.match( regExForNumber ) ) {
      const newValue = replaceValueWithDot( e );

      this.setState( {
        x: newValue
      } );
    }
    if( !e && !e.includes( '0' ) || e === '-' ) {
      this.setState( {
        x: '0'
      } );
    }
  };

  handleChangeY = ( e: string ) => {
    if( e.match( regExForNumber ) ) {
      const newValue = replaceValueWithDot( e );

      this.setState( {
        y: newValue
      } );
    }
    if( !e && !e.includes( '0' ) || e === '-' ) {
      this.setState( {
        y: '0'
      } );
    }
  };

  handleRemoveCorner = () => {
    const selectedObject = Storage.get( 'selectedObject' );

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    selectedObject[ 0 ].delete();
  };

  handleApplyX = () => {
    const { oldX, x } = this.state;

    const selectedObject = Storage.get( 'selectedObject' );
    const viewer = Storage.get( 'viewer' );
    if( oldX !== x ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      selectedObject[ 0 ].moveToX( Number( x ) );
    }

    this.setState( {
      oldX: x
    } );

    viewer.renderOnDemand.set();
  };

  handleApplyY = () => {
    const { oldY, y } = this.state;

    const selectedObject = Storage.get( 'selectedObject' );
    const viewer = Storage.get( 'viewer' );
    if( oldY !== y ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      selectedObject[ 0 ].moveToZ( Number( y ) );
    }

    this.setState( {
      oldY: y
    } );

    viewer.renderOnDemand.set();
  };

  render() {
    const {
      handleChangeX,
      handleChangeY,
      handleApplyX,
      handleApplyY
    } = this;

    const {
      oldX = '0',
      oldY = '0',
      x = '0',
      y = '0'
    } = this.state;

    const newX = replaceValue( x );
    const newY = replaceValue( y );

    return (
      <div className={ style.rightMenuForCorner }>
        <Button onClick={ this.handleRemoveCorner }>
          Remove Corner
        </Button>
        <h4>Position</h4>
        <CheckChangedValue changed={ oldX !== x }>
          <LabeledField
            name='X:'
            className='input-for-corner'
          >
            <SimpleInput
              value={ newX }
              onChange={ handleChangeX }
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              onBlur={ handleApplyX }
            />
          </LabeledField>
        </CheckChangedValue>
        <CheckChangedValue changed={ oldY !== y }>
          <LabeledField
            name='Y:'
            className='input-for-corner'
          >
            <SimpleInput
              value={ newY }
              onChange={ handleChangeY }
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              onBlur={ handleApplyY }
            />
          </LabeledField>
        </CheckChangedValue>
      </div>
    );
  }
}

export default connect( ( { selectedFloorPlanObject }: redux._IStore ) => {
  return {
    selectedFloorPlanObject
  };
} )( RightMenuForCorner );
