import { inches } from '@npmrost/utils/lib';

export const UpperFinishPanel:
  Partial<CabD.A.ContainerConfigs.ContainerConfig> =
{
  layout: 'WIDTH',
  height: 30 as inches,
  depth: 13 as inches,
  width: 0.75 as inches,
  name: 'UpperAssembly',
  isTopLevel: true,
  mountTypes: [
    'wall'
  ],
  constrName: 'WallCabinet',
  children: [
    {
      name: 'FinishPanel',
      width: 0.75 as inches,
      type: 'Panel',
      panelParent: 'door'
    }
  ],
  countertopMaterialName: 'marblewhite005'
};
