import {
  Group, Mesh, Geometry, ExtrudeGeometry, Vector2, Vector3, Shape
} from 'three';
import Storage from 'scr/utilitiesStorage';
import { createGeometry } from '../helpers/geometry';
import {
  parseAttributes, parseVector3, parseShape, calculateValue
  , calculateVector2, calculateVector3, calculateEuler, buildShape
} from './helpers';
import Handle from './Handle';


export default class Panel extends Group {
    shape: Shape

    size: Vector3 = new Vector3( 36, 24, 18 );

    mesh: Mesh;

    isPanel = true;

    constructor(
      config
    ) {
      super();
      const material = config.type === 'door'
        ? Storage.get( 'namesToMaterials' ).cabinet[ Storage.get( 'defaultMaterials' ).doorMaterial ]
        : Storage.get( 'namesToMaterials' ).cabinet[ Storage.get( 'defaultMaterials' ).cabinetMaterial ];

      this.mesh = new Mesh( new Geometry(), material );
      this.add( this.mesh );
      this.fromJSON( config );

      // OLD APPROACH COMPATIBILITY/////////////////////////////////////


      if ( config.type === 'door' ) {
        let handle = new Handle();
        handle.rotation.set( 0, Math.PI, 0 );
        handle.position.set( 28, 26, -0.625 / 2 );
        this.add( handle );
      }

      this.vestaObject.getType = () => {
        if ( config.type === 'door' ) {
          return 'panel/door';
        }


        return 'panel/body';
      };

      // //////////////////////////////////////////////////////////////

    }


    rebuildGeometry( ) {
      this.position.copy( this.rebuildPosition() );
      this.rotation.copy( this.rebuildRotation() );
      this.size.copy( this.rebuildSize() );
      this.shape = buildShape(this._shape, this);
      this.mesh.geometry = this.generateGeometry();
      this._r = this.getRotation();
    }

    setMaterial() {

    }

    generateGeometry() {

      return createGeometry( new ExtrudeGeometry( this.shape,
        {
          steps: 1,
          depth: this.size.z,
          bevelEnabled: false
        }
      ) );
    }

    getSize () {
      return this.size.clone();
    }

    getRotation () {
      return this.rotation.clone();
    }

    setPosition( position: Vector3 ) {
      this.position = position;
      this.rebuildGeometry();
    }

    setName( name: string ) {
      this.name = name;
    }

    fromJSON( config ) {
      this._attributes = Reflect.apply( parseAttributes, this, [config.attributes] );
      this._size = Reflect.apply( parseVector3, this, [config.size] );
      this._position = Reflect.apply( parseVector3, this, [config.position] );
      this._rotation = Reflect.apply( parseVector3, this, [config.rotation] );
      this._shape = Reflect.apply( parseShape, this, [config.shape] );
      this.setName( config.name );
    }

    getAttributeValue( attributeName: string ) {
      return Reflect.apply( calculateValue, this, [this._attributes[ attributeName ]] );
    }

    rebuildPosition() {
      return Reflect.apply( calculateVector3, this, [this._position] );
    }

    rebuildRotation() {
      return Reflect.apply( calculateEuler, this, [this._rotation] );
    }

    rebuildSize() {
      return Reflect.apply( calculateVector3, this, [this._size] );
    }

    getPart() {
      let obj = this.parent;

      while ( obj ) {
        if ( obj.isPart ) {
          return obj;
        }

        obj = obj.parent;
      }

      return null;

    }

    getCabinet() {
      let obj = this.parent;

      while ( obj ) {
        if ( obj.isCabinet ) {
          return obj;
        }

        obj = obj.parent;
      }

      return null;
    }

}
