import { simpleSet } from '@r/reducers/utils';
import { inches } from '@npmrost/utils';

const setCalculationModeTopDrawerHeightValue = simpleSet as
  redux.reducers.calculationMode.settings.dimensions.topDrawerHeight.value;

function validateInches( maybeInches: number ): maybeInches is inches {
  if ( maybeInches >= 0 ) {
    return true;
  }

  return false;
}

let defaultValue = 0;

export const value = setCalculationModeTopDrawerHeightValue(
  'SET_CALCULATION_MODE_TOP_DRAWER_HEIGHT_VALUE',
  validateInches( defaultValue ) ? defaultValue : 0 as inches
);
