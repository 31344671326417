// eslint-disable-next-line import/no-cycle
import Storage from 'scr/utilitiesStorage';

export function calcCanvasWidth( screenWidth ) {

  // check width and min-width and borderRadius
  // in CabinetRightSideMenu folder index.styl file
  const { isPresented } = Storage.get( 'rightSideMenuParameters' );
  if( !isPresented ) {
    return screenWidth;
  }

  const rightSideMenuWidth = screenWidth < 1200
    ? 295
    : screenWidth * 0.25 - 5;

  return screenWidth - rightSideMenuWidth;
}
