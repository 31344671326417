// @ts-check
import { feetInchRegexp } from 'scr/metricsConversion';

/** @returns { sanitizeUnitsResult } */
function composeRespForSanitize(
  /** @type { boolean } */ isValid,
  /** @type { UnitsInputv2Value } */ value
) {
  if( isValid === false ) {
    return { isValid: false };
  }

  return { isValid: true, value };
}

/**
 * @typedef {{ isValid: false } | { isValid: true, value: UnitsInputv2Value }
 * } sanitizeUnitsResult
 */
/**
 * @param { redux.store['projectFor3d']['units'] } units
 * @param { UnitsInputv2Value } val
 * @returns { sanitizeUnitsResult }
 */
export default function sanitizeUnits( units, val ) {
  const t = /** @type { UnitsInputv2Value } */ (
    val.trim()
    /*
     * Remove leading non-matter zeros e.g.
     * "0000123" -> "123", "000,0123" -> "0.0123"
     */
      .replace( /^0+([^\.])/, '$1' )
  );

  if( units === 'ftAndInch' ) {
    if( t === '' ) return composeRespForSanitize( true, t );

    if( !t.match( feetInchRegexp ) ) {
      return composeRespForSanitize( false );
    }

    return composeRespForSanitize( true, t );
  }

  if( !t ) return composeRespForSanitize( true, t );

  let numbered = Number( t );
  // eslint-disable-next-line no-self-compare
  if( numbered !== numbered ) {
    return composeRespForSanitize( false );
  }

  return composeRespForSanitize( true, t );
}
