import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Slider from 'rc-slider/lib/Slider';
import 'rc-slider/assets/index.css';

interface _IProps {
  onChange: ( scale: number ) => void;
}

const Wrap = styled.div`
  position: absolute;
  width: 20%;
  top: 50px;
  left: 45px;
`;

// keys of object 'marks' are values and values are labels in slider copmonent
const marks = {
  0.25: '1/4',
  0.5: '1/2',
  0.75: '3/4',
  1: 1,
  1.5: '1 1/2',
  2: 2,
  2.5: '2 1/2',
  3: 3
};

export default class PrintModeSlider extends PureComponent<_IProps> {

  render() {
    const { props: { onChange } } = this;

    return (
      <Wrap>
        <Slider
          min={ 0.25 }
          max={ 3 }
          defaultValue={ 1 }
          marks={ marks }
          step={ 0.25 }
          onChange={ onChange }
          trackStyle={ [{ backgroundColor: '#57ABFF' }] }
        />
      </Wrap>
    );
  }
}
