import React, { PureComponent, Dispatch } from "react";
import { connect } from "react-redux";
import Modal from "c/Modal/Modal";
import ModalStyle from 'c/Modal/Modal.styl';
import { IconButton } from "c/shared/icon-button";
import MyStyle from './index.styl';
import Button from 'c/shared/ThemeButton';
import CabinetView from './preview/CabinetView';
import DoorsAngle from './Widget/DoorsAngle';
import { degrees } from "@r/reducers/storeParts/selectedCabinet/doorsAngle";

import './jsoneditor/dist/jsoneditor.css';
import JSONEditor from './jsoneditor/dist/jsoneditor';
import ShapeEditor from "./ShapeEditor";

class CItemEditorModal extends PureComponent {
    treeViewRef = null;
    treeView = null;
    
    state = {
        itemJson: {
        }
        ,initControl: true
        ,doorsAngle: 0
        ,shapeNode: null
    };

    closeModal = () => {
        const {dispatch} = this.props;
        dispatch({type: 'SET_ITEM_EDITOR_VISIBLE', value: false});
    }
    onChangedDoorsAngle = (angle) => {
        this.setState({
            ...this.state
            ,doorsAngle: angle
        });
    }
    onItemChange = () => {
        const itemJson = this.treeView?.get();
        this.setState({
            ...this.state
            ,itemJson
            ,initControl: false
        });
    }
    onItemEvent = (node, event) => {
        const devmode = false;
        if (devmode) { // for test
            if (event.type==='click') {
                this.setState({
                    ...this.state
                    ,shapeNode: {}
                });
            }
            return;
        }
        if (event.type==='click' && node.field==='shape') {
            this.setState({
                ...this.state
                ,shapeNode: node
            });
        }
    }
    onSubmitShape = (itemJson) => { // new json updated with shape.
        this.setState({
            ...this.state
            ,itemJson
            ,shapeNode: null // close shape-editor
        });
    }

    componentDidMount() {
        const options = {
            mode: 'tree'
            ,modes: ['tree', 'text']
            ,enableSort: false
            ,enableTransform: false
            ,onError: (err) => {
                console.log(err);
            }
            ,onEditable: (node) => {
                // node is an object like:
                //   {
                //     field: 'FIELD',
                //     value: 'VALUE',
                //     path: ['PATH', 'TO', 'NODE']
                //   }

                // In modes code and text, node is empty: no path, field, or value                
                if (node.path) {
                    if (node.field) {
                        return {
                            field: false,
                            value: true
                        }
                    }
                }
                return true;
            }
            ,onChange: this.onItemChange
            ,onEvent: this.onItemEvent
        }
        this.treeView = new JSONEditor(this.treeViewRef, options, this.state.itemJson);
        this.treeView.expandAll();
    }
    componentWillUnmount() {
        if (this.treeView) this.treeView.destroy();
    }
    componentDidUpdate() {
        this.treeView.update(this.state.itemJson);
    }
    render() {
        const {
            props: {
                visible,
                className,
            }
        } = this;

        return (
            <div className={className + (visible ? '' : ' hidden')}>
                <Modal className={MyStyle.modalReact}>
                    <h1 className='addItemsHeading'>Edit Item</h1>
                    <IconButton title='Close' variant='close' className={ModalStyle.close} onClick={this.closeModal} />
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <CabinetView className={MyStyle.preview + ' view3d'}
                                        src = {this.state.itemJson}
                                        rotation = {{x: 0, y: Math.PI*15/180, z:0}}
                                        initControl = {this.state.initControl}
                                        doorsAngle = {this.state.doorsAngle}
                                    />
                                </td>
                                <td rowSpan={2}>
                                    <div ref={e => this.treeViewRef = e} className={MyStyle.jsonview}></div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className={MyStyle.preview + ' control'}>
                                        <DoorsAngle angle={this.state.doorsAngle} onChangedValue={this.onChangedDoorsAngle} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className='forAddButton itemsAdd'>
                        <Button onClick={this.closeModal}>Register To Lib</Button>
                    </div>
                </Modal>
                {this.state.shapeNode && <ShapeEditor itemJson = {this.state.itemJson} shapeNode={this.state.shapeNode} onSubmitShape={this.onSubmitShape} />}
            </div>
        );
    }
}

export const ItemEditorModal = connect(
    (s) => ({
        visible: s.modals.itemEditorVisible,
    })
)(CItemEditorModal);