import {
  Group, Vector3, Vector2, Mesh, MeshBasicMaterial, DoubleSide, FrontSide, Shape, Geometry, ExtrudeGeometry, Quaternion, Math as TMath, Box2, Matrix4
} from 'three';
import Storage from 'scr/utilitiesStorage';
import Corner from './Corner';
import Utils from './utils';
import Wall2D from './Wall2D';
import Stage from './Stage';

// import { updateUISelectedObject } from '../core/helpers/UI';
import Dimension from '../core/helpers/Dimension';
import { isHoleableWithWall } from '../core/helpers/object3d';

const defaultMaterialUnselected = new MeshBasicMaterial( {
  color: 0x555555,
  transparent: false,
  opacity: 1.0,
  side: DoubleSide
} );
const defaultMaterialSelected = new MeshBasicMaterial( {
  color: 0x3f8cba,
  transparent: false,
  opacity: 1.0,
  side: DoubleSide
} );
const defaultMaterialInvisible = new MeshBasicMaterial( {
  visible: false
} );
const defaultMaterial3DMode = new MeshBasicMaterial( {
  visible: false,
  color: 0x555555,
  transparent: true,
  opacity: 0.1,
  side: FrontSide
} );
export default class Wall3D extends Group {
  from: Corner;

  to: Corner;

  depth: number;

  mesh: Mesh;

  height: number;

  isTopLevel = true;

  isWall3D = true;

  width: number;

  wall2D_1: Wall2D;

  wall2D_2: Wall2D;

  stage: Stage;

  shapePoints: any = {};

  constructor( from: Corner, to: Corner, depth: number = Storage.get( 'dimensions' ).wallDepth, height: number = Storage.get( 'dimensions' ).wallHeight, material: MeshBasicMaterial = defaultMaterialUnselected ) {
    super();
    this.from = from;
    this.to = to;
    this.depth = depth;
    this.height = height;
    // this.stage = this.from.stage;

    const geometry = new Geometry();
    this.mesh = new Mesh( geometry, material );
    this.mesh.rotation.set( -Math.PI / 2, 0, 0 );
    this.mesh.position.set( 0, -this.height / 2, 0 );
    this.mesh.isRaycastedInFloorplanMode = true;
    this.wall2D_1 = new Wall2D( '',
      1,
      1,
      new Shape(),
      Storage.get( 'defaultMaterials' ).walls );
    this.wall2D_1.wall3D = this;
    this.wall2D_2 = new Wall2D( '',
      1,
      1,
      new Shape(),
      Storage.get( 'defaultMaterials' ).walls );
    this.wall2D_2.wall3D = this;
    this.rebuildGeometry();
    this.add( this.mesh );
    // this.add(this.wall2D_1);
    // this.add(this.wall2D_2);


    this.vestaObject.setType( 'wall3D' );

  }

  rebuildDimensions () {
    const angle = this.get2DAngleDeg();
    const rotatedNumber = ( angle > 90 ) && ( angle <= 270 );
    if ( !this.wall2D1Dimension ) {
      this.wall2D1Dimension = new Dimension( new Vector3( 0, 0, 0 ), new Vector3( 1, 0, 0 ), Math.round( this.wall2D_1.width * 100 ) / 100, rotatedNumber );
      // this.wall2D_1.add( this.wall2D1dimension );
      this.add( this.wall2D1Dimension );
    }

    this.wall2D1Dimension.rotatedNumber = rotatedNumber;
    this.wall2D1Dimension.rotation.set( -Math.PI / 2, 0, 0 );
    this.wall2D1Dimension.position.set( this.wall2D_1.position.x, this.height / 2 + 1, 15 );
    this.wall2D1Dimension.update( Math.round( this.wall2D_1.width * 100 ) / 100 );

    if ( !this.wall2D2Dimension ) {
      this.wall2D2Dimension = new Dimension( new Vector3( 0, 0, 0 ), new Vector3( -1, 0, 0 ), Math.round( this.wall2D_2.width * 100 ) / 100, rotatedNumber );
      // this.wall2D_2.add( this.wall2D2dimension );
      this.add( this.wall2D2Dimension );
    }

    this.wall2D2Dimension.rotatedNumber = rotatedNumber;
    this.wall2D2Dimension.rotation.set( -Math.PI / 2, 0, 0 );
    this.wall2D2Dimension.position.set( this.wall2D_2.position.x, this.height / 2 + 1, -15 );
    this.wall2D2Dimension.update( Math.round( this.wall2D_2.width * 100 ) / 100 );

  }

  getBoundaries(): { k: number; b1: number; b2: number } {
    let k, diff, b, b1, b2;
    if ( this.to.x !== this.from.x ) {
      k = ( this.to.y - this.from.y ) / ( this.to.x - this.from.x );
      diff = this.depth / 2 * Math.sqrt( 1 + Math.pow( k, 2 ) );
      b = this.from.y - ( this.from.x * ( this.to.y - this.from.y ) / ( this.to.x - this.from.x ) );
      b1 = b - diff;
      b2 = b + diff;
    } else {
      k = NaN;
      b1 = NaN;
      b2 = NaN;
    }


    return { k, b1, b2 };
  }

  get2DAngle() {
    return new Vector2( this.to.x - this.from.x, this.to.y - this.from.y ).angle();
  }

  get2DAngleDeg() {

    const result = new Vector2( this.to.x - this.from.x, -this.to.y + this.from.y ).angle() * TMath.RAD2DEG;

    return result;
  }

  set2DAngleDeg( angle ) {
    const newToPosition = new Vector2( this.from.x + Math.cos( angle * TMath.DEG2RAD ) * this.width, this.from.y - Math.sin( angle * TMath.DEG2RAD ) * this.width );
    this.to.moveTo( newToPosition.x, newToPosition.y, false );
    this.stage.floorplan.rebuildGeometry();

    // updateUISelectedObject();
  }

  rebuildGeometry() {
    // refresh geometry with holes
    this.width = this.from.position.distanceTo(this.to.position);

    // major volume of 3d wall
    let shape = new Shape();
    if (this.shapePoints[0]) {
      shape.moveTo(this.shapePoints[0].x, this.shapePoints[0].y);
    } else {
      shape.moveTo(-this.width / 2, -(this.depth - 0.01) / 2);
    }
    if (this.shapePoints[1]) {
      shape.lineTo(this.shapePoints[1].x, this.shapePoints[1].y);
    } else {
      shape.lineTo(-this.width / 2, (this.depth - 0.01) / 2);
    }
    if (this.shapePoints[2]) {
      shape.lineTo(this.shapePoints[2].x, this.shapePoints[2].y);
    } else {
      shape.lineTo(this.width / 2, (this.depth - 0.01) / 2);
    }
    if (this.shapePoints[3]) {
      shape.lineTo(this.shapePoints[3].x, this.shapePoints[3].y);
    } else {
      shape.lineTo(this.width / 2, -(this.depth - 0.01) / 2);
    }
    if (this.shapePoints[0]) {
      shape.lineTo(this.shapePoints[0].x, this.shapePoints[0].y);
    } else {
      shape.lineTo(-this.width / 2, -(this.depth - 0.01) / 2);
    }
    this.mesh.geometry = new ExtrudeGeometry(shape,
      {
      steps: 1,
      depth: this.height,
      bevelEnabled: false
    });
    this.mesh.position.set(0, -this.height / 2, 0);

    // wall2ds for mounting plane
    function makeHole(wall2d: Wall2D, width: number, height: number): Shape {
      // sub utility to inflate for safety to triangulation of region.
      function inflate2d(bound2d) {
        const center2d = new Vector2();
        bound2d.getCenter(center2d);
        const size2d = new Vector2();
        bound2d.getSize(size2d);
        size2d.subScalar(0.001); // inflate 2d
        bound2d.setFromCenterAndSize(center2d, size2d);
        return bound2d;
      }
      function setInBound(vts, bound2d) {
        vts.forEach(vt => {
          vt.x = Math.max(vt.x, bound2d.min.x);
          vt.x = Math.min(vt.x, bound2d.max.x);
          vt.y = Math.max(vt.y, bound2d.min.y);
          vt.y = Math.min(vt.y, bound2d.max.y);
        });
        return vts;
      }

      // region
      const shape = new Shape()
      .moveTo(0, 0)
      .lineTo(width, 0)
      .lineTo(width, height)
      .lineTo(0, height)
      .lineTo(0, 0);

      const w2b = new Box2(); // bounding box
      shape.curves.forEach(curve => w2b.expandByPoint(curve.v1));
      inflate2d(w2b);
      const w2m = new Matrix4().getInverse(wall2d.matrixWorld); // matrix: world to local
      
      // holeables
      wall2d.mountPlane.children.filter(item => isHoleableWithWall(item)).forEach(item => {
        item.updateMatrixWorld(); // important for local to world below.

        const vts = item.getHoleShape(); // in bcs
        // setInBound(vts, inflate2d(new Box2().setFromPoints(vts)));
        
        vts.forEach(vt => {
          vt.applyMatrix4(item.matrixWorld); // local to world
          vt.applyMatrix4(w2m); // world to local
        });
        setInBound(vts, w2b); // inflate

        const hole = new Shape();
        hole.moveTo(vts[0].x, vts[0].y);
        for (let ivt = 1; ivt < vts.length; ivt++) hole.lineTo(vts[ivt].x, vts[ivt].y);
        hole.lineTo(vts[0].x, vts[0].y);
        
        shape.holes.push(hole);
      });

      return shape;
    }

    // inner wall2d
    let width = shape.curves[3].v1.x - shape.curves[0].v1.x;
    const shape1 = makeHole(this.wall2D_1, width, this.height);
    this.wall2D_1.rebuildGeometry(Math.abs(width), this.height, shape1);

    this.wall2D_1.position.set(shape.curves[0].v1.x, -this.height / 2, this.depth / 2);
    this.wall2D_1.updateMatrixWorld();

    // outer wall2d
    width = shape.curves[2].v1.x - shape.curves[1].v1.x;
    const shape2 = makeHole(this.wall2D_2, width, this.height);
    this.wall2D_2.rebuildGeometry(Math.abs(width), this.height, shape2);
    
    this.wall2D_2.position.set(shape.curves[2].v1.x, -this.height / 2, -this.depth / 2);
    this.wall2D_2.rotation.set(0, Math.PI, 0);
    this.wall2D_2.updateMatrixWorld();

    // wall3d coords
    this.position.copy(this.from.position).add(this.to.position).multiplyScalar(0.5).add(new Vector3(0, this.height/2, 0));
    this.quaternion.setFromUnitVectors(new Vector3(1, 0, 0), this.to.position.clone().sub(this.from.position).normalize());

    this.rebuildDimensions();
    this.updateMatrixWorld();
  }

  setMaterialToUnselected() {
    this.mesh.material = defaultMaterialUnselected;
  }

  setMaterialToSelected() {
    this.mesh.material = defaultMaterialSelected;
  }

  setMaterialToInvisible() {
    this.mesh.material = defaultMaterialInvisible;
  }

  setMaterialTo3DMode() {
    this.mesh.material = defaultMaterial3DMode;
  }

  changeWidth( width: number, origin: 'from' | 'to' | 'center' ) {
    // for now all corners are just moved
    // TODO : ability to divide corner and move it separately
    if ( !origin || origin === 'from' ) {
      let target = ( ( ( this.to.position.clone().sub( this.from.position.clone() ) ).normalize() ).multiplyScalar( width ) )
        .add( this.from.position.clone() );
      this.to.moveTo( target.x, target.z );
      this.stage.floorplan.rebuildGeometry();

      return;
    }
    if ( origin === 'to' ) {
      let target = ( ( ( this.from.position.clone().sub( this.to.position.clone() ) ).normalize() ).multiplyScalar( width ) )
        .add( this.to.position.clone() );
      this.from.moveTo( target.x, target.z );
      this.stage.floorplan.rebuildGeometry();

      return;
    }
    if ( origin === 'center' ) {
      let center = this.to.position.clone().add( this.from.position.clone() ).multiplyScalar( 0.5 );
      let target1 = ( ( ( this.to.position.clone().sub( this.from.position.clone() ) ).normalize() ).multiplyScalar( width / 2 ) )
        .add( center.clone() );

      let target2 = ( ( ( this.from.position.clone().sub( this.to.position.clone() ) ).normalize() ).multiplyScalar( width / 2 ) )
        .add( center.clone() );
      this.to.moveTo( target1.x, target1.z );
      this.from.moveTo( target2.x, target2.z );
      this.stage.floorplan.rebuildGeometry();

    }
  }

  changeHeight( height: number ) {
    this.height = height;
    this.rebuildGeometry();
    this.stage.floorplan.rebuildGeometry();
    // updateUISelectedObject();
  }

  changeDepth( depth: number ) {
    this.depth = depth;
    this.from.rebuildGeometry();
    this.to.rebuildGeometry();
    this.rebuildGeometry();
    this.stage.floorplan.rebuildGeometry();
    // updateUISelectedObject();
  }

  getAngleAroundCorner ( corner: Corner ): number {

    let angle = this.get2DAngle();
    if ( this.from !== corner ) {
      angle = angle > Math.PI ? ( angle - Math.PI ) : ( angle + Math.PI );
    }


    return angle;
  }

  getPosition() {
    return new Vector2( this.position.x, this.position.z );
  }

  getResizeOrigin ( direction: 'top' | 'bottom' | 'left' | 'center' | 'right' ):
  'from' | 'to' | 'center' {
    if ( direction === 'center' ) {
      return 'center';
    }


    if ( direction === 'top' ) {
      if ( this.from.y < this.to.y ) {
        return 'from';
      }

      return 'to';
    }

    if ( direction === 'bottom' ) {
      if ( this.from.y < this.to.y ) {
        return 'to';
      }

      return 'from';
    }

    if ( direction === 'left' ) {
      if ( this.from.x > this.to.x ) {
        return 'to';
      }

      return 'from';
    }

    if ( direction === 'right' ) {
      if ( this.from.x > this.to.x ) {
        return 'from';
      }

      return 'to';
    }

    /* const angle = this.get2DAngle();
    if ( ( angle > Math.PI / 4 && angle < 3 * Math.PI / 4 ) ||
     ( ( angle > 5 * Math.PI / 4 && angle < 7 * Math.PI / 4 ) ) ) {
       if (циферка в поле < текущей длины стены) {
        // левая кнопка значок стрелка вниз , 'top'
        //средння кнопка значок стрелки сверху и снизу к центру ,'center'
        // правая кнопка значок стрелка снизу вверх , 'bottom'
       }
       if (циферка в поле > текущей длины стены) {
         // левая кнопка значок стрелка вверх , 'bottom'
        //средння кнопка значок стрелки вверх и вниз от центра ,'center'
        // правая кнопка значок стрелка вниз , 'top'
       }
    }
    if (циферка в поле < текущей длины стены) {
      // левая кнопка значок стрелка влево , 'left'
      //средння кнопка значок стрелки слева и справа к центру ,'center'
      // правая кнопка значок стрелка вправо , 'right'
     }
     if (циферка в поле > текущей длины стены) {
      // левая кнопка значок стрелка вправо , 'right'
      //средння кнопка значок стрелки от центра вправо и влево ,'center'
      // правая кнопка значок стрелка влево , 'left'
     } */
  }

  setWall2D1Width( width: number, direction: 'top' | 'bottom' | 'left' | 'center' | 'right' ) {
    const origin = this.getResizeOrigin( direction );
    let depth1, angle1, depth2, angle2;
    let fromNeighbourWall3d;
    let toNeighbourWall3d;
    const from = this.from;
    const to = this.to;

    if ( from.getNeighbourWalls3D().length < 2 ) {
      depth1 = 0;
      angle1 = Math.PI / 2; // any with sin !==0
    } else {
      let fromSortedList = from.walls3DSortedList;
      let index = fromSortedList.indexOf( this );
      if ( index === ( fromSortedList.length - 1 ) ) {
        fromNeighbourWall3d = fromSortedList[ 0 ];
      } else {
        fromNeighbourWall3d = fromSortedList[ index + 1 ];
      }

      depth1 = fromNeighbourWall3d.depth;
      angle1 = fromNeighbourWall3d.getAngleAroundCorner( from ) - this.getAngleAroundCorner( from );
      if ( angle1 < 0 ) {
        angle1 += 2 * Math.PI;
      }
    }

    if ( to.getNeighbourWalls3D().length < 2 ) {
      depth2 = 0;
      angle2 = Math.PI / 2; // any with sin !==0
    } else {
      let toSortedList = to.walls3DSortedList;
      let index = toSortedList.indexOf( this );
      if ( index > 0 ) {
        toNeighbourWall3d = toSortedList[ index - 1 ];

      } else {
        toNeighbourWall3d = toSortedList[ toSortedList.length - 1 ];
      }

      depth2 = toNeighbourWall3d.depth;
      angle2 = this.getAngleAroundCorner( to ) - toNeighbourWall3d.getAngleAroundCorner( to );
      if ( angle2 < 0 ) {
        angle2 += 2 * Math.PI;
      }
    }

    if ( !origin || origin === 'from' ) {
      let oldPos = { x: to.x, y: to.y };

      this.changeWidth( width + depth1 / 2 / Math.tan( angle1 / 2 ) + depth2 / 2 / Math.tan( angle2 / 2 ) );

      let newPos = { x: to.x, y: to.y };
      let neighbourCorners = to.getNeighbourCorners();
      for ( let i = 0; i < neighbourCorners.length; i += 1 ) {
        if ( neighbourCorners[ i ] !== this.from ) {
          neighbourCorners[ i ].moveTo( neighbourCorners[ i ].x + newPos.x - oldPos.x, neighbourCorners[ i ].y + newPos.y - oldPos.y );
        }
      }
    }
    if ( origin === 'to' ) {
      let oldPos = { x: from.x, y: from.y };

      this.changeWidth( width + depth1 / 2 / Math.tan( angle1 / 2 ) + depth2 / 2 / Math.tan( angle2 / 2 ), 'to' );

      let newPos = { x: from.x, y: from.y };
      let neighbourCorners = from.getNeighbourCorners();
      for ( let i = 0; i < neighbourCorners.length; i += 1 ) {
        if ( neighbourCorners[ i ] !== this.to ) {
          neighbourCorners[ i ].moveTo( neighbourCorners[ i ].x + newPos.x - oldPos.x, neighbourCorners[ i ].y + newPos.y - oldPos.y );
        }
      }
    }
    if ( origin === 'center' ) {
      let oldPosFrom = { x: from.x, y: from.y };
      let oldPosTo = { x: to.x, y: to.y };

      this.changeWidth( width + depth1 / 2 / Math.tan( angle1 / 2 ) + depth2 / 2 / Math.tan( angle2 / 2 ), 'center' );

      let newPosFrom = { x: from.x, y: from.y };
      let newPosTo = { x: to.x, y: to.y };
      let neighbourCorners = from.getNeighbourCorners();
      for ( let i = 0; i < neighbourCorners.length; i += 1 ) {
        if ( neighbourCorners[ i ] !== this.to ) {
          neighbourCorners[ i ].moveTo( neighbourCorners[ i ].x + newPosFrom.x - oldPosFrom.x, neighbourCorners[ i ].y + newPosFrom.y - oldPosFrom.y );
        }
      }

      neighbourCorners = to.getNeighbourCorners();
      for ( let i = 0; i < neighbourCorners.length; i += 1 ) {
        if ( neighbourCorners[ i ] !== this.from ) {
          neighbourCorners[ i ].moveTo( neighbourCorners[ i ].x + newPosTo.x - oldPosTo.x, neighbourCorners[ i ].y + newPosTo.y - oldPosTo.y );
        }
      }
    }


    this.stage.floorplan.rebuildGeometry();
  }

  setWall2D2Width( width: number, direction: 'top' | 'bottom' | 'left' | 'center' | 'right' ) {
    const origin = this.getResizeOrigin( direction );
    let depth1, angle1, depth2, angle2;
    let fromNeighbourWall3d;
    let toNeighbourWall3d;
    const from = this.from;
    const to = this.to;

    if ( from.getNeighbourWalls3D().length < 2 ) {
      depth1 = 0;
      angle1 = Math.PI / 2; // any with sin !==0
    } else {
      let fromSortedList = from.walls3DSortedList;
      let index = fromSortedList.indexOf( this );
      if ( index > 0 ) {
        fromNeighbourWall3d = fromSortedList[ index - 1 ];
      } else {
        fromNeighbourWall3d = fromSortedList[ fromSortedList.length - 1 ];
      }

      depth1 = fromNeighbourWall3d.depth;
      angle1 = this.getAngleAroundCorner( from ) - fromNeighbourWall3d.getAngleAroundCorner( from );
      if ( angle1 < 0 ) {
        angle1 += 2 * Math.PI;
      }
    }

    if ( to.getNeighbourWalls3D().length < 2 ) {
      depth2 = 0;
      angle2 = Math.PI / 2; // any with sin !==0
    } else {
      let toSortedList = to.walls3DSortedList;
      let index = toSortedList.indexOf( this );
      if ( index === ( toSortedList.length - 1 ) ) {
        toNeighbourWall3d = toSortedList[ 0 ];

      } else {
        toNeighbourWall3d = toSortedList[ index + 1 ];
      }

      depth2 = toNeighbourWall3d.depth;
      angle2 = toNeighbourWall3d.getAngleAroundCorner( to ) - this.getAngleAroundCorner( to );
      if ( angle2 < 0 ) {
        angle2 += 2 * Math.PI;
      }
    }

    /* let oldPos = { x: to.x, y: to.y };
    this.changeWidth( width + depth1 / 2 / Math.tan( angle1 / 2 ) + depth2 / 2 / Math.tan( angle2 / 2 ) );

    let newPos = { x: to.x, y: to.y };
    let neighbourCorners = to.getNeighbourCorners();
    for ( let i = 0; i < neighbourCorners.length; i += 1 ) {
      if ( neighbourCorners[ i ] !== this.from ) {
        neighbourCorners[ i ].moveTo( neighbourCorners[ i ].x + newPos.x - oldPos.x, neighbourCorners[ i ].y + newPos.y - oldPos.y );
      }
    } */
    if ( !origin || origin === 'from' ) {
      let oldPos = { x: to.x, y: to.y };

      this.changeWidth( width + depth1 / 2 / Math.tan( angle1 / 2 ) + depth2 / 2 / Math.tan( angle2 / 2 ) );

      let newPos = { x: to.x, y: to.y };
      let neighbourCorners = to.getNeighbourCorners();
      for ( let i = 0; i < neighbourCorners.length; i += 1 ) {
        if ( neighbourCorners[ i ] !== this.from ) {
          neighbourCorners[ i ].moveTo( neighbourCorners[ i ].x + newPos.x - oldPos.x, neighbourCorners[ i ].y + newPos.y - oldPos.y );
        }
      }
    }
    if ( origin === 'to' ) {
      let oldPos = { x: from.x, y: from.y };

      this.changeWidth( width + depth1 / 2 / Math.tan( angle1 / 2 ) + depth2 / 2 / Math.tan( angle2 / 2 ), 'to' );

      let newPos = { x: from.x, y: from.y };
      let neighbourCorners = from.getNeighbourCorners();
      for ( let i = 0; i < neighbourCorners.length; i += 1 ) {
        if ( neighbourCorners[ i ] !== this.to ) {
          neighbourCorners[ i ].moveTo( neighbourCorners[ i ].x + newPos.x - oldPos.x, neighbourCorners[ i ].y + newPos.y - oldPos.y );
        }
      }
    }
    if ( origin === 'center' ) {
      let oldPosFrom = { x: from.x, y: from.y };
      let oldPosTo = { x: to.x, y: to.y };

      this.changeWidth( width + depth1 / 2 / Math.tan( angle1 / 2 ) + depth2 / 2 / Math.tan( angle2 / 2 ), 'center' );

      let newPosFrom = { x: from.x, y: from.y };
      let newPosTo = { x: to.x, y: to.y };
      let neighbourCorners = from.getNeighbourCorners();
      for ( let i = 0; i < neighbourCorners.length; i += 1 ) {
        if ( neighbourCorners[ i ] !== this.to ) {
          neighbourCorners[ i ].moveTo( neighbourCorners[ i ].x + newPosFrom.x - oldPosFrom.x, neighbourCorners[ i ].y + newPosFrom.y - oldPosFrom.y );
        }
      }

      neighbourCorners = to.getNeighbourCorners();
      for ( let i = 0; i < neighbourCorners.length; i += 1 ) {
        if ( neighbourCorners[ i ] !== this.from ) {
          neighbourCorners[ i ].moveTo( neighbourCorners[ i ].x + newPosTo.x - oldPosTo.x, neighbourCorners[ i ].y + newPosTo.y - oldPosTo.y );
        }
      }
    }

    this.stage.floorplan.rebuildGeometry();
  }

  moveTo( x: number, z: number, modeOrtho: boolean, basicPosition: Vector2 ) {
    if ( false ) {
      if ( Math.abs( x - basicPosition.x ) >= Math.abs( z - basicPosition.y ) ) {
        this.moveXZ( x, basicPosition.y );
      } else {
        this.moveXZ( basicPosition.x, z );
      }
    } else {
      this.moveXZ( x, z );
    }
  }

  moveXZ( x: number, z: number ) {
    const target = new Vector3( x, 0, z );

    const targetProjected = Utils.projectPointOnLine( this.to.position.clone(), this.from.position.clone(), target );// target.clone().projectOnVector(this.to.position.clone().sub(this.from.position));
    const displacement = target.clone().sub( targetProjected );
    this.from.shiftTo( Math.round( displacement.x * 10000 ) / 10000, Math.round( displacement.z * 10000 ) / 10000 );
    this.to.shiftTo( Math.round( displacement.x * 10000 ) / 10000, Math.round( displacement.z * 10000 ) / 10000 );
  }

  moveX( x: number ) {

    const target = new Vector3( x, 0, 0 );

    const targetProjected = Utils.projectPointOnLine( this.to.position.clone(), this.from.position.clone(), target );// target.clone().projectOnVector(this.to.position.clone().sub(this.from.position));
    const displacement = target.clone().sub( targetProjected );
    this.from.shiftTo( displacement.x, 0 );
    this.to.shiftTo( displacement.x, 0 );
  }

  moveZ( z: number ) {
    const target = new Vector3( 0, 0, z );

    const targetProjected = Utils.projectPointOnLine( this.to.position.clone(), this.from.position.clone(), target );// target.clone().projectOnVector(this.to.position.clone().sub(this.from.position));
    const displacement = target.clone().sub( targetProjected );
    this.from.shiftTo( 0, displacement.z );
    this.to.shiftTo( 0, displacement.z );
  }

  changeFromTo( corner: Corner ) {
    this.from = corner;
    this.rebuildGeometry();
  }

  changeToTo( corner: Corner ) {
    this.to = corner;
    this.rebuildGeometry();
  }

  getNearestWall2D( point: Vector3 ) {

    this.wall2D_1.updateMatrixWorld();
    this.wall2D_2.updateMatrixWorld();

    let wall2D_1Distance = (
      new Vector3( this.width / 2, 0, 0 )
        .applyMatrix4( this.wall2D_1.matrix )
        .applyMatrix4( this.matrixWorld )
    )
      .sub( point.clone() );
    let wall2D_2Distance = (
      new Vector3( this.width / 2, 0, 0 )
        .applyMatrix4( this.wall2D_2.matrix )
        .applyMatrix4( this.matrixWorld ) )
      .sub( point.clone() );

    // console.log(this.wall2D_1, wall2D_1Distance, wall2D_1Distance.length(), this.wall2D_2, wall2D_2Distance, wall2D_2Distance.length(), point.clone());

    if ( wall2D_1Distance.length() <= wall2D_2Distance.length() ) {
      return this.wall2D_1;
    }

    return this.wall2D_2;

  }

  delete() {
    this.stage.removeWall3D( this );
    const viewer = Storage.get( 'viewer' );


    const fromWalls3D = this.from.getNeighbourWalls3D();
    const toWalls3D = this.to.getNeighbourWalls3D();
    if ( fromWalls3D.length === 1 && fromWalls3D[ 0 ] === this ) {
      this.from.delete();
    }
    if ( toWalls3D.length === 1 && toWalls3D[ 0 ] === this ) {
      this.to.delete();
    }

    this.stage.floorplan.rebuildGeometry();
    // updateUISelectedObject( [null] );
    viewer.scene.dragDropManager.object3dF = null;
    viewer.renderOnDemand.set();
  }
}
