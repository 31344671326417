// @ts-check
import { PRESETS, } from '../../container';
import { getPanel, } from '../helpers/GeneralConfigs';

const w1 = 23;
const w2 = 23;

/** @type { Partial<CabD.A.ContainerConfigs.ContainerConfig> } */
const config = {
  height: 36,
  depth: 40,
  width: 40,
  name: 'CornerTopCabinet',
  isTopLevel: true,
  isTopCorner: true,
  constrName: 'CornerWithCountertop',
  layout: PRESETS.LAYOUT.WIDTH,
  children: [
    getPanel( { width: w1, } ),
    { 
      layout: PRESETS.LAYOUT.DEPTH, 
      children: [ getPanel( { depth: w2, } ), ], },
  ],
};

export default config;
