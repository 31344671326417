import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { cabinetName } from './cabinetName';

const modalsComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['search'],
    AllActions
  >;

export const search = modalsComb( {
  cabinetName
} );
