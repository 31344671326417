import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { label } from './label';
import { value } from './value';

const shippingCostManualComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['projects']['currentProject']['settings']['information']['shippingCostManual'],
    AllActions
  >;

export const shippingCostManual = shippingCostManualComb( {
  label,
  value
} );
