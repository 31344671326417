import DoBaseManager from "c/ThreeJsWrap/Viewer/UndoRedo/DoBaseManager";
import DoAddRemoveEntity from "./DoAddRemoveEntity";

/**
 * Manager for Undo/Redo history.
 */
class DoManager extends DoBaseManager {
	viewer;

	constructor(viewer) {
		super(); // must call super when redefine constructor.

		this.viewer = viewer;
	}

	isDoable() {
		return this.viewer && this.viewer.cmdMgr.isIdle();
	}

    registerDo(doaction, group) {
		doaction = super.registerDo(doaction, group);
		if (doaction) {
			doaction.viewer = this.viewer;
		}
		return doaction;
	}

	// utils
	doDeleteEntity(entity, parent) {
		entity.highlight = false;
		const doaction = new DoAddRemoveEntity(entity, false, parent);
		this.registerDo(doaction);
		doaction.redo();
	}
}

export default DoManager;