import { combineReducers } from 'redux';

import {
  setDefaultMaterialsSets
} from '@r/reducers/storeParts/calculationMode/adminSettings/projectSettings/materials/materialsSets';
import {
  setDefaultCountertop
} from '@r/reducers/storeParts/calculationMode/adminSettings/projectSettings/materials/countertop';
import {
  setDefaultHinges
} from '@r/reducers/storeParts/calculationMode/adminSettings/projectSettings/materials/hinges';
import {
  setDefaultPulls
} from '@r/reducers/storeParts/calculationMode/adminSettings/projectSettings/materials/pulls';
import {
  setDefaultLegs
} from '@r/reducers/storeParts/calculationMode/adminSettings/projectSettings/materials/legs';
import {
  setDefaultDrawerSystems
} from '@r/reducers/storeParts/calculationMode/adminSettings/projectSettings/materials/drawerSystems';

import {
  getAdminSettings,
  // createAdminSettings,
  patchAdminSettings
} from 'services/adminSettings/adminSettings.service';
import { excelUrl } from './excelUrl';
import { projectSettings } from './projectSettings';
import { fixLabourCost } from './fixLabourCost';
import { doorLabourUnit } from './doorLabourUnit';
import { drawerLabourUnit } from './drawerLabourUnit';
import { cabinetsLabourUnit } from './cabinetsLabourUnit';


export const adminSettings = combineReducers( {
  excelUrl,
  projectSettings,
  fixLabourCost,
  doorLabourUnit,
  drawerLabourUnit,
  cabinetsLabourUnit
} );

function closeNonLeafOptions( options ) {
  const newOptions = options.slice();

  for ( let i = 0; i < newOptions.length; i++ ) {
    const { nodeType } = newOptions[ i ];

    if ( nodeType === 'nonLeaf' ) {
      newOptions[ i ].open = false;
      newOptions[ i ].children = closeNonLeafOptions(
        newOptions[ i ].children
      );
    }
  }

  return newOptions;
}

export function saveAdminSettings() {
  return async ( dispatch, getStore ) => {
    const {
      calculationMode: {
        adminSettings: {
          projectSettings
        }
      }
    } = getStore();

    for ( let i = 0; i < projectSettings.materials.materialsSets.data.length; i++ ) {
      projectSettings.materials.materialsSets.data[ i ].coreSearchText = '';
      projectSettings.materials.materialsSets.data[ i ].doorsDrawersSearchText = '';
      projectSettings.materials.materialsSets.data[ i ].edgebandingSearchText = '';
    }

    projectSettings.materials.materialsSets.coreOptions = closeNonLeafOptions(
      projectSettings.materials.materialsSets.coreOptions
    );

    projectSettings.materials.materialsSets.doorsDrawersOptions = closeNonLeafOptions(
      projectSettings.materials.materialsSets.doorsDrawersOptions
    );

    projectSettings.materials.materialsSets.edgebandingOptions = closeNonLeafOptions(
      projectSettings.materials.materialsSets.edgebandingOptions
    );

    projectSettings.materials.countertop.options = closeNonLeafOptions(
      projectSettings.materials.countertop.options
    );
    projectSettings.materials.countertop.searchText = '';

    projectSettings.materials.pulls.options = closeNonLeafOptions(
      projectSettings.materials.pulls.options
    );
    projectSettings.materials.pulls.searchText = '';

    projectSettings.materials.hinges.options = closeNonLeafOptions(
      projectSettings.materials.hinges.options
    );
    projectSettings.materials.hinges.searchText = '';

    return new Promise( async ( resolve, reject ) => {
      try {
        const response = await patchAdminSettings( { projectSettings } );

        resolve( response );
      } catch ( err ) {
        reject( err );
      }
    } );
  };
}

function dispatchAdminSettings(
  dispatch,
  {
    excelUrl,
    projectSettings,
    fixLabourCost,
    doorLabourUnit,
    drawerLabourUnit,
    cabinetsLabourUnit
  }
) {
  dispatch( {
    type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_EXCEL_URL',
    value: excelUrl
  } );

  dispatch( {
    type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_PROJECT_SETTINGS',
    value: projectSettings
  } );

  dispatch( {
    type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_FIX_LABOUR_COST',
    value: fixLabourCost
  } );

  dispatch( {
    type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_DOOR_LABOUR_UNIT',
    value: doorLabourUnit
  } );

  dispatch( {
    type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_DRAWER_LABOUR_UNIT',
    value: drawerLabourUnit
  } );

  dispatch( {
    type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_CABINETS_LABOUR_UNIT',
    value: cabinetsLabourUnit
  } );
}

export const setDefaultAdminSettings = ( updated = false ) => {
  return async ( dispatch ) => {
    if ( updated ) {
      dispatch( setDefaultMaterialsSets() );
      dispatch( setDefaultCountertop() );
      dispatch( setDefaultHinges() );
      dispatch( setDefaultPulls() );
      dispatch( setDefaultLegs() );
      dispatch( setDefaultDrawerSystems() );

      dispatch( saveAdminSettings() )
        .then( ( data ) => {
          dispatchAdminSettings( dispatch, data );

          return Promise.resolve();
        } )
        .catch( ( err ) => {
          return Promise.reject( err );
        } );
    } else {
      try {
        const data = await getAdminSettings();

        dispatchAdminSettings( dispatch, data );

        Promise.resolve();
      } catch ( err ) {
        return Promise.reject( err );
      }
    }
  };
};
