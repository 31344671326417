import React from 'react';
import {LabeledField} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledField';
import styled from 'styled-components';
import Storage from 'scr/utilitiesStorage';
import {GenericCabinet} from 'cSrc/structured/singletonCabinet/core/GenericCabinet';
import SimpleInput from 'c/CalculationLayer/utils/SimpleInput';
import CheckChangedValue from 'c/shared/borderForChangedValue';

const Degrees = styled.span`
  margin-left: 10px;
`;
const Wrap = styled.div`
  display: flex;
  align-items: center;
`;
const Description = styled.span`font-size: 10px;`;

const StyledLabeledField = styled(LabeledField)`
`;

class DoorsAngle extends React.Component {
  static defaultProps = {
    angle: 0
  };
  state = {
    newAngle: 0
  };

  componentDidMount() {
    const { angle } = this.props;
    this.setState({newAngle: angle});
  }
  componentDidUpdate(prevProps) {
    const { angle } = this.props;
    if (angle !== prevProps.angle) this.setState({newAngle: angle});
  }

  onChange = () => {
    const { newAngle } = this.state;
    const naturalNumber = String(newAngle);
    const value = Number(naturalNumber) % 91;

    const { onChangedValue } = this.props;
    if (onChangedValue) onChangedValue(value);
  }

  handleValueChange = (value) => {
    const checkRegExp = /^\d*$/;
    const newValue = String(Number(value));
    if (newValue.match(checkRegExp)) this.setState({newAngle: newValue});
    else if (!value) this.setState({newAngle: 0});
  }

  render() {
    const { newAngle } = this.state;

    return (
      <StyledLabeledField name='Doors Angle'>
        <Wrap>
          <SimpleInput
            className='door-angle-input'
            value={ String(newAngle) }
            onChange={ this.handleValueChange }
            onBlur={ this.onChange }
          />
          <Degrees>degrees</Degrees>
        </Wrap>
        <Description>possible values from 0 to 90 degrees</Description>
      </StyledLabeledField>
    );
  }
}

export default DoorsAngle;