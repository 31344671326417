// @ts-check
import {
  processUnitSetLabelAction,
  processUnitSetValueAction,
  processSetUnitsAction
} from '@r/reducers/utils';
import { red, AllActions } from '@r/storeUtils';
import { UnitsInputv2Value } from 'decl/general/html';
import { inches } from 'decl/general/units';


export const snapSensitivity: red<
  redux._IStore['projectFor3d']['snapSensitivity'],
  AllActions
> = ( state = {
  label: '1' as UnitsInputv2Value,
  value: 10 as inches
}, action ) => {
  switch( action.type ) {
    case 'SET_SNAP_SENSITIVITY_LABEL':
      return processUnitSetLabelAction( state, action );
    case 'SET_SNAP_SENSITIVITY_VALUE':
      return processUnitSetValueAction( state, action );
    case 'SET_UNITS':
      return processSetUnitsAction( state, action );
    default:
      return state;
  }
};

