import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { toeKickHeight } from './toeKickHeight';
import { topDrawerHeight } from './topDrawerHeight';
import { toeKickType } from './toeKickType';
import { cabinets } from './cabinets';

const dimensionsComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['settings']['dimensions'],
    AllActions
  >;

const dimensionsStandart = dimensionsComb( {
  cabinets,
  toeKickHeight,
  topDrawerHeight,
  toeKickType
} );

const defaultState = {
  cabinets: {
    base: {
      height: {
        label: '37',
        value: 37
      },
      depth: {
        label: '23.5',
        value: 23.5
      },
      shelvesQty: 1
    },
    upper: {
      height: {
        label: '36',
        value: 36
      },
      depth: {
        label: '11.75',
        value: 11.75
      },
      shelvesQty: 2
    },
    tall: {
      height: {
        label: '84',
        value: 84
      },
      depth: {
        label: '26',
        value: 26
      },
      shelvesQty: 4
    }
  },
  toeKickHeight: {
    label: '',
    value: 0
  },
  topDrawerHeight: {
    label: '',
    value: 0
  },
  toeKickType: 'toFloor'
};

export function dimensions( state = defaultState, action ) {
  switch ( action.type ) {
    case 'SET_CALCULATION_MODE_SETTINGS_DIMENSIONS':
      return action.value;
    default:
      return dimensionsStandart( state, action );
  }
}

export function setDefaultDimensions() {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        adminSettings: {
          projectSettings: {
            dimensions
          }
        }
      }
    } = getStore();

    dispatch( {
      type: 'SET_CALCULATION_MODE_SETTINGS_DIMENSIONS',
      value: dimensions
    } );
  };
}
