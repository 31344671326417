import { simpleSet } from '@r/reducers/utils';
import { percent } from '@npmrost/utils';

const setCalculationModeShippingCostManualValue = simpleSet as
  redux.reducers.calculationMode.projects.currentProject.settings.information.shippingCostManual.value;

export const value = setCalculationModeShippingCostManualValue(
  'SET_CALCULATION_MODE_CURRENT_PROJECT_SHIPPING_COST_MANUAL_VALUE',
  0 as percent
);
