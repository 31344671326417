/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-check
import Storage from 'scr/utilitiesStorage';
import { createGeometry } from 'c/ThreeJsWrap/Viewer/core/helpers/geometry';
import { BoxGeometry, Mesh } from 'three';
import { Container } from '../container';


export default class Panel extends Container {
  /** @type { THREE.Mesh & { userData: { _isPanelPartPreset: true }} } */
  mesh;

  constructor(
    /**
     * @type { Omit<import('decl/cabinetdesigner/ContainerConfigs').PanelConfig, 'type'> }
     */ { grainDirection, panelParent } ) {
    super();

    /*
     * Const cubeMaterials = [
     *   new THREE.MeshBasicMaterial({ color: 'red', side: THREE.DoubleSide }), // RIGHT SIDE
     *   new THREE.MeshBasicMaterial({ color: 'red', side: THREE.DoubleSide }), // LEFT SIDE
     *   new THREE.MeshBasicMaterial({ color: 'blue', side: THREE.DoubleSide }), // TOP SIDE
     *   new THREE.MeshBasicMaterial({ color: 'blue', side: THREE.DoubleSide }), // BOTTOM SIDE
     *   new THREE.MeshBasicMaterial({ color: 'green', side: THREE.DoubleSide }), // FRONT SIDE
     *   new THREE.MeshBasicMaterial({ color: 'green', side: THREE.DoubleSide }) // BACK SIDE
     * ];
     *console.log(Storage);
     */
    const { material } = Storage.get(
      'materials'
    ).cabinet[ 0 ];

    const geometry = new BoxGeometry( 1, 1, 1 );
    // @ts-ignore
    geometry.userData = { grainDirection };

    /** @type { Panel['mesh'] } */
    // @ts-ignore
    const cube = new Mesh( createGeometry( geometry ), material );
    cube.userData._isPanelPartPreset = true;

    this.origin.add( cube );
    this.mesh = cube;
    this.mesh.vestaObject.setType(
      /** @type { ThreeScene.Object3dTypes } */ ( `panel/${ panelParent }` )
    );
    // @ts-ignore
    this.mesh.receiveShadow = true;
    // @ts-ignore
    this.mesh.castShadow = true;

    cube.geometry.vertices.forEach( ( vertex ) => {
      if ( vertex.y === -0.5 ) vertex.y = 0;
    } );

    this.mesh.geometry.verticesNeedUpdate = true;
  }

  updateVerticies(
    /** @type { CabD.A.AxisNames } */ axis,
    /** @type { number } */ value
  ) {
    if ( value === 0 ) return;

    if ( axis === 'y' ) {
      this.mesh.geometry.vertices.forEach(
        ( vertex ) => vertex[ axis ] !== 0 && ( vertex[ axis ] = value )
      );
    } else {
      this.mesh.geometry.vertices.forEach(
        ( vertex ) => (
          vertex[ axis ] = ( value / 2 ) * Math.sign( vertex[ axis ] )
        )
      );
    }

    this.mesh.geometry.verticesNeedUpdate = true;
    // @ts-ignore
    this.mesh.geometry.computeFaceNormals();
    // @ts-ignore
    this.mesh.geometry.normalsNeedUpdate = true;
  }

  changeWidth( /** @type { number } */ value ) {
    if ( value === this.size.width || value === 0 ) return;

    for ( let i = 0; i <= 2; i += 1 ) {

      for ( let j = 4; j <= 7; j += 1 ) {
        // @ts-ignore
        this.mesh.geometry.faceVertexUvs[ 0 ][ j ][ i ].x *=
          value / this.size.width;
      }

      for ( let j = 8; j <= 11; j += 1 ) {
        // @ts-ignore
        this.mesh.geometry.faceVertexUvs[ 0 ][ j ][ i ].x *=
          value / this.size.width;
      }
    }

    // @ts-ignore
    this.mesh.geometry.uvsNeedUpdate = true;
    super.changeWidth( value );
    this.updateVerticies( 'x', value );


  }

  changeDepth( /** @type { number } */ value ) {
    if ( value === this.size.depth || value === 0 ) return;

    for ( let i = 0; i <= 2; i += 1 ) {
      for ( let j = 0; j <= 3; j += 1 ) {
        // @ts-ignore
        this.mesh.geometry.faceVertexUvs[ 0 ][ j ][ i ].x *=
          value / this.size.depth;
      }

      for ( let j = 4; j <= 7; j += 1 ) {
        // @ts-ignore
        this.mesh.geometry.faceVertexUvs[ 0 ][ j ][ i ].y *=
          value / this.size.depth;
      }
    }

    // @ts-ignore
    this.mesh.geometry.uvsNeedUpdate = true;
    super.changeDepth( value );

    this.updateVerticies( 'z', value );
  }

  changeHeight( /** @type { number } */ value ) {
    if ( value === this.size.height || value === 0 ) return;

    for ( let i = 0; i <= 2; i += 1 ) {
      for ( let j = 0; j <= 3; j += 1 ) {
        // @ts-ignore
        this.mesh.geometry.faceVertexUvs[ 0 ][ j ][ i ].y *=
          value / this.size.height;
      }

      for ( let j = 8; j <= 11; j += 1 ) {
        // @ts-ignore
        this.mesh.geometry.faceVertexUvs[ 0 ][ j ][ i ].y *=
          value / this.size.height;
      }
    }

    // @ts-ignore
    this.mesh.geometry.uvsNeedUpdate = true;
    super.changeHeight( value );

    this.updateVerticies( 'y', value );
  }
}
