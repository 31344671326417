import {
  Group,
  Box3,
  Vector3,
  Matrix4,
  Euler,
  Quaternion
} from 'three';

import Storage from 'scr/utilitiesStorage';
import { getFromGroup, getFromModel } from './helpers';
import { getTopLevelObject, isHoleableWithWall, getWall3D } from '../helpers/object3d';
import DoAddRemoveEntity from '../../UndoRedo/DoAddRemoveEntity';

export function addFreeItemToScene( source ) {
  let size = { width: 30, depth: 26, height: 45 };
  const item = new FreeAppliance( source, size );

  const viewer = Storage.get( 'viewer' );
  const defaultLocationBefore = viewer.scene.cloneDefaultLocation(); // for undo

  if ( !viewer.scene.addObjectDirectly( item ) ) return;
  if (isHoleableWithWall(item)) viewer.onceActionsMgr.registerRefreshWallHoles(getWall3D(item)); // refresh holes
  
  const defaultLocationAfter = viewer.scene.cloneDefaultLocation(); // for redo
  viewer.doMgr.registerDo( new DoAddRemoveEntity( item, true, null, { defaultLocationBefore, defaultLocationAfter } ) ); // register undo/redo
}

export default class FreeAppliance extends Group {
  constructor( source, dimensions ) {
    super();
    
    this.vestaObject.setType( 'item' );

    this.userData.source = source; // Object3D
    let rota = Math.PI / 2;
    this.userData.rotation = new Euler( rota, 0, 0 );
    this.userData.dimensions = { width: dimensions.width, height: dimensions.height, depth: dimensions.depth };
    this.userData.center = { x: 0, y: 0.5, z: 0 };
    this.userData.addBox = true;
    this.mountTypes = ['floor'];
    this.initialRotation = rota;
    this.isTopLevel = true;
    this.isBaseAppliance = true;
    this.isAppliance = true;
    this.init();
  }

  getSizes() {
    if ( this.userData.bBox ) {
      return {
        width: this.scale.x * ( this.userData.bBox.max.x - this.userData.bBox.min.x ),
        height: this.scale.z * ( this.userData.bBox.max.y - this.userData.bBox.min.y ),
        depth: this.scale.y * ( this.userData.bBox.max.z - this.userData.bBox.min.z )

      };
    }

    return {
      width: 1,
      depth: 1,
      height: 1
    };
  }

  initObject( obj ) {
    obj.rotation.copy( new Euler( 0, 0, 0 ) );
    this.rotation.copy( this.userData.rotation );
    this.add( obj );
    this.initParameters();

    obj.traverse( ( o ) => {
      if ( o.isMesh ) {
        o.castShadow = true;
        o.receiveShadow = true;
      }
    } );

    this.vestaObject.changeWidth( this.userData.dimensions.width );
    this.vestaObject.changeDepth( this.userData.dimensions.depth );
    this.vestaObject.changeHeight( this.userData.dimensions.height );

    if ( this.userData.matrix ) { // only when model
      new Matrix4().fromArray( this.userData.matrix ).decompose( this.position, this.quaternion, new Vector3() );
    }

    Storage.get( 'viewer' ).renderOnDemand.set();
  }

  init() {
    let group = this.userData.source;
    if ( this.userData.source.isScene ) {
      let children = [...this.userData.source.children];
      group = new Group();
      children.forEach( ( child ) => {
        group.add( child );
      } );
    }

    getFromGroup(
      group,
      this.userData.rotation,
      this.userData.center,
      this.userData.addBox )
      .then( ( obj ) => {
        this.initObject( obj );
      }
      );
  }

  initParameters() {
    this.isDragable = true;
    const parent = this.parent;
    this.parent = null;
    this.userData.bBox = new Box3().setFromObject( this );
    this.parent = parent;

    this.vestaObject.getSizes = () => {
      return {
        width: this.scale.x * ( this.userData.bBox.max.x - this.userData.bBox.min.x ),
        height: this.scale.y * ( this.userData.bBox.max.z - this.userData.bBox.min.z ),
        depth: this.scale.z * ( this.userData.bBox.max.y - this.userData.bBox.min.y )
      };
    };


    this.vestaObject.changeWidth = ( width ) => {
      if ( width > 0 ) {
        this.scale.x = width / ( this.userData.bBox.max.x - this.userData.bBox.min.x );
      }

    };
    this.vestaObject.changeHeight = ( height ) => {
      if ( height > 0 ) {
        this.scale.y = height / ( this.userData.bBox.max.z - this.userData.bBox.min.z );
      }

    };
    this.vestaObject.changeDepth = ( depth ) => {
      if ( depth > 0 ) {
        this.scale.z = depth / ( this.userData.bBox.max.y - this.userData.bBox.min.y );
      }
    };

    this.vestaObject.getDistanceToWallEnds = ( toPoint ) => {
      if ( !this.parent || ( toPoint !== 'center' && toPoint !== 'left' && toPoint !== 'right' ) ) {
        return null;
      }

      const snapping = this.vestaObject.updateSnapping( /** @type { inches } */( 1e6 ) );
      if ( snapping &&
          snapping.plusY &&
          snapping.plusY.object ) {
        const topLevelObject = getTopLevelObject( snapping.plusY.object );
        if ( topLevelObject.isWall &&
            Math.abs( snapping.plusY.distance ) < ( 0.1 + this.getSizes().height / 2 )
        ) {

          let leftCorrectionMergedWalls2D = 0;
          let rightCorrectionMergedWalls2D = 0;
          if ( topLevelObject.relatedWalls2D ) {
            const index = topLevelObject.relatedWalls2D.indexOf( topLevelObject );
            for ( let i = 0; i < index; i += 1 ) {
              leftCorrectionMergedWalls2D += topLevelObject.relatedWalls2D[ i ].width;
            }

            for ( let i = index + 1, l = topLevelObject.relatedWalls2D.length; i < l; i += 1 ) {
              rightCorrectionMergedWalls2D += topLevelObject.relatedWalls2D[ i ].width;
            }
          }

          if ( toPoint === 'center' ) {
            const localPos = this.position.clone()
              .applyMatrix4( this.parent.matrixWorld )
              .applyMatrix4(
                new Matrix4().getInverse( snapping.plusY.object.matrixWorld )
              );

            return ( {
              snapping,
              minusX: localPos.x + leftCorrectionMergedWalls2D,
              plusX: topLevelObject.width - localPos.x + rightCorrectionMergedWalls2D,
              minusY: null,
              plusY: null
            } );
          }
          if ( toPoint === 'left' ) {
            const localPos = ( this.position.clone().add( new Vector3( -this.getSizes().width / 2, 0, 0 ).applyMatrix4( new Matrix4().makeRotationFromEuler( this.rotation ) ) ) )
              .applyMatrix4( this.parent.matrixWorld )
              .applyMatrix4(
                new Matrix4().getInverse( snapping.plusY.object.matrixWorld )
              );

            return ( {
              snapping,
              minusX: localPos.x + leftCorrectionMergedWalls2D,
              plusX: topLevelObject.width - localPos.x + rightCorrectionMergedWalls2D,
              minusY: null,
              plusY: null
            } );
          }
          if ( toPoint === 'right' ) {
            const localPos = ( this.position.clone().add( new Vector3( this.getSizes().width / 2, 0, 0 ).applyMatrix4( new Matrix4().makeRotationFromEuler( this.rotation ) ) ) )
              .applyMatrix4( this.parent.matrixWorld )
              .applyMatrix4(
                new Matrix4().getInverse( snapping.plusY.object.matrixWorld )
              );

            return ( {
              snapping,
              minusX: localPos.x + leftCorrectionMergedWalls2D,
              plusX: topLevelObject.width - localPos.x + rightCorrectionMergedWalls2D,
              minusY: null,
              plusY: null
            } );
          }
        }

        return ( {
          minusX: null,
          plusX: null,
          minusY: null,
          plusY: null
        } );

      }

      return ( {
        minusX: null,
        plusX: null,
        minusY: null,
        plusY: null
      } );


    };

    this.vestaObject.setDistanceToWallEnd = ( side, toPoint, distance ) => {
      const distances = this.vestaObject.getDistanceToWallEnds( 'center' );
      if ( !this.parent || ( toPoint !== 'center' && toPoint !== 'left' && toPoint !== 'right' ) ) {
        return null;
      }

      if ( ( side !== 'left' && side !== 'right' ) ||
          distance < 0 ||
          !this.parent ||
          ( !distances.minusX || !distances.plusX ) ) {
        return;
      }

      const topLevelObject = getTopLevelObject( distances.snapping.plusY.object );
      if ( side === 'left' ) {
        let localPos = this.position.clone()
          .applyMatrix4( this.parent.matrixWorld ).applyMatrix4(
            new Matrix4()
              .getInverse( distances.snapping.plusY.object.matrixWorld )
          );
        if ( toPoint === 'center' ) {
          localPos.x += distance - distances.minusX;
        }
        if ( toPoint === 'left' ) {
          localPos.x += distance - distances.minusX + this.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          localPos.x += distance - distances.minusX - this.getSizes().width / 2;
        }

        this.position.copy( localPos.clone()
          .applyMatrix4( distances.snapping.plusY.object.matrixWorld )
          .applyMatrix4( new Matrix4()
            .getInverse( this.parent.matrixWorld ) ) );
      }

      if ( side === 'right' ) {
        let localPos = this.position.clone()
          .applyMatrix4( this.parent.matrixWorld ).applyMatrix4(
            new Matrix4()
              .getInverse( distances.snapping.plusY.object.matrixWorld )
          );
        if ( toPoint === 'center' ) {
          localPos.x += -distance + distances.plusX;
        }
        if ( toPoint === 'left' ) {
          localPos.x += -distance + distances.plusX + this.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          localPos.x += -distance + distances.plusX - this.getSizes().width / 2;
        }

        this.position.copy( localPos.clone()
          .applyMatrix4( distances.snapping.plusY.object.matrixWorld )
          .applyMatrix4( new Matrix4()
            .getInverse( this.parent.matrixWorld ) ) );
      }
    };

    this.vestaObject.getDistanceToWalls = ( toPoint ) => {
      if ( ['center', 'edge'].indexOf( toPoint ) === -1 ) {
        return;
      }

      const result = {};
      const intersectionObjects = [];
      Storage.get( 'viewer' ).scene.traverseVisible( ( obj ) => {
        if ( obj.isWall ) {
          intersectionObjects.push( obj.mesh );
        }
      } );

      const snapping = this.vestaObject.updateSnapping( /** @type { inches } */( 1e6 ), intersectionObjects );
      result.snapping = snapping;
      if ( snapping ) {
        if ( snapping.plusX &&
            snapping.plusX.object ) {
          const topLevelObject = getTopLevelObject( snapping.plusX.object );
          if ( topLevelObject.isWall ) {
            result.plusX = Math.abs( snapping.plusX.distance ) - this.getSizes().width / 2;
          }
        }
        if ( snapping.minusX &&
            snapping.minusX.object ) {
          const topLevelObject = getTopLevelObject( snapping.minusX.object );
          if ( topLevelObject.isWall ) {
            result.minusX = Math.abs( snapping.minusX.distance ) - this.getSizes().width / 2;
          }
        }
        if ( snapping.plusY &&
            snapping.plusY.object ) {
          const topLevelObject = getTopLevelObject( snapping.plusY.object );
          if ( topLevelObject.isWall ) {
            result.plusY = Math.abs( snapping.plusY.distance ) - this.getSizes().height / 2;
          }
        }
        if ( snapping.minusY &&
            snapping.minusY.object ) {
          const topLevelObject = getTopLevelObject( snapping.minusY.object );
          if ( topLevelObject.isWall ) {
            result.minusY = Math.abs( snapping.minusY.distance ) - this.getSizes().height / 2;
          }
        }
      }

      if ( toPoint === 'center' ) {
        result.plusX += this.getSizes().width / 2;
        result.minusX += this.getSizes().width / 2;
        result.plusY += this.getSizes().height / 2;
        result.minusY += this.getSizes().height / 2;
      }

      return result;
    };

    this.vestaObject.setDistanceToWall = ( side, toPoint, distance ) => {

      const distances = this.vestaObject.getDistanceToWalls( 'edge' );
      if ( ['left', 'right', 'top', 'bottom'].indexOf( side ) === -1 ||
          distance < 0 ||
          !this.parent ||
          ( ( !distances.minusX && distances.minusX !== 0 ) ||
          ( !distances.plusX && distances.plusX !== 0 ) ||
          ( !distances.minusY && distances.minusY !== 0 ) ||
          ( !distances.plusY && distances.plusY !== 0 ) ) ) {

        return;

      }

      if ( !this.parent || (
        toPoint !== 'center' &&
          toPoint !== 'left' &&
          toPoint !== 'right' &&
          toPoint !== 'top' &&
          toPoint !== 'bottom'
      ) ) {
        return null;
      }

      let p = new Vector3();
      let q = new Quaternion();
      let s = new Vector3();
      this.matrixWorld.decompose( p, q, s );

      let localPos = this.position.clone()
        .applyMatrix4( this.parent.matrixWorld ).applyMatrix4(
          new Matrix4()
            .getInverse( new Matrix4().compose( p, q, new Vector3( 1, 1, 1 ) ) )
        );

      if ( side === 'left' ) {
        if ( toPoint === 'left' ) {
          localPos.x += distance - distances.minusX;
        }
        if ( toPoint === 'center' ) {
          localPos.x += distance - distances.minusX - this.vestaObject.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          localPos.x += distance - distances.minusX - this.vestaObject.getSizes().width;
        }
      }
      if ( side === 'right' ) {
        if ( toPoint === 'left' ) {
          localPos.x += -distance + distances.plusX + this.vestaObject.getSizes().width;
        }
        if ( toPoint === 'center' ) {
          localPos.x += -distance + distances.plusX + this.vestaObject.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          localPos.x += -distance + distances.plusX;
        }
      }
      if ( side === 'top' ) {
        if ( toPoint === 'bottom' ) {
          localPos.z += -distance + distances.minusY + this.vestaObject.getSizes().depth;
        }
        if ( toPoint === 'center' ) {
          localPos.z += -distance + distances.minusY + this.vestaObject.getSizes().depth / 2;
        }
        if ( toPoint === 'top' ) {
          localPos.z += -distance + distances.minusY;
        }
      }
      if ( side === 'bottom' ) {
        if ( toPoint === 'bottom' ) {
          localPos.z += distance - distances.plusY;
        }
        if ( toPoint === 'center' ) {
          localPos.z += distance - distances.plusY - this.vestaObject.getSizes().depth / 2;
        }
        if ( toPoint === 'top' ) {
          localPos.z += distance - distances.plusY - this.vestaObject.getSizes().depth;
        }
      }

      this.position.copy( localPos.clone()
        .applyMatrix4( new Matrix4().compose( p, q, new Vector3( 1, 1, 1 ) ) )
        .applyMatrix4( new Matrix4()
          .getInverse( this.parent.matrixWorld ) ) );

    };

    this.getType = () => {
      return 'baseAppliance';
    };
  }
}

