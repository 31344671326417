import feathersClient from 'scr/getFeathers';

export async function getCabinets (lang) {
  try {
    return feathersClient.service('api/cabinets').find({
      query: {
        $limit: -1,
        $sort: {
          [`${lang}.name`]: 1
        }
      }
    });
  } catch (error) {
    throw error;
  }
}
