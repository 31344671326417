// eslint-disable-next-line import/no-cycle
import Appliance from './Appliance';
// eslint-disable-next-line import/no-cycle
import WallAppliance from './WallAppliance';
// eslint-disable-next-line import/no-cycle
import TallAppliance from './TallAppliance';
// eslint-disable-next-line import/no-cycle
import BaseAppliance from './BaseAppliance';
// eslint-disable-next-line import/no-cycle
import BaseApplianceWithCountertop from './BaseApplianceWithCountertop';


export {
  Appliance,
  WallAppliance,
  TallAppliance,
  BaseAppliance,
  BaseApplianceWithCountertop
};
/* export function addSimpleWindow() {
  // materials correction
  function correction( result ) {
    result.traverse( ( o ) => {
      if ( o.isMesh ) {
        o.castShadow = true;
        o.receiveShadow = true;
      }
    } );
  }

  return new WallAppliance(
    Storage.get( 'assets' ).inWallItems[ 0 ].data,
    new Euler( 0, 0, 0 ),
    { width: 30, depth: 2, height: 30 },
    { x: 0, y: 0, z: 0.5 },
    true,
    correction );
}

export function addHood() {
  // materials correction
  function correction( result ) {
    result.traverse( ( o ) => {
      if ( o.isMesh ) {
        o.castShadow = true;
        o.receiveShadow = true;
      }
    } );
  }

  return new WallAppliance(
    Storage.get( 'assets' ).appliances[ 4 ].data,
    new Euler( 0, 0, 0 ),
    { width: 30, depth: 30, height: 30 },
    { x: 0, y: 0, z: 0.5 },
    true,
    correction );
}

export function addKitchenFridge() {
  // materials correction
  function correction( result ) {
    result.traverse( ( o ) => {
      if ( o.isMesh ) {
        o.castShadow = true;
        o.receiveShadow = true;
      }
    } );
  }

  return new TallAppliance(
    Storage.get( 'assets' ).appliances[ 2 ].data,
    new Euler( Math.PI / 2, 0, 0 ),
    { width: 36, height: 70, depth: 30 },
    { x: 0, y: 0.5, z: 0 },
    true,
    correction
  );

}

export function addDishWasherGDWT368VSS() {
  // materials correction
  function correction( result ) {
    result.traverse( ( o ) => {
      if ( o.isMesh ) {
        o.castShadow = true;
        o.receiveShadow = true;
      }
    } );
  }

  return new BaseApplianceWithCountertop(
    Storage.get( 'assets' ).appliances[ 1 ].data,
    new Euler( Math.PI / 2, 0, 0 ),
    { width: 24, depth: 25, height: 36 },
    { x: 0, y: 0.5, z: 0 },
    true,
    correction );
}

export function addKitchenStoveGas() {

  // materials correction
  function correction( result ) {
    result.traverse( ( o ) => {
      if ( o.isMesh ) {
        o.castShadow = true;
        o.receiveShadow = true;

        // o.material.envMap = this.viewer.cubeCamera.renderTarget.texture;
        // o.material.envMapIntensity = 0.04;
        o.material.roughness = 0.2;
        o.material.roughnessMap = null;
        o.material.metalness = 0.8;
        o.material.emissive = new Color( 0.02, 0.02, 0.02 );
        o.material.color = new Color( 1, 1, 1 );


      }
    } );
  }

  return new BaseAppliance(
    Storage.get( 'assets' ).appliances[ 3 ].data,
    new Euler( Math.PI / 2, 0, 0 ),
    { width: 30, height: 45, depth: 26 },
    { x: 0, y: 0.5, z: 0 },
    true,
    correction
  );
} */
