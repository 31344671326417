import {
  Scene, Vector3, Vector4, Matrix4
} from 'three';
import Storage from 'scr/utilitiesStorage';
import SnappingDetector from '../../SnappingDetector';
import DoSetEntityMaterial from '../../UndoRedo/DoSetEntityMaterial';

export const isFloorMounted = (object3d) => {
  return object3d.mountTypes && object3d.mountTypes.indexOf('floor') > -1;
};
export const isWallMounted = (object3d) => {
  return object3d.mountTypes && object3d.mountTypes.indexOf('wall') > -1;
};
export const isCabinet = (object3d) => {
  return object3d &&
    !object3d.isWall &&
    !object3d.isFloor &&
    !object3d.isWall3D &&
    !object3d.isCorner &&
    !object3d.isStage &&
    !object3d.isFloorplan &&
    (object3d.isTopLevel || object3d._isCabinet);
};

export const isHoleableWithWall = (object3d) => {
  if (isWallMounted(object3d)) {
    if (object3d.isWallWindow) return true;
    switch (object3d.vestaObject.getType()) {
      case "item/window":
      // case "item/door":
      // ...
        return true;
    }
  }
  return false;
};

export const getWall3D = (object3d) => {
  let obj = object3d;
  while (obj) {
    if (obj.isWall3D) {
      return obj;
    }
    obj = obj.parent;
  }
  return null;
};

export const getTopLevelObject = (object3d) => {
  let obj = object3d;
  while (obj) {
    if ((obj.isTopLevel || obj._isCabinet) || obj.parent instanceof Scene) {
      return obj;
    }
    obj = obj.parent;
  }
};

export const getClosestLevelObject = (object3d) => {
  let obj = object3d;
  while (obj) {
    if (obj.isCountertop || obj.isCrownMolding || obj.isTopLevel || obj._isCabinet || obj.parent instanceof Scene) {
      return obj;
    }
    obj = obj.parent;
  }
};

export const getRelatedObjects = (object3d) => {
  if (object3d.isCountertop) {
    return object3d.getMergedCountertops();
  }
  if (object3d.isWall && object3d.relatedWalls2D) {
    return object3d.relatedWalls2D;
  }
  return [object3d];
};

export const toDoSetMaterialByName = (vestaObject, material, part, doMgr) => {
  const materialBefore = vestaObject.getMaterialNameOfPart(part); // for undo
  vestaObject.setMaterialByName({[part]: material}); // do
  doMgr.registerDo(new DoSetEntityMaterial(vestaObject, part, {materialBefore, materialAfter: material})); // register undo/redo
};

export const toDoSetMaterialsByName = (entities, material, part, doMgr) => {
  doMgr.beginDo(); // begin to register group of undo/redos
  for (let i = 0; i < entities.length; i += 1) {
    const vestaObject = entities[i].vestaObject;
    toDoSetMaterialByName(vestaObject, material, part, doMgr);
  }
  doMgr.endDo(); // end
};