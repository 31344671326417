export const regExForNumber = /^(\-)?\d+\.?\d{0,2}$/;

export function replaceValue( value: string ) {
  const num = Number( value );
  // eslint-disable-next-line no-bitwise
  if( !( ( num ^ 0 ) === num ) ) {
    return String( Math.round( ( num ) * 100 ) / 100 );
  }

  return value;
}

export function replaceValueWithDot( value: string ) {
  let newValue = `${ Number( value ) }`;
  if( value[ value.length - 1 ] === '.' ) {
    newValue = `${ newValue }.`;
  }
  if( value[ 0 ] === '-' && newValue[ 0 ] !== '-' ) {
    newValue = `-${ newValue }`;
  }

  return newValue;
}

export function checkValue( value: string, oldValue: string ) {
  let check = false;
  const indexOf = value.indexOf( oldValue );

  if( !( indexOf === 0 ) ) {
    check = true;
  }

  return check;
}
