import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  position: relative;
  height: 25%;
  display: flex;
  padding: 4px;
  .img, .hoveredImg {
    width: 25px;
    height: auto;
    margin-left: 4px;
  }
  .hoveredImg { display: none; }
  .forHover {
    display: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.39);
    width: 100px;
    left: 100%;
    border-radius: 0 5px 5px 0;
  }
  &:hover {
    .hoveredImg { display: inline; }
    .img { display: none; }
    .forHover { display: flex; }
  }
`;

interface _IProps {
  img: string;
  hoveredImg: string;
  elementForHover: JSX.Element;
  onClick: ( arg: React.MouseEvent<HTMLDivElement> ) => void;
}


export class HoverableImage extends PureComponent< _IProps > {
  render() {
    const {
      img, hoveredImg, elementForHover, onClick
    } = this.props;

    return (
      <Wrap onClick={ onClick }>
        <img className='img' src={ img } alt='' />
        <img className='hoveredImg' src={ hoveredImg } alt='' />
        <div className='forHover'>{ elementForHover }</div>
      </Wrap>
    );
  }
}
