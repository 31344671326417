import React from 'react';
import { connect } from 'react-redux';
import { UnitsInputv2 } from 'c/CalculationLayer/utils/UnitsInputv2';
import { unitMapping as uM } from 'scr/metricsConversion';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import modalStyl from 'c/CalculationLayer/Modal/index.styl';
import UnitsInputWithUnits from 'c/CalculationLayer/utils/UnitsInputWithUnits';
import Storage from 'scr/utilitiesStorage';
import { batchActions, BatchAction } from 'redux-batched-actions';

const b = batchActions as redux.batchActionsWithTempl<
  redux.actions['setSnapSensitivityLabel'] |
  redux.actions['setSnapSensitivityValue']
>;

const Connected = connect(
  ( { projectFor3d: { units, snapSensitivity } }: redux.store ) => ( {
    value: snapSensitivity.label,
    units,
    className: `${ modalStyl.commonInput } wide` as className<'input'>
  } ),
  {}
)( UnitsInputv2 );


 interface _IProps {
  units: redux.store['projectFor3d']['units'];
  dispatch: redux.d< BatchAction >;
 }

interface _IStateProps {
  units: redux.store['projectFor3d']['units'];
}

export default connect<_IStateProps, never, never, redux.store>(
  ( s ) => ( { units: s.projectFor3d.units } )
)(
  class SnapSensitivityControl extends React.PureComponent<_IProps> {
    onChange = ( value: UnitsInputv2Value ) => {
      const { units, dispatch } = this.props;
      let val = units === 'ftAndInch'
        ? uM.ftAndInch( value )
        : uM[ units ]( Number( value ) );

      Storage.set( 'snappingTolerance', val );
      dispatch(
        b( [
          { type: 'SET_SNAP_SENSITIVITY_VALUE', value: val },
          { type: 'SET_SNAP_SENSITIVITY_LABEL', value }
        ] )
      );
    }

    render() {
      return (
        <LabeledField
          className={
            `${ modalStyl.commonLabeledField } shortLabel wider fontSize14px`
          }
          name='Snap sensitivity'
        >
          <UnitsInputWithUnits units={ this.props.units }>
            <Connected onChange={ this.onChange } />
          </UnitsInputWithUnits>
        </LabeledField>
      );
    }
  }
);
