import { combineReducers } from 'redux';

import { combRed, AllActions } from '@r/storeUtils';

import { dimensions } from './dimensions';
import { materials } from './materials';
import { percentageLoss } from './percentageLoss';
import { shippingCost } from './shippingCost';

const settingsComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['adminSettings']['projectSettings'],
    AllActions
  >;

const settingsStandart = settingsComb( {
  dimensions,
  materials,
  percentageLoss,
  shippingCost
} );

const defaultState = {
  dimensions: {},
  materials: {},
  percentageLoss: {
    data: [],
    counter: 0,
    keys: []
  },
  shippingCost: []
};

export function projectSettings( state = defaultState, action ) {
  switch ( action.type ) {
    case 'SET_CALCULATION_MODE_ADMIN_SETTINGS_PROJECT_SETTINGS':
      return action.value;
    default:
      return settingsStandart( state, action );
  }
}
