import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  LabeledReactSelectSingle
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledReactSelectSingle';
import {
  ReactSelectSingle
} from '@npmrost/storybook/stories/Vesta/Choice/ReactSelectSingle';
import { changeValue } from '../../../utils';

class MaterialsSets extends PureComponent {
  render() {

    const {
      props: {
        dispatch,
        dataKey,
        setName,
        grainDirectionOptionsText,
        materialsSets,
        materialsSetKey,
        labeled
      }
    } = this;

    const grainDirectionOptionsArr = ['vertical', 'horizontal'];

    const grainDirectionOptions =
      grainDirectionOptionsText.map( ( item, index ) => ( {
        label: item,
        value: grainDirectionOptionsArr[ index ]
      } ) );

    const setsOptions = materialsSets.data.map( ( item, index ) => {
      const {
        core: { name: coreName, costPerSqFt: coreCost },
        doorsDrawers: { name: doorsDrawersName, costPerSqFt: doorsDrawersCost },
        doorGrainDirection,
        drawerGrainDirection,
        edgebanding: { name: edgebandingName, costPerFt: edgebandingCost }
      } = item;

      const doorGrainDirectionLabel = grainDirectionOptions
        .find( ( item ) => item.value === doorGrainDirection ).label;
      const drawerGrainDirectionLabel = grainDirectionOptions
        .find( ( item ) => item.value === drawerGrainDirection ).label;

      return {
        label: `${ setName } ${ index + 1 }: ${ coreName } ($${ coreCost }), ${ doorsDrawersName } ($${ doorsDrawersCost }), ${ doorGrainDirectionLabel }, ${ drawerGrainDirectionLabel }, ${ edgebandingName } ($${ edgebandingCost })`,
        value: materialsSets.keys[ index ]
      };
    } );

    const setsChosen = setsOptions[ materialsSets.keys.indexOf( materialsSetKey ) ];

    return (
      <>
        {
          labeled ? (
            <LabeledReactSelectSingle
              name={ setName }
              options={ setsOptions }
              chosen={ [setsChosen] }
              choose={ changeValue( dispatch, dataKey, 'materialsSetKey', 'setsSelect' ) }
            />
          ) : (
            <ReactSelectSingle
              options={ setsOptions }
              chosen={ [setsChosen] }
              choose={ changeValue( dispatch, dataKey, 'materialsSetKey', 'setsSelect' ) }
            />
          )
        }
      </>

    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        materials: {
          setName,
          grainDirectionOptions: grainDirectionOptionsText
        }
      }
    },
    calculationMode: {
      projects: {
        currentProject: {
          settings: {
            materials: {
              materialsSets
            }
          }
        }
      }
    }
  } ) => {
    return {
      setName,
      grainDirectionOptionsText,
      materialsSets
    };
  }
)( MaterialsSets );
