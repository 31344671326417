import { simpleSet } from '@r/reducers/utils';
import { inches } from '@npmrost/utils';

const setCalculationModeAdminSettingsTopDrawerHeightValue = simpleSet as
  redux.reducers.calculationMode.adminSettings.projectSettings.dimensions.topDrawerHeight.value;

export const value = setCalculationModeAdminSettingsTopDrawerHeightValue(
  'SET_CALCULATION_MODE_ADMIN_SETTINGS_TOP_DRAWER_HEIGHT_VALUE',
  0 as inches
);
