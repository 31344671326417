/* eslint-disable no-return-assign */
import React, { Fragment as F, PureComponent } from 'react';
import Storage, { isNullable } from 'scr/utilitiesStorage';
import {
  // eslint-disable-next-line no-unused-vars
  GenericCabinet
} from 'cSrc/structured/singletonCabinet/core/GenericCabinet';
import { Object3dTypes, _IObject3D } from 'decl/ThreeScene';
import styled from 'styled-components';
import Position from 'c/CabinetRightSideMenu/CabinetMenuWidgets/Position';
import style from './index.styl';
import Materials from './Materials';
import { Settings } from './Settings';
import { Dimensions } from './Dimensions';
import Shapes from './Shapes';
import GrainDirection from './GrainDirection';

interface _ITab {
  name:
    'position' |
    'dimensions' |
    'materials' |
    'shapes' |
    'settings' |
    'grainDirection';
  uiName: string;
  type: Array<Object3dTypes>;
  comp:
    typeof Position |
    typeof Dimensions |
    typeof Materials |
    typeof Shapes |
    typeof Settings |
    typeof GrainDirection;
}

interface _IChooseTabArg extends React.MouseEvent<HTMLDivElement> {
  currentTarget: React.MouseEvent<HTMLDivElement>['currentTarget'] & {
    dataset: {
      name: _ITab['name'];
      type: string;
    };
  };
}

const DimensionWrap = styled.div`
  background-color: #F8F8F8;
  opacity: 0.8;
  border-radius: 9px;
  padding: 10px;
  margin-top: 10px;
`;

const PositionWrap = styled.div`
  background-color: #F8F8F8;
  opacity: 0.8;
  border-radius: 9px;
  padding: 0 10px;
  margin-top: 10px;
`;

class Info extends PureComponent<
  { tabs: _ITab[]; hidden: boolean },
  { tabName: _ITab['name'] }
> {
  static defaultProps: Info['props'] = {
    hidden: true,
    tabs: [
      // {
      //   name: 'name',
      //   uiName: 'Name',
      //   type: [
      //     'cabinet',
      //     'cabinet/withCountetop',
      //     'floor',
      //     'wall',
      //     'countertop',
      //     'item'
      //   ],
      //   comp: Name
      // },
      {
        name: 'dimensions',
        uiName: 'Dimensions',
        comp: Dimensions,
        type: [
          'cabinet',
          'cabinet/withCountetop',
          'item',
          'item/window'
        ]
      },
      {
        name: 'position',
        uiName: 'Position',
        comp: Position,
        type: [
          'cabinet',
          'cabinet/withCountetop',
          'item',
          'item/window'
        ]
      },
      {
        name: 'settings',
        uiName: 'Settings',
        comp: Settings,
        type: ['crownMolding']
      },
      {
        name: 'grainDirection',
        uiName: 'Grain direction',
        comp: GrainDirection,
        type: [
          'wall',
          'floor'
        ]
      },
      {
        name: 'shapes',
        uiName: 'Shapes',
        comp: Shapes,
        type: [
          'crownMolding'
        ]
      },
      {
        name: 'materials',
        uiName: 'Materials',
        comp: Materials,
        type: [
          'wall',
          'floor',
          'cabinet',
          'cabinet/withCountetop',
          'countertop',
          'crownMolding'
        ]
      }
    ]
  };

  object3d: _IObject3D[] | GenericCabinet[];

  constructor ( p: Info['props'] ) {
    super( p );

    const object3d = Storage.get( 'selectedObject' );
    if( isNullable( object3d ) ) {
      throw new Error( 'Selected object is undefined.' );
    }

    this.object3d = object3d;

    this.state = {
      tabName: p.tabs.filter(
        ( it ) => it.type.indexOf(
          this.object3d[ 0 ].vestaObject.getType()
        ) !== -1
      )[ 0 ].name
    };


  }

  chooseTab = ( {
    currentTarget: { dataset: { name: tabName, type } }
  }: _IChooseTabArg ) => {
    if( type === undefined ) return;

    const { object3d } = this;
    /*
     * If type of the clicked div is not equal to the type of
     * the selected entity -> bail out
     */
    if( !type.match( object3d[ 0 ].vestaObject.getType() ) ) {
      return;
    }

    this.setState( { tabName } );
  }

  render () {
    const {
      state: { tabName: chosenTab },
      props: { tabs, hidden },
      object3d
    } = this;

    const styleWrap = hidden ? { style: { display: 'none' } } : {};

    if( object3d === null ) {
      throw new Error( 'Selected object is undefined.' );
    }

    const type = object3d[ 0 ].vestaObject.getType();

    return (
      <div { ...styleWrap } className={ style.infoBlock }>
        <div className='infoTabs'>
          {
            tabs.map( ( it ) => {
              if(
                ( type === 'crownMolding' &&
                ( it.name === 'dimensions' || it.name === 'position' ) ) ||
                ( type !== 'crownMolding' &&
                ( it.name === 'shapes' || it.name === 'settings' ) ) ||
                ( ( type === 'wall' || type === 'floor' ) &&
                  ( it.name === 'dimensions' ) ) ||
                ( ( type !== 'wall' && type !== 'floor' ) &&
                it.name === 'grainDirection' )
              ) {
                return null;
              }

              return (
                <F key={ it.name }>
                  <div
                    onClick={ this.chooseTab }
                    data-type={ it.type }
                    data-name={ it.name }
                    className={ `infoTab ${ it.name } ${
                      chosenTab === it.name
                        ? 'chosen' : '' }` +
                  `${ it.type.indexOf( type ) === -1 ? ' dis' : '' }` }
                  >
                    <img src={ `/img/info/${ it.name }.png` } alt='' />
                    <span className={ chosenTab === it.name ? 'chosen' : '' }>
                      {it.uiName}
                    </span>
                  </div>
                </F>
              );
            } )
          }
        </div>
        {
          !( type === 'crownMolding' ) && chosenTab === 'dimensions' && (
            <DimensionWrap>
              <Dimensions className='forObject3dPopUp' />
            </DimensionWrap>
          )
        }
        { chosenTab === 'materials' && <Materials forRightSide={ false } /> }
        { !( type === 'crownMolding' ) && chosenTab === 'position' &&
        (
          <PositionWrap>
            <Position />
          </PositionWrap>
        ) }
        { ( type === 'wall' || type === 'floor' ) &&
        chosenTab === 'grainDirection' &&
          <GrainDirection />}
        { type === 'crownMolding' && chosenTab === 'shapes' && <Shapes /> }
        { type === 'crownMolding' && chosenTab === 'settings' && <Settings /> }
      </div>
    );
  }
}

export default Info;
