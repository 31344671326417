import DoAction from "./DoAction";

export default class DoSetEntityMatrix extends DoAction {
    constructor(entity, before, after) {
        super();

        this.entity = entity;
        this.before = before;
        this.after = after;
    }
	redo() {
        this.do(this.after);
	}
	undo() {
        this.do(this.before);
    }
    do(matrix) {
        // this.entity.matrix.copy(matrix);
        matrix.decompose(this.entity.position, this.entity.quaternion, this.entity.scale);
        
        this.viewer.renderOnDemand.set();
    }
}