import {
  processUnitSetLabelAction,
  processUnitSetValueAction,
  processSetUnitsAction
} from '@r/reducers/utils';
import { inches, quantity } from 'decl/general/units';
import { AllActions } from '@r/storeUtils';
import { UnitsInputValue } from '@npmrost/storybook/stories/Vesta/Inputs/UnitsInput';

type base =
  redux._IStore['calculationMode']['settings']['dimensions']['cabinets']['base'];

const baseDefault: base = {
  height: {
    value: 37 as inches,
    label: '37' as UnitsInputValue
  },
  depth: {
    value: 23.5 as inches,
    label: '23.5' as UnitsInputValue
  },
  shelvesQty: 1 as quantity
};

export const base = (
  state = baseDefault,
  action: AllActions
): typeof state => {
  switch( action.type ) {
    case 'SET_CALCULATION_MODE_BASE_CABINET_HEIGHT_LABEL': {
      return {
        ...state,
        height: processUnitSetLabelAction( state.height, action )
      };
    }
    case 'SET_CALCULATION_MODE_BASE_CABINET_HEIGHT_VALUE': {
      return {
        ...state,
        height: processUnitSetValueAction( state.height, action )
      };
    }
    case 'SET_CALCULATION_MODE_BASE_CABINET_DEPTH_LABEL': {
      return {
        ...state,
        depth: processUnitSetLabelAction( state.depth, action )
      };
    }
    case 'SET_CALCULATION_MODE_BASE_CABINET_DEPTH_VALUE': {
      return {
        ...state,
        depth: processUnitSetValueAction( state.depth, action )
      };
    }
    case 'SET_CALCULATION_MODE_BASE_CABINET_SHELVES_QTY': {
      return {
        ...state,
        shelvesQty: action.value
      };
    }
    case 'SET_CALCULATION_MODE_UNITS': {
      return {
        ...state,
        height: processSetUnitsAction( state.height, action ),
        depth: processSetUnitsAction( state.depth, action )
      };
    }
    default:
      return state;
  }
};
