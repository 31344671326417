/*
type mountTypes = 'wall' | 'floor';

export interface _ISnapable {
  isSnapable : bool;
  snap() {};
  unSnap() {};
}

export interface _ISizable {
  isSizable : bool;
 getSizes() : {width : number, height : number, depth : number}
 changeWidth(width : number);
 changeWidth(width : number);
 changeWidth(width : number);
}

export interface _ICollidable {
  isCollidable : bool;
}

export interface _IDragable {
  isDragable : bool;
  mountTypes : mountTypes[];
  addTo(obj : _IDropable) {};
}

export interface _IDropable {
  isDropable : bool;
  mountSlotTypes : mountTypes[];
} */


import {
  Group,
  Box3,
  Vector3,
  Mesh,
  BoxGeometry
} from 'three';
import Storage from 'scr/utilitiesStorage';
import GLTFLoader from '../loaders/GLTFLoader';
import DRACOLoader from '../loaders/DRACOLoader';

const getFromGroup = (source, rotation, center, addBox = true, correctOrigin = true) => {
  return new Promise((resolve, reject) => {

    if (source.parent) {
      source.parent.remove(source);
    }

    const bBox = new Box3().setFromObject(source);
    source.rotation.copy(rotation);


    const centerBoundingBox = new Vector3();
    bBox.getCenter(centerBoundingBox);
    let displacement = centerBoundingBox.add(
      new Vector3(-(bBox.max.x - bBox.min.x) * center.x, -(bBox.max.y - bBox.min.y) * center.y, -(bBox.max.z - bBox.min.z) * center.z));


    for (let i = 0; i < source.children.length; i += 1) {
      if (correctOrigin) {
        source.children[i].position.sub(displacement);
      }
    }


    if (addBox) {

      const bBoxMesh = new Mesh(new BoxGeometry(
        bBox.max.x - bBox.min.x,
        bBox.max.y - bBox.min.y,
        bBox.max.z - bBox.min.z
      ), Storage.get('namesToMaterials').service.invisible);


      bBoxMesh.isCollidable = true;
      bBoxMesh.isSnapable = true;

      source.add(bBoxMesh);
      if (correctOrigin) {
        bBoxMesh.position.set(
          (bBox.max.x - bBox.min.x) * center.x,
          (bBox.max.y - bBox.min.y) * center.y,
          (bBox.max.z - bBox.min.z) * center.z
        );
      }
    }


    // source.scale.copy(scale);

    resolve(source);

  });
};

const getFromModel = (source, rotation, center, addBox = true, correctOrigin = true) => {
  const gltfLoader = new GLTFLoader();
  const dracoLoader = new DRACOLoader();
  dracoLoader.setDecoderPath('/assets');
  gltfLoader.setDRACOLoader(dracoLoader);

  return new Promise((resolve, rej) => {
    gltfLoader.parse(
      source,
      './',
      (obj) => {
        const group = new Group();
        const len = obj.scene.children.length;
        for (let i = 0; i < len; i += 1) {
          group.add(obj.scene.children[0]);
        }

        resolve(getFromGroup(group, rotation, center, addBox, correctOrigin));
      },
      rej);
  });
};

export { getFromGroup, getFromModel };


