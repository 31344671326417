import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  LabeledTreeViewSelect
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledTreeViewSelect';
import {
  getItems
} from '@npmrost/storybook/stories/Vesta/Choice/TreeViewSelect';
import {
  updateCabinet
} from '@r/reducers/storeParts/calculationMode/projects/currentProject/cabinets';
import { changeValue, search } from '../../../utils';

class Part extends PureComponent {

  cancel = () => {
    const {
      dispatch,
      dataKey,
      propName,
      data: {
        options
      },
      countertopNone
    } = this.props;

    dispatch( updateCabinet( dataKey, propName, {
      options,
      value: { label: countertopNone, value: '' },
      searchText: ''
    } ) );
  }

  render() {

    const {
      props: {
        dispatch,
        dataKey,
        labelName,
        propName,
        data: { options, value, searchText },
        searchPlaceholder,
        isCanceled
      },
      cancel
    } = this;

    return (
      <LabeledTreeViewSelect
        name={ labelName }
        placeholder={ searchPlaceholder }
        items={ getItems( options, searchText ) }
        chosen={ value }
        choose={
          changeValue( dispatch, dataKey, propName, 'treeViewSelect' )
        }
        searchText={ searchText }
        setSearchText={ search( dispatch, dataKey, propName ) }
        isCanceled={ isCanceled }
        cancel={ cancel }
      />
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        search: searchPlaceholder,
        materials: { countertopNone }
      }
    }
  } ) => {
    return {
      searchPlaceholder,
      countertopNone
    };
  }
)( Part );
