import {Step, BaseCmd} from "./BaseCmd";
import * as THREE from 'three';
import { SnapFlag } from "../viewer/Viewport";
import { DrawSnap } from "../common";

class DraftCmd extends BaseCmd {
    drawSnap(ctx) {
		const snap = this.tracker.osnap;
		if (snap) {
			switch (snap.sflag) {
				case SnapFlag.grid:
					DrawSnap.drawGridSnap(ctx, snap.viewp);
					break;
				case SnapFlag.endVt:
					DrawSnap.drawEndVtSnap(ctx, snap.viewp);
					break;
			}
		}
    }

    equalsPoint(v1, v2) {
        return this.viewer.snapCompare(v1, v2);
    }
}

export default DraftCmd;