import * as THREE from 'three';
import {Constants, CommonUtil, CurveUtil, ShapeConfig} from '../common';
import { BufferGeometry } from 'three/src/core/BufferGeometry';
import { parseShape, calculateValue, calculateVector2, buildShape, parseVector2 } from 'c/ThreeJsWrap/Viewer/core/cabinetdesigner/helpers';
import { createCurveEntity } from './CurveEntityEx';

class EntityMgr extends THREE.Group {
    shapeMaterial = new THREE.MeshBasicMaterial({
        color: 'rgb(173,164,142)',
        side: THREE.DoubleSide
    });

    // [The pivot for shape edit space]
    // The 2d bounding-range of all curves is a base coordinate system (2d).
    // The 2d coordinate-system is originated at min(left-bottom corner) of the bounding-range.
    // The pivot(2d) is described as a normalized scale of the bounding-range.
    // So, the pivot is used to calculate the normalize(scale) the coordinates of all curve's vertex.
    shapePivot = new THREE.Vector2();

    constructor() {
        super();

        this.add(new THREE.Group()); // for curves
        this.add(new THREE.Group()); // for shape made by curves-group
        this.add(new THREE.Group()); // temporary draft-entity

        // singleton shape
        // const shapePath = new THREE.ShapePath();
        // const subPaths = shapePath.subPaths;
        // let subPath = null;
        // subPath = new THREE.Path().absarc(0, 0, 5, 0, Constants.TwoPi, false);
        // subPaths.push(subPath);
        // subPath = new THREE.Path().absarc(0, 0, 3, 0, Constants.TwoPi, true);
        // subPaths.push(subPath);

        // subPath = new THREE.Path().absarc(10, 10, 5, 0, Constants.TwoPi, false);
        // subPaths.push(subPath);
        // subPath = new THREE.Path().absarc(10, 10, 3, 0, Constants.TwoPi, true);
        // subPaths.push(subPath);

        // const shapes = shapePath.toShapes(true);
        // const geometry = new THREE.ShapeBufferGeometry(shapes);
        // const mesh = new THREE.Mesh(geometry, this.shapeMaterial);
        // this.shapeG.add(mesh);
        // // shapes.forEach(shape => {
        // //     const geometry = new THREE.ShapeBufferGeometry(shape);
        // //     const mesh = new THREE.Mesh(geometry, this.shapeMaterial);
        // //     this.shapeG.add(mesh);
        // // });

        const shapeEntity = new THREE.Mesh(undefined, this.shapeMaterial); // empty shape for now
        this.shapeG.add(shapeEntity);
    }
    get curveG() { // for curves
        return this.children[0];
    }
    get shapeG() { // for shape made by curves-group
        return this.children[1];
    }
    get draftG() { // temporary draft-entity
        return this.children[2];
    }

    get shapeEntity() {
        return this.shapeG.children[0];
    }

    updateShape = (function() {
        const onEnd = new THREE.Vector2();
        const first = new THREE.Vector2();
        const last = new THREE.Vector2();

        return function() {
            // make ordered curves-ring.
            const curves = [];
            const curveG = this.curveG.children.map(entity => entity.curve);
    
            while (0 < curveG.length) {
                if (curves.length == 0) {
                    curves.push(curveG[0]);
                    curveG.splice(0, 1);
                    continue;
                }
                
                const cur = curves[curves.length - 1];
                cur.getPoint(1, onEnd);

                let adj = -1;
                for (let i = 0; i < curveG.length; i++) {
                    const curve = curveG[i];
                    curve.getPoint(0, first);
                    curve.getPoint(1, last);

                    if (CommonUtil.equalsVector2(onEnd, first)) {}
                    else if (CommonUtil.equalsVector2(onEnd, last)) curve.reverse();
                    else continue;
                    
                    adj = i;
                    break;
                }
                if (adj == -1) break;

                curves.push(curveG[adj]);
                curveG.splice(adj, 1);
            }

            const shapeEntity = this.shapeEntity;
            shapeEntity.geometry.dispose();
            
            let geometry = undefined;
            if (0 < curves.length && curves.length==this.curveG.children.length && CurveUtil.isOrderedClosed(curves)) {
                const shape = new THREE.Shape();
                shape.curves = [...curves];
                geometry = new THREE.ShapeBufferGeometry(shape, Constants.CurveDivisions);
            } else {
                geometry = new BufferGeometry();
            }
            shapeEntity.geometry = geometry;
        }
    })();

    fromJson(shapeWrapper, shapeItem, cameraControl) { // json of item containing shape & shapePivot.
        // virtual item having metrics.
        let owner = shapeItem;
        if (!owner) {
            owner = { // default metrics.
                getSize: () => {
                    return ShapeConfig.size;
                }
            }
        }
        // pivot
        const jshapePivot = shapeWrapper['shapePivot'];
        if (jshapePivot) {
            const parsed = Reflect.apply(parseVector2, undefined, [jshapePivot]);
            const pivot = Reflect.apply(calculateVector2, owner, [parsed]);
            this.shapePivot.copy(pivot);
        } else {
            this.shapePivot.set(0, 0);
        }
        let shape = null;
        if (shapeItem) shape = shapeItem.shape;
        else shape = buildShape(shapeWrapper['shape'], owner);
        
        // move the bounding-range to see at centering of initial-view.
        const range = curvesRange(shape.curves);
        const off = new THREE.Vector2();
        range.getCenter(off);
        shape.curves.forEach(curve => {
            curve.translate(-off.x, -off.y);
        });
        if (cameraControl) {
            const {camera, controls} = cameraControl;

            const size = new THREE.Vector2();
            range.getSize(size);
            const h = Math.max(size.x, size.y);

            camera.position.set(0, h, h);
            camera.lookAt(0, 0, 0);
        }

        // shape-mesh
        this.shapeEntity.geometry.dispose();
        this.shapeEntity.geometry = new THREE.ShapeBufferGeometry(shape, Constants.CurveDivisions);

        // curves
        // remove old
        const curves = [...this.curveG.children];
        curves.forEach(entity => {
            entity.dispose(); // CurveEntityEx
        });
        // add new
        shape.curves.forEach(curve => {
            const entity = createCurveEntity(curve);
            this.curveG.add(entity);
        });
    }
    toJson = (() => {
        // fetch json from shape
        const org = new THREE.Vector2(); // left-bottom corner of range
        const size = new THREE.Vector2(); // size of range
        const vt = new THREE.Vector2();

        function roundValue(value) {
            const v = value.toFixed(4);
            const num = parseFloat(v);
            return num;
        }
        function parseValue(value, range, org, dim) {
            const v = (value - org) / range; // normalize
            const num = roundValue(v);
            return [
                {"type" : "constant", "value" : num},
                {"type" : "operator", "value" : "*"},
                {"type" : "size", "value" : dim}
            ];
        }
        function parseRadius(radius) {
            // min-approach
            let dim = 'y';
            let ds = size.y;
            if (size.x < size.y) {
                dim = 'x';
                ds = size.x;
            }
            // max-approach
            // let dim = 'y';
            // let ds = size.y;
            // if (size.x >= size.y) {
            //     dim = 'x';
            //     ds = size.x;
            // }
            return parseValue(radius, ds, 0, dim);
        }
        function setPoint(point, json = {}) {
            json.x = parseValue(point.x, size.x, org.x, 'x');
            json.y = parseValue(point.y, size.y, org.y, 'y');
            return json;
        }
        function moveTo(point) {
            return setPoint(point);
        }
        function lineTo(point) {
            const result = {
                "type" : "lineTo",
            };
            return setPoint(point, result);
        }
        function quadraticBezierCurveTo(cp1, end) { // QuadraticBezier
            const result = {
                "type" : "bezierCurveTo",
                "controlPoint1" : setPoint(cp1),
            };
            return setPoint(end, result);
        }
        function cubicBezierCurveTo(cp1, cp2, end) { // CubicBezier
            const result = {
                "type" : "3bezierCurveTo",
                "controlPoint1" : setPoint(cp1),
                "controlPoint2" : setPoint(cp2),
            };
            return setPoint(end, result);
        }

        function arcTo(center, radius, startAngle, endAngle, clockwise) {
            const result = {
                "type" : "arcTo",
                "center" : setPoint(center),
                "radius" : parseRadius(radius),
                "startAngle" : roundValue(startAngle),
                "endAngle" : roundValue(endAngle),
                "clockwise" : clockwise,
            };
            return result;
        }

        return () => {
            const parameters = this.shapeEntity.geometry.parameters;
            if (!parameters) return null;
            const shapes = parameters.shapes;
            if (!shapes) return null;

            let shape = shapes;
            if (shapes instanceof Array && shapes.length != 0) {
                console.warn("shapes: ", shapes.length);
                shape = shapes[0];
            }

            const curves = shape.curves;
            const range = curvesRange(curves);
            if (range.isEmpty()) return null;

            range.getSize(size);
            org.copy(size).multiply(this.shapePivot).add(range.min);

            const result = [];
            let path;
            // moveTo first
            path = moveTo(curves[0].getPoint(0, vt));
            result.push(path);
            // pathTo nexts
            for (let i = 0; i < curves.length; i += 1) {
                const curve = curves[i];
                if (curve.isLineCurve) {
                    path = lineTo(curve.v2);
                } else if (curve.isEllipseCurve) {
                    path = arcTo({x: curve.aX, y: curve.aY}, curve.xRadius, curve.aStartAngle, curve.aEndAngle, curve.aClockwise);
                } else if (curve.isQuadraticBezierCurve) {
                    path = quadraticBezierCurveTo(curve.v1, curve.v2);
                } else if (curve.isCubicBezierCurve) {
                    path = cubicBezierCurveTo(curve.v1, curve.v2, curve.v3);
                } else {
                    console.error('no-defined curve: ', curve);
                    continue;
                }
                result.push(path);
            }

            return result;
        }
    })();
}

const curvesRange = (() => {
    const range = new THREE.Box2();
    const bcurve = new THREE.Box2();    
    return (curves) => {
        range.makeEmpty();
        curves.forEach(curve => {
            const pts = curve.getPoints(CurveUtil.curveDivisions(curve));
            bcurve.setFromPoints(pts);
            range.union(bcurve);
        });
        return range;
    }
})();

export default EntityMgr;