/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable prefer-reflect */
/* eslint-disable import/prefer-default-export */
// @ts-check
import { Matrix4, Vector3 } from 'three';
import { getTopLevelObject } from 'c/ThreeJsWrap/Viewer/core/helpers/object3d';
import Storage from 'scr/utilitiesStorage';
import CrownMolding from
  'c/Cabinet/cabinetdesigner/src/structured/singletonCabinet/core/CrownMolding';
import { GenericCabinet } from '../../core/GenericCabinet';
import { getCabinetFromConfig } from '../../core/cabinetTypes';
import { TallFiller } from '../TallFiller';
import { TallRightFinishPanel } from '../TallRightFinishPanel';
import { TallLeftFinishPanel } from '../TallLeftFinishPanel';

export class FloorCabinet extends GenericCabinet {
  // @ts-ignore
  constructor( ...p ) {
    // @ts-ignore
    super( ...p );

    this.crownMolding = new CrownMolding( this,
      Storage.get( 'namesToShapes' ).crownMoldings[ Storage.get( 'crownMoldings' ).defaultShape ],
      false,
      false
    );
    this.crownMolding.rotation.set( 0, -Math.PI / 2, 0 );

    if ( Storage.get( 'crownMoldings' ).isPresented ) {
      this.addCrownMolding();
    }

    this.setType( 'tall' );
  }

  isCrownMoldingPresent() {
    if ( this.crownMolding.parent ) {
      return true;
    }

    return false;
  }

  removeCrownMolding() {

    if ( this.crownMolding.parent ) {
      this.crownMolding.parent.remove( this.crownMolding );

    }
  }

  addCrownMolding() {
    if ( !this.crownMolding.parent ) {
      this.add( this.crownMolding );
    }
  }

  changeDepth( d ) {
    super.changeDepth( d );
    // this.origin.position.z = d / 2;
    this.crownMolding.changeDepth();
    if ( this.crownMolding ) {
      this.crownMolding.position.set( this.getSizes().width / 2, this.getSizes().depth, this.getSizes().height / 2 - 0.5 );
    }
  }

  changeHeight( h ) {
    super.changeHeight( h );
    // this.origin.position.y = -h / 2;
    if ( this.crownMolding ) {
      this.crownMolding.position.set( this.getSizes().width / 2, this.getSizes().depth, this.getSizes().height / 2 - 0.5 );
    }
  }

  changeWidth( w ) {
    super.changeWidth( w );
    if ( this.crownMolding ) {
      this.crownMolding.changeWidth();
      this.crownMolding.position.set( this.getSizes().width / 2, this.getSizes().depth, this.getSizes().height / 2 - 0.5 );
    }
  }

  initVestaObject() {
    super.initVestaObject();

    const oldChangeHeight = this.vestaObject.changeHeight;
    this.vestaObject.changeHeight = function changeHeight( /** @type { number } */val ) {
      oldChangeHeight.call( this, val );

      this.getParent().arrowVert.position.y = 0;
      this.getParent().arrowHori.position.y = val + 1 + 1;
    };


    const oldChangeDepth = this.vestaObject.changeDepth;
    this.vestaObject.changeDepth = function changeDepth( /** @type { number } */val ) {
      oldChangeDepth.call( this, val );

      this.getParent().arrowHori.position.z = -val / 2;
      this.getParent().arrowVert.position.z = -val / 2;
    };

    this.vestaObject.getDistanceToWalls = ( toPoint ) => {
      if ( ['center', 'edge'].indexOf( toPoint ) === -1 ) {
        return;
      }

      const result = {};
      const intersectionObjects = [];
      Storage.get( 'viewer' ).scene.traverseVisible( ( obj ) => {
        if ( obj.isWall ) {
          intersectionObjects.push( obj.mesh );
        }
      } );

      const snapping = this.vestaObject.updateSnapping( /** @type { inches } */( 1e6 ), intersectionObjects );
      result.snapping = snapping;
      if ( snapping ) {
        if ( snapping.plusX &&
          snapping.plusX.object ) {
          const topLevelObject = getTopLevelObject( snapping.plusX.object );
          if ( topLevelObject.isWall ) {
            result.plusX = Math.abs( snapping.plusX.distance ) - this.getSizes().width / 2;
          }
        }
        if ( snapping.minusX &&
          snapping.minusX.object ) {
          const topLevelObject = getTopLevelObject( snapping.minusX.object );
          if ( topLevelObject.isWall ) {
            result.minusX = Math.abs( snapping.minusX.distance ) - this.getSizes().width / 2;
          }
        }
        if ( snapping.plusY &&
          snapping.plusY.object ) {
          const topLevelObject = getTopLevelObject( snapping.plusY.object );
          if ( topLevelObject.isWall ) {
            result.plusY = Math.abs( snapping.plusY.distance ) - this.getSizes().height / 2;
          }
        }
        if ( snapping.minusY &&
          snapping.minusY.object ) {
          const topLevelObject = getTopLevelObject( snapping.minusY.object );
          if ( topLevelObject.isWall ) {
            result.minusY = Math.abs( snapping.minusY.distance ) - this.getSizes().height / 2;
          }
        }
      }

      if ( toPoint === 'center' ) {
        result.plusX += this.getSizes().width / 2;
        result.minusX += this.getSizes().width / 2;
        result.plusY += this.getSizes().height / 2;
        result.minusY += this.getSizes().height / 2;
      }

      return result;
    };

    this.vestaObject.setDistanceToWall = ( side, toPoint, distance ) => {
      const distances = this.vestaObject.getDistanceToWalls( 'edge' );

      if ( ['left', 'right', 'top', 'bottom'].indexOf( side ) === -1 ||
        distance < 0 ||
        !this.parent ||
        ( !distances.minusX || !distances.plusX || !distances.minusY || !distances.plusY ) ) {

        return;

      }

      if ( !this.parent || (
          toPoint !== 'center' &&
          toPoint !== 'left' &&
          toPoint !== 'right' &&
          toPoint !== 'top' &&
          toPoint !== 'bottom'
      ) ) {
        return null;
      }

      let localPos = this.position.clone()
        .applyMatrix4( this.parent.matrixWorld ).applyMatrix4(
          new Matrix4()
            .getInverse( this.matrixWorld )
        );

      if ( side === 'left' ) {
        if ( toPoint === 'left' ) {
          localPos.x += distance - distances.minusX;
        }
        if ( toPoint === 'center' ) {
          localPos.x += distance - distances.minusX - this.vestaObject.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          localPos.x += distance - distances.minusX - this.vestaObject.getSizes().width;
        }
      }
      if ( side === 'right' ) {
        if ( toPoint === 'left' ) {
          localPos.x += -distance + distances.plusX + this.vestaObject.getSizes().width;
        }
        if ( toPoint === 'center' ) {
          localPos.x += -distance + distances.plusX + this.vestaObject.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          localPos.x += -distance + distances.plusX;
        }
      }
      if ( side === 'top' ) {
        if ( toPoint === 'bottom' ) {
          localPos.z += -distance + distances.minusY + this.vestaObject.getSizes().depth;
        }
        if ( toPoint === 'center' ) {
          localPos.z += -distance + distances.minusY + this.vestaObject.getSizes().depth / 2;
        }
        if ( toPoint === 'top' ) {
          localPos.z += -distance + distances.minusY;
        }
      }
      if ( side === 'bottom' ) {
        if ( toPoint === 'bottom' ) {
          localPos.z += distance - distances.plusY;
        }
        if ( toPoint === 'center' ) {
          localPos.z += distance - distances.plusY - this.vestaObject.getSizes().depth / 2;
        }
        if ( toPoint === 'top' ) {
          localPos.z += distance - distances.plusY - this.vestaObject.getSizes().depth;
        }
      }

      this.position.copy( localPos.clone()
        .applyMatrix4( this.matrixWorld )
        .applyMatrix4( new Matrix4()
          .getInverse( this.parent.matrixWorld ) ) );

    };

    this.vestaObject.getDistanceToWallEnds = ( toPoint ) => {
      if ( !this.parent || ( toPoint !== 'center' && toPoint !== 'left' && toPoint !== 'right' ) ) {
        return null;
      }

      const snapping = this.vestaObject.updateSnapping( /** @type { inches } */( 1e6 ) );
      if ( snapping &&
        snapping.plusY &&
        snapping.plusY.object ) {
        const topLevelObject = getTopLevelObject( snapping.plusY.object );
        if ( topLevelObject.isWall &&
          Math.abs( snapping.plusY.distance ) < ( 0.1 + this.getSizes().height / 2 )
        ) {

          let leftCorrectionMergedWalls2D = 0;
          let rightCorrectionMergedWalls2D = 0;
          if ( topLevelObject.relatedWalls2D ) {
            const index = topLevelObject.relatedWalls2D.indexOf( topLevelObject );
            for ( let i = 0; i < index; i += 1 ) {
              leftCorrectionMergedWalls2D += topLevelObject.relatedWalls2D[ i ].width;
            }

            for ( let i = index + 1, l = topLevelObject.relatedWalls2D.length; i < l; i += 1 ) {
              rightCorrectionMergedWalls2D += topLevelObject.relatedWalls2D[ i ].width;
            }
          }

          if ( toPoint === 'center' ) {
            const localPos = this.position.clone()
              .applyMatrix4( this.parent.matrixWorld )
              .applyMatrix4(
                new Matrix4().getInverse( snapping.plusY.object.matrixWorld )
              );

            return ( {
              snapping,
              minusX: localPos.x + leftCorrectionMergedWalls2D,
              plusX: topLevelObject.width - localPos.x + rightCorrectionMergedWalls2D,
              minusY: null,
              plusY: null
            } );
          }
          if ( toPoint === 'left' ) {
            const localPos = ( this.position.clone().add( new Vector3( -this.getSizes().width / 2, 0, 0 ).applyMatrix4( new Matrix4().makeRotationFromEuler( this.rotation ) ) ) )
              .applyMatrix4( this.parent.matrixWorld )
              .applyMatrix4(
                new Matrix4().getInverse( snapping.plusY.object.matrixWorld )
              );

            return ( {
              snapping,
              minusX: localPos.x + leftCorrectionMergedWalls2D,
              plusX: topLevelObject.width - localPos.x + rightCorrectionMergedWalls2D,
              minusY: null,
              plusY: null
            } );
          }
          if ( toPoint === 'right' ) {
            const localPos = ( this.position.clone().add( new Vector3( this.getSizes().width / 2, 0, 0 ).applyMatrix4( new Matrix4().makeRotationFromEuler( this.rotation ) ) ) )
              .applyMatrix4( this.parent.matrixWorld )
              .applyMatrix4(
                new Matrix4().getInverse( snapping.plusY.object.matrixWorld )
              );

            return ( {
              snapping,
              minusX: localPos.x + leftCorrectionMergedWalls2D,
              plusX: topLevelObject.width - localPos.x + rightCorrectionMergedWalls2D,
              minusY: null,
              plusY: null
            } );
          }
        }

        return ( {
          minusX: null,
          plusX: null,
          minusY: null,
          plusY: null
        } );

      }

      return ( {
        minusX: null,
        plusX: null,
        minusY: null,
        plusY: null
      } );


    };

    this.vestaObject.setDistanceToWallEnd = ( side, toPoint, distance ) => {
      const distances = this.vestaObject.getDistanceToWallEnds( 'center' );
      if ( !this.parent || ( toPoint !== 'center' && toPoint !== 'left' && toPoint !== 'right' ) ) {
        return null;
      }

      if ( ( side !== 'left' && side !== 'right' ) ||
        distance < 0 ||
        !this.parent ||
        ( !distances.minusX || !distances.plusX ) ) {
        return;
      }

      const topLevelObject = getTopLevelObject( distances.snapping.plusY.object );
      if ( side === 'left' ) {
        let localPos = this.position.clone()
          .applyMatrix4( this.parent.matrixWorld ).applyMatrix4(
            new Matrix4()
              .getInverse( distances.snapping.plusY.object.matrixWorld )
          );
        if ( toPoint === 'center' ) {
          localPos.x += distance - distances.minusX;
        }
        if ( toPoint === 'left' ) {
          localPos.x += distance - distances.minusX + this.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          localPos.x += distance - distances.minusX - this.getSizes().width / 2;
        }

        this.position.copy( localPos.clone()
          .applyMatrix4( distances.snapping.plusY.object.matrixWorld )
          .applyMatrix4( new Matrix4()
            .getInverse( this.parent.matrixWorld ) ) );
      }

      if ( side === 'right' ) {
        let localPos = this.position.clone()
          .applyMatrix4( this.parent.matrixWorld ).applyMatrix4(
            new Matrix4()
              .getInverse( distances.snapping.plusY.object.matrixWorld )
          );
        if ( toPoint === 'center' ) {
          localPos.x += -distance + distances.plusX;
        }
        if ( toPoint === 'left' ) {
          localPos.x += -distance + distances.plusX + this.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          localPos.x += -distance + distances.plusX - this.getSizes().width / 2;
        }

        this.position.copy( localPos.clone()
          .applyMatrix4( distances.snapping.plusY.object.matrixWorld )
          .applyMatrix4( new Matrix4()
            .getInverse( this.parent.matrixWorld ) ) );
      }
    };

  }

  getSizes() {
    return {
      height: this.size.depth,
      width: this.size.width,
      depth: this.size.height
    };
  }

  addFiller(
    /** @type { 'left' | 'right' } */side,
    /** @type { import('@npmrost/utils').inches } */width
  ) {
    const filler = getCabinetFromConfig( { config: TallFiller } );
    filler.vestaObject.changeWidth( width );
    filler.vestaObject.changeDepth( this.getSizes().height );
    filler.vestaObject.changeHeight( this.getSizes().depth );


    if ( side === 'left' ) {
      filler.position.copy(
        this.position.clone()
          .add(
            new Vector3( -this.getSizes().width / 2 - width / 2, 0, 0 )
              .applyMatrix4( new Matrix4()
                .makeRotationFromEuler( this.rotation )
              )
          )
      );
    }
    if ( side === 'right' ) {
      filler.position.copy(
        this.position.clone()
          .add(
            new Vector3( this.getSizes().width / 2 + width / 2, 0, 0 )
              .applyMatrix4( new Matrix4()
                .makeRotationFromEuler( this.rotation )
              )
          )
      );
    }

    filler.rotation.copy( this.rotation );
    this.parent.add( filler );
    filler.updateMatrixWorld();
    Storage.get( 'viewer' ).renderOnDemand.set();
  }

  addFillerEnd(
    /** @type { 'left' | 'right' } */side,
    /** @type { import('@npmrost/utils').inches } */width
  ) {


    let finishPanel;
    if ( side === 'left' ) {
      finishPanel = getCabinetFromConfig( { config: TallLeftFinishPanel } );
      finishPanel.vestaObject.changeWidth( width );
      finishPanel.vestaObject.changeDepth( this.getSizes().height );
      finishPanel.vestaObject.changeHeight( this.getSizes().depth );
      finishPanel.position.copy(
        this.position.clone()
          .add(
            new Vector3( -this.getSizes().width / 2 - width / 2, 0, 0 )
              .applyMatrix4( new Matrix4()
                .makeRotationFromEuler( this.rotation )
              )
          )
      );
    }
    if ( side === 'right' ) {
      finishPanel = getCabinetFromConfig( { config: TallRightFinishPanel } );
      finishPanel.vestaObject.changeWidth( width );
      finishPanel.vestaObject.changeDepth( this.getSizes().height );
      finishPanel.vestaObject.changeHeight( this.getSizes().depth );
      finishPanel.position.copy(
        this.position.clone()
          .add(
            new Vector3( this.getSizes().width / 2 + width / 2, 0, 0 )
              .applyMatrix4( new Matrix4()
                .makeRotationFromEuler( this.rotation )
              )
          )
      );
    }

    finishPanel.rotation.copy( this.rotation );

    // finishPanel.countertop.baseCountertop = this.countertop.baseCountertop || this.countertop;
    this.parent.add( finishPanel );
    finishPanel.updateMatrixWorld();

    Storage.get( 'viewer' ).renderOnDemand.set();
  }
}
