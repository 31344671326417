import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  GenericArray
} from '@npmrost/storybook/stories/Vesta/Arrays/GenericArray';
import {
  LabeledReactSelectSingle
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledReactSelectSingle';
import {
  LabeledTreeViewSelect
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledTreeViewSelect';
import {
  getItems, getValueToIItem, updateFullDataFromPart, openTheFirstest
} from '@npmrost/storybook/stories/Vesta/Choice/TreeViewSelect';
import {
  LabeledPercentInput
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledPercentInput';
import {
  updateCabinetsSettings
} from '@r/reducers/storeParts/calculationMode/projects/currentProject/cabinets';

const Wrapper = styled.div`
  padding-right: 7px;
`;

const Title = styled.span`
  ${ ( { theme: t } ) => `
    color: ${ t.darkGreyColor };
  ` }
`;

const Content = styled.div`
  margin-top: 10px;
`;

const StyledLabeledTreeViewSelect = styled( LabeledTreeViewSelect )`
  margin-bottom: 10px;
`;

const StyledLabeledPercentInput = styled( LabeledPercentInput )`
  margin-bottom: 10px;
`;

const StyledLabeledReactSelectSingle = styled( LabeledReactSelectSingle )`
  margin-bottom: 10px;
`;

class Materials extends PureComponent {

  setMaterialsSetSingleSelectProperty = ( index, propName ) => ( [{ value }] ) => {
    const {
      settings: {
        materialsSets
      },
      currentProject,
      adminMode,
      dispatch
    } = this.props;

    const newMaterialsSets = JSON.parse( JSON.stringify( materialsSets ) );

    newMaterialsSets.data[ index ][ propName ] = value;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_MATERIALS_SETS`,
      value: newMaterialsSets
    } );
  }

  setMaterialsSetTreeViewSelectProperty = ( index, propName ) => ( { data, chosenItem } ) => {
    const {
      settings: {
        materialsSets
      },
      currentProject,
      adminMode,
      dispatch
    } = this.props;

    const hash = data.reduce( ( acc, it ) => ( {
      ...acc,
      ...getValueToIItem( it )
    } ), {} );

    const newMaterialsSets = JSON.parse( JSON.stringify( materialsSets ) );

    const options = JSON.parse(
      JSON.stringify( newMaterialsSets[ `${ propName }Options` ] )
    );

    newMaterialsSets[ `${ propName }Options` ] = options
      .map( ( it ) => updateFullDataFromPart( it, hash ) );

    if ( chosenItem ) {
      newMaterialsSets.data[ index ][ propName ] = chosenItem;
    }

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_MATERIALS_SETS`,
      value: newMaterialsSets
    } );
  }

  setMaterialsSetTreeViewSelectSearchText = ( index, propName ) => ( searchText ) => {
    const {
      settings: {
        materialsSets
      },
      currentProject,
      adminMode,
      dispatch
    } = this.props;

    const newMaterialsSets = JSON.parse( JSON.stringify( materialsSets ) );

    newMaterialsSets.data[ index ][ `${ propName }SearchText` ] = searchText;

    const options = JSON.parse(
      JSON.stringify( newMaterialsSets[ `${ propName }Options` ] )
    );

    const filtered = getItems( options, searchText )
      .map( ( item, i ) => openTheFirstest( item, searchText !== '' && i === 0 ) );

    const hash = filtered.reduce( ( acc, it ) => ( {
      ...acc,
      ...getValueToIItem( it )
    } ), {} );

    newMaterialsSets[ `${ propName }Options` ] = options
      .map( ( it ) => updateFullDataFromPart( it, hash ) );

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_MATERIALS_SETS`,
      value: newMaterialsSets
    } );
  }

  setItems = () => {
    const {
      props: {
        searchPlaceholder,
        texts: {
          core: coreText,
          doorsDrawers: doorsDrawersText,
          doorGrainDirection: doorGrainDirectionText,
          drawerGrainDirection: drawerGrainDirectionText,
          edgebanding: edgebandingText,
          grainDirectionOptions: grainDirectionOptionsText
        },
        settings: {
          materialsSets: {
            data,
            coreOptions,
            doorsDrawersOptions,
            edgebandingOptions
          }
        }
      },
      setMaterialsSetSingleSelectProperty,
      setMaterialsSetTreeViewSelectProperty,
      setMaterialsSetTreeViewSelectSearchText
    } = this;

    const grainDirectionOptionsArr = ['vertical', 'horizontal'];

    const grainDirectionOptions =
      grainDirectionOptionsText.map( ( item, index ) => ( {
        label: item,
        value: grainDirectionOptionsArr[ index ]
      } ) );

    const items = [];

    for ( let i = 0; i < data.length; i++ ) {
      const {
        core: coreValue,
        coreSearchText,
        doorsDrawers: doorsDrawersValue,
        doorsDrawersSearchText,
        doorGrainDirection,
        drawerGrainDirection,
        edgebanding: edgebandingValue,
        edgebandingSearchText
      } = data[ i ];

      items.push( (
        <>
          <LabeledTreeViewSelect
            placeholder={ searchPlaceholder }
            name={ coreText }
            items={ getItems( coreOptions, coreSearchText ) }
            chosen={ coreValue }
            choose={ setMaterialsSetTreeViewSelectProperty( i, 'core' ) }
            searchText={ coreSearchText }
            setSearchText={ setMaterialsSetTreeViewSelectSearchText( i, 'core' ) }
          />
          <LabeledTreeViewSelect
            placeholder={ searchPlaceholder }
            name={ doorsDrawersText }
            items={ getItems( doorsDrawersOptions, doorsDrawersSearchText ) }
            chosen={ doorsDrawersValue }
            choose={ setMaterialsSetTreeViewSelectProperty( i, 'doorsDrawers' ) }
            searchText={ doorsDrawersSearchText }
            setSearchText={ setMaterialsSetTreeViewSelectSearchText( i, 'doorsDrawers' ) }
          />
          <LabeledReactSelectSingle
            name={ doorGrainDirectionText }
            options={ grainDirectionOptions }
            chosen={
              [{
                label: grainDirectionOptions.find( ( item ) => item.value === doorGrainDirection ).label,
                value: doorGrainDirection
              }]
            }
            choose={ setMaterialsSetSingleSelectProperty( i, 'doorGrainDirection' ) }
          />
          <LabeledReactSelectSingle
            name={ drawerGrainDirectionText }
            options={ grainDirectionOptions }
            chosen={
              [{
                label: grainDirectionOptions.find( ( item ) => item.value === drawerGrainDirection ).label,
                value: drawerGrainDirection
              }]
            }
            choose={ setMaterialsSetSingleSelectProperty( i, 'drawerGrainDirection' ) }
          />
          <LabeledTreeViewSelect
            placeholder={ searchPlaceholder }
            name={ edgebandingText }
            items={ getItems( edgebandingOptions, edgebandingSearchText ) }
            chosen={ edgebandingValue }
            choose={ setMaterialsSetTreeViewSelectProperty( i, 'edgebanding' ) }
            searchText={ edgebandingSearchText }
            setSearchText={ setMaterialsSetTreeViewSelectSearchText( i, 'edgebanding' ) }
          />
        </>
      ) );
    }

    return items;
  };

  addSet = () => {
    const {
      settings: {
        materialsSets,
        materialsSets: {
          data,
          counter,
          keys
        }
      },
      currentProject,
      adminMode,
      dispatch
    } = this.props;

    const newData = JSON.parse( JSON.stringify( data ) );
    const newKeys = JSON.parse( JSON.stringify( keys ) );
    const newCounter = counter + 1;

    newData.push(
      JSON.parse( JSON.stringify( newData[ newData.length - 1 ] ) )
    );
    newKeys.push( newCounter );

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_MATERIALS_SETS`,
      value: {
        ...materialsSets,
        data: newData,
        counter: newCounter,
        keys: newKeys
      }
    } );
  }

  removeSet = ( keyNumber ) => {
    const {
      settings: {
        materialsSets,
        materialsSets: {
          data,
          counter,
          keys
        }
      },
      currentProject,
      adminMode,
      dispatch
    } = this.props;

    if ( data.length === 1 ) return;

    const newData = JSON.parse( JSON.stringify( data ) );
    const newKeys = JSON.parse( JSON.stringify( keys ) );

    const index = newKeys.indexOf( keyNumber );

    newData.splice( index, 1 );
    newKeys.splice( index, 1 );

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_MATERIALS_SETS`,
      value: {
        ...materialsSets,
        data: newData,
        counter,
        keys: newKeys
      }
    } );
  }

  setProperty = ( propName ) => ( { data, chosenItem } ) => {
    const {
      currentProject,
      adminMode,
      dispatch,
      settings: {
        [ propName ]: { options }
      }
    } = this.props;

    const hash = data.reduce( ( acc, it ) => ( {
      ...acc,
      ...getValueToIItem( it )
    } ), {} );

    const newOptions = JSON.parse( JSON.stringify( options ) )
      .map( ( it ) => updateFullDataFromPart( it, hash ) );

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_${ propName.toUpperCase() }_OPTIONS`,
      value: newOptions
    } );

    if ( chosenItem ) {
      dispatch( {
        type: `SET_CALCULATION_MODE${ typeText }_${ propName.toUpperCase() }_VALUE`,
        value: chosenItem
      } );

      if ( currentProject ) {
        dispatch( updateCabinetsSettings(
          propName,
          { options: newOptions, value: chosenItem, searchText: '' }
        ) );
      }
    }
  }

  setPropertySearchText = ( propName ) => ( searchText ) => {
    const {
      settings: {
        [ propName ]: { options: items }
      },
      currentProject,
      adminMode,
      dispatch
    } = this.props;

    const options = JSON.parse( JSON.stringify( items ) );

    const filtered = getItems( options, searchText )
      .map( ( item, i ) => openTheFirstest( item, searchText !== '' && i === 0 ) );

    const hash = filtered.reduce( ( acc, it ) => ( {
      ...acc,
      ...getValueToIItem( it )
    } ), {} );

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_${ propName.toUpperCase() }_OPTIONS`,
      value: options
        .map( ( it ) => updateFullDataFromPart( it, hash ) )
    } );

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_${ propName.toUpperCase() }_SEARCH_TEXT`,
      value: searchText
    } );
  }

  setSingleSelectValue = ( propName, dispatchPropName ) => ( [value] ) => {
    const {
      settings: {
        [ propName ]: { options }
      },
      currentProject,
      adminMode,
      dispatch
    } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    dispatch(
      {
        type: `SET_CALCULATION_MODE${ typeText }_${ dispatchPropName }_VALUE`,
        value
      }
    );

    if ( currentProject ) {
      dispatch( updateCabinetsSettings(
        propName,
        { options, value }
      ) );
    }
  }

  setMarkup = ( value ) => {
    const {
      currentProject,
      adminMode,
      dispatch
    } = this.props;

    const newValue = { label: value, value: Number( value ) };

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    dispatch(
      {
        type: `SET_CALCULATION_MODE${ typeText }_MARKUP_LABEL`,
        value: newValue.label
      }
    );

    dispatch(
      {
        type: `SET_CALCULATION_MODE${ typeText }_MARKUP_VALUE`,
        value: newValue.value
      }
    );

    if ( currentProject ) {
      dispatch( updateCabinetsSettings(
        'markup',
        newValue
      ) );
    }
  }

  cancelProperty = ( propName ) => () => {
    const {
      currentProject,
      adminMode,
      dispatch,
      settings: {
        [ propName ]: { options }
      },
      texts: { countertopNone }
    } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_${ propName.toUpperCase() }_VALUE`,
      value: { label: countertopNone, value: '' }
    } );

    if ( currentProject ) {
      dispatch( updateCabinetsSettings(
        propName,
        {
          options,
          value: { label: countertopNone, value: '' },
          searchText: ''
        }
      ) );
    }
  }

  render() {

    const {
      props: {
        className,
        searchPlaceholder,
        texts: {
          title,
          setName,
          countertop: countertopText,
          // mouldings: mouldingsText,
          // hardware: hardwareText,
          pulls: pullsText,
          hinges: hingesText,
          drawerSystems: drawerSystemsText,
          markup: markupText
        },
        settings: {
          materialsSets: { keys },
          countertop: {
            options: countertopOptions,
            value: countertopValue,
            searchText: countertopSearchText
          },
          hinges: {
            options: hingesOptions,
            value: hingesValue,
            searchText: hingesSearchText
          },
          pulls: {
            options: pullsOptions,
            value: pullsValue,
            searchText: pullsSearchText
          },
          drawerSystems: {
            options: drawerSystemsOptions,
            value: drawerSystemsValue
          },
          markup: { label: markup }
        },
        adminMode,
        adminTitle
      },
      setItems,
      addSet,
      removeSet,
      setProperty,
      setPropertySearchText,
      setSingleSelectValue,
      setMarkup,
      cancelProperty
    } = this;

    return (
      <Wrapper { ...{ className } }>
        <Title>{ adminMode ? adminTitle : title }</Title>
        <Content>
          <GenericArray
            items={ setItems() }
            { ...{ setName, keys } }
            addItem={ addSet }
            removeItem={ removeSet }
          />
          <StyledLabeledTreeViewSelect
            placeholder={ searchPlaceholder }
            name={ countertopText }
            items={ getItems( countertopOptions, countertopSearchText ) }
            chosen={ countertopValue }
            choose={ setProperty( 'countertop' ) }
            searchText={ countertopSearchText }
            setSearchText={ setPropertySearchText( 'countertop' ) }
            isCanceled
            cancel={ cancelProperty( 'countertop' ) }
          />
          {
            countertopValue.usedMarkup && (
              <StyledLabeledPercentInput
                name={ markupText }
                value={ markup }
                onChange={ setMarkup }
              />
            )
          }
          <StyledLabeledTreeViewSelect
            placeholder={ searchPlaceholder }
            name={ pullsText }
            items={ getItems( pullsOptions, pullsSearchText ) }
            chosen={ pullsValue }
            choose={ setProperty( 'pulls' ) }
            searchText={ pullsSearchText }
            setSearchText={ setPropertySearchText( 'pulls' ) }
            isCanceled
            cancel={ cancelProperty( 'pulls' ) }
          />
          <StyledLabeledTreeViewSelect
            placeholder={ searchPlaceholder }
            name={ hingesText }
            items={ getItems( hingesOptions, hingesSearchText ) }
            chosen={ hingesValue }
            choose={ setProperty( 'hinges' ) }
            searchText={ hingesSearchText }
            setSearchText={ setPropertySearchText( 'hinges' ) }
          />
          <StyledLabeledReactSelectSingle
            name={ drawerSystemsText }
            options={ drawerSystemsOptions }
            chosen={ [drawerSystemsValue] }
            choose={ setSingleSelectValue( 'drawerSystems', 'DRAWER_SYSTEMS' ) }
          />
        </Content>
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        search: searchPlaceholder,
        materials: texts,
        adminMode: { materialsTitle: adminTitle }
      }
    }
  } ) => ( {
    // settings: s.calculationMode.settings.materials,
    texts,
    searchPlaceholder,
    adminTitle
  } )
)( Materials );
