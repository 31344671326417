import React from 'react';
import style from './index.styl';

export default class Item extends React.PureComponent {
  innerClick = () => {
    this.props.chooseItem( this.props.item );
  }

  innerDblclickAdd = () => {
    this.props.dblclickAdd( this.props.item );
  }

  render() {
    const {
      props: {
        item: { id, name, image },
        search, chosenItem
      }, innerDblclickAdd, innerClick
    } = this;

    return (
      <div
        style={ { display: name.toLowerCase().match( search.toLowerCase() ) ? '' : 'none' } }
        className={
          style.item + ( chosenItem && chosenItem.id === id
            ? ' chosen' : '' )
        }
        onClick={ innerClick }
        onDoubleClick={ innerDblclickAdd }
      >
        <div>
          <img src={ `/${ image }` } alt={ name } />
        </div>
        <p>{ name }</p>
      </div>
    );
  }
}
