import {
  Group, Vector3
} from 'three';

import Panel from './Panel';
import Countertop from './Countertop';
import {
  parseAttributes, parseVector3
  , calculateVector3, calculateEuler
} from './helpers';

export default class Part extends Group {

    isPart = true;

    size: Vector3 = new Vector3( 36, 24, 18 );

    components: ( Panel | Countertop )[] = [];

    constructor( config ) {
      super();
      this.config = config;
      this.fromJSON( config );
      this.rebuildGeometry();
    }

    getCabinet() {
      let obj = this.parent;

      while ( obj ) {
        if ( obj.isCabinet ) {
          return obj;
        }

        obj = obj.parent;
      }

      return null;
    }

    fromJSON( config ) {
      this._attributes = Reflect.apply( parseAttributes, this, [config.attributes] );
      this._size = Reflect.apply( parseVector3, this, [config.size] );
      this._position = Reflect.apply( parseVector3, this, [config.position] );
      this._rotation = Reflect.apply( parseVector3, this, [config.rotation] );

      for ( let i = 0; i < config.children.length; i += 1 ) {
        this.addComponent( createObjectFromConfig( config.children[ i ] ) );
      }
    }

    setName( name: string ) {
      this.name = name;
    }

    addComponent( component: Panel | Countertop ) {
      this.components.push( component );
      this.add( component );
    }

    removeComponent() {

    }

    rebuildGeometry() {
      this.position.copy( this.rebuildPosition() );
      this.rotation.copy( this.rebuildRotation() );
      this.size.copy( this.rebuildSize() );

      for ( let i = 0; i < this.components.length; i += 1 ) {
        this.components[ i ].rebuildGeometry();
      }
    }

    rebuildPosition() {
      return Reflect.apply( calculateVector3, this, [this._position] );
    }

    rebuildRotation() {
      return Reflect.apply( calculateEuler, this, [this._rotation] );
    }

    rebuildSize() {
      return Reflect.apply( calculateVector3, this, [this._size] );
    }
}
