import {
  processUnitSetLabelAction,
  processUnitSetValueAction,
  processSetUnitsAction
} from '@r/reducers/utils';
import { AllActions } from '@r/storeUtils';
import { inches, quantity } from 'decl/general/units';
import { UnitsInputv2Value } from 'decl/general/html';

type base = redux._IStore['projectFor3d']['cabinetsSettings']['base'];
const baseDefault: base = {
  depth: {
    value: 24 as inches,
    label: '' as UnitsInputv2Value
  },
  height: {
    value: 36 as inches,
    label: '' as UnitsInputv2Value
  },
  shelvesQty: 1 as quantity
};

export const base = (
  state = baseDefault,
  action: AllActions
): typeof state => {
  switch( action.type ) {
    case 'SET_CABINETS_SETTINGS_BASE_DEPTH_LABEL': {
      return {
        ...state,
        depth: processUnitSetLabelAction( state.depth, action )
      };
    }
    case 'SET_CABINETS_SETTINGS_BASE_DEPTH_VALUE': {
      return {
        ...state,
        depth: processUnitSetValueAction( state.depth, action )
      };
    }
    case 'SET_CABINETS_SETTINGS_BASE_HEIGHT_LABEL': {
      return {
        ...state,
        height: processUnitSetLabelAction( state.height, action )
      };
    }
    case 'SET_CABINETS_SETTINGS_BASE_HEIGHT_VALUE': {
      return {
        ...state,
        height: processUnitSetValueAction( state.height, action )
      };
    }
    case 'SET_CABINETS_SETTINGS_BASE_SHELVES_QTY': {
      return {
        ...state,
        shelvesQty: action.value
      };
    }
    case 'SET_UNITS': {
      return {
        ...state,
        height: processSetUnitsAction( state.height, action ),
        depth: processSetUnitsAction( state.depth, action )
      };
    }
    default:
      return state;
  }
};
