/*
 * @ts-check
 *import Countertop from '../../core/Countertop';
 */
import { WithCountertop } from './WithCountertop';
import { FloorCabinet } from './FloorCabinet';

// eslint-disable-next-line import/prefer-default-export
export class CornerWithCountertop extends WithCountertop {
  constructor() {
    // super();
    // this.isCornerWithCountertop = true;

    // this.countertop = new Countertop( this );
    // this.countertop.setDepthOverhang( 0, 0 );
    // debugger;
    // this.setType( 'base' );

    const cab = new FloorCabinet();
    cab.setType( 'base' );

    return cab;
  }
}
