import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { value } from './value';
import { options } from './options';

const shippingCostComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['projects']['currentProject']['settings']['information']['shippingCost'],
    AllActions
  >;

export const shippingCost = shippingCostComb( {
  value,
  options
} );
