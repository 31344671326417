function getColorAndOpacity({ _isCork, _isWindowFiller, _isMargin }){
  const rtrn = { color: 0xffff00, opacity: 1 };

  switch(true){
    case _isCork: rtrn.color = 0x006400; break;
    case _isWindowFiller: rtrn.color = 0x06e5de; break;
    case _isMargin: rtrn.color = 0xf76802; break;
  }

  switch(true){
    case _isCork: 
    case _isWindowFiller:
    case _isMargin:
      rtrn.opacity = 1;
  }

  return rtrn;
}

export function setSelected(){
  if(!this._cube) return;
  if(this._cube.highlighted) return;

  const { width, height, depth } = this.size,
    { color, opacity } = getColorAndOpacity( this ),
    material = new THREE.LineBasicMaterial({ opacity, color, linewidth: 4 }),
    geometry = new THREE.Geometry();

  geometry.vertices.push(
    // top part
    new THREE.Vector3( width / 2, height / 2, -(depth / 2) ),
    new THREE.Vector3( width / 2, height / 2, depth / 2),
    new THREE.Vector3( -(width / 2), height / 2, depth / 2),
    new THREE.Vector3(  -(width / 2), height / 2, -(depth / 2) ),
    new THREE.Vector3( width / 2, height / 2, -(depth / 2) ),
    // back part
    new THREE.Vector3( width / 2, -(height / 2), -(depth / 2) ),
    new THREE.Vector3( -(width / 2), -(height / 2), -(depth / 2) ),
    // bottom part
    new THREE.Vector3( -(width / 2), -(height / 2), (depth / 2) ),
    new THREE.Vector3( (width / 2), -(height / 2), (depth / 2) ),
    new THREE.Vector3( width / 2, -(height / 2), -(depth / 2) ),

    new THREE.Vector3( -(width / 2), -(height / 2), -(depth / 2) ),
    new THREE.Vector3( -(width / 2), height / 2, -(depth / 2) ),

    new THREE.Vector3( -(width / 2), height / 2, depth / 2 ),
    new THREE.Vector3( -(width / 2), -(height / 2), depth / 2 ),


    new THREE.Vector3( (width / 2), -(height / 2), depth / 2 ),
    new THREE.Vector3( (width / 2), height / 2, depth / 2 ),
    new THREE.Vector3( (width / 2), height / 2, -(depth / 2) ),
  );
   
  this._cube._line = new THREE.Line( geometry, material );
  this._cube._line.renderOrder = 99;
  this._cube._line.name = `${ this.name }_outtline`;
  this._cube.add( this._cube._line );
  this._cube.highlighted = true;
}

export function setUnselected(){
  if(!this._cube) return;
  this._cube.remove(this._cube._line);
  this._cube.highlighted = false;
}
