/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-check
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IconButton } from 'c/shared/icon-button';
import ItemsModalContent from 'c/ItemsModalContent/index';
import Button from 'c/shared/ThemeButton';
import Storage from 'scr/utilitiesStorage';
import { getObjectForDrag } from 'c/CabinetRightSideMenu/getObjectForDrag';
import Modal from './Modal';
import style from './Modal.styl';
import DoAddRemoveEntity from 'c/ThreeJsWrap/Viewer/UndoRedo/DoAddRemoveEntity';
import { isHoleableWithWall, getWall3D } from 'c/ThreeJsWrap/Viewer/core/helpers/object3d';

export async function addItemToScene(
  /**
   * @type { import('c/CabinetRightSideMenu/getObjectForDrag')._IItemFromJson }
   */jsonRepresentation
) {
  const { format } = jsonRepresentation;
  if (
    format !== 'cabinet' &&
    format !== 'item' &&
    format !== 'newCabinet'
  ) return;

  const item = await getObjectForDrag( jsonRepresentation );

  const viewer = Storage.get('viewer');
  const defaultLocationBefore = viewer.scene.cloneDefaultLocation(); // for undo

  if ( jsonRepresentation.name === 'Sink' ) {
    Storage.get('selectedObject')[0].mountPoint.add( item );
  } else {
    if (!viewer.scene.addObjectDirectly(item)) return;
  }
  
  if (isHoleableWithWall(item)) viewer.onceActionsMgr.registerRefreshWallHoles(getWall3D(item)); // refresh holes
  const defaultLocationAfter = viewer.scene.cloneDefaultLocation(); // for redo
  viewer.doMgr.registerDo(new DoAddRemoveEntity(item, true, null, {defaultLocationBefore, defaultLocationAfter}));
}

/**
 * @typedef {{
 *  tabs: {
 *    object: string;
 *    placement: string;
 *    room: string;
 *  }
 *  dispatch: import('@r/storeUtils').d<redux._IActions['setItemsModalVisible']>
 * }} Props
 */
/**
 * @typedef {{
 *  tab: keyof Props['tabs'],
 *  chosenItem: any
 * }} State
 */
/** @augments { PureComponent<Props, State> }  */
class ItemsModal extends PureComponent {
  /** @type { Partial<Props> } */
  static defaultProps = {
    tabs: {
      object: 'Sort by object type',
      placement: 'Sort by placement type',
      room: 'Sort by room type'
    }
  }

  constructor( /** @type { Props } */p ) {
    super( p );
    /** @type { State } */
    this.state = {
      tab: 'placement',
      chosenItem: null
    };
  }

  componentDidMount() {
    // @ts-ignore
    document.addEventListener( 'keyup', this.closeOnEsc );
  }

  componentWillUnmount() {
    // @ts-ignore
    document.removeEventListener( 'keyup', this.closeOnEsc );
  }

  closeOnEsc = ( /** @type { React.KeyboardEvent } */ e ) => {
    if ( e.keyCode === 27 ) this.closeModalInner();
  }

  closeModalInner = () => {
    this.props.dispatch( { type: 'SET_ITEMS_MODAL_VISIBLE', value: false } );
  };

  chooseTabInner = (
    /**
     * @type { React.MouseEvent<HTMLSpanElement> & {
     *  currentTarget: { dataset: { tab: keyof Props['tabs'] } }
     * }}
     */{
      currentTarget: {
        dataset: { tab }
      }
    } ) => this.setState( { tab } );

  chooseItem = ( /** @type {any} */ chosenItem ) => {
    this.setState( { chosenItem } );
  };

  addBtnClick = async( ) => {
    const {
      state: { chosenItem }
    } = this;
    if ( chosenItem ) {
      await addItemToScene( chosenItem );
    }

    this.closeModalInner();
  };

  dblclickAdd = () => {
    this.addBtnClick();
  };

  render() {
    const {
      state: { tab, chosenItem },
      props: { tabs },
      closeModalInner,
      chooseTabInner,
      chooseItem,
      addBtnClick,
      dblclickAdd
    } = this;

    return (
      <>
        <h1 className='addItemsHeading'>Add object</h1>
        <IconButton
          title='Close'
          variant='close'
          className={ style.close }
          onClick={ closeModalInner }
        />
        <div className='tabs'>
          {
            Object.keys( tabs ).map( ( inner ) => (
              <span
                key={ inner }
                data-tab={ inner }
                onClick={ chooseTabInner }
                className={ ( tab === inner && 'chosen' ) || '' }
              >
                { tabs[ /** @type { keyof Props['tabs'] } */ ( inner ) ] }
              </span>
            ) )
          }
        </div>
        <ItemsModalContent
          { ...{
            tab,
            chooseItem,
            chosenItem,
            dblclickAdd
          } }
        />
        <div className='forAddButton itemsAdd'>
          <Button onClick={ addBtnClick }>Add</Button>
        </div>
      </>
    );
  }
}

const ItemsModalConnected = connect(
  ( /** @type { redux._IStore } */ s ) => ( {
    itemsMenuVisible: s.flags.itemsModalVisible
  } )
)( ItemsModal );

class ItemsModalWrap extends React.PureComponent {
  render() {
    const { itemsMenuVisible: it, className } = this.props;

    return (
      <div className={ className + ( it ? '' : ' hidden' ) }>
        <Modal>{it ? <ItemsModalConnected /> : null }</Modal>
      </div>
    );
  }
}

export default connect(
  ( /** @type { redux._IStore } */ s ) => ( {
    itemsMenuVisible: s.flags.itemsModalVisible
  } )
)( ItemsModalWrap );


// "210": {
//   "name": "Hood",
//   "image": "models/thumbnails/cabinets/2 Door Upper Flip.png",
//   "type": "11",
//   "format": "item",
//   "id": 210,
//   "comp": "WallAppliance"
//   key: 'hood'
// }


// (
// Storage.get( 'assets' ).inWallItems[ 0 ].data,
// Storage.get( 'assets' ).inWallItems[ 0 ].sizes
// )

// interface CreateItem{
// (data: ArrayBuffer, sizes: { width: inches, depth: inches, height: inches }): THREE.Group
// }
