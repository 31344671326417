import groups from './groups';

export default ( { group, ...rest } ) => {
  const { core, other } = groups[ group ]( { ...rest } );

  core.forEach( ( {
    name, length, width, qty
  } ) => {
    console.log( `${ name }:
      length: ${ length }
      width: ${ width }
      quantity: ${ qty }
    ` );
  } );

  other.forEach( ( {
    name, length, width, qty
  } ) => {
    console.log( `${ name }:
      length: ${ length }
      width: ${ width }
      quantity: ${ qty }
    ` );
  } );

  return { core, other };
};
