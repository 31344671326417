import { LoaderResourceTypes, ResourceTypes, threadsNumber } from './interfaces/Loader';
import Loader from './Loader';
import _chunk from 'lodash/chunk';
import _flatten from 'lodash/flatten';
import _groupBy from 'lodash/groupBy';
//import GLTFLoader from './GLTFLoader';
import { Texture } from 'three';

/** @ignore */
/*
 *Const OBJloader = new OBJLoader();
 */
//const GLTFloader = new GLTFLoader();

/**
 * Renders texture once after loading to transfer into videoRAM
 */
/*
 *Const forceTextureInitialization = (() => {
 * const material = new MeshBasicMaterial();
 * const geometry = new PlaneBufferGeometry();
 * const scene = new Scene();
 * scene.add(new Mesh(geometry, material));
 * const camera = new Camera();
 *
 * return function forceTextureInitialization(texture, renderer) {
 * material.map = texture;
 * renderer.setRenderTarget(new WebGLRenderTarget());
 * renderer.render(scene, camera);
 * };
 *})();
 */

/**
 * The namespace that contains functions for loading different kinds of resources
 * @abstract
 */
class AssetsLoader {
    /**
     * Loads .GLTF model
     * @param {string} path Path to the model
     * @returns {Promise<Object3D>} 3D Object
     * @constructor
     */

    static loaderResourceType(ResourceType) {
        switch (ResourceType) {
            case ResourceTypes.GLTFTextObject:
            case ResourceTypes.ObjTextObject:
            case ResourceTypes.Material:
                return LoaderResourceTypes.TEXT;
            case ResourceTypes.GLTFBinaryObject:
                return LoaderResourceTypes.ARRAYBUFFER;
            case ResourceTypes.Texture:
            case ResourceTypes.CubeTexture:
                return LoaderResourceTypes.BLOB;
            case ResourceTypes.JSON:
                return LoaderResourceTypes.JSON;
            default:
                return LoaderResourceTypes.TEXT;
        }
    }

    static load(res, renderer) {
        switch (res.type) {
            case ResourceTypes.GLTFTextObject:
            // AssetsLoader.LoadGLTFObject(res);
            case ResourceTypes.ObjTextObject:
                return null; // AssetsLoader.LoadOBJObject(res);
            case ResourceTypes.Material:
                return res;
            case ResourceTypes.GLTFBinaryObject:
                return res;
            case ResourceTypes.Texture:
                return AssetsLoader.LoadTexture(res);
            case ResourceTypes.CubeTexture:
                return null; // AssetsLoader.LoadCubeTexture(res, renderer);
            case ResourceTypes.JSON:
                return res;
            default:
                return res;
        }
    }


    /*  static LoadGLTFObject(res) {
     return new Promise((resolve, reject) => {
     GLTFloader.parse(
      res.data,
      `${dirname(res.path)}/`,
      (object) => {res.object = object; resolve(object)},
       (err) => {
       reject(err)
       }
       );
      });
     }*/


    /**
     * Loads .OBJ model
     * @param {string} path Path to the model
     * @returns {Promise<Object3D>} 3D Object
     * @constructor
     */
    /*
     *  Static LoadOBJObject(path) {
     * return new Promise((resolve, reject) => {
     * Loader.loadAsText(
     * path,
     * (objectData) => {
     * resolve(OBJloader.parse(objectData));
     * // Const object = OBJloader.parse(objectData);
     *
     * Object.traverse((node) => {
     * if (node instanceof Mesh) {
     * node.receiveShadow = true;
     * node.castShadow = true;
     * node.material.side = DoubleSide;
     * }
     * });
     *
     * object.receiveShadow = true;
     * object.castShadow = true;
     *
     *
     * // Resolve(object);
     * },
     * reject
     * );
     * });
     *}
     */

    /**
     * Loads Cube Texture
     * @param {Array} urls Array of paths of the images
     * @returns {Promise<CubeTexture>} Promise that returns CubeTexture
     * @constructor
     */
    /*
     *Static LoadCubeTexture(res, renderer) {
     * for (let i = 0; i < 6; i += 1) {
     * let img = new Image();
     * res.image = [];
     * img.onload = () => {
     * res.image.push(img);
     * if (i === 5) {
     * res.object = new CubeTexture(res.image);
     * res.object.format = RGBFormat;
     * forceTextureInitialization(res.object, renderer);
     * }
     * };
     * img.src = URL.createObjectURL(res.data[i]);
     * }
     *}
     */

    /**
     * Loads image or array of images
     * @param {Array} urls Array of paths of the images
     * @returns {Promise<Image|[Image]>} Promise that returns Image or Array of images
     * @constructor
     */
    static LoadTexture(res) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                res.image = img;

                res.object = null;//new Texture(img);
                resolve(res);

                // ForceTextureInitialization(res.object, renderer);
            };
            img.src = URL.createObjectURL(res.data);
        });
    }

    /**
     * Loads JSON
     * @param {Array} url Path to JSON
     * @returns {Promise<JSON|[JSON]>} Promise that returns Image or Array of images
     * @constructor
     */
    static LoadJSON(url) {
        return new Promise((resolve, reject) => {
            Loader.loadAsJSON(url, resolve, reject);
        });
    }

    /**
     * Loads all Parts/Materials that described in the data
     * @param data Parts/Materials list
     * @param app Main app
     * @param envMap Environment map
     * @returns {Promise<array>} Array with loaded models and materials
     * @constructor
     */
    /*
     * Static LoadModel(data, app, envMap) {
     * return new Promise((resolve, reject) => {
     * let props = getLoadData(data);
     *
     * let count = 0;
     * let total = props.loadableResourcesCount;
     *
     * let loadedModels = [];
     * let loadedMaterials = {};
     *
     * _each(props.resources.parts, (part) => {
     * if (!part.type) {
     * app.dispatch({
     * type: ViewerEvents.LOADING_ERROR,
     * data: `Model ${part.path} must have a type`
     * });
     *
     * return true;
     * }
     *
     * let modelsPath = part.path instanceof Array ? part.path : [part.path];
     * for (let path of modelsPath) {
     * AssetsLoader.LoadObject(path)
     * .then((model) => {
     * let loadedModelData = {
     * object: model,
     * id: part.id,
     * type: part.type,
     * materials: part.materials || []
     * };
     *
     * loadedModels.push(loadedModelData);
     * count++;
     *
     * app.dispatch({
     * type: ViewerEvents.LOADING_PROGRESS,
     * data: count / total
     * });
     * if (count >= total) {
     * resolve([loadedModels, loadedMaterials]);
     * }
     * })
     * .catch(reject);
     * }
     * });
     *
     * _each(props.resources.materials, (material) => {
     * loadedMaterials[material.id] = new MeshPhysicalMaterial({
     * name: material.id
     * });
     * loadedMaterials[material.id].side = DoubleSide;
     * loadedMaterials[material.id].color =
     * material.color || material.color === 0
     * ? new Color(material.color)
     * : new Color('#ffffff');
     *
     * if (material.metallic) {
     * loadedMaterials[material.id].envMap = envMap;
     * loadedMaterials[material.id].envMapIntensity = 1.0;
     * loadedMaterials[material.id].clearCoat = 1.0;
     * loadedMaterials[material.id].clearCoatRoughness = 0.0;
     * loadedMaterials[material.id].reflectivity = 1.0;
     * loadedMaterials[material.id].metalness = 0.99;
     * loadedMaterials[material.id].roughness = 0.2;
     * } else {
     * loadedMaterials[material.id].envMap = envMap;
     * loadedMaterials[material.id].clearCoat = 0.0;
     * loadedMaterials[material.id].clearCoatRoughness = 0.99;
     * loadedMaterials[material.id].reflectivity = 0.0;
     * loadedMaterials[material.id].metalness = 0.0;
     * loadedMaterials[material.id].roughness = 0.99;
     * }
     * material.maps = material.maps || {};
     *
     * _each(material.maps, (map, key) => {
     * AssetsLoader.LoadTextures(map)
     * .then((img) => {
     * let texture = new Texture(img);
     * texture.wrapS = RepeatWrapping;
     * texture.wrapT = RepeatWrapping;
     * texture.needsUpdate = true;
     *
     * loadedMaterials[material.id][key] = texture;
     *
     * count++;
     *
     * app.dispatch({
     * type: ViewerEvents.LOADING_PROGRESS,
     * data: count / total
     * });
     * if (count >= total) {
     * resolve([loadedModels, loadedMaterials]);
     * }
     * })
     * .catch(reject);
     * });
     * });
     * });
     *}
     */

    /**
     * Loads all resources described in JSON file
     * @param {String} JSONPath  path to JSON file
     * @param {WebGLRenderer} renderer  renderer for prerendering textures
     */
    static loadAssets(JSONPath, renderer) {
        let tasks;

        return Loader.loadAsArray(0, LoaderResourceTypes.JSON, JSONPath)
            .then((json) => {
                // Console.log('json', json);
                const threadTasks = [];
                for (let i = 0; i < threadsNumber; i += 1) {
                    threadTasks.push([]);
                }
                const groups = _groupBy(json, (item) => item.type);
                // Console.log('groups', groups);
                for (let key in groups) {
                    let chunks = _chunk(
                        groups[key],
                        Math.ceil(groups[key].length / threadsNumber)
                    );
                    // Console.log('chunks', chunks);
                    for (let i = 0; i < threadsNumber; i += 1) {
                        if (chunks[i]) {
                            threadTasks[i].push(chunks[i]);
                        }
                    }
                    if (chunks[threadsNumber]) {
                        threadTasks[threadsNumber - 1] = threadTasks[
                            threadsNumber - 1
                        ].concat([chunks[threadsNumber]]);
                    }
                }
                // Console.log('threadTasks', threadTasks);

                return new Promise((resolve, reject) => resolve(threadTasks));
            })
            .then((t) => {
                tasks = _flatten(t);
                // Console.log('tasks', tasks);
                let promises = [];
                let threadNumber = -1;
                for (let threadTasks of t) {
                    threadNumber += 1;
                    for (let groupedTasks of threadTasks) {
                        let paths = [];
                        for (let i = 0; i < groupedTasks.length; i += 1) {
                            if (groupedTasks[i].path instanceof Array) {
                                for (let j = 0; j < groupedTasks[i].path.length; j += 1) {
                                    paths.push(groupedTasks[i].path[j]);
                                }
                            } else {
                                paths.push(groupedTasks[i].path);
                            }
                        }
                        promises.push(Loader.loadAsArray(
                            threadNumber,
                            AssetsLoader.loaderResourceType(groupedTasks[0].type),
                            paths
                        ));
                    }
                }
                // Console.log('promises', promises);

                return Promise.all(promises);
            })
            .then((data) => {
                // Console.log('data', data);
                let promises = [];
                for (let i = 0; i < tasks.length; i += 1) {
                    for (let j = 0; j < tasks[i].length; j += 1) {
                        if (tasks[i][0].type === ResourceTypes.CubeTexture) {
                            tasks[i][j].data = data[i].slice(6 * j, 6 * j + 6);
                        } else {
                            tasks[i][j].data = data[i][j];
                        }
                        promises.push(AssetsLoader.load(tasks[i][j], renderer));
                    }
                }
                // Console.log(tasks, 'all resources loaded successfully');

                // Return new Promise((resolve, reject) => resolve(_flatten(tasks)));
                return Promise.all(promises);
            })
            .then((data) => {
                return new Promise((resolve, reject) => resolve(_flatten(tasks)));
            })
            .catch((err) => {
                return new Promise((resolve, reject) => reject(err));
            });
    }
}

export default AssetsLoader;
