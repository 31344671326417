import DoBaseAction from "c/ThreeJsWrap/Viewer/UndoRedo/DoBaseAction";

class DoAction extends DoBaseAction {
    viewer; // settled in DoManager.registerDo
    
    get entityMgr() {
        return this.viewer.entityMgr;
    }
    
    updateRender(justNow) {
        this.viewer.render(justNow);
    }
}

export default DoAction;