/* eslint-disable no-self-compare */
import React, { PureComponent } from 'react';

export interface Props {
  value: react.components.utils.IntInputValue;
  onChange: react.components.utils.IntInputOnChange;
  className: className<'input'>;
}

export class IntInput extends PureComponent<Props> {
  static defaultProps: Pick<Props, 'className'> = {
    className: '' as Props['className']
  }

  onChange: ( e: React.FormEvent<HTMLInputElement> ) => void =
  ( { currentTarget: { value } } ) => {
    const t = value.trim().replace( /^0+([^\.])/, '$1' ),
      { onChange } = this.props;

    if( t === '' ) return onChange( 0 );

    const numbered = Number( t );
    if( numbered !== numbered ) return;

    onChange( Math.abs( parseInt( t, 10 ) ) );
  }

  render() {
    const {
      props: { value, className = '', ...rest },
      onChange
    } = this;

    return (
      <input { ...{
        ...rest, value, type: 'text', onChange, className
      } }
      />
    );
  }
}
