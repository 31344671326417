// @ts-check

/** @type { Partial<CabD.A.ContainerConfigs.ContainerConfig> } */
export const OpenBaseWithShelves = {
  height: 36,
  depth: 24,
  width: 36,
  name: 'Open Base With Shelves',
  isTopLevel: true,
  children: [
    {
      name: 'ToeKickAssembly',
      layout: 'DEPTH',
      height: 3,
      children: [
        {
          name: 'ToeKickFinishEnds',
          layout: 'WIDTH',
          children: [
            {
              width: 0.5,
              type: 'Panel',
              name: 'ToeKickAssembly--LeftPanel'
            },
            {
              name: 'ToeKickAssembly--HollowCenter'
            },
            {
              width: 0.5,
              type: 'Panel',
              name: 'ToeKickAssembly--RightPanel'
            }
          ]
        },
        {
          name: 'ToeKickAssembly--FrontPanel',
          type: 'Panel',
          depth: 0.5
        },
        {
          name: 'ToeKickAssembly--ToeKickSetBack',
          depth: 2
        }
      ]
    },
    {
      name: 'BaseAssembly',
      layout: 'DEPTH',
      children: [
        {
          name: 'BaseCarcassAssembly',
          layout: 'WIDTH',
          children: [
            {
              name: 'BaseLeftSide',
              type: 'Panel',
              width: 0.5
            },
            {
              name: 'BaseCarcassAssembly--InteriorAssembly',
              children: [
                {
                  name: 'BaseBottom',
                  height: 0.5,
                  type: 'Panel'
                },
                {
                  name: 'BaseInteriorAssembly',
                  layout: 'DEPTH',
                  children: [
                    {
                      name: 'BaseBack',
                      type: 'Panel',
                      depth: 0.5
                    },
                    {
                      name: 'BaseInterior',
                      children: [
                        {
                          name: 'Interior',
                          children: [
                            {},
                            {
                              height: 0.5,
                              type: 'Panel',
                              name: 'Shelf'
                            },
                            {},
                            {
                              height: 0.5,
                              type: 'Panel',
                              name: 'Shelf'
                            },
                            {}
                          ]
                        },
                        {
                          name: 'BaseFullTop',
                          type: 'Panel',
                          height: 0.5
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              name: 'BaseRightSide',
              width: 0.5,
              type: 'Panel'
            }
          ]
        },
        {
          name: 'FrontExteriorAssembly',
          layout: 'WIDTH',
          depth: 0.5
        }
      ]
    }
  ]
};

