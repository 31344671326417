import Storage from 'scr/utilitiesStorage';
import DoBaseAction from './DoBaseAction';

class DoAction extends DoBaseAction {
    get viewer() {
        return Storage.get('viewer');
    }
    get selectedObject() {
        return Storage.get('selectedObject');
    }
}

export default DoAction;