import {LoaderActions, LoaderResourceTypes, threadsNumber} from './interfaces/Loader';
import EventEmitter from './EventEmitter';
import LoaderWorker from '../loaders/Loader.worker';
import _each from 'lodash/each';
import _find from 'lodash/find';
import _remove from 'lodash/remove';
import {resolve} from 'path';

/** @ignore */
const loaders = [];

for (let i = 0; i < threadsNumber; i += 1) {
    loaders[i] = new LoaderWorker();
}

/** @ignore */
let loaded = -1;

/**
 * Helps to load resources in other thread
 */
class Loader extends EventEmitter {
    /**
     * @constructor
     */
    constructor() {
        super();

        /** @type {Array} Used internally to stack loadable resources */
        this.processes = [];
        /** @type {string} Used as base part of the url for each loadable resource*/
        this.origin = location.origin;
        const error = (e) => console.error(e);
        const message = (e) => {
            let event = e.data;

            if (isNaN(event.id)) {
                throw new Error('Id must be specified');
            }

            let process = _find(this.processes, {id: event.id});

            if (!process) {
                return false;
            }

            switch (event.action) {
            case LoaderActions.FINISH:
                if (process.onLoad) {
                    process.onLoad(event.data);
                }

                _remove(this.processes, (process) => {
                    return process.id === event.id;
                });
                break;
            case LoaderActions.ERROR:
                if (process.onError) {
                    process.onError(event.data);
                }

                _remove(this.processes, (process) => {
                    return process.id === event.id;
                });
                break;
            default:
                break;
            }

            return true;
        };
        for (let i = 0; i < threadsNumber; i += 1) {
            loaders[i].onmessage = message;
            loaders[i].onerror = error;
        }
    }

    /**
     * Loads resource using new thread
     * @param path {string} Path to the resource
     * @param type {number} Type of the loadable resource
     * @param onLoad {function} Callback that will be executed when model is loaded
     * @param onError {function} Callback that will be executed when model loading is failed
     */
    __load(threadNumber, path, type, onLoad, onError) {
        let data = {
            id: ++loaded,
            onLoad,
            onError
        };

        let resourcePath = path;
        if (this.origin) {
            resourcePath = this.origin + resolve(path);
        }

        this.processes.push(data);
        loaders[threadNumber].postMessage({
            type,
            id: data.id,
            path: resourcePath
        });
    }

    /**
     * Loads some type of resources
     */
    load(threadNumber, loaderResourceType, path, resolve, reject) {
        switch (loaderResourceType) {
        case LoaderResourceTypes.TEXT:
            this.loadAsText(threadNumber, path, resolve, reject);
            break;
        case LoaderResourceTypes.BLOB:
            this.loadAsBlob(threadNumber, path, resolve, reject);
            break;
        case LoaderResourceTypes.JSON:
            this.loadAsJSON(threadNumber, path, resolve, reject);
            break;
        case LoaderResourceTypes.ARRAYBUFFER:
            this.loadAsArrayBuffer(threadNumber, path, resolve, reject);
            break;
        default:
            break;
        }
    }

    /**
     * Loads models as text content
     * @param path {string} Path to the model
     * @param onLoad {function} Callback that will be executed when model is loaded
     * @param onError {function} Callback that will be executed when model loading is failed
     */
    loadAsText(threadNumber, path, resolve, reject) {
        this.__load(
            threadNumber,
            path,
            LoaderResourceTypes.TEXT,
            (txt) => resolve(txt),
            () => reject(new Error(`Error loading as text : ${path}`))
        );
    }

    /**
     * Loads JSON
     * @param path {string} Path to JSON
     * @param onLoad {function} Callback that will be executed when model is loaded
     * @param onError {function} Callback that will be executed when model loading is failed
     */
    loadAsJSON(threadNumber, path, resolve, reject) {
        this.__load(
            threadNumber,
            path,
            LoaderResourceTypes.JSON,
            (json) => resolve(json),
            () => reject(new Error(`Error loading JSON : ${path}`))
        );
    }

    /**
     * Loads arrayBuffer
     * @param path {string} Path to binary Data
     * @param onLoad {function} Callback that will be executed when model is loaded
     * @param onError {function} Callback that will be executed when model loading is failed
     */
    loadAsArrayBuffer(threadNumber, path, resolve, reject) {
        this.__load(threadNumber, path, LoaderResourceTypes.ARRAYBUFFER, resolve, reject);
    }

    /**
     * Loads image
     * @param path {string} Path to the image
     * @param onLoad {function} Callback that will be executed when image is loaded
     * @param onError {function} Callback that will be executed when image loading is failed
     */
    loadAsBlob(threadNumber, path, resolve, reject) {
        this.__load(
            threadNumber,
            path,
            LoaderResourceTypes.BLOB,

            (blob) => {
                resolve(blob);

                /*
                 * Img.onload = () => {
                 * resolve(img);
                 * };
                 * img.src = URL.createObjectURL(blob);
                 */
            },
            () => reject(new Error(`Error loading blob : ${path}`))
        );
    }

    /**
     * Loads image or array of images
     * @param path {string|array} Path to the image or array of paths
     * @param onLoad {function} Callback that will be executed when image/images is/are loaded
     * @param onError {function} Callback that will be executed when image/images loading is/are failed
     */
    loadAsArray(threadNumber, loaderResourceType, path) {
        if (path instanceof Array) {
            let promises = [];
            _each(path, (p) => {
                promises.push(new Promise((resolve, reject) => {
                    this.load(threadNumber, loaderResourceType, p, resolve, reject);
                }));
            });

            return Promise.all(promises);
        }

        return new Promise((resolve, reject) => {
            this.load(threadNumber, loaderResourceType, path, resolve, reject);
        });
    }
}

/** @ignore */
const handler = new Loader();

export default {
    loadAsArray: (threadNumber, loaderResourceType, path) => handler.loadAsArray(threadNumber, loaderResourceType, path),
    setOrigin: (origin) => {
        handler.origin = origin;
    }
};
