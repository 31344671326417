/* eslint-disable import/prefer-default-export */
// @ts-check
import { CollidableMesh } from '../core/CollidableMesh';

/** @this { import('../core/BlueprintDummy')['BlueprintDummy'] } */
export function addCube() {
  const { width, height, depth } = this.size;
  if ( this._cube ) this.origin.remove( this._cube );

  let newGeometry = new THREE.BoxGeometry( width, height, depth );
  let newMaterial = new THREE.MeshBasicMaterial( { visible: false } );
  this._cube = new CollidableMesh( newGeometry, newMaterial );

  this._cube.position.y = height / 2;
  this._cube.arrows = [];
  this._cube.geometry.verticesNeedUpdate = true;

  this.origin.add( this._cube );
}
