import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Button } from '@npmrost/storybook/stories/Vesta/Button';
import { Copy, Remove } from '@npmrost/storybook/stories/Vesta/Icons';
import {
  copyProject, removeProject, openProject
} from '@r/reducers/storeParts/calculationMode/projects/existingProjects';

const Wrapper = styled.div`
  height: 73%;
  margin-top: 20px;
`;

const StyledPerfectScrollbar = styled( PerfectScrollbar )`
  width: calc(100% + 14px);
  padding-right: 7px;
`;

const RowWrapper = styled.div`
  margin-bottom: 10px;
  padding: 3px;
  cursor: pointer;
  ${ ( { theme: t, chosen } ) => `
    background: ${ chosen ? t.mainGradient : t.generalBackground };
  ` }
  &:hover {
    ${ ( { theme: t } ) => `
    background: ${ t.mainGradient };
  ` }
  }
`;

const Row = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ ( { theme: t } ) => `
    background: ${ t.generalBackground };
  ` }
`;

const Name = styled.div`
  width: 50%;
`;

const Date = styled.div`
  width: 35%;
  margin-left: 20px;
`;

const Icons = styled.div`
  width: 15%;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Tag = styled.div`
  width: 28px;
  height: 28px;
  padding: 2px;
  border-radius: 2px;
  ${ ( { theme: t } ) => `
    background: ${ t.mainGradient };
    color: ${ t.secondaryColor };
  ` }
`;

const TagLetter = styled.span`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  ${ ( { theme: t } ) => `
    background: ${ t.generalBackground };
  ` }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const NoProjects = styled.div`
  margin-top: 20px;
  font-size: 18px;
  text-align: center;
`;

class Projects extends PureComponent {

  constructor( props ) {
    super( props );
    this.state = {
      chosen: ''
    };
  }

  copy = ( e ) => {
    e.stopPropagation();

    this.props.dispatch( copyProject( e.currentTarget.dataset.id ) )
      .catch( ( err ) => {
        alert( err );
      } );
  }

  remove = ( e ) => {
    e.stopPropagation();

    const {
      props: { dispatch },
      state: { chosen }
    } = this;

    const id = e.currentTarget.dataset.id;

    dispatch( removeProject( id ) )
      .then( () => {
        if ( chosen === id ) {
          this.setState( { chosen: '' } );
        }
      } )
      .catch( ( err ) => {
        alert( err );
      } );
  }

  choose = ( e ) => {
    this.setState( { chosen: e.currentTarget.dataset.id } );
  }

  open = () => {
    const {
      state: { chosen },
      props: { dispatch, close }
    } = this;

    dispatch( openProject( chosen ) )
      .then( () => {
        close();
      } )
      .catch( ( err ) => {
        alert( err );
      } );
  }

  render() {

    const {
      props: {
        texts: {
          createdOn: createdOnText,
          modifiedOn: modifiedOnText,
          projectTagsLetters,
          openProject: openProjectText,
          noProjects,
          noProjectsBySuchCriteria
        },
        existingProjects,
        chosenTags
      },
      state: {
        chosen
      },
      copy,
      remove,
      choose,
      open
    } = this;

    if ( !existingProjects.data || !existingProjects.data.length ) {
      return (
        <NoProjects>
          { chosenTags.length ? noProjectsBySuchCriteria : noProjects }
        </NoProjects>
      );
    }

    return (
      <>
        <Wrapper>
          <StyledPerfectScrollbar>
            {
              existingProjects.data.map( ( {
                _id, updatedAt, createdAt,
                settings: { information: { projectName: name, tag } }
              } ) => (
                <RowWrapper chosen={ chosen === _id }>
                  <Row
                    key={ _id }
                    data-id={ _id }
                    onClick={ choose }
                  >
                    <Name>
                      { name }
                    </Name>
                    <Date>
                      {
                        updatedAt
                          ? `${ modifiedOnText } ${ moment( updatedAt ).locale( 'en' ).format( 'MMMM DD, YYYY' ) }`
                          : `${ createdOnText } ${ moment( createdAt ).locale( 'en' ).format( 'MMMM DD, YYYY' ) }`
                      }
                    </Date>
                    <Icons>
                      <Tag>
                        <TagLetter>{ projectTagsLetters[ tag ] }</TagLetter>
                      </Tag>
                      <div data-id={ _id } onClick={ copy }>
                        { Copy }
                      </div>
                      <div data-id={ _id } onClick={ remove }>
                        { Remove }
                      </div>
                    </Icons>
                  </Row>
                </RowWrapper>

              ) )
            }
          </StyledPerfectScrollbar>
        </Wrapper>
        {
          chosen && (
            <ButtonWrapper>
              <Button primary onClick={ open }>
                { openProjectText }
              </Button>
            </ButtonWrapper>
          )
        }
      </>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: texts
    },
    calculationMode: {
      projects: { existingProjects, chosenTags }
    }
  } ) => ( {
    texts,
    existingProjects,
    chosenTags
  } )
)( Projects );
