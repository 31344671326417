/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import {
  LabeledField
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledField';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import Storage from 'scr/utilitiesStorage';
import {
  GenericCabinet
} from 'cSrc/structured/singletonCabinet/core/GenericCabinet';
import {
  ContainerConfig, cabinetConfig
} from 'decl/cabinetdesigner/ContainerConfigs.d.ts';
import { quantity } from '@npmrost/utils';
import CheckChangedValue from 'c/shared/borderForChangedValue';
import SimpleInput from 'c/CalculationLayer/utils/SimpleInput';


interface _IStateProps {
  shelves: redux._IStore['selectedCabinet']['shelves'];
}

interface _IDispatchProps {
  shelves: redux._IStore['selectedCabinet']['shelves'];
  dispatch: Dispatch<
    redux._IActions['SET_SELECTED_CABINET_SHELVES_QTY'] |
    redux._IActions['setRightSideCabinetmenuMode']
  >;
}

const StyledLabeledField = styled( LabeledField )`
  margin-top: 10px;
`;

export function findShelvesQty( config: cabinetConfig ): quantity {
  if( config.type === 'Door' || config.type === 'Panel' ) {
    return 0 as quantity;
  }

  if( config.name === 'Interior' ) {
    return ( ( config.children.length - 1 ) / 2 ) as quantity;
  }
  if( !config.children ) return 0 as quantity;

  return config.children
    .reduce<quantity>(
      ( acc, child ) => (
        acc === 0 as quantity
          ? findShelvesQty( child as ContainerConfig )
          : acc
      ),
      0 as quantity
    );
}

function setShelvesQty( config: cabinetConfig, n: quantity ) {
  if( config.type === 'Door' || config.type === 'Panel' ) return;

  if( config.name === 'Interior' ) {
    // eslint-disable-next-line no-param-reassign
    config.children = new Array( n * 2 + 1 + 1 )
      .join( '+' )
      .split( '' )
      .map( ( _, i ) => ( i % 2 === 0 ? {} : {
        height: 0.5 as inches,
        type: 'Panel',
        name: 'Shelf'
      } ) );
  }

  if( !config.children ) return;

  config.children
    .forEach( ( child ) => setShelvesQty( child as ContainerConfig, n ) );
}

export const ShelvesQty = connect<
  _IStateProps,
  _IDispatchProps,
  {},
  redux._IStore
>(
  ( s ) => ( { shelves: s.selectedCabinet.shelves } )
)(
  class ShelvesQty extends PureComponent<_IStateProps & _IDispatchProps> {
    // eslint-disable-next-line react/state-in-constructor
    state = {
      newShelves: 0
    };

    componentDidMount(): void {
      const { shelves } = this.props;
      this.setState( {
        newShelves: shelves
      } );
    }

    componentDidUpdate( prevProps: _IDispatchProps ): void {
      const { shelves } = this.props;

      if( shelves !== prevProps.shelves ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState( {
          newShelves: shelves
        } );
      }
    }

    onChange = () => {
      const { newShelves } = this.state;
      const shelvesQty = String( newShelves );
      const { props: { dispatch } } = this;
      const viewer = Storage.get( 'viewer' );

      dispatch( {
        type: 'SET_SELECTED_CABINET_SHELVES_QTY',
        value: Number( shelvesQty ) as quantity
      } );

      const cabinet = Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet;
      const config = cabinet.getConfig() as cabinetConfig;
      const angle = cabinet.getDoorsAngle();
      setShelvesQty( config, Number( shelvesQty ) as quantity );
      viewer.transformControls && viewer.transformControls.detach();

      const newCab = cabinet.reloadConfig( config );
      newCab.openAllDoors( angle );

      Storage.set( 'selectedObject', [newCab] );
      viewer.outlinePass!.selectedObjects = Storage.get( 'selectedObject' );
      viewer.renderOnDemand.set();

    }

    handleValueChange = ( value: string ) => {
      const checkRegExp = /^\d*$/;
      const newValue = String( Number( value ) );
      if( newValue.match( checkRegExp ) ) {
        this.setState( {
          newShelves: newValue
        } );
      }
      if( !value ) {
        this.setState( {
          newShelves: 0
        } );
      }
    }

    render() {
      const { props: { shelves }, onChange, handleValueChange } = this;
      const { newShelves } = this.state;

      return (
        <CheckChangedValue changed={ newShelves !== shelves }>
          <StyledLabeledField name='Shelves qty'>
            <SimpleInput
              className='shelves-qty-input'
              value={ String( newShelves ) }
              onChange={ handleValueChange }
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              onBlur={ onChange }
            />
          </StyledLabeledField>
        </CheckChangedValue>
      );
    }
  }
);
