export default ( {
  drawerHeight = '', doubleDrawerHeight = '', height, toeKickHeight
} ) => {
  if ( !drawerHeight && !doubleDrawerHeight ) return [];

  const heights = ( doubleDrawerHeight || drawerHeight ).split( ';' );
  const drawerHeightCount = heights.length;
  const constHeight = Number( heights[ 0 ] ) || height;

  let drawers = [];

  if ( doubleDrawerHeight ) {
    drawers = [constHeight, constHeight];
  } else if ( drawerHeightCount === 1 ) {
    drawers = [Number( ( height - toeKickHeight ).toFixed( 3 ) )];
  } else if ( drawerHeightCount === 2 ) {
    const halfHeight = Number(
      ( ( height - toeKickHeight ) / 2 ).toFixed( 3 )
    );

    drawers = [halfHeight, halfHeight];
  } else {
    drawers.push( constHeight );

    for ( let i = 0; i < drawerHeightCount - 1; i++ ) {
      drawers.push( Number(
        ( ( height - constHeight - toeKickHeight ) / ( drawerHeightCount - 1 ) )
          .toFixed( 3 )
      ) );
    }
  }

  return drawers;
};
