import Konva from 'konva';


const container = document.createElement( 'div' );
export const KonvaStage = new Konva.Stage( { container } );

export function clearStageAndDrawBackground(
  stage: typeof KonvaStage,
  canvas: HTMLCanvasElement,
  scaledWidth = canvas.width
) {
  const scale = scaledWidth / canvas.width;
  const scaledHeight = canvas.height * scale;

  stage.clear();
  stage.removeChildren();
  stage.clearCache();
  stage.width( scaledWidth );
  stage.height( scaledHeight );

  const layer = new Konva.Layer();
  stage.add( layer );

  const image = new Konva.Image( {
    image: canvas,
    width: scaledWidth,
    height: scaledHeight
  } );

  layer.add( image );
  layer.batchDraw();

  return stage;
}
