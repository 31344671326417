import groups from './groups';

const sumObjValues = ( obj ) => {
  let sum = 0;
  // eslint-disable-next-line no-restricted-syntax
  for ( let key in obj ) {
    // eslint-disable-next-line no-prototype-builtins
    if ( obj.hasOwnProperty( key ) ) {
      sum += obj[ key ];
    }
  }

  return sum;
};

const calcEdgebandingCost = ( {
  group,
  edgebanding: { name, costPerFt },
  coreParts,
  otherParts,
  drawers
} ) => {

  const { core, other } = groups[ group ]( { drawers } );

  core.forEach( ( {
    edgeL1, edgeL2, edgeW1, edgeW2
  }, index ) => {
    console.log( `${ coreParts[ index ].name }:
      Edge L1: ${ edgeL1 }
      Edge L2: ${ edgeL2 }
      Edge W1: ${ edgeW1 }
      Edge W2: ${ edgeW2 }
    ` );
  } );

  other.forEach( ( {
    edgeL1, edgeL2, edgeW1, edgeW2
  }, index ) => {
    console.log( `${ otherParts[ index ].name }:
      Edge L1: ${ edgeL1 }
      Edge L2: ${ edgeL2 }
      Edge W1: ${ edgeW1 }
      Edge W2: ${ edgeW2 }
    ` );
  } );

  const lossQty =
    coreParts
      .map( ( item, i ) => item.qty * sumObjValues( core[ i ] ) )
      .reduce( ( acc, value ) => acc + value ) +
    otherParts
      .map( ( item, i ) => item.qty * sumObjValues( other[ i ] ) )
      .reduce( ( acc, value ) => acc + value );

  for ( let i = 0; i < core.length; i++ ) {
    const {
      edgeL1, edgeL2, edgeW1, edgeW2
    } = core[ i ];

    core[ i ].edgeL1 = edgeL1
      ? coreParts[ i ].qty * coreParts[ i ].length
      : 0;
    core[ i ].edgeL2 = edgeL2
      ? coreParts[ i ].qty * coreParts[ i ].length
      : 0;
    core[ i ].edgeW1 = edgeW1
      ? coreParts[ i ].qty * coreParts[ i ].width
      : 0;
    core[ i ].edgeW2 = edgeW2
      ? coreParts[ i ].qty * coreParts[ i ].width
      : 0;
  }

  for ( let i = 0; i < other.length; i++ ) {
    const {
      edgeL1, edgeL2, edgeW1, edgeW2
    } = other[ i ];

    other[ i ].edgeL1 = edgeL1
      ? otherParts[ i ].qty * otherParts[ i ].length
      : 0;
    other[ i ].edgeL2 = edgeL2
      ? otherParts[ i ].qty * otherParts[ i ].length
      : 0;
    other[ i ].edgeW1 = edgeW1
      ? otherParts[ i ].qty * otherParts[ i ].width
      : 0;
    other[ i ].edgeW2 = edgeW2
      ? otherParts[ i ].qty * otherParts[ i ].width
      : 0;
  }

  const totalInches =
    core
      .map( ( item ) => sumObjValues( item ) )
      .reduce( ( acc, value ) => acc + value ) +
    other
      .map( ( item ) => sumObjValues( item ) )
      .reduce( ( acc, value ) => acc + value );

  console.log( `Total inches: ${ totalInches }` );
  console.log( `Loss quantity: ${ lossQty }` );

  const lossFactor = 4;

  console.log( `Loss factor: ${ lossFactor }` );

  const lossInches = lossQty * lossFactor;

  console.log( `Loss inches: ${ lossInches }` );

  const totalLength = totalInches + lossInches;

  console.log( `Total length: ${ totalLength }` );
  console.log( `Cost per linear ft: ${ costPerFt }` );

  const totalCost = Number(
    ( totalLength * Number( costPerFt ) / 12 ).toFixed( 2 )
  );

  console.log( `Total cost: ${ totalCost }` );

  return {
    name,
    length: Number( totalLength.toFixed( 3 ) ),
    // doubled prices
    cost: 2 * totalCost
  };
};

export default ( { totalEdgebanding, ...rest } ) => {

  let newTotalEdgebanding = totalEdgebanding.slice();

  const newEdgebanding = calcEdgebandingCost( { ...rest } );

  const {
    name: newName,
    length: newLength,
    cost: newCost
  } = newEdgebanding;

  const edgebandingExists = newTotalEdgebanding
    .find( ( item ) => item.name === newName );

  if ( edgebandingExists ) {
    for ( let i = 0; i < newTotalEdgebanding.length; i++ ) {
      const { name, cost } = newTotalEdgebanding[ i ];

      if ( name === newName ) {
        newTotalEdgebanding[ i ].length += newLength;
        newTotalEdgebanding[ i ].cost = Number(
          ( cost + newCost ).toFixed( 2 )
        );

        break;
      }
    }
  } else {
    newTotalEdgebanding.push( newEdgebanding );
  }

  return newTotalEdgebanding;
};
