import { simpleSet } from '@r/reducers/utils';
import { percent } from '@npmrost/utils';

const setCalculationModeMarkupValue = simpleSet as
  redux.reducers.calculationMode.adminSettings.projectSettings.materials.markup.value;

export const value = setCalculationModeMarkupValue(
  'SET_CALCULATION_MODE_ADMIN_SETTINGS_MARKUP_VALUE',
  0 as percent
);
