export const SimpleWallWindow = {
  height: 36,
  depth: 2.5,
  width: 60,

  name: 'Wall Window',
  isTopLevel: true,
  mountTypes: [
    'wall'
  ],
  cabinetMaterialName: '300_Classic_White_Blanc',// '555_NovaWhite_NovaBlanc',
  glassMaterialName: 'white',// '555_NovaWhite_NovaBlanc',

  constrName: 'WallWindow',

  layout: 'DEPTH',
  children: [
    {
      name: 'WindowBodyAssembly',
      layout: 'HEIGHT',
      children: [
        {
          name: 'Sill',
          type: 'Panel',
          height: 0.8
        },
        {
          name: 'UpperAssembly',
          layout: 'WIDTH',
          children: [
            // {
            //   name: 'LeftSpace',
            //   width: 0.5,
            // },
            {
              name: 'LeftFrame',
              type: 'Panel',
              width: 2
            },
            {
              layout: 'HEIGHT',
              children: [
                { // inner-body
                  layout: 'DEPTH',
                  children: [
                    {
                      depth: 0.5,
                    },
                    {
                      layout: 'WIDTH',
                      children: [
                        {
                          name: 'Left Glass',
                          layout: 'WIDTH',
                          children: [
                            {
                              type: 'Panel',
                              width: 1.05,
                            },
                            {
                              layout: 'HEIGHT',
                              children: [
                                {
                                  type: 'Panel',
                                  height: 1.05,
                                },
                                {
                                  layout: 'WIDTH',
                                  children: [
                                    {
                                      name: 'glass',
                                      type: 'Panel',
                                      depth: 0.2,
                                      panelParent: 'glass',
                                    },
                                  ]
                                },
                                {
                                  type: 'Panel',
                                  height: 1.05,
                                }
                              ],
                            },
                            {
                              type: 'Panel',
                              width: 1.05,
                            },
                          ],
                        },
                        {
                          name: 'Right Glass',
                          layout: 'WIDTH',
                          children: [
                            {
                              type: 'Panel',
                              width: 1.05,
                            },
                            {
                              layout: 'HEIGHT',
                              children: [
                                {
                                  type: 'Panel',
                                  height: 1.05,
                                },
                                {
                                  layout: 'WIDTH',
                                  children: [
                                    {
                                      name: 'glass',
                                      type: 'Panel',
                                      depth: 0.2,
                                      panelParent: 'glass',
                                    },
                                  ]
                                },
                                {
                                  type: 'Panel',
                                  height: 1.05,
                                }
                              ],
                            },
                            {
                              type: 'Panel',
                              width: 1.05,
                            },
                          ],
                        }
                      ],
                    },
                    {
                      depth: 0.5,
                    },
                  ],
                },
                {
                  name: 'TopFrame',
                  type: 'Panel',
                  height: 2,
                },
              ],
            },
            {
              name: 'RightFrame',
              type: 'Panel',
              width: 2
            },
            // {
            //   name: 'RightSpace',
            //   width: 0.5,
            // },
          ],
        },
      ],
    },
    {
      name: 'FrontExteriorAssembly',
      layout: 'WIDTH',
      depth: 0.5
    },
  ],
};
