import { combineReducers } from 'redux';

import {
  getTreeViewStructure, groupData, getTreeViewDefaultValue
} from '@r/reducers/storeParts/calculationMode/utils';

import { value } from './value';
import { options } from './options';
import { searchText } from './searchText';

export const countertop = combineReducers( {
  value,
  options,
  searchText
} );

export function setDefaultCountertop() {
  return ( dispatch, getStore ) => {
    const { calculationMode: { materialsFromDb } } = getStore();

    const options = getTreeViewStructure(
      groupData( materialsFromDb.Countertop, ['type', 'company', 'thickness'] ),
      'code'
    );

    dispatch( {
      type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_COUNTERTOP_OPTIONS',
      value: options
    } );

    dispatch( {
      type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_COUNTERTOP_VALUE',
      value: getTreeViewDefaultValue( options )
    } );
  };
}
