/* eslint-disable no-warning-comments */
/* eslint-disable no-return-assign */
/* eslint-disable no-undef */
// @ts-check
import Storage from 'scr/utilitiesStorage';
import { BoxGeometry, Vector3 } from 'three';
import { createGeometry } from 'c/ThreeJsWrap/Viewer/core/helpers/geometry';
import Panel from './Panel';

class Handle extends THREE.Mesh {
  constructor() {
    const material = Storage.get( 'materials' ).handle[ 0 ]
      .material; /* New THREE.MeshPhysicalMaterial({
      color: 'black',
      side: THREE.DoubleSide
    }); */
    super(
      createGeometry( new BoxGeometry( 2 / 2.54, 16 / 2.54, 2 / 2.54 ) ),
      material
    );
    this.receiveShadow = true;
    this.castShadow = true;
    this.position.copy( new Vector3( 0, 0, 2 ) );
    this.vestaObject.setType( 'handle' );
  }

  /** @type { Handle['getSizes'] } */
  getSizes() {
    const {
      width,
      depth,
      height
    } = /** @type { DoorHandle['geometry'] } */ this.geometry.parameters;

    return {
      width,
      depth,
      height
    };
  }
}

export default class Door extends Panel {
  /** @type { Door['handle'] } */
  handle;

  /** @type { Door['handleOrientation'] } */
  handleOrientation;

  /** @type { Door['handlePosition'] } */
  handlePosition = {
    oZ: 'onSurface',
    oX: { type: 'center', value: null },
    oY: { type: 'center', value: null }
  };

  constructor( /** @type { CabD.A.ContainerConfigs.DoorConfig } */ config ) {
    super( config );

    this.handle = /** @type { CabD.A.Door['handle'] } */ new Handle();
    this.mesh.add( this.handle );
    this.handleOrientation = 'VERTICAL';
    this.mesh.vestaObject.setType( 'panel/door' );
    this.isDoor = true;
  }

  setHandleOrientation(
    /** @type { CabD.A.Door['handleOrientation'] } */ value
  ) {
    this.handleOrientation = value;
    const r = this.handle.rotation;

    if ( value === 'HORIZONTAL' ) return void ( r.z = Math.PI / 2 );
    if ( value === 'VERTICAL' ) return void ( r.z = 0 );

    return void ( r.z = ( value * Math.PI ) / 180 );
  }

  /** @type { CabD.A.Door['setHandlePositionAlongX'] } */
  setHandlePositionAlongX(
    /** @type { CabD.A.Door['handlePosition']['oX'] } */ val = {
      type: 'center',
      value: null
    }
  ) {
    this.handlePosition.oX = val;
    this.updateHandlePositionAlongX();
  }

  /** @type { CabD.A.Door['getHandlePositionAlongX'] } */
  getHandlePostionAlongX() {
    return this.handlePosition.oX;
  }

  /** @type { CabD.A.Door['updateHandlePositionAlongX'] } */
  // eslint-disable-next-line consistent-return
  updateHandlePositionAlongX() {
    const { type, value } = this.getHandlePostionAlongX();
    const { width } = this.getSizes();
    const { height: hHeight } = this.handle.getSizes();

    /*
     * For 'center' and 'free' positioning modes we just set coordinate
     * absolutely
     */
    if ( type === 'center' || type === 'free' ) {
      // eslint-disable-next-line no-return-assign
      return void (
        this.handle.position.x = type === 'center' ? 0 : Number( value )
      );
    }

    /*
     * Here we will need to deal with rotation of the handle
     * positioning will consist of two parts: position that we get from
     * val and from handleHeight * cos rotation angle around oZ
     */

    /*
     * First of all sign of the deviation that is caused by handle rotation
     * the point is that if we choose to right, deviation is negative,
     * we should move coordinate from right to left
     * otherwise move coordinate from left to right -> positive
     */
    const sign = type === 'toRight' ? -1 : 1;

    // Then calculate mentioned deviation using sign
    let shift = ( ( sign * hHeight ) / 2 ) * Math.sin( this.handle.rotation.z );

    // Then add desirable distance to calculated deviation
    switch ( type ) {
      case 'toLeft':
        shift += -width / 2 + Number( value );
        break;
      case 'toRight':
        shift += width / 2 - Number( value );
        break;
      default:
    }

    this.handle.position.x = shift;
  }

  /** @type { CabD.A.Door['setHandlePositionAlongY'] } */
  setHandlePositionAlongY(
    /** @type { CabD.A.Door['handlePosition']['oY'] } */ val = {
      type: 'center',
      value: null
    }
  ) {
    this.handlePosition.oY = val;
    this.updateHandlePositionAlongY();
  }

  /** @type { CabD.A.Door['getHandlePositionAlongY'] } */
  getHandlePostionAlongY() {
    return this.handlePosition.oY;
  }

  // For operations description see updateHandlePositionAlongX
  /** @type { CabD.A.Door['updateHandlePositionAlongY'] } */
  // eslint-disable-next-line consistent-return
  updateHandlePositionAlongY() {
    const { type, value } = this.getHandlePostionAlongY();
    const { height } = this.getSizes();
    const { height: hHeight } = this.handle.getSizes();

    if ( type === 'center' || type === 'free' ) {
      // eslint-disable-next-line no-return-assign
      return void (
        this.handle.position.y = type === 'center'
          ? height / 2
          : Number( value )
      );
    }

    const sign = type === 'toTop' ? -1 : 1;
    let shift = ( ( sign * hHeight ) / 2 ) * Math.cos( this.handle.rotation.z );

    switch ( type ) {
      case 'toBottom':
        shift += Number( value );
        break;
      case 'toTop':
        shift += height - Number( value );
        break;
      default:
    }

    this.handle.position.y = shift;
  }

  /** @type { CabD.A.Door['setHandlePositionAlongZ'] } */
  setHandlePositionAlongZ(
    /** @type { CabD.A.Door['handlePosition']['oZ'] } */ val = 'onSurface'
  ) {
    this.handlePosition.oZ = val;
    this.updateHandlePositionAlongZ();
  }

  /** @type { CabD.A.Door['getHandlePositionAlongZ'] } */
  getHandlePostionAlongZ() {
    return this.handlePosition.oZ;
  }

  // For operations description see updateHandlePositionAlongX
  /** @type { CabD.A.Door['updateHandlePositionAlongZ'] } */
  updateHandlePositionAlongZ() {
    const value = this.getHandlePostionAlongZ();
    const { depth } = this.getSizes();
    const { depth: hDepth } = this.handle.getSizes();

    if ( typeof value === 'number' ) this.handle.position.z = value;
    else this.handle.position.z = depth / 2 + hDepth / 2;
  }

  changeWidth( /** @type { number } */ value ) {
    // TODO: Check if this can be implemented at Container level
    if ( value === this.size.width ) return;

    super.changeWidth( value );
    this.updateHandlePositionAlongX();
  }

  changeDepth( /** @type { number } */ value ) {
    // TODO: Check if this can be implemented at Container level
    if ( value === this.size.depth ) return;

    super.changeDepth( value );
    this.updateHandlePositionAlongZ();
  }

  changeHeight( /** @type { number } */ value ) {
    // TODO: Check if this can be implemented at Container level
    if ( value === this.size.height ) return;

    super.changeHeight( value );
    this.updateHandlePositionAlongY();
  }

  /** @typedef { import('decl/general/core').NominalHardNum<'radiansFrom0ToPi_2'> } radiansFrom0ToPi_2 */
  _angle = /** @type { radiansFrom0ToPi_2 } */( 0 );

  open( /** @type { radiansFrom0ToPi_2 } */angle /* 0 - PI/2 */ ) {
    const rad = Math.max( Math.min( angle, Math.PI / 2 ), 0 );
    const prevRad = this._angle ? this._angle : 0;
    if ( this.handleOrientation === 'VERTICAL' ) {
      if ( ( this.handlePosition.oX.type === 'toLeft' && this.handlePosition.oX.value <= this.size.width / 2 ) ||
        ( this.handlePosition.oX.type === 'toRight' && this.handlePosition.oX.value >= this.size.width / 2 ) ) {
        this.mesh.rotation.y = rad;
        this.mesh.position.z += ( this.size.width - this.size.depth ) * 0.5 * Math.sin( rad );
        this.mesh.position.x += ( this.size.width - this.size.depth ) * 0.5 * ( 1 - Math.cos( rad ) );
        this.mesh.position.z -= ( this.size.width - this.size.depth ) * 0.5 * Math.sin( prevRad );
        this.mesh.position.x -= ( this.size.width - this.size.depth ) * 0.5 * ( 1 - Math.cos( prevRad ) );
      }
      if ( ( this.handlePosition.oX.type === 'toRight' && this.handlePosition.oX.value <= this.size.width / 2 ) ||
        ( this.handlePosition.oX.type === 'toLeft' && this.handlePosition.oX.value >= this.size.width / 2 ) ) {
        this.mesh.rotation.y = -rad;
        this.mesh.position.z += ( this.size.width - this.size.depth ) * 0.5 * Math.sin( rad );
        this.mesh.position.x -= ( this.size.width - this.size.depth ) * 0.5 * ( 1 - Math.cos( rad ) );
        this.mesh.position.z -= ( this.size.width - this.size.depth ) * 0.5 * Math.sin( prevRad );
        this.mesh.position.x += ( this.size.width - this.size.depth ) * 0.5 * ( 1 - Math.cos( prevRad ) );
      }
    }

    this._angle = /** @type { radiansFrom0ToPi_2 } */( rad );
  }

  /** @returns { radiansFrom0ToPi_2 | null } */
  getAngle() {
    if ( this.handleOrientation !== 'VERTICAL' ) return null;

    return this._angle;
  }

}
