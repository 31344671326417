import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from '@r/store';

import ReactAppTest from './containers/index';

if ( module.hot ) {
  module.hot.accept();
}


/**
 * VestaApp namespace, includes CabinetDesigner namespace and React-based               asda sdas as dasd asd asd s
 * UI namespace. <a href="/docs">See</a>
 * @namespace VestaApp
 *
 *
 */
class App extends PureComponent {
  render() {
    return <ReactAppTest />;
  }
}

ReactDOM.render(
  <Provider store={ store }>
    <App />
  </Provider>,
  document.getElementById( 'app' )
);
