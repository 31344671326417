/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Konva from 'konva';
import { KonvaStage as stage } from 'c/Modal/ModalFor2d/KonvaStage';

import Modal from 'c/Modal/Modal';
import styled from 'styled-components';
import { IconButton } from 'c/shared/icon-button';
import ThemeButton from 'c/shared/ThemeButton';
import { Dispatch } from 'redux';
import debounce from 'debounce';
import Slider from 'c/Modal/ModalFor2d/slider.tsx';

interface _IProps {
  visible: redux._IStore['modals']['modalFor2dVisible'];
  projectName: redux._IStore['projectFor3d']['projectName'];
  d: Dispatch<redux._IActions['setModalFor2dVisible']>;
}

const Wrap = styled.div`
  ${ ( { visible }: Pick<_IProps, 'visible'> ) => (
    visible ? 'visibility: visible;' : 'visibility: hidden;'
  ) }
  box-sizing: border-box;
`;

const StyledModal = styled( Modal )`
  && {
    width: 95%;
  }
  height: 95%;
`;

const StyledIcon = styled( IconButton )`
  position: absolute;
  top: 30px;
  right: 30px;
`;

const StyledButton = styled( ThemeButton )`
  position: absolute;
  top: 50px;
  right: 50px;
`;

const StyledSaveImgButton = styled( ThemeButton )`
  position: absolute;
  top: 50px;
  right: 200px;
`;

const StyledHeading = styled.h1`
  text-align: center;
  margin: 5px 0;
`;

const Canvas2DWrap = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  border: 1px solid rgba(155,155,155,0.3);
  overflow-y: scroll;
  overflow-x: auto;
  .holder {
    width: 100%;
    height: 100%;
    canvas {
      width: 100%;
      height: 100%;
    }
  }
`;


export const ModalFor2d = connect(
  ( s: redux._IStore ) => ( {
    visible: s.modals.modalFor2dVisible,
    projectName: s.projectFor3d.projectName
  } ),
  ( d ) => ( { d } )
)(
  class ModalFor2d extends PureComponent<_IProps> {

    close = () => void this.props.d( {
      type: 'SET_MODAL_FOR_2D_VISIBLE', value: false
    } )

    addText = () => {
      const layerId = 'textLayer';

      let [layer] = stage.getLayers()
        .filter( ( { attrs } ) => attrs.id === layerId );

      if ( !layer ) {
        layer = new Konva.Layer( { id: layerId } );
        stage.add( layer );
      }

      const textConfig = {
        x: stage.width() / 2,
        y: stage.height() / 2,
        text: 'Change me',
        fontSize: 18,
        fontFamily: 'Calibri',
        draggable: true
      };

      const textNode = new Konva.Text( textConfig );
      layer.add( textNode );
      layer.draw();

      textNode.on( 'dblclick', () => {
        let textPosition = textNode.getAbsolutePosition();
        let stageBox = stage.container().getBoundingClientRect();
        let areaPosition = {
          x: stageBox.left + textPosition.x,
          y: stageBox.top + textPosition.y
        };
        let textarea = document.createElement( 'textarea' );
        document.body.appendChild( textarea );

        textarea.value = textNode.text();
        textarea.style.position = 'absolute';
        textarea.style.top = `${ areaPosition.y }px`;
        textarea.style.left = `${ areaPosition.x }px`;
        textarea.style.width = `${ textNode.width() }px`;
        textarea.style.zIndex = 123;

        textarea.focus();

        textarea.addEventListener( 'keydown', ( e ) => {
          if ( e.keyCode === 13 ) {
            textNode.text( textarea.value );
            layer.draw();
            document.body.removeChild( textarea );
          }
        } );
      } );
    }

    downloadURI = ( uri: string, name: string ): void => {
      let link = document.createElement( 'a' );
      link.download = name;
      link.href = uri;
      document.body.appendChild( link );
      link.click();
      document.body.removeChild( link );
    }

    saveAsImage = () => {
      let pixelRatio = 4;

      if ( stage.width() >= 2450 ) {
        pixelRatio = Math.floor( 9800 / stage.width() );
      }

      const dataUrl: string = stage.toDataURL( { pixelRatio } );

      this.downloadURI( dataUrl, `${ this.props.projectName }.png` );
    }

    scaleStage = () => {
      let prevScale = 1;

      return debounce( ( scale: number ): void => {
        stage.width( stage.width() * scale / prevScale );
        stage.height( stage.height() * scale / prevScale );
        stage.scale( { x: scale, y: scale } );
        stage.draw();
        prevScale = scale;
      }, 500 );
    }

    render() {
      const {
        props: {
          visible
        },
        close,
        addText,
        saveAsImage,
        scaleStage
      } = this;

      return (
        <Wrap visible={ visible }>
          <StyledModal hideModal={ close }>
            <StyledHeading>Plan 2D</StyledHeading>
            <StyledIcon
              title='Close'
              variant='close'
              onClick={ close }
            />
            <Slider onChange={ scaleStage() } />
            <StyledButton onClick={ addText }>Add text</StyledButton>
            <StyledSaveImgButton onClick={ saveAsImage }>
              Save as image
            </StyledSaveImgButton>
            <Canvas2DWrap id='canvas2dWrap'>
              <div className='holder' />
            </Canvas2DWrap>
          </StyledModal>
        </Wrap>
      );
    }
  }
);
