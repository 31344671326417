import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { mainModalOpen, mainModalTab } from './mainModal';
import { cornerMenuVisible } from './cornerMenuVisible';
import { object3dModal } from './object3dModal';
import { modalFor2dVisible } from './modalFor2d';
import { importExportModalVisible } from './importExportModalVisible';
import { itemEditorVisible } from './itemEditorVisible';

const modalsComb = combineReducers as
  combRed<redux._IStore['modals'], AllActions>;

export const modals = modalsComb( {
  mainModalOpen,
  mainModalTab,
  cornerMenuVisible,
  object3dModal,
  modalFor2dVisible,
  importExportModalVisible,
  itemEditorVisible,
} );
