import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { materialsSets } from './materialsSets';
import { countertop } from './countertop';
import { pulls } from './pulls';
import { hinges } from './hinges';
import { legs } from './legs';
import { drawerSystems } from './drawerSystems';
import { markup } from './markup';

const materialsComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['settings']['materials'],
    AllActions
  >;

const materialsStandart = materialsComb( {
  materialsSets,
  countertop,
  pulls,
  hinges,
  legs,
  drawerSystems,
  markup
} );

const defaultState = {
  materialsSets: {
    data: [],
    counter: 0,
    keys: [],
    coreOptions: [],
    doorsDrawersOptions: [],
    edgebandingOptions: []
  },
  countertop: {
    options: [],
    value: {
      label: '',
      value: ''
    }
  },
  pulls: {
    options: [],
    value: {
      label: '',
      value: ''
    }
  },
  hinges: {
    options: [],
    value: {
      label: '',
      value: ''
    }
  },
  legs: {
    options: [],
    value: {
      label: '',
      value: ''
    }
  },
  drawerSystems: {
    options: [],
    value: {
      label: '',
      value: ''
    }
  },
  markup: {
    label: '',
    value: 0
  }
};

export function materials( state = defaultState, action ) {
  switch ( action.type ) {
    case 'SET_CALCULATION_MODE_SETTINGS_MATERIALS':
      return action.value;
    default:
      return materialsStandart( state, action );
  }
}

export function setDefaultMaterials() {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        adminSettings: {
          projectSettings: {
            materials
          }
        }
      }
    } = getStore();

    dispatch( {
      type: 'SET_CALCULATION_MODE_SETTINGS_MATERIALS',
      value: materials
    } );
  };
}
