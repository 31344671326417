import {Step} from "./BaseCmd";
import DraftCmd from "./DraftCmd";
import * as THREE from 'three';
import {createCurveEntity} from '../entity/CurveEntityEx';
import DoAddRemoveEntity from "../UndoRedo/DoAddRemoveEntity";
import { SnapFlag } from "../viewer/Viewport";
import { Constants } from "../common";

class RectCmd extends DraftCmd {
    draftEntity = [];

    vt0 = new THREE.Vector2();
    vt1 = new THREE.Vector2();
    vt2 = new THREE.Vector2();
    vt3 = new THREE.Vector2();

    doActivate() {
        super.doActivate();
        this.setCursor('rect', true);
    }
    initCmd() {
        super.initCmd();
        this.draftEntity = [];
    }
    doEscape() {
        this.draftEntity.forEach(entity => {
            entity.dispose();
        });
        this.updateRender();

        super.doEscape();
    }
    onMouseMove(e) {
        super.onMouseMove(e);

        const pos = this.tracker.trackPos2d(true);
        if (!pos) return;

        switch(this.step) {
            case Step.step1: {
                this.vt2.copy(pos);
                const dot = this.vt1.subVectors(this.vt2, this.vt0).dot(Constants.xAxis);
                this.vt1.copy(Constants.xAxis).multiplyScalar(dot).add(this.vt0);
                this.vt3.subVectors(this.vt2, this.vt1).add(this.vt0);

                updateEntity(this.draftEntity[0], this.vt0, this.vt1);
                updateEntity(this.draftEntity[1], this.vt1, this.vt2);
                updateEntity(this.draftEntity[2], this.vt2, this.vt3);
                updateEntity(this.draftEntity[3], this.vt3, this.vt0);

                this.updateRender();
                break;
            }
        }
    }
    onMouseClick(e) {
        super.onMouseClick(e);

        const pos = this.tracker.trackPos2d(true);
        if (!pos) return;

        switch(this.step) {
            case Step.step0: {
                for (let i = 0; i < 4; i++) {
                    const entity = createCurveEntity(new THREE.LineCurve(pos.clone(), pos.clone()));
                    this.entityMgr.draftG.add(entity);

                    this.draftEntity.push(entity);
                }
                this.vt0.copy(pos);

                this.step = Step.step1;
                break;
            }
            case Step.step1: {
                if (this.equalsPoint(this.vt0, this.vt1) || this.equalsPoint(this.vt0, this.vt3)) {}
                else {
                    // end with undo/redo
                    this.doMgr.beginDo();
                    this.draftEntity.forEach(entity => {
                        const doaction = new DoAddRemoveEntity(entity, true, this.entityMgr.curveG);
                        this.doMgr.registerDo(doaction);
                        doaction.redo();
                    });
                    this.doMgr.endDo();

                    this.initCmd();
                }
                break;
            }
        }
    }
}

function updateEntity(entity, v1, v2) {
    entity.curve.v1.copy(v1);
    entity.curve.v2.copy(v2);
    entity.updateEntity();
}

export default RectCmd;