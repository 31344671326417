import { Theme as T } from '@npmrost/storybook/stories/Vesta/theme';

const copy: typeof T = JSON.parse( JSON.stringify( T ) );
export const Theme: typeof T = {
  ...copy,
  modal: {
    ...copy.modal,
    backgroundColor: copy.generalBackground,
    padding: '30px 100px 30px 30px',
    closeBtn: {
      top: 28,
      right: 37
    },
    overlay: {
      ...copy.modal.overlay,
      backgroundColor: 'rgba(0,0,0,0.7)'
    }
  }
};
