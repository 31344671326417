import { inches } from '@npmrost/utils/lib';

export const TallFiller: Partial<CabD.A.ContainerConfigs.ContainerConfig> = {
  height: 36 as inches,
  depth: 24 as inches,
  width: 1 as inches,
  name: 'EmptyAssembly',
  constrName: 'FloorCabinet',
  isTopLevel: true,
  isFiller: true,
  children: [
    {
      name: 'ToeKickAssembly',
      layout: 'DEPTH',
      height: 3 as inches,
      children: [
        {
        },
        {
          name: 'ToeKickAssembly--FrontPanel',
          type: 'Panel',
          depth: 0.5 as inches
        },
        {
          name: 'ToeKickAssembly--ToeKickSetBack',
          depth: 2 as inches
        }
      ]
    },
    {
      name: 'Filler',
      layout: 'DEPTH',
      children: [
        {},
        {
          depth: 0.5 as inches,
          type: 'Panel',
          name: 'filler',
          panelParent: 'door'
        },
        {
          name: 'FrontExteriorAssembly',

          depth: 0.5 as inches
        }
      ]
    }
  ]
};


