import {
  FrontSide,
  Mesh,
  Group,
  MeshBasicMaterial,
  ShapeGeometry
} from 'three';
import { createGeometry } from 'c/ThreeJsWrap/Viewer/core/helpers/geometry';

const defaultMaterialUnselected = new MeshBasicMaterial( {
  color: 0xffff00,
  transparent: true,
  opacity: 0.2,
  side: FrontSide
} );
const defaultMaterialSelected = new MeshBasicMaterial( {
  color: 0xffff00,
  transparent: false,
  side: FrontSide
} );
const defaultMaterialInvisible = new MeshBasicMaterial( {
  visible: false
} );
/**
 * Represents plane on which other objects (matching slot text description)  can be mounted
 */
export default class MountPlane extends Group {
  /**
   *
   * @param { CabD.A.mountTypes[] | CabD.A.mountTypes } mountSlotTypes Text descriptions array
   * @param {THREE.Shape} shape Shape consists of points connecting with each other by curves or lines
   * @param {THREE.Material} material Material if it is needed to overwrite default one
   */
  constructor( mountSlotTypes, shape, material = defaultMaterialInvisible ) {
    const geometry = createGeometry( new ShapeGeometry( shape ) );
    /*
     * New ExtrudeGeometry(shape, {
     * steps: 1,
     * depth: 0.0001,
     * bevelEnabled: false
     *})
     */

    super();
    this.plane = new Mesh( geometry, material );
    this.plane.isMount = true;
    this.plane.isMountPlane = true;
    this.add( this.plane );
    this.plane.position.set( 0, 0, 0.01 );
    if ( mountSlotTypes instanceof Array ) {
      this.plane.mountSlotTypes = mountSlotTypes;
    } else {
      this.plane.mountSlotTypes = [];
      this.plane.mountSlotTypes.push( mountSlotTypes );
    }
  }

  setMaterialToUnselected() {
    this.plane.material = defaultMaterialUnselected;
  }

  setMaterialToSelected() {
    this.plane.material = defaultMaterialSelected;
  }

  setMaterialToInvisible() {
    this.plane.material = defaultMaterialInvisible;
  }

  rebuildGeometry( shape ) {
    this.plane.geometry = createGeometry( new ShapeGeometry( shape ) );
  }
}
