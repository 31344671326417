import { combineReducers } from 'redux';
import { AllActions, combRed } from '@r/storeUtils';
import { generalViewMode } from './generalViewMode';
import { rightSideCabinetMenuOpen } from './rightSideCabinetMenuOpen';
import { rightSideCabinetMenuMode } from './rightSideCabinetMenuMode';
import { singletonCabinetJsonModalOpen } from './singletonCabinetJsonModalOpen';
import { wireframeMode } from './wireframeMode';
import { lockViewMode } from './lockViewMode';
import { cameraPerItem } from './cameraPerItem';
import { showCurrentItem } from './showCurrentItem';
import { orthographicMode } from './orthographicMode';
import { itemsModalVisible } from './itemsModalVisible';
import { cabinetSetMaterialMode } from './cabinetSetMaterialMode';
import {
  cabinetSetMaterialOnDoorOrBody
} from './cabinetSetMaterialOnDoorsOrBody';
import { cabinetsMeasurePoint } from './cabinetsMeasurePoint';
import { hasSavedProject } from './hasSavedProject';

const flagsComb = combineReducers as
  combRed<redux._IStore['flags'], AllActions>;

export const flags = flagsComb( {
  generalViewMode,
  cameraPerItem,
  showCurrentItem,
  wireframeMode,
  lockViewMode,
  orthographicMode,
  itemsModalVisible,
  rightSideCabinetMenuMode,
  rightSideCabinetMenuOpen,
  singletonCabinetJsonModalOpen,
  cabinetSetMaterialMode,
  cabinetSetMaterialOnDoorOrBody,
  cabinetsMeasurePoint,
  hasSavedProject
} );

