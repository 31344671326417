import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div`
  ${ ( { theme: t } ) => `
    border-bottom: 1px solid ${ t.darkGreyColor };
  ` }
`;

class Header extends PureComponent {
  render() {
    const {
      props: {
        texts: {
          number,
          name,
          setOfMaterials,
          width,
          height,
          depth,
          fullDepth,
          comment,
          cost
        },
        fullDepthIsShown
      }
    } = this;

    return (
      <Wrapper>
        <div className='row'>
          <div className='column columnNumber'>
            <span>{number}</span>
          </div>
          <div className='column columnName'>
            <span>{name}</span>
          </div>
          <div className='column columnSetOfMaterials'>
            <span>{setOfMaterials}</span>
          </div>
          <div className='column columnUnit'>
            <span>{width}</span>
          </div>
          <div className='column columnUnit'>
            <span>{height}</span>
          </div>
          <div className='column columnUnit'>
            <span>{depth}</span>
          </div>
          {
            fullDepthIsShown && (
              <div className='column columnUnit'>
                <span>{fullDepth}</span>
              </div>
            )
          }
          <div className='column columnComment'>
            <span>{comment}</span>
          </div>
          <div className='column columnCost'>
            <span>{cost}</span>
          </div>
          <div className='column columnActions' />
        </div>
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        estimationPreview: {
          table: { header }
        }
      }
    }
  } ) => {
    return {
      texts: header
    };
  }
)( Header );
