/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { PureComponent } from 'react';
import Storage from 'scr/utilitiesStorage';
import { connect } from 'react-redux';
import { batchActions, BatchAction } from 'redux-batched-actions';
import { batchActionsWithTempl, d } from '@r/storeUtils';
import { _IRemoveSelectionClick, _ISelectObject3d } from 'decl/ThreeScene';
import Tabs from'./Tabs';
import styles from './index.styl';

const b = batchActions as batchActionsWithTempl<
  redux._IActions['setObject3dModalVisible'] |
  redux._IActions['setObject3dModalY'] |
  redux._IActions['setObject3dModalX']
>;

interface _IProps {
  visible: boolean;
  x: redux._IStore['modals']['object3dModal']['x'];
  y: redux._IStore['modals']['object3dModal']['y'];
  dispatch: d<
    redux._IActions['setObject3dModalVisible'] |
    BatchAction
  >;
}

function isNullable( e: ObjectSelectionEvt ):
  e is _IRemoveSelectionClick {
  return e.data.object[ 0 ] === null;
}

type ObjectSelectionEvt = _ISelectObject3d |
  _IRemoveSelectionClick

export default connect(
  ( { modals }: redux._IStore ) => {
    const { object3dModal: { x, y, visible } } = modals;

    return { x, y, visible };
  }
)(
  class Object3dModal extends PureComponent<_IProps> {
    root = React.createRef<HTMLDivElement>();

    componentDidMount () {
      const viewer = Storage.get( 'viewer' );

      viewer.addEventListener(
        'objectselected',
        this.process3dObjectSelection
      );
    }

    componentWillUnmount () {
      const viewer = Storage.get( 'viewer' );

      viewer.removeEventListener(
        'objectselected',
        this.process3dObjectSelection
      );
    }

    process3dObjectSelection = ( e: ObjectSelectionEvt ) => {
      const { props: { dispatch } } = this;

      if( isNullable( e ) ) {
        Storage.set( 'selectedObject', [null] );

        dispatch( {
          type: 'SET_OBJECT_3D_MODAL_VISIBLE',
          value: false
        } );

        return void 0;
      }


      const firstSelected = e.data.object[ 0 ];

      // For cabinets we will have right menu, so here we skip cabinet-like object3d-s
      // @ts-ignore

      if( !firstSelected ) {
        return;
      }
      if( firstSelected.vestaObject.getType().match( 'cabinet' ) &&
        firstSelected.vestaObject.getType().match( 'wall3D' ) &&
        firstSelected.vestaObject.getType().match( 'corner' )
      ) {
        return void 0;
      }

      const { mouse: { x, y } } = e.data;
      Storage.set( 'selectedObject', e.data.object );

      dispatch(
        b( [
          { type: 'SET_OBJECT_3D_MODAL_VISIBLE', value: true },
          { type: 'SET_OBJECT_3D_MODAL_X', value: x },
          { type: 'SET_OBJECT_3D_MODAL_Y', value: y }
        ] )
      );

      return void 0;
    }

    calculateX( x: number ) {
      const width = document.documentElement.clientWidth;

      // 0.8 because right panel is 1 / 5 of the screen
      if( 0.8 * width - x < 150 ) {
        return 0.8 * width - 150;
      }

      return x;
    }

    calculateY( y: number ) {
      const height = document.documentElement.clientHeight;

      // 0.8 because right panel is 1 / 5 of the screen
      if( height - y < 250 ) {
        return height - 250;
      }

      return y;
    }

    render () {
      const { visible, x, y } = this.props;

      if( visible === false ) {
        return null;
      }

      const style = {
        left: `${ this.calculateX( x ) }px`,
        top: `${ this.calculateY( y ) }px`
      };

      return (
        <div
          className={ styles.object3dModal }
          style={ style }
          ref={ this.root }
        >
          <Tabs />
        </div>
      );
    }
  }
);
