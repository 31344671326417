import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { batchActions } from 'redux-batched-actions';

import { Button } from '@npmrost/storybook/stories/Vesta/Button';

import {
  setDefaultAdminSettings,
  saveAdminSettings
} from '@r/reducers/storeParts/calculationMode/adminSettings';
import {
  setDefaultSettings
} from '@r/reducers/storeParts/calculationMode/settings';

import Content from './Content';

const Container = styled.div`
  ${ ( { theme, hidden } ) => `
    display: ${ hidden ? 'none' : 'block' };
    background: ${ theme.calculationBackground };
  ` }
  z-index: 49;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 18px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 10px;
  top: 5px;
`;

const StyledButton = styled( Button )`
  margin-left: 20px;
`;

class AdminMode extends PureComponent {

  save = () => {
    const { dispatch } = this.props;

    dispatch( saveAdminSettings() )
      .then( () => {
        dispatch( setDefaultSettings() );
        alert( 'Successfully saved' );
      } )
      .catch( ( err ) => {
        alert( err );
      } );
  }

  exit = () => {
    const { generalViewMode, dispatch } = this.props;

    if ( generalViewMode === 'calculation' ) {
      return;
    }

    dispatch( batchActions( [
      { type: 'SET_GENERAL_VIEW_MODE', value: 'calculation' },
      { type: 'SET_CORNER_MENU_VISIBLE', value: false },
      { type: 'SET_RIGHT_SIDE_CABINET_MENU_OPEN', value: false }
    ] ) );

    dispatch( setDefaultAdminSettings() );
  }

  cancel = () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.dispatch( setDefaultAdminSettings() );
  }

  render() {
    const {
      props: {
        generalViewMode,
        texts: {
          title,
          save: saveText,
          exit: exitText,
          cancel: cancelText
        }
      },
      save,
      exit,
      cancel
    } = this;

    return (
      <>
        <Container hidden={ generalViewMode !== 'admin' }>
          <ButtonWrapper>
            <Button primary onClick={ save }>
              { saveText }
            </Button>
            <StyledButton primary onClick={ cancel }>
              { cancelText }
            </StyledButton>
            <StyledButton primary onClick={ exit }>
              { exitText }
            </StyledButton>
          </ButtonWrapper>
          <Title>
            { title }
          </Title>
          <Content />
        </Container>
      </>
    );
  }
}

export default connect(
  ( {
    flags: { generalViewMode },
    texts: {
      calculationMode: {
        adminMode
      }
    }
  } ) => {
    return {
      generalViewMode,
      texts: adminMode
    };
  }
)( AdminMode );
