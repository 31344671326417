/* eslint no-console: off */
import {
  PCFSoftShadowMap,
  PerspectiveCamera,
  Uncharted2ToneMapping,
  Vector2,
  WebGLRenderer,
  Color,
  Clock,
  OrthographicCamera,
  Box3,
  CubeCamera,
  Vector3,
  Mesh,
  REVISION,
  MeshPhongMaterial,
  FrontSide,
  GridHelper,
  CanvasTexture,
  UVMapping,
  RepeatWrapping,
  PlaneGeometry,
  MeshBasicMaterial,
  DoubleSide,
  Matrix4
} from 'three';
// eslint-disable-next-line import/no-cycle
import {
  isCabinet, isHoleableWithWall, getWall3D
} from 'c/ThreeJsWrap/Viewer/core/helpers/object3d';
import Storage from 'scr/utilitiesStorage';
// import { RaytracingRenderer } from '../core/RaytracingRenderer';
import FirstPersonControls from '../core/FirstPersonControls';
import TransformControls from '../core/TransformControls';
// eslint-disable-next-line import/no-cycle
import { serializeViewer, deserializeViewer } from './Serialization.ts';

// To disable global keyevent handling use three-OrbitControls instead of custom OrbitControls.
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'; // import OrbitControls from '../core/OrbitControls';

// eslint-disable-next-line import/no-cycle
import Scene from './Scene';

import { EffectComposer } from '../core/EffectComposer';

import WEBGL from '../core/WebGL';
import EventEmitter from '../core/loaders/EventEmitter';
import RenderOnDemand from './RenderOnDemand';
import { RenderPass } from '../core/RenderPass';
// eslint-disable-next-line import/no-cycle
import OutlinePass from '../core/OutlinePass';

import { ShaderPass } from '../core/ShaderPass';
import { FXAAShader } from '../core/FXAAShader';

// import { camera as testCamera, scene as testScene } from '../core/scene';

import Floorplan from '../floorplan/Floorplan.ts';
import Stage from '../floorplan/Stage';
import Wall3D from '../floorplan/Wall3D';
import Corner from '../floorplan/Corner';
import { calcCanvasWidth } from '../core/helpers/canvas';

import helpers from '../core/cabinetdesigner/helpers';
import DoManager from '../UndoRedo/DoManager';
import OnceActionsMgr from '../UndoRedo/OnceActionsMgr';
import DoAddRemoveEntity from '../UndoRedo/DoAddRemoveEntity';


export default class Viewer3D extends EventEmitter {
  /** @type { Scene } */
  scene;

  /** @type { RenderOnDemand } */
  renderOnDemand;
  onceActionsMgr; // does actions once.

  /** @type { OrbitControls } */
  controls;

  constructor( parentElement ) {
    super();
    window.viewer = this;
    this.WEBGLCheck();
    this.parentElement = parentElement;
    this.canvas.width = calcCanvasWidth( window.innerWidth );
    this.assets = {};
    window.Storage = Storage;
    this.floorplanModeParams = {
      instruments: {
        editWall3Ddimension: { enabled: false },
        selectObject: { enabled: true },
        moveObject: { enabled: false },
        addWall: { enabled: false },
        mergeCorners: { enabled: false },
        deleteObject: { enabled: false }
      }
    };
    this.floorplan2 = this.createDefaultFloorplan2();

    if ( this.canvas && this.context ) {
      this.init();
    }

    this.useOrbitControls();

    this.doMgr = new DoManager();
    console.log( this );
  }

  WEBGLCheck() {
    if ( WEBGL.isWebGL2Available() === false ) {
      if ( WEBGL.isWebGLAvailable() === false ) {
        document.body.appendChild( WEBGL.getWebGLErrorMessage() );
      } else {
        console.log( `THREE.JS v${ REVISION } webgl v1 is available` );
        this.canvas = document.createElement( 'canvas' );
        this.context = this.canvas.getContext( 'webgl', { antialias: false } );
      }
    } else {
      console.log( `THREE.JS v${ REVISION } webgl v2 is available` );
      this.canvas = document.createElement( 'canvas' );
      this.context = this.canvas.getContext( 'webgl2', { antialias: false } );
    }
  }

  setFloorplanInstrumentMode( instrument, enabled ) {
    // moveObject , addWall
    if ( this.floorplanMode ) {
      this.scene.dragDropManager.object3dF = null;

      if ( enabled ) {
        this.floorplanModeParams.instruments.addWall.enabled = false;
        this.floorplanModeParams.instruments.moveObject.enabled = false;
      }

      this.floorplanModeParams.instruments[ instrument ].enabled = enabled;

      if ( !this.floorplanModeParams.instruments.moveObject.enabled &&
        !this.floorplanModeParams.instruments.addWall.enabled
      ) {
        this.floorplanModeParams.instruments.selectObject.enabled = true;
      } else {
        this.floorplanModeParams.instruments.selectObject.enabled = false;
      }
    }
  }

  setFloorplanParameterValue( parameter, value ) {
    // orthoMode , snapToGrid
    this.floorplanModeParams[ parameter ] = value;
  }

  initScene() {
    this.scene = new Scene( this );
    this.scene.add( this.floorplan2 );
  }

  initCamera() {

    const floorplanBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    floorplanBox.getCenter( center );
    const min = floorplanBox.min;
    const max = floorplanBox.max;
    const maxDim = Math.max( max.x - min.x, max.z - min.z );

    const camera = new PerspectiveCamera(
      45,
      calcCanvasWidth( window.innerWidth ) / window.innerHeight,
      1,
      5 * maxDim
    );


    camera.position.copy( max );

    this.camera = camera;

    const canvasAspect = ( this.canvas.width / this.canvas.height );
    let oHeight = max.y - min.y;
    let oWidth = oHeight * canvasAspect;

    if ( oWidth < maxDim ) {
      oHeight = maxDim / canvasAspect;
      oWidth = maxDim;
    }

    const orthoCamera = new OrthographicCamera(
      -oWidth * 0.6,
      oWidth * 0.6,
      oHeight * 0.6,
      -oHeight * 0.6,
      0.001,
      1e6
    );

    orthoCamera.updateProjectionMatrix();

    orthoCamera.position.set(
      ( max.x - min.x ) / 2,
      ( max.y - min.y ) / 2,
      max.z - min.z / 1.99
    );


    this.orthoCamera = orthoCamera;

    const floorplanCamera = new OrthographicCamera(
      -oWidth * 0.6,
      oWidth * 0.6,
      oHeight * 0.6,
      -oHeight * 0.6,
      0.001,
      1e6
    );

    floorplanCamera.updateProjectionMatrix();

    floorplanCamera.position.set(
      ( max.x - min.x ) / 2,
      ( max.y - min.y ) / 2,
      max.z - min.z / 1.99
    );


    this.floorplanCamera = floorplanCamera;
  }

  fitCameraToObjectFront( obj ) {
    const bBox = new Box3().setFromObject( obj );
    const center = new Vector3();
    bBox.getCenter( center );
    const size = new Vector3();
    bBox.getSize( size );

    if ( !obj.isWall && !obj.isFloor && !isCabinet( obj ) ) return;

    let width, depth, height;

    if ( obj.isWall ) {
      width = obj.width;
      height = obj.height;
      depth = 50;
    }

    if ( obj.isFloor ) {
      width = size.x;
      height = size.z;
      depth = 100;
    }
    if ( !obj.isFloor && !obj.isWall ) {
      width = obj.vestaObject.getSizes().width;
      height = obj.vestaObject.getSizes().height;
      depth = obj.vestaObject.getSizes().depth;
    }

    if ( this.renderPass.camera === this.camera ) {

      let w = width;
      let h = height;

      let fovX = this.camera.fov * this.camera.aspect;
      let fovY = this.camera.fov;

      let distanceX = ( w / 2 ) / Math.tan( Math.PI * fovX / 360 ) + ( w / 2 );
      let distanceY = ( h / 2 ) / Math.tan( Math.PI * fovY / 360 ) + ( w / 2 );

      let distance = Math.max( distanceX, distanceY );

      this.camera.position.copy(
        center.clone()
          .add( new Vector3( 0, 0, distance + depth / 2 )
            .applyMatrix4( new Matrix4().extractRotation( obj.matrixWorld ) ) ) );
      this.camera.updateProjectionMatrix();

      this.controls.target.copy( center );
      this.controls.update();
    }

    if ( this.renderPass.camera === this.orthoCamera ) {

      let distance = depth * 1.5;

      this.orthoCamera.position.copy(
        center.clone()
          .add( new Vector3( 0, 0, distance + depth / 2 )
            .applyMatrix4( new Matrix4().extractRotation( obj.matrixWorld ) ) ) );

      const canvasAspect = ( this.canvas.width / this.canvas.height );
      let oHeight = height;
      let oWidth = oHeight * canvasAspect;

      if ( oWidth < width ) {
        oHeight = width / canvasAspect;
        oWidth = width;
      }

      this.orthoCamera.left = -oWidth * 0.55;
      this.orthoCamera.right = oWidth * 0.55;
      this.orthoCamera.bottom = -oHeight * 0.55;
      this.orthoCamera.top = oHeight * 0.55;

      this.orthoCamera.updateProjectionMatrix();

      this.controls.target.copy( center );
      this.controls.update();
    }

    this.renderOnDemand.set();
  }

  setCameraViewLeftBack() {
    const bBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    const size = new Vector3();
    bBox.getSize( size );
    bBox.getCenter( center );
    if ( this.renderPass.camera === this.camera ) {
      const offset = 1.0;

      let w = 1.41 * Math.max( size.z, size.x );
      let h = size.y;

      let fovX = this.camera.fov * this.camera.aspect;
      let fovY = this.camera.fov;

      let distanceX = ( w / 2 ) / Math.tan( Math.PI * fovX / 360 ) + ( w / 2 );
      let distanceY = ( h / 2 ) / Math.tan( Math.PI * fovY / 360 ) + ( w / 2 );

      let distance = Math.max( distanceX, distanceY );

      this.camera.position.set( center.x - distance / 1.41, center.y, center.z - distance / 1.41 );
      this.camera.updateProjectionMatrix();

      // set camera to rotate around center of loaded object
      this.controls.target.copy( center );
      // prevent camera from zooming out far enough to create far plane cutoff
      // this.controls.maxDistance = cameraToFarEdge * 2;
      // this.controls.saveState();
      this.controls.update();

    }

    if ( this.renderPass.camera === this.orthoCamera ) {
      this.orthoCamera.position.set( center.x - ( size.x / Math.SQRT2 ), center.y, center.z - ( size.z / Math.SQRT2 ) );
      this.controls.target.copy( center );
      const maxDim = Math.sqrt( Math.pow( size.z, 2 ) + Math.pow( size.x, 2 ) );

      const canvasAspect = ( this.canvas.width / this.canvas.height );
      let oHeight = size.y;
      let oWidth = oHeight * canvasAspect;

      if ( oWidth < maxDim ) {
        oHeight = maxDim / canvasAspect;
        oWidth = maxDim;
      }

      this.orthoCamera.left = -oWidth * 0.55 - 0 * center.z;
      this.orthoCamera.right = oWidth * 0.55 - 0 * center.z;
      this.orthoCamera.bottom = -oHeight * 0.55 - 0 * center.y;
      this.orthoCamera.top = oHeight * 0.55 - 0 * center.y;

      this.orthoCamera.updateProjectionMatrix();
      this.controls.update();
    }
  }

  setCameraViewBack() {
    const bBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    const size = new Vector3();
    bBox.getSize( size );
    bBox.getCenter( center );
    if ( this.renderPass.camera === this.camera ) {
      const offset = 1.0;

      let w = Math.max( size.z, size.x );
      let h = size.y;

      let fovX = this.camera.fov * this.camera.aspect;
      let fovY = this.camera.fov;

      let distanceX = ( w / 2 ) / Math.tan( Math.PI * fovX / 360 ) + ( w / 2 );
      let distanceY = ( h / 2 ) / Math.tan( Math.PI * fovY / 360 ) + ( w / 2 );

      let distance = Math.max( distanceX, distanceY );

      this.camera.position.set( center.x, center.y, center.z - distance );
      this.camera.updateProjectionMatrix();

      // set camera to rotate around center of loaded object
      this.controls.target.copy( center );
      // prevent camera from zooming out far enough to create far plane cutoff
      // this.controls.maxDistance = cameraToFarEdge * 2;
      // this.controls.saveState();
      this.controls.update();

    }

    if ( this.renderPass.camera === this.orthoCamera ) {
      this.orthoCamera.position.set( center.x, center.y, center.z - size.z );
      this.controls.target.copy( center );
      const maxDim = size.x;

      const canvasAspect = ( this.canvas.width / this.canvas.height );
      let oHeight = size.y;
      let oWidth = oHeight * canvasAspect;

      if ( oWidth < maxDim ) {
        oHeight = maxDim / canvasAspect;
        oWidth = maxDim;
      }

      this.orthoCamera.left = -oWidth * 0.55 - 0 * center.x;
      this.orthoCamera.right = oWidth * 0.55 - 0 * center.x;
      this.orthoCamera.bottom = -oHeight * 0.55 - 0 * center.y;
      this.orthoCamera.top = oHeight * 0.55 - 0 * center.y;

      this.orthoCamera.updateProjectionMatrix();
      this.controls.update();
    }
  }

  setCameraViewRightBack() {
    const bBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    const size = new Vector3();
    bBox.getSize( size );
    bBox.getCenter( center );
    if ( this.renderPass.camera === this.camera ) {
      const offset = 1.0;

      let w = 1.41 * Math.max( size.z, size.x );
      let h = size.y;

      let fovX = this.camera.fov * this.camera.aspect;
      let fovY = this.camera.fov;

      let distanceX = ( w / 2 ) / Math.tan( Math.PI * fovX / 360 ) + ( w / 2 );
      let distanceY = ( h / 2 ) / Math.tan( Math.PI * fovY / 360 ) + ( w / 2 );

      let distance = Math.max( distanceX, distanceY );

      this.camera.position.set( center.x + distance / 1.41, center.y, center.z - distance / 1.41 );
      this.camera.updateProjectionMatrix();

      // set camera to rotate around center of loaded object
      this.controls.target.copy( center );
      // prevent camera from zooming out far enough to create far plane cutoff
      // this.controls.maxDistance = cameraToFarEdge * 2;
      // this.controls.saveState();
      this.controls.update();

    }

    if ( this.renderPass.camera === this.orthoCamera ) {
      this.orthoCamera.position.set( center.x + ( size.x / Math.SQRT2 ), center.y, center.z - ( size.z / Math.SQRT2 ) );
      this.controls.target.copy( center );
      const maxDim = Math.sqrt( Math.pow( size.z, 2 ) + Math.pow( size.x, 2 ) );

      const canvasAspect = ( this.canvas.width / this.canvas.height );
      let oHeight = size.y;
      let oWidth = oHeight * canvasAspect;

      if ( oWidth < maxDim ) {
        oHeight = maxDim / canvasAspect;
        oWidth = maxDim;
      }

      this.orthoCamera.left = -oWidth * 0.55 - 0 * center.z;
      this.orthoCamera.right = oWidth * 0.55 - 0 * center.z;
      this.orthoCamera.bottom = -oHeight * 0.55 - 0 * center.y;
      this.orthoCamera.top = oHeight * 0.55 - 0 * center.y;

      this.orthoCamera.updateProjectionMatrix();
      this.controls.update();
    }
  }

  setCameraViewLeft() {
    const bBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    const size = new Vector3();
    bBox.getSize( size );
    bBox.getCenter( center );
    if ( this.renderPass.camera === this.camera ) {
      const offset = 1.0;

      let w = Math.max( size.z, size.x );
      let h = size.y;

      let fovX = this.camera.fov * this.camera.aspect;
      let fovY = this.camera.fov;

      let distanceX = ( w / 2 ) / Math.tan( Math.PI * fovX / 360 ) + ( w / 2 );
      let distanceY = ( h / 2 ) / Math.tan( Math.PI * fovY / 360 ) + ( w / 2 );

      let distance = Math.max( distanceX, distanceY );

      this.camera.position.set( center.x - distance, center.y, center.z );
      this.camera.updateProjectionMatrix();

      // set camera to rotate around center of loaded object
      this.controls.target.copy( center );
      // prevent camera from zooming out far enough to create far plane cutoff
      // this.controls.maxDistance = cameraToFarEdge * 2;
      // this.controls.saveState();
      this.controls.update();

    }

    if ( this.renderPass.camera === this.orthoCamera ) {
      this.orthoCamera.position.set( center.x - size.x, center.y, center.z );
      this.controls.target.copy( center );
      const maxDim = size.z;

      const canvasAspect = ( this.canvas.width / this.canvas.height );
      let oHeight = size.y;
      let oWidth = oHeight * canvasAspect;

      if ( oWidth < maxDim ) {
        oHeight = maxDim / canvasAspect;
        oWidth = maxDim;
      }

      this.orthoCamera.left = -oWidth * 0.55 - 0 * center.z;
      this.orthoCamera.right = oWidth * 0.55 - 0 * center.z;
      this.orthoCamera.bottom = -oHeight * 0.55 - 0 * center.y;
      this.orthoCamera.top = oHeight * 0.55 - 0 * center.y;

      this.orthoCamera.updateProjectionMatrix();
      this.controls.update();
    }
  }

  setCameraViewTop() {
    const bBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    const size = new Vector3();
    bBox.getSize( size );
    bBox.getCenter( center );
    if ( this.renderPass.camera === this.camera ) {
      console.log( 'top', 'perspective' );
      const offset = 1.0;

      let w = Math.max( size.y, size.x );
      let h = size.z;

      let fovX = this.camera.fov * this.camera.aspect;
      let fovY = this.camera.fov;

      let distanceX = ( w / 2 ) / Math.tan( Math.PI * fovX / 360 ) + ( w / 2 );
      let distanceY = ( h / 2 ) / Math.tan( Math.PI * fovY / 360 ) + ( w / 2 );

      let distance = Math.max( distanceX, distanceY );

      this.camera.position.set( center.x, center.y + distance, center.z );
      this.camera.updateProjectionMatrix();

      // set camera to rotate around center of loaded object
      this.controls.target.copy( center );
      // prevent camera from zooming out far enough to create far plane cutoff
      // this.controls.maxDistance = cameraToFarEdge * 2;
      // this.controls.saveState();
      this.controls.update();

    }

    if ( this.renderPass.camera === this.orthoCamera ) {
      this.orthoCamera.position.set( center.x, center.y + size.y, center.z );
      this.controls.target.copy( center );
      const maxDim = size.x;

      const canvasAspect = ( this.canvas.width / this.canvas.height );
      let oHeight = size.z;
      let oWidth = oHeight * canvasAspect;

      if ( oWidth < maxDim ) {
        oHeight = maxDim / canvasAspect;
        oWidth = maxDim;
      }

      this.orthoCamera.left = -oWidth * 0.55 - 0 * center.x;
      this.orthoCamera.right = oWidth * 0.55 - 0 * center.x;
      this.orthoCamera.bottom = -oHeight * 0.55 - 0 * center.z;
      this.orthoCamera.top = oHeight * 0.55 - 0 * center.z;

      this.orthoCamera.updateProjectionMatrix();
      this.controls.update();
    }


  }


  setCameraViewRight() {
    const bBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    const size = new Vector3();
    bBox.getSize( size );
    bBox.getCenter( center );
    if ( this.renderPass.camera === this.camera ) {
      const offset = 1.0;

      let w = Math.max( size.z, size.x );
      let h = size.y;

      let fovX = this.camera.fov * this.camera.aspect;
      let fovY = this.camera.fov;

      let distanceX = ( w / 2 ) / Math.tan( Math.PI * fovX / 360 ) + ( w / 2 );
      let distanceY = ( h / 2 ) / Math.tan( Math.PI * fovY / 360 ) + ( w / 2 );

      let distance = Math.max( distanceX, distanceY );

      this.camera.position.set( center.x + distance, center.y, center.z );
      this.camera.updateProjectionMatrix();

      // set camera to rotate around center of loaded object
      this.controls.target.copy( center );
      // prevent camera from zooming out far enough to create far plane cutoff
      // this.controls.maxDistance = cameraToFarEdge * 2;
      // this.controls.saveState();
      this.controls.update();

    }

    if ( this.renderPass.camera === this.orthoCamera ) {
      this.orthoCamera.position.set( center.x + size.x, center.y, center.z );
      this.controls.target.copy( center );
      const maxDim = size.z;

      const canvasAspect = ( this.canvas.width / this.canvas.height );
      let oHeight = size.y;
      let oWidth = oHeight * canvasAspect;

      if ( oWidth < maxDim ) {
        oHeight = maxDim / canvasAspect;
        oWidth = maxDim;
      }

      this.orthoCamera.left = -oWidth * 0.55 - 0 * center.z;
      this.orthoCamera.right = oWidth * 0.55 - 0 * center.z;
      this.orthoCamera.bottom = -oHeight * 0.55 - 0 * center.y;
      this.orthoCamera.top = oHeight * 0.55 - 0 * center.y;

      this.orthoCamera.updateProjectionMatrix();
      this.controls.update();
    }
  }

  setCameraViewLeftFront() {
    const bBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    const size = new Vector3();
    bBox.getSize( size );
    bBox.getCenter( center );
    if ( this.renderPass.camera === this.camera ) {
      const offset = 1.0;

      let w = 1.41 * Math.max( size.z, size.x );
      let h = size.y;

      let fovX = this.camera.fov * this.camera.aspect;
      let fovY = this.camera.fov;

      let distanceX = ( w / 2 ) / Math.tan( Math.PI * fovX / 360 ) + ( w / 2 );
      let distanceY = ( h / 2 ) / Math.tan( Math.PI * fovY / 360 ) + ( w / 2 );

      let distance = Math.max( distanceX, distanceY );

      this.camera.position.set( center.x - distance / 1.41, center.y, center.z + distance / 1.41 );
      this.camera.updateProjectionMatrix();

      // set camera to rotate around center of loaded object
      this.controls.target.copy( center );
      // prevent camera from zooming out far enough to create far plane cutoff
      // this.controls.maxDistance = cameraToFarEdge * 2;
      // this.controls.saveState();
      this.controls.update();

    }

    if ( this.renderPass.camera === this.orthoCamera ) {
      this.orthoCamera.position.set( center.x - ( size.x / Math.SQRT2 ), center.y, center.z + ( size.z / Math.SQRT2 ) );
      this.controls.target.copy( center );
      const maxDim = Math.sqrt( Math.pow( size.z, 2 ) + Math.pow( size.x, 2 ) );

      const canvasAspect = ( this.canvas.width / this.canvas.height );
      let oHeight = size.y;
      let oWidth = oHeight * canvasAspect;

      if ( oWidth < maxDim ) {
        oHeight = maxDim / canvasAspect;
        oWidth = maxDim;
      }

      this.orthoCamera.left = -oWidth * 0.55 - 0 * center.z;
      this.orthoCamera.right = oWidth * 0.55 - 0 * center.z;
      this.orthoCamera.bottom = -oHeight * 0.55 - 0 * center.y;
      this.orthoCamera.top = oHeight * 0.55 - 0 * center.y;

      this.orthoCamera.updateProjectionMatrix();
      this.controls.update();
    }
  }

  setCameraViewFront() {
    const bBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    const size = new Vector3();
    bBox.getSize( size );
    bBox.getCenter( center );
    if ( this.renderPass.camera === this.camera ) {
      const offset = 1.0;

      let w = Math.max( size.z, size.x );
      let h = size.y;

      let fovX = this.camera.fov * this.camera.aspect;
      let fovY = this.camera.fov;

      let distanceX = ( w / 2 ) / Math.tan( Math.PI * fovX / 360 ) + ( w / 2 );
      let distanceY = ( h / 2 ) / Math.tan( Math.PI * fovY / 360 ) + ( w / 2 );

      let distance = Math.max( distanceX, distanceY );

      this.camera.position.set( center.x, center.y, center.z + distance );
      this.camera.updateProjectionMatrix();

      // set camera to rotate around center of loaded object
      this.controls.target.copy( center );
      // prevent camera from zooming out far enough to create far plane cutoff
      // this.controls.maxDistance = cameraToFarEdge * 2;
      // this.controls.saveState();
      this.controls.update();

    }
    if ( this.renderPass.camera === this.orthoCamera ) {
      this.orthoCamera.position.set( center.x, center.y, center.z + size.z );
      this.controls.target.copy( center );
      const maxDim = size.x;

      const canvasAspect = ( this.canvas.width / this.canvas.height );
      let oHeight = size.y;
      let oWidth = oHeight * canvasAspect;

      if ( oWidth < maxDim ) {
        oHeight = maxDim / canvasAspect;
        oWidth = maxDim;
      }

      this.orthoCamera.left = -oWidth * 0.55 - 0 * center.x;
      this.orthoCamera.right = oWidth * 0.55 - 0 * center.x;
      this.orthoCamera.bottom = -oHeight * 0.55 - 0 * center.y;
      this.orthoCamera.top = oHeight * 0.55 - 0 * center.y;

      this.orthoCamera.updateProjectionMatrix();
      this.controls.update();
    }
  }


  setCameraViewRightFront() {
    const bBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    const size = new Vector3();
    bBox.getSize( size );
    bBox.getCenter( center );
    if ( this.renderPass.camera === this.camera ) {
      const offset = 1.0;

      let w = 1.41 * Math.max( size.z, size.x );
      let h = size.y;

      let fovX = this.camera.fov * this.camera.aspect;
      let fovY = this.camera.fov;

      let distanceX = ( w / 2 ) / Math.tan( Math.PI * fovX / 360 ) + ( w / 2 );
      let distanceY = ( h / 2 ) / Math.tan( Math.PI * fovY / 360 ) + ( w / 2 );

      let distance = Math.max( distanceX, distanceY );

      this.camera.position.set( center.x + distance / 1.41, center.y, center.z + distance / 1.41 );
      this.camera.updateProjectionMatrix();

      // set camera to rotate around center of loaded object
      this.controls.target.copy( center );
      // prevent camera from zooming out far enough to create far plane cutoff
      // this.controls.maxDistance = cameraToFarEdge * 2;
      // this.controls.saveState();
      this.controls.update();

    }

    if ( this.renderPass.camera === this.orthoCamera ) {
      this.orthoCamera.position.set( center.x + ( size.x / Math.SQRT2 ), center.y, center.z + ( size.z / Math.SQRT2 ) );
      this.controls.target.copy( center );
      const maxDim = Math.sqrt( Math.pow( size.z, 2 ) + Math.pow( size.x, 2 ) );

      const canvasAspect = ( this.canvas.width / this.canvas.height );
      let oHeight = size.y;
      let oWidth = oHeight * canvasAspect;

      if ( oWidth < maxDim ) {
        oHeight = maxDim / canvasAspect;
        oWidth = maxDim;
      }

      this.orthoCamera.left = -oWidth * 0.55 - 0 * center.z;
      this.orthoCamera.right = oWidth * 0.55 - 0 * center.z;
      this.orthoCamera.bottom = -oHeight * 0.55 - 0 * center.y;
      this.orthoCamera.top = oHeight * 0.55 - 0 * center.y;

      this.orthoCamera.updateProjectionMatrix();
      this.controls.update();
    }
  }

  initControls() {
    const floorplanBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    floorplanBox.getCenter( center );
    const min = floorplanBox.min;
    const max = floorplanBox.max;
    const maxDim = Math.max( max.x - min.x, max.z - min.z );


    /* OrbitControls */
    this.controls = new OrbitControls( this.camera, this.canvas );
    this.controls.maxDistance = 2.5 * maxDim;
    this.controls.minDistance = 0;
    this.controls.target.copy( center );
    this.controls.enableZoom = true;
    this.controls.screenSpacePanning = true;
    // This.controls.maxPolarAngle = Math.PI / 2;
    this.controls.addEventListener(
      'change',
      () => this.renderOnDemand.set(),
      false
    );
    /*---------------------------------*/
    /* FirstPersonControls */
    this.firstPersonControls = new FirstPersonControls( this.camera, this.canvas );
    this.firstPersonControls.enabled = false;
    this.firstPersonControls.addEventListener(
      'change',
      () => this.renderOnDemand.set(),
      false
    );
    /*---------------------------------*/
    /*---------------------------------*/
    /* TransformControls */
    this.transformControls = new TransformControls( this.camera, this.canvas );
    this.scene.add( this.transformControls );
    this.transformControls.enabled = true;
    this.transformControls.addEventListener(
      'change',
      () => this.renderOnDemand.set(),
      false
    );
    this.transformControls.addEventListener( 'dragging-changed', ( event ) => {
      this.controls.enabled = !event.value;
      // this.scene.dragDropManager.enabled = !event.value;
    } );
    this.transformControls.detach();


    /*---------------------------------*/
  }

  useOrbitControls( withoutCameraMovement ) {
    if ( this.floorplanMode ) {
      this.floorplan2.rebuildGeometry();
      this.outlinePass.selectedObjects = [];
      this.scene.remove( this.floorplanModeParams.floorplanGrid );
      this.floorplan2.enableLights();
      this.floorplanMode = false;
    }

    this.controls.minZoom = 0;
    this.controls.maxZoom = Infinity;
    this.usePerspectiveCamera();


    const floorplanBox = this.floorplan2.getBoundingBox();
    const min = floorplanBox.min;
    const max = floorplanBox.max;
    const maxDim = Math.max( max.x - min.x, max.z - min.z );

    this.firstPersonControls.enabled = false;
    this.camera.far = 5 * maxDim;
    this.controls.enabled = true;
    this.transformControls.enabled = true;
    this.setFloorplanVisibility( false );
    this.controls.enableRotate = true;
    this.scene.setDimensionsVisibility( false );

    if ( !withoutCameraMovement ) {
      this.setCameraViewFront();
    }


    this.onResize();

    this.renderOnDemand.set();
  }

  useFirstPersonControls() {
    this.floorplanMode = false;

    const floorplanBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    floorplanBox.getCenter( center );
    const min = floorplanBox.min;
    const max = floorplanBox.max;
    const maxDim = Math.max( max.x - min.x, max.z - min.z );

    this.controls.enabled = false;
    this.transformControls.enabled = false;

    this.camera.position.copy( center );
    this.camera.rotation.set( 0, 0, 0 );
    this.firstPersonControls.enabled = true;

    this.onResize();

    this.renderOnDemand.set();
  }

  useOrthographicCamera() {
    this.floorplanMode = false;

    const floorplanBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    floorplanBox.getCenter( center );
    const min = floorplanBox.min;
    const max = floorplanBox.max;
    const maxDim = Math.max( max.x - min.x, max.z - min.z );

    this.renderPass.camera = this.orthoCamera;
    this.outlinePass.renderCamera = this.orthoCamera;
    this.controls.object = this.orthoCamera;
    this.controls.reset();
    this.controls.target.copy( center );
    this.scene.dragDropManager.camera = this.orthoCamera;
    this.orthoMode = true;


    if ( this.outlinePass.selectedObjects.length ) {
      let bBox = new Box3().setFromObject( this.outlinePass.selectedObjects[ 0 ] );
      let center = new Vector3();
      bBox.getCenter( center );
      this.controls.target.copy( center );
      this.orthoCamera.position.copy( center );
    }


    this.scene.setDimensionsVisibility( true );
    this.setCameraViewTop();
    this.renderOnDemand.set();

  }


  getFloorplanGridTexture () {
    let canvas = document.createElement( 'canvas' );
    canvas.width = 512;
    canvas.height = 512;
    let ctx = canvas.getContext( '2d' );
    ctx.strokeStyle = '#0088ff';
    ctx.fillStyle = '#ffffff';
    ctx.fillRect( 0, 0, 512, 512 );
    // жирная обводка в 4 пикселя
    ctx.lineWidth = 4;
    ctx.strokeRect( 0, 0, 512, 512 );
    // мелкие линии в 1 пиксель
    ctx.lineWidth = 1;
    let dx = 10;
    let dt = 512 / dx;
    for ( let x = 0; x < dx; x++ ) {
      for ( let y = 0; y < dx; y++ ) {
        ctx.strokeRect( x * dt, y * dt, dt, dt );
      }
    }

    let map = new CanvasTexture( canvas, UVMapping, RepeatWrapping, RepeatWrapping );
    map.repeat.set( 10000, 10000 );
    map.needsUpdate = true;

    return map;
  }

  createDefaultFloorplan2() {


    const floorplan = new Floorplan();
    floorplan.tallCabinetHeight = 94;
    floorplan.defaultFloorMaterialName = 'fineWood';
    floorplan.defaultWallMaterialName = 'lightBrick';
    const stage = new Stage();

    // stage.addWall3D(new Vector2(0, 0), new Vector2(100, 100), 3);
    const dimensions = Storage.get( 'dimensions' );
    if ( dimensions.floorPlan > 0 ) {
      stage.addWall3D( new Vector2( 0, 0 ), new Vector2( dimensions.roomWidth, 0 ), dimensions.wallDepth, dimensions.wallHeight );
    }
    if ( dimensions.floorPlan > 1 ) {
      stage.addWall3D( new Vector2( 0, dimensions.roomDepth ), new Vector2( 0, 0 ), dimensions.wallDepth, dimensions.wallHeight );
    }
    if ( dimensions.floorPlan > 2 ) {
      stage.addWall3D( new Vector2( dimensions.roomWidth, 0 ), new Vector2( dimensions.roomWidth, dimensions.roomDepth ), dimensions.wallDepth, dimensions.wallHeight );
    }
    if ( dimensions.floorPlan > 3 ) {
      stage.addWall3D( new Vector2( dimensions.roomWidth, dimensions.roomDepth ), new Vector2( 0, dimensions.roomDepth ), dimensions.wallDepth, dimensions.wallHeight );
    }

    floorplan.addStage( stage );
    this.floorplanModeParams.floorplanGrid = new Mesh(
      new PlaneGeometry( 100000, 100000 ).rotateX( Math.PI / 2 ).translate( 0, -10, 0 ),
      new MeshBasicMaterial( { side: DoubleSide, color: 0xffffff, map: this.getFloorplanGridTexture() } ) );

    floorplan.rebuildGeometry();

    for ( let i = 0; i < floorplan.stages[ 0 ].corners.length; i += 1 ) {
      floorplan.stages[ 0 ].corners[ i ].rebuildGeometry();
    }

    this.floorplanModeParams.currentStage = floorplan.stages[ 0 ];

    return floorplan;
  }

  useFloorplanMode() {

    this.transformControls.detach();
    let selectObject3dAction = {
      type: 'objectselected',
      data: {
        object: [null],
        mouse: {
          x: 0,
          y: 0
        }
      }
    };

    this.dispatch( selectObject3dAction );
    this.outlinePass.selectedObjects = [];

    this.mode = 'floorplan';
    this.floorplanMode = true;


    this.floorplanModeParams.orthoMode = true;
    this.floorplanModeParams.snapToGrid = { big: true, small: false };
    this.scene._background = this.scene.background;
    this.scene.background = new Color( 0xffffff );
    this.scene.add( this.floorplanModeParams.floorplanGrid );

    this.renderPass.camera = this.floorplanCamera;
    this.outlinePass.renderCamera = this.floorplanCamera;
    this.controls.object = this.floorplanCamera;

    this.scene.dragDropManager.camera = this.floorplanCamera;
    this.orthoMode = false;
    this.controls.enableRotate = false;

    this.floorplan2.disableLights();
    let bBox = new Box3().setFromObject( this.floorplan2 );
    let center = new Vector3();
    bBox.getCenter( center );
    this.controls.target.copy( center );
    this.controls.minZoom = 0.15;
    this.controls.maxZoom = 100;
    this.floorplanCamera.updateProjectionMatrix();
    this.floorplanCamera.position.copy( center.clone().add( new Vector3( 0, 10000 + bBox.max.y - bBox.min.y, 0 ) ) );
    console.log( center.clone().add( new Vector3( 0, 10000 + bBox.max.y - bBox.min.y, 0 ) ) );

    this.controls.update();
    this.scene.setDimensionsVisibility( false );
    this.setFloorplanVisibility( true );
    this.onResize();


  }

  setFloorplanVisibility( visibility ) {
    this.scene.traverse( ( obj ) => {
      if ( obj.isFloorplanDimension && obj.parent.isWall3D ) {
        if ( obj.parent.stage === this.floorplanModeParams.currentStage ) {
          obj.visible = visibility;
        } else {
          obj.visible = false;
        }
      }
      if ( obj.isFloorplan ) {
        obj.marker.visible = visibility;
      }
      if ( obj.isStage ) {
        if ( visibility ) {
          if ( obj === this.floorplanModeParams.currentStage ) {
            obj.visible = true;
          } else {
            obj.visible = false;
          }
        } else {
          obj.visible = true;
        }
      }
      if ( obj.isCorner ) {
        obj.visible = visibility;
      }
      if ( obj.isWall3D ) {
        if ( !visibility ) {
          obj.setMaterialTo3DMode();
        }
        if ( visibility ) {
          obj.setMaterialToUnselected();
        }
      }
    } );
  }


  initCubeCamera() {
    const floorplanBox = this.floorplan2.getBoundingBox();
    const center = new Vector3();
    floorplanBox.getCenter( center );
    const min = floorplanBox.min;
    const max = floorplanBox.max;
    const maxDim = Math.max( max.x - min.x, max.z - min.z );

    this.cubeCamera = new CubeCamera( 0.1, 3 * maxDim, 64 );
    this.cubeCamera.renderTarget.texture.generateMipmaps = false;
    this.cubeCamera.position.copy( center );
    this.cubeCamera.flag = true;
    this.scene.add( this.cubeCamera );

  }

  updateMaterialsEnvMaps( envMap, envMapIntensity ) {

    const materials = Storage.get( 'materials' );
    // for ( let arr in materials ) {
    // if ( materials.hasOwnProperty( arr ) ) {
    /* for ( let i = 0; i < materials.cabinet.length; i += 1 ) {
       materials.cabinet[ i ].material.envMap = envMap;
       materials.cabinet[ i ].material.envMapIntensity = envMapIntensity;
     }

     for ( let i = 0; i < materials.countertop.length; i += 1 ) {
       materials.countertop[ i ].material.envMap = envMap;
       materials.countertop[ i ].material.envMapIntensity = envMapIntensity;
     } */

    for ( let i = 0; i < materials.wall.length; i += 1 ) {
      materials.wall[ i ].material.envMap = envMap;
      materials.wall[ i ].material.envMapIntensity = envMapIntensity;
    }

    for ( let i = 0; i < materials.floor.length; i += 1 ) {
      materials.floor[ i ].material.envMap = envMap;
      materials.floor[ i ].material.envMapIntensity = envMapIntensity;
    }
    //  }
    // }
  }

  usePerspectiveCamera() {

    this.renderPass.camera = this.camera;
    this.outlinePass.renderCamera = this.camera;
    this.controls.object = this.camera;
    this.controls.reset();
    // this.controls.target.copy( center );
    this.scene.dragDropManager.camera = this.camera;
    this.orthoMode = false;
    this.scene.setDimensionsVisibility( false );
    if ( this.scene._background ) {
      this.scene.background = this.scene._background;
      Reflect.deleteProperty( this.scene, '_background' );
    }

    this.setCameraViewFront();
  }

  lockCamera() {

    this.controls.enabled = false;
    this.isCameraUnlocked = false;
  }

  unlockCamera() {
    this.controls.enabled = true;
    this.isCameraUnlocked = true;

  }

  initRenderer() {
    const renderer = new WebGLRenderer( {
      canvas: this.canvas,
      context: this.context,
      antialias: false,
      preserveDrawingBuffer: true,
      alpha: true,
      logarithmicDepthBuffer: true
    } );

    renderer.gammaInput = true;
    renderer.gammaOutput = true;
    renderer.gammaFactor = 2;
    renderer.toneMapping = Uncharted2ToneMapping;
    renderer.setSize( calcCanvasWidth( window.innerWidth ), window.innerHeight );
    // renderer.setScissorTest( true );
    let size = new Vector2();
    renderer.getSize( size );
    // renderer.setScissor( 0, 0, size.x * 0.78, size.y );

    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = PCFSoftShadowMap;
    renderer.setPixelRatio(
      window.devicePixelRatio ? window.devicePixelRatio : 1
    );
    renderer.setClearColor( new Color( 1, 1, 1 ), 1.0 );

    this.parentElement.appendChild( renderer.domElement );
    renderer.autoClear = false;

    this.renderer = renderer;

    this.renderOnDemand = new RenderOnDemand();
    this.onceActionsMgr = new OnceActionsMgr();
  }

  initComposer() {
    const composer = new EffectComposer( this.renderer );
    /*
     * Const ssaoPass = new SSAOPass(
     * this.scene,
     * this.camera,
     * window.innerWidth,
     * window.innerHeight
     * );
     * ssaoPass.kernelRadius = 32;
     * ssaoPass.minDistance = 0.01;
     * ssaoPass.maxDistance = 0.3;
     * composer.addPass(ssaoPass);
     * ssaoPass.renderToScreen = true;
     * ssaoPass.output = SSAOPass.OUTPUT.Default;
     */
    this.renderPass = new RenderPass( this.scene, this.camera );
    this.renderPass.renderToScreen = false;
    composer.addPass( this.renderPass );

    this.outlinePass = new OutlinePass(
      new Vector2( calcCanvasWidth( window.innerWidth ), window.innerHeight ),
      this.scene,
      this.camera,
      []
    );
    this.outlinePass.renderToScreen = false;
    composer.addPass( this.outlinePass );

    this.FXAAPass = new ShaderPass( FXAAShader );
    this.outlinePass.renderToScreen = true;
    this.FXAAPass.material.uniforms.resolution.value.x = 1 / this.canvas.width;
    this.FXAAPass.material.uniforms.resolution.value.y = 1 / this.canvas.height;

    composer.addPass( this.FXAAPass );
    this.composer = composer;
  }

  onResize() {
    this.renderer.setPixelRatio(
      window.devicePixelRatio ? window.devicePixelRatio : 1
    );
    if ( this.firstPersonControls.enabled ) {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.renderer.setSize( window.innerWidth, window.innerHeight );
      this.composer.setSize( window.innerWidth, window.innerHeight );
    } else {
      this.camera.aspect = calcCanvasWidth( window.innerWidth ) / window.innerHeight;
      this.renderer.setSize( calcCanvasWidth( window.innerWidth ), window.innerHeight );
      this.composer.setSize( calcCanvasWidth( window.innerWidth ), window.innerHeight );
    }

    this.camera.updateProjectionMatrix();
    let size = new Vector2();
    this.renderer.getSize( size );
    // this.renderer.setScissor( 0, 0, size.x * 0.78, size.y );
    console.log( 'resize' );
    // OrthoCamera update
    if ( this.orthoMode ) {

      const floorplanBox = this.floorplan2.getBoundingBox();
      const center = new Vector3();
      floorplanBox.getCenter( center );
      const min = floorplanBox.min;
      const max = floorplanBox.max;
      const maxDim = Math.max( max.x - min.x, max.z - min.z );

      const canvasAspect = ( this.canvas.width / this.canvas.height );
      let oHeight = max.y - min.y;
      let oWidth = oHeight * canvasAspect;

      if ( oWidth < maxDim ) {
        oHeight = maxDim / canvasAspect;
        oWidth = maxDim;
      }

      this.orthoCamera.left = -oWidth * 0.9;
      this.orthoCamera.right = oWidth * 0.9;
      this.orthoCamera.bottom = -oHeight * 0.9;
      this.orthoCamera.top = oHeight * 0.9;

      this.orthoCamera.updateProjectionMatrix();
    }
    if ( this.floorplanMode ) {

      const floorplanBox = this.floorplan2.getBoundingBox();
      const center = new Vector3();
      floorplanBox.getCenter( center );
      const min = floorplanBox.min;
      const max = floorplanBox.max;
      const maxDim = Math.max( max.x - min.x, max.z - min.z );

      const canvasAspect = ( this.canvas.width / this.canvas.height );
      let oHeight = max.y - min.y;
      let oWidth = oHeight * canvasAspect;

      if ( oWidth < maxDim ) {
        oHeight = maxDim / canvasAspect;
        oWidth = maxDim;
      }

      this.floorplanCamera.left = -oWidth * 0.9;
      this.floorplanCamera.right = oWidth * 0.9;
      this.floorplanCamera.bottom = -oHeight * 0.9;
      this.floorplanCamera.top = oHeight * 0.9;

      this.floorplanCamera.updateProjectionMatrix();
    }

    this.renderOnDemand.set();
  }

  saveProject() {
    this.lastSavedProject = serializeViewer( this );
  }

  loadSaved() {
    deserializeViewer( this.lastSavedProject );
  }

  onScroll( e ) {
    const bounding = this.renderer.domElement.getBoundingClientRect();
    const x = bounding.left;
    const y = bounding.top;
    const ww = Math.max(
      document.documentElement.clientWidth,
      calcCanvasWidth( window.innerWidth ) || 0
    );
    const hw = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    const w = this.canvas.clientWidth;
    const h = this.canvas.clientHeight;

    this.enabled = y < hw && y + h > 0 && ( x < ww && x + w > 0 );
  }


  init() {
    this.initRenderer();
    this.initCamera();
    this.initScene();
    this.initControls();
    this.initCubeCamera();
    this.initComposer();

    window.addEventListener( 'resize', () => this.onResize(), false );
    window.addEventListener( 'scroll', ( e ) => this.onScroll( e ), false );

    /*
     * this.addEventListener('objectselected', (event) => {
     * console.log('objectselected :', event.data);
     *});
     */

    /* this.raytracingRenderer = new RaytracingRenderer({
      workers: 8,
      workerPath: '/assets/RaytracingWorker.js',
      randomize: true,
      blockSize: 20
    });
    // this.raytracingRenderer = new WebGLRenderer();
    this.raytracingRenderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(this.raytracingRenderer.domElement); */

    // To disable global keyevent handling use this element instead of document or window.
    this.parentElement.tabIndex = '0';
    this.parentElement.addEventListener( 'keydown', ( event ) => {
      if ( event.ctrlKey && event.keyCode == 90 ) { // ctrl + z
        this.doMgr.undo();
      } else if ( event.ctrlKey && event.keyCode == 89 ) { // ctrl + y
        this.doMgr.redo();
      } else if ( event.keyCode === 49 ) {
      } else if ( event.keyCode === 70 ) { // f
        // if (this.floorplanMode) {
        //   this.floorplan2.rebuildGeometry();
        //   this.outlinePass.selectedObjects = [];
        //   this.scene.remove(this.floorplanModeParams.floorplanGrid);
        //   this.floorplan2.enableLights();
        //   this.controls.minZoom = 0;
        //   this.controls.maxZoom = Infinity;
        //   this.usePerspectiveCamera();
        //   this.useOrbitControls();
        // } else {
        //   this.outlinePass.selectedObjects = [];
        //   this.useFloorplanMode();
        // }
      } else if ( event.keyCode === 79 ) { // o
        if ( this.floorplanMode ) {
          this.floorplanModeParams.orthoMode = !this.floorplanModeParams.orthoMode;
        }
      } else if ( event.keyCode === 51 ) {
      } else if ( event.keyCode === 52 ) {
      } else if ( event.keyCode === 53 ) {
        // this.scene.remove( this.scene.roof );
        // this.scene.traverse( ( obj ) => {
        //   if ( obj.isMesh && obj.material.visible && obj.visible ) {
        //     obj.material = new MeshPhongMaterial( {
        //       mirror: true, reflectivity: 0.1, refractionRatio: 0.98, color: 0xffffff, specular: 0x111111, side: FrontSide, shininess: 0.2, flatShading: false
        //     } );
        //   }
        //   if ( obj.isPointLight ) {
        //     obj.intensity = 1000.0;
        //     obj.physicalAttenuation = true;
        //   }
        // } );
        // this.raytracingRenderer.render( testScene, testCamera );
        // this.scene.overrideMaterial = null;
      } else if ( event.keyCode === 90 ) {
        this.saveProject();
        // console.log( JSON.stringify( this.lastSavedProject, null, 2 ) );
        console.log( 'Project saved!' );
      } else if ( event.keyCode === 88 ) {
        this.loadSaved();
        console.log( 'Project loaded!' );
      } else if ( event.keyCode === 67 ) {
        // // eslint-disable-next-line no-alert
        // deserializeViewer( JSON.parse( prompt( 'Enter project JSON' ) ) );
        // console.log( 'Project loaded!' );
      } else if ( event.keyCode === 53 ) {
        // const itemsMaterial = new THREE.LineBasicMaterial({
        //   color: 0xffffff
        // });
        // const edgesMaterial = new THREE.LineBasicMaterial({
        //   color: 0x000000
        // });
        // const edges = [];
        // // setup 2D drawing scene
        // this.scene._background = this.scene.background;
        // this.scene.background = new Color(0xffffff);
        // this.scene.traverse((obj) => {
        //   if (obj.isMesh) {
        //     obj._material = obj.material;
        //     obj.material = itemsMaterial;
        //     const edge = new EdgesGeometry(obj.geometry);
        //     const edgesMesh = new LineSegments(edge, edgesMaterial);
        //     obj.add(edgesMesh);
        //     edges.push(edgesMesh);
        //   }
        // });
      }
    } );

    this.dispatch( { type: 'load', data: null } );

    this.clock = new Clock( true );
    this.animate();
  }

  toDoRemoveObject(item) {
    const parent = item.parent;

    this.transformControls && this.transformControls.detach();
    if (parent) {
      if (isHoleableWithWall(item)) this.onceActionsMgr.registerRefreshWallHoles(getWall3D(item)); // refresh holes
      parent.remove(item);
    }
    this.renderOnDemand.set();

    this.doMgr.registerDo(new DoAddRemoveEntity(item, false, parent)); // register undo/redo
  }

  getDomElement() {
    return this.renderer.domElement;
  }

  animate( time = 0 ) {
    requestAnimationFrame( ( time ) => this.animate( time ) );

    this.render();

    // if (time === 0) {
    // this.updateMaterialsEnvMaps( this.cubeCamera.renderTarget.texture, 0.75 );
    // }
  }

  render() {
    if ( this.controls.enabled ) {
      this.controls.update();
    }
    if ( this.firstPersonControls.enabled ) {
      this.firstPersonControls.update( this.clock.getDelta() );
    }
    if ( this.cubeCamera.flag ) {
      // this.updateMaterialsEnvMaps( null, 0 );
      this.cubeCamera.update( this.renderer, this.scene );
      // this.updateMaterialsEnvMaps( this.cubeCamera.renderTarget.texture, 0.75 );
      this.cubeCamera.flag = false;
    }
    this.onceActionsMgr.run();
    if ( this.renderOnDemand.check() ) {
      /*
       * Console.log('render');
       *this.renderer.clear(true, true);
       *this.renderer.render(this.scene, this.camera);
       */
      this.composer.render();
      this.renderOnDemand.reset();
    }
  }
}
