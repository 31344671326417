/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import {
  LabeledField
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledField';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import Storage from 'scr/utilitiesStorage';
import {
  GenericCabinet
} from 'cSrc/structured/singletonCabinet/core/GenericCabinet';
import {
  ContainerConfig, cabinetConfig
} from 'decl/cabinetdesigner/ContainerConfigs.d.ts';
import { RadioSelect } from 'c/CalculationLayer/utils/RadioSelect';

interface _IStateProps {
  swing: redux._IStore['selectedCabinet']['swing'];
}

interface _IDispatchProps {
  dispatch: Dispatch< redux._IActions['SET_SELECTED_CABINET_SWING'] >;
}

const StyledLabeledField = styled( LabeledField )`
  margin-top: 10px;
`;

export function findDoorSwing(
  config: cabinetConfig
): Array< redux._IStore['selectedCabinet']['swing'] > {
  if( config.type === 'Door' ) {
    const { handlePosition: hP, handleOrientation: hO } = config;
    if( !hO ) return [null];

    return hP.oX.type === 'toLeft' ? ['left'] : ['right'];
  }

  if( config.type === 'Panel' || !config.children ) return [null];

  return config.children
    .reduce< ReturnType<typeof findDoorSwing> >(
      ( acc, child ) => acc.concat( findDoorSwing( child as ContainerConfig ) ),
      []
    )
    .filter( ( it ) => it !== null );
}

export function setDoorSwing(
  config: cabinetConfig,
  swing: redux._IStore['selectedCabinet']['swing']
): void {
  if( config.type === 'Door' ) {
    const { handlePosition: hP, handleOrientation: hO } = config;
    if( hO === 'VERTICAL' ) {
      hP.oX.type = swing === 'left' ? 'toLeft' : 'toRight';
    }

    return;
  }

  if( config.type === 'Panel' || !config.children ) return;

  config.children.forEach( ( child ) => setDoorSwing( child as cabinetConfig, swing ) );
}

interface _IVariant {
  label: string;
  value: 'left' | 'right';
  chosen: boolean;
}
export const Swing = connect<
  _IStateProps,
  _IDispatchProps,
  {},
  redux._IStore
>(
  ( s ) => ( { swing: s.selectedCabinet.swing } )
)(
  class Swing extends PureComponent<_IStateProps & _IDispatchProps> {
    chooseVariant = ( value: 'left' | 'right' ) => {
      const { props: { dispatch } } = this;
      const viewer = Storage.get( 'viewer' );

      dispatch( { type: 'SET_SELECTED_CABINET_SWING', value } );

      const cabinet = Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet;
      const config = cabinet.getConfig() as cabinetConfig;
      const angle = cabinet.getDoorsAngle();
      setDoorSwing( config, value );

      viewer.transformControls && viewer.transformControls.detach();
      const newCab = cabinet.reloadConfig( config );
      newCab.openAllDoors( angle );

      Storage.set( 'selectedObject', [newCab] );
      viewer.outlinePass!.selectedObjects = Storage.get( 'selectedObject' );
      viewer.renderOnDemand.set();
    }

    render() {
      const { props: { swing }, chooseVariant } = this;
      const variants: _IVariant[] = [
        { label: 'Left', value: 'left', chosen: swing === 'left' },
        { label: 'Right', value: 'right', chosen: swing === 'right' }
      ];
      if( swing === null ) return null;

      return (
        <StyledLabeledField name='Swing'>
          <RadioSelect { ... { variants, chooseVariant } } />
        </StyledLabeledField>
      );
    }
  }
);
