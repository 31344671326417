const calcToeKickFillerCost = ( {
  group,
  width,
  height,
  toeKickHeight: toeKickWidth,
  leftSideFillerWidth,
  rightSideFillerWidth,
  materialCost,
  materialName
} ) => {

  console.log( `Material name: ${ materialName }` );
  console.log( `Material cost: ${ materialCost }` );
  console.log( `Toe kick width: ${ toeKickWidth }` );
  console.log( `Left side filler width: ${ leftSideFillerWidth }` );
  console.log( `Right side filler width: ${ rightSideFillerWidth }` );

  let fillersQty = 0;
  // if (Number(leftSideFillerWidth) > 0) fillersQty++;
  // if (Number(rightSideFillerWidth) > 0) fillersQty++;
  if ( leftSideFillerWidth || rightSideFillerWidth ) fillersQty = 1;

  console.log( `Fillers quantity: ${ fillersQty }` );

  const fillersLength = fillersQty ? height : 0;
  const fillersWidth = leftSideFillerWidth + rightSideFillerWidth;

  console.log( `Fillers length: ${ fillersLength }` );
  console.log( `Fillers width: ${ fillersWidth }` );

  const fillersTotal = fillersQty * fillersLength * fillersWidth;

  console.log( `Fillers area: ${ fillersTotal }` );

  const toeKickQty = group === 'Upper' ? 0 : 1;

  console.log( `Toe kick quantity: ${ toeKickQty }` );

  if ( !fillersQty && !toeKickQty ) return null;

  const toeKickLength = toeKickQty ? width : 0;

  console.log( `Toe kick length: ${ toeKickLength }` );

  const toeKickTotal = toeKickQty * toeKickLength * toeKickWidth;

  console.log( `Toe kick area: ${ toeKickTotal }` );

  const totalLength = Number( ( fillersLength + toeKickLength ).toFixed( 3 ) );

  console.log( `Total length: ${ totalLength }` );

  const area = Number( ( ( fillersTotal + toeKickTotal ) / 144 ).toFixed( 2 ) );

  const cost = Number(
    ( area * materialCost ).toFixed( 2 )
  );

  console.log( `Total cost: ${ cost }` );

  return {
    name: materialName,
    length: totalLength,
    // doubled prices
    cost: 2 * cost,
    area
  };
};

export default ( {
  group,
  width,
  height,
  toeKickHeight,
  leftSideFillerWidth,
  rightSideFillerWidth,
  materialCost,
  materialName,
  materialSheetArea,
  materialSheetCost,
  totalMaterials,
  totalToeKickFillers
} ) => {

  const newToeKickFiller = calcToeKickFillerCost( {
    group,
    width,
    height,
    toeKickHeight,
    leftSideFillerWidth,
    rightSideFillerWidth,
    materialCost,
    materialName
  } );

  if ( !newToeKickFiller ) return { totalToeKickFillers, totalMaterials };

  const newTotalToeKickFillers = totalToeKickFillers.slice();
  const newTotalMaterials = { ...totalMaterials };

  const {
    length: newLength,
    cost: newCost,
    area
  } = newToeKickFiller;

  const materialArea = area;
  // eslint-disable-next-line prefer-reflect
  delete newToeKickFiller.area;

  const toeKickFillersExists = newTotalToeKickFillers
    .find( ( item ) => item.name === materialName );

  if ( toeKickFillersExists ) {
    for ( let i = 0; i < newTotalToeKickFillers.length; i++ ) {
      const { name, cost } = newTotalToeKickFillers[ i ];

      if ( name === materialName ) {
        newTotalToeKickFillers[ i ].length += newLength;
        newTotalToeKickFillers[ i ].cost = Number(
          ( cost + newCost ).toFixed( 2 )
        );

        break;
      }
    }
  } else {
    newTotalToeKickFillers.push( newToeKickFiller );
  }

  if ( newTotalMaterials[ materialName ] ) {
    newTotalMaterials[ materialName ].area += materialArea;
  } else {
    newTotalMaterials[ materialName ] = {
      area: materialArea,
      cost: materialCost,
      sheetArea: materialSheetArea,
      sheetCost: Number( materialSheetCost )
    };
  }

  return {
    totalToeKickFillers: newTotalToeKickFillers,
    totalMaterials: newTotalMaterials
  };
};
