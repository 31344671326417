import { simpleSet } from '@r/reducers/utils';
import {
  UnitsInputValue
} from '@npmrost/storybook/stories/Vesta/Inputs/UnitsInput';

const setCalculationModeAdminSettingsTopDrawerHeightLabel = simpleSet as
  redux.reducers.calculationMode.adminSettings.projectSettings.dimensions.topDrawerHeight.label;

export const label = setCalculationModeAdminSettingsTopDrawerHeightLabel(
  'SET_CALCULATION_MODE_ADMIN_SETTINGS_TOP_DRAWER_HEIGHT_LABEL',
  '' as UnitsInputValue
);
