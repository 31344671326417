
import React from 'react';
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {DirectionLight as directionligth, AmbientLight as ambientlight} from './light'
import Tick from './tick'
import _ from 'lodash';

let GlEment = {
  directionligth: {
    create({color, position, castShadow}={}) {
      let direction_light = new THREE.DirectionalLight(new THREE.Color(color), .95);
      direction_light.position.set(position.x,position.y,position.z);
      direction_light.castShadow = castShadow;
      return direction_light;
    }
  }
  ,ambientlight: {
    create({color}={}) {
      let ambient_light = new THREE.AmbientLight(new THREE.Color(color));
      return ambient_light;
    }
  }
  ,spotlight: {
    create(...args) {
    }
  }
}

class Model extends React.Component {
  static defaultProps = {
    width: 500
    ,height: 500
    ,antialias: true
    ,loader: ''
    ,baseUrl: ''
    ,texPath: ''
    ,position: {x:0, y: 0, z: 0}
    ,scale: {x:1, y:1, z: 1}
    ,rotation: {x:0, y: 0, z: 0}
    ,background: 'rgb(240, 240, 240)'
    // enableKeys,enableRotate,enableZoom,enabled
    ,enableKeys: false
    ,enablePan: true
    ,enableRotate: true
    ,enableZoom: true
    ,enabled: true

    ,initControl: true
    ,doorsAngle: 0
  }

  constructor(props) {
    super(props);

    this.obj3d;
    this.src;
    this.lights = [];

    this.group = new THREE.Group()
  }
 
  render() {
    const {props: {className}} = this;
    return (
      <div ref={node => this.$container=node} data-loader={this.props.loader} className={className}>
      </div>
    );
  }

  get array_children() {
    if(!this.props.children) return [];
    if(!(this.props.children instanceof Array)) return [this.props.children];
    return this.props.children;
  }
  get need_children_lights() {
    if (this.array_children.filter((o) => {
      return /directionligth|ambientlight|spotlight/.test(o.props.__constructor)
    }).length) {
      return true;
    }
    return false;
  }

  getWidth() {
    return this.$container.clientWidth || this.props.width;
  }
  getHeight() {
    return this.$container.clientHeight || this.props.height;
  }

  addObject(obj3d) {
    this.group.add(obj3d);
  }
  removeObject(obj3d) {
    this.group.remove(obj3d)
  }

  componentDidMount() {
    let {antialias, background} = this.props;
    
    const width = this.getWidth();
    const height = this.getHeight();

    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(50, width / height);
    
    this.renderer = new THREE.WebGLRenderer({antialias});
    this.renderer.setClearColor(new THREE.Color(background));
    this.renderer.setSize(width, height);

    this.$container.appendChild(this.renderer.domElement);

    this.scene.add(this.group)
    this.camera.position.set(0, 0, .1);

    this.createDefaultLight();

    let {position, rotation, scale} = this.props;
    this.group.position.copy(new THREE.Vector3(position.x, position.y, position.z));
    this.group.rotation.set(rotation.x, rotation.y, rotation.z);
    this.group.scale.set(scale.x, scale.y, scale.z);
    
    this.load3dModel();
    if (this.obj3d) this.try3dModel();

    this.tick = Tick(() => {
      this.renderer.render(this.scene, this.camera);
      if (this.obj3d) {
        if (!this.group.children.length) this.addObject(this.obj3d);
        let {position, rotation, scale} = this.props;
        this.group.position.copy(new THREE.Vector3(position.x, position.y, position.z))
        this.group.rotation.set(rotation.x, rotation.y, rotation.z)
        this.group.scale.set(scale.x, scale.y, scale.z)
      }
    });
  }
  componentWillUnmount() {
    this.tick.animate = false
  }
  componentDidUpdate() {
    let {src, background} = this.props;
    const width = this.getWidth();
    const height = this.getHeight();

    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);

    this.initCameraControl();
    if (!_.isEqual(this.src, src)) this.load3dModel();
    if (this.obj3d) this.try3dModel();

    this.lights.forEach((light) => this.scene.remove(light));
    this.lights = [];
    this.createDefaultLight();
  }

  initCameraControl() {
    const {enableKeys, enablePan, enableRotate, enableZoom, enabled} = this.props;

    let controls = this.controls;
    if (!controls) {
      controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls = controls;

      const d = 1;
      controls.zoomSpeed = d;
      controls.rotateSpeed = d;
      controls.panSpeed = d;
    }

    Object.assign(controls, {
      enableKeys
      ,enablePan
      ,enableRotate
      ,enableZoom
      ,enabled
    });
    controls.update();
  }
  
  load3dModel() {
  }
  try3dModel() {
  }

  addLight(__constructor, props) {
    if(!(/directionligth|ambientlight|spotlight/.test(__constructor))) return;
    let o = GlEment[__constructor].create(props);
    this.scene.add(o);
    this.lights.push(o);
    return o;
  }
  addChildrenLights() {
    this.lights.forEach((light) => {
      this.scene.remove(light);
    });
    this.lights = [];

    this.array_children.map(o => {
      let {props} = o;
      let {__constructor} = props;
      this.addLight(__constructor,props);
    });
  }
  createDefaultLight() {
    this.addLight('ambientlight', ambientlight.defaultProps);

    if (this.need_children_lights) {
      this.addChildrenLights();
    } else {
      const color = 'rgb(92, 92, 92)';
      const color2 = 'rgb(32, 32, 32)';

      this.addLight('directionligth', {
        ...directionligth.defaultProps
        ,position: new THREE.Vector3(1, 1, 1)
        ,color: color
      });
      this.addLight('directionligth', {
        ...directionligth.defaultProps
        ,position: new THREE.Vector3(1, 1, -1)
        ,color: color
      });
      this.addLight('directionligth', {
        ...directionligth.defaultProps
        ,position: new THREE.Vector3(-1, 1, 1)
        ,color: color
      });
      this.addLight('directionligth', {
        ...directionligth.defaultProps
        ,position: new THREE.Vector3(-1, 1, -1)
        ,color: color
      });

      this.addLight('directionligth', {
        ...directionligth.defaultProps
        ,position: new THREE.Vector3(1, -1, 1)
        ,color: color2
      });
      this.addLight('directionligth', {
        ...directionligth.defaultProps
        ,position: new THREE.Vector3(1, -1, -1)
        ,color: color2
      });
      this.addLight('directionligth', {
        ...directionligth.defaultProps
        ,position: new THREE.Vector3(-1, -1, 1)
        ,color: color2
      });
      this.addLight('directionligth', {
        ...directionligth.defaultProps
        ,position: new THREE.Vector3(-1, -1, -1)
        ,color: color2
      });
    }
  }
}

export default Model
