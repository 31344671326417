import {
  Group, Shape, Vector3, PointLight, Box3, Quaternion, Matrix4, Vector2
} from 'three';

import Storage from 'scr/utilitiesStorage';
import Floor2D from './Floor2D';
import Ceiling2D from './Ceiling2D';
import Wall2D from './Wall2D';


export default class Room extends Group {
  constructor( stage, corners ) {
    super();
    this.stage = stage;
    this.corners = corners;
    this.walls2D = [];
    this.lights = [];


    this.floor2D = new Floor2D( '', new Shape(), Storage.get( 'defaultMaterials' ).floor );
    this.floor2D.room = this;
    this.floor2D.rotation.set( -Math.PI / 2, 0, 0 );

    this.ceiling2D = new Ceiling2D( '', new Shape() );
    this.ceiling2D.rotation.set( -Math.PI / 2, 0, 0 );
    this.ceiling2D.position.set( 0, Storage.get( 'cabinetsSettings' ).tall.height.value, 0 );

    this.rebuildGeometry();
    this.createLighting();

    this.add( this.floor2D );
    this.add( this.ceiling2D );

    this.isRoom = true;
  }

  createLighting() {
    const box = this.floor2D.getBoundingBox();
    const light = new PointLight(
      0xffffff,
      1.5,
      Math.sqrt(
        Math.pow( box.max.x - box.min.x, 2 ),
        Math.pow( box.max.z - box.min.z, 2 ),
        Math.pow( this.height, 2 ) ) );
    /* light.castShadow = true;
    light.shadow.mapSize.width = 2048;
    light.shadow.mapSize.height = 2048;
    light.shadow.camera.near = 0.1;
    light.shadow.camera.far = Math.sqrt(
      ( box.max.x - box.min.x ) * ( box.max.x - box.min.x ) +
      this.height * this.height +
      ( box.max.z - box.min.z ) * ( box.max.z - box.min.z )
    );
    light.shadow.bias = -0.001; */
    light.position.set( ( box.max.x + box.min.x ) / 2, this.height - 5, ( box.max.z + box.min.z ) / 2 );
    this.lights.push( light );
    this.add( light );
  }

  cornersOnLine( i ) {
    const corner1 = ( i > this.corners.length - 1 )
      ? this.corners[ i - this.corners.length ] : ( i < 0 )
        ? this.corners[ i + this.corners.length ] : this.corners[ i ];
    const corner2 = ( i + 1 > this.corners.length - 1 )
      ? this.corners[ i + 1 - this.corners.length ] : ( i + 1 < 0 )
        ? this.corners[ i + 1 + this.corners.length ] : this.corners[ i + 1 ];
    const corner3 = ( i + 2 > this.corners.length - 1 )
      ? this.corners[ i + 2 - this.corners.length ] : ( i + 2 < 0 )
        ? this.corners[ i + 2 + this.corners.length ] : this.corners[ i + 2 ];

    return ( Math.abs( ( corner3.x - corner1.x ) / ( corner2.x - corner1.x ) -
      ( corner3.y - corner1.y ) / ( corner2.y - corner1.y ) ) <= 1e-5 ||
      ( ( Math.abs( corner1.x - corner2.x ) <= 1e-5 ) && ( Math.abs( corner1.x - corner3.x ) <= 1e-5 ) ) ||
      ( Math.abs( corner1.y - corner2.y ) <= 1e-5 && Math.abs( corner1.y - corner3.y ) <= 1e-5 ) );


  }

  findOneLineCorners() {
    const result = { ranges: [], numbers: [] };
    for ( let i = 0; i < this.corners.length; i += 1 ) {
      if ( this.cornersOnLine( i ) ) {
        let j = i - 1;
        while ( this.cornersOnLine( j ) ) {
          j -= 1;
        }
        let k = i + 1;
        while ( this.cornersOnLine( k ) ) {
          k += 1;
        }
        for ( let l = j + 1; l < k + 1; l += 1 ) {
          result.numbers.push( l );
        }

        if ( i === j + 1 ) {
          result.ranges.push( { min: j + 1, max: k + 1 } );
        }

      }
    }

    return result;
  }


  findWalls2D() {
    this.walls2D = [];

    const oneLineCorners = this.findOneLineCorners();

    for ( let i = 0; i < this.corners.length; i += 1 ) {
      let current = i;
      let next = i + 1;
      if ( next > ( this.corners.length - 1 ) ) {
        next = 0;
      }

      let currentCorner = this.corners[ current ];
      let nextCorner = this.corners[ next ];

      for ( let j = 0; j < this.stage.walls3D.length; j += 1 ) {
        if ( ( this.stage.walls3D[ j ].from === currentCorner &&
          this.stage.walls3D[ j ].to === nextCorner ) ||
          ( this.stage.walls3D[ j ].to === currentCorner &&
            this.stage.walls3D[ j ].from === nextCorner ) ) {

          let normal = new Vector2(
            nextCorner.x - currentCorner.x,
            nextCorner.y - currentCorner.y )
            .rotateAround( new Vector2(), Math.PI / 2 )
            .normalize();

          let point = new Vector2( this.stage.walls3D[ j ].position.x, this.stage.walls3D[ j ].position.z )
            .add( normal );

          let plane1 = this.stage.walls3D[ j ].wall2D_1.getPlane();
          let plane2 = this.stage.walls3D[ j ].wall2D_2.getPlane();
          let distance1 = Math.abs( plane1.distanceToPoint( new Vector3( point.x, 0, point.y ) ) );
          let distance2 = Math.abs( plane2.distanceToPoint( new Vector3( point.x, 0, point.y ) ) );

          if ( distance1 <= distance2 ) {
            this.walls2D.push( this.stage.walls3D[ j ].wall2D_1 );
            this.stage.walls3D[ j ].add( this.stage.walls3D[ j ].wall2D_1 );
            this.stage.walls3D[ j ].wall2D_1.room = this;
            Reflect.deleteProperty( this.stage.walls3D[ j ].wall2D_1, 'relatedWalls2D' );
          } else {
            this.walls2D.push( this.stage.walls3D[ j ].wall2D_2 );
            this.stage.walls3D[ j ].add( this.stage.walls3D[ j ].wall2D_2 );
            this.stage.walls3D[ j ].wall2D_2.room = this;
            Reflect.deleteProperty( this.stage.walls3D[ j ].wall2D_2, 'relatedWalls2D' );
          }

          break;
        }
      }

    }


    for ( let i = 0; i < oneLineCorners.ranges.length; i += 1 ) {
      let startWall2D = null;
      let startWall3D = null;
      let width = 0;
      let height = 0;
      let walls2D = [];
      for ( let k = oneLineCorners.ranges[ i ].min; k <= oneLineCorners.ranges[ i ].max; k += 1 ) {
        let current = k;
        let next = k + 1;
        if ( next > ( oneLineCorners.ranges[ i ].max ) ) {
          next = oneLineCorners.ranges[ i ].min;
        }
        if ( current > this.corners.length - 1 ) {
          current -= this.corners.length;
        }
        if ( current < 0 ) {
          current += this.corners.length;
        }
        if ( next > this.corners.length - 1 ) {
          next -= this.corners.length;
        }
        if ( next < 0 ) {
          next += this.corners.length;
        }


        for ( let j = 0; j < this.stage.walls3D.length; j += 1 ) {
          if ( ( this.stage.walls3D[ j ].from === this.corners[ current ] &&
            this.stage.walls3D[ j ].to === this.corners[ next ] ) ||
            ( this.stage.walls3D[ j ].to === this.corners[ current ] &&
              this.stage.walls3D[ j ].from === this.corners[ next ] ) ) {


            let normal = new Vector2(
              this.corners[ next ].x - this.corners[ current ].x,
              this.corners[ next ].y - this.corners[ current ].y )
              .rotateAround( new Vector2(), Math.PI / 2 )
              .normalize();

            let point = new Vector2( this.stage.walls3D[ j ].position.x, this.stage.walls3D[ j ].position.z )
              .add( normal );

            let plane1 = this.stage.walls3D[ j ].wall2D_1.getPlane();
            let plane2 = this.stage.walls3D[ j ].wall2D_2.getPlane();
            let distance1 = Math.abs( plane1.distanceToPoint( new Vector3( point.x, 0, point.y ) ) );
            let distance2 = Math.abs( plane2.distanceToPoint( new Vector3( point.x, 0, point.y ) ) );

            if ( distance1 <= distance2 ) {
              walls2D.push( this.stage.walls3D[ j ].wall2D_1 );
            } else {
              walls2D.push( this.stage.walls3D[ j ].wall2D_2 );
            }

            break;
          }
        }
      }

      for ( let j = 0; j < walls2D.length; j += 1 ) {
        walls2D[ j ].relatedWalls2D = walls2D;
        if ( j > 0 ) {
          walls2D[ j ].setGrainOffset( new Vector2( walls2D[ j - 1 ].getMaxUV().x, 0 ) );
        }
      }
    }
  }

  /* findWalls2DOLD() {
    this.walls2D = [];
    // const oneLineCorners = this.findOneLineCorners();
    for (let i = 0; i < this.corners.length; i += 1) {
      let current = i;
      let next = i + 1;
      if (next > (this.corners.length - 1)) {
        next = 0;
      }
      if (true || (oneLineCorners.numbers.indexOf(current) === -1 &&
        oneLineCorners.numbers.indexOf(current) === -1)) {
        for (let j = 0; j < this.stage.walls3D.length; j += 1) {
          if ((this.stage.walls3D[j].from === this.corners[current] &&
            this.stage.walls3D[j].to === this.corners[next]) ||
            (this.stage.walls3D[j].to === this.corners[current] &&
              this.stage.walls3D[j].from === this.corners[next])) {
            let center = new Vector3();
            let wall2D = this.stage.walls3D[j]
              .getNearestWall2D(this.floor2D.getBoundingBox()
                .getCenter(center));
            this.walls2D.push(wall2D);
            this.stage.walls3D[j].add(wall2D);
            wall2D.room = this;
            break;
          }
        }
      }
    }

    // console.log(oneLineCorners.ranges);

    /* for (let i = 0; i < oneLineCorners.ranges.length; i += 1) {
       let startWall2D = null;
       let startWall3D = null;
       let width = 0;
       let height = 0;
       for (let k = oneLineCorners.ranges[i].min; k <= oneLineCorners.ranges[i].max; k += 1) {
         let current = k;
         let next = k + 1;
         if (next > (oneLineCorners.ranges[i].max)) {
           next = oneLineCorners.ranges[i].min;
         }
         if (current > this.corners.length - 1) {
           current -= this.corners.length;
         }
         if (current < 0) {
           current += this.corners.length;
         }
         if (next > this.corners.length - 1) {
           next -= this.corners.length;
         }
         if (next < 0) {
           next += this.corners.length;
         }


         for (let j = 0; j < this.stage.walls3D.length; j += 1) {
           if ((this.stage.walls3D[j].from === this.corners[current] &&
             this.stage.walls3D[j].to === this.corners[next]) ||
             (this.stage.walls3D[j].to === this.corners[current] &&
               this.stage.walls3D[j].from === this.corners[next])) {
             let center = new Vector3();
             let wall2D = this.stage.walls3D[j]
               .getNearestWall2D(this.floor2D.getBoundingBox()
                 .getCenter(center));
             if (k === oneLineCorners.ranges[i].min) {
               startWall2D = wall2D;
               startWall3D = this.stage.walls3D[j];
             }

             width += wall2D.width;
             height = Math.max(height, wall2D.height);

             break;
           }
         }
       }

       let newWall2D = new Wall2D(
         '',
         width,
         height,
         new Shape()
           .moveTo(startWall2D.mesh.geometry.parameters.shapes.curves[0].v1.x, startWall2D.mesh.geometry.parameters.shapes.curves[0].v1.y)
           .lineTo(startWall2D.mesh.geometry.parameters.shapes.curves[0].v1.x + width, startWall2D.mesh.geometry.parameters.shapes.curves[0].v1.y)
           .lineTo(startWall2D.mesh.geometry.parameters.shapes.curves[0].v1.x + width, startWall2D.mesh.geometry.parameters.shapes.curves[0].v1.y + height)
           .lineTo(startWall2D.mesh.geometry.parameters.shapes.curves[0].v1.x, startWall2D.mesh.geometry.parameters.shapes.curves[0].v1.y + height)
           .lineTo(startWall2D.mesh.geometry.parameters.shapes.curves[0].v1.x, startWall2D.mesh.geometry.parameters.shapes.curves[0].v1.y),
         'lightBrick');
       new Matrix4().multiplyMatrices(startWall3D.matrixWorld, startWall2D.matrix).decompose(newWall2D.position, newWall2D.quaternion, newWall2D.scale);
       newWall2D.room = this;
       this.add(newWall2D);
       this.walls2D.push(newWall2D);
     }
  } */

  mergeWalls2D() {
    for ( let i = 0; i < this.walls2D.length; i += 1 ) {
      let currentWall2D = this.walls2D[ i ];
      let currentWall2DWorldQuaternion = new Quaternion();
      currentWall2D.getWorldQuaternion( currentWall2DWorldQuaternion );
      let nextWall2D = i >= this.walls2D.length - 1 ? this.walls2D[ 0 ] : this.walls2D[ i + 1 ];
      let nextNumber = i >= this.walls2D.length - 1 ? 0 : i + 1;
      let nextWall2DWorldQuaternion = new Quaternion();
      nextWall2D.getWorldQuaternion( nextWall2DWorldQuaternion );
      // console.log(this, currentWall2DWorldQuaternion, nextWall2DWorldQuaternion);

      if ( Math.abs( currentWall2DWorldQuaternion.x - nextWall2DWorldQuaternion.x ) < 1e-5 &&
        Math.abs( currentWall2DWorldQuaternion.y - nextWall2DWorldQuaternion.y ) < 1e-5 &&
        Math.abs( currentWall2DWorldQuaternion.z - nextWall2DWorldQuaternion.z ) < 1e-5 &&
        Math.abs( currentWall2DWorldQuaternion.w - nextWall2DWorldQuaternion.w ) < 1e-5 ) {
        const width = currentWall2D.width;
        const height = currentWall2D.height;
        currentWall2D.parent.remove( currentWall2D );
        this.walls2D.splice( i, 1 );
        i -= 1;
        const shape = nextWall2D.mesh.geometry.parameters.shapes;
        nextWall2D.rebuildGeometry(
          width + nextWall2D.width,
          Math.max( height, nextWall2D.height ),
          new Shape()
            .moveTo( shape.curves[ 0 ].v1.x - width, shape.curves[ 0 ].v1.y )
            .lineTo( shape.curves[ 1 ].v1.x, shape.curves[ 1 ].v1.y )
            .lineTo( shape.curves[ 2 ].v1.x, shape.curves[ 2 ].v1.y )
            .lineTo( shape.curves[ 3 ].v1.x - width, shape.curves[ 3 ].v1.y )
            .lineTo( shape.curves[ 0 ].v1.x - width, shape.curves[ 0 ].v1.y ) );

      }
    }
  }

  rebuildFloor2DShape() {
    const shape = new Shape()
      .moveTo( this.corners[ 0 ].x, -this.corners[ 0 ].y );
    for ( let i = 1; i < this.corners.length; i += 1 ) {
      shape.lineTo( this.corners[ i ].x, -this.corners[ i ].y );
    }

    shape.lineTo( this.corners[ 0 ].x, -this.corners[ 0 ].y );

    return shape;
  }

  updateHeight() {
    let result = 0;
    for ( let i = 0; i < this.walls2D.length; i += 1 ) {
      if ( this.walls2D[ i ].height > result ) {
        result = this.walls2D[ i ].height;
      }
    }

    this.height = result;
  }

  rebuildGeometry() {
    this.floor2DShape = this.rebuildFloor2DShape();
    this.floor2D.rebuildGeometry( this.floor2DShape );
    this.ceiling2D.rebuildGeometry( this.floor2DShape );
    this.findWalls2D();
    // this.mergeWalls2D();
    this.findOneLineCorners();
    this.updateHeight();
    this.ceiling2D.position.set( 0, this.height, 0 );

  }

  getBoundingBox() {
    return new Box3().setFromObject( this );
  }
}
