import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { label } from './label';
import { value } from './value';

const toeKickHeightComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['adminSettings']['projectSettings']['dimensions']['toeKickHeight'],
    AllActions
  >;

export const toeKickHeight = toeKickHeightComb( {
  label,
  value
} );
