import { simpleSet } from '@r/reducers/utils';
import { inches } from '@npmrost/utils';

const setCalculationModeToeKickHeightValue = simpleSet as
  redux.reducers.calculationMode.settings.dimensions.toeKickHeight.value;

function validateInches( maybeInches: number ): maybeInches is inches {
  if ( maybeInches >= 0 ) {
    return true;
  }

  return false;
}

let defaultValue = 0;

export const value = setCalculationModeToeKickHeightValue(
  'SET_CALCULATION_MODE_TOE_KICK_HEIGHT_VALUE',
  validateInches( defaultValue ) ? defaultValue : 0 as inches
);
