import React, { PureComponent } from 'react';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import UnitsInputWithUnits from 'c/CalculationLayer/utils/UnitsInputWithUnits';
import modalStyl from 'c/CalculationLayer/Modal/index.styl';
import { connect } from 'react-redux';
import { roundForUi, unitMapping as uM } from 'scr/metricsConversion';
import Storage from 'scr/utilitiesStorage';
import { UnitsInputv2 } from 'c/CalculationLayer/utils/UnitsInputv2';
import { UnitsInputv2Value, className as clName } from 'decl/general/html';
import { debounce } from 'throttle-debounce';
import {
  regExForNumber
} from 'c/CabinetRightSideMenu/RightMenuForFloorPlan/components/utils';
import CheckChangedValue from 'c/shared/borderForChangedValue';
import {
  GenericCabinet
} from 'cSrc/structured/singletonCabinet/core/GenericCabinet';
import style from './index.styl';
import DoSetEntitySize from 'c/ThreeJsWrap/Viewer/UndoRedo/DoSetEntitySize';
import { isHoleableWithWall } from 'c/ThreeJsWrap/Viewer/core/helpers/object3d';
import DoRefreshWallHoles from 'c/ThreeJsWrap/Viewer/UndoRedo/DoRefreshWallHoles';

interface _IProps {
  units: redux._IStore['projectFor3d']['units'];
  className: string;
  mode: redux._IStore['flags']['rightSideCabinetMenuMode'];
}

interface _IState {
  width: UnitsInputv2Value;
  widthOld: UnitsInputv2Value;
  depth: UnitsInputv2Value;
  depthOld: UnitsInputv2Value;
  height: UnitsInputv2Value;
  heightOld: UnitsInputv2Value;
}

export const Dimensions = connect(
  ( s: redux._IStore ) => ( {
    units: s.projectFor3d.units,
    mode: s.flags.rightSideCabinetMenuMode
  } )
)(
  class Dimensions extends PureComponent<_IProps, _IState> {

    static defaultProps: Pick<_IProps, 'className'> = {
      className: ''
    }


    constructor( p: _IProps ) {
      super( p );

      const {
        width, depth, height
      } = this.getSelectedObject().vestaObject.getSizes();

      this.state = {
        width: String( width ) as UnitsInputv2Value,
        widthOld: String( width ) as UnitsInputv2Value,
        depth: String( depth ) as UnitsInputv2Value,
        depthOld: String( depth ) as UnitsInputv2Value,
        height: String( height ) as UnitsInputv2Value,
        heightOld: String( height ) as UnitsInputv2Value
      };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillUpdate(): void {
      if(
        !( Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet ) &&
        this.state.width
      ) {
        const {
          widthOld,
          depthOld,
          heightOld
        } = this.state;
        const newState = {
          width: widthOld,
          depth: depthOld,
          height: heightOld
        };

        // eslint-disable-next-line react/no-will-update-set-state
        this.setState( newState );
      }
    }

    getSelectedObject() {
      const [selected] = Storage.get( 'selectedObject' );
      if( selected === null ) {
        throw new Error( 'Selected object is null.' );
      }

      return selected;
    }

    _rawChangeMetric = (
      metric: 'width' | 'depth' | 'height',
      inInches: inches
    ) => {
      const viewer = Storage.get('viewer');
      const entity = this.getSelectedObject();

      const before = {...entity.vestaObject.getSizes()};
      switch( metric ) {
        case 'width':
          entity.vestaObject.changeWidth( inInches );
          break;
        case 'depth':
          entity.vestaObject.changeDepth( inInches );
          break;
        default:
          entity.vestaObject.changeHeight( inInches );
      }
      viewer.renderOnDemand.set();
      const after = {...entity.vestaObject.getSizes()};

      if (before.width != after.width || before.height != after.height || before.depth != after.depth) {
        const doMgr = viewer.doMgr;
        doMgr.beginDo();
        doMgr.registerDo(new DoSetEntitySize(entity, before, after));
        if (isHoleableWithWall(entity)) {
          const drwh = new DoRefreshWallHoles(entity); // refresh holes on walls.
          drwh.redo();
          doMgr.registerDo(drwh);
        }
        doMgr.endDo();
      }
    }

    // eslint-disable-next-line react/sort-comp
    rawChangeMetric = debounce( 600, this._rawChangeMetric )

    changeMetric = ( metric: 'width' | 'depth' | 'height' ) => () => {
      const { units } = this.props;
      const value = this.state[ metric ];
      this.setState( ( prev ) => ( { ...prev, [ `${ metric }Old` ]: value } ) );

      const inInches = units === 'ftAndInch'
        ? uM.ftAndInch( value )
        : uM[ units ]( Number( value ) );

      this.rawChangeMetric( metric, inInches );
    };

    handleChangeInput = ( key: string ) => ( value: string ) => {
      const state = { ...this.state };
      if( value.match( regExForNumber ) ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        state[ key ] = value;
      }
      if( !value ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        state[ key ] = '0';
      }

      this.setState( state );
    };

    render() {
      const {
        props: { units, className },
        state: {
          width,
          widthOld,
          depth,
          depthOld,
          height,
          heightOld
        },
        changeMetric
      } = this;

      return (
        <div className={ `${ style.dimensions } ${ className }` }>
          <CheckChangedValue changed={ width !== widthOld }>
            <LabeledField
              className={ `${ modalStyl.commonLabeledField } shortLabel wider` }
              name='Width'
            >
              <UnitsInputWithUnits units={ units }>
                <UnitsInputv2
                  units={ units }
                  className={
                    `${ modalStyl.commonInput } wide` as clName<'input'>
                  }
                  onChange={ this.handleChangeInput( 'width' ) }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                  // @ts-ignore
                  onBlur={ changeMetric( 'width' ) }
                  value={ roundForUi( width, units ) as UnitsInputv2Value }
                />
              </UnitsInputWithUnits>
            </LabeledField>
          </CheckChangedValue>

          <CheckChangedValue changed={ depth !== depthOld }>
            <LabeledField
              className={ `${ modalStyl.commonLabeledField } shortLabel wider` }
              name='Depth'
            >
              <UnitsInputWithUnits units={ units }>
                <UnitsInputv2
                  units={ units }
                  className={
                    `${ modalStyl.commonInput } wide` as clName<'input'>
                  }
                  onChange={ this.handleChangeInput( 'depth' ) }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                  // @ts-ignore
                  onBlur={ changeMetric( 'depth' ) }
                  value={ roundForUi( depth, units ) as UnitsInputv2Value }
                />
              </UnitsInputWithUnits>
            </LabeledField>
          </CheckChangedValue>

          <CheckChangedValue changed={ height !== heightOld }>
            <LabeledField
              className={ `${ modalStyl.commonLabeledField } shortLabel wider` }
              name='Height'
            >
              <UnitsInputWithUnits units={ units }>
                <UnitsInputv2
                  units={ units }
                  className={
                    `${ modalStyl.commonInput } wide` as clName<'input'>
                  }
                  onChange={ this.handleChangeInput( 'height' ) }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                  // @ts-ignore
                  onBlur={ changeMetric( 'height' ) }
                  value={ roundForUi( height, units ) as UnitsInputv2Value }
                />
              </UnitsInputWithUnits>
            </LabeledField>
          </CheckChangedValue>
        </div>
      );
    }
  }
);
