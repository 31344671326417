import { radians } from 'decl/cabinetdesigner/core.d.ts';
import { inches } from '@npmrost/utils/lib';

export const SingleDoorUpperCabinet:
  Partial<CabD.A.ContainerConfigs.ContainerConfig> = {
    layout: 'DEPTH',
    height: 36 as inches,
    depth: 13 as inches,
    width: 18 as inches,
    name: 'Single Door Upper',
    isTopLevel: true,
    mountTypes: [
      'wall'
    ],
    constrName: 'WallCabinet',
    children: [
      {
        name: 'UpperCarcassAssembly',
        layout: 'WIDTH',
        children: [
          {
            name: 'UpperLeftSide',
            type: 'Panel',
            width: 0.5 as inches,
            grainDirection: ( 50 * Math.PI ) / 180 as radians
          },
          {
            name: 'UpperCarcassAssembly--InteriorAssembly',
            children: [
              {
                name: 'UpperBottom',
                height: 0.5 as inches,
                type: 'Panel'
              },
              {
                name: 'UpperInteriorAssembly',
                layout: 'DEPTH',
                children: [
                  {
                    name: 'UpperBack',
                    type: 'Panel',
                    depth: 0.5 as inches
                  },
                  {
                    name: 'UpperInterior',
                    children: [
                      {
                        name: 'Interior',
                        children: [
                          {},
                          {
                            height: 0.5 as inches,
                            type: 'Panel',
                            name: 'Shelf'
                          },
                          {},
                          {
                            height: 0.5 as inches,
                            type: 'Panel',
                            name: 'Shelf'
                          },
                          {}
                        ]
                      },
                      {
                        name: 'UpperTop',
                        type: 'Panel',
                        height: 0.5 as inches
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            name: 'UpperRightSide',
            width: 0.5 as inches,
            type: 'Panel'
          }
        ]
      },
      {
        name: 'FrontExteriorAssembly',
        layout: 'WIDTH',
        depth: 0.5 as inches,
        children: [
          {
            name: 'LeftDoorOverlay',
            width: 0.25 as inches
          },
          {
            name: 'FrontAssembly',
            children: [
              {
                name: 'BottomReveal',
                height: 0.25 as inches
              },
              {
                handleOrientation: 'VERTICAL',
                handlePosition: {
                  oX: {
                    type: 'toRight',
                    value: 3 as inches
                  },
                  oY: {
                    type: 'toBottom',
                    value: 3 as inches
                  },
                  oZ: 'onSurface'
                },
                type: 'Door',
                name: 'Door--DoorPanel'
              },
              {
                name: 'TopReveal',
                height: 0.25 as inches
              }
            ]
          },
          {
            name: 'RightDoorOverlay',
            width: 0.25 as inches
          }
        ]
      }
    ],
    countertopMaterialName: 'marblewhite005'
  };

