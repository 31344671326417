import React, { PureComponent } from 'react';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import modalStyl from 'c/CalculationLayer/Modal/index.styl';
import TripletHolder from 'c/CalculationLayer/utils/TripletHolder';
import { UnitsInputv2 } from 'c/CalculationLayer/utils/UnitsInputv2';
import { connect } from 'react-redux';
import { IntInput, Props as IntProps } from 'c/CalculationLayer/utils/IntInput';
import { batchActions } from 'redux-batched-actions';
import { unitMapping } from 'scr/metricsConversion';

type upper = redux.store['projectFor3d']['cabinetsSettings']['upper'];
type a = redux.actions;

type labelActionTypes = a['setCabinetsSettingsUpperDepthLabel']['type'] |
  a['setCabinetsSettingsUpperHeightLabel']['type'];

type valueActionTypes = a['setCabinetsSettingsUpperDepthValue']['type'] |
a['setCabinetsSettingsUpperHeightValue']['type']

type batchAction = redux.batchActionsWithTempl<
  a['setCabinetsSettingsUpperDepthLabel'] |
  a['setCabinetsSettingsUpperDepthValue'] |
  a['setCabinetsSettingsUpperHeightLabel'] |
  a['setCabinetsSettingsUpperHeightValue'] |
  a['setCabinetsSettingsUpperShelvesQty']
>;

interface Props {
  units: redux.store['projectFor3d']['units'];
  height: upper['height'];
  depth: upper['depth'];
  shelvesQty: upper['shelvesQty'];
  dispatch: redux.d<ReturnType<batchAction>>;
}

export const Upper = connect(
  ( {
    projectFor3d: {
      units,
      cabinetsSettings: { upper }
    }
  }: redux.store ): Omit<Props, 'dispatch'> => ( {
    units,
    height: upper.height,
    depth: upper.depth,
    shelvesQty: upper.shelvesQty
  } )
)(
  class Upper extends PureComponent<Props> {
    changeMetric = (
      labelAction: labelActionTypes,
      valueAction: valueActionTypes
    ) => ( value: UnitsInputv2Value ) => {
      const { units, dispatch } = this.props;

      const val = units === 'ftAndInch'
        ? unitMapping.ftAndInch( value )
        : unitMapping[ units ]( Number( value ) );

      dispatch(
        ( batchActions as batchAction )( [
          { type: labelAction, value },
          { type: valueAction, value: val }
        ] )
      );
    }

    changeQty = ( n: number ) => {
      this.props.dispatch(
        ( batchActions as batchAction )( [
          {
            type: 'SET_CABINETS_SETTINGS_UPPER_SHELVES_QTY',
            value: n as quantity
          }
        ] )
      );
    }

    render() {
      const {
        props: {
          units,
          height: { label: hLabel },
          shelvesQty,
          depth: { label: dLabel }
        },
        changeMetric,
        changeQty
      } = this;

      return (
        <LabeledField
          className={
            `${ modalStyl.commonLabeledField } wider` +
          ' shortLabel label100px fontSize14px'
          }
          name='Upper cabinet'
        >
          <TripletHolder>
            <UnitsInputv2
              units={ units }
              value={ hLabel }
              onChange={ changeMetric(
                'SET_CABINETS_SETTINGS_UPPER_HEIGHT_LABEL',
                'SET_CABINETS_SETTINGS_UPPER_HEIGHT_VALUE'
              ) }
              className={ modalStyl.commonInput }
            />
            <UnitsInputv2
              units={ units }
              value={ dLabel }
              onChange={ changeMetric(
                'SET_CABINETS_SETTINGS_UPPER_DEPTH_LABEL',
                'SET_CABINETS_SETTINGS_UPPER_DEPTH_VALUE'
              ) }
              className={ modalStyl.commonInput }
            />
            <IntInput
              value={
                shelvesQty as
                  baseOf<Props['shelvesQty']> as
                    IntProps['value']
              }
              onChange={ changeQty }
              className={ modalStyl.commonInput }
            />
          </TripletHolder>
        </LabeledField>
      );
    }
  }
);
