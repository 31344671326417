import { ThunkAction } from 'redux-thunk';
import { AllActions, batchActionsWithTempl } from '@r/storeUtils';
import {
  GenericCabinet
} from 'cSrc/structured/singletonCabinet/core/GenericCabinet';
import Storage from 'scr/utilitiesStorage';
import * as allCabinets from 'cSrc/structured/singletonCabinet/cabinets/index';
import { NominalHardStr } from 'decl/general/core';
import {
  addNewCabinet
} from '@r/reducers/storeParts/calculationMode/projects/currentProject/cabinets';
import { _IItem } from '@npmrost/storybook/stories/Vesta/TreeView';
import { batchActions } from 'redux-batched-actions';
import { createNewProject } from '@r/reducers/storeParts/calculationMode/projects/currentProject';
import { ArrayCounter } from '@npmrost/utils/lib';
import { switchToCalculation } from '../ViewMenu';

const mapping = {
  OneDoorBase: '1 Door Base',
  OneDoorBlindLeftCornerBase: '1 Door Blind Left Corner Base',
  OneDoorBlindRightCornerBase: '1 Door Blind Right Corner Base',
  OneDrawerOneDoorBase: '1 Door 1 Drawer Base',
  OneDrawerOneDoorBlindCornerLeft: '1 Door 1 Drawer Blind Left Corner Base',
  OneDrawerOneDoorBlindCornerRight: '1 Door 1 Drawer Blind Right Corner Base',
  OneDrawer2DoorsBase: '2 Door 1 Drawer Base',
  TwoDoorBase: '2 Door Base',
  TwoDoorsOneFalseFrontSink: '2 Door ADA Sink Base w False Front',
  TwoDrawersTwoDoorsBase: '2 Door 2 Drawer Base',
  OpenBaseWithShelves: 'Open Bas',
  OpenUpperCabinet: 'Open Upper',
  SingleDoorUpperCabinet: '1 Door Upper',
  UpperFlipUprCabinet: 'Single Door Upper Lift Up',
  TwoSingleDoorTall: '2 Door Tall',
  OneDoorBlindLeftUpper: '1 Door Blind Left Corner Upper',
  TwoDoorBlindLeftUpper: '2 Door Blind Left Corner Upper',
  TwoDoorBlindRightUpper: '2 Door Blind Right Corner Upper',
  TwoDoorUpper: '2 Door Upper',
  MicrowaveTwoDoorUpper: '2 Door Microwave Upper',
  TwooDoorUpperFlipUpr: '2 Door Upper',
  // ShakerDoorBase: '',
  ThreeDrawerBase: '3 Drawer Base'
};

const b = batchActions as batchActionsWithTempl<
  redux._IActions['setCornerMenuVisible'] |
  redux._IActions['setGeneralViewMode'] |
  redux._IActions['setRightSideCabinetMenuOpen'] |
  redux._IActions['setCalculationModeCurrentProjectCabinets']
>;


function getUiNamesToIItems( item: _IItem ): { [uiName: string]: _IItem } {
  if( item.nodeType === 'leaf' ) {
    return { [ item.label ]: item };
  }

  return item.children.reduce<ReturnType<typeof getUiNamesToIItems>>(
    ( acc, child ) => ( {
      ...acc,
      ...getUiNamesToIItems( child )
    } ),
    {}
  );
}

type cabinetConstructorName = NominalHardStr<'cabinetConstructorName'>;
interface _IAllCabinets {
  [constrName: string]: { name: string };
}

interface _IUiNamesToConstrNames {
  [uiName: string]: cabinetConstructorName;
}

const allCabinetsUiNamesToConstrNames = Object.keys( allCabinets )
  .reduce<_IUiNamesToConstrNames>( ( acc, constrName ) => ( {
    ...acc,
    [ ( allCabinets as _IAllCabinets )[ constrName ].name ]: constrName as cabinetConstructorName
  } ), {} );

function getDimensions( cab: GenericCabinet ): {} | { width: inches; height: inches; depth: inches } {
  const { width, depth, height } = cab.getConfig();
  if( !width || !height || !depth ) return {};

  return { width, depth, height };
}

export const cabinetsFrom3dToCalcualation: ThunkAction<void, redux._IStore, {}, AllActions> =
function cabinetsFrom3dToCalcualation( dispatch, getState ) {
  const {
    calculationMode: {
      projects: {
        currentProject: {
          projectId
        }
      }
    }
  } = getState();

  const cabinets = Storage.get( 'viewer' )
    .scene.getAllCabinets() as GenericCabinet[];

  const uiNamesToIItems = getUiNamesToIItems( {
    nodeType: 'nonLeaf',
    children: getState().calculationMode.cabinets,
    chosen: false,
    label: '',
    open: false,
    value: ''
  } as _IItem );

  const filteredCabinets = cabinets
    .filter( ( cab ) => {
      const name = allCabinetsUiNamesToConstrNames[ cab.getConfig().name! ];

      return Boolean( mapping[ name as keyof typeof mapping ] );
    } );

  dispatch( {
    type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_CABINETS',
    value: {
      counter: 0 as ArrayCounter,
      data: [],
      keys: []
    }
  } );

  if( projectId === '' ) {
    dispatch( createNewProject() )
      .then( () => {
        filteredCabinets
          .map( ( cab ) => allCabinetsUiNamesToConstrNames[ cab.getConfig().name! ] )
          .map( ( name ) => mapping[ name as keyof typeof mapping ] )
          .map( ( name ) => uiNamesToIItems[ name ] )
          .forEach( ( item, i ) => dispatch( addNewCabinet( { ...item, ...getDimensions( filteredCabinets[ i ] ) } ) ) );
      } );
  } else {
    filteredCabinets
      .map( ( cab ) => allCabinetsUiNamesToConstrNames[ cab.getConfig().name! ] )
      .map( ( name ) => mapping[ name as keyof typeof mapping ] )
      .map( ( name ) => uiNamesToIItems[ name ] )
      .forEach( ( item, i ) => dispatch( addNewCabinet( { ...item, ...getDimensions( filteredCabinets[ i ] ) } ) ) );
  }


  switchToCalculation( dispatch, b );
  dispatch( { type: 'TOGGLE_CALCULATION_MODE_MAIN_MODAL', value: false } );

};


