import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SimpleSelect from 'c/CalculationLayer/utils/SimpleSelect';
import { batchActions, BatchAction } from 'redux-batched-actions';
import Storage from 'scr/utilitiesStorage';
import { batchActionsWithTempl, d } from '@r/storeUtils';
import style from './index.styl';

const b = batchActions as batchActionsWithTempl<
  redux._IActions['setOrthoMode'] |
  redux._IActions['setAddWallMode'] |
  redux._IActions['setMoveElementMode'] |
  redux._IActions['setSnapToGrid']
  >;


interface _IProps {
  generalViewMode: redux._IStore['flags']['generalViewMode'];
  addWallMode: redux._IStore['floorPlanTools']['addWallMode'];
  moveElementMode: redux._IStore['floorPlanTools']['moveElementMode'];
  orthoMode: redux._IStore['floorPlanTools']['orthoMode'];
  snapToGrid: redux._IStore['floorPlanTools']['snapToGrid'];
  dispatch: d<BatchAction>;
}

class FloorPlanTools extends PureComponent<_IProps> {
  onChangeForSelect = ( value: string ) => {
    const { dispatch } = this.props;
    const viewer = Storage.get( 'viewer' );
    const snapToGridValue = { big: false, small: false };

    if ( value === 'big' ) {
      snapToGridValue.big = true;
    } else if( value === 'small' ) {
      snapToGridValue.small = true;
    }

    viewer.setFloorplanParameterValue( 'snapToGrid', snapToGridValue );

    dispatch( b( [{
      type: 'SET_SNAP_TO_GRID', value
    }] ) );
  };

  toggleOrthoMode = () => {
    const { dispatch, orthoMode } = this.props;
    const viewer = Storage.get( 'viewer' );

    viewer.setFloorplanParameterValue( 'orthoMode', !orthoMode );

    dispatch( b( [{
      type: 'SET_ORTHO_MODE', value: !orthoMode
    }] ) );
  };

  toggleMoveElementMode = () => {
    const { dispatch, moveElementMode, addWallMode } = this.props;
    const viewer = Storage.get( 'viewer' );

    viewer.setFloorplanInstrumentMode( 'moveObject', !moveElementMode );
    if ( addWallMode ) {
      viewer.setFloorplanInstrumentMode( 'addWall', false );
    }

    dispatch( b( [{
      type: 'SET_MOVE_ELEMENT_MODE', value: !moveElementMode
    },
    {
      type: 'SET_ADD_WALL_MODE', value: false
    }] ) );
  };

  toggleAddWallMode = () => {
    const { dispatch, addWallMode, moveElementMode } = this.props;
    const viewer = Storage.get( 'viewer' );

    viewer.setFloorplanInstrumentMode( 'addWall', !addWallMode );
    if ( moveElementMode ) {
      viewer.setFloorplanInstrumentMode( 'moveObject', false );
    }

    dispatch( b( [{
      type: 'SET_ADD_WALL_MODE', value: !addWallMode
    },
    {
      type: 'SET_MOVE_ELEMENT_MODE', value: false
    }] ) );
  };

  render() {
    const {
      props: {
        generalViewMode,
        addWallMode,
        moveElementMode,
        orthoMode,
        snapToGrid
      },
      toggleAddWallMode,
      toggleMoveElementMode,
      toggleOrthoMode,
      onChangeForSelect
    } = this;

    if( generalViewMode !== 'floorPlan' ) {
      return null;
    }

    const optionsForSelect = [
      {
        value: 'big',
        label: 'Big square'
      },
      {
        value: 'small',
        label: 'Small square'
      },
      {
        value: 'none',
        label: 'None'
      }
    ];

    return (
      <div className={ style.root }>
        <div
          className={ `button ${ orthoMode ? 'selected' : '' }` }
          onClick={ toggleOrthoMode }
        >
          Ortho mode
        </div>
        <div
          className={ `button ${ moveElementMode ? 'selected' : '' }` }
          onClick={ toggleMoveElementMode }
        >
          Move Element
        </div>
        <div
          className={ `button ${ addWallMode ? 'selected' : '' }` }
          onClick={ toggleAddWallMode }
        >
          Add wall
        </div>
        <SimpleSelect
          className='dropdown'
          options={ optionsForSelect }
          value={ snapToGrid }
          onChange={ onChangeForSelect }
        />
      </div>
    );
  }
}

export default connect(
  ( { flags, floorPlanTools }: redux._IStore ) => {
    return {
      generalViewMode: flags.generalViewMode,
      addWallMode: floorPlanTools.addWallMode,
      moveElementMode: floorPlanTools.moveElementMode,
      orthoMode: floorPlanTools.orthoMode,
      snapToGrid: floorPlanTools.snapToGrid
    };
  }
)( FloorPlanTools );
