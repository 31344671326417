import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { d } from '@r/storeUtils';
import { Theme } from '@@/Theme';
import CornerMenu from './CornerMenu';
import Modal from './Modal';
import RightMenu from './RightMenu';
import Preview from './Preview';


const Container = styled.div`
  display: ${ ( p: { hidden: boolean } ) => ( p.hidden ? 'none' : 'block' ) };
  z-index: 49;
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  ${ ( { theme: t }: { theme: typeof Theme } ): string => `
    background: ${ t.calculationBackground };
  ` }
`;

const Content = styled.div`
  width: 70%;
  height: 100%;
`;

interface _IProps {
  generalViewMode: redux._IStore['flags']['generalViewMode'];
  modals: redux._IStore['calculationMode']['modals'];
  dispatch: d<redux._IActions['setCalculationModeProjectName']>;
}

class CalculationLayerModal extends PureComponent<_IProps> {

  render() {
    const {
      props: {
        generalViewMode,
        modals: {
          main: mainModalIsOpen,
          editProject: editProjectModalIsOpen,
          account: accountModalIsOpen
        }
      }
    } = this;

    return (
      <>
        <Container hidden={ generalViewMode !== 'calculation' }>
          {
            mainModalIsOpen || editProjectModalIsOpen || accountModalIsOpen
              ? <Modal />
              : (
                <Wrapper>
                  <Content>
                    <CornerMenu />
                    {/* <div className='calculationWrap' /> */}
                    <Preview />
                  </Content>
                  <RightMenu />
                </Wrapper>
              )
          }
        </Container>
      </>
    );
  }
}

export default connect(
  ( { flags, calculationMode }: redux._IStore ) => {
    return {
      generalViewMode: flags.generalViewMode,
      modals: calculationMode.modals
    };
  }
)( CalculationLayerModal );
