import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Dimensions from 'c/CalculationLayer/Modal/Main/NewProject/Dimensions';
import Materials from 'c/CalculationLayer/Modal/Main/NewProject/Materials';
import PercentageLoss from './PercentageLoss';
import ShippingCost from './ShippingCost';
import ExcelControls from './ExcelControls';

const Wrapper = styled.div`
  margin: 20px 10px 0;
  height: calc(100vh - 20px);
  padding: 30px;
  ${ ( { theme: t } ) => `
    background-color: ${ t.generalBackground };
    border-radius: ${ t.borderRadius };
  ` }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
`;

const ConnectedDimensions = connect(
  ( s ) => {
    return {
      settings: s.calculationMode.adminSettings.projectSettings.dimensions,
      units: s.calculationMode.settings.information.units,
      adminMode: true
    };
  }
)( Dimensions );

const StyledDimensions = styled( ConnectedDimensions )`
  max-width: 450px;
  padding: 20px;
  ${ ( { theme: t } ) => `
    background-color: ${ t.arraySetsBackground };
    border-radius: ${ t.borderRadius };
  ` }
`;

const ConnectedMaterials = connect(
  ( s ) => {
    return {
      settings: s.calculationMode.adminSettings.projectSettings.materials,
      adminMode: true
    };
  }
)( Materials );

const StyledMaterials = styled( ConnectedMaterials )`
  max-width: 600px;
  padding: 20px;
  margin-left: 30px;
  ${ ( { theme: t } ) => `
    background-color: ${ t.arraySetsBackground };
    border-radius: ${ t.borderRadius };
  ` }
`;

const StyledPerfectScrollbar = styled( PerfectScrollbar )`
  max-height: 500px;
`;

export default class Content extends PureComponent {
  render() {
    return (
      <Wrapper>
        <Container>
          <StyledPerfectScrollbar>
            <StyledDimensions />
          </StyledPerfectScrollbar>
          <StyledPerfectScrollbar>
            <StyledMaterials />
          </StyledPerfectScrollbar>
          <div>
            <PercentageLoss />
            <ShippingCost />
          </div>
        </Container>
        <ExcelControls />
      </Wrapper>
    );
  }
}
