/* eslint-disable max-len */
import { AllActions, _ISimpleAction } from '@r/storeUtils';
import {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  UnitsInputValue
} from '@npmrost/storybook/stories/Vesta/Inputs/UnitsInput';

type action<
  Const extends keyof redux._IConstants,
  isInches extends boolean = false
> = _ISimpleAction<
  redux._IConstants[Const],
  isInches extends true ? inches : UnitsInputValue
>;

declare global {

  namespace redux {

    namespace store {

      namespace selectedCabinet {

        namespace to4Walls {
          interface _IValue {
            value: inches;
            label: UnitsInputValue;
          }
        }

        interface _ITo4Walls {
          oXPlus: to4Walls._IValue;
          oXMinus: to4Walls._IValue;
          oZPlus: to4Walls._IValue;
          oZMinus: to4Walls._IValue;
        }

      }


      interface _ISelectedCabinet {
        to4Walls: selectedCabinet._ITo4Walls;
      }
    }

    interface _IConstants {
      SET_SELECTED_CABINET_PLUS_OX_VALUE: 'SET_SELECTED_CABINET_PLUS_OX_VALUE';
      SET_SELECTED_CABINET_PLUS_OX_LABEL: 'SET_SELECTED_CABINET_PLUS_OX_LABEL';

      SET_SELECTED_CABINET_MINUS_OX_VALUE: 'SET_SELECTED_CABINET_MINUS_OX_VALUE';
      SET_SELECTED_CABINET_MINUS_OX_LABEL: 'SET_SELECTED_CABINET_MINUS_OX_LABEL';

      SET_SELECTED_CABINET_PLUS_OZ_VALUE: 'SET_SELECTED_CABINET_PLUS_OZ_VALUE';
      SET_SELECTED_CABINET_PLUS_OZ_LABEL: 'SET_SELECTED_CABINET_PLUS_OZ_LABEL';

      SET_SELECTED_CABINET_MINUS_OZ_VALUE: 'SET_SELECTED_CABINET_MINUS_OZ_VALUE';
      SET_SELECTED_CABINET_MINUS_OZ_LABEL: 'SET_SELECTED_CABINET_MINUS_OZ_LABEL';
    }

    interface _IActions {
      SET_SELECTED_CABINET_PLUS_OX_VALUE: action<'SET_SELECTED_CABINET_PLUS_OX_VALUE', true>;
      SET_SELECTED_CABINET_PLUS_OX_LABEL: action<'SET_SELECTED_CABINET_PLUS_OX_LABEL'>;

      SET_SELECTED_CABINET_MINUS_OX_VALUE: action<'SET_SELECTED_CABINET_MINUS_OX_VALUE', true>;
      SET_SELECTED_CABINET_MINUS_OX_LABEL: action<'SET_SELECTED_CABINET_MINUS_OX_LABEL'>;

      SET_SELECTED_CABINET_PLUS_OZ_VALUE: action<'SET_SELECTED_CABINET_PLUS_OZ_VALUE', true>;
      SET_SELECTED_CABINET_PLUS_OZ_LABEL: action<'SET_SELECTED_CABINET_PLUS_OZ_LABEL'>;

      SET_SELECTED_CABINET_MINUS_OZ_VALUE: action<'SET_SELECTED_CABINET_MINUS_OZ_VALUE', true>;
      SET_SELECTED_CABINET_MINUS_OZ_LABEL: action<'SET_SELECTED_CABINET_MINUS_OZ_LABEL'>;
    }
  }
}
type to4Walls = redux._IStore['selectedCabinet']['to4Walls']

const defaultEntry: to4Walls['oXMinus'] = {
  label: '0' as UnitsInputValue,
  value: 0 as inches
};

const defaultState: to4Walls = {
  oXMinus: { ...defaultEntry },
  oXPlus: { ...defaultEntry },
  oZMinus: { ...defaultEntry },
  oZPlus: { ...defaultEntry }
};

export function to4Walls(
  state = defaultState,
  action: AllActions
): typeof state {
  let prop: keyof to4Walls = '' as keyof to4Walls;
  let labelOrVal: 'label' | 'value' = 'label';

  switch( action.type ) {
    case 'SET_SELECTED_CABINET_PLUS_OX_LABEL':
      prop = 'oXPlus';
      break;
    case 'SET_SELECTED_CABINET_PLUS_OX_VALUE':
      prop = 'oXPlus';
      labelOrVal = 'value';
      break;
    case 'SET_SELECTED_CABINET_MINUS_OX_LABEL':
      prop = 'oXMinus';
      break;
    case 'SET_SELECTED_CABINET_MINUS_OX_VALUE':
      prop = 'oXMinus';
      labelOrVal = 'value';
      break;
    case 'SET_SELECTED_CABINET_PLUS_OZ_LABEL':
      prop = 'oZPlus';
      break;
    case 'SET_SELECTED_CABINET_PLUS_OZ_VALUE':
      prop = 'oZPlus';
      labelOrVal = 'value';
      break;
    case 'SET_SELECTED_CABINET_MINUS_OZ_LABEL':
      prop = 'oZMinus';
      break;
    case 'SET_SELECTED_CABINET_MINUS_OZ_VALUE':
      prop = 'oZMinus';
      labelOrVal = 'value';
      break;
    default:
      return state;
  }

  return {
    ...state,
    [ prop ]: {
      ...state[ prop ],
      [ labelOrVal ]: action.value as to4Walls['oZPlus'][typeof labelOrVal]
    }
  };
}
