const getTotalCost = ( arr ) => {
  return Number(
    arr
      .reduce( ( acc, value ) => acc + value.cost, 0 )
      .toFixed( 2 )
  );
};

const getTotalLabourCost = ( arr ) => {
  return Number(
    arr
      .reduce( ( acc, value ) => acc + value.totalLabourCost, 0 )
      .toFixed( 2 )
  );
};

export default ( {
  totalEdgebanding,
  totalCountertop,
  totalHinges,
  totalPulls,
  totalLegs,
  totalToeKickFillers,
  totalValance,
  totalDrawerSystems,
  totalLessThanOneSheet
} ) => {
  const edgebandingCost = getTotalCost( totalEdgebanding );
  const countertopCost = getTotalCost( totalCountertop );
  const hingesCost = getTotalCost( totalHinges );
  const hingesLabourCost = getTotalLabourCost( totalHinges );
  const pullsCost = getTotalCost( totalPulls );
  const pullsLabourCost = getTotalLabourCost( totalPulls );
  const legsCost = getTotalCost( totalLegs );
  const legsLabourCost = getTotalLabourCost( totalLegs );
  const toeKickFillersCost = getTotalCost( totalToeKickFillers );
  const valanceCost = getTotalCost( totalValance );
  const drawerSystemsCost = getTotalCost( totalDrawerSystems );
  const drawerSystemsLabourCost = getTotalLabourCost( totalDrawerSystems );

  const toeKickFillersCostInLessThanOneSheet = getTotalCost(
    totalToeKickFillers.filter(
      ( item ) => totalLessThanOneSheet.find( ( { name } ) => name === item.name )
    )
  );

  const valanceCostInLessThanOneSheet = getTotalCost(
    totalValance.filter(
      ( item ) => totalLessThanOneSheet.find( ( { name } ) => name === item.name )
    )
  );

  console.log( 'Edgebanding cost:', edgebandingCost );
  console.log( 'Countertop cost:', countertopCost );
  console.log( 'Hinges cost:', hingesCost );
  console.log( 'Pulls cost:', pullsCost );
  console.log( 'Legs cost:', legsCost );
  console.log( 'Toe kick and fillers cost:', toeKickFillersCost );
  console.log( 'Valance cost:', valanceCost );
  console.log( 'Drawer systems cost:', drawerSystemsCost );
  console.log( 'Toe kick and fillers material in less than one sheet cost:', toeKickFillersCostInLessThanOneSheet );
  console.log( 'Valance material in less than one sheet cost:', valanceCostInLessThanOneSheet );
  console.log( 'Final Toe kick and fillers cost:', toeKickFillersCost - toeKickFillersCostInLessThanOneSheet );
  console.log( 'Final Valance cost:', valanceCost - valanceCostInLessThanOneSheet );

  const totalCost = Number(
    ( edgebandingCost +
      countertopCost +
      hingesCost - hingesLabourCost +
      pullsCost - pullsLabourCost +
      legsCost - legsLabourCost +
      toeKickFillersCost - toeKickFillersCostInLessThanOneSheet +
      valanceCost - valanceCostInLessThanOneSheet +
      drawerSystemsCost - drawerSystemsLabourCost
    ).toFixed( 2 )
  );

  console.log( 'Total additional parts cost', totalCost );

  return {
    totalCost,
    edgebandingCost,
    countertopCost,
    hingesCost,
    pullsCost,
    legsCost,
    toeKickFillersCost,
    valanceCost,
    drawerSystemsCost
  };
};
