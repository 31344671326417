import superagent from 'superagent';
import rest from '@feathersjs/rest-client';
import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';

import host from '@@/apiHost.json';

export default feathers()
  .configure( rest( host.slice( 0, -1 ) ).superagent( superagent ) )
  .configure( auth( { storage: window.localStorage } ) );
