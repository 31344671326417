/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { Fragment as F } from 'react';
import { connect } from 'react-redux';
import Storage, { UtilitiesStorage } from 'scr/utilitiesStorage';
import { Dispatch } from 'redux';
import { _IObject3D } from 'decl/ThreeScene';
import floor from './FloorMenu';
import countertop from './Countertop';
import crownMolding from './CrownMoldings';
import wall from './WallMenu';
import item from './ItemMenu';
// import crownMoldings from './CrownMoldings';
import { tabs, tab } from './interfaces';

function isObject3dOrCabinetArr(
  arg: UtilitiesStorage['hash']['selectedObject']
): arg is Exclude<typeof arg, null[] | undefined> {
  if( arg && arg[ 0 ] === null ) return false;

  return true;
}


interface _IProps {
  tabs: tabs;
  d: Dispatch;
}


interface _IState {
  info: boolean;
  delete: boolean;
  copy: boolean;
  preset: boolean;
  json: boolean;
  ortho: boolean;
}


type ChangeTabStateArg = React.MouseEvent<HTMLDivElement> & {
  currentTarget: { dataset: {
    tab: tab['name'];
  }; };
}

class Tabs extends React.PureComponent<_IProps, _IState> {
  static defaultProps: Pick<Tabs['props'], 'tabs'> = {
    tabs: {
      wall,
      floor,
      countertop,
      crownMolding,
      item
    }
  }

  object3d: _IObject3D[] | CabinetDesigner.GenericCabinet[];

  constructor ( p: Tabs['props'] ) {
    super( p );
    const object3d = Storage.get( 'selectedObject' );

    if( !isObject3dOrCabinetArr( object3d ) ) {
      throw new Error( 'Selected object is null.' );
    }

    this.object3d = object3d;

    this.state = {
      info: false,
      // Lock: fixed,
      delete: false,
      copy: false,
      preset: false,
      json: false,
      ortho: false
    };


  }

  changeTabState = ( {
    currentTarget: { dataset: { tab } }
  }: ChangeTabStateArg
  ) => {
    const type = this.object3d[ 0 ].vestaObject.getType();
    const t = type.match( 'cabinet' ) ? 'cabinet'
      : type.match( 'item' ) ? 'item' : type;

    const { props: { tabs: { [ t ]: options } } } = this;
    if( options === undefined ) return;

    const clickedOption = options
      .filter( ( { name } ) => name === tab )[ 0 ];

    const tabState = clickedOption.afterFunc( Storage, this.props.d );
    this.setState( ( prevState ) => ( {
      ...prevState,
      [ tab ]: tabState === 'inverse' ? !prevState[ tab ] : tabState
    } ) );
  }

  render () {
    const type = this.object3d[ 0 ].vestaObject.getType();
    const t = type.match( 'cabinet' ) ? 'cabinet'
      : type.match( 'item' ) ? 'item' : type;

    const { props: { tabs: { [ t ]: tabs } } } = this;

    return (
      <>
        <div className='modalIconTabs'>
          {
            tabs && tabs.map( ( it ) => {
              return (
                <F key={ it.name }>
                  <div
                    key={ it.name }
                    onClick={ this.changeTabState }
                    data-tab={ it.name }
                    className={
                      `modalIconTab ${ this.state[ it.name ] ? 'chosen' : '' }
                    ${ it.isNotHovarable ? '' : ' hoverable' }
                    ${ it.className ? ( ` ${ it.className }` ) : '' }`
                    }
                  >
                    <img alt='' src={ `/img/info/${ it.name }.png` } />
                    <span>{ it.uiName }</span>
                  </div>
                </F>
              );
            } )
          }
        </div>
        {
          tabs && tabs.map( ( { name, comp: C } ) => {
            return (
              <F key={ name }>
                { C && <C hidden={ !this.state[ name ] } /> }
              </F>
            );
          }
          )
        }
      </>
    );
  }
}


export default connect(
  null,
  ( d ) => ( { d } )
)( Tabs );
