import {
  Group, Mesh, MeshBasicMaterial, Shape, ShapeGeometry, Box3, BackSide
} from 'three';
import { createGeometry } from 'c/ThreeJsWrap/Viewer/core/helpers/geometry';
import Storage from 'scr/utilitiesStorage';

export default class Ceiling2D extends Group {
  constructor( name, shape ) {

    super();
    const mesh = new Mesh(
      createGeometry( new ShapeGeometry( shape ) ),
      Storage.get( 'namesToMaterials' ).service.invisible );// .floor.fineWood.clone() );
    // mesh.material.side = BackSide;
    this.name = name;
    mesh.name = name;
    mesh.isCollidable = true;
    // mesh.isSnapable = true;
    this.mesh = mesh;
    this.add( mesh );
    this.isCeiling = true;
  }

  rebuildGeometry( shape ) {
    this.mesh.geometry = createGeometry( new ShapeGeometry( shape ) );
  }

  getBoundingBox() {
    return new Box3().setFromObject( this );
  }
}
