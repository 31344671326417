/* eslint-disable prefer-reflect */
// @ts-check

/** @type { Partial<CabD.A.ContainerConfigs.ContainerConfig> } */
export const FillerWithCountertop = {
  height: 36,
  depth: 24,
  width: 1,
  name: 'Filler With Countertop',
  isTopLevel: true,
  constrName: 'WithCountertop',
  children: [
    {
      name: 'ToeKickAssembly',
      layout: 'DEPTH',
      height: 3,
      children: [
        {
          name: 'ToeKickFinishEnds',
          layout: 'WIDTH',
          children: [
            {
              type: 'Panel',
              name: 'ToeKickAssembly--LeftPanel'
            },
            {
              name: 'ToeKickAssembly--HollowCenter'
            },
            {
              type: 'Panel',
              name: 'ToeKickAssembly--RightPanel'
            }
          ]
        },
        {
          name: 'ToeKickAssembly--FrontPanel',
          type: 'Panel',
          depth: 0.5
        },
        {
          name: 'ToeKickAssembly--ToeKickSetBack',
          depth: 2
        }
      ]
    },
    {
      name: 'Filler',
      layout: 'DEPTH',
      children: [
        {},
        {
          depth: 0.5,
          type: 'Panel',
          name: 'filler'
        },
        {
          name: 'FrontExteriorAssembly',
          depth: 0.5
        }
      ]
    }
  ]
};


export function getFillerForInCabinetUsage() {
  const json = JSON.parse( JSON.stringify( FillerWithCountertop ) );

  delete json.isTopLevel;
  delete json.height;
  delete json.depth;

  return json;
}
