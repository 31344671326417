import DoBaseAction from "./DoBaseAction";

/**
 * Manager for Undo/Redo history.
 */
class DoBaseManager {
	undos = [];
	redos = [];

    constructor() {
		this.clear();
    }
    clear() {
		this.undos = [];
		this.redos = [];
    }
    registerDo(doaction, group) {
		doaction.group = group;

		let addable = true;
		if (doaction.isGroupEnd && 0 < this.undos.length) {
			if (0 < this.undos.length) {
				const last = this.undos[this.undos.length - 1];
				if (last.dummy && last.isGroupBegin) {
					this.undos.pop();
					addable = false;
				}
			} else {
				console.error('Action-history is invalid.');
				addable = false;
			}
		}
		
		if (addable) {
			this.undos.push(doaction);
			this.redos = [];

			return doaction;
		}

		return null;
	}
	isDoable() {
		return true;
	}
	isUndoable() {
		return this.isDoable() && 0 < this.undos.length;
	}
	isRedoable() {
		return this.isDoable() && 0 < this.redos.length;
	}
    undo() {
        if (this.isUndoable()) {
			let cursor = 0;
			do {
				const doaction = this.undos.pop();
				// do
				doaction.undo();
                this.redos.push(doaction);
                
				// check lifecycle in group
				if (doaction.isGroupEnd) cursor++;
				if (doaction.isGroupBegin) cursor--;
			} while (cursor && 0 < this.undos.length);
		}
    }
    redo() {
		if (this.isRedoable()) {
			let cursor = 0;
			do {
				const doaction = this.redos.pop();
				// do
				doaction.redo();
                this.undos.push(doaction);
                
				// check lifecycle in group
				if (doaction.isGroupBegin) cursor++;
				if (doaction.isGroupEnd) cursor--;
			} while(cursor && 0 < this.redos.length);
		}
	}
	beginDo() {
		const action = new DoBaseAction();
		action.dummy = true;
		this.registerDo(action, true);
	}
	endDo() {
		const action = new DoBaseAction();
		action.dummy = true;
		this.registerDo(action, false);
	}
}

export default DoBaseManager;