// @ts-check
import { simpleSet } from '@r/reducers/utils';
import { _ISimpleSet, AllActions } from '@r/storeUtils';

const s = simpleSet as _ISimpleSet<
  redux._IConstants['SET_PROJECT_NAME'],
  redux._IStore['projectFor3d']['projectName'],
  AllActions
>;
export const projectName = s( 'SET_PROJECT_NAME', 'Test project' );
