/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import Storage from 'scr/utilitiesStorage';
import SimpleInput from 'c/CalculationLayer/utils/SimpleInput';
import CheckChangedValue from 'c/shared/borderForChangedValue';


interface _IStateProps {
  name: string;
  oldName: string;
}

class Name extends PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    name: '',
    oldName: ''
  } as _IStateProps;

  componentDidMount(): void {
    const [selectedObject] = Storage.get( 'selectedObject' );

    if( selectedObject ) {
      this.setState( {
        name: selectedObject.name,
        oldName: selectedObject.name
      } );
    }
  }

  handleChangeName = ( value: string ) => {
    this.setState( {
      name: value
    } );
  };

  setNewName = () => {
    const { name } = this.state;
    const [selectedObject] = Storage.get( 'selectedObject' );

    if( selectedObject ) {

      selectedObject.setName( name );
      this.setState( {
        oldName: name
      } );
    }
  };

  render() {
    const { name, oldName } = this.state;

    return (
      <CheckChangedValue changed={ name !== oldName }>
        <div>
          <SimpleInput
            className='name-input'
            value={ name }
            onChange={ this.handleChangeName }
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            onBlur={ this.setNewName }
          />
        </div>
      </CheckChangedValue>
    );
  }
}

export default Name;
