import {BaseCmd} from "./BaseCmd";
import * as THREE from 'three';
import DoAddRemoveEntity from "../UndoRedo/DoAddRemoveEntity";
import { DrawSnap } from "../common";

class EraseCmd extends BaseCmd {
    hitEntity; // highlight curve-entity
    
    doActivate() {
        super.doActivate();
        this.setCursor('eraser', true);
    }
    
    initCmd() {
        super.initCmd();
        if (this.hitEntity) {
            if (this.hitEntity.highlight) this.hitEntity.highlight = false;
            this.hitEntity = null;
        }
    }

    hitTest() {
        let entity = null;
        const sels = this.viewer.hitCurveG();
        if (0 < sels.length) {
            entity = sels[0].object; // CurveEntity
            entity = entity.parent; // CurveEntityEx
        }
        return entity;
    }

    onMouseMove(e) {
        super.onMouseMove(e);

        // old
        const entities = this.entityMgr.curveG.children;
        entities.forEach(entity => {
            if (entity.highlight) entity.highlight = false;
        });
        // new
        this.hitEntity = this.hitTest();
        if (this.hitEntity) {
            if (!this.hitEntity.highlight) this.hitEntity.highlight = true;
        }

        this.updateRender();
    }

    onMouseClick(e) {
        super.onMouseClick(e);

        if (this.hitEntity) {
            this.doMgr.doDeleteEntity(this.hitEntity);
            this.hitEntity = null;
        }
    }

    vs = new THREE.Vector2();
    ve = new THREE.Vector2();
    drawSnap(ctx) {
        if (!this.hitEntity) return;

        const curve = this.hitEntity.curve;
        curve.getPoint(0, this.vs);
        curve.getPoint(1, this.ve);

        this.vs.copy(this.viewer.uds2view(this.vs));
        this.ve.copy(this.viewer.uds2view(this.ve));

        DrawSnap.drawSticker(ctx, this.vs);
        DrawSnap.drawSticker(ctx, this.ve);
    }
}

export default EraseCmd;