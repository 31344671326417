import {
  FrontSide,
  MeshPhysicalMaterial,
  RepeatWrapping,
  Texture,
  UVMapping
} from 'three';

export const createPhysicalMaterial = (
  color,
  mapImage,
  normalMapImage,
  roughnessMapImage,
  metalnessMapImage
) => {
  let map = null,
    normalMap = null,
    roughnessMap = null,
    metalnessMap = null,
    metalness = 0.0;
  if (mapImage) {
    map = new Texture(mapImage, UVMapping, RepeatWrapping, RepeatWrapping);
    map.wrapS = RepeatWrapping;
    map.wrapT = RepeatWrapping;
    map.needsUpdate = true;
  }
  if (normalMapImage) {
    normalMap = new Texture(
      normalMapImage,
      UVMapping,
      RepeatWrapping,
      RepeatWrapping
    );
    normalMap.wrapS = RepeatWrapping;
    normalMap.wrapT = RepeatWrapping;
    normalMap.needsUpdate = true;
  }
  if (roughnessMapImage) {
    roughnessMap = new Texture(
      roughnessMapImage,
      UVMapping,
      RepeatWrapping,
      RepeatWrapping
    );
    roughnessMap.wrapS = RepeatWrapping;
    roughnessMap.wrapT = RepeatWrapping;
    roughnessMap.needsUpdate = true;
  }
  if (metalnessMapImage) {
    metalnessMap = new Texture(
      metalnessMapImage,
      UVMapping,
      RepeatWrapping,
      RepeatWrapping
    );
    metalnessMap.wrapS = RepeatWrapping;
    metalnessMap.wrapT = RepeatWrapping;
    metalnessMap.needsUpdate = true;
    metalness = 0.9;
  }

  return new MeshPhysicalMaterial({
    color,
    map,
    normalMap,
    side: FrontSide,
    roughnessMap,
    roughness: 1,
    metalnessMap,
    metalness,
    transparent: false,
    //reflectivity: 1.0,
    //clearCoat: 0.0
  });
};

export const updatePhysicalMaterial = (
  material,
  textureRepeat,
  textureOffset
) => {
  if (textureRepeat) {
    if (material.map) {
      material.map.repeat.copy(textureRepeat);
    }
    if (material.normalMap) {
      material.normalMap.repeat.copy(textureRepeat);
    }
    if (material.roughnessMap) {
      material.roughnessMap.repeat.copy(textureRepeat);
    }
  }
  if (textureOffset) {
    if (material.map) {
      material.map.offset.copy(textureOffset);
    }
    if (material.normalMap) {
      material.normalMap.offset.copy(textureOffset);
    }
    if (material.roughnessMap) {
      material.roughnessMap.offset.copy(textureOffset);
    }
  }
};
