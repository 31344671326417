import {
  processUnitSetLabelAction,
  processUnitSetValueAction,
  processSetUnitsAction
} from '@r/reducers/utils';
import { inches, quantity } from 'decl/general/units';
import { UnitsInputv2Value } from 'decl/general/html';
import { AllActions } from '@r/storeUtils';

type upper = redux._IStore['projectFor3d']['cabinetsSettings']['upper'];
const upperDefault: upper = {
  depth: {
    value: 13 as inches,
    label: '' as UnitsInputv2Value
  },
  height: {
    value: 30 as inches,
    label: '' as UnitsInputv2Value
  },
  shelvesQty: 2 as quantity
};

export const upper = (
  state = upperDefault,
  action: AllActions
): typeof state => {
  switch( action.type ) {
    case 'SET_CABINETS_SETTINGS_UPPER_DEPTH_LABEL': {
      return {
        ...state,
        depth: processUnitSetLabelAction( state.depth, action )
      };
    }
    case 'SET_CABINETS_SETTINGS_UPPER_DEPTH_VALUE': {
      return {
        ...state,
        depth: processUnitSetValueAction( state.depth, action )
      };
    }
    case 'SET_CABINETS_SETTINGS_UPPER_HEIGHT_LABEL': {
      return {
        ...state,
        height: processUnitSetLabelAction( state.height, action )
      };
    }
    case 'SET_CABINETS_SETTINGS_UPPER_HEIGHT_VALUE': {
      return {
        ...state,
        height: processUnitSetValueAction( state.height, action )
      };
    }
    case 'SET_CABINETS_SETTINGS_UPPER_SHELVES_QTY': {
      return {
        ...state,
        shelvesQty: action.value
      };
    }
    case 'SET_UNITS': {
      return {
        ...state,
        height: processSetUnitsAction( state.height, action ),
        depth: processSetUnitsAction( state.depth, action )
      };
    }
    default:
      return state;
  }
};
