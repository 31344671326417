// @ts-check
import React, { PureComponent } from 'react';
import { unitsLabel } from 'scr/metricsConversion';
import { unitsInputWithUnits } from './index.styl';
/**
 * @typedef { object } Props
 * @property { typeof unitsLabel } mapping
 * @property { redux.store['units'] } units
 * @property { string } [className='']
 */

/** @augments { PureComponent<Props> } */
export default class UnitsInputWithUnits extends PureComponent {
  static defaultProps = {
    mapping: unitsLabel
  }

  render() {
    const {
      children, mapping, units, className: clN = ''
    } = this.props;
    const className = `${ clN } ${ unitsInputWithUnits } unitsInputWithUnits`;

    return (
      <div className={ className }>
        { children }
        <span className='units'>{ mapping[ units ] }</span>
      </div>
    );
  }
}
