// @ts-check
import { simpleSet } from '@r/reducers/utils';
import { _ISimpleSet, AllActions } from '@r/storeUtils';

const s = simpleSet as _ISimpleSet<
  redux._IConstants['SET_PROJECT_TAG'],
  redux._IStore['projectFor3d']['projectTag'],
  AllActions
>;
export const projectTag = s( 'SET_PROJECT_TAG', 'estimating' );
