/* eslint-disable no-restricted-syntax */
import { groupBy, sortBy } from 'lodash';

export function getTreeViewStructure ( data, nameField, value ) {
  const structuredData = [];

  if ( Array.isArray( data ) ) {
    for ( let i = 0; i < data.length; i++ ) {
      const item = data[ i ];
      const { [ nameField ]: name } = item;

      structuredData.push( {
        nodeType: 'leaf',
        chosen: false,
        ...item,
        label: name,
        value: `${ value } - ${ name }`
      } );
    }
  } else {
    for ( let key in data ) {
      const newValue = `${ value ? `${ value } - ${ key }` : key }`;

      structuredData.push( {
        nodeType: 'nonLeaf',
        open: false,
        label: key,
        value: newValue,
        children: getTreeViewStructure( data[ key ], nameField, newValue )
      } );
    }
  }

  return structuredData;
}

export function groupData( data, fields, index = 0 ) {
  if ( index >= fields.length ) return data.slice();

  const field = fields[ index ];
  let groupedData = groupBy(
    sortBy( data, field ),
    field
  );

  for ( let key in groupedData ) {
    groupedData[ key ] = groupData( groupedData[ key ], fields, index + 1 );
  }

  return groupedData;
}

export function getTreeViewDefaultValue( options ) {
  for ( let i = 0; i < options.length; i++ ) {
    const item = options[ i ];
    const { nodeType, children } = item;
    if ( nodeType === 'leaf' ) {
      return { ...item };
    }

    return getTreeViewDefaultValue( children );
  }
}
