import React from 'react';

export default class SimpleSelect extends React.PureComponent {
  onChange = ({ target: { value } }) => this.props.onChange( value )

  render(){
    const { 
      props: { value, className="", options, disabled = false }, 
      onChange 
    } = this;
    const selectClass = className + " simpleSelect";
    
    return <select { ... { onChange, value, className: selectClass, disabled } }>
      { 
        options.map(({ value, label }) => 
          <option value={ value } key={ value }>{ label }</option>
        )
      }
    </select>;
  }
}