/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button } from '@npmrost/storybook/stories/Vesta/Button';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

class Account extends PureComponent {

  render() {

    const {
      props: {
        isOpen,
        close,
        texts: {
          title,
          fullName,
          email,
          changePasswordQuestion,
          oldPassword,
          newPassword,
          confirmPassword,
          logOut,
          deleteAccount,
          goToAdminMode,
          save,
          cancel
        }
      }
    } = this;

    if ( !isOpen ) return null;

    return (
      <>
        <div>
          <h3>{ title }</h3>
          <Button minimalist onClick={ () => {} }>
            { goToAdminMode }
          </Button>
          <ButtonWrapper>
            <Button inverse onClick={ close }>
              { cancel }
            </Button>
          </ButtonWrapper>
        </div>
      </>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        accountModal
      }
    }
  } ) => {
    return {
      // settings: s.calculationMode.projects.currentProject.settings
      texts: accountModal
    };
  }
)( Account );
