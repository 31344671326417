/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { PureComponent } from 'react';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import UnitsInputWithUnits from 'c/CalculationLayer/utils/UnitsInputWithUnits';
import modalStyl from 'c/CalculationLayer/Modal/index.styl';
import {
  roundForUi,
  reversedUnitMapping as rUm,
  unitMapping as uM
} from 'scr/metricsConversion';
import { UnitsInputv2 } from 'c/CalculationLayer/utils/UnitsInputv2';
import { connect } from 'react-redux';
import Storage from 'scr/utilitiesStorage';
import {
  GenericCabinet
} from 'cSrc/structured/singletonCabinet/core/GenericCabinet';
import { UnitsInputv2Value, className } from '@npmrost/utils';
import { RadioSelect } from 'c/CalculationLayer/utils/RadioSelect';
import { d } from '@r/storeUtils';
import CheckChangedValue from 'c/shared/borderForChangedValue';
// @ts-ignore
import isEqual from 'lodash/isEqual';
import {
  regExForNumber
} from '../../../RightMenuForFloorPlan/components/utils';

type measurePoints = 'toCenter' | 'toEdge'
interface _IMeasurePointLabelAndValue {
  label: string;
  value: measurePoints;
  chosen: boolean;
}

interface _IProps {
  units: redux._IStore['projectFor3d']['units'];
  measurePoint: redux._IStore['flags']['cabinetsMeasurePoint'];
  mode: redux._IStore['flags']['rightSideCabinetMenuMode'];
  dispatch: d<redux._IActions['SET_CABINETS_MEASURE_POINT']>;
  cabinetType: string;
  onChangeMeasurePoint: any;
  changePosition: string;
}

interface _IState {
  toLeft: UnitsInputv2Value;
  toLeftOld: UnitsInputv2Value;
  toRight: UnitsInputv2Value;
  toRightOld: UnitsInputv2Value;
  toTop: UnitsInputv2Value;
  toTopOld: UnitsInputv2Value;
  toBottom: UnitsInputv2Value;
  toBottomOld: UnitsInputv2Value;
}

export const DistanceToWall = connect(
  ( s: redux._IStore ) => ( {
    units: s.projectFor3d.units,
    measurePoint: s.flags.cabinetsMeasurePoint,
    mode: s.flags.rightSideCabinetMenuMode
  } )
)(
  class DistanceToWalls extends PureComponent<_IProps, _IState> {
    constructor( p: DistanceToWalls['props'] ) {
      super( p );

      if( p.cabinetType === 'upper' || p.cabinetType.includes( 'wall' ) ) {
        this.state = {
          toLeft: this.getToLeft(),
          toLeftOld: this.getToLeft(),
          toRight: this.getToRight(),
          toRightOld: this.getToRight(),
          toTop: this.getToTop(),
          toTopOld: this.getToTop(),
          toBottom: this.getToBottom(),
          toBottomOld: this.getToBottom()
        };
      }
    }


    componentDidMount(): void {
      const {
        measurePoint,
        onChangeMeasurePoint
      } = this.props;

      // eslint-disable-next-line no-unused-expressions
      onChangeMeasurePoint && onChangeMeasurePoint( measurePoint );
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillUpdate(): void {
      if(
        !( Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet ) &&
        this.state.toLeft
      ) {
        const {
          toLeftOld,
          toRightOld,
          toTopOld,
          toBottomOld
        } = this.state;
        const newState = {
          toLeft: toLeftOld,
          toRight: toRightOld,
          toTop: toTopOld,
          toBottom: toBottomOld
        };

        // eslint-disable-next-line react/no-will-update-set-state
        this.setState( newState );
      }
    }

    componentDidUpdate( prevProps: _IProps ): void {
      const {
        cabinetType
      } = this.props;

      const cabinet = Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet;

      if( cabinetType !== prevProps.cabinetType && cabinet ) {
        this.setMeasurePoint( 'toEdge' );
      }

      if( cabinet ) {
        const newState = { ...this.state };
        const {
          toLeftOld,
          toRightOld,
          toBottomOld,
          toTopOld
        } = this.state;

        const left = this.getToLeft();
        const right = this.getToRight();
        const top = this.getToTop();
        const bottom = this.getToBottom();

        if( toLeftOld !== left ) {
          newState.toLeft = left;
          newState.toLeftOld = left;
        }
        if( toRightOld !== right ) {
          newState.toRight = right;
          newState.toRightOld = right;
        }
        if( toTopOld !== top ) {
          newState.toTop = top;
          newState.toTopOld = top;
        }
        if( toBottomOld !== bottom ) {
          newState.toBottom = bottom;
          newState.toBottomOld = bottom;
        }

        // eslint-disable-next-line react/no-did-update-set-state
        if( !isEqual( this.state, newState ) ) this.setState( newState );
      }
    }

    getToLeft(): UnitsInputv2Value {
      const dirHori = this.computeDirectionHori( 'left' );
      // we don`t care for this point
      const dirVerti = this.computeDirectionVert( 'bottom' );
      const cabinet = Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet;

      const val = cabinet.vestaObject
      // @ts-ignore
        .getDistanceToWallEnds( dirHori, dirVerti ).minusX;

      return rUm[ this.props.units ]( val ) as UnitsInputv2Value;
    }

    getToRight(): UnitsInputv2Value {
      const dirHori = this.computeDirectionHori( 'right' );
      // we don`t care for this point
      const dirVerti = this.computeDirectionVert( 'bottom' );
      const cabinet = Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet;

      const val = cabinet.vestaObject
      // @ts-ignore
        .getDistanceToWallEnds( dirHori, dirVerti ).plusX;

      return rUm[ this.props.units ]( val ) as UnitsInputv2Value;
    }

    getToTop(): UnitsInputv2Value {
      // we don`t care for this point
      const dirHori = this.computeDirectionHori( 'left' );
      const dirVerti = this.computeDirectionVert( 'top' );
      const cabinet = Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet;

      const val = cabinet.vestaObject
      // @ts-ignore
        .getDistanceToWallEnds( dirHori, dirVerti ).plusY;

      return rUm[ this.props.units ]( val ) as UnitsInputv2Value;
    }

    getToBottom(): UnitsInputv2Value {
      // we don`t care for this point
      const dirHori = this.computeDirectionHori( 'left' );
      const dirVerti = this.computeDirectionVert( 'bottom' );
      const cabinet = Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet;

      const val = cabinet.vestaObject
      // @ts-ignore
        .getDistanceToWallEnds( dirHori, dirVerti ).minusY;

      return rUm[ this.props.units ]( val ) as UnitsInputv2Value;
    }

    setDistance = ( type: keyof _IState ) => ( value: UnitsInputv2Value = this.state[ type ] ) => {
      const { cabinetType } = this.props;

      if(
        ( cabinetType === 'upper' || cabinetType.includes( 'wall' ) ) &&
        Storage.get( 'selectedObject' )[ 0 ]
      ) {
        const { units } = this.props;
        this.setState( ( prev ) => ( {
          ...prev, [ type ]: value, [ `${ type }Old` ]: value
        } ) );
        const cabinet = Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet;

        const inInches = units === 'ftAndInch'
          ? uM.ftAndInch( value )
          : uM[ units ]( Number( value ) );

        switch ( type ) {
          case 'toLeft':
            const dir1 = this.computeDirectionHori( 'left' );

            cabinet.vestaObject
            // @ts-ignore
              .setDistanceToWallEnd( 'left', dir1, inInches );
            const right = this.getToRight();

            this.setState( ( prev ) => ( {
              ...prev, toRight: right, toRightOld: right
            } ) );
            break;
          case 'toTop': {
            const dir1 = this.computeDirectionVert( 'top' );

            cabinet.vestaObject
            // @ts-ignore
              .setDistanceToWallEnd( 'top', dir1, inInches );

            const bottom = this.getToBottom();

            this.setState( ( prev ) => ( {
              ...prev, toBottom: bottom, toBottomOld: bottom
            } ) );
            break;
          }
          case 'toBottom': {
            const dir1 = this.computeDirectionVert( 'bottom' );

            cabinet.vestaObject
            // @ts-ignore
              .setDistanceToWallEnd( 'bottom', dir1, inInches );

            const top = this.getToTop();

            this.setState( ( prev ) => ( {
              ...prev, toTop: top, toTopOld: top
            } ) );
            break;
          }
          default:
            const dir2 = this.computeDirectionHori( 'right' );

            cabinet.vestaObject
            // @ts-ignore
              .setDistanceToWallEnd( 'right', dir2, inInches );

            const left = this.getToLeft();

            this.setState( ( prev ) => ( {
              ...prev, toLeft: left, toLeftOld: left
            } ) );
        }

        Storage.get( 'viewer' ).renderOnDemand.set();
      }
    }

    setMeasurePoint = ( value: measurePoints ) => {
      const { dispatch, onChangeMeasurePoint } = this.props;
      dispatch( { type: 'SET_CABINETS_MEASURE_POINT', value } );
      setTimeout( () => this.setDistance( 'toLeft' )( this.getToLeft() ), 100 );
      setTimeout( () => this.setDistance( 'toRight' )( this.getToRight() ), 100 );
      setTimeout( () => this.setDistance( 'toTop' )( this.getToTop() ), 100 );
      setTimeout( () => this.setDistance( 'toBottom' )( this.getToBottom() ), 100 );
      // eslint-disable-next-line no-unused-expressions
      onChangeMeasurePoint && onChangeMeasurePoint( value );
    }

    handleChangeInput = ( key: string ) => ( value: string ) => {
      const state = { ...this.state };
      if( value.match( regExForNumber ) ) {
        // @ts-ignore
        state[ key ] = value;
      }
      if( !value ) {
        // @ts-ignore
        state[ key ] = '0';
      }

      this.setState( state );
    };

    computeDirectionHori( dir: 'left' | 'right' ) {
      const v = this.props.measurePoint;
      if ( v === 'toCenter' ) return 'center';

      return dir;
    }

    computeDirectionVert( dir: 'bottom' | 'top' ) {
      const v = this.props.measurePoint;
      if ( v === 'toCenter' ) return 'center';

      return dir;
    }

    render() {
      const {
        props: { cabinetType, units, measurePoint },
        state: {
          toLeft,
          toLeftOld,
          toRight,
          toRightOld,
          toTop,
          toTopOld,
          toBottom,
          toBottomOld
        },
        setDistance,
        setMeasurePoint
      } = this;

      const measurePoints: _IMeasurePointLabelAndValue[] = [
        {
          label: 'To Center',
          value: 'toCenter',
          chosen: measurePoint === 'toCenter'
        },
        { label: 'To Edge', value: 'toEdge', chosen: measurePoint === 'toEdge' }
      ];

      return (
        <>
          <div className='measure-points'>
            <h3>Measure point</h3>
            <RadioSelect
              variants={ measurePoints }
              chooseVariant={ setMeasurePoint }
            />
          </div>
          {
            ( cabinetType.includes( 'upper' ) ||
              cabinetType.includes( 'wall' ) )
              ? (
                <>
                  <CheckChangedValue changed={ toLeft !== toLeftOld }>
                    <LabeledField
                      className={
                        `${ modalStyl.commonLabeledField } shortLabel wider`
                      }
                      name='To Left'
                    >
                      <UnitsInputWithUnits units={ units }>
                        <UnitsInputv2
                          units={ units }
                          className={
                            `${
                              modalStyl.commonInput
                            } wide` as className<'input'>
                          }
                          onChange={ this.handleChangeInput( 'toLeft' ) }
                          // @ts-ignore
                          onBlur={ setDistance( 'toLeft' ) }
                          value={
                            roundForUi( toLeft, units ) as UnitsInputv2Value
                          }
                        />
                      </UnitsInputWithUnits>
                    </LabeledField>
                  </CheckChangedValue>

                  <CheckChangedValue changed={ toRight !== toRightOld }>
                    <LabeledField
                      className={
                        `${ modalStyl.commonLabeledField } shortLabel wider`
                      }
                      name='To Right'
                    >
                      <UnitsInputWithUnits units={ units }>
                        <UnitsInputv2
                          units={ units }
                          className={
                            `${
                              modalStyl.commonInput
                            } wide` as className<'input'>
                          }
                          onChange={ this.handleChangeInput( 'toRight' ) }
                          // @ts-ignore
                          onBlur={ setDistance( 'toRight' ) }
                          value={
                            roundForUi( toRight, units ) as UnitsInputv2Value
                          }
                        />
                      </UnitsInputWithUnits>
                    </LabeledField>
                  </CheckChangedValue>
                  <CheckChangedValue changed={ toTop !== toTopOld }>
                    <LabeledField
                      className={
                        `${ modalStyl.commonLabeledField } shortLabel wider`
                      }
                      name='To Top'
                    >
                      <UnitsInputWithUnits units={ units }>
                        <UnitsInputv2
                          units={ units }
                          className={
                            `${
                              modalStyl.commonInput
                            } wide` as className<'input'>
                          }
                          onChange={ this.handleChangeInput( 'toTop' ) }
                          // @ts-ignore
                          onBlur={ setDistance( 'toTop' ) }
                          value={
                            roundForUi( toTop, units ) as UnitsInputv2Value
                          }
                        />
                      </UnitsInputWithUnits>
                    </LabeledField>
                  </CheckChangedValue>

                  <CheckChangedValue changed={ toBottom !== toBottomOld }>
                    <LabeledField
                      className={
                        `${ modalStyl.commonLabeledField } shortLabel wider`
                      }
                      name='To Bottom'
                    >
                      <UnitsInputWithUnits units={ units }>
                        <UnitsInputv2
                          units={ units }
                          className={
                            `${
                              modalStyl.commonInput
                            } wide` as className<'input'>
                          }
                          onChange={ this.handleChangeInput( 'toBottom' ) }
                          // @ts-ignore
                          onBlur={ setDistance( 'toBottom' ) }
                          value={
                            roundForUi( toBottom, units ) as UnitsInputv2Value
                          }
                        />
                      </UnitsInputWithUnits>
                    </LabeledField>
                  </CheckChangedValue>
                </>
              ) : null
          }
        </>
      );
    }
  }
);
