const Step = {
    step0: 0,
    step1: 1,
    step2: 2,
    step3: 3,
}
class BaseCmd
{
    viewer;
    step = Step.step0;

    get isIdle() {
        return this.step < Step.step1;
    }

    get entityMgr() {
        return this.viewer.entityMgr;
    }
    get doMgr() {
        return this.viewer.doMgr;
    }
    get tracker() {
        return this.viewer.tracker;
    }

    doActivate() {
        this.viewer.setCursor('default');
        this.initCmd();
    }
    initCmd() {
        this.step = Step.step0;
    }
    doEscape() { // escape (cancel) command
        this.initCmd();
    }
    deActivate() {
        this.doEscape();
    }

    onMouseMove(e) {
        this.viewer.graspSnap();
    }
    onMouseClick(e) {
        this.viewer.graspSnap();
    }
    onMouseWheel(e) {
        this.onMouseMove(e);
    }
    onKeyDown(e) {
        const key = e.key.toLowerCase();
        if (key=='escape' || key=='esc' || e.keyCode==27 || e.keyCode==32) this.doEscape(); // esc, space
    }

    setCursor(cursor, isUrl, isUp) {
        this.viewer.setCursor(cursor, isUrl, isUp);
    }
    updateRender(justNow) {
        this.viewer.render(justNow);
    }
    drawSnap(ctx) {
    }
}

export {Step, BaseCmd};