
import {
  BufferGeometry,
  Float32BufferAttribute,
  Geometry,
  Vector3, Line, Group
} from 'three';

let _axis = new Vector3();


export default class DimensionHelper extends Group {
  constructor( dir, origin, length, material ) {
    super();
    // добавить черточки и линии выноски
    let _lineGeometry = new BufferGeometry();
    _lineGeometry.setAttribute( 'position', new Float32BufferAttribute( [0, 0, 0, 0, 1, 0], 3 ) );
    this.line = new Line( _lineGeometry, material );
    this.add( this.line );
    // выноска 0
    let geometry = new Geometry();
    geometry.vertices.push(
      new Vector3( -1.5, 0, 0 ),
      new Vector3( 1.5, 0, 0 )
    );
    this.line0 = new Line( geometry, material );

    this.add( this.line0 );

    geometry = new Geometry();
    // выноска 1
    geometry.vertices.push(
      new Vector3( -1.5, length, 0 ),
      new Vector3( 1.5, length, 0 )
    );
    this.line1 = new Line( geometry, material );
    this.add( this.line1 );

    // черточка 0
    geometry = new Geometry();
    geometry.vertices.push(
      new Vector3( -1, 1, 0 ),
      new Vector3( 1, -1, 0 )
    );
    this.slash0 = new Line( geometry, material );
    this.add( this.slash0 );

    // черточка 1
    geometry = new Geometry();
    geometry.vertices.push(
      new Vector3( -1, 1 + length, 0 ),
      new Vector3( 1, -1 + length, 0 )
    );
    this.slash1 = new Line( geometry, material );
    this.add( this.slash1 );

    this.setDirection( dir );
  }

  setLength( length ) {
    // сместить черточки
    // console.log( this );

    this.line1.geometry.vertices[ 0 ].y = length - 0.01;
    this.line1.geometry.vertices[ 1 ].y = length - 0.01;
    this.line1.geometry.verticesNeedUpdate = true;


    this.slash1.geometry.vertices[ 0 ].y = 1 + length;
    this.slash1.geometry.vertices[ 1 ].y = -1 + length;
    this.slash1.geometry.verticesNeedUpdate = true;

    this.line.scale.set( 1, Math.max( 0, length ), 1 );
    this.line.updateMatrix();

  }

  setDirection( dir ) {

    // dir is assumed to be normalized

    if ( dir.y > 0.99999 ) {

      this.quaternion.set( 0, 0, 0, 1 );

    } else if ( dir.y < -0.99999 ) {

      this.quaternion.set( 1, 0, 0, 0 );

    } else {

      _axis.set( dir.z, 0, -dir.x ).normalize();

      let radians = Math.acos( dir.y );

      this.quaternion.setFromAxisAngle( _axis, radians );

    }

  }
}


