import {
  Group, Mesh, Geometry, ExtrudeGeometry, Vector3, Shape, Matrix4
} from 'three';
import Storage from 'scr/utilitiesStorage';
import { createGeometry } from '../helpers/geometry';
import {
  parseAttributes, parseVector3, parseShape, calculateValue
  , calculateVector2, calculateVector3, calculateEuler, buildShape
} from './helpers';
import { textureSize } from '../helpers/constants';
import { getTopLevelObject } from '../helpers/object3d';

export default class Countertop extends Group {
    shape: Shape

    size: Vector3 = new Vector3( 36, 24, 18 );

    mesh: Mesh;

    isCountertop = true;

    isNewCountertop = true;

    constructor(
      config
    ) {
      super();
      this.mesh = new Mesh( new Geometry(), Storage.get( 'namesToMaterials' ).countertop.marblewhite005 );
      this.mesh.isCollidable = true;
      this.mesh.isSnapable = true;
      this.add( this.mesh );
      this.fromJSON( config );


      // / OLD cabinetdesigner methods//////////////////////////////////////////
      this.isCountertop = true;

      this.boxPart = this.mesh;

      this.getOverhang = () => {
        return {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        };
      };

      this.updateAllMergedUvsOffset = ( base ) => {
        const mergedCountertops = this.getMergedCountertops();

        for ( let i = 0; i < mergedCountertops.length; i += 1 ) {
          mergedCountertops[ i ].baseCountertop = base;
          mergedCountertops[ i ].updateUVsOffset( base );
        }
      };

      this.getMergedCountertops = ( arr ) => {
        let mergedCountertops = arr || [this];

        const snapping = this.cabinet.vestaObject.updateSnapping( 0.1 );

        if ( snapping ) {
          if ( snapping.minusX && snapping.minusX.object ) {
            let topLevelObject = getTopLevelObject( snapping.minusX.object );
            if ( topLevelObject.countertop ) {
              if ( mergedCountertops.indexOf( topLevelObject.countertop ) === -1 ) {
                mergedCountertops.push( topLevelObject.countertop );
                mergedCountertops = topLevelObject.countertop.getMergedCountertops( mergedCountertops );
              }
            }
          }
          if ( snapping.plusX && snapping.plusX.object ) {
            let topLevelObject = getTopLevelObject( snapping.plusX.object );
            if ( topLevelObject.countertop ) {
              if ( mergedCountertops.indexOf( topLevelObject.countertop ) === -1 ) {
                mergedCountertops.push( topLevelObject.countertop );
                mergedCountertops = topLevelObject.countertop.getMergedCountertops( mergedCountertops );
              }
            }
          }
          if ( snapping.minusY && snapping.minusY.object ) {
            let topLevelObject = getTopLevelObject( snapping.minusY.object );
            if ( topLevelObject.countertop ) {
              if ( mergedCountertops.indexOf( topLevelObject.countertop ) === -1 ) {
                mergedCountertops.push( topLevelObject.countertop );
                mergedCountertops = topLevelObject.countertop.getMergedCountertops( mergedCountertops );
              }
            }
          }
          if ( snapping.plusY && snapping.plusY.object ) {
            let topLevelObject = getTopLevelObject( snapping.plusY.object );
            if ( topLevelObject.countertop ) {
              if ( mergedCountertops.indexOf( topLevelObject.countertop ) === -1 ) {
                mergedCountertops.push( topLevelObject.countertop );
                mergedCountertops = topLevelObject.countertop.getMergedCountertops( mergedCountertops );
              }
            }
          }
        }

        return mergedCountertops;
      };

      this.updateUVsOffset = ( base ) => {
        if ( !base || base === this ) {
          return;
          // base = this;
        }

        const BP = this.boxPart;
        const g = BP.geometry;
        const v = g.vertices;
        const f = g.faces;
        const fvuvs = g.faceVertexUvs;

        const baseBP = base.boxPart;
        const bv = baseBP.geometry.vertices;
        let VP;
        for ( let i = 0; i < f.length; i += 1 ) {
          if ( Math.max( Math.abs( f[ i ].normal.x ),
            Math.abs( f[ i ].normal.y ),
            Math.abs( f[ i ].normal.z ) ) === Math.abs( f[ i ].normal.y ) ) {
            if ( base.isNewCountertop ) {
              VP = [
                v[ f[ i ].a ].clone()
                  .applyMatrix4( BP.matrixWorld )
                  .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
                  // .sub( bv[ 4 ].clone() )
                  .multiplyScalar( 1 / textureSize ),
                v[ f[ i ].b ].clone()
                  .applyMatrix4( BP.matrixWorld )
                  .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
                  // .sub( bv[ 4 ].clone() )
                  .multiplyScalar( 1 / textureSize ),
                v[ f[ i ].c ].clone()
                  .applyMatrix4( BP.matrixWorld )
                  .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
                  // .sub( bv[ 4 ].clone() )
                  .multiplyScalar( 1 / textureSize )
              ];

              fvuvs[ 0 ][ i ][ 0 ].set( VP[ 0 ].x, VP[ 0 ].z );
              fvuvs[ 0 ][ i ][ 1 ].set( VP[ 1 ].x, VP[ 1 ].z );
              fvuvs[ 0 ][ i ][ 2 ].set( VP[ 2 ].x, VP[ 2 ].z );
            } else {
              VP = [
                v[ f[ i ].a ].clone()
                  .applyMatrix4( BP.matrixWorld )
                  .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
                  .sub( bv[ 4 ].clone() )
                  .multiplyScalar( 1 / textureSize ),
                v[ f[ i ].b ].clone()
                  .applyMatrix4( BP.matrixWorld )
                  .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
                  .sub( bv[ 4 ].clone() )
                  .multiplyScalar( 1 / textureSize ),
                v[ f[ i ].c ].clone()
                  .applyMatrix4( BP.matrixWorld )
                  .applyMatrix4( new Matrix4().getInverse( baseBP.matrixWorld ) )
                  .sub( bv[ 4 ].clone() )
                  .multiplyScalar( 1 / textureSize )
              ];

              fvuvs[ 0 ][ i ][ 0 ].set( VP[ 0 ].x, -VP[ 0 ].z );
              fvuvs[ 0 ][ i ][ 1 ].set( VP[ 1 ].x, -VP[ 1 ].z );
              fvuvs[ 0 ][ i ][ 2 ].set( VP[ 2 ].x, -VP[ 2 ].z );
            }
          }
        }

        g.uvsNeedUpdate = true;
      };

      this.updateAllMergedUvsOffset = ( base ) => {
        const mergedCountertops = this.getMergedCountertops();

        for ( let i = 0; i < mergedCountertops.length; i += 1 ) {
          mergedCountertops[ i ].baseCountertop = base;
          mergedCountertops[ i ].updateUVsOffset( base );
        }
      };

      this.vestaObject.getType = () => {
        return 'countertop';
      };

      this.vestaObject.setMaterialByName = function setMaterialByName( mat ) {
        const viewer = Storage.get( 'viewer' );
        const newMaterial = Storage.get( 'namesToMaterials' ).countertop[
          mat.countertop
        ];

        if ( !newMaterial ) {
          return;
        }

        for ( let i = 0; i < Storage.get( 'materials' ).countertop.length; i += 1 ) {

          if ( Storage.get( 'materials' ).countertop[ i ].material.map ) {
            Storage.get( 'materials' ).countertop[ i ].material.map.dispose();
          }
          if ( Storage.get( 'materials' ).countertop[ i ].material.roughnessMap ) {
            Storage.get( 'materials' ).countertop[ i ].material.roughnessMap.dispose();
          }
          if ( Storage.get( 'materials' ).countertop[ i ].material.normalMap ) {
            Storage.get( 'materials' ).countertop[ i ].material.normalMap.dispose();
          }

          Storage.get( 'materials' ).countertop[ i ].material.dispose();

        }

        this.getParent().boxPart.material = newMaterial;
        viewer.renderOnDemand.set();

        /* if ( this.getParent().cabinet.updateMaterialInConfig ) {
          this.getParent().cabinet
            .updateMaterialInConfig( 'countertopMaterialName', mat.countertop );
        } */
      };

      // ///////////////////////////////////////////////////////////////////////
    }


    rebuildGeometry( ) {
      this.position.copy( this.rebuildPosition() );
      this.rotation.copy( this.rebuildRotation() );
      this.size.copy( this.rebuildSize() );
      this.shape = buildShape(this._shape, this);
      this.mesh.geometry = this.generateGeometry();
    }

    setMaterial() {

    }

    generateGeometry() {

      return createGeometry( new ExtrudeGeometry( this.shape,
        {
          steps: 1,
          depth: this.size.z,
          bevelEnabled: false
        }
      ).rotateX( Math.PI / 2 ) );
    }

    getSize () {
      return this.size.clone();
    }

    setPosition( position: Vector3 ) {
      this.position = position;
      this.rebuildGeometry();
    }

    setName( name: string ) {
      this.name = name;
    }

    fromJSON( config ) {
      this._attributes = Reflect.apply( parseAttributes, this, [config.attributes] );
      this._size = Reflect.apply( parseVector3, this, [config.size] );
      this._position = Reflect.apply( parseVector3, this, [config.position] );
      this._rotation = Reflect.apply( parseVector3, this, [config.rotation] );
      this._shape = Reflect.apply( parseShape, this, [config.shape] );
      this.setName( config.name );
    }

    getAttributeValue( attributeName: string ) {
      return Reflect.apply( calculateValue, this, [this._attributes[ attributeName ]] );
    }

    rebuildPosition() {
      return Reflect.apply( calculateVector3, this, [this._position] );
    }

    rebuildRotation() {
      return Reflect.apply( calculateEuler, this, [this._rotation] );
    }

    rebuildSize() {
      return Reflect.apply( calculateVector3, this, [this._size] );

    }

    getPart() {
      let obj = this.parent;

      while ( obj ) {
        if ( obj.isPart ) {
          return obj;
        }

        obj = obj.parent;
      }

      return null;

    }

    getCabinet() {
      let obj = this.parent;

      while ( obj ) {
        if ( obj.isCabinet ) {
          return obj;
        }

        obj = obj.parent;
      }

      return null;
    }
}
