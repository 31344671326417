import React,{Component} from 'react';
import * as THREE from 'three';

export class AmbientLight extends Component {
  static defaultProps = {
    color: 'rgb(32, 32, 32)'
    ,__constructor:'ambientlight'
  }
  render() {
    return (
      <React.Fragment></React.Fragment>
    )
  }
}

export class DirectionLight extends Component{
  static defaultProps = {
    color: 'rgb(64, 64, 64)'
    ,__constructor: 'directionligth'
    ,position: new THREE.Vector3(1, 1, 1)
    ,castShadow: true
  }
  render() {
    return (
      <React.Fragment></React.Fragment>
    )
  }
}