import * as THREE from 'three';

import { UIPanel } from '../libs/ui.js';
import EditorControls from './EditorControls.js';
import CmdMgr from '../command/CmdMgr';
import DoManager from '../UndoRedo/DoManager';
import EntityMgr from '../entity/EntityMgr';
import { CurveDefaultMaterialEx, CurveHighlightMaterial } from '../entity/entity.js';
import { Constants, Grid, DrawSnap } from '../common';

export const SnapFlag = {
	none: 0,
	grid: 1,
	endVt: 2,
}

let Viewport = function() {
	const container = new UIPanel(); // div
	container.setId('shapeeditor_viewport');

	const view = container.dom;
	view.tabIndex = '0'; // to get keyboard event

	this.dom = view; // div-container
	const scope = this;

	function viewWidth() {
		return view.offsetWidth;
	}
	function viewHeight() {
		return view.offsetHeight;
	}

	// do manager
	const doMgr = new DoManager(this);
	this.doMgr = doMgr;

	// 3d canvas
	const renderer = new THREE.WebGLRenderer({antialias: true}); // create renderer
	renderer.autoClear = false;
	renderer.autoUpdateScene = false;
	renderer.outputEncoding = THREE.sRGBEncoding;
	renderer.setPixelRatio(window.devicePixelRatio);
	view.appendChild(renderer.domElement);
	// 2d canvas
	const canvas2d = document.createElement('canvas');
	canvas2d.className = 'shapeeditor-canvas-2d';
	view.appendChild(canvas2d);
	const ctx2d = canvas2d.getContext("2d");

	function setSize(w, h) {
		renderer.setSize(w, h);

		canvas2d.width = w;
		canvas2d.height = h;
	}
	setSize(viewWidth(), viewHeight());

	// camera
	const camera = new THREE.PerspectiveCamera(50, 1, 0.01, 1000);
	this.camera = camera;
	camera.position.set(0, 15, 10);
	camera.lookAt(0, 0, 0);

	// entity manager with uds-scene
	const scene = new THREE.Scene();
	const entityMgr = new EntityMgr(scene);
	scene.add(entityMgr);
	this.entityMgr = entityMgr;

	scene.background = new THREE.Color(0xaaaaaa);
	scene.rotateX(-Math.PI/2);
	scene.updateMatrixWorld(); // important to call here.
	
	// scene helpers
	const sceneHelpers = new THREE.Scene();
	sceneHelpers.add(Grid.create()); // grid
	sceneHelpers.updateMatrixWorld();

	// members
	const cursorPath = "/img/editshape/cursor/";
	this.setCursor = (cursor, isUrl, isUp) => {
		if (isUrl) {
		  let cursorStyle = "";
		  if (isUp) {
			cursorStyle = "url('" + cursorPath + cursor + ".png'), auto";
		  } else {
			cursorStyle = "url('" + cursorPath + cursor + ".png') 0 25, auto";
		  }
		  this.dom.style.cursor = cursorStyle;
		} else {
		  this.dom.style.cursor = cursor;
		}
	};
	
	// command manager
	const cmdMgr = new CmdMgr(this);
	this.cmdMgr = cmdMgr;

	// coordinates transform : screen2viewEvent -> view2ndc -> ndc2uds
	class Tracker {
		// members
		viewp = { // view-screen coordinates
			x: 0, // cursor x in view
			y: 0, // cursor y in view
			w: 0, // view width
			h: 0, // view height
		};
		ndc = {	// NDC coordinates (-1, 1)
			x: 0,
			y: 0,
		};
		rayCaster = new THREE.Raycaster();
		ray = this.rayCaster.ray;

		udsPlane = new THREE.Plane(); // xy-plane in wcs
		udsInv = new THREE.Matrix4(); // world to uds

		udsPos = null; // THREE.Vector3
		osnap = null; // snap info
		// {
		// 	udsp: THREE.Vector3, // pos3d in uds
		// 	viewp: THREE.Vector2, // pt2d in view
		// 	sflag: SnapFlag,
		// 	dist2: Number, // squared distance from cursor pos in view
		// }

		constructor(scene) {
			this.rayCaster.linePrecision = Grid.linePrecision; // used only when gl.LINE

			this.update(scene);
		}
		update(scene) {
			this.udsPlane.copy(Constants.planeXY) // xy-plane in uds
			this.udsPlane.applyMatrix4(scene.matrixWorld); // to wcs
			this.udsInv.getInverse(scene.matrixWorld); // wcs to uds
		}
		trackPos3d(snap) {
			if (snap && this.osnap) return this.osnap.udsp;
			return this.udsPos;
		}
		trackPos2d = (function() {
			const pos2d = new THREE.Vector2();
			return function(snap) {
				const pos = this.trackPos3d(snap);
				if (pos) return pos2d.copy(pos);
				else return null;
			}
		})();
	}
	const tracker = new Tracker(scene);
	this.tracker = tracker;

	function screen2view(x, y) {
		const rect = view.getBoundingClientRect();
		
		const viewp = tracker.viewp;
		viewp.x = x - rect.left;
		viewp.y = y - rect.top;
		viewp.w = rect.width;
		viewp.h = rect.height;

		return viewp;
	}
	function screen2viewEvent(event) {
		return screen2view(event.clientX, event.clientY);
	}
	function view2ndc() {
		const vp = tracker.viewp;
		const ndc = tracker.ndc;
		ndc.x = (vp.x * 2) / vp.w - 1;
		ndc.y = -(vp.y * 2) / vp.h + 1;
		return ndc;
	}
	const ndc2uds = (() => {
		const udsPos = new THREE.Vector3();
		return () => {
			const ndc = tracker.ndc;
			tracker.rayCaster.setFromCamera(ndc, camera);
			const udsp = tracker.ray.intersectPlane(tracker.udsPlane, udsPos);
			if (udsp) udsp.applyMatrix4(tracker.udsInv);
			tracker.udsPos = udsp;
			return udsp;
		}
	})();
	this.uds2view = (() => { // world to window coordinates
		const wp = new THREE.Vector3();
		const vp = new THREE.Vector2();
		return (udsp) => {
			wp.set(udsp.x, udsp.y, 0).applyMatrix4(scene.matrixWorld); // uds -> wcs
			wp.project(camera); // wcs -> ndc
			vp.set(Math.round((wp.x + 1) * tracker.viewp.w / 2), Math.round((-wp.y + 1) * tracker.viewp.h / 2));
			return vp;
		}
	})();
	this.snapCompare = (() => {
		const vp1 = new THREE.Vector2();
		const vp2 = new THREE.Vector2();
		return (udsp1, udsp2) => {
			vp1.copy(scope.uds2view(udsp1));
			vp2.copy(scope.uds2view(udsp2));
			const dist2 = vp1.distanceToSquared(vp2);
			return dist2 <= Constants.snapSize2;
		}
	})();

	this.graspSnap = (() => {
		const snps = []; // candidates for snap

		function sInit() {
			snps.length = 0;
			tracker.osnap = null;
		}
		function sRegister(udspx, udspy, sflag) {
			const udsp = new THREE.Vector3(udspx, udspy);
			const vp = scope.uds2view(udsp);
			const dist2 = vp.distanceToSquared(tracker.viewp);
			if (dist2 <= Constants.snapSize2) {
				const snp = {
					udsp: udsp, // pos3d in uds
					viewp: vp.clone(), // pt2d in view
					sflag: sflag,
					dist2: dist2, // squared distance from cursor pos in view
				}
				snps.push(snp);
			}
		}
		function sRegGrid(gx, gy) {
			sRegister(gx * Grid.unitSize, gy * Grid.unitSize, SnapFlag.grid);
		}

		return () => {
			view2ndc();
			ndc2uds();
			// get snap
			sInit();
			if (tracker.udsPos) {
				// snap on grid
				const gx = Math.floor(tracker.udsPos.x / Grid.unitSize);
				const gy = Math.floor(tracker.udsPos.y / Grid.unitSize);

				sRegGrid(gx, gy)
				sRegGrid(gx+1, gy);
				sRegGrid(gx+1, gy+1);
				sRegGrid(gx, gy+1);

				// snap on entity-vt
				entityMgr.curveG.children.forEach(entity => {
					const curve = entity.curve;
					const end1 = curve.getPoint(0);
					const end2 = curve.getPoint(1);
					sRegister(end1.x, end1.y, SnapFlag.endVt);
					sRegister(end2.x, end2.y, SnapFlag.endVt);
				});

				// pick suitable snap.
				if (0 < snps.length) {
					snps.sort((a, b) => (a.dist2 - b.dist2));
					
					let osnap = null;
					osnap = snps.find((snp) => snp.sflag==SnapFlag.endVt); // on entity
					if (!osnap) osnap = snps.find((snp) => snp.sflag==SnapFlag.grid); // on grid

					tracker.osnap = osnap;
				}
			}
		}
	})();

	this.hitCurveG = () => {
		return tracker.rayCaster.intersectObjects(entityMgr.curveG.children);
	}

	// mouse event
	const onDownPosition = new THREE.Vector2();
	const onUpPosition = new THREE.Vector2();

	function onMouseDown(event) {
		// event.preventDefault();
		screen2viewEvent(event);
		onDownPosition.copy(tracker.viewp);
	}
	function onMouseMove(event) {
		screen2viewEvent(event);
		cmdMgr.onMouseMove(event);
	}
	function onMouseUp(event) {
		screen2viewEvent(event);
		onUpPosition.copy(tracker.viewp);

		if (event.button===0) { // L-Button
			if (onDownPosition.distanceToSquared(onUpPosition) <= Constants.snapSize2) cmdMgr.onMouseClick(event);
		}
	}
	function onMouseWheel(event) {
		cmdMgr.onMouseWheel(event);
	}
	function onKeyDown(event) {
		event.preventDefault(); // very important to avoid furthermore propagation.

		const idle = cmdMgr.isIdle();

		if (event.ctrlKey && event.keyCode == 90) { // Ctrl + Z
			doMgr.undo();
		} else if (event.ctrlKey && event.keyCode == 89) { // Ctrl + Y
			doMgr.redo();
		} else if (idle && event.ctrlKey && event.keyCode == 65) { // Ctrl + A
			// select all
			const entities = entityMgr.curveG.children;
			entities.forEach(entity => {
				if (!entity.highlight) entity.highlight = true;
			});
			render();
		} else if (idle && event.keyCode == 27) { // Esc
			// unselect all
			const entities = entityMgr.curveG.children;
			entities.forEach(entity => {
				if (entity.highlight) entity.highlight = false;
			});
			render();
		} else if (idle && event.keyCode == 46) { // Delete
			// delete selected
			doMgr.beginDo();
			const entities = [...entityMgr.curveG.children];
			entities.forEach(entity => {
				if (entity.highlight) doMgr.doDeleteEntity(entity);
			});
			doMgr.endDo();
		} else {
			cmdMgr.onKeyDown(event);
		}
	}
	
	// Here : register mouse event handlers for controls before view.
	const controls = new EditorControls(camera, view);
	this.controls = controls;
	controls.addEventListener('change', render);
	view.addEventListener('mousedown', onMouseDown, false);
	view.addEventListener('mouseup', onMouseUp, false);
	view.addEventListener('mouseout', onMouseUp, false);
	view.addEventListener('mousemove', onMouseMove, false);
	view.addEventListener('wheel', onMouseWheel, false);
	view.addEventListener('keydown', onKeyDown, false);

	// draw
	function render(justNow) {
		scene.updateMatrixWorld();
		if (justNow) {
			drawRender();
			renderflag = false;
		} else {
			renderflag = true;
		}
	}
	this.render = render;

	let renderflag = true;
	function animate(time) {
		requestAnimationFrame(animate);
		if (renderflag) {
			drawRender();
			renderflag = false;
		}
	}
	requestAnimationFrame(animate);
	function drawRender() {
		renderer.render(scene, camera);
		renderer.render(sceneHelpers, camera);
	}
	this.drawSnap = function() {
		const vw = viewWidth();
		const vh = viewHeight();
		ctx2d.clearRect(0, 0, vw, vh);

		cmdMgr.drawSnap(ctx2d);
	};

	this.onWindowResize = () => {
		const vw = viewWidth();
		const vh = viewHeight();

		camera.aspect = vw / vh;
		camera.updateProjectionMatrix();

		setSize(vw, vh);
		
		CurveDefaultMaterialEx.resolution.set(vw, vh);
		CurveHighlightMaterial.resolution.set(vw, vh);

		render();
	}
};

export default Viewport;
