import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Details from './Details';
import Materials from './Materials';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default class Settings extends PureComponent {
  render() {
    const { props } = this;

    return (
      <Wrapper>
        <Details { ...props } />
        <Materials { ...props } />
      </Wrapper>
    );
  }
}
