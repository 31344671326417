// @ts-check
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
import Storage from 'scr/utilitiesStorage';
import {
  Vector3, Vector4, Matrix4,
  Texture, PlaneGeometry, Mesh,
  MeshBasicMaterial, DoubleSide, LinearFilter,
  LineBasicMaterial
} from 'three';
import DimensionHelper from 'c/ThreeJsWrap/Viewer/core/helpers/DimensionHelper';

import removeExcessiveFromConfig
  from 'cSrc/structured/singletonCabinet/cabinets/helpers/removeExcessiveFromConfig';
import {
  _changeWidthVestaObject,
  _changeDepthVestaObject,
  _changeHeightVestaObject
} from 'cSrc/structured/singletonCabinet/core/genericCabinetUtils/metrics';
import { BlueprintDummy } from './BlueprintDummy';

/** @this { import('./VestaObject3D').default<GenericCabinet> } */
export function setMaterialByNameGenericCabinet(/** @type {{ cabinet?: string, door?: string, glass?:string }} */mat) {
  // this : vestaObject

  const namesToMaterials = Storage.get('namesToMaterials');
  let newMaterial = null;
  if (mat.glass) newMaterial = namesToMaterials.glass[mat.glass];
  else newMaterial = namesToMaterials.cabinet[mat.cabinet || mat.door || ''];
  if (!newMaterial) return;

  const materials = Storage.get('materials');
  const cabinetMats = materials.cabinet;
  for (let i = 0; i < materials.cabinet.length; i += 1) {
    let cabinetMat = cabinetMats[i];
    let material = cabinetMat.material;
    material.dispose();
    if (material.map) material.map.dispose();
    if (material.roughnessMap) material.roughnessMap.dispose();
    if (material.normalMap) material.normalMap.dispose();
  }
  
  // set material
  const cabinet = this.getParent();
  cabinet.traverse((/** @type { THREE.Object3D } */obj) => {
    const type = obj.vestaObject.getType();
    if (mat.cabinet) { // to cabinet(body)
      if (type !== 'panel/body') return;
    } else if (mat.door) { // to door
      if (type !== 'panel/door') return;
    } else if (mat.glass) { // to door
      if (type !== 'panel/glass') return;
    }
    /** @type { THREE.Mesh } */
    (obj).material = newMaterial;
  });
  const viewer = Storage.get('viewer');
  viewer.renderOnDemand.set();

  if (mat.cabinet) cabinet.updateMaterialInConfig('cabinetMaterialName', mat.cabinet);
  else if (mat.door) cabinet.updateMaterialInConfig('doorMaterialName', mat.door);
}

/** @this { import('./VestaObject3D').default<GenericCabinet> } */
export function getMaterialNameGenericCabinet() {
  return getMaterialNameOfPartGenericCabinet('cabinet');
}

/** @this { import('./VestaObject3D').default<GenericCabinet> } */
export function getMaterialNameOfPartGenericCabinet(/**@type {any}*/part) {
  const config = this.getParent().getConfig();
  if (part=='door') return config.doorMaterialName;
  else return config.cabinetMaterialName;
}

/**
 * This class is aimed to generalize operations with cabinets (blueprint3d
 * legacy operations as move, raycast, rotate, etc but also serialize|deserialize
 * routines).
 *
 * @class
 * @memberof VestaApp.CabinetDesigner.Cabinets
 *
 */
export class GenericCabinet extends BlueprintDummy {
  /** @type { CabD.A.mountTypes[] } */
  mountTypes = [];

  /**
   * @type { import('./VestaObject3D').default<GenericCabinet> & {
   *  changeWidth: ( val: import('decl/general/units').inches ) => void;
   *  changeDepth: ( val: import('decl/general/units').inches ) => void;
   *  changeHeight: ( val: import('decl/general/units').inches ) => void;
   *  setMaterialByName: (arg: { cabinet?: string, door?: string }) => void;
   * } }
   */
  vestaObject = this.vestaObject;

  setMountTypes( /** @type { CabD.A.mountTypes[] } */ mountTypes ) {
    this.mountTypes = mountTypes;
  }

  _isCabinet = true;

  _isSingletonCabinet = true;

  /**
   * @constructs GenericCabinet
   */
  constructor() {
    super();

    this.vestaObject.setType( 'cabinet' );
    this.initVestaObject();
  }

  createDimension(
    /** @type { import('three/src/math/Vector3').Vector3 } */origin,
    /** @type { import('three/src/math/Vector3').Vector3 } */direction
  ) {
    let arrow = new DimensionHelper(
      direction.clone().normalize(),
      origin,
      50,
      new LineBasicMaterial( {
        color: 0x000000
      } )
    );

    if ( !Storage.get( 'viewer' ).controls.object.isOrthographicCamera ) {
      arrow.visible = false;
    }

    const labelCanvas = document.createElement( 'canvas' );
    const context = labelCanvas.getContext( '2d' );
    if ( context === null ) {
      throw new Error( 'Context is null.' );
    }

    context.font = '20px Times';
    const labelWidth = context.measureText( '123' ).width;
    labelCanvas.width = labelWidth;

    // 25 to account for g, p, etc.
    labelCanvas.height = 25;
    context.font = '20px Times';
    context.fillText( '123', 0, 20 );

    let labelTexture = new Texture( labelCanvas );
    labelTexture.magFilter = LinearFilter;
    labelTexture.minFilter = LinearFilter;
    labelTexture.needsUpdate = true;

    let labelMaterial = new MeshBasicMaterial( {
      map: labelTexture,
      side: DoubleSide,
      transparent: true
    } );

    const labelPlane = new PlaneGeometry( 5 * labelWidth / 25, 5 );
    const labelMesh = new Mesh( labelPlane, labelMaterial );

    arrow.label = labelMesh;
    arrow.add( labelMesh );

    return arrow;
  }

  initVestaObject() {
    const v = this.vestaObject;

    v.changeWidth = _changeWidthVestaObject;
    v.changeDepth = _changeDepthVestaObject;
    v.changeHeight = _changeHeightVestaObject;

    v.setMaterialByName = setMaterialByNameGenericCabinet;
    v.getMaterialName = getMaterialNameGenericCabinet;
    v.getMaterialNameOfPart = getMaterialNameOfPartGenericCabinet;
  }

  createDimensionArrows() {
    let origin = new Vector3( 40, 0, 0.1 );
    let direction = new Vector3( 0, 1, 0 ).normalize();
    this.arrowVert = this.createDimension( origin, direction );
    this.add( this.arrowVert );

    origin = new Vector3( 0, 40, 0.1 );
    direction = new Vector3( 1, 0, 0 ).normalize();
    this.arrowHori = this.createDimension( origin, direction );
    this.add( this.arrowHori );
  }

  /** @type { Partial<import('decl/cabinetdesigner/ContainerConfigs').cabinetConfig> } */
  // @ts-ignore
  _config;

  /** @type { () => this['_config'] } */
  getConfig() {
    return this._config;
  }

  setConfig( /** @type { this['_config'] } */ config ) {
    this._config = config;
  }

  reloadConfig( /** @type { any } */ _arg ) {
    return this;
  }

  cloneConfig() {
    return removeExcessiveFromConfig(this.getConfig());
  }

  updateMaterialInConfig(
    /**
     * @type { 'cabinetMaterialName' |
     *  'countertopMaterialName' |
     *  'doorMaterialName'
     * } */key,
    /** @type { string } */materialName
  ) {
    const config = this.getConfig();
    if (config.type==='Door' || config.type==='Panel') {
      return;
    }
    // @ts-ignore
    config[key] = materialName;
    this.setConfig(removeExcessiveFromConfig({config}));
  }

  /** @type { CabD.A.ContainerConfigs.cabinetNames | null } */
  _constr = null;

  /** @type { () => this['_constr'] } */
  getConstr() {
    return this._constr;
  }

  setConstr( /** @type { this['_constr'] } */ constr ) {
    this._constr = constr;
  }

  /** @type { '' | 'tall' | 'base' | 'upper' }  */
  _type = '';

  setType( /** @type { this['_type'] } */t ) {
    this._type = t;
  }

  getType() {
    return this._type;
  }

  fitByWidthToSpace() {
    const viewer = Storage.get( 'viewer' );
    const scene = viewer.scene;
    const snapping = this.vestaObject.updateSnapping( 1e6 );
    const minusXDistance = ( snapping && snapping.minusX && snapping.minusX.distance )
      ? Math.abs( snapping.minusX.distance ) : null;
    const plusXDistance = ( snapping && snapping.plusX && snapping.plusX.distance )
      ? Math.abs( snapping.plusX.distance ) : null;
    const fitWidth = ( minusXDistance && plusXDistance )
      ? ( minusXDistance + plusXDistance ) : null;

    if ( fitWidth ) {
      const oldWidth = this.vestaObject.getSizes().width;
      this.vestaObject.changeWidth( fitWidth - 0.001 );

      const a = new Vector4( ( plusXDistance - minusXDistance ) / 2, 0, 0, 0 )
        .applyMatrix4( new Matrix4().makeRotationX( this.initialRotation
          ? -this.initialRotation : 0 ) )
        .applyMatrix4( new Matrix4().makeRotationFromEuler( this.rotation ) );
      this.position.add( new Vector3( a.x - ( fitWidth - oldWidth ) / 2, a.y, a.z ) );
      viewer.renderOnDemand.set();
    }

    return fitWidth;
  }

  centerByWidthToSpace() {
    const viewer = Storage.get( 'viewer' );
    const scene = viewer.scene;
    const snapping = this.vestaObject.updateSnapping( 1e6 );

    const minusXDistance = ( snapping && snapping.minusX && snapping.minusX.distance )
      ? Math.abs( snapping.minusX.distance ) : null;
    const plusXDistance = ( snapping && snapping.plusX && snapping.plusX.distance )
      ? Math.abs( snapping.plusX.distance ) : null;

    if ( minusXDistance && plusXDistance ) {

      const a = new Vector4( ( plusXDistance - minusXDistance ) / 2, 0, 0, 0 )
        .applyMatrix4( new Matrix4().makeRotationX( this.initialRotation
          ? -this.initialRotation : 0 ) )
        .applyMatrix4( new Matrix4().makeRotationFromEuler( this.rotation ) );
      this.position.add( new Vector3( a.x, a.y, a.z ) );
      viewer.renderOnDemand.set();
    }

  }

  fitByWidthToLeft() {
    const viewer = Storage.get( 'viewer' );
    const scene = viewer.scene;
    const snapping = this.vestaObject.updateSnapping( 1e6 );
    const minusXDistance = ( snapping && snapping.minusX && snapping.minusX.distance )
      ? Math.abs( snapping.minusX.distance ) : null;
    if ( minusXDistance ) {

      const a = new Vector4( ( -minusXDistance + this.getSizes().width / 2 ), 0, 0, 0 )
        .applyMatrix4( new Matrix4().makeRotationX( this.initialRotation
          ? -this.initialRotation : 0 ) )
        .applyMatrix4( new Matrix4().makeRotationFromEuler( this.rotation ) );
      this.position.add( new Vector3( a.x, a.y, a.z ) );
      viewer.renderOnDemand.set();
    }
  }

  fitByWidthToRight() {
    const viewer = Storage.get( 'viewer' );
    const scene = viewer.scene;
    const snapping = this.vestaObject.updateSnapping( 1e6 );
    const plusXDistance = ( snapping && snapping.plusX && snapping.plusX.distance )
      ? Math.abs( snapping.plusX.distance ) : null;
    if ( plusXDistance ) {

      const a = new Vector4( ( plusXDistance - this.getSizes().width / 2 ), 0, 0, 0 )
        .applyMatrix4( new Matrix4().makeRotationX( this.initialRotation
          ? -this.initialRotation : 0 ) )
        .applyMatrix4( new Matrix4().makeRotationFromEuler( this.rotation ) );
      this.position.add( new Vector3( a.x, a.y, a.z ) );
      viewer.renderOnDemand.set();
    }
  }

  initialRotation = 0;
}
