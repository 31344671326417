import { combineReducers } from 'redux';

import { combRed, AllActions } from '@r/storeUtils';

import {
  setDefaultPercentageLoss
} from './information/percentageLoss';
import {
  setDefaultShippingCost
} from './information/shippingCost';
import { information } from './information';
import {
  setDefaultMaterials, materials
} from './materials';
import {
  setDefaultDimensions, dimensions
} from './dimensions';

const settingsComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['settings'],
    AllActions
  >;

export const settings = settingsComb( {
  information,
  dimensions,
  materials
} );

export function setDefaultSettings() {
  return ( dispatch ) => {
    dispatch( setDefaultPercentageLoss() );
    dispatch( setDefaultShippingCost() );
    dispatch( setDefaultMaterials() );
    dispatch( setDefaultDimensions() );
  };
}
