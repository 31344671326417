import { simpleSet } from '@r/reducers/utils';
import { percent } from '@npmrost/utils';

const setCalculationModeMarkupValue = simpleSet as
  redux.reducers.calculationMode.projects.currentProject.settings.materials.markup.value;

export const value = setCalculationModeMarkupValue(
  'SET_CALCULATION_MODE_CURRENT_PROJECT_MARKUP_VALUE',
  0 as percent
);
