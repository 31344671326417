import DoAction from "./DoAction";

export default class DoSetDefaultLocation extends DoAction {
    constructor(before, after) {
        super();

        this.before = before;
        this.after = after;
    }
	redo() {
        this.viewer.scene.setDefaultLocation(this.after);
	}
	undo() {
        this.viewer.scene.setDefaultLocation(this.before);
    }
}