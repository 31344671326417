// @ts-check
import React, { PureComponent } from 'react';
import TripletHolder from 'c/CalculationLayer/utils/TripletHolder';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import modalStyl from 'c/CalculationLayer/Modal/index.styl';
import { Upper } from './Upper';
import { Base } from './Base';
import { Tall } from './Tall';
import { CrownMolding } from './CrownMolding';

export class CabinetsSettings extends PureComponent {
  render() {
    return (
      <div>
        <LabeledField { ...{
          className: `${ modalStyl.commonLabeledField } wider shortLabel label100px fontSize14px`,
          name: ''
        } }
        >
          <TripletHolder>
            <span>Height</span>
            <span>Depth</span>
            <span>QTY Shelves</span>
          </TripletHolder>
        </LabeledField>

        <Base />
        <Upper />
        <Tall />
        <h4>Crown molding</h4>
        <CrownMolding />
      </div>
    );
  }
}
