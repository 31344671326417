/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import EditProject from '../Main/NewProject';

const Content = styled.div`
  margin-top: 35px;
  height: calc(100% - 35px);
  overflow-x: hidden;
`;

class Edit extends PureComponent {

  render() {

    const {
      props: {
        isOpen,
        settings,
        close
      }
    } = this;

    if ( !isOpen ) return null;

    return (
      <>
        <Content>
          <EditProject
            currentProject
            editProject
            close={ close }
            settings={ settings }
          />
        </Content>
      </>
    );
  }
}

export default connect(
  ( s ) => {
    return {
      settings: s.calculationMode.projects.currentProject.settings
    };
  }
)( Edit );
