const calcPullsCost = ( {
  pullQty,
  pull: { name, costPerFt, labourUnit },
  fixLabourCost
} ) => {

  console.log( `Pull:
    name: ${ name }
    cost per ft: ${ costPerFt }
    labout unit: ${ labourUnit }
  ` );

  console.log( `Pull quantity from cabinet table: ${ pullQty }` );

  const labourCostNotDoubled = Number(
    ( pullQty * fixLabourCost * ( labourUnit ? Number( labourUnit ) : 0 ) )
      .toFixed( 2 )
  );

  console.log( `Labour cost: ${ labourCostNotDoubled }` );

  // doubled prices
  const labourCost = 2 * labourCostNotDoubled;

  const costNotDoubled = Number(
    ( pullQty * Number( costPerFt ) ).toFixed( 2 )
  );

  console.log( `Cost (without labour): ${ costNotDoubled }` );

  // doubled prices
  const cost = 2 * costNotDoubled;

  const totalCost = Number(
    ( cost + labourCost ).toFixed( 2 )
  );

  console.log( `Total cost: ${ totalCost }` );

  return {
    pull: {
      name,
      count: pullQty,
      cost: totalCost,
      costWithoutLabour: cost,
      totalLabourCost: labourCost
    },
    labourCost
  };
};

export default ( {
  pullQty,
  pull,
  totalPulls,
  fixLabourCost
} ) => {

  if ( !pullQty || !pull.value ) {
    return { newTotalPulls: totalPulls, pullLabourCost: 0 };
  }

  const newTotalPulls = totalPulls.slice();

  const {
    pull: newPull,
    labourCost: pullLabourCost
  } = calcPullsCost( { pullQty, pull, fixLabourCost } );

  const {
    name: newName,
    count: newCount,
    cost: newCost,
    costWithoutLabour: newCostWithoutLabour,
    totalLabourCost: newTotalLabourCost
  } = newPull;

  const pullExists = newTotalPulls
    .find( ( item ) => item.name === newName );

  if ( pullExists ) {
    for ( let i = 0; i < newTotalPulls.length; i++ ) {
      const {
        name, cost, costWithoutLabour, totalLabourCost
      } = newTotalPulls[ i ];

      if ( name === newName ) {
        newTotalPulls[ i ].count += newCount;
        newTotalPulls[ i ].cost = Number( ( cost + newCost ).toFixed( 2 ) );
        newTotalPulls[ i ].costWithoutLabour = Number(
          ( costWithoutLabour + newCostWithoutLabour ).toFixed( 2 )
        );
        newTotalPulls[ i ].totalLabourCost = Number(
          ( totalLabourCost + newTotalLabourCost ).toFixed( 2 )
        );

        break;
      }
    }
  } else {
    newTotalPulls.push( newPull );
  }

  return { newTotalPulls, pullLabourCost };
};
