import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Storage from 'scr/utilitiesStorage';
import FloorPlanTools from './components/FloorPlanTools';
import FloorPlanModal from './components/FloorPlanModal';

interface _IProps {
  generalViewMode: redux._IStore['flags']['generalViewMode'];
}

class FloorPlanMode extends PureComponent<_IProps> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    selectedObject: null,
    mouseCoordinates: null
  };

  componentDidMount () {
    const viewer = Storage.get( 'viewer' );

    viewer.addEventListener(
      'objectselected',
      this.processSelection
    );
  }

  componentWillUnmount () {
    const viewer = Storage.get( 'viewer' );

    viewer.removeEventListener(
      'objectselected',
      this.processSelection
    );
  }

  processSelection = (
    e: any
  ) => {
    const { data, data: { object } } = e;

    if( object[ 0 ] && object[ 0 ].isFloorplanDimension ) {
      this.setState( {
        selectedObject: object[ 0 ],
        mouseCoordinates: data.mouse
      } );
    } else {
      this.setState( {
        selectedObject: null,
        mouseCoordinates: null
      } );
    }
  };

  handleHideModal = () => {
    this.setState( {
      selectedObject: null
    } );
  };

  render() {
    const {
      props: {
        generalViewMode
      },
      state: {
        selectedObject,
        mouseCoordinates
      }
    } = this;

    if( generalViewMode !== 'floorPlan' ) {
      return null;
    }

    return (
      <>
        <FloorPlanTools />
        <FloorPlanModal
          dimensions={ selectedObject }
          visible={ !!( selectedObject ) }
          hideModal={ this.handleHideModal }
          mouseCoordinates={mouseCoordinates}
        />
      </>
    );
  }
}

export default connect(
  ( { flags }: redux._IStore ) => {
    return {
      generalViewMode: flags.generalViewMode
    };
  }
)( FloorPlanMode );
