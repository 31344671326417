import React, { PureComponent } from 'react';
import { RadioSelect } from 'c/CalculationLayer/utils/RadioSelect';
import Storage from 'scr/utilitiesStorage';
import ImageSelect from 'c/ImageSelect';
import { d, batchActionsWithTempl } from '@r/storeUtils';
import { connect } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
// eslint-disable-next-line no-unused-vars
import { crownMoldings } from 'decl/ThreeScene';
import style from './index.styl';

type PresenceVal = 'on' | 'off';

interface _IVariant<V> {
  label: string;
  value: V;
  chosen: boolean;
}

type Shape = {
  img: string;
  name: string;
  uiName: string;
  shape: object;
}

type Material = {
  img: string;
  name: string;
  uiName: string;
  material: object;
}

interface ISelectedShape {
  baseMap: string;
  label: string;
  value: string;
}

interface ISelectedMaterial {
  baseMap: string;
  label: string;
  value: string;
}

type defaultMaterial = redux._IStore['projectFor3d']['cabinetsSettings']['defaultMaterial'];
type defaultShape = redux._IStore['projectFor3d']['cabinetsSettings']['defaultShape'];
type isPresented = redux._IStore['projectFor3d']['cabinetsSettings']['isPresented'];

type a = redux._IActions;

type batchAction = batchActionsWithTempl<
  a['setCabinetsSettingsCrownMoldingDefaultMaterial'] |
  a['setCabinetsSettingsCrownMoldingDefaultShape'] |
  a['setCabinetsSettingsCrownMoldingIsPresented']
  >;

interface _IProps {
  defaultMaterial: defaultMaterial;
  defaultShape: defaultShape;
  isPresented: isPresented;
  dispatch: d<ReturnType<batchAction>>;
}

export const CrownMolding = connect(
  ( {
    projectFor3d: {
      cabinetsSettings: { defaultShape, defaultMaterial, isPresented }
    }
  }: redux._IStore ): Omit<_IProps, 'dispatch'> => ( {
    defaultMaterial,
    defaultShape,
    isPresented
  } )
)( class CrownMolding extends PureComponent<_IProps> {
  choosePresence = ( isPresentedNew: boolean ) => () => {
    const { dispatch } = this.props;
    const crownMoldings = Storage.get( 'crownMoldings' ) as crownMoldings;
    crownMoldings.isPresented = !isPresentedNew;
    Storage.set( 'crownMoldings', crownMoldings );
    dispatch(
      ( batchActions as batchAction )( [
        {
          type: 'SET_CABINETS_SETTINGS_CROWN_MOLDING_IS_PRESENTED',
          value: !isPresentedNew
        }
      ] )
    );
    this.forceUpdate();
  };

  chooseShape = ( shape: { value: string } ) => {
    const { dispatch } = this.props;
    const crownMoldings = Storage.get( 'crownMoldings' ) as crownMoldings;
    crownMoldings.defaultShape = shape.value;
    Storage.set( 'crownMoldings', crownMoldings );
    dispatch( ( batchActions as batchAction )( [
      {
        type: 'SET_CABINETS_SETTINGS_CROWN_MOLDING_DEFAULT_SHAPE',
        value: shape.value
      }
    ] )
    );
    this.forceUpdate();
  };

  chooseMaterial = ( material: { value: string } ) => {
    const { dispatch } = this.props;
    const crownMoldings = Storage.get( 'crownMoldings' ) as crownMoldings;
    crownMoldings.defaultMaterial = material.value;
    Storage.set( 'crownMoldings', crownMoldings );
    dispatch( ( batchActions as batchAction )( [
      {
        type: 'SET_CABINETS_SETTINGS_CROWN_MOLDING_DEFAULT_MATERIAL',
        value: material.value
      }
    ] )
    );
    this.forceUpdate();
  }

  render() {
    const {
      choosePresence,
      chooseShape,
      chooseMaterial,
      props: {
        defaultMaterial,
        defaultShape,
        isPresented
      }
    } = this;

    const shapes = Storage.get( 'shapes' ).crownMoldings;
    const materials = Storage.get( 'materials' ).crownMolding;
    const shapesOptions = shapes.map( ( {
      img: baseMap, uiName: label, name: value
    }: Shape ) => ( { value, label, baseMap } as ISelectedShape )
    );
    const materialsOptions = materials.map( ( {
      img: baseMap, uiName: label, name: value
    }: Material ) => ( { value, label, baseMap } as ISelectedMaterial )
    );
    const selectedValue = shapesOptions.find(
      ( shape: ISelectedShape ) => (
        shape.value === defaultShape
      ) );
    const selectedMaterial = materialsOptions.find(
      ( material: ISelectedMaterial ) => (
        material.value === defaultMaterial
      ) );

    const variantsForPresence: _IVariant< PresenceVal >[] = [
      { label: 'On', value: 'on', chosen: isPresented },
      { label: 'Off', value: 'off', chosen: !isPresented }
    ];

    return (
      <div>
        <RadioSelect< _IVariant<PresenceVal> > { ...{
          variants: variantsForPresence,
          chooseVariant: choosePresence( isPresented )
        } }
        />
        <h5>Type</h5>
        <div className={ style.crownMoldingsSelect }>
          <ImageSelect
            value={ selectedValue }
            options={ shapesOptions }
            onChange={ chooseShape }
          />
        </div>
        <h5>Material</h5>
        <div className={ style.crownMoldingsSelect }>
          <ImageSelect
            value={ selectedMaterial }
            options={ materialsOptions }
            onChange={ chooseMaterial }
          />
        </div>
      </div>
    );
  }
} );
