/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  TabsVertical
} from '@npmrost/storybook/stories/Vesta/Tabs/TabsVertical';
import { Button } from '@npmrost/storybook/stories/Vesta/Button';
import { d } from '@r/storeUtils';
import { Theme } from '@@/Theme';
import {
  createNewProject, saveProject
} from '@r/reducers/storeParts/calculationMode/projects/currentProject';
import ProjectInformation from './ProjectInformation';
import Dimensions from './Dimensions';
import Materials from './Materials';

const Container = styled.div`
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  height: 85%;
`;

const AsideMenu = styled.div`
  min-width: 240px;
  margin-right: 40px;
  padding-right: 30px;
  height: 100%;
  ${ ( { theme: t }: { theme: typeof Theme } ): string => `
    border-right: ${ t.generalBorder };
    font-size: ${ t.headerFontSize };
  ` }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledPerfectScrollbar = styled( PerfectScrollbar )`
  width: 100%;
`;

interface _IProps {
  newProjectTabChosen: redux._IStore['calculationMode']['tabs']['newProject'];
  texts: redux._IStore['texts']['calculationMode'];
  settings: redux._IStore['calculationMode']['settings'];
  currentProject: boolean;
  editProject: boolean;
  close: () => void;
  dispatch: d<
    redux._IActions['setCalculationModeNewProjectTab'] |
    redux._IActions['setCalculationModeCurrentProject'] |
    redux._IActions['toggleCalculationModeMainModal']
  >;
}

class NewProject extends PureComponent<_IProps> {

  chooseTab = ( value: string ) => {
    this.props.dispatch( {
      type: 'SET_CALCULATION_MODE_NEW_PROJECT_TAB',
      value: value as _IProps['newProjectTabChosen']
    } );
  }

  create = () => {
    const { close, dispatch } = this.props;

    dispatch( createNewProject() )
      .then( () => {
        close();
      } )
      .catch( ( err: any ) => {
        alert( err );
      } );
  }

  save = () => {
    const { close, dispatch } = this.props;

    dispatch( saveProject() )
      .then( () => {
        close();
      } )
      .catch( ( err: any ) => {
        alert( err );
      } );
  }

  render() {

    const {
      props: {
        texts: {
          newProjectTabs: newProjectTabsText,
          createProject: createProjectText,
          saveProject: saveProjectText
        },
        newProjectTabChosen,
        settings: {
          information,
          information: { units },
          dimensions,
          materials
        },
        currentProject,
        editProject
      },
      chooseTab,
      create,
      save
    } = this;

    const newProjectTabs = ['projectInformation', 'dimensions', 'materials'];

    const options = newProjectTabsText.map( ( item, index ) => {
      return { label: item, value: newProjectTabs[ index ] };
    } );

    return (
      <Container>
        <Wrapper>
          <AsideMenu>
            <TabsVertical
              className='tabs'
              options={ options }
              chosen={
                [{ label: newProjectTabChosen, value: newProjectTabChosen }]
              }
              choose={ chooseTab }
            />
          </AsideMenu>
          <StyledPerfectScrollbar>
            {
              newProjectTabChosen === 'projectInformation' && (
                <ProjectInformation
                  settings={ information }
                  currentProject={ currentProject }
                />
              )
            }
            {
              newProjectTabChosen === 'dimensions' && (
                <Dimensions
                  settings={ dimensions }
                  units={ units }
                  currentProject={ currentProject }
                  adminMode={ false }
                />
              )
            }
            {
              newProjectTabChosen === 'materials' && (
                <Materials
                  settings={ materials }
                  currentProject={ currentProject }
                  adminMode={ false }
                />
              )
            }
          </StyledPerfectScrollbar>
        </Wrapper>
        <ButtonWrapper>
          <Button primary onClick={ editProject ? save : create }>
            { editProject ? saveProjectText : createProjectText }
          </Button>
        </ButtonWrapper>
      </Container>
    );
  }
}

export default connect(
  ( s: redux._IStore ): Omit<
    _IProps,
    'dispatch' | 'currentProject' | 'close' | 'settings' | 'editProject'
  > => {
    return {
      newProjectTabChosen: s.calculationMode.tabs.newProject,
      texts: s.texts.calculationMode
    };
  }
)( NewProject );
