/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { AllActions, _ISimpleSetObj, _ISimpleAction } from '@r/storeUtils';
import { simpleSet } from '@r/reducers/utils';

declare global {
  namespace redux {
    namespace store {
      // eslint-disable-next-line @typescript-eslint/interface-name-prefix
      interface flags {
        hasSavedProject: boolean;
      }
    }

    interface _IConstants {
      SET_HAS_SAVED_PROJECT: 'SET_HAS_SAVED_PROJECT';
    }

    interface _IActions {
      SET_HAS_SAVED_PROJECT: _ISimpleAction<
        _IConstants['SET_HAS_SAVED_PROJECT'],
        _IStore['flags']['hasSavedProject']
      >;
    }
  }
}

const setHasSavedProject = simpleSet as _ISimpleSetObj<
  redux._IConstants['SET_HAS_SAVED_PROJECT'],
  redux._IStore['flags']['hasSavedProject'],
  AllActions
>;

export const hasSavedProject = setHasSavedProject(
  'SET_HAS_SAVED_PROJECT',
  false
);
