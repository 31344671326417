/* eslint-disable prefer-reflect */
/* eslint-disable no-param-reassign */
// @ts-check
import { roundForUi, reversedUnitMapping as rUm } from 'scr/metricsConversion';
import store from '@r/store';

function createCanvasAndAddToLabel(
  /** @type { number } */ val,
  /** @type { any } */ l
) {
  const { projectFor3d: { units } } = store.getState();

  const labelCanvas = document.createElement( 'canvas' );
  const context = labelCanvas.getContext( '2d' );
  if( context === null ) {
    throw new Error( 'Context is null.' );
  }

  context.font = '20px Times';
  const title = String( roundForUi( rUm[ units ]( val ), units ) );
  const labelWidth = context.measureText( title ).width;
  labelCanvas.width = labelWidth;

  // 25 to account for g, p, etc.
  labelCanvas.height = 25;
  context.font = '20px Times';
  context.fillText( title, 0, 20 );

  l.material.map.image = labelCanvas;
  l.geometry = new THREE.PlaneGeometry( 5 * labelWidth / 25, 5 );
  l.rotation.z = Math.PI / 2;

  // 2.5 is a half of dimension plane width
  l.position.y = val / 2 - 0 * 2.5 * labelWidth / 25;
  l.material.map.needsUpdate = true;

  return l;
}

/** @this { import('../GenericCabinet').GenericCabinet['vestaObject']} */
function updateConfigSizes() {
  const cabinet = this.getParent();
  const config = cabinet.getConfig();
  const { width, depth, height } = this.getSizes();

  cabinet.setConfig( {
    ...config,
    width,
    depth,
    height
  } );
}

/** @this { import('../GenericCabinet').GenericCabinet['vestaObject'] } */
function updateVestaObjectSizes(
  /** @type { 'width' | 'depth' | 'height' } */ metric,
  /** @type { redux.inches } */val
) {
  // @ts-ignore
  this.constructor.updateSizesOnResize.call( this, metric, val );
  updateConfigSizes.call( this );
}


/** @this { import('../GenericCabinet').GenericCabinet['vestaObject'] } */
function _changeWidthVestaObject( /** @type { number } */val ) {
  const container = this.getParent();

  container.arrowVert.position.x = val / 2 + 1;
  container.arrowHori.position.x = -val / 2;
  container.arrowHori.setLength( val, 1, 1 );

  const l = createCanvasAndAddToLabel( val, container.arrowHori.label );
  // -2.5 is a half of dimension plane width
  l.position.x = -2.5 - 1;

  const { width: old } = container.vestaObject.getSizes();
  const pos = container.position;

  container.changeWidth( val );
  pos.x = pos.x - old / 2 + val / 2;

  container.addCube();

  updateVestaObjectSizes.call( this, 'width', val );
}

/** @this { import('../GenericCabinet').GenericCabinet['vestaObject'] } */
function _changeDepthVestaObject( /** @type { number } */val ) {
  const container = this.getParent();
  container.changeDepth( val );
  container.addCube();

  updateVestaObjectSizes.call( this, 'depth', val );
}

/** @this { import('../GenericCabinet').GenericCabinet['vestaObject'] } */
function _changeHeightVestaObject( /** @type { number } */val ) {
  const container = this.getParent();
  container.arrowVert.position.y = -val / 2;
  container.arrowVert.setLength( val, 1, 1 );

  const l = createCanvasAndAddToLabel( val, container.arrowVert.label );
  // -2.5 is a half of dimension plane width
  l.position.x = 2.5 + 1;

  container.arrowHori.position.y = val / 2 + 1;

  container.changeHeight( val );
  container.addCube();

  updateVestaObjectSizes.call( this, 'height', val );
}

export {
  _changeDepthVestaObject,
  _changeHeightVestaObject,
  _changeWidthVestaObject
};

