import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { projectName } from './projectName';
import { floor } from './floor';
import { cabinetMaterial } from './cabinetMaterial';
import { doorMaterial } from './doorMaterial';
import { walls } from './walls';
import { projectTag } from './projectTag';
import { units } from './units';
import { snapSensitivity } from './snapSensitivity';
import { wHeight } from './wHeight';
import { wDepth } from './wDepth';
import { roomTemplate } from './roomTemplate';
import { roomWidthDepth } from './roomWidthDepth';
import { cabinetsSettings } from './cabinetsSettings';

const projectFor3dComb = combineReducers as
  combRed<redux._IStore['projectFor3d'], AllActions>;

export const projectFor3d = projectFor3dComb( {
  projectName,
  floor,
  cabinetMaterial,
  doorMaterial,
  walls,
  projectTag,
  units,
  snapSensitivity,
  wHeight,
  wDepth,
  roomTemplate,
  roomWidthDepth,
  cabinetsSettings
} );


