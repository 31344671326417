/* eslint-disable import/prefer-default-export */
import { Utils } from 's/scripts/core/utils.js';
import getBp, { updateThings } from 'scr/getBlueprintObject.js';
import { Container } from '../container/index.js';
import { addCube } from '../utils/addCube.js';
import * as selUtils from '../utils/selectedUtils';
import { genericRaycast } from '../utils/genericRaycast.js';

function getReflectionCube() {
  const path = './img/materials/env/temple/';
  const format = '.png';
  const urls = [
    `${path}px${format}`,
    `${path}nx${format}`,
    `${path}py${format}`,
    `${path}ny${format}`,
    `${path}pz${format}`,
    `${path}nz${format}`
  ];
  const reflectionCube = new THREE.CubeTextureLoader().load(urls);
  reflectionCube.format = THREE.RGBFormat;

  return reflectionCube;
}

async function loadTripletWithEnvMap(pathToImg) {
  const textures = [
    pathToImg,
    pathToImg.replace(/basecolor\.jpg$/, 'roughness.jpg'),
    pathToImg.replace(/basecolor\.jpg$/, 'normal.jpg')
  ];

  return await Promise.all(
    textures
      .map(
        (it) => new Promise(((r) => {
          new THREE.TextureLoader().load(it, (t) => {
            r(t);
          });
        }))
      )
      .concat(getReflectionCube())
  );
}

export class BlueprintDummy extends Container {
  metadata = { itemName: '' };

  setMetadataName(n) {
    this.metadata.itemName = n;
  }

  getMetadataName() {
    return this.metadata.itemName;
  }

  scene = null;

  setScene(s) {
    this.scene = s;
  }

  getScene() {
    return this.scene;
  }

  position_set = false;

  setPosition_SetState(p) {
    this.position_set = p;
  }

  getPosition_SetState() {
    return this.position_set;
  }

  removeSelf() {
    this.parent.remove(this);
    getBp().three.scene.removeItem(this);
    updateThings();
  }

  /**
   * Creates copy of the item in the (0,0,0) coordinates
   * @method
   *
   *
   */
  copySelf() {
    const scene = this.getScene();
    const comp = this.getConstr();
    const itemName = this.getMetadataName();

    scene.addItem('11', null, {
      format: 'cabinet',
      comp,
      itemName,
      cabinetSettings: {
        metrics: {
          width: this.getWidth(),
          depth: this.getDepth(),
          height: this.getHeight()
        },
        texture: this.getTextureUrl()
      }
    });
  }

  setScene(s) {
    this.scene = s;
  }

  getScene() {
    return this.scene;
  }

  visible = true;

  allowRotate = true;

  userData = {};

  drawArrows() { }

  updateArrows() { }

  updateHighlight() { }

  mouseOver() { }

  mouseOff() { }

  moveToPosition() { }

  switchWireframe() { }

  clickReleased() { }

  customIntersectionPlanes() { }

  objectHalfSize() { }

  getProportionalResize() { }

  getMetaData() { }

  removed() { }

  setSelected() {
    selUtils.setSelected.call(this);
  }

  setUnselected() {
    selUtils.setUnselected.call(this);
  }

  raycast(...rest) {
    genericRaycast.call(this, ...rest);
  }

  addCube() {
    addCube.call(this);
  }

  /**
   * Utility function that updates cube and reselects cabinet if it was selected
   */
  refreshHighlightAndCube() {
    const isSelected = this._cube && this._cube.highlighted;
    // isSelected && this.setUnselected();
    this.addCube();
    // isSelected && this.setSelected();
  }

  getHeight() {
    return this.size.height;
  }

  getWidth() {
    return this.size.width;
  }

  getDepth() {
    return this.size.depth;
  }

  setFixed( /** @type { boolean } */ fixed) {
    this.fixed = fixed;
  }


  //remove() { }
}
