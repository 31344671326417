import { simpleSet } from '@r/reducers/utils';
import { inches } from '@npmrost/utils';

const setCalculationModeTopDrawerHeightValue = simpleSet as
  redux.reducers.calculationMode.projects.currentProject.settings.dimensions.topDrawerHeight.value;

export const value = setCalculationModeTopDrawerHeightValue(
  'SET_CALCULATION_MODE_CURRENT_PROJECT_TOP_DRAWER_HEIGHT_VALUE',
  0 as inches
);
