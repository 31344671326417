import { simpleSet } from '@r/reducers/utils';

export const cabinetsLabourUnit = simpleSet(
  'SET_CALCULATION_MODE_ADMIN_SETTINGS_CABINETS_LABOUR_UNIT',
  {
    'Blind Corner Base': 0,
    Base: 0,
    'Blind Corner Tall': 0,
    Upper: 0,
    Tall: 0,
    Corner: 0
  }
);
