import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { value } from './value';
import { options } from './options';

const percentageLossComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['settings']['information']['percentageLoss'],
    AllActions
  >;

export const percentageLoss = percentageLossComb( {
  value,
  options
} );

export function setDefaultPercentageLoss() {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        adminSettings: {
          projectSettings: {
            percentageLoss: { data }
          }
        }
      }
    } = getStore();

    const options = data.map( ( { name, value: { value } } ) => ( {
      label: name,
      value
    } ) );

    dispatch( {
      type: 'SET_CALCULATION_MODE_PROJECT_PERCENTAGE_LOSS_OPTIONS',
      value: options
    } );

    dispatch( {
      type: 'SET_CALCULATION_MODE_PROJECT_PERCENTAGE_LOSS_VALUE',
      value: options[ 0 ] || {}
    } );
  };
}
