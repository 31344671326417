import React from 'react';
import styled from 'styled-components';
import {
  _IProject3d, renameProject, duplicateProject, deleteProject
} from '@r/reducers/projectsFor3d';
// import Delete from './Delete';
// import Duplicate from './Duplicate';
// import EditTags from './EditTags';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { HoverableImage } from './utils';

function dblClick( e: React.MouseEvent<HTMLDivElement, MouseEvent> ) {
  e.stopPropagation();
}

function click( e: React.MouseEvent<HTMLDivElement, MouseEvent> ) {
  e.stopPropagation();
}

const ProjectMenuStyled = styled.div`
  display: none;
  position: absolute;
  width: calc(100% / 6);
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.39);
  flex-direction: column;
`;

interface _IProps {
  id: _IProject3d['_id'];
  projectTag: _IProject3d['projectTag'];
  dispatch: ThunkDispatch<redux._IStore, {}, never>;
}

export const ProjectMenu = connect()(
  class ProjectMenu extends React.PureComponent< _IProps > {
    rename = () => {
      // eslint-disable-next-line no-alert
      const rez = prompt( 'Enter new name.' );
      if( !rez ) return;

      this.props.dispatch( renameProject( this.props.id, rez ) );
    }

    duplicate = () => {
      // eslint-disable-next-line no-alert
      const rez = prompt( 'Enter duplicate name.', '' );
      if( !rez ) return;

      this.props.dispatch( duplicateProject( this.props.id, rez ) );
    }

    delete = () => {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const rez = confirm( 'Remove this project?' );
      if( !rez ) return;

      this.props.dispatch( deleteProject( this.props.id ) );
    }

    render() {
      const {
        rename,
        duplicate,
        delete: DeleteProj
      } = this;

      return(
        <ProjectMenuStyled className='projectMenu' onClick={ click } onDoubleClick={ dblClick }>
          {/* <EditTags { ...{ projectTag, id } } /> */}
          <HoverableImage { ...{
            hoveredImg: '/img/renameGradient.svg',
            img: '/img/rename.svg',
            elementForHover: <span style={ { color: '#E5E5E5' } }> Rename </span>,
            onClick: rename
          } }
          />
          <HoverableImage { ...{
            hoveredImg: '/img/renameGradient.svg',
            img: '/img/rename.svg',
            elementForHover: <span style={ { color: '#E5E5E5' } }> Rename </span>,
            onClick: rename
          } }
          />
          <HoverableImage { ...{
            hoveredImg: '/img/copyGradient.svg',
            img: '/img/copy.svg',
            elementForHover: <span style={ { color: '#E5E5E5' } }> Duplicate </span>,
            onClick: duplicate
          } }
          />
          <HoverableImage { ...{
            hoveredImg: '/img/deleteGradient.svg',
            img: '/img/deleteWhite.svg',
            elementForHover: <span style={ { color: '#E5E5E5' } }> Delete </span>,
            onClick: DeleteProj
          } }
          />
          {/* <Duplicate id={ id } />
          <Delete id={ id } /> */}
        </ProjectMenuStyled>
      );
    }
  }
);
