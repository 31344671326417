import {Step} from "./BaseCmd";
import DraftCmd from "./DraftCmd";
import * as THREE from 'three';
import {createCurveEntity} from '../entity/CurveEntityEx';
import DoAddRemoveEntity from "../UndoRedo/DoAddRemoveEntity";
import { SnapFlag } from "../viewer/Viewport";

class LineCmd extends DraftCmd {
    draftEntity = null;

    doActivate() {
        super.doActivate();
        this.setCursor('pencil', true);
    }
    initCmd() {
        super.initCmd();
        this.draftEntity = null;
    }
    doEscape() {
        if (this.draftEntity) {
            this.draftEntity.dispose();
            this.updateRender();
        }
        super.doEscape();
    }
    onMouseMove(e) {
        super.onMouseMove(e);

        const pos = this.tracker.trackPos2d(true);
        if (!pos) return;

        switch(this.step) {
            case Step.step1: {
                this.draftEntity.curve.v2.copy(pos);
                this.draftEntity.updateEntity();

                this.updateRender();
                break;
            }
        }
    }
    onMouseClick(e) {
        super.onMouseClick(e);

        const pos = this.tracker.trackPos2d(true);
        if (!pos) return;

        switch(this.step) {
            case Step.step0: {
                this.draftEntity = createCurveEntity(new THREE.LineCurve(pos.clone(), pos.clone()));
                this.entityMgr.draftG.add(this.draftEntity);

                this.step = Step.step1;
                break;
            }
            case Step.step1: {
                const curve = this.draftEntity.curve;
                if (!this.equalsPoint(curve.v1, curve.v2)) {
                    // end with undo/redo
                    const doaction = new DoAddRemoveEntity(this.draftEntity, true, this.entityMgr.curveG);
                    this.doMgr.registerDo(doaction);
                    doaction.redo();

                    this.initCmd();
                    if (this.continueable()) this.onMouseClick(e);
                }
                break;
            }
        }
    }
    continueable() {
        const snp = this.tracker.osnap; // current snap
        return !snp || snp.sflag != SnapFlag.endVt;
    }
}

export default LineCmd;