import React from 'react';
import { connect } from 'react-redux';
import ViewMenu from './ViewMenu';
import FileMenu from './FileMenu';
import WithIcon from './WithIcon';
import style from './index.styl';

const Chevron = () => (
  <img
    style={ { marginLeft: 10 } }
    src='/img/ChevronDown.svg'
    alt=''
  />
);

class CornerMenu extends React.PureComponent {
  static defaultProps = {
    tabs: [
      { icon: '/img/File.svg', hasChevron: false },
      { icon: '/img/View.svg', hasChevron: true }
    ]
  }

  constructor( p ) {
    super( p );
    this.state = {
      chosen: 'File...',
      open: false
    };
  }

  componentDidMount() {
    document.addEventListener( 'click', this.clickOutside );
  }

  componentWillUnmount() {
    document.removeEventListener( 'click', this.clickOutside );
  }

  clickOutside = ( /** @type { MouseEvent } */e ) => {
    const rootDiv = document.querySelector( `.${ style.cornerMenu }` );
    if( rootDiv === null ) return;

    if( rootDiv.contains( e.target ) || !this.state.open ) {
      return;
    }

    this.setState( { open: false } );
  }

  tabClick = ( tab ) => () => {
    this.setState( ( prevState ) => {
      return {
        chosen: tab,
        open: prevState.chosen === tab ? !prevState.open : true
      };
    } );
  }

  hideMenu = () => {
    this.setState( { open: false } );
  }

  render() {
    const {
      state: { chosen, open },
      props: {
        tabs: [fileMenu, viewMenu],
        fileTitle,
        viewTitle
      },
      tabClick,
      hideMenu
    } = this;

    return (
      <div className={ style.cornerMenu }>
        <div className='tabs'>
          <div className='tab' onClick={ tabClick( 'File...' ) }>
            <WithIcon img={ fileMenu.icon }>
              <Chevron />
            </WithIcon>
            { fileTitle }
          </div>
          <div className='tab' onClick={ tabClick( 'View' ) }>
            <WithIcon img={ viewMenu.icon }>
              <Chevron />
            </WithIcon>
            { viewTitle }
          </div>
        </div>
        <ViewMenu hidden={ !open || chosen !== 'View' } />
        <FileMenu
          hidden={ !open || chosen !== 'File...' }
          hideMenu={ hideMenu }
        />
      </div>
    );
  }
}

export default connect(
  ( {
    texts: {
      cornerMenu: {
        file: {
          title: fileTitle
        },
        view: {
          title: viewTitle
        }
      }
    }
  } ) => {
    return {
      fileTitle,
      viewTitle
    };
  }
)( CornerMenu );
