import * as THREE from 'three';
import { CurveDefaultMaterial } from './entity';
import {CurveUtil} from '../common';

class CurveEntity extends THREE.Line {
    constructor(geometry, material) {
        super(geometry, material);
    }

    get curve() {
        return this.userData.curve;
    }
    set curve(value) {
        this.userData.curve = value;
    }

    updateEntity() {
        this.geometry.dispose();
        this.geometry = createCurveGeometry(this.curve);
        this.computeLineDistances();
    }

    dispose() {
        this.geometry.dispose();
        
        const parent = this.parent;
        if (parent) parent.remove(this);
    }
}

// utils
const createCurveGeometry = (() => {
    const point = new THREE.Vector2();
    return (curve) => {
        const geometry = new THREE.BufferGeometry();

        const divisions = CurveUtil.curveDivisions(curve);
        const posarray = (divisions + 1) * 3; // posarray : [x1,y1,z1,x2,y2,z2,...] or length of array.
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(posarray, 3));

        const positions = geometry.getAttribute('position');
        for (let i = 0; i <= divisions; i++) {
            const t = i / divisions;
            curve.getPoint(t, point);
            positions.setXYZ(i, point.x, point.y, 0);
        }
        // positions.needsUpdate = true;

        // fix to hit-test correctly after applying geometry
        geometry.computeBoundingBox();
        geometry.computeBoundingSphere();

        return geometry;
    }
})();
function createCurveEntity(curve, material = CurveDefaultMaterial) {
    const geometry = createCurveGeometry(curve);
    const entity = new CurveEntity(geometry, material);
    
    entity.computeLineDistances();
    entity.curve = curve;

    return entity;
}

export {createCurveEntity};