// @ts-check
/* eslint-disable @typescript-eslint/ban-ts-ignore */

// eslint-disable-next-line import/no-cycle
import Storage from 'scr/utilitiesStorage';
// eslint-disable-next-line import/no-cycle
import SnappingDetector from 'c/ThreeJsWrap/Viewer/SnappingDetector';


/** @this { InstanceType< VestaObject3D<THREE.Object3D> > } */
function updateSizesOnResize(
  /** @type { 'width' | 'depth' | 'height' } */ metric,
  /** @type { import('@npmrost/utils/lib').inches } */val
) {
  let sizes = this.getSizes();
  sizes[ metric ] = val;

  const { width, depth, height } = sizes;
  this.setSizes( height, width, depth );
}

/**
 * @template Parent
 */
class VestaObject3D {
  static updateSizesOnResize = updateSizesOnResize;

  constructor(
    /** @type { Parent } */parent
  ) {
    this._setParent( parent );
  }

  /** @type { import('decl/ThreeScene').Object3dTypes } */
  type = 'VestaObject3D';

  getType() {
    return this.type;
  }

  setType( /** @type { this['type'] } */ t ) {
    this.type = t;
  }

  /**
   * Parent is effectively object3d that has instance of VestaObject3d
   * as its property (it is composition of objects so that we can bind
   * some useful interfaces without changing parent class)
   */
  /** @type { Parent } */
  // @ts-ignore
  _parent;

  /** @type { (p: this['_parent']) => void } */
  _setParent( p ) {
    this._parent = p;
  }

  /** @type { () => this['_parent'] } */
  getParent() {
    return this._parent;
  }

  /** @type { string } */
  _name = '';

  getName() {
    return this._name;
  }

  setName( /** @type { string } */n ) {
    this._name = n;
  }

  /** @type { any } */
  _materialName;

  getMaterialName() {
    return this._materialName;
  }

  getMaterialNameOfPart(/** @type { any } */part) {
    return part;
  }

  setMaterialByNameDirty( /** @type { any } */m ) {
    this._materialName = m;
  }

  setMaterialByName( /** @type { any } */m ) {
    this.setMaterialByNameDirty( m );
    Storage.get('viewer').cubeCamera.flag = true;
  }

  changeWidth( /** @type { import('@npmrost/utils/lib').inches } */ _w ) { /** */ }

  changeDepth( /** @type { import('@npmrost/utils/lib').inches } */ _d ) { /** */ }

  changeHeight( /** @type { import('@npmrost/utils/lib').inches } */ _h ) { /** */ }

  /**
   * @type {{
   *  width: import('@npmrost/utils/lib').inches;
   *  depth: import('@npmrost/utils/lib').inches;
   *  height: import('@npmrost/utils/lib').inches;
   * }}
   */
  _sizes = {
    width: /** @type { import('@npmrost/utils/lib').inches } */ ( 0 ),
    depth: /** @type { import('@npmrost/utils/lib').inches } */ ( 0 ),
    height: /** @type { import('@npmrost/utils/lib').inches } */ ( 0 )
  }

  setSizes(
    /** @type { this['_sizes']['height'] } */height,
    /** @type { this['_sizes']['height'] } */width,
    /** @type { this['_sizes']['height'] } */depth
  ) {
    this._sizes = {
      height, width, depth
    };
  }

  getSizes() {
    return this._sizes;
  }

  updateSnapping(
    /** @type { import('scr/utilitiesStorage').default['hash']['snappingTolerance'] } */
    tolerance = Storage.get( 'snappingTolerance' ),
    iObjects = undefined
  ) {
    const scene = Storage.get( 'viewer' ).scene;
    const snappingDetector = new SnappingDetector( scene, tolerance );
    return snappingDetector.checkSnappingWithObject( this.getParent(), iObjects );
  }
}

export default VestaObject3D;
