/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable require-jsdoc */
/* eslint-disable func-style */
// @ts-check
import Storage from 'scr/utilitiesStorage';
import * as cabinets from '../cabinets';
import generateCabinet from './generateCabinet';
// @ts-ignore
import removeExcessiveFromConfig
  from '../cabinets/helpers/removeExcessiveFromConfig';

/** @type { CabD.A.ContainerConfigs.CabinetConfigsHash } */
const cabinetsHash = cabinets;

export function createCabinetFromConfig(/** @type { Partial<CabD.A.ContainerConfigs.ContainerConfig> } */ config) {
  // Prettier-ignore
  const cab = /** @type { CabD.A.GenericCabinet } */(generateCabinet( config ));
  // @ts-ignore
  cab.setConfig( removeExcessiveFromConfig( { config } ) );
  cab.setMountTypes( config.mountTypes || ['floor'] );

  if ( config.cabinetMaterialName ) {
    cab.vestaObject.setMaterialByName( { cabinet: config.cabinetMaterialName } );
  } else {
    cab.vestaObject.setMaterialByName( { cabinet: Storage.get( 'defaultMaterials' ).cabinetMaterial } );
  }
  if ( config.constrName === 'WithCountertop' ) {
    // @ts-ignore
    cab.countertop.vestaObject.setMaterialByName( {
      countertop: config.countertopMaterialName
    } );
  }

  if ( config.doorMaterialName ) {
    cab.vestaObject.setMaterialByName( { door: config.doorMaterialName } );
  } else {
    cab.vestaObject.setMaterialByName( { door: Storage.get( 'defaultMaterials' ).doorMaterial } );
  }

  if (config.glassMaterialName) {
    cab.vestaObject.setMaterialByName({glass: config.glassMaterialName});
  } else {
    cab.vestaObject.setMaterialByName({glass: 'white'});
  }

  return cab;
}

/**
 * @typedef {{
 *  config: Partial<CabD.A.ContainerConfigs.ContainerConfig>;
 *  constr: CabD.A.GenericCabinet['_constr'];
 *  itemName: string;
 *  cabinetSettings: { texture: string; }
 * }} cabinetJson
 */

export function getCabinetFromConfig(
  /** @type { cabinetJson } */
  {
    constr, itemName, cabinetSettings, config
  }
) {
  const cab = /** @type { CabD.A.GenericCabinet } */ createCabinetFromConfig(config);
  
  if ( cab.mountTypes[ 0 ] === 'floor' ) {
    cab.rotateX( Math.PI / 2 );
    cab.initialRotation = Math.PI / 2;
  } else {
    cab.origin.rotation.set( 0, 0, 0 );
  }
  
  return cab;
}


/**
 * @typedef {{
 *  constr: cabinetJson['constr'];
 *  itemName?: cabinetJson['itemName'];
 *  cabinetSettings?: cabinetJson['cabinetSettings'];
 * }} getCabinetArg
 */

export default function getCabinet( /** @type { getCabinetArg } */p ) {
  const { constr, itemName = '', cabinetSettings = { texture: '' } } = p;
  if ( constr === null ) {
    throw new Error( 'Cabinet constructor is null' );
  }

  const config = cabinetsHash[ constr ];
  const cabinet = getCabinetFromConfig( {
    constr,
    itemName,
    cabinetSettings,
    config
  } );

  cabinet.reloadConfig = function reloadConfig(
    /** @type { CabD.A.ContainerConfigs.ContainerConfig } */ conf
  ) {
    if ( this.parent === null ) return this;
    if ( conf === null ) return this;

    const newCab = getCabinetFromConfig( {
      config: conf,
      constr: this.getConstr(),
      cabinetSettings: { texture: '' },
      itemName: this.getMetadataName()
    } );

    newCab.position.copy( this.position );
    newCab.reloadConfig = this.reloadConfig;

    this.parent.add( newCab );
    this.parent.remove( this );

    return newCab;
  };


  return cabinet;
}
