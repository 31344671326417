import feathersClient from 'scr/getFeathers';

export async function getAdminSettings() {
  // eslint-disable-next-line no-useless-catch
  try {
    const data = ( await feathersClient.service( 'api/adminSettings' ).find( {
      query: {
        $limit: 1
      }
    } ) ).data[ 0 ];

    return data;
  } catch ( error ) {
    throw error;
  }
}

export async function createAdminSettings( data ) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await feathersClient
      .service( 'api/adminSettings' )
      .create( data );

    return response;
  } catch ( error ) {
    throw error;
  }
}

export async function patchAdminSettings( data ) {
  // eslint-disable-next-line no-useless-catch
  try {
    const { _id } = await getAdminSettings();

    const response = await feathersClient
      .service( 'api/adminSettings' )
      .patch( _id, data );

    return response;
  } catch ( error ) {
    throw error;
  }
}
