export type feathersAccessToken = string;

export interface _IUsersService {
  email: string;
  fullName: string;
}

export interface _IServiceObject<T> {
  get: ( id: string ) => Promise<T>;
}

export type feathersService<T> = ( name: string ) => _IServiceObject<T>;


export class feathers {
  authenticate( options: _IJwtOptions | _ILocalOptions ):
    Promise<{ accessToken: string }>;

  passport: {
    verifyJWT( token: feathersAccessToken ): Promise<{ userId: string }>;
  }

  service: feathersService<any>
}

export interface _IJwtOptions {
  strategy: 'jwt';
}

export interface _ILocalOptions {
  strategy: 'local';
  email: string;
  password: string;
}
