// @ts-check
import React from 'react';
import { connect } from 'react-redux';
import { unitsLabel } from 'scr/metricsConversion';
import SimpleSelect from 'c/CalculationLayer/utils/SimpleSelect';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import modalStyl from 'c/CalculationLayer/Modal/index.styl';

/** @type {Array<{ value: redux.store['projectFor3d']['units']; label: string; }>} */
export const unitsMapping = [
  { value: 'ftAndInch', label: unitsLabel.ftAndInch },
  { value: 'inch', label: unitsLabel.inch },
  { value: 'cm', label: unitsLabel.cm },
  { value: 'mm', label: unitsLabel.mm },
  { value: 'm', label: unitsLabel.m }
];

/**
 * @typedef {{
 *  units: redux.store['projectFor3d']['units'];
 *  mapping: typeof import('scr/metricsConversion').unitsLabel;
 *  dispatch: redux.d<redux.actions['setUnits']>;
 * }} Props
 */

/**
 * @augments { React.PureComponent<Props> }
 */
class Units extends React.PureComponent {
  static defaultProps = {
    mapping: unitsMapping
  }

  chooseUnit = (
    /** @type { Props['units'] } */value
  ) => this.props.dispatch( {
    type: 'SET_UNITS',
    value,
    prev: this.props.units
  } );

  render() {
    const { units, mapping } = this.props;

    return (
      <LabeledField
        className={ `${ modalStyl.commonLabeledField } shortLabel wider fontSize14px` }
        name='Units'
      >
        <SimpleSelect
          value={ units }
          onChange={ this.chooseUnit }
          className={ `${ modalStyl.commonInput } wide` }
          options={ mapping }
        />
      </LabeledField>
    );
  }
}

export default connect(
  ( /** @type { redux.store } */s ) => ( { units: s.projectFor3d.units } )
)( /** @type { React.ComponentClass } */ ( /** @type { unknown } */ ( Units ) ) );
