/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/named
import {
  Object3dTypes,
  loaderMaterials,
  _IObject3D,
  crownMoldings
} from 'decl/ThreeScene';
import { inches } from 'decl/general/units';
import Viewer from 'c/ThreeJsWrap/Viewer/objects/Viewer';

type defaultDimensions = {
  wallHeight: number;
  wallDepth: number;
  roomWidth: number;
  roomDepth: number;
  floorPlan: number;
}

type defaultCabinetsSettings = {
  defaultMaterial: string;
  defaultShape: string;
  isPresented: boolean;
  base: object;
  upper: object;
  tall: object;
}

type defaultMaterials = {
  walls: string;
  floor: string;
}

type defaultInformation = {
  units: string;
  snapSensitivity: number;
}

type defaultRightSideMenuParameters = {
  isPresented: boolean;
}

interface _IHash {
  viewer: Viewer;

  selectedObject: _IObject3D[] |
    CabD.A.GenericCabinet[] | null[];

  namesToMaterials: {
    [key in Object3dTypes]: {
      [s: string]: THREE.MeshPhysicalMaterial;
    }
  };

  namesToShapes: {
    [key in Object3dTypes]: {
      [s: string]: THREE.MeshPhysicalMaterial;
    }
  };

  assets: any;
  materials: loaderMaterials;
  snappingTolerance: inches;
  shapes: any;
  crownMoldings: crownMoldings;
  dimensions: defaultDimensions;
  cabinetsSettings: defaultCabinetsSettings;
  defaultMaterials: defaultMaterials;
  information: defaultInformation;
  rightSideMenuParameters: defaultRightSideMenuParameters;
}

export class UtilitiesStorage {
  hash: Partial<_IHash> = {
    selectedObject: []
  }

  get<T extends keyof _IHash>( key: T ): _IHash[T] {
    return this.hash[ key ];
  }

  set<T extends keyof _IHash> ( key: T, value: _IHash[T] ) {
    this.hash[ key ] = value;
  }
}

const Storage = new UtilitiesStorage();

export function isNullable( arg: _IHash['selectedObject'] ): arg is null[] {
  return Boolean( !arg || arg[ 0 ] === null );
}
export default Storage;
