// @ts-check
import { simpleSet } from '@r/reducers/utils';
import { _ISimpleSet, AllActions } from '@r/storeUtils';

const s = simpleSet as _ISimpleSet<
  redux._IConstants['SET_FLOOR'],
  redux._IStore['projectFor3d']['floor'],
  AllActions
>;
export const floor = s( 'SET_FLOOR', { value: '', baseMap: '', label: '' } );
