// @ts-check
import React from 'react';
import { connect } from 'react-redux';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import modalStyl from 'c/CalculationLayer/Modal/index.styl';
import { UnitsInputv2 } from 'c/CalculationLayer/utils/UnitsInputv2';
import { unitMapping } from 'scr/metricsConversion';
import UnitsInputWithUnits from 'c/CalculationLayer/utils/UnitsInputWithUnits';
import { batchActions } from 'redux-batched-actions';

const b = /**
  @type {
    redux.batchActionsWithTempl<
      redux.actions['setWHeightLabel'] |
      redux.actions['setWHeightValue']
    >
  }
*/( batchActions );
/*
 * Const WallThickness = connectUnitInput(
 *   s => ({ name: "Wall thickness", value: s.wThickness, units: s.units }),
 *   mTC.SET_W_THICKNESS
 * );
 * ==========================================================================
 * const SettingsWallHeight = connect(
 *   s => ({ name: "Wall height", value: s.wHeight, units: s.units }),
 *   d => ({
 *     onChange(v, units) {
 *       d(simpleActionCreator(mTC.SET_W_HEIGHT)(v));
 *       updateHeightThickness("height", v, units);
 *     }
 *   })
 * )(UnitsInput);
 * const SettingsWallThickness = connect(
 *   s => ({ name: "Wall thickness", value: s.wThickness, units: s.units }),
 *   d => ({
 *     onChange(v, units) {
 *       d(simpleActionCreator(mTC.SET_W_THICKNESS)(v));
 *       updateHeightThickness("thickness", v, units);
 *     }
 *   })
 * )(UnitsInput);
 */

/**
 * @typedef {{
 *   units: redux.store['projectFor3d']['units'];
 *   wHeight: redux.store['projectFor3d']['wHeight'];
 *   dispatch: redux.d<import('redux-batched-actions').BatchAction>
 * }} MetricsLeftProps
 */
export const Height = connect(
  ( /** @type { redux.store } */{
    projectFor3d: {
      wHeight, units, wDepth
    }
  } ) => ( { wHeight, units, wDepth } )
)(
  /** @augments { React.PureComponent<MetricsLeftProps> } */
  class extends React.PureComponent {
    setHeight = ( /** @type { UnitsInputv2Value } */value ) => {
      const { units, dispatch } = this.props;

      const val = units === 'ftAndInch'
        ? unitMapping.ftAndInch( value )
        : unitMapping[ units ]( Number( value ) );

      dispatch(
        b( [
          { type: 'SET_W_HEIGHT_LABEL', value },
          { type: 'SET_W_HEIGHT_VALUE', value: val }
        ] )
      );
    };

    setDepth = ( /** @type { UnitsInputv2Value } */value ) => {
      const { units, dispatch } = this.props;

      const val = units === 'ftAndInch'
        ? unitMapping.ftAndInch( value )
        : unitMapping[ units ]( Number( value ) );

      dispatch(
        b( [
          { type: 'SET_W_DEPTH_LABEL', value },
          { type: 'SET_W_DEPTH_VALUE', value: val }
        ] )
      );
    }

    render() {
      const {
        setDepth,
        setHeight,
        props: { wHeight, units, wDepth }
      } = this;

      return (
        <>
          <LabeledField
            className={ `${ modalStyl.commonLabeledField } shortLabel wider fontSize14px` }
            name='Wall height'
          >
            <UnitsInputWithUnits units={ units }>
              <UnitsInputv2
                className={ /** @type { className<'input'>} */ ( `${ modalStyl.commonInput } wide` ) }
                value={ wHeight.label }
                onChange={ setHeight }
                units={ units }
              />
            </UnitsInputWithUnits>

          </LabeledField>
          <LabeledField
            className={ `${ modalStyl.commonLabeledField } shortLabel wider fontSize14px` }
            name='Wall depth'
          >
            <UnitsInputWithUnits units={ units }>
              <UnitsInputv2
                className={ /** @type { className<'input'>} */ ( `${ modalStyl.commonInput } wide` ) }
                value={ wDepth.label }
                onChange={ setDepth }
                units={ units }
              />
            </UnitsInputWithUnits>

          </LabeledField>
        </>
      );
    }
  }
);
/**
 * @typedef { redux.actions['setFullFloorplanDepthLabel']['type'] |
 *  redux.actions['setFullFloorplanWidthLabel']['type'] |
 *  redux.actions['setLShapeFloorplanDepthLabel']['type'] |
 *  redux.actions['setLShapeFloorplanWidthLabel']['type'] |
 *  redux.actions['setSingleFloorplanWidthLabel']['type'] |
 *  redux.actions['setUSHapeFloorplanDepth2Label']['type'] |
 *  redux.actions['setUSHapeFloorplanDepthLabel']['type'] |
 *  redux.actions['setUShapeFloorplanWidthLabel']['type']
 * } WidthDepthConstantsForLabel
 */

/**
  * @typedef { redux.actions['setFullFloorplanDepthValue']['type'] |
  *   redux.actions['setFullFloorplanWidthValue']['type'] |
  *   redux.actions['setLShapeFloorplanDepthValue']['type'] |
  *   redux.actions['setLShapeFloorplanWidthValue']['type'] |
  *   redux.actions['setSingleFloorplanWidthValue']['type'] |
  *   redux.actions['setUSHapeFloorplanDepth2Value']['type'] |
  *   redux.actions['setUSHapeFloorplanDepthValue']['type'] |
  *   redux.actions['setUShapeFloorplanWidthValue']['type']
  * } WidthDepthConstantsForValue
  */

const batchForWidthDepth = /**
  @type { redux.batchActionsWithTempl<
    {
      type: WidthDepthConstantsForLabel,
      value: UnitsInputv2Value
    }
    |
    {
      type: WidthDepthConstantsForValue,
      value: inches
    }
  > }
*/( batchActions );

function constructMetricField(
  /** @type { string } */name,
  /** @type { redux.store['projectFor3d']['units'] } */units,
  /** @type { UnitsInputv2Value } */ value,
  /** @type { (val: UnitsInputv2Value ) => void } */onChange
) {
  return (
    <LabeledField
      className={ `${ modalStyl.commonLabeledField } shortLabel wider fontSize14px` }
      name={ name }
    >
      <UnitsInputWithUnits units={ units }>
        <UnitsInputv2
          units={ units }
          className={
            /** @type { className<'input'>} */
            ( `${ modalStyl.commonInput } wide` )
          }
          value={ value }
          onChange={ onChange }
        />
      </UnitsInputWithUnits>

    </LabeledField>
  );
}


/**
 * @typedef {{
 *  units: redux.store['projectFor3d']['units'] ;
 *  roomWidthDepth: redux.store['projectFor3d']['roomWidthDepth'];
 *  roomTemplate: redux.store['projectFor3d']['roomTemplate'];
 *  dispatch: redux.d<import('redux-batched-actions').BatchAction>;
 * }} MetricsRightProps
 */
export const WidthAndDepth = connect(
  ( /** @type { redux.store } */{
    projectFor3d: { units, roomTemplate, roomWidthDepth }
  } ) => ( {
    roomWidthDepth,
    units,
    roomTemplate
  } )
)(
  /** @augments { React.PureComponent<MetricsRightProps> } */
  class extends React.PureComponent {
    dispAction = (
      /** @type { WidthDepthConstantsForLabel } */ actionForLabel,
      /** @type { WidthDepthConstantsForValue } */ actionForValue,
      /** @type { UnitsInputv2Value } */ value
    ) => {
      const { units, dispatch } = this.props;

      const val = units === 'ftAndInch'
        ? unitMapping.ftAndInch( value )
        : unitMapping[ units ]( Number( value ) );

      dispatch(
        batchForWidthDepth( [
          { type: actionForLabel, value },
          { type: actionForValue, value: val }
        ] )
      );
    };

    /**
     * @type {(
     *    actionForLabel: WidthDepthConstantsForLabel,
     *    actionForValue: WidthDepthConstantsForValue
     *  ) => (v: UnitsInputv2Value) => void
     * }
     */
    setFloorplanMetric = ( actionForLabel, actionForValue ) => (
      value
    ) => {
      this.dispAction( actionForLabel, actionForValue, value );
    }

    render() {
      const {
        props: {
          roomTemplate,
          units,
          roomWidthDepth
        },
        setFloorplanMetric
      } = this;


      switch( roomTemplate.value ) {
        case 'single': {
          const {
            single: { width: { label } }
          } = roomWidthDepth;

          return constructMetricField( 'Single wall length', units,
            label, setFloorplanMetric(
              'SET_SINGLE_FLOORPLAN_WIDTH_LABEL',
              'SET_SINGLE_FLOORPLAN_WIDTH_VALUE'
            )
          );
        }
        case 'U-shape': {
          const {
            'U-shape': {
              width: { label: wLabel },
              depth: { label: dLabel },
              depth2: { label: d2Label }
            }
          } = roomWidthDepth;

          return (
            <>
              {
                constructMetricField( 'Left wall length', units,
                  dLabel, setFloorplanMetric(
                    'SET_U_SHAPE_FLOORPLAN_DEPTH_LABEL',
                    'SET_U_SHAPE_FLOORPLAN_DEPTH_VALUE'
                  )
                )
              }
              {
                constructMetricField( 'Central wall length', units,
                  wLabel, setFloorplanMetric(
                    'SET_U_SHAPE_FLOORPLAN_WIDTH_LABEL',
                    'SET_U_SHAPE_FLOORPLAN_WIDTH_VALUE'
                  )
                )
              }
              {
                constructMetricField( 'Right wall length', units,
                  d2Label, setFloorplanMetric(
                    'SET_U_SHAPE_FLOORPLAN_DEPTH2_LABEL',
                    'SET_U_SHAPE_FLOORPLAN_DEPTH2_VALUE'
                  )
                )
              }
            </>
          );
        }
        case 'L-shape': {
          const {
            'L-shape': {
              width: { label: wLabel },
              depth: { label: dLabel }
            }
          } = roomWidthDepth;

          return (
            <>
              {
                constructMetricField( 'Left wall length', units,
                  dLabel, setFloorplanMetric(
                    'SET_L_SHAPE_FLOORPLAN_DEPTH_LABEL',
                    'SET_L_SHAPE_FLOORPLAN_DEPTH_VALUE'
                  )
                )
              }
              {
                constructMetricField( 'Central wall length', units,
                  wLabel, setFloorplanMetric(
                    'SET_L_SHAPE_FLOORPLAN_WIDTH_LABEL',
                    'SET_L_SHAPE_FLOORPLAN_WIDTH_VALUE'
                  )
                )
              }
            </>
          );
        }
        default: {
          const {
            full: {
              width: { label: wLabel },
              depth: { label: dLabel }
            }
          } = roomWidthDepth;

          return (
            <>
              {
                constructMetricField( 'Room width', units,
                  wLabel, setFloorplanMetric(
                    'SET_FULL_FLOORPLAN_WIDTH_LABEL',
                    'SET_FULL_FLOORPLAN_WIDTH_VALUE'
                  )
                )
              }
              {
                constructMetricField( 'Room depth', units,
                  dLabel, setFloorplanMetric(
                    'SET_FULL_FLOORPLAN_DEPTH_LABEL',
                    'SET_FULL_FLOORPLAN_DEPTH_VALUE'
                  )
                )
              }
            </>
          );
        }
      }
    }
  }
);
