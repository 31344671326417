/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable line-comment-position */
/* eslint-disable no-warning-comments */
/* eslint-disable no-bitwise */
// 1 @ts-check

/**
 * @typedef { Object } ContainerPresetsLayout
 * @property { "WIDTH" } WIDTH container width layout constant (from left to right)
 * @property { "HEIGHT" } HEIGHT container height layout constant (from bottom to top)
 * @property { "DEPTH" } DEPTH container depth layout constant (from  user POW forward)
 */
/** @type { CabD.A.ContainerPresetsLayout }  */
const LAYOUT = {
  WIDTH: 'WIDTH',
  HEIGHT: 'HEIGHT',
  DEPTH: 'DEPTH'
};

/**
 * @typedef { Object } ContainerPresetsPivotEntry
 * @property { string } name
 * @property { string } type
 */
/**
 * @typedef { Object } ContainerPresetsPivot
 * @property { ContainerPresetsPivotEntry } top
 * @property { ContainerPresetsPivotEntry } h_center
 * @property { ContainerPresetsPivotEntry } bottom
 * @property { ContainerPresetsPivotEntry } left
 * @property { ContainerPresetsPivotEntry } w_center
 * @property { ContainerPresetsPivotEntry } right
 * @property { ContainerPresetsPivotEntry } front
 * @property { ContainerPresetsPivotEntry } d_center
 * @property { ContainerPresetsPivotEntry } back
 *
 */
/** @type { CabD.A.ContainerPresetsPivot }  */
const PIVOT = {
  top: {
    name: 'TOP',
    type: 'height'
  },
  h_center: {
    name: 'HEIGHT_CENTER',
    type: 'height'
  },
  bottom: {
    name: 'BOTTOM',
    type: 'height'
  },

  left: {
    name: 'LEFT',
    type: 'width'
  },

  w_center: {
    name: 'WIDTH_CENTER',
    type: 'width'
  },

  right: {
    name: 'RIGHT',
    type: 'width'
  },

  front: {
    name: 'FRONT',
    type: 'depth'
  },

  d_center: {
    name: 'DEPTH_CENTER',
    type: 'depth'
  },

  back: {
    name: 'BACK',
    type: 'depth'
  }
};
/**
 * @typedef { Object } ContainerPresets
 * @property { ContainerPresetsLayout } LAYOUT container layouts constants
 * @property { ContainerPresetsPivot } PIVOT container layouts constants
 * @property { Object } POSITION_CONSTRAINT
 */
/** @type { CabD.A.ContainerPresets } */
const PRESETS = {
  POSITION_CONSTRAINT: {
    ABSOLUTE: 'ABSOLUTE',
    ALLIGN_CENTER: 'ALLIGN_CENTER',
    ALLIGN_LEFT: 'ALLIGN_LEFT',
    ALLIGN_RIGHT: 'ALLIGN_RIGHT',
    ALLIGN_TOP: 'ALLIGN_TOP',
    ALLIGN_BOTTOM: 'ALLIGN_BOTTOM'
  },
  PIVOT,
  LAYOUT
};
/**
 * @class
 * @augments THREE.Group
 */
class Container extends THREE.Group {
  /*
   * get origin() {
   * return this;
   * }
   */
  /**
   * @typedef { ContainerPresetsLayout['DEPTH']
   *  | ContainerPresetsLayout['WIDTH']
   *  | ContainerPresetsLayout['HEIGHT']
   * } ContainerLayoutEnum
   */
  /**
   * Layout for container means direction of cabinets stacking.
   * For possible values [see]{@link ContainerPresetsLayout}
   * @member
   * @type { ContainerLayoutEnum }
   */
  layout = PRESETS.LAYOUT.WIDTH;

  /**
   * Object for holding cabinet sizes, so values can be only >= 0
   * @typedef {Object} ContainerSizeObj
   * @property {number} width
   * @property {number} depth
   * @property {number} height
   */
  /**
   * Size of the container denotes it`s threeJs size values, so that resulting
   * container would be exactly like it. But note that user works with this
   * member only with {@link ConstructableKitchen} (which is descendant) of
   * Container. Working with any other cabinet involves workign with sizeConstraint
   * @member
   * @type {ContainerSizeObj}
   */
  size = {
    width: 1,
    height: 1,
    depth: 1
  };

  /**
   * Same structure as {@link ContainerSizeObj} but properrties can be equal to -1.
   * It\`s special value that denotes this container as filler (it will have it\`s
   * width set from free space inside parent, for multiple fillers space will be
   * divided equally)
   * @typedef {ContainerSizeObj} ContainerSizeConstarintObj
   */
  /**
   * SizeConstraint for cabinet
   * @member
   * @type {ContainerSizeConstarintObj}
   */
  sizeConstraint = {
    width: -1,
    height: -1,
    depth: -1
  };

  static getUiName() {
    return 'Container';
  }


  /**
   * Container class. Second after the root element of the
   *  cabinet | kitchen | container hierarchy.
   *
   * @memberof VestaApp.CabinetDesigner
   *
   */
  constructor() {
    super();
    /** @typedef { 'height'|'width'|'depth' } ContainerPivotPropertyNames */
    /** @type { { [ key in ContainerPivotPropertyNames ]: ContainerPresetsPivotEntry } } */
    this.pivot = {
      height: PRESETS.PIVOT.bottom,
      width: PRESETS.PIVOT.w_center,
      depth: PRESETS.PIVOT.d_center
    };

    /** @type { Container | null | THREE.Object3D } */
    this.parent = null; // tracks the "constrainer"
    /** @type {{ [ s: string ]: Container }} */
    this.component = {}; // tracks dependent components
    /** @type { string[] } */
    this.componentOrder = []; // tracks the order for placing components
    this.name = null; // used for references

    this.origin = new THREE.Group(); // tracks the local position

    this.bounds = new THREE.Group(); // track the sizes
    this.origin.name = 'origin';
    this.bounds.name = 'bounds';

    this.origin.add( this.bounds );
    this.add( this.origin );
    // Contour Geometry
    /* {
      let boundGeom = new THREE.BoxGeometry();
      let edgeGeom = new THREE.EdgesGeometry( boundGeom );
      const edgesMaterial = new THREE.LineBasicMaterial( {
        color: 0x000000,
        transparent: true,
        opacity: 0.3
      } );

      this.contour = new THREE.LineSegments( edgeGeom, edgesMaterial );
    } */
    // this.showBounds(true);
  }

  getSizes() {
    return {
      height: this.size.height,
      width: this.size.width,
      depth: this.size.depth
    };
  }

  setName( /** @type { string } */ name ) {
    this.name = name;
  }

  /*
   * COMPONENT MANIPULATION METHODS
   */

  /**
   * getComponentList will return an object containing all components
   * @returns {{}|*}
   */
  getComponentList() {
    return this.component;
  }

  addComponent( arg1, arg2 ) {
    let id = arg1;
    let component = arg2;
    if ( !arg2 ) {
      // meaning we passed just the component as first arg
      component = arg1;
      id = this.uuidv4();
    }

    this.origin.add( component.origin || component );
    component._setParent( this );
    if ( !component.name ) {
      component.name = id;
    }

    this.component[ id ] = component;
    this.componentOrder.push( id );
    this.updateInnerComponents();
  }

  /**
   * addComponentAtIdx adds a component at a specified place
   * @param id - to be used to reference this component
   * @param component - component derived from Container class
   * @param index - the place of this component starting from left, bottom or back
   */
  addComponentAtIdx( id, component, index ) {
    this.addComponent( id, component );
    this.moveGivenComponentIdToIndex( id, index );
  }

  replaceComponent( id, component ) {
    if ( !this.component[ id ] ) {
      return false;
    }

    component.sizeConstraint = JSON.parse(
      JSON.stringify( this.component[ id ].sizeConstraint )
    );
    this.origin.remove( this.component[ id ].origin );
    this.origin.add( component.origin || component );
    component._setParent( this );
    if ( !component.name ) {
      component.name = id;
    }

    this.component[ id ] = component;
    this.updateInnerComponents();

    return true;
  }

  removeComponentByID( id ) {
    if ( !this.component[ id ] ) {
      return false;
    }

    const idx = this.getComponentIndexOrder( id );

    return this.removeComponentByIndex( idx );
  }

  removeComponent( child ) {
    let id = Object.keys( this.component )
      .find( ( id ) => this.component[ id ] === child );

    return this.removeComponentByID( id );
  }

  clearChildren() {
    // eslint-disable-next-line no-restricted-syntax
    for( let id in this.component ) {
      this.removeComponentByID( id );
    }
  }

  getComponentOrder() {
    return this.componentOrder;
  }

  getComponentIndexOrder( id ) {
    return this.componentOrder.indexOf( id );
  }

  /**
   *
   * @param { keyof ContainerPresetsLayout } layout
   */
  setLayout( layout ) {
    if ( PRESETS.LAYOUT[ layout ] ) {
      this.layout = layout;
    }
  }

  getBoundingPositions() {
    return {
      left: -this.size.width / 2, // TODO: This is just a stub
      right: this.size.width / 2, // TODO: This is just a stub
      bottom: -this.size.height / 2, // TODO: This is just a stub
      top: this.size.height / 2, // TODO: This is just a stub
      front: this.size.depth / 2, // TODO: This is just a stub
      back: -this.size.depth / 2 // TODO: This is just a stub
    };
  }

  /** @param { number } newPosition */
  changeXPositionTo( newPosition ) {
    this.origin.position.x = newPosition;
  }

  /** @param { number } newPosition */
  changeYPositionTo( newPosition ) {
    this.origin.position.y = newPosition;
  }

  /** @param { number } newPosition */
  changeZPositionTo( newPosition ) {
    this.origin.position.z = newPosition;
  }

  setWidthConstraint( value ) {
    this.sizeConstraint.width = value;
    /*
     * if (this.parent && value > this.parent.size.width) {
     *     this.sizeConstraint.width = this.parent.size.width;
     * }
     */
    if ( this.parent && this.parent.updateInnerComponents ) {
      this.parent.updateInnerComponents();
    }
  }

  setHeightConstraint( value ) {
    this.sizeConstraint.height = value;
    if ( this.parent && this.parent.updateInnerComponents ) {
      this.parent.updateInnerComponents();
    }
  }

  setDepthConstraint( value ) {
    this.sizeConstraint.depth = value;
    if ( this.parent && this.parent.updateInnerComponents ) {
      this.parent.updateInnerComponents();
    }
  }

  /**
   * Main logical center of the width layout calculation
   */
  _computePerComponentWidthLayout() {
    let sizes = [];
    let fillerIdx = [];
    let filledSize = 0;

    for ( let i = 0; i < this.componentOrder.length; ++i ) {
      let component = this.component[ this.componentOrder[ i ] ];
      const w = component.sizeConstraint.width;
      if ( w < 0 ) {
        // meaning it is a floating one
        fillerIdx.push( i );
      } else {
        filledSize += w;
      }

      sizes.push( w );
    }

    let freeSpace = this.size.width - filledSize;
    for ( let i = 0; i < fillerIdx.length; ++i ) {
      sizes[ fillerIdx[ i ] ] = freeSpace / fillerIdx.length;
    }

    // compute position of components, based on their layout
    let positions = [];
    let leftMargin = this.getBoundingPositions().left;
    for ( let i = 0; i < sizes.length; ++i ) {
      let size = sizes[ i ];
      let position = leftMargin + size / 2;
      // TODO: review this approach
      positions.push( position );
      leftMargin += size;
    }

    return { sizes, positions };
  }

  _computePerComponentDepthLayout() {
    let sizes = [];
    let fillerIdx = [];
    let filledSize = 0;
    for ( let i = 0; i < this.componentOrder.length; ++i ) {
      let component = this.component[ this.componentOrder[ i ] ];
      if ( component.sizeConstraint.depth < 0 ) {
        // meaning it is a floating one
        fillerIdx.push( i );
      } else {
        filledSize += component.sizeConstraint.depth;
      }

      sizes.push( component.sizeConstraint.depth );
    }

    let freeSpace = this.size.depth - filledSize;
    for ( let i = 0; i < fillerIdx.length; ++i ) {
      sizes[ fillerIdx[ i ] ] = freeSpace / fillerIdx.length;
    }

    // compute position of components, based on their layout
    let positions = [];
    let backMargin = this.getBoundingPositions().back;
    for ( let i = 0; i < sizes.length; ++i ) {
      // TODO: review this approach
      positions.push( backMargin + sizes[ i ] / 2 );
      backMargin += sizes[ i ];
    }

    return { sizes, positions };
  }

  _computePerComponentHeightLayout() {
    let sizes = [];

    // tracks the index of floating component (component with autosize)
    let fillerIdx = [];
    let filledSize = 0;

    for ( let i = 0; i < this.componentOrder.length; ++i ) {
      let component = this.component[ this.componentOrder[ i ] ];
      if ( component.sizeConstraint.height < 0 ) {
        // meaning it is a floating one
        fillerIdx.push( i );
      } else {
        filledSize += component.sizeConstraint.height;
      }

      sizes.push( component.sizeConstraint.height );
    }

    let freeSpace = this.size.height - filledSize;
    for ( let i = 0; i < fillerIdx.length; ++i ) {
      sizes[ fillerIdx[ i ] ] = freeSpace / fillerIdx.length;
    }

    // compute position of components, based on their layout
    let positions = [];

    // this.getBoundingPositions().bottom;;
    let bottomMargin = 0;

    for ( let i = 0; i < sizes.length; ++i ) {
      // TODO: review this approach
      positions.push( bottomMargin );
      bottomMargin += sizes[ i ];
    }

    return { sizes, positions };
  }

  updateInnerComponents() {
    let componentArray = Object.values( this.component );

    // skip if there are no components
    if ( componentArray.length === 0 ) {
      return;
    }

    let layout = null;
    switch ( this.layout ) {
      case PRESETS.LAYOUT.WIDTH:
        layout = this._computePerComponentWidthLayout();

        for ( let i = 0; i < this.componentOrder.length; ++i ) {
          let component = this.component[ this.componentOrder[ i ] ];
          component.changeWidth( layout.sizes[ i ] );
          component.changeHeight( this.size.height );
          component.changeDepth( this.size.depth );

          // component.changeXPositionTo(this.getBoundingPositions().left + component.size.width*(2*i+1)/2);
          component.changeXPositionTo( layout.positions[ i ] );
        }

        break;
      case PRESETS.LAYOUT.HEIGHT:
        layout = this._computePerComponentHeightLayout();
        for ( let i = 0; i < this.componentOrder.length; ++i ) {
          let component = this.component[ this.componentOrder[ i ] ];
          component.changeWidth( this.size.width );
          component.changeHeight( layout.sizes[ i ] );
          component.changeDepth( this.size.depth );

          // component.changeYPositionTo(this.getBoundingPositions().bottom + component.size.height*i);
          component.changeYPositionTo( layout.positions[ i ] );
        }

        break;
      case PRESETS.LAYOUT.DEPTH:
        layout = this._computePerComponentDepthLayout();
        for ( let i = 0; i < this.componentOrder.length; ++i ) {
          let component = this.component[ this.componentOrder[ i ] ];
          component.changeWidth( this.size.width );
          component.changeHeight( this.size.height );
          component.changeDepth( layout.sizes[ i ] );

          // component.changeZPositionTo(this.getBoundingPositions().back + component.size.depth*(2*i+1)/2);
          component.changeZPositionTo( layout.positions[ i ] );
        }

        break;
      default: break;
    }
  }

  updatePositionBasedOnConstraints() {
    if ( this.positionConstraint.height === PRESETS.POSITION_CONSTRAINT.ABSOLUTE ) {
      switch ( this.pivot.height ) {
        case PRESETS.PIVOT.top:
          this.origin.position.y +=
            this.size.height / 2 + this.bounds.position.y;
          break;
        case PRESETS.PIVOT.bottom:
          this.origin.position.y -=
            this.size.height / 2 - this.bounds.position.y;
          break;
        case PRESETS.PIVOT.h_center:
          this.origin.position.y -= this.size.height / 2;
          break;
        default: break;
      }
    }
  }

  /**
   *
   * @param { Boolean } flag
   */
  /* showBounds( flag ) {
     // TODO: make in nicer and simpler
     if (
       ( flag && this.bounds.children.length ) ||
       ( !flag && !this.bounds.children.length )
     ) {
       return;
     }
     if ( !flag && this.bounds.children.length ) {
       this.bounds.remove( this.contour );

       return;
     }

     this.bounds.add( this.contour );

      * Object.values(this.component).forEach(component => {
      *     component.showBounds(flag)});

   } */

  uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace( /[xy]/g, ( c ) => {
      let r = ( Math.random() * 16 ) | 0,
        v = c === 'x' ? r : ( r & 0x3 ) | 0x8;

      return v.toString( 16 );
    } );
  };

  _setParent( parent ) {
    this.parent = parent;
  }

  addTo( parent ) {
    parent.add( this.origin );
    this._setParent( parent );
  }

  // Dimension Handling
  /**
   * @param { number } value
   */
  changeWidth( value ) {
    if ( this.size.width === value ) {
      return;
    }

    this.size.width = value >= 0 ? value : 0;
    if ( this.size.width !== 0 ) {
      this.bounds.scale.x = this.size.width;
    }

    switch ( this.pivot.width ) {
      case PRESETS.PIVOT.left:
        this.bounds.position.x = value / 2;
        break;
      case PRESETS.PIVOT.w_center:
        this.bounds.position.x = 0;
        break;
      case PRESETS.PIVOT.right:
        this.bounds.position.x = -value / 2;
        break;
      default: break;
    }

    this.updateInnerComponents();
  }

  /**
   *
   * @param {number} value
   */
  changeHeight( value ) {
    if ( this.size.height === value ) {
      return;
    }

    this.size.height = value >= 0 ? value : 0;
    if ( this.size.height !== 0 ) {
      this.bounds.scale.y = this.size.height;
    }

    switch ( this.pivot.height ) {
      case PRESETS.PIVOT.top:
        this.bounds.position.y = -value / 2;
        break;
      case PRESETS.PIVOT.h_center:
        this.bounds.position.y = 0;
        break;
      case PRESETS.PIVOT.bottom:
        this.bounds.position.y = value / 2;
        break;
      default: break;
    }

    this.updateInnerComponents();
  }

  /**
   *
   * @param { number } value
   */
  changeDepth( value ) {
    if ( this.size.depth === value ) {
      return;
    }

    this.size.depth = value >= 0 ? value : 0;
    if ( this.size.depth !== 0 ) {
      this.bounds.scale.z = this.size.depth;
    }

    switch ( this.pivot.depth ) {
      case PRESETS.PIVOT.back:
        this.bounds.position.z = value / 2;
        break;
      case PRESETS.PIVOT.d_center:
        this.bounds.position.z = 0;
        break;
      case PRESETS.PIVOT.front:
        this.bounds.position.z = -value / 2;
        break;
      default: break;
    }

    this.updateInnerComponents();
  }

  // Pivot Handling
  changeHeightPivot( /** @type { ContainerPresetsPivotEntry } */ pivot ) {
    switch ( pivot ) {
      case PRESETS.PIVOT.top:
        this.pivot.height = pivot;
        break;
      case PRESETS.PIVOT.bottom:
        this.pivot.height = pivot;
        break;
      case PRESETS.PIVOT.h_center:
        this.pivot.height = pivot;
        break;
      default: break;
    }

    // this.updatePositionBasedOnConstraints();
    this.changeHeight( this.size.height );
    this.changeWidth( this.size.width );
    this.changeDepth( this.size.depth );

    Object.values( this.component ).forEach( ( component ) => {
      component.changeHeightPivot( pivot );
    } );
  }

  changeWidthPivot( /** @type { ContainerPresetsPivotEntry } */ pivot ) {
    switch ( pivot ) {
      case PRESETS.PIVOT.left:
        this.pivot.width = pivot;
        // this.origin.position.x -= this.size.width / 2 - this.bounds.position.x;
        break;
      case PRESETS.PIVOT.right:
        this.pivot.width = pivot;
        // this.origin.position.x += this.size.width / 2 + this.bounds.position.x;
        break;
      case PRESETS.PIVOT.w_center:
        this.pivot.width = pivot;
        // this.origin.position.x += this.size.width / 2;
        break;
      default: break;
    }

    this.changeHeight( this.size.height );
    this.changeWidth( this.size.width );
    this.changeDepth( this.size.depth );

    Object.values( this.component ).forEach( ( component ) => {
      component.changeWidthPivot( pivot );
    } );
  }

  changeDepthPivot( /** @type { ContainerPresetsPivotEntry } */ pivot ) {
    switch ( pivot ) {
      case PRESETS.PIVOT.front:
        this.pivot.depth = pivot;
        // this.origin.position.z += this.size.depth / 2 + this.bounds.position.z;
        break;
      case PRESETS.PIVOT.back:
        this.pivot.depth = pivot;
        // this.origin.position.z -= this.size.depth / 2 - this.bounds.position.z;
        break;
      case PRESETS.PIVOT.d_center:
        this.pivot.depth = pivot;
        // this.origin.position.z -= this.size.depth / 2;
        break;
      default: break;
    }

    this.changeHeight( this.size.height );
    this.changeWidth( this.size.width );
    this.changeDepth( this.size.depth );

    Object.values( this.component ).forEach( ( component ) => {
      component.changeDepthPivot( pivot );
    } );
  }

  openAllDoors( /** @type { import('../core/Door').radiansFrom0ToPi_2 */ angle ) {
    // eslint-disable-next-line no-restricted-syntax
    for ( let prop in this.component ) {
      if ( this.component[ prop ].isDoor ) {
        this.component[ prop ].open( angle );
      } else {
        this.component[ prop ].openAllDoors( angle );
      }
    }
  }

  /** @returns { import('../core/Door').radiansFrom0ToPi_2 } */
  getDoorsAngle() {
    if( this.isDoor ) return this.getAngle();

    const compOrder = this.getComponentOrder();
    if( compOrder.length === 0 ) return null;

    return compOrder
      .reduce(
        ( acc, name ) => {
          const angle = this.component[ name ].getDoorsAngle();

          return angle === null ? acc : angle;
        },
        null
      );
  }
}

export { Container, PRESETS };
