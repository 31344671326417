/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { connect } from 'react-redux';
import Storage from 'scr/utilitiesStorage';
import Konva from 'konva';
import {
  toCabinetsDimensionsWall, drawCabinetsDimensions
} from 'c/Modal/ModalFor2d/canvasUtils';
import { labelSettings } from 'c/Modal/ModalFor2d/requirements';
import Floor from 'c/ThreeJsWrap/Viewer/Floor';
import {
  KonvaStage,
  clearStageAndDrawBackground
} from 'c/Modal/ModalFor2d/KonvaStage';
import { saveProject, updateProject } from '@r/reducers/projectsFor3d';
import { ThunkDispatch } from 'redux-thunk';
import WithIcon from './WithIcon';
import style from './index.styl';

interface _IProps {
  hidden: boolean;
  savedToBackend: boolean;
  d: ThunkDispatch<redux._IStore, {},
    redux._IActions['setModalFor2dVisible'] |
    redux._IActions['SET_IMPORT_EXPORT_MODAL_VISIBLE'] |
    redux._IActions['SET_HAS_SAVED_PROJECT'] |
    redux._IActions['setMainModalOpen'] |
    redux._IActions['setMainModalTab']
  >;
}

type mainModalTab = redux._IActions['setMainModalTab']['value'];
type _IDivWithDataset = HTMLDivElement & { dataset: { tab: mainModalTab } };

interface _IDivForModal<Tab extends mainModalTab> extends JSX.IntrinsicAttributes {
  'data-tab': Tab;
  children: JSX.Element;
  onClick: ( e: React.MouseEvent<_IDivWithDataset> ) => void;
}

function TypesafeDiv<P extends object>( props: P ) {
  return <div { ...props } />;
}

export default connect<Omit<_IProps, 'd' | 'hidden'>,
  Pick<_IProps, 'd'>,
  { hidden: boolean },
  redux._IStore
>(
  ( s ) => ( {
    savedToBackend: s.projectsFor3d.projectId !== null
  } ),
  ( d ) => ( { d } )
)(
  class FileMenu extends React.PureComponent<_IProps> {
    stage: Konva.Stage = KonvaStage;

    holder: HTMLDivElement = {} as HTMLDivElement;


    componentDidMount() {
      const holder = document.querySelector<HTMLDivElement>(
        '#canvas2dWrap .holder'
      );
      if ( holder === null ) throw new Error( '2d canvas holder absent' );

      this.stage.container( holder );
    }


    setImportExportModalVisible = () => this.props.d( {
      type: 'SET_IMPORT_EXPORT_MODAL_VISIBLE', value: true
    } );

    setModalTo2dVisible = () => {
      const scene = Storage.get( 'viewer' ).scene;
      const [maybeWallOrFloor] = Storage.get( 'selectedObject' );

      const parentWidth =
        document?.querySelector( '#canvas2dWrap' )?.clientWidth;

      if ( maybeWallOrFloor && ( maybeWallOrFloor as Floor ).isFloor ) {
        const resp = scene.createStandard2DView( maybeWallOrFloor );
        if ( !resp ) return;

        const { canvas } = resp;
        if ( !canvas ) return;

        const width = parentWidth || canvas.width;

        clearStageAndDrawBackground( this.stage, canvas, width );
        this.props.d( {
          type: 'SET_MODAL_FOR_2D_VISIBLE', value: true
        } );

        return;
      }

      const defaultWall = scene.getObjectByName( 'Wall1' );

      // @ts-ignore
      const wall = maybeWallOrFloor && maybeWallOrFloor.isWall
        ? maybeWallOrFloor : defaultWall;

      const resp = scene.createStandard2DView( wall );
      if ( !resp ) return;

      const { canvas, dimensions } = resp;
      if ( !canvas ) return;
      if ( !dimensions ) return;

      const width = parentWidth || canvas.width;
      const scale = width / canvas.width;

      const stage = clearStageAndDrawBackground( this.stage, canvas, width );

      const { up, left } = toCabinetsDimensionsWall( dimensions );
      const layer2 = new Konva.Layer();

      drawCabinetsDimensions( layer2, up );
      drawCabinetsDimensions( layer2, left, {
        rotation: ( -Math.PI / 2 ) as labelSettings['rotation']
      } );

      layer2.scale( { x: scale, y: scale } );
      stage.add( layer2 );
      this.props.d( {
        type: 'SET_MODAL_FOR_2D_VISIBLE', value: true
      } );
    }

    saveOrUpdateProject = () => {
      const viewer = Storage.get( 'viewer' );
      viewer.saveProject();

      if( this.props.savedToBackend ) {
        this.props.d( updateProject( JSON.stringify( viewer.lastSavedProject ) ) );

        return;
      }

      this.props.d( saveProject( JSON.stringify( viewer.lastSavedProject ) ) );
    }

    chooseMainModalTab = ( value: mainModalTab ) => this.props.d( { type: 'SET_MAIN_MODAL_TAB', value } );

    openMainModal = ( tab: mainModalTab ) => {
      this.chooseMainModalTab( tab );
      this.props.d( { type: 'SET_MAIN_MODAL_OPEN_STATE', value: true } );
    }

    mainModalActionClick = ( e: React.MouseEvent<_IDivWithDataset> ) => {
      const { tab } = e.currentTarget.dataset;
      this.openMainModal( tab );
    }

    render() {
      const {
        props: {
          hidden,
          savedToBackend
        },
        setModalTo2dVisible,
        setImportExportModalVisible,
        saveOrUpdateProject,
        mainModalActionClick
      } = this;

      return (
        <div
          style={ hidden ? { display: 'none' } : {} }
          className={ style.fileMenu }
        >

          <div className='row'>

            <TypesafeDiv<_IDivForModal<'newProject'>>
              data-tab='newProject'
              onClick={ mainModalActionClick }
            >
              <WithIcon img='/img/NewProject.svg'>
                New Project
              </WithIcon>
            </TypesafeDiv>

            <div onClick={ setImportExportModalVisible }>
              <WithIcon img='/img/ImportExport.svg'>
                Import,&nbsp;Export
              </WithIcon>
            </div>

          </div>

          <div className='row'>

            <TypesafeDiv<_IDivForModal<'openProject'>>
              data-tab='openProject'
              onClick={ mainModalActionClick }
            >
              <WithIcon img='/img/OpenProject.svg'>
              Open Project

              </WithIcon>
            </TypesafeDiv>

            <div onClick={ setModalTo2dVisible }>
              <WithIcon img='/img/Print.svg'>
                Print
              </WithIcon>
            </div>
          </div>

          <div className='row'>

            <div onClick={ saveOrUpdateProject }>
              <WithIcon img='/img/Save.svg'>
                { savedToBackend ? 'Update' : 'Save' }
              </WithIcon>
            </div>

            <div>
              <WithIcon img='/img/Profile.svg'>
                Account
              </WithIcon>
            </div>

          </div>

          <div className='row'>
            <WithIcon img='/img/Settings.svg'>
              Settings
            </WithIcon>
            <WithIcon img='/img/Help.svg'>
              Help
            </WithIcon>
          </div>

        </div>
      );
    }
  }
);
