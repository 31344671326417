import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  ${ ( { theme: t } ) => `
    background-color: ${ t.arraySetsBackground };
    border-radius: ${ t.borderRadius };
  ` }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  & > div:not(:first-child) {
    margin-left: 10px;
  }
`;

const Order = styled.div`
  width: 3%;
  text-align: center;
`;

const Name = styled.div`
  width: 22%;
`;

const Dimensions = styled.div`
  width: 58%;
  display: flex;
  & > div {
    width: calc(100% / 5);
    padding: 10px;
    ${ ( { theme: t } ) => `
      background-color: ${ t.greyTextColor };
      border-radius: ${ t.borderRadius };
    ` }
    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;

const Cost = styled.div`
  font-weight: 700;
`;

class Details extends PureComponent {
  render() {

    const {
      props: {
        index,
        data,
        texts: {
          length: lengthText,
          width: widthText,
          height: heightText,
          depth: depthText,
          count: countText,
          area: areaText,
          cost: costText,
          labour: labourText
        }
      }
    } = this;

    return (
      <Wrapper>
        {
          data.map( ( {
            name,
            cost,
            count,
            length,
            width,
            height,
            depth,
            area,
            costWithoutLabour,
            totalLabourCost
          }, i ) => (
            <Row key={ name }>
              <Order>
                <span>{ `${ index }.${ i + 1 }` }</span>
              </Order>
              <Name>
                <span>{ name }</span>
              </Name>
              <Dimensions>
                {
                  count !== undefined && (
                    <div>
                      <span>{ `${ countText }: ${ count }` }</span>
                    </div>
                  )
                }
                {
                  length !== undefined && (
                    <div>
                      <span>{ `${ lengthText }: ${ length }` }</span>
                    </div>
                  )
                }
                {
                  width !== undefined && (
                    <div>
                      <span>{ `${ widthText }: ${ width }` }</span>
                    </div>
                  )
                }
                {
                  height !== undefined && (
                    <div>
                      <span>{ `${ heightText }: ${ height }` }</span>
                    </div>
                  )
                }
                {
                  depth !== undefined && (
                    <div>
                      <span>{ `${ depthText }: ${ depth }` }</span>
                    </div>
                  )
                }
                {
                  area !== undefined && (
                    <div>
                      <span>{`${ areaText }: ${ area }`}</span>
                    </div>
                  )
                }
                {
                  costWithoutLabour !== undefined && (
                    <div>
                      <span>{`${ costText }: $${ costWithoutLabour }`}</span>
                    </div>
                  )
                }
                {
                  totalLabourCost !== undefined && (
                    <div>
                      <span>{`${ labourText }: $${ totalLabourCost }`}</span>
                    </div>
                  )
                }
              </Dimensions>
              <Cost>
                <span>
                  $
                  { cost }
                </span>
              </Cost>
            </Row>
          ) )
        }
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        estimationPreview: {
          table: {
            additionalParts: {
              dimensions: texts
            }
          }
        }
      }
    }
  } ) => {
    return { texts };
  }
)( Details );
