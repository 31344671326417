/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable prefer-reflect */
// @ts-check
import { Object3D } from 'three';
import Storage from 'scr/utilitiesStorage';
import VestaObject3D from 'cSrc/structured/singletonCabinet/core/VestaObject3D';
import Viewer from './objects/Viewer';


Object.defineProperty(
  /** @type { THREE.Object3D.prototype } */( Object3D.prototype ),
  'vestaObject', {
    get () {
      const ctx = /** @type { THREE.Object3D } */( this );

      if ( ctx._vestaObject === undefined ) {
        ctx._vestaObject = new VestaObject3D( this );
      }

      return ctx._vestaObject;
    }
  }
);

/**  */
export default function(
  /**
   * @type {{
   *  cb: Function;
   *  elem: React.RefObject<HTMLDivElement>['current'];
   *  floorplan: ThreeScene.floorplan;
   * }}
   */
  { cb, elem, floorplan }
) {

  const viewer = new Viewer( elem, floorplan );
  Storage.set( 'viewer', viewer );

  cb();
}
