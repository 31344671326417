import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Storage from 'scr/utilitiesStorage';
import style from './index.styl';

interface _IArrowOrDirectionReactProps {
  onClick: any;
  key: string;
  value: string;
}
class Arrow extends PureComponent<_IArrowOrDirectionReactProps> {
  render() {
    const { onClick, value } = this.props;

    return (
      <div onClick={ onClick } className={ `directionRect ${ value }` }>
        <div className={ `${ style.arrow } arrow` }>
          <span />
          <div />
        </div>
      </div>
    );
  }
}

class DirectionRect extends PureComponent<_IArrowOrDirectionReactProps> {
  render() {
    const { onClick, value } = this.props;

    return (
      <div onClick={ onClick } className={ `directionRect ${ value }` }>
        <span className='primary'>{ value }</span>
        { value === 'T' && <span className='secondary'>Top</span> }
      </div>
    );
  }
}

interface _IProps {
  generalViewMode: redux._IStore['flags']['generalViewMode'];
  className: string;
}

interface IItemProps {
  primT: string;
  arr?: boolean;
  mode: string;
}

class CameraControls extends PureComponent<_IProps> {

  handleOnClick = ( value: string ) => () => {
    const viewer = Storage.get( 'viewer' );

    switch ( value ) {
      case 'VIEW_LEFT_BACK':
        viewer.setCameraViewLeftBack();
        break;
      case 'VIEW_BACK':
        viewer.setCameraViewBack();
        break;
      case 'VIEW_RIGHT_BACK':
        viewer.setCameraViewRightBack();
        break;
      case 'VIEW_LEFT':
        viewer.setCameraViewLeft();
        break;
      case 'VIEW_TOP':
        viewer.setCameraViewTop();
        break;
      case 'VIEW_RIGHT':
        viewer.setCameraViewRight();
        break;
      case 'VIEW_LEFT_FRONT':
        viewer.setCameraViewLeftFront();
        break;
      case 'VIEW_FRONT':
        viewer.setCameraViewFront();
        break;
      case 'VIEW_RIGHT_FRONT':
        viewer.setCameraViewRightFront();
        break;
      default:
        break;
    }
  };

  render() {
    const items = [
      { primT: 'LB', arr: true, mode: 'VIEW_LEFT_BACK' },
      { primT: 'B', mode: 'VIEW_BACK', tooltip: 'Back' },
      { primT: 'RB', arr: true, mode: 'VIEW_RIGHT_BACK' },
      { primT: 'L', mode: 'VIEW_LEFT' },
      { primT: 'T', mode: 'VIEW_TOP' },
      { primT: 'R', mode: 'VIEW_RIGHT' },
      { primT: 'LF', arr: true, mode: 'VIEW_LEFT_FRONT' },
      { primT: 'F', mode: 'VIEW_FRONT' },
      { primT: 'RF', arr: true, mode: 'VIEW_RIGHT_FRONT' }
    ];

    const {
      generalViewMode,
      className
    } = this.props;


    if( generalViewMode !== '3d' ) {
      return null;
    }

    const blockClassName = `${ style.root } ${ className || '' }`;

    return(
      <div className={ blockClassName }>
        {items.map( ( item: IItemProps ) => {
          return item && item.arr
            ? <Arrow onClick={ this.handleOnClick( item.mode ) } key={ item.mode } value={ item.primT } />
            : <DirectionRect onClick={ this.handleOnClick( item.mode ) } key={ item.mode } value={ item.primT } />;

        } )}
      </div>
    );
  }
}

export default connect(
  ( { flags }: redux._IStore ) => {
    return {
      generalViewMode: flags.generalViewMode
    };
  }
)( CameraControls );
