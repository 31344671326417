import * as THREE from 'three';
import { CurveDefaultMaterialEx, CurveHighlightMaterial } from './entity';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import {CurveUtil} from '../common';
import {createCurveEntity as createSelector} from './CurveEntity';

class CurveEntityEx extends Line2 {
    constructor(geometry, material) {
        super(geometry, material);
    }

    get curve() {
        return this.userData.curve;
    }
    set curve(value) {
        this.userData.curve = value;
    }
    // to fix three.js raycast of Line2:LineSegments2
    get selector() {
        return this.children[0];
    }
    set selector(value) {
        const old = this.selector;
        if (old) this.remove(old);
        
        value.visible = false; // hide, but selectable
        return this.add(value);
    }
    raycast(raycaster, intersects) { // override to fix three.js raycast of Line2:LineSegments2
        const selector = this.selector;
        if (selector) {
            selector.raycast(raycaster, intersects);
        }
    }

    updateEntity() {
        this.geometry.dispose();
        this.geometry = createCurveGeometry(this.curve);
        this.computeLineDistances();

        this.selector.updateEntity();
    }

    dispose() {
        this.geometry.dispose();
        
        const parent = this.parent;
        if (parent) parent.remove(this);

        this.selector.dispose();
    }

    get highlight() {
        return this.material == CurveHighlightMaterial;
    }
    set highlight(value) {
        this.material = value ? CurveHighlightMaterial : CurveDefaultMaterialEx;
        // this.material.needsUpdate = true;
    }
}

// utils
const createCurveGeometry = (() => {
    const point = new THREE.Vector2();
    return (curve) => {
        const geometry = new LineGeometry();

        const divisions = CurveUtil.curveDivisions(curve);
        const positions = [];
        for (let i = 0; i <= divisions; i++) {
            const t = i / divisions;
            curve.getPoint(t, point);
            positions.push(point.x, point.y, 0);
        }
        geometry.setPositions(positions);

        return geometry;
    }
})();
function createCurveEntity(curve, material = CurveDefaultMaterialEx) {
    const geometry = createCurveGeometry(curve);
    const entity = new CurveEntityEx(geometry, material);
    
    entity.computeLineDistances();
    entity.curve = curve;

    entity.selector = createSelector(curve);

    return entity;
}

export {createCurveEntity};