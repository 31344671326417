// @ts-check
import { simpleSet } from '@r/reducers/utils';
import { _ISimpleSet, AllActions } from '@r/storeUtils';

const s = simpleSet as _ISimpleSet<
  redux._IConstants['SET_CABINETS_SETTINGS_CROWN_MOLDING_DEFAULT_SHAPE'],
  redux._IStore['projectFor3d']['cabinetsSettings']['defaultShape'],
  AllActions
  >;
export const defaultShape = s(
  'SET_CABINETS_SETTINGS_CROWN_MOLDING_DEFAULT_SHAPE',
  'CM01'
);
