import { d } from '@r/storeUtils';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Theme } from '@@/Theme';
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  TabsWithIconUnderlined
} from '@npmrost/storybook/stories/Vesta/Tabs/TabsWithIconUnderlined';
import { Plus, Ruller, Filler } from '@npmrost/storybook/stories/Vesta/Icons';
import Catalog from './Catalog';
import Dimensions from '../Modal/Main/NewProject/Dimensions';
import Materials from '../Modal/Main/NewProject/Materials';

interface _IProps {
  rightMenuTabChosen: redux._IStore['calculationMode']['tabs']['rightMenu'];
  texts: redux._IStore['texts']['calculationMode'];
  dispatch: d<
    redux._IActions['setCalculationModeRightMenuTab']
  >;
}

const Wrapper = styled.div`
  width: 30%;
  height: 100vh;
  padding: 15px 3px 15px 15px;
  ${ ( { theme: t }: { theme: typeof Theme } ): string => `
    background: ${ t.generalBackground };
    border-radius: ${ t.borderRadius };
  ` }
`;

const Tabs = styled( TabsWithIconUnderlined )`
  font-size: 13px;
  white-space: nowrap;
`;

const Content = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  height: calc(100% - 100px);
  overflow: hidden;
`;

const StyledPerfectScrollbar = styled( PerfectScrollbar )`
  padding-right: 3px;
`;

const ConnectedDimensions = connect(
  ( s: redux._IStore ) => {
    return {
      settings: s.calculationMode.projects.currentProject.settings.dimensions,
      units: s.calculationMode.projects.currentProject.settings.information.units,
      currentProject: true
    };
  }
)( Dimensions );

const ConnectedMaterials = connect(
  ( s: redux._IStore ) => {
    return {
      settings: s.calculationMode.projects.currentProject.settings.materials,
      currentProject: true
    };
  }
)( Materials );

class RightMenu extends PureComponent<_IProps> {

  chooseRightMenuTab = ( value: string ) => {
    this.props.dispatch( {
      type: 'SET_CALCULATION_MODE_RIGHT_MENU_TAB',
      value: value as _IProps['rightMenuTabChosen']
    } );
  }

  render() {

    const {
      props: {
        texts: {
          rightMenuTabs: rightMenuTabsText
        },
        rightMenuTabChosen
      },
      chooseRightMenuTab
    } = this;

    const rightMenuTabs = ['catalog', 'defaultDimensions', 'defaultMaterials'];
    const icons = [Plus, Ruller, Filler];

    const options = rightMenuTabsText.map( ( item, index ) => {
      return {
        label: item,
        value: rightMenuTabs[ index ],
        icon: icons[ index ]
      };
    } );

    return (
      <Wrapper>
        <Tabs
          options={ options }
          chosen={ [{
            label: rightMenuTabsText[ rightMenuTabs.indexOf( rightMenuTabChosen ) ],
            value: rightMenuTabChosen,
            icon: icons[ rightMenuTabs.indexOf( rightMenuTabChosen ) ]
          }] }
          choose={ chooseRightMenuTab }
        />
        <Content>
          <StyledPerfectScrollbar>
            {
              rightMenuTabChosen === 'catalog' &&
                <Catalog />
            }
            {
              rightMenuTabChosen === 'defaultDimensions' &&
                <ConnectedDimensions />
            }
            {
              rightMenuTabChosen === 'defaultMaterials' &&
                <ConnectedMaterials />
            }
          </StyledPerfectScrollbar>
        </Content>
      </Wrapper>
    );
  }
}

export default connect(
  ( s: redux._IStore ): Omit<_IProps, 'dispatch'> => {
    return {
      rightMenuTabChosen: s.calculationMode.tabs.rightMenu,
      texts: s.texts.calculationMode
    };
  }
)( RightMenu );
