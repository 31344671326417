// @ts-check
import { simpleSet } from '@r/reducers/utils';
import { _ISimpleSet, AllActions } from '@r/storeUtils';

const s = simpleSet as _ISimpleSet<
  redux._IConstants['SET_CABINETS_SETTINGS_CROWN_MOLDING_DEFAULT_MATERIAL'],
  redux._IStore['projectFor3d']['cabinetsSettings']['defaultMaterial'],
  AllActions
  >;
export const defaultMaterial = s(
  'SET_CABINETS_SETTINGS_CROWN_MOLDING_DEFAULT_MATERIAL',
  'white'
);
