import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Close } from '@npmrost/storybook//stories/Vesta/Icons';
import { Search } from '@npmrost/storybook/stories/Vesta/Search';
import {
  chooseTag as chooseTagAction
} from '@r/reducers/storeParts/calculationMode/projects/chosenTags';
import Projects from './Projects';

const Container = styled.div`
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
`;

const Head = styled.div`
  display: flex;
  align-items: center;
`;

// const SearchWrapper = styled( Search )`
//   width: 50%;
// `;

const SearchWrapper = styled.div`
  width: 50%;
`;

const Tags = styled.div`
  margin-left: 30px;
  /* width: 50%; */
  display: flex;
  justify-content: space-between;
`;

const TagWrapper = styled.div`
  padding: 3px;
  border-radius: 2px;
  white-space: nowrap;
  margin-left: 10px;
  cursor: pointer;
  ${ ( { theme: t, chosen } ) => `
    background: ${ chosen ? t.mainGradient : t.disabledBackground };
  ` }
`;

const TagContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 7px;
  ${ ( { theme: t, chosen } ) => `
    background: ${ t.generalBackground };
    color: ${ chosen ? t.secondaryColor : t.greyTextColor }
  ` }
`;

const Plus = styled.span`
  margin-left: 10px;
  display: flex;
  align-items: center;
  svg {
    width: 13px;
    height: 13px;
    ${ ( { chosen } ) => `
      transform: ${ chosen ? 'rotate(0deg)' : 'rotate(45deg)' };
    ` }
    path {
      ${ ( { theme: t, chosen } ) => `
        fill: ${ chosen ? t.secondaryColor : t.greyTextColor };
      ` }
    }
    &:hover path {
      ${ ( { theme: t, chosen } ) => `
        fill: ${ chosen ? t.secondaryColor : t.greyTextColor };
      ` }
    }
  }
`;

class ExistingProjects extends PureComponent {

  chooseTag = ( e ) => {
    const { existingProjects, chosenTags, dispatch } = this.props;

    if ( ( existingProjects.data && existingProjects.data.length ) || chosenTags.length ) {
      dispatch( chooseTagAction( e.currentTarget.dataset.value ) );
    }
  }

  render() {

    const {
      props: {
        texts: {
          projectInformation: {
            projectTags: projectTagsText
          },
          searchProjectByTitle
        },
        chosenTags,
        close
      },
      chooseTag
    } = this;

    const projectTags = ['estimating', 'inProgress', 'completed'];

    const tags = projectTagsText.map( ( item, index ) => {
      return { label: item, value: projectTags[ index ] };
    } );

    return (
      <Container>
        <Head>
          <SearchWrapper
            placeholder={ searchProjectByTitle }
          />
          <Tags>
            {
              tags.map( ( { label, value } ) => {
                const chosen = chosenTags.includes( value );

                return (
                  <TagWrapper
                    key={ value }
                    chosen={ chosen }
                    data-value={ value }
                    onClick={ chooseTag }
                  >
                    <TagContent chosen={ chosen }>
                      <span>{ label }</span>
                      <Plus chosen={ chosen }>
                        { Close }
                      </Plus>
                    </TagContent>
                  </TagWrapper>
                );
              } )
            }
          </Tags>
        </Head>
        <Projects close={ close } />
      </Container>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: texts
    },
    calculationMode: {
      projects: { chosenTags, existingProjects }
    }
  } ) => ( {
    texts,
    chosenTags,
    existingProjects
  } )
)( ExistingProjects );
