import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { settings } from './settings';
import { tabs } from './tabs';
import { projects } from './projects';
import { modals } from './modals';
import { cabinets } from './cabinets';
import { search } from './search';
import { materialsFromDb } from './materialsFromDb';
import { adminSettings } from './adminSettings';

const calculationModeComb = combineReducers as
  combRed<
    redux._IStore['calculationMode'],
    AllActions
  >;

export const calculationMode = calculationModeComb( {
  settings,
  tabs,
  modals,
  projects,
  cabinets,
  search,
  materialsFromDb,
  adminSettings
} );
