import React, { PureComponent } from 'react';
import Storage from 'scr/utilitiesStorage';
import Button from 'c/shared/ThemeButton';
import { IconButton } from 'c/shared/icon-button';
import RightMenuForCorner from './components/RightMenuForCorner';
import RightMenuForWall3D from './components/RightMenuForWall3D';
import style from './index.styl';

interface IProps {
  mode: string;
}

class RightMenuForFloorPlan extends PureComponent<IProps> {
  handleOnClick = ( stage: any ) => () => {
    const floorPlan = Storage.get( 'viewer' ).floorplan2;
    floorPlan.switchToStage( stage );
    this.forceUpdate();
  };

  handleRemoveStage = ( stage: any ) => () => {
    const floorPlan = Storage.get( 'viewer' ).floorplan2;
    floorPlan.removeStage( stage );
    this.forceUpdate();
  };

  renderStages = ( floorPlan: any ) => {
    return floorPlan.stages.map( ( stage: any, index: number ) => {
      const number = this.renderFloorNumber( index + 1 );

      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const currentStage = Storage.get( 'viewer' ).floorplanModeParams.currentStage;

      return (
        <div
          className='stage-block'
          key={ stage.uuid }
        >
          <div
            onClick={ this.handleOnClick( stage ) }
            className={
              `stage ${ stage.uuid === currentStage.uuid ? 'selected' : '' }`
            }
          >
            { number }
          Floor
          </div>
          <div className='stage-actions'>
            <IconButton
              title='Copy'
              variant='copy'
              className={ style.close }
              onClick={ () => {} }
            />
            <IconButton
              title='Close'
              variant='close'
              className={ style.close }
              onClick={ this.handleRemoveStage( stage ) }
            />
          </div>
        </div>
      );
    } );
  };

  renderFloorNumber = ( index: number ) => {
    switch ( index ) {
      case 1:
        return '1st ';
      case 2:
        return '2nd ';
      case 3:
        return '3rd ';
      default:
        return `${ index }th `;
    }
  };

  handleAddNewStage = () => {
    const floorPlan = Storage.get( 'viewer' ).floorplan2;
    floorPlan.addStage();
    this.forceUpdate();
  }

  render() {
    const { mode } = this.props;
    const floorPlan = Storage.get( 'viewer' ).floorplan2;

    if( mode === 'editWall3D' ) {
      return <RightMenuForWall3D />;
    }

    if( mode === 'editCorner' ) {
      return <RightMenuForCorner />;
    }

    return (
      <div className={ style.rightMenuForFloorPlan }>
        <h3>Floor Plan</h3>
        <Button onClick={ this.handleAddNewStage }>
          Add Floor
        </Button>

        <div className='stages'>
          {this.renderStages( floorPlan )}
        </div>
      </div>
    );
  }
}

export default RightMenuForFloorPlan;
