import { Box3, Ray, Vector3 } from 'three';

export default class IntersectionDetector {
  constructor( scene ) {
    this.scene = scene;
    this.ray = new Ray();
  }

  /**
     *
     * @param {Array} t Array with 3 Vector3 elements representing triangle
     * @param {THREE.Matrix4} m Matrix to apply
     */
  _applyMatrix4ToTriangle( t, m ) {
    return [
      t[ 0 ].clone().applyMatrix4( m ),
      t[ 1 ].clone().applyMatrix4( m ),
      t[ 2 ].clone().applyMatrix4( m )
    ];
  }

  /**
     * Checks intersection of ray and 3D triangle
     *
     * @param {THREE.Vector3} origin Ray origin point
     * @param {THREE.Vector3} to Ray destination point
     * @param {Array} t Array with 3 Vector3 elements representing triangle
     */
  _rayTriangleIntersection( origin, to, t ) {
    let direction = to.clone().sub( origin );
    this.ray.origin = origin;
    this.ray.direction = direction.clone().normalize();
    let point = this.ray.intersectTriangle( t[ 0 ], t[ 1 ], t[ 2 ], false, new Vector3() );
    if ( point ) {
      if (
        point
          .clone()
          .sub( origin )
          .length() < direction.length()
      ) {
        return true;
      }
    }

    return false;
  }

  /**
     * Checks intersection of two 3D triangles
     *
     * @param {Array} t1 Array with 3 Vector3 elements representing triangle
     * @param {Array} t2 Array with 3 Vector3 elements representing triangle
     */
  _triangleTriangleIntersection( t1, t2 ) {
    // Console.log('_triangleTriangleIntersection', t1, t2);
    let result = false;
    result = this._rayTriangleIntersection( t1[ 0 ], t1[ 1 ], [t2[ 0 ], t2[ 1 ], t2[ 2 ]] );
    if ( result ) {
      return true;
    }

    result = this._rayTriangleIntersection( t1[ 1 ], t1[ 2 ], [t2[ 0 ], t2[ 1 ], t2[ 2 ]] );
    if ( result ) {
      return true;
    }

    result = this._rayTriangleIntersection( t1[ 2 ], t1[ 0 ], [t2[ 0 ], t2[ 1 ], t2[ 2 ]] );
    if ( result ) {
      return true;
    }

    result = this._rayTriangleIntersection( t2[ 0 ], t2[ 1 ], [t1[ 0 ], t1[ 1 ], t1[ 2 ]] );
    if ( result ) {
      return true;
    }

    result = this._rayTriangleIntersection( t2[ 1 ], t2[ 2 ], [t1[ 0 ], t1[ 1 ], t1[ 2 ]] );
    if ( result ) {
      return true;
    }

    result = this._rayTriangleIntersection( t2[ 2 ], t2[ 0 ], [t1[ 0 ], t1[ 1 ], t1[ 2 ]] );
    if ( result ) {
      return true;
    }

    return false;
  }

  /**
     * Checks exists Collisions between two Meshes or not
     *
     * @param {THREE.Mesh} mesh1 Mesh1
     * @param {THREE.Mesh} mesh2  Mesh2
     */
  _meshesIntersection( mesh1, mesh2 ) {
    // Console.log('_meshesIntersection', mesh1, mesh2);
    let result = false;
    let triangles1 = mesh1.geometry.triangles;
    let triangles2 = mesh2.geometry.triangles;
    mesh1.updateMatrixWorld( true );
    mesh2.updateMatrixWorld( true );

    for ( let i = 0; i < triangles1.length; i += 1 ) {
      for ( let j = 0; j < triangles2.length; j += 1 ) {
        result = this._triangleTriangleIntersection(
          this._applyMatrix4ToTriangle( triangles1[ i ], mesh1.matrixWorld ),
          this._applyMatrix4ToTriangle( triangles2[ j ], mesh2.matrixWorld )
        );
        if ( result ) {
          return true;
        }
      }
    }

    return false;
  }

  /**
     * Checks exists Collisions between two Arrays of Meshes or not
     *
     * @param {Array} arr1 Array1 of Meshes
     * @param {Array} arr2  Array2 of Meshes
     */
  _arrayMeshesIntersection( arr1, arr2 ) {
    let result = false;
    for ( let i = 0; i < arr1.length; i += 1 ) {
      for ( let j = 0; j < arr2.length; j += 1 ) {
        result = this._meshesIntersection( arr1[ i ], arr2[ j ] );
        if ( result ) {
          return true;
        }
      }
    }

    return false;
  }

  /**
     * Updates scene Meshes triangles information (for use in loop)
     */
  update() {
    this.scene.traverseVisible( ( obj ) => {
      let vertices = [];
      let faces = [];
      let triangles = [];
      if ( obj.isMesh && obj.isCollidable ) {
        if (
          obj.geometry.isGeometry &&
          ( obj.geometry.verticesNeedUpdate ||
            obj.geometry.elementsNeedUpdate ||
            obj.geometry.triangles === undefined )
        ) {
          vertices = obj.geometry.vertices;
          faces = obj.geometry.faces;

          for ( let i = 0; i < faces.length; i += 1 ) {
            triangles.push( [
              vertices[ faces[ i ].a ].clone(),
              vertices[ faces[ i ].b ].clone(),
              vertices[ faces[ i ].c ].clone()
            ] );
          }

          obj.geometry.triangles = triangles;
        }
        if (
          obj.geometry.isBufferGeometry &&
          ( obj.geometry.attributes.position.version !==
            obj.geometry.attributes.position.lastVersion ||
            obj.geometry.triangles === undefined )
        ) {
          obj.geometry.attributes.position.lastVersion = obj.geometry.attributes.position.version;
          for ( let i = 0; i < obj.geometry.index.array.length; i += 1 ) {
            vertices.push(
              new Vector3().fromBufferAttribute(
                obj.geometry.attributes.position,
                obj.geometry.index.array[ i ]
              )
            );
          }

          for ( let i = 0; i < vertices.length; i += 3 ) {
            triangles.push( [
              vertices[ i ].clone(),
              vertices[ i + 1 ].clone(),
              vertices[ i + 2 ].clone()
            ] );
          }

          obj.geometry.triangles = triangles;
        }
      }
    } );
  }

  /**
     * Checks exists Collisions between Object3D and other scene objects or not
     *
     * @param {THREE.Object3D} object3d Object to check collisions
     */
  checkSceneIntersectionsWithObject( object3d ) {
    this.update();
    object3d.updateMatrixWorld( true );
    const objBoundingBox = new Box3().setFromObject( object3d );
    let sceneCollidableMeshList = [];
    let object3dCollidableMeshList = [];

    object3d.traverseVisible( ( obj ) => {
      if ( obj.isMesh && obj.isCollidable ) {
        object3dCollidableMeshList.push( obj );
      }
    } );

    this.scene.traverseVisible( ( obj ) => {
      if ( obj.isMesh && obj.isCollidable ) {
        let meshBoundingBox = new Box3().setFromObject( obj );
        if (
          objBoundingBox.intersectsBox( meshBoundingBox ) &&
          object3dCollidableMeshList.indexOf( obj ) === -1
        ) {
          sceneCollidableMeshList.push( obj );
        }
      }
    } );

    return this._arrayMeshesIntersection(
      sceneCollidableMeshList,
      object3dCollidableMeshList
    );
  }
}
