// @ts-check
import { simpleSet } from '@r/reducers/utils';
import { _ISimpleSet, AllActions } from '@r/storeUtils';

const s = simpleSet as _ISimpleSet<
  redux._IConstants['SET_CABINET_MATERIAL'],
  redux._IStore['projectFor3d']['cabinetMaterial'],
  AllActions
>;
export const cabinetMaterial = s( 'SET_CABINET_MATERIAL', { value: '', baseMap: '', label: '' } );
