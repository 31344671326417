/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { _ISimpleSet, _ISimpleAction, AllActions } from '@r/storeUtils';
import { NominalHardNum } from 'decl/general/core';
import { simpleSet } from '@r/reducers/utils';

export type degrees = NominalHardNum<'degrees'>;

declare global {
  namespace redux {
    namespace store {
      interface _ISelectedCabinet {
        doorsAngle: degrees;
      }
    }

    interface _IConstants {
      SET_SELECTED_CABINET_DOORS_ANGLE: 'SET_SELECTED_CABINET_DOORS_ANGLE';
    }

    interface _IActions {
      SET_SELECTED_CABINET_DOORS_ANGLE: _ISimpleAction<
        _IConstants['SET_SELECTED_CABINET_DOORS_ANGLE'],
        _IStore['selectedCabinet']['doorsAngle']
      >;
    }
  }
}

const s = simpleSet as _ISimpleSet<
  redux._IConstants['SET_SELECTED_CABINET_DOORS_ANGLE'],
  redux._IStore['selectedCabinet']['doorsAngle'],
  AllActions
>;

export const doorsAngle = s( 'SET_SELECTED_CABINET_DOORS_ANGLE', 0 as degrees );
