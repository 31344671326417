import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'c/shared/ThemeButton';
import feathersClient from 'scr/getFeathers';
import modalStyl from 'c/CalculationLayer/Modal/index.styl';
import { loginRequest } from '@r/actions';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import SimpleInput from 'c/CalculationLayer/utils/SimpleInput';
import { ThunkDispatch } from 'redux-thunk';
import { isValidEmail } from 'scr/isValidEmail';
import styl from './index.styl';

interface IProps {
  dispatch: ThunkDispatch<
    redux._IStore,
    {},
    | redux._IActions['setUserLogin']
    | redux._IActions['setUserPassword']
    | redux._IActions['setUserLoginError']
  >;
  error: redux._IStore ['user']['loginError'];
  login: redux._IStore ['user']['login'];
  password: redux._IStore ['user']['password'];
}


class LoginForm extends PureComponent< IProps > {

  loginRequestInner = ( e: React.FormEvent ) => {
    e.preventDefault();

    this.props.dispatch( loginRequest( feathersClient ) );
  };

  setLogin = ( login: string ) => {
    this.props.dispatch( {
      type: 'SET_USER_LOGIN',
      value: login as IProps['login']
    } );

    this.props.dispatch( { type: 'SET_USER_LOGIN_ERROR', value: null } );
  }

  setPassword = ( password: string ) => this.props.dispatch( {
    type: 'SET_USER_PASSWORD',
    value: password as IProps['password']
  } );

  render() {
    const {
      props: {
        login,
        password, error
      },
      setLogin,
      setPassword,
      loginRequestInner
    } = this;

    return (
      <form onSubmit={ loginRequestInner } className={ `${ styl.loginModal } form` }>
        <div className='inputs'>
          <LabeledField name='Email' className={ modalStyl.commonLabeledField }>
            <SimpleInput
              className={ modalStyl.commonInput }
              value={ login }
              onChange={ setLogin }
            />
          </LabeledField>

          <LabeledField name='Password' className={ modalStyl.commonLabeledField }>
            <SimpleInput
              className={ modalStyl.commonInput }
              value={ password }
              onChange={ setPassword }
              type='password'
            />
          </LabeledField>

          { error && <p className='signUpError'>{ error }</p> }
        </div>

        <div className='btns'>
          <Button type='submit' text='Login' />
          <Button
            minimalist
            type='button'
            text='Forgot password?'
          />
        </div>

      </form>
    );
  }
}

export default connect< Omit< IProps, 'dispatch' >, Pick< IProps, 'dispatch' >, {}, redux._IStore >(
  ( { user } ) => ( {
    error: user.loginError,
    login: user.login,
    password: user.password
  } )
)( LoginForm );
