import DoAction from "./DoAction";
import { getWall3D } from "../core/helpers/object3d";
import { isArray } from "util";

export default class DoRefreshWallHoles extends DoAction {
    constructor(entities) {
        super();
        if (Array.isArray(entities)) this.walls = entities.map(entity => getWall3D(entity));
        else this.walls = [getWall3D(entities)];
    }
	redo() {
        this.do();
	}
	undo() {
        this.do();
    }
    do() {
        this.walls.forEach(wall => this.viewer.onceActionsMgr.registerRefreshWallHoles(wall)); // refresh holes
        this.viewer.renderOnDemand.set();
    }
}