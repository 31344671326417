import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { value } from './value';
import { options } from './options';

const percentageLossComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['projects']['currentProject']['settings']['information']['percentageLoss'],
    AllActions
  >;

export const percentageLoss = percentageLossComb( {
  value,
  options
} );
