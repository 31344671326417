import React from 'react';

import cn from 'classnames';

import { Setting } from './icon-button.setting';

import style from './icon-button.styl';

export class IconButton extends Setting {

  render() {
    const { title, onClick, variant, className } = this.props;
    const iconClasses = cn([[style.iconButton], [className], [variant + '-icon']]);
    return (
      <button
        title={title}
        type="button"
        className={iconClasses}
        onClick={() => onClick()}
      />
    );
  }

}
