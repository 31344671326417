// @ts-check

/** @type { Partial<CabD.A.ContainerConfigs.ContainerConfig> } */
export const OpenUpperCabinet = {
  layout: 'DEPTH',
  height: 36,
  depth: 13,
  width: 24,
  name: 'Open Upper',
  isTopLevel: true,
  mountTypes: [
    'wall'
  ],
  constrName: 'WallCabinet',
  children: [
    {
      name: 'UpperCarcassAssembly',
      layout: 'WIDTH',
      children: [
        {
          name: 'UpperLeftSide',
          type: 'Panel',
          width: 0.5
        },
        {
          name: 'UpperCarcassAssembly--InteriorAssembly',
          children: [
            {
              name: 'UpperBottom',
              height: 0.5,
              type: 'Panel'
            },
            {
              name: 'UpperInteriorAssembly',
              layout: 'DEPTH',
              children: [
                {
                  name: 'UpperBack',
                  type: 'Panel',
                  depth: 0.5
                },
                {
                  name: 'UpperInterior',
                  children: [
                    {
                      name: 'Interior',
                      children: [
                        {},
                        {
                          height: 0.5,
                          type: 'Panel',
                          name: 'Shelf'
                        },
                        {},
                        {
                          height: 0.5,
                          type: 'Panel',
                          name: 'Shelf'
                        },
                        {}
                      ]
                    },
                    {
                      name: 'UpperTop',
                      type: 'Panel',
                      height: 0.5
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          name: 'UpperRightSide',
          width: 0.5,
          type: 'Panel'
        }
      ]
    },
    {
      name: 'FrontExteriorAssembly',
      layout: 'WIDTH',
      depth: 0.5
    }
  ],
  countertopMaterialName: 'marblewhite005'
};
