import * as THREE from 'three';
import * as math from './libs/math';

const Constants = {
    TwoPi: Math.PI * 2,

    markSize: 5,
    snapSize: 10,
    stickSize: 3.5,
    sthickness: 0.5,

    CurveDivisions: 100,

    xAxis: new THREE.Vector3(1, 0, 0),
    yAxis: new THREE.Vector3(0, 1, 0),
    zAxis: new THREE.Vector3(0, 0, 1),

    xAxis2d: new THREE.Vector2(1, 0),
    yAxis2d: new THREE.Vector2(0, 1),

    planeXY: new THREE.Plane(new THREE.Vector3(0, 0, 1)),
}
Constants.snapSize2 = Constants.snapSize * Constants.snapSize;

const Grid = {
    size: 50,
    divisions: 100,
    page: 5,
    linePrecision: 0.2,
    MColor: new THREE.Color(0x444444), // major color
    mColor: new THREE.Color(0x888888), // minor color
    xcolor: new THREE.Color(0xff0000), // x-axis
    ycolor: new THREE.Color(0x00ff00), // y-axis

    create: () => {
        const grid = new THREE.GridHelper(Grid.size, Grid.divisions, Grid.MColor, Grid.mColor);

		// x0,y0,x1,y1,...,xn,yn
		const gca = grid.geometry.attributes.color.array;
		const xyi = 2 + 2; // xi(b, e), yi(b, e)
		const ci = 3; // color item : r,g,b
		const xyci = xyi * ci;
		const n = (Grid.divisions + 1) * xyci;
		const np = Grid.page * xyci;
		for (let i = 0; i < n; i += np) {
			for (let j = 0; j < xyci; j += ci) Grid.MColor.toArray(gca, i+j);
		}
		
		// x-axis, y-axis
		let center = Grid.divisions / 2 * xyci;
		Grid.xcolor.toArray(gca, center);
		center += 3;
		Grid.xcolor.toArray(gca, center);
		center += 3;
		Grid.ycolor.toArray(gca, center);
		center += 3;
		Grid.ycolor.toArray(gca, center);
        center += 3;
        
        return grid;
    }
}
Grid.unitSize = Grid.size / Grid.divisions;

const ShapeConfig = {
    size: new THREE.Vector2(10, 10),
}

const CommonUtil = {
    CAD_ZERO: 10e-4,

    IsZero: function(v, precision) {
        precision = (precision !== undefined) ? precision : this.CAD_ZERO;
        if (-precision<v && v<precision) {
            return true;
        }
        return false;
    },
    equalsValue: function(a, b, precision) {
        var ab = Math.abs(a - b);
        return this.IsZero(ab, precision);
    },
    equalsVector2: function(a, b, precision) {
        if (a==b) return true;
        if (a.equals(b)) return true;
        // var d = a.distanceTo(b);
        // return this.IsZero(d, precision);
        return this.equalsValue(a.x, b.x, precision)
        && this.equalsValue(a.y, b.y, precision);
    },
    equalsVector3: function(a, b, precision) {
        if (a==b) return true;
        if (a.equals(b)) return true;
        // var d = a.distanceTo(b);
        // return this.IsZero(d, precision);
        return this.equalsValue(a.x, b.x, precision)
        && this.equalsValue(a.y, b.y, precision)
        && this.equalsValue(a.z, b.z, precision);
    },

    calcAngle2d: function(udir, base) { // udir: normalized
        base = base || Constants.xAxis2d;

        let dot = base.dot(udir);
        dot = THREE.Math.clamp(dot, -1, 1);
        const a = Math.acos(dot);
        
        const wind = base.cross(udir);
        return wind < 0 ? -a : a;
    },
}

const CurveUtil = {
    curveDivisions: (curve) => {
        // from THREE.CurvePath.getPoints
        if (curve.isEllipseCurve) return Constants.CurveDivisions * 2;
        if (curve.isLineCurve || curve.isLineCurve3) return 1;
        if (curve.isSplineCurve) return Constants.CurveDivisions * curve.points.length;
        return Constants.CurveDivisions;
    },

    isOrderedClosed: (() => {
        const startPoint = new THREE.Vector2();
        const endPoint = new THREE.Vector2();
        return (curves) => {
            if (curves.length == 0) return false;

            const cfirst = curves[0];
            const clast = curves[curves.length-1];
    
            cfirst.getPoint(0, startPoint);
            clast.getPoint(1, endPoint);
            if (CommonUtil.equalsVector2(startPoint, endPoint)) {
                if (curves.length <= 2 && cfirst.isLineCurve && clast.isLineCurve) return false;
                return true;
            }
    
            return false;
        }
    })(),

    calcArc: (() => {
        const arc = {
            center: new THREE.Vector2(),
            radius: 0,
            aStart: 0,
            aEnd: 0,
            clockWise: false,
        };
        
        const pabm = new THREE.Vector2();
        const pabu = new THREE.Vector2();
        const pbcm = new THREE.Vector2();
        const pbcu = new THREE.Vector2();
        function lineseg(a, b, m, u) {
            m.addVectors(a, b).multiplyScalar(0.5);        
            u.subVectors(b, a);
            u.set(-u.y, u.x);        
            u.add(m);
        }
    
        const ds = new THREE.Vector2();
        const de = new THREE.Vector2();
        const dm = new THREE.Vector2();
    
        function windAngle(from, to, clockWise) {
            let delta = to - from;
            if (clockWise) {
                if (0 <= delta) delta -= Constants.TwoPi;
            } else {
                if (delta < 0) delta += Constants.TwoPi;
            }
            return from + delta;
        }
    
        return function (pa, pb, pc) { // a-b-c
            lineseg(pa, pb, pabm, pabu);
            lineseg(pb, pc, pbcm, pbcu);
            const cross = math.intersect([pabm.x, pabm.y], [pabu.x, pabu.y], [pbcm.x, pbcm.y], [pbcu.x, pbcu.y]);
            
            if (cross) {
                arc.center.fromArray(cross);
                arc.radius = arc.center.distanceTo(pa);
                // calc angles
                ds.subVectors(pa, arc.center).normalize();
                dm.subVectors(pb, arc.center).normalize();
                de.subVectors(pc, arc.center).normalize();
    
                pabm.subVectors(dm, ds);
                pbcm.subVectors(de, dm);
                arc.clockWise = (pabm.cross(pbcm) < 0) ? true : false;
                
                arc.aStart = CommonUtil.calcAngle2d(ds);
                const aMiddle = windAngle(arc.aStart, CommonUtil.calcAngle2d(dm), arc.clockWise);
                arc.aEnd = windAngle(aMiddle, CommonUtil.calcAngle2d(de), arc.clockWise);
                
                return arc;
            }
            return null;
        }
    })(),
}

const DrawSnap = {
    drawCircleShape: function(ctx, center, radius, fillColorMain, strokeColorMain, fillColorInside, strokeColorOutside) {
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.fillStyle = fillColorMain;
        ctx.strokeStyle = strokeColorMain;
        ctx.arc(center.x, center.y, radius, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();
        ctx.beginPath();
        ctx.fillStyle = fillColorInside;
        ctx.arc(center.x, center.y, radius - 3, 0, 2 * Math.PI);
        ctx.fill();
        ctx.beginPath();
        ctx.strokeStyle = strokeColorOutside;
        ctx.arc(center.x, center.y, radius + 1, 0, 2 * Math.PI);
        ctx.stroke();
    },
    drawRectShape: function(ctx, center, radius, fillColor, strokeColor, aRotate=0) {
        ctx.save();
        ctx.translate(center.x, center.y);
        ctx.rotate(aRotate);

        ctx.lineWidth = Constants.sthickness;
        ctx.beginPath();
        ctx.fillStyle = fillColor;
        ctx.strokeStyle = strokeColor;
        ctx.rect(-radius, -radius, radius * 2, radius * 2);
        ctx.fill();
        ctx.stroke();
        ctx.strokeStyle = "#fff";
        ctx.strokeRect(-radius -Constants.sthickness * 2, -radius - Constants.sthickness * 2, radius * 2 + Constants.sthickness * 4, radius * 2 + Constants.sthickness * 4);
        ctx.restore();
    },
    drawDashLine: function(ctx, startPt, endPt, color) {
        ctx.lineWidth = 1;
        ctx.strokeStyle = color;
        ctx.save();
        ctx.beginPath();
        ctx.setLineDash([2, 5]);
        ctx.moveTo(startPt.x, startPt.y);
        ctx.lineTo(endPt.x, endPt.y);
        ctx.stroke();
        ctx.restore();
    }
}
DrawSnap.drawGridSnap = function(ctx, center) {
    DrawSnap.drawCircleShape(ctx, center, Constants.markSize, "#C5CCC9", "#000", "#000", "#fff");
}
DrawSnap.drawEndVtSnap = function(ctx, center) {
    DrawSnap.drawCircleShape(ctx, center, Constants.markSize, "#7DDD73", "#2D6527", "#3C7D36", "#fff");
}
DrawSnap.drawSticker = function(ctx, center) {
    // DrawSnap.drawRectShape(ctx, center, Constants.stickSize, "#E6A88C", "#A43434", Math.PI * 0.25);
    DrawSnap.drawRectShape(ctx, center, Constants.stickSize, "#636FCF", "#2D39AE", Math.PI * 0.25);
}
DrawSnap.drawStickerLine = function(ctx, p1, p2) {
    DrawSnap.drawDashLine(ctx, p1, p2, "#ffffff");
}

Object.assign(THREE.LineCurve.prototype, {
    reverse: function() {
        const vs = [this.v1, this.v2].reverse();
        this.v1 = vs[0];
        this.v2 = vs[1];
    },
    translate: function(x, y) {
        this.v1.add({x, y});
        this.v2.add({x, y});
    },
});

Object.assign(THREE.EllipseCurve.prototype, {
    reverse: function() {
        // refered from THREE.EllipseCurve.getPoint()
        let deltaAngle = this.aEndAngle - this.aStartAngle;
        const samePoints = Math.abs(deltaAngle) < Number.EPSILON;
    
        // ensures that deltaAngle is 0 .. 2 PI
        while (deltaAngle < 0) deltaAngle += Constants.TwoPi;
        while (deltaAngle > Constants.TwoPi) deltaAngle -= Constants.TwoPi;
    
        if (deltaAngle < Number.EPSILON) {
            if (samePoints) {
                deltaAngle = 0;
            } else {
                deltaAngle = Constants.TwoPi;
            }
        }
        
        if (samePoints) return;

        if (this.aClockwise) {
            if (deltaAngle === Constants.TwoPi) {
                deltaAngle = -deltaAngle;
            } else {
                deltaAngle -= Constants.TwoPi;
            }
        }

        // reverse
        const aStart = this.aStartAngle;
        const aEnd = aStart + deltaAngle;

        this.aStartAngle = aEnd;
        this.aEndAngle = aStart;
        this.aClockwise = !this.aClockwise;
    },
    translate: function(x, y) {
        this.aX += x;
        this.aY += y;
    },
});

Object.assign(THREE.QuadraticBezierCurve.prototype, {
    reverse: function() {
        const vs = [this.v0, this.v1, this.v2].reverse();
        this.v0 = vs[0];
        this.v1 = vs[1];
        this.v2 = vs[2];
    },
    translate: function(x, y) {
        this.v0.add({x, y});
        this.v1.add({x, y});
        this.v2.add({x, y});
    },
});

Object.assign(THREE.CubicBezierCurve.prototype, {
    reverse: function() {
        const vs = [this.v0, this.v1, this.v2, this.v3].reverse();
        this.v0 = vs[0];
        this.v1 = vs[1];
        this.v2 = vs[2];
        this.v3 = vs[3];
    },
    translate: function(x, y) {
        this.v0.add({x, y});
        this.v1.add({x, y});
        this.v2.add({x, y});
        this.v3.add({x, y});
    },
});

export {Constants, CommonUtil, CurveUtil, Grid, DrawSnap, ShapeConfig};