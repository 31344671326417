import { simpleSet } from '@r/reducers/utils';
import { inches } from '@npmrost/utils';

const setCalculationModeToeKickHeightValue = simpleSet as
  redux.reducers.calculationMode.projects.currentProject.settings.dimensions.toeKickHeight.value;

export const value = setCalculationModeToeKickHeightValue(
  'SET_CALCULATION_MODE_CURRENT_PROJECT_TOE_KICK_HEIGHT_VALUE',
  0 as inches
);
