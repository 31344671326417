import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
  Filler
} from 'c/CabinetRightSideMenu/CabinetMenuWidgets/General/Filler';
import {
  FinishEnd
} from 'c/CabinetRightSideMenu/CabinetMenuWidgets/General/FinishEnd';
import { OYDistances } from './oYDistances';
import { CrownMolding } from './CrownMolding';


const Header = styled.h3`
  font-weight: 400;
  text-align: center;
`;

export class Upper
  extends PureComponent {
  render() {

    return (
      <>
        <OYDistances />
        <Header>Crown molding</Header>
        <CrownMolding />
        <Filler />
        <FinishEnd />
      </>
    );
  }
}
