// @ts-check
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'c/shared/ThemeButton';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import SimpleInput from 'c/CalculationLayer/utils/SimpleInput';
import modalStyl from 'c/CalculationLayer/Modal/index.styl';
import ProjectTagDefault from './ProjectTag';
import Units from './Units';
import SnapSensitivity from './SnapSensitivity';
import Materials from './Materials';
import { WidthAndDepth, Height } from './Metrics';
import RoomTemplate from './RoomTemplate';
import style from './index.styl';
import { CabinetsSettings } from './cabinetsSettings';


/** @typedef {{ name: string; uiName: string; }} AsideMenuVariant */
/**
 * @typedef { Object } AsideMenuProps
 * @property { InstanceType<typeof LeftTabbedOptions>['props']['tabs'] } variants
 * @property { string } chosenName
 * @property { (e: React.MouseEvent<HTMLDivElement> & { currentTarget: { dataset: { name: tab['name'] } } }) => void } onClick
 */
/** @augments { React.PureComponent<AsideMenuProps> } */
export class AsideMenu extends React.PureComponent {
  render() {
    const { variants, chosenName, onClick } = this.props;

    return (
      <div className={ style.asideMenu }>
        {
          variants.map( (
            /** @type { AsideMenuVariant } */ { name, uiName }
          ) => (
            <div
              key={ name }
              onClick={ onClick }
              className={ name === chosenName ? 'chosen' : '' }
              data-name={ name }
            >
              <span>{uiName}</span>
            </div>
          ) )
        }
      </div>
    );
  }
}


/** @typedef {{ name: 'info' | 'dim' | 'mat' | 'cabSettings'; uiName:string; } } tab */

/**
 * @typedef {{
 *  dispatch: redux.d<
 *    redux.actions['setProjectName']
 *  >;
 *  tabs: tab[];
 *  forSettings?: boolean;
 *  projectName: redux.store['projectFor3d']['projectName'];
 *  style: { [s:string]: string }
 * }} LeftTabbedOptionsProps
 */
/** @augments { React.PureComponent<LeftTabbedOptionsProps> } */
export class LeftTabbedOptions extends React.PureComponent {
  /** @type {{ tabs: LeftTabbedOptionsProps['tabs'] }} */
  static defaultProps = {
    tabs: [
      { uiName: 'Information', name: 'info' },
      { uiName: 'Dimensions', name: 'dim' },
      { uiName: 'Materials', name: 'mat' },
      { uiName: 'Cabinets settings', name: 'cabSettings' }
      // { uiName: 'Customer data', name: 'cust_data' },
    ]
  };

  constructor( /** @type { LeftTabbedOptionsProps } */ p ) {
    super( p );
    const {
      tabs: [{ name }]
    } = p;

    this.state = {
      leftTab: name
    };
  }

  /** @typedef { React.MouseEvent<HTMLDivElement> & { currentTarget: { dataset: { name: tab['name'] } } } } chooseEvent */
  chooseLeftTab = (
    /** @type { chooseEvent } */ {
      currentTarget: {
        dataset: { name: leftTab }
      }
    }
  ) => this.setState( { leftTab } );

  setProjectName = ( /** @type { string } */ value ) => this.props.dispatch( {
    type: 'SET_PROJECT_NAME', value
  } );

  render() {
    const {
      props: {
        style, tabs, projectName
      },
      state: { leftTab },
      chooseLeftTab,
      setProjectName
    } = this;

    return (
      <div className={ style.contentWrap }>
        <aside>
          <AsideMenu
            variants={ tabs }
            chosenName={ leftTab }
            onClick={ chooseLeftTab }
          />
        </aside>

        <div className={ style.content }>
          {
            leftTab === tabs[ 0 ].name && (
              <>
                <LabeledField
                  className={
                    `${ modalStyl.commonLabeledField } shortLabel wider fontSize14px`
                  }
                  name='Project name:'
                >
                  <SimpleInput
                    value={ projectName }
                    onChange={ setProjectName }
                    className={ `${ modalStyl.commonInput } wide` }
                  />
                </LabeledField>

                <ProjectTagDefault />
                <Units />
                <SnapSensitivity />
              </>
            )
          }
          {
            leftTab === tabs[ 1 ].name && (
              <>
                <Height />
                <RoomTemplate />
                <WidthAndDepth />
              </>
            )
          }
          { leftTab === tabs[ 2 ].name && <Materials /> }
          { leftTab === tabs[ 3 ].name && <CabinetsSettings /> }

          {/* {
          leftTab == tabs[3].name &&
            <>
              <div className={`${tabs[3].name}-row center`}>
                <label htmlFor=''>Customer name</label>
                <input type='text' defaultValue='Name' />
              </div>
              <div className={`${tabs[3].name}-row`}>
                <label htmlFor=''>Customer address</label>
                <textarea defaultValue='Address' />
              </div>
              <div className={`${tabs[3].name}-row center`}>
                <label htmlFor=''>Project number</label>
                <input type='text' defaultValue='01' />
              </div>
              <div className={`${tabs[3].name}-row`}>
                <label htmlFor=''>Shipping address</label>
                <textarea defaultValue='Address for shipping to client' />
              </div>
              <span className={`${tabs[3].name}-row span-option`}><input type='checkbox' />Same as customer address</span>
              <div className={`${tabs[3].name}-row center`}>
                <label htmlFor=''>Shipping date</label>
                <DatePicker
                  todayButton='Today'
                  selected={new Date()}
                  dateFormat='yyyy-MM-dd'
                />
              </div>
            </>
        } */}
        </div>
      </div>
    );
  }
}

const LeftTabbedOptionsConnected = connect(
  ( /** @type { redux.store } */ s ) => ( {
    projectName: s.projectFor3d.projectName,
    forSettings: false
  } )
)( LeftTabbedOptions );

/**
 * @typedef {{
 *  dispatch: redux.d<redux.actions['initHappened']>;
 *  hidden: boolean;
 * }} NewProjectProps
 */
/** @augments { PureComponent<NewProjectProps> } */
class NewProject extends PureComponent {
  createProject = () => {
    this.props.dispatch( {
      type: 'SET_INIT_HAPPENED', value: true
    } );
  };

  render() {
    const {
      props: { hidden },
      createProject
    } = this;

    return (
      <div
        style={ hidden ? { display: 'none' } : {} }
        className={ style.newProject }
      >
        <LeftTabbedOptionsConnected { ...{ style } } />

        <div className={ style.start }>
          <Button onClick={ createProject }>Create project</Button>
        </div>
      </div>
    );
  }
}


export default connect( null )( NewProject );
