import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { toeKickHeight } from './toeKickHeight';
import { topDrawerHeight } from './topDrawerHeight';
import { toeKickType } from './toeKickType';
import { cabinets } from './cabinets';

const dimensionsComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['adminSettings']['projectSettings']['dimensions'],
    AllActions
  >;

export const dimensions = dimensionsComb( {
  cabinets,
  toeKickHeight,
  topDrawerHeight,
  toeKickType
} );
