export default ( { drawers } ) => {
  const core = [
    // Left side
    {
      edgeL1: 1,
      edgeL2: 0,
      edgeW1: 0,
      edgeW2: 0
    },
    // Right side
    {
      edgeL1: 1,
      edgeL2: 0,
      edgeW1: 0,
      edgeW2: 0
    },
    // Back
    {
      edgeL1: 0,
      edgeL2: 0,
      edgeW1: 0,
      edgeW2: 0
    },
    // Bottom
    {
      edgeL1: 1,
      edgeL2: 0,
      edgeW1: 0,
      edgeW2: 0
    },
    // Full top stretcher
    {
      edgeL1: 1,
      edgeL2: 0,
      edgeW1: 0,
      edgeW2: 0
    },
    // Shelf
    {
      edgeL1: 1,
      edgeL2: 0,
      edgeW1: 0,
      edgeW2: 0
    }
  ];

  const other = [
    // Doors
    {
      edgeL1: 1,
      edgeL2: 1,
      edgeW1: 1,
      edgeW2: 1
    },
    // Applied FE
    {
      edgeL1: 1,
      edgeL2: 0,
      edgeW1: 1,
      edgeW2: 0
    }
    // Drawers
  ];

  if ( drawers.length ) {
    for ( let i = 0; i < drawers.length; i++ ) {
      other.push( {
        edgeL1: 1,
        edgeL2: 0,
        edgeW1: 1,
        edgeW2: 0
      } );
    }
  }

  return { core, other };
};
