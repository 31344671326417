/* eslint-disable @typescript-eslint/ban-ts-ignore */
// @ts-check
import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@npmrost/storybook/stories/Vesta/Button';
import ViewMenu from './ViewMenu';
/*
 * import CameraMenu from './CameraMenu';
 *
 * import Button from 'c/shared/ThemeButton';
 */
import FileMenu from './FileMenu';
import WithIcon from './WithIcon';
import style from './index.styl';
import { cabinetsFrom3dToCalcualation } from './cabinetsFrom3dToCalculation';

const Chevron = () => (
  <img
    style={ { marginLeft: 10 } }
    src='/img/ChevronDown.svg'
    alt=''
  />
);

/**
 * @typedef {{
 *  tabs: Array<{
 *    icon: string;
 *    hasChevron: boolean;
 *    underText: 'View' | 'File...' | 'Add Item' | 'Add Wall' | 'Cancel' | 'Edit Item';
 *  }>;
 *  cornerMenuVisible: boolean;
 *  dispatch: import('redux-thunk').ThunkDispatch<redux._IStore, {}, redux._IActions['setItemsModalVisible'] | redux._IActions['SET_ITEM_EDITOR_VISIBLE']>,
 *  isCalculation: boolean
 * }} Props
 */

/**
 * @typedef {{
 *  chosen: Props['tabs'][0]['underText'],
 *  open: boolean;
 *  alternateAdd?: boolean;
 * }} State
 */


/** @augments { React.PureComponent<Props, State> } */
class CornerMenu extends React.PureComponent {
  /** @type { Partial<Props> } */
  static defaultProps = {
    tabs: [
      /*
       *
       * { img: "/img/Camera.svg", text: "/img/ChevronDown.svg", underText: "Camera" },
       */
      { icon: '/img/File.svg', hasChevron: false, underText: 'File...' },
      { icon: '/img/View.svg', hasChevron: true, underText: 'View' },
      { icon: '/img/addItem.png', hasChevron: false, underText: 'Add Item' },
      // { icon: '/img/addItem.png', hasChevron: false, underText: 'Edit Item' }
    ]
  }

  constructor( /** @type { Props } */ p ) {
    super( p );
    /** @type { State } */
    this.state = {
      chosen: 'File...',
      open: false
    };
  }

  componentDidMount() {
    document.addEventListener( 'click', this.clickOutside );
  }

  componentWillUnmount() {
    document.removeEventListener( 'click', this.clickOutside );
  }

  clickOutside = ( /** @type { MouseEvent } */e ) => {
    const rootDiv = document.querySelector( `.${ style.cornerMenu }` );
    if( rootDiv === null ) return;
    // @ts-ignore
    if( rootDiv.contains( e.target ) || !this.state.open ) {
      return;
    }

    this.setState( { open: false } );
  }

  addItemClick = ( /** @type { React.MouseEvent<HTMLDivElement> } */e ) => {
    this.props.dispatch( { type: 'SET_ITEMS_MODAL_VISIBLE', value: true } );
  }

  editItemClick = ( /** @type { React.MouseEvent<HTMLDivElement> } */e ) => {
    this.props.dispatch( { type: 'SET_ITEM_EDITOR_VISIBLE', value: true } );
  }

  tabClick = ( /** @type { Props['tabs'][0]['underText'] } */tab ) => () => {
    this.setState( ( prevState ) => {
      return {
        chosen: tab,
        open: prevState.chosen === tab ? !prevState.open : true
      };
    } );
  }


  render() {
    const {
      state: { chosen, open },
      props: {
        cornerMenuVisible,
        tabs: [fileMenu, viewMenu, additem, editItem],
        isCalculation,
        dispatch
      },
      tabClick,
      addItemClick,
      editItemClick
    } = this;

    const className = style.cornerMenu +
      ( cornerMenuVisible ? '' : ' hidden' );

    return (
      <div className={ className }>
        <div className='tabs'>

          <div className='tab' onClick={ tabClick( 'File...' ) }>
            <WithIcon img={ fileMenu.icon }>
              <Chevron />
            </WithIcon>
            { fileMenu.underText }
          </div>

          <div className='tab' onClick={ tabClick( 'View' ) }>
            <WithIcon img={ viewMenu.icon }>
              <Chevron />
            </WithIcon>
            { viewMenu.underText }
          </div>

          <div className='tab' onClick={ addItemClick }>
            <WithIcon img={ additem.icon } />
            { additem.underText }
          </div>

          {/* <div className='tab' onClick={ editItemClick }>
            <WithIcon img={ editItem.icon } />
            { editItem.underText }
          </div> */}

          {
            !isCalculation && (
              <Button minimalist onClick={ () => dispatch( cabinetsFrom3dToCalcualation ) }>
                <img src='/img/ImportExport.svg' alt='' />
              </Button>
            )
          }
        </div>
        <ViewMenu hidden={ !open || chosen !== 'View' } />
        <FileMenu
          hidden={ !open || chosen !== 'File...' }
        />
        {/*
        <FileMenu
          hideMenu={() => this.setState({ open: false })}

        />

        <CameraMenu hidden={ !open || chosen !== tabs[1].underText } /> */}
      </div>
    );
  }
}

export default connect(
  ( /** @type { redux._IStore } */ s ) => {

    return {
      cornerMenuVisible: s.modals.cornerMenuVisible,
      isCalculation: s.flags.generalViewMode === 'calculation'
      /*
       * calcModalVisible: cMP.calculationModalVisibility
       */
    };
  }
)(
  /** @type { React.ComponentType<never> } */(
    /** @type { unknown } */( CornerMenu )
  )
);
