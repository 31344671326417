// @ts-check
import React from 'react';
import { connect } from 'react-redux';
import { IconButton } from 'c/shared/icon-button';
import Modal from 'c/Modal/Modal';
import Button from 'c/shared/ThemeButton';
import Storage from 'scr/utilitiesStorage';
import style from './index.styl';

/** @augments { React.PureComponent< Props > } */
class SingletonCabinetJsonModal extends React.PureComponent {
  constructor ( /** @type { Props } */p ) {
    super( p );
    this.state = {
      config: ''
    };
  }

  componentDidMount () {
    const [cabinet] = Storage.get( 'selectedObject' );
    if( cabinet === null ) {
      throw new Error( 'Selected cabinet is null' );
    }

    const c = /** @type { import('cSrc/structured/singletonCabinet/core/GenericCabinet').GenericCabinet } */( cabinet );

    this.setState( {
      config: JSON.stringify(
        c.getConfig(), null, 2
      )
    } );
  }

  changeConfig = (
    /** @type { React.FormEvent<HTMLTextAreaElement> } */ e
  ) => this.setState( { config: e.currentTarget.value } )

  singletonCabinetJsonModalClose = () => {
    const { dispatch } = this.props;
    dispatch( { type: 'SET_SINGLETON_CABINET_JSON_MODAL_OPEN', value: false } );
  }


  reloadConfig = () => {
    let parsed;
    const {
      state: { config },
      props: { dispatch }
    } = this;

    try {
      parsed = JSON.parse( config );
    } catch ( e ) {
      // eslint-disable-next-line no-alert
      return alert( 'Invalid JSON!' );
    }

    // first of all we hide current object3dModal
    // dispatch( { type: 'SET_OBJECT_3D_MODAL_VISIBLE', data: { visible: false } } );

    // then do scene replacements
    const [cabinet] = /** @type { CabD.A.GenericCabinet[] } */(
      Storage.get( 'selectedObject' )
    );
    cabinet.reloadConfig( parsed );
    Storage.set( 'selectedObject', [null] );
    Storage.get( 'viewer' ).renderOnDemand.set();

    // and finally remove singletonCabinetJsonModal
    dispatch( { type: 'SET_SINGLETON_CABINET_JSON_MODAL_OPEN', value: false } );
    dispatch( { type: 'SET_RIGHT_SIDE_CABINET_MENU_MODE', value: 'addCabinet' } );

    return void 0;
  }

  render () {
    const {
      props: { visible, className = '' },
      state: { config },
      singletonCabinetJsonModalClose,
      changeConfig,
      reloadConfig
    } = this;

    return (
      <div className={ className + ( visible ? '' : ' hidden' ) }>
        <Modal className={ style.singletonCabinetJsonModal }>
          <h3>JSON</h3>
          <IconButton
            title='Close'
            variant='close'
            className={ style.close }
            onClick={ singletonCabinetJsonModalClose }
          />
          <textarea
            cols={ 30 }
            rows={ 10 }
            value={ config }
            onChange={ changeConfig }
          />
          <Button onClick={ reloadConfig }>
            Reload
          </Button>
        </Modal>
      </div>
    );
  }
}

/**
 * @typedef {{
 *  visible: boolean;
 *  dispatch: import('@r/storeUtils').d<
 *    redux._IActions['setRightSideCabinetmenuMode'] |
 *    redux._IActions['setSingletonCabinetJsonModalOpen']
 *  >;
 *  className?: string;
 * }} Props
 */
/*
 * json editing modal will be unlike other modals that
 * become only invisible. It will be added and removed
 *  to DOM so we can leverage componendDidMount method
 * and set up config from chosen item there
 */
/** @augments { React.PureComponent< Props > } */
class SingletonCabinetJsonModalWrap extends React.PureComponent {
  render () {
    const { props: { visible } } = this;
    if( !visible ) {
      return null;
    }

    return <SingletonCabinetJsonModal { ...this.props } />;
  }
}

export default connect(
  ( /** @type { redux._IStore } */
    { flags: { singletonCabinetJsonModalOpen: visible } }
  ) => ( { visible } )
)( SingletonCabinetJsonModalWrap );
