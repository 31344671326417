/* eslint-disable prefer-reflect */
// // @ts-check
/** @typedef { import('@npmrost/utils').px } px */
/**
 * @typedef {{
  *  padding : {
    *    left : px;
    *    right : px;
    *    top : px;
    *    bottom : px;
    *  }
    * }} _I2DViewSettings
    */

import {
  AmbientLight,
  Color,
  PointLight,
  Scene as ThreeScene,
  Vector3,
  BoxGeometry,
  Mesh,
  Group,
  MeshBasicMaterial,
  Box3,
  LinearFilter,
  CubeTexture,
  Matrix4,
  Vector4,
  DoubleSide,
  EdgesGeometry,
  LineSegments,
  CubeRefractionMapping,
  LineBasicMaterial,
  WebGLRenderer,
  OrthographicCamera,
  Euler,
  VertexColors,
  Vector2
} from 'three';

import {
  isFloorMounted,
  isWallMounted,
  toDoSetMaterialsByName,
  isCabinet,
  getTopLevelObject
} from 'c/ThreeJsWrap/Viewer/core/helpers/object3d';
import Storage from 'scr/utilitiesStorage';
import Countertop from 'c/Cabinet/cabinetdesigner/src/structured/singletonCabinet/core/Countertop';
import { LineMaterial } from '../core/LineMaterial';
import { LineSegments2 } from '../core/LineSegments2';
import { LineSegmentsGeometry } from '../core/LineSegmentsGeometry';
import { LineGeometry } from '../core/LineGeometry';
import { Line2 } from '../core/Line2';
import BaseApplianceWithCountertop from '../core/Items/BaseApplianceWithCountertop';
import BaseAppliance from '../core/Items/BaseAppliance';
import TallAppliance from '../core/Items/TallAppliance';
import WallAppliance from '../core/Items/WallAppliance';


import DragDropManager from '../DragDropManager';
import IntersectionDetector from '../IntersectionDetector';
import Floor from '../Floor';
import Roof from '../Roof';
import Wall from '../Wall';
import SnappingDetector from '../SnappingDetector';

export default class Scene extends ThreeScene {
  constructor( viewer, serialData ) {
    super();
    this.viewer = viewer;
    this.serialData = serialData;
    // this.background = new Color( 0, 0, 0 );
    this.floorplan2 = this.viewer.floorplan2;
    this.dragDropManager = new DragDropManager( this, this.viewer.camera );

    this.init();

  }

  setDimensionsVisibility( bool ) {
    /* this.traverse((obj) => {
       if (obj.arrowVert) {
         obj.arrowVert.visible = bool;
         obj.arrowVert.updateMatrixWorld(true);
       }
       if (obj.arrowHori) {
         obj.arrowHori.visible = bool;
         obj.arrowHori.updateMatrixWorld(true);
       }
     }); */
  }

  init() {

    const floorplanBox = this.viewer.floorplan2.getBoundingBox();
    const center = new Vector3();
    floorplanBox.getCenter( center );
    const min = floorplanBox.min;
    const max = floorplanBox.max;
    const maxDim = Math.max( max.x - min.x, max.z - min.z );


    this.initLights();
    if ( !this.serialData ) {
      // this.createFloorplan();
    }

    const backgrounds = Storage.get( 'assets' ).background;
    this.cubeTexture = new CubeTexture( [backgrounds[ 5 ].image, backgrounds[ 2 ].image, backgrounds[ 6 ].image, backgrounds[ 3 ].image, backgrounds[ 7 ].image, backgrounds[ 4 ].image] );
    this.cubeTexture.minFilter = LinearFilter;
    this.cubeTexture.magFilter = LinearFilter;
    this.cubeTexture.needsUpdate = true;
    this.setBackground( this.cubeTexture );
    this.defaultLocation = {
      wall: {
        object: this.viewer.floorplan2.stages[ 0 ].walls3D[ 0 ].wall2D_1.mountPlane,
        lastObject: null,
        position: new Vector3( 0, ( max.y - min.y ) * 0.75, 0 )
      },
      floor: {
        object: this.viewer.floorplan2.stages[ 0 ].rooms[ 0 ].floor2D.mountPlane,
        lastObject: null,
        position: new Vector3( 0, 0, 0 )
      }
    };
  }

  cloneDefaultLocation(defloc) {
    if (defloc==null) defloc = this.defaultLocation;
    return {
      wall: {
        object: defloc.wall.object,
        lastObject: defloc.wall.lastObject,
        position: defloc.wall.position.clone()
      },
      floor: {
        object: defloc.floor.object,
        lastObject: defloc.floor.lastObject,
        position: defloc.floor.position.clone()
      }
    }
  }
  setDefaultLocation(defloc) {
    this.defaultLocation.wall.object = defloc.wall.object;
    this.defaultLocation.wall.lastObject = defloc.wall.lastObject;
    this.defaultLocation.wall.position.copy(defloc.wall.position);

    this.defaultLocation.floor.object = defloc.floor.object;
    this.defaultLocation.floor.lastObject = defloc.floor.lastObject;
    this.defaultLocation.floor.position.copy(defloc.floor.position);
  }

  setBackground( cubeTexture ) {
    this.background = cubeTexture;
    this.cubeTexture = cubeTexture;
    /* this.traverse( ( obj ) => {
      if ( obj.isMesh ) {
        obj.material.envMap = cubeTexture;
      }
    } ); */

  }

  addKitchenStoveGas() {

    // materials correction
    function correction( result ) {
      result.traverse( ( o ) => {
        if ( o.isMesh ) {
          o.castShadow = true;
          o.receiveShadow = true;

          // o.material.envMap = this.viewer.cubeCamera.renderTarget.texture;
          // o.material.envMapIntensity = 0.04;
          o.material.roughness = 0.2;
          o.material.roughnessMap = null;
          o.material.metalness = 0.8;
          o.material.emissive = new Color( 0.02, 0.02, 0.02 );
          o.material.color = new Color( 1, 1, 1 );


        }
      } );
    }

    return new BaseAppliance(
      Storage.get( 'assets' ).appliances[ 3 ].data,
      new Euler( Math.PI / 2, 0, 0 ),
      { width: 30, height: 45, depth: 26 },
      { x: 0, y: 0.5, z: 0 },
      true,
      correction
    );
  }

  initLights() {
    const ambientLight = new AmbientLight( 0xffffff, 0.15 ); // 0.05
    this.add( ambientLight );

    /* let pointLight = new PointLight(0xffffff, 0.35, 1000, 2);
     pointLight.position.set(
       (max.x - min.x) / 3,
       (max.y - min.y) * 0.99,
       (max.z - min.z) / 3
     );
     pointLight.castShadow = true;
     pointLight.shadow.mapSize.width = 1024;
     pointLight.shadow.mapSize.height = 1024;
     pointLight.shadow.camera.near = 0.1;
     pointLight.shadow.camera.far = Math.sqrt(
       (max.x - min.x) * (max.x - min.x) +
       (max.y - min.y) * (max.y - min.y) +
       (max.z - min.z) * (max.z - min.z)
     );
     pointLight.shadow.bias = -0.001;
     this.add(pointLight);

     pointLight = new PointLight(0xffffff, 0.35, 1000, 2);
     pointLight.position.set(
       ((max.x - min.x) * 2) / 3,
       (max.y - min.y) * 0.99,
       (max.z - min.z) / 3
     );
     pointLight.castShadow = true;
     pointLight.shadow.mapSize.width = 1024;
     pointLight.shadow.mapSize.height = 1024;
     pointLight.shadow.camera.near = 0.1;
     pointLight.shadow.camera.far = Math.sqrt(
       (max.x - min.x) * (max.x - min.x) +
       (max.y - min.y) * (max.y - min.y) +
       (max.z - min.z) * (max.z - min.z)
     );
     pointLight.shadow.bias = -0.001;
     this.add(pointLight);

     pointLight = new PointLight(0xffffff, 0.35, 1000, 2);
     pointLight.position.set(
       (max.x - min.x) / 3,
       (max.y - min.y) * 0.99,
       (max.z - min.z) * 2 / 3
     );
     pointLight.castShadow = true;
     pointLight.shadow.mapSize.width = 1024;
     pointLight.shadow.mapSize.height = 1024;
     pointLight.shadow.camera.near = 0.1;
     pointLight.shadow.camera.far = Math.sqrt(
       (max.x - min.x) * (max.x - min.x) +
       (max.y - min.y) * (max.y - min.y) +
       (max.z - min.z) * (max.z - min.z)
     );
     pointLight.shadow.bias = -0.001;
     this.add(pointLight);

     pointLight = new PointLight(0xffffff, 0.35, 1000, 2);
     pointLight.position.set(
       ((max.x - min.x) * 2) / 3,
       (max.y - min.y) * 0.99,
       (max.z - min.z) * 2 / 3
     );
     pointLight.castShadow = true;
     pointLight.shadow.mapSize.width = 1024;
     pointLight.shadow.mapSize.height = 1024;
     pointLight.shadow.camera.near = 0.1;
     pointLight.shadow.camera.far = Math.sqrt(
       (max.x - min.x) * (max.x - min.x) +
       (max.y - min.y) * (max.y - min.y) +
       (max.z - min.z) * (max.z - min.z)
     );
     pointLight.shadow.bias = -0.001;
     this.add(pointLight); */
  }

  createCustom2DView( dpi, camera ) {
    const itemsMaterial = new LineBasicMaterial( {
      color: 0xffffff
    } );
    const edgesMaterial = new LineBasicMaterial( {
      color: 0x000000
    } );

    const edges = [];


    // setup 2D drawing scene
    this._background = this.background;
    this.background = new Color( 0xffffff );
    this.traverse( ( obj ) => {
      if ( obj.isMesh ) {
        obj._material = obj.material;
        obj.material = itemsMaterial;
        const edge = new EdgesGeometry( obj.geometry );
        const edgesMesh = new LineSegments( edge, edgesMaterial );
        obj.add( edgesMesh );
        edges.push( edgesMesh );
      }
    } );

    const canvas = document.createElement( 'canvas' );
    canvas.width = 1000 * window.innerWidth / window.innerHeight;
    canvas.height = 1000;
    const context = canvas.getContext( '2d' );

    const renderer = new WebGLRenderer( {
      antialias: true,
      preserveDrawingBuffer: true,
      alpha: true
    } );
    // renderer.gammaInput = true;
    // renderer.gammaOutput = true;
    // renderer.gammaFactor = 2;
    // renderer.toneMapping = Uncharted2ToneMapping;
    renderer.setSize( 1000 * window.innerWidth / window.innerHeight, 1000 );
    renderer.setPixelRatio(
      window.devicePixelRatio ? window.devicePixelRatio : 1
    );
    renderer.setClearColor( new Color( 1, 1, 1 ), 1.0 );
    renderer.autoClear = true;

    renderer.render( this, camera );
    context.drawImage( renderer.domElement, 0, 0 );

    // clean scene back to initial state
    this.background = this._background;
    Reflect.deleteProperty( this, '_background' );
    this.traverse( ( obj ) => {
      if ( obj.isMesh ) {
        obj.material = obj._material;
        Reflect.deleteProperty( obj, '_material' );
      }
    } );
    for ( let i = 0; i < edges.length; i += 1 ) {
      edges[ i ].parent.remove( edges[ i ] );
    }

    // return canvas2D
    return canvas;

  }

  saveVisibilityState() {
    this.traverse( ( obj ) => {
      if ( obj.isGroup || obj.isMesh ) {
        obj._visible = obj.visible;
      }
    } );
  }

  restoreVisibilityState() {
    this.traverse( ( obj ) => {
      if ( ( obj.isGroup || obj.isMesh || obj.isLineSegments ) && ( obj._visible === true || obj._visible === false ) ) {
        obj.visible = obj._visible;
        Reflect.deleteProperty( obj, '_visible' );
      }
    } );
    this.viewer.renderOnDemand.set();
  }

  setAllInvisible() {
    this.traverse( ( obj ) => {
      if ( obj.isGroup || obj.isMesh || obj.isLineSegments ) {
        obj.visible = false;
      }
    } );
  }

  setAllVisible() {
    this.traverse( ( obj ) => {
      if ( obj.isGroup || obj.isMesh || obj.isLineSegments ) {
        obj.visible = true;
      }
    } );
  }

  setVisibleOnlyItem( visibleObject ) {
    this.saveVisibilityState();
    this.setAllInvisible();

    if ( visibleObject.isFloor ) {
      visibleObject = visibleObject.room;
    }

    visibleObject.traverse( ( obj ) => {
      obj.visible = obj._visible;
      Reflect.deleteProperty( obj, '_visible' );
    } );

    visibleObject.traverseAncestors( ( obj ) => {
      obj.visible = obj._visible;
      Reflect.deleteProperty( obj, '_visible' );
    } );

    if ( visibleObject.isRoom ) {
      for ( let i = 0; i < visibleObject.walls2D.length; i += 1 ) {
        visibleObject.walls2D[ i ].traverse( ( obj ) => {
          obj.visible = obj._visible;
          Reflect.deleteProperty( obj, '_visible' );
        } );
        visibleObject.walls2D[ i ].traverseAncestors( ( obj ) => {
          obj.visible = obj._visible;
          Reflect.deleteProperty( obj, '_visible' );
        } );
      }

    }
    if ( visibleObject.room ) {
      visibleObject.room.visible = visibleObject.room._visible;
      Reflect.deleteProperty( visibleObject.room, '_visible' );
    }

    this.viewer.fitCameraToObjectFront( visibleObject );

    this.viewer.renderOnDemand.set();

  }

  createStandard2DView(
    obj,
    /** @type { _I2DViewSettings } */ settings =
    {
      padding:
        {
          left: 100,
          right: 20,
          top: 100,
          bottom: 20
        }
    } ) {
    if ( !obj ) {
      return;
    }
    if ( obj.isWall ) {
      return this.createStandardWall2DView( obj, settings );
    }
    if ( obj.isFloor ) {
      return this.createStandardFloor2DView( obj, settings );
    }


    return { canvas: null, dimensions: null };
  }

  createStandardFloor2DView( floor, /** @type { _I2DViewSettings } */ settings ) {

    const itemsMaterialVisible = new LineBasicMaterial( {
      color: 0xffffff,
      visible: true
    } );
    const edgesMaterial = new LineBasicMaterial( {
      color: 0x000000,
      side: DoubleSide
    } );

    const edges = [];


    const renderer = new WebGLRenderer( {
      antialias: true,
      preserveDrawingBuffer: true,
      alpha: true
    } );

    renderer.setClearColor( new Color( 1, 1, 1 ), 1.0 );
    // renderer.autoClear = true;


    const canvas = document.createElement( 'canvas' );
    const context = canvas.getContext( '2d' );

    const bBox = new Box3().setFromObject( floor.mesh );
    const center = new Vector3();
    bBox.getCenter( center );
    const size = new Vector3();
    bBox.getSize( size );

    let camera = new OrthographicCamera(
      -size.x / 2,
      size.x / 2,
      size.z / 2,
      -size.z / 2,
      1e-3,
      1e6
    );


    camera.position.copy( center.clone().add( new Vector3( 0, 90, 0 ) ) );
    camera.lookAt( center );

    camera.updateProjectionMatrix();

    if ( size.z < size.x ) {

      canvas.width = 2000;
      canvas.height = ( 2000 - settings.padding.left - settings.padding.right ) *
        ( size.z ) / ( size.x ) + settings.padding.top + settings.padding.bottom;
    } else {

      canvas.width = ( 2000 - settings.padding.top - settings.padding.bottom ) * ( size.x ) / ( size.z ) + settings.padding.left + settings.padding.right;
      canvas.height = 2000;

    }

    renderer.setSize( canvas.width - settings.padding.left - settings.padding.right, canvas.height - settings.padding.top - settings.padding.bottom );

    const uvbase = {
      width: canvas.width - settings.padding.left - settings.padding.right,
      height: canvas.height - settings.padding.top - settings.padding.bottom
    };

    // setup 2D drawing scene
    this._background = this.background;
    this.background = new Color( 0xffffff );
    this.traverseVisible( ( obj ) => {
      if ( obj.isMesh ) {
        obj._material = obj.material;
        if ( obj._material.visible ) {
          obj.material = itemsMaterialVisible;
          const edge = new EdgesGeometry( obj.geometry );
          const edgesMesh = new LineSegments( edge, edgesMaterial );
          obj.add( edgesMesh );
          edges.push( edgesMesh );
        }


      }
    } );

    renderer.render( this, camera );
    context.drawImage( renderer.domElement, settings.padding.left, settings.padding.top );
    renderer.getContext().getExtension( 'WEBGL_lose_context' ).loseContext();
    renderer.dispose();
    // clean scene back to initial state
    this.background = this._background;
    Reflect.deleteProperty( this, '_background' );
    this.traverseVisible( ( obj ) => {
      if ( obj.isMesh ) {
        obj.material = obj._material;
        Reflect.deleteProperty( obj, '_material' );
      }
    } );
    for ( let i = 0; i < edges.length; i += 1 ) {

      edges[ i ].parent.remove( edges[ i ] );
      edges[ i ].geometry.dispose();
      edges[ i ].material.dispose();
    }

    itemsMaterialVisible.dispose();
    edgesMaterial.dispose();

    /** @type { import('c/Modal/ModalFor2d/requirements')._ICanvasInput } */
    const dimensions = {
      dpi: uvbase.width / ( camera.right - camera.left ),
      root: {
        type: 'floor',
        vertices: {}
      },
      cabinets: {
        upper: [],
        tall: [],
        base: []
      },
      appliances: {
        upper: [],
        tall: [],
        base: []
      }
    };


    let uv;
    let result = {
      leftBottom: { x: null, y: null }, rightTop: { x: null, y: null }
    };


    uv = new Vector4( 0, 0, 0, 1 )
      .applyMatrix4( floor.matrixWorld )
      .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
      .applyMatrix4( camera.projectionMatrix );
    dimensions.root.vertices.leftBottom =
      {
        x: 0,
        y: 0,
        u: settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width,
        v: settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height
      };
    uv = new Vector4( size.x, 0, 0, 1 )
      .applyMatrix4( floor.matrixWorld )
      .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
      .applyMatrix4( camera.projectionMatrix );
    dimensions.root.vertices.rightBottom =
      {
        x: size.x,
        y: 0,
        u: settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width,
        v: settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height
      };
    uv = new Vector4( 0, size.z, 0, 1 )
      .applyMatrix4( floor.matrixWorld )
      .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
      .applyMatrix4( camera.projectionMatrix );
    dimensions.root.vertices.leftTop =
      {
        x: 0,
        y: size.z,
        u: settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width,
        v: settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height
      };
    uv = new Vector4( size.x, size.z, 0, 1 )
      .applyMatrix4( floor.matrixWorld )
      .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
      .applyMatrix4( camera.projectionMatrix );
    dimensions.root.vertices.rightTop =
      {
        x: size.x,
        y: size.z,
        u: settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width,
        v: settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height
      };


    this.traverse( ( obj ) => {
      if ( isCabinet( obj ) && isWallMounted( obj ) ) {

        result = {
          leftBottom: {
            u: null, v: null, x: null, y: null
          },
          rightTop: {
            u: null, v: null, x: null, y: null
          }
        };
        uv = new Vector4( obj.position.x - obj.getSizes().width / 2, obj.position.y - obj.getSizes().height / 2, obj.position.z + obj.getSizes().depth, 1 )
          .applyMatrix4( obj.parent.matrixWorld )
          .applyMatrix4( new Matrix4().getInverse( floor.matrixWorld ) );
        result.leftBottom.x = uv.x;
        result.leftBottom.y = uv.y;
        uv.applyMatrix4( floor.matrixWorld )
          .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
          .applyMatrix4( camera.projectionMatrix );

        result.leftBottom.u = settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width;
        result.leftBottom.v = settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height;


        uv = new Vector4( obj.position.x + obj.getSizes().width / 2, obj.position.y + obj.getSizes().height / 2, 0, 1 )
          .applyMatrix4( obj.parent.matrixWorld )
          .applyMatrix4( new Matrix4().getInverse( floor.matrixWorld ) );
        result.rightTop.x = uv.x;
        result.rightTop.y = uv.y;
        uv.applyMatrix4( floor.matrixWorld )
          .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
          .applyMatrix4( camera.projectionMatrix );

        result.rightTop.u = settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width;
        result.rightTop.v = settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height;
        if ( obj.getType() === 'upper' ) {
          dimensions.cabinets.upper.push( result );
        }
        if ( obj.getType() === 'upperAppliance' ) {
          dimensions.appliances.upper.push( result );
        }

      }
    } );

    floor.traverse( ( obj ) => {
      if ( isCabinet( obj ) ) {
        const snapping = obj.vestaObject.updateSnapping( 1e6 );

        result = {
          leftBottom: {
            u: null, v: null, x: null, y: null
          },
          rightTop: {
            u: null, v: null, x: null, y: null
          }
        };
        uv = ( new Vector4( obj.position.x, obj.position.y, 0, 1 )
          .add( ( new Vector4( -obj.getSizes().width / 2, obj.getSizes().height / 2, 0, 0 ) )
            .applyMatrix4( new Matrix4().makeRotationX( obj.initialRotation ) )
            .applyMatrix4( new Matrix4().makeRotationFromEuler( obj.rotation ) ) ) );
        result.leftBottom.x = uv.x;
        result.leftBottom.y = uv.y;
        uv.applyMatrix4( obj.parent.matrixWorld )
          .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
          .applyMatrix4( camera.projectionMatrix );
        result.leftBottom.u = settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width;
        result.leftBottom.v = settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height;

        uv = ( new Vector4( obj.position.x, obj.position.y, 0, 1 )
          .add( ( new Vector4( obj.getSizes().width / 2, -obj.getSizes().height / 2, -obj.getSizes().depth, 0 ) )
            .applyMatrix4( new Matrix4().makeRotationX( obj.initialRotation ) )
            .applyMatrix4( new Matrix4().makeRotationFromEuler( obj.rotation ) ) ) );
        result.rightTop.x = uv.x;
        result.rightTop.y = uv.y;
        uv.applyMatrix4( obj.parent.matrixWorld )
          .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
          .applyMatrix4( camera.projectionMatrix );
        result.rightTop.u = settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width;
        result.rightTop.v = settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height;
        if ( obj.getType() === 'base' ) {
          dimensions.cabinets.base.push( result );
        }
        if ( obj.getType() === 'baseAppliance' ) {
          dimensions.appliances.base.push( result );
        }
        if ( obj.getType() === 'tall' ) {
          dimensions.cabinets.tall.push( result );
        }
        if ( obj.getType() === 'tallAppliance' ) {
          dimensions.appliances.tall.push( result );
        }


      }
    } );

    // return canvas2D
    // console.log({ canvas, dimensions });

    return { canvas, dimensions };

  }

  /**
   * @returns {{
   *  canvas: HTMLCanvasElement;
   *  dimensions: import('c/Modal/ModalFor2d/requirements')._ICanvasInput;
   * }}
   */
  createStandardWall2DView( wall, /** @type { _I2DViewSettings } */ settings ) {

    const floor = wall.room.floor2D;
    const itemsMaterialVisible = new LineBasicMaterial( {
      color: 0xffffff,
      visible: true
    } );
    const edgesMaterial = new LineBasicMaterial( {
      color: 0x000000,
      side: DoubleSide
    } );

    const edges = [];


    const renderer = new WebGLRenderer( {
      antialias: true,
      preserveDrawingBuffer: true,
      alpha: true
    } );

    renderer.setClearColor( new Color( 1, 1, 1 ), 1.0 );
    // renderer.autoClear = true;


    const canvas = document.createElement( 'canvas' );
    const context = canvas.getContext( '2d' );

    const bBox = new Box3().setFromObject( wall.mesh );
    const center = new Vector3();
    bBox.getCenter( center );

    let camera = new OrthographicCamera(
      -wall.width / 2,
      wall.width / 2,
      wall.height / 2,
      -wall.height / 2,
      1e-3,
      1e6
    );

    camera.position.copy( center.clone().add( new Vector3( 0, 0, 40 ).applyMatrix4( new Matrix4().extractRotation( wall.matrixWorld ) ) ) );
    camera.lookAt( center );
    camera.updateProjectionMatrix();
    camera.updateMatrixWorld();

    if ( wall.height < wall.width ) {

      canvas.width = 2000;
      canvas.height = ( 2000 - settings.padding.left - settings.padding.right ) *
        ( wall.height ) / ( wall.width ) + settings.padding.top + settings.padding.bottom;
    } else {

      canvas.width = ( 2000 - settings.padding.top - settings.padding.bottom ) * ( wall.width ) / ( wall.height ) + settings.padding.left + settings.padding.right;
      canvas.height = 2000;

    }

    renderer.setSize( canvas.width - settings.padding.left - settings.padding.right, canvas.height - settings.padding.top - settings.padding.bottom );

    const uvbase = {
      width: canvas.width - settings.padding.left - settings.padding.right,
      height: canvas.height - settings.padding.top - settings.padding.bottom
    };

    const objects = [];

    /** @type { import('c/Modal/ModalFor2d/requirements')._ICanvasInput } */
    const dimensions = {
      dpi: uvbase.width / ( camera.right - camera.left ),
      root: { type: 'wall', vertices: {} },
      cabinets: {
        upper: [],
        tall: [],
        base: []
      },
      appliances: {
        upper: [],
        tall: [],
        base: []
      }
    };


    // setup 2D drawing scene
    this._background = this.background;
    this.background = new Color( 0xffffff );
    this.traverseVisible( ( obj ) => {
      if ( obj.isMesh ) {
        obj._material = obj.material;
        if ( obj._material.visible ) {
          obj.material = itemsMaterialVisible;
          const edge = new EdgesGeometry( obj.geometry );
          const edgesMesh = new LineSegments( edge, edgesMaterial );
          obj.add( edgesMesh );
          edges.push( edgesMesh );
        }
      }

      if ( isCabinet( obj ) ) {
        objects.push( obj );
      }
    } );

    let uv;
    let result = {
      leftBottom: { x: null, y: null }, rightTop: { x: null, y: null }
    };


    uv = new Vector4( 0, 0, 0, 1 )
      .applyMatrix4( wall.matrixWorld )
      .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
      .applyMatrix4( camera.projectionMatrix );
    dimensions.root.vertices.leftBottom =
      {
        x: 0,
        y: 0,
        u: settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width,
        v: settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height
      };
    uv = new Vector4( wall.width, 0, 0, 1 )
      .applyMatrix4( wall.matrixWorld )
      .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
      .applyMatrix4( camera.projectionMatrix );
    dimensions.root.vertices.rightBottom =
      {
        x: wall.width,
        y: 0,
        u: settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width,
        v: settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height
      };
    uv = new Vector4( 0, wall.height, 0, 1 )
      .applyMatrix4( wall.matrixWorld )
      .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
      .applyMatrix4( camera.projectionMatrix );
    dimensions.root.vertices.leftTop =
      {
        x: 0,
        y: wall.height,
        u: settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width,
        v: settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height
      };
    uv = new Vector4( wall.width, wall.height, 0, 1 )
      .applyMatrix4( wall.matrixWorld )
      .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
      .applyMatrix4( camera.projectionMatrix );
    dimensions.root.vertices.rightTop =
      {
        x: wall.width,
        y: wall.height,
        u: settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width,
        v: settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height
      };

    wall.traverse( ( obj ) => {
      if ( isCabinet( obj ) && obj !== wall ) {
        result = {
          leftBottom: {
            u: null, v: null, x: null, y: null
          },
          rightTop: {
            u: null, v: null, x: null, y: null
          }
        };
        uv = new Vector4( obj.position.x - obj.getSizes().width / 2, obj.position.y - obj.getSizes().height / 2, obj.position.z + obj.getSizes().depth, 1 )
          .applyMatrix4( wall.matrixWorld )
          .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
          .applyMatrix4( camera.projectionMatrix );
        result.leftBottom.u = settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width;
        result.leftBottom.v = settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height;
        result.leftBottom.x = obj.position.x - obj.getSizes().width / 2;
        result.leftBottom.y = obj.position.y - obj.getSizes().height / 2;

        uv = new Vector4( obj.position.x + obj.getSizes().width / 2, obj.position.y + obj.getSizes().height / 2, 0, 1 )
          .applyMatrix4( wall.matrixWorld )
          .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
          .applyMatrix4( camera.projectionMatrix );

        result.rightTop.u = settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width;
        result.rightTop.v = settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height;
        result.rightTop.x = obj.position.x + obj.getSizes().width / 2;
        result.rightTop.y = obj.position.y + obj.getSizes().height / 2;
        if ( obj.getType() === 'upper' ) {
          dimensions.cabinets.upper.push( result );
          objects.splice( objects.indexOf( obj ), 1 );
        }

        if ( obj.getType() === 'upperAppliance' ) {
          dimensions.appliances.upper.push( result );
          objects.splice( objects.indexOf( obj ), 1 );
        }
      }
    } );

    floor.traverse( ( obj ) => {
      if ( isCabinet( obj ) ) {
        const snapping = obj.vestaObject.updateSnapping( 1e6 );

        if ( snapping && snapping.plusY && snapping.plusY.object ) {
          const snappingTopLevelObject = getTopLevelObject( snapping.plusY.object );
          if ( snappingTopLevelObject === wall &&
            snapping.plusY.distance < obj.getSizes().height / 2 + 0.01 ) {

            result = {
              leftBottom: {
                u: null, v: null, x: null, y: null
              },
              rightTop: {
                u: null, v: null, x: null, y: null
              }
            };
            uv = ( new Vector4( obj.position.x, obj.position.y, 0, 1 )
              .add( ( new Vector4( -obj.getSizes().width / 2, obj.getSizes().height / 2, 0, 0 ) )
                .applyMatrix4( new Matrix4().makeRotationX( obj.initialRotation ) )
                .applyMatrix4( new Matrix4().makeRotationFromEuler( obj.rotation ) ) ) )
              .applyMatrix4( obj.parent.matrixWorld )
              .applyMatrix4( new Matrix4().getInverse( wall.matrixWorld ) );
            result.leftBottom.x = uv.x;
            result.leftBottom.y = uv.y;
            uv.applyMatrix4( wall.matrixWorld )
              .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
              .applyMatrix4( camera.projectionMatrix );
            result.leftBottom.u = settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width;
            result.leftBottom.v = settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height;

            uv = ( new Vector4( obj.position.x, obj.position.y, 0, 1 )
              .add( ( new Vector4( obj.getSizes().width / 2, -obj.getSizes().height / 2, -obj.getSizes().depth, 0 ) )
                .applyMatrix4( new Matrix4().makeRotationX( obj.initialRotation ) )
                .applyMatrix4( new Matrix4().makeRotationFromEuler( obj.rotation ) ) ) )
              .applyMatrix4( obj.parent.matrixWorld )
              .applyMatrix4( new Matrix4().getInverse( wall.matrixWorld ) );
            result.rightTop.x = uv.x;
            result.rightTop.y = uv.y;
            uv.applyMatrix4( wall.matrixWorld )
              .applyMatrix4( new Matrix4().getInverse( camera.matrixWorld ) )
              .applyMatrix4( camera.projectionMatrix );
            result.rightTop.u = settings.padding.left + ( uv.x / uv.w * 0.5 + 0.5 ) * uvbase.width;
            result.rightTop.v = settings.padding.top + ( -( uv.y / uv.w * 0.5 ) + 0.5 ) * uvbase.height;
            if ( obj.getType() === 'base' ) {
              dimensions.cabinets.base.push( result );
              objects.splice( objects.indexOf( obj ), 1 );
            }
            if ( obj.getType() === 'baseAppliance' ) {
              dimensions.appliances.base.push( result );
              objects.splice( objects.indexOf( obj ), 1 );
            }
            if ( obj.getType() === 'tall' ) {
              dimensions.cabinets.tall.push( result );
              objects.splice( objects.indexOf( obj ), 1 );
            }
            if ( obj.getType() === 'tallAppliance' ) {
              dimensions.appliances.tall.push( result );
              objects.splice( objects.indexOf( obj ), 1 );
            }
          }
        }
      }
    } );

    // do cabinets from other walls invisible
    for ( let i = 0; i < objects.length; i += 1 ) {
      objects[ i ].visible = false;
    }

    renderer.render( this, camera );
    context.drawImage( renderer.domElement, settings.padding.left, settings.padding.top );
    renderer.getContext().getExtension( 'WEBGL_lose_context' ).loseContext();
    renderer.dispose();


    // do cabinets from other walls visible again
    for ( let i = 0; i < objects.length; i += 1 ) {
      objects[ i ].visible = true;
    }

    // clean scene back to initial state
    this.background = this._background;
    Reflect.deleteProperty( this, '_background' );
    this.traverseVisible( ( obj ) => {
      if ( obj.isMesh ) {
        obj.material = obj._material;
        Reflect.deleteProperty( obj, '_material' );
      }
    } );

    for ( let i = 0; i < edges.length; i += 1 ) {

      edges[ i ].parent.remove( edges[ i ] );
      edges[ i ].geometry.dispose();
      edges[ i ].material.dispose();
    }

    itemsMaterialVisible.dispose();
    edgesMaterial.dispose();


    console.log( { canvas, dimensions } );

    // return canvas2D
    return { canvas, dimensions };

  }

  createStandard3DView() {
    const itemsMaterialVisible = new MeshBasicMaterial( {
      color: 0xffffff,
      visible: true
    } );
    const edgesMaterial = new LineBasicMaterial( {
      color: 0x000000,
      // linewidth: 7,
      side: DoubleSide
      // dashed: false,
      // resolution: new Vector2(window.innerWidth, window.innerHeight)
    } );

    const edges = [];

    const camera = this.viewer.camera;
    const renderer = this.viewer.renderer;
    const canvas = document.createElement( 'canvas' );
    canvas.width = renderer.domElement.width;
    canvas.height = renderer.domElement.height;
    const context = canvas.getContext( '2d' );


    // setup 2D drawing scene
    this._background = this.background;
    this.background = new Color( 0xffffff );
    this.traverseVisible( ( obj ) => {
      if ( obj.isMesh && !obj.isLineSegments2 ) {
        obj._material = obj.material;
        if ( obj._material.visible ) {
          obj.material = itemsMaterialVisible;
          const edge = new EdgesGeometry( obj.geometry );
          const edgesMesh = new LineSegments( edge, edgesMaterial );
          obj.add( edgesMesh );
          edges.push( edgesMesh );
        }
      }
    } );
    renderer.antialias = false;
    renderer.render( this, camera );
    renderer.antialias = true;
    context.drawImage( renderer.domElement, 0, 0 );

    // clean scene back to initial state
    this.background = this._background;
    Reflect.deleteProperty( this, '_background' );
    this.traverseVisible( ( obj ) => {
      if ( obj.isMesh ) {
        obj.material = obj._material;
        Reflect.deleteProperty( obj, '_material' );
      }
    } );
    for ( let i = 0; i < edges.length; i += 1 ) {
      edges[ i ].parent.remove( edges[ i ] );
      edges[ i ].geometry.dispose();
      // edges[i].material.dispose();
    }

    itemsMaterialVisible.dispose();
    edgesMaterial.dispose();
    this.viewer.composer.render();


    return { canvas, dimensions: null };

  }

  addObjectDirectly( object ) {
    if ( isFloorMounted( object ) ) {
      if ( this.defaultLocation.floor.lastObject && this.defaultLocation.floor.lastObject.parent ) {
        this.defaultLocation.floor.lastObject.parent.add( object );
        object.rotation.copy( this.defaultLocation.floor.lastObject.rotation );
        object.position.copy(
          this.defaultLocation.floor.lastObject.position
            .clone()
            .add(
              new Vector3(
                this.defaultLocation.floor.lastObject.getSizes().width / 2 + 0 * 0.0001,
                -( object.getSizes().height / 2 ) + ( this.defaultLocation.floor.lastObject.getSizes().height / 2 + 0 * 0.0001 - 5 ),
                0 * 0.001
              ).applyMatrix4( new Matrix4().makeRotationX( object.initialRotation ) )
                .applyMatrix4( new Matrix4().makeRotationFromEuler( this.defaultLocation.floor.lastObject.rotation ) )
            )
            .add(
              new Vector3(
                object.getSizes().width / 2 + 0 * 0.0001,
                0 * -( object.getSizes().height / 2 + 0 * 0.0001 ),
                0 * 0.001
              ).applyMatrix4( new Matrix4().makeRotationX( object.initialRotation ) )
                .applyMatrix4( new Matrix4().makeRotationFromEuler( this.defaultLocation.floor.lastObject.rotation ) )
            )
        );
      } else {
        this.defaultLocation.floor.object.add( object );
        object.position.copy(
          this.defaultLocation.floor.position
            .clone()
            .add(
              new Vector3(
                object.getSizes().width / 2 + 0.0001,
                -( object.getSizes().height / 2 + 0.0001 ),
                0.001
              )
            )
        );
      }
    } else if ( isWallMounted( object ) ) {
      if ( this.defaultLocation.wall.lastObject && this.defaultLocation.wall.lastObject.parent ) {
        this.defaultLocation.wall.lastObject.parent.add( object );
        object.position.copy(
          this.defaultLocation.wall.lastObject.position
            .clone()
            .add( new Vector3( object.getSizes().width / 2 + 0.0001, 0, 0.0001 ) )
            .add( new Vector3( this.defaultLocation.wall.lastObject.getSizes().width / 2 + 0.0001, 0, 0.0001 ) )
        );
      } else {
        this.defaultLocation.wall.object.add( object );
        object.position.copy(
          this.defaultLocation.wall.position
            .clone()
            .add( new Vector3( object.getSizes().width / 2 + 0.0001, 0, 0.0001 ) )
        );
      }
    } else {
      console.warn( 'Added object is not wall or floor mounted!' );
      return false;
    }

    const snappingDetector = new SnappingDetector( this, Storage.get( 'snappingTolerance' ) );
    object.updateMatrixWorld( true );
    object.snapping = snappingDetector.checkSnappingWithObject( object );
    if ( object && object.snapping && object.countertop ) {
      object.countertop.updateUVsOffset();
    }

    snappingDetector.snapToObject( object, object.snapping );

    let ret = true;
    const intersectionDetector = new IntersectionDetector( this );
    intersectionDetector.update();
    if ( intersectionDetector.checkSceneIntersectionsWithObject( object ) ) {
      console.warn( 'Impossible to add object. Collision detected' );
      ret = false;
      object.parent.remove( object );
      this.viewer.dispatch( {
        type: 'collisionDuringInsert',
        data: null
      } );
    } else if ( isFloorMounted( object ) ) {
      this.defaultLocation.floor.position.add(
        new Vector3( object.getSizes().width + 0.001, 0, 0 )
      );
      this.defaultLocation.floor.lastObject = object;
    } else if ( isWallMounted( object ) ) {
      this.defaultLocation.wall.position.add(
        new Vector3( object.getSizes().width + 0.001, 0, 0 )
      );
      this.defaultLocation.wall.lastObject = object;
    }

    this.viewer.renderOnDemand.set();
    return ret;
  }

  getAllCabinets() {
    const cabinets = [];

    this.traverse( ( obj ) => {
      if ( !obj.vestaObject ) return;

      const type = obj.vestaObject.getType();
      const t = type.match( 'cabinet' ) ? 'cabinet' : type;

      if ( t === 'cabinet' ) {
        cabinets.push( obj );
      }
    } );

    return cabinets;
  }

  toDoSetAllCabinetMaterialsByName(material, part) {
    const cabinets = this.getAllCabinets();
    toDoSetMaterialsByName(cabinets, material, part, this.viewer.doMgr);
  }

  setWireframeMode() {
    this.traverse( ( obj ) => {
      if ( obj.isMesh && isCabinet( getTopLevelObject( obj ) ) ) {
        obj.material.wireframe = true;
      }
    } );
  }

  resetWireframeMode() {
    this.traverse( ( obj ) => {
      if ( obj.isMesh && isCabinet( getTopLevelObject( obj ) ) ) {
        obj.material.wireframe = false;
      }
    } );
  }
}
