// @ts-check
import { simpleSet } from '@r/reducers/utils';
import { _ISimpleSet, AllActions } from '@r/storeUtils';

const s = simpleSet as _ISimpleSet<
  redux._IConstants['SET_CABINETS_SETTINGS_CROWN_MOLDING_IS_PRESENTED'],
  redux._IStore['projectFor3d']['cabinetsSettings']['isPresented'],
  AllActions
  >;
export const isPresented = s(
  'SET_CABINETS_SETTINGS_CROWN_MOLDING_IS_PRESENTED',
  true
);
