/**
 * @file This utility functions exist for the purpose of 
 * unification of the blueprint3d objects and it`s ancestors 
 * access.
 * 
 */

let blueprint3d, dirtyHacks;
/**
 * Returns global blueprint3d object. NOTE that it uses simple 
 * cache mechanic: it either returns memoized value inside 
 * \`blueprint3d\` variable or requests object from window
 * @returns {object} blueprint3d object
 */
export default function getBlueprintObject(){
  blueprint3d = blueprint3d || window.blueprint3d;
  return blueprint3d;
}
/**
 * Returns special property from blueprint3d that is used for 
 * so called dirty hacks e.g. functionalities that cannot be 
 * reliably stored in files and should be distributed through 
 * the global object hacked approach (for example MyComponent`s 
 * resetState can be called from react hierarchy and also from 
 * old build/app.js piece of code, and there is no way to make 
 * React methods propagate to outside of the components, except this)
 * @returns {object} hash of dirty hack functionalities
 */
export function getDirtyHacks(){
  dirtyHacks = dirtyHacks || getBlueprintObject().dirtyHacks;
  return dirtyHacks;
}
/**
 * Schedules threejs update using controller mechanism (setting 
 * controller.needsUpdate to true makes threejs update the scene)
 */
export function updateThings(){
  getBlueprintObject().three.controller.needsUpdate = true;
}