/* eslint-disable max-len */
import {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  _ISimpleAction,
  AllActions, _ISimpleSet, combRed
} from '@r/storeUtils';
import { simpleSet } from '@r/reducers/utils';
import { combineReducers } from 'redux';

declare global {

  namespace redux {

    namespace store {

      interface _ISelectedCabinet {
        crownMolding: {
          present: boolean;
          side: 'left' | 'right' | 'both' | 'none';
        };
      }
    }

    interface _IConstants {
      SET_SELECTED_CABINET_CROWN_MOLDING_PRESENT: 'SET_SELECTED_CABINET_CROWN_MOLDING_PRESENT';
      SET_SELECTED_CABINET_CROWN_MOLDING_SIDE: 'SET_SELECTED_CABINET_CROWN_MOLDING_SIDE';
    }

    interface _IActions {
      SET_SELECTED_CABINET_CROWN_MOLDING_PRESENT: _ISimpleAction<
        _IConstants['SET_SELECTED_CABINET_CROWN_MOLDING_PRESENT'],
        _IStore['selectedCabinet']['crownMolding']['present']
      >;
      SET_SELECTED_CABINET_CROWN_MOLDING_SIDE: _ISimpleAction<
        _IConstants['SET_SELECTED_CABINET_CROWN_MOLDING_SIDE'],
        _IStore['selectedCabinet']['crownMolding']['side']
      >;
    }
  }
}

const present = ( simpleSet as _ISimpleSet<
  redux._IConstants['SET_SELECTED_CABINET_CROWN_MOLDING_PRESENT'],
  redux._IStore['selectedCabinet']['crownMolding']['present'],
  AllActions
> )( 'SET_SELECTED_CABINET_CROWN_MOLDING_PRESENT', false );

const side = ( simpleSet as _ISimpleSet<
  redux._IConstants['SET_SELECTED_CABINET_CROWN_MOLDING_SIDE'],
  redux._IStore['selectedCabinet']['crownMolding']['side'],
  AllActions
> )( 'SET_SELECTED_CABINET_CROWN_MOLDING_SIDE', 'left' );


const crownMoldingCombine = combineReducers as
  combRed<redux._IStore['selectedCabinet']['crownMolding'], AllActions>;

export const crownMolding = crownMoldingCombine( {
  present,
  side
} );
