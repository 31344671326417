/* eslint-disable line-comment-position */
/* eslint-disable no-continue */
/* eslint-disable max-statements-per-line */
/* eslint-disable func-style */
/* eslint-disable require-jsdoc */
// @ts-check
/** @type {{ [key in redux.store['projectFor3d']['units']]: string }} */
export const unitsLabel = {
  ftAndInch: 'ft & in ',
  inch: 'inches',
  cm: 'cm',
  mm: 'mm',
  m: 'm'
};


export function inchToInch( /** @type { number } */u ) {
  return /** @type { inches } */( u );
}

export function feetAndInchToInch( /** @type { string } */u ) {
  let hash = {
      '\'': ( /** @type { number } */u ) => u * 12,
      '"': inchToInch
    },
    buf = '',
    rez = 0;

  for( let i = 0; i < u.length; i++ ) {
    const char = u[ i ];

    if( char === '"' || char === '\'' ) {
      rez += hash[ char ]( Number( buf ) );

      if( u[ i ] === '"' ) {
        return /** @type { inches } */( rez );
      }

      buf = '';
      continue;
    }

    buf += char;
  }

  return /** @type { inches } */( rez );
}

export function cmToInch( /** @type { number } */u ) {
  return /** @type { inches } */ ( u / 2.54 );
}

export function millimetersToInch( /** @type { number } */u ) {
  return cmToInch( u / 10 );
}


export function metersToInch( /** @type { number } */u ) {
  return cmToInch( u * 100 );
}


export function inchToFeetAndInch( /** @type { inches } */u ) {
  const q = Math.floor( u / 12 ),
    r = u % 12;

  return `${ q === 0 ? '' : ( `${ q }'` ) }${ r === 0 ? '' : `${ r }"` }`;
}

export function inchToMillimeters( /** @type { inches } */u ) {
  return u ** 2 / millimetersToInch( u );
}

export function inchToMeters( /** @type { inches } */u ) {
  return u ** 2 / metersToInch( u );
}

export function inchToCm( /** @type { inches } */u ) {
  return u ** 2 / cmToInch( u );
}


export const unitMapping = {
  ftAndInch: feetAndInchToInch,
  inch: inchToInch,
  cm: cmToInch,
  m: metersToInch,
  mm: millimetersToInch
};

export const reversedUnitMapping = {
  ftAndInch: inchToFeetAndInch,
  inch: inchToInch,
  cm: inchToCm,
  m: inchToMeters,
  mm: inchToMillimeters
};


const feetInchVariations = /** @type { feetAndInchRegexps } */( [
  String.raw`^\d+$`, // 12
  String.raw`^\d+\.$`, // 12.
  String.raw`^\d+\.\d+$`, // 12.2
  String.raw`^\d+\'$`, // 12'
  // String.raw`\d+\.\d+\'`,
  String.raw`^\d+\'\d+$`, // 12'2
  String.raw`^\d+\'\d+\"$`, // 12'2"
  String.raw`^\d+\'\d+\.$`, // 12'2.
  String.raw`^\d+\'\d+\.\d+$`, // 12'2.21
  String.raw`^\d+\'\d+\.\d+\"$`, // 12'2.21"
  /*
   * String.raw`\d+\.\d+\'\d+\"`,
   * String.raw`\d+\.\d+\'\d+\.\d+\"`,
   */
  String.raw`^\d+\"$`, // 12"
  String.raw`^\d+\.\d+\"$` // 12.2"
] );
export const feetInchRegexp = new RegExp( feetInchVariations.join( '|' ) );


function feetInchStrParse( /** @type { string } */s ) {
  const hash = { feet: '', inch: '' };
  let buf = '';

  for( let i = 0; i < s.length; i++ ) {
    if( s[ i ] === '\'' ) {
      hash.feet = buf;
      buf = '';
      continue;
    }

    if( s[ i ] === '"' ) {
      hash.inch = buf;
      break;
    }

    buf += s[ i ];
  }

  return hash;
}

/*
 * This function main aim is to negate precision errors
 * in calculations/ We store real numbers inside project
 * as 0,100000000001 but using this function we round up
 * to adequate numbers of digits corresponding to currently
 * selected units
 */
export function roundForUi(
  /** @type { string | number } */t,
  /** @type { redux._IStore['projectFor3d']['units'] } */currentUnits,
  /** @type { redux._IStore['projectFor3d']['units'] } */particularUnits
) {
  const hasDotEnd = typeof t === 'string' && Boolean( t.match( /^\d+\.$/ ) );
  let rtrn = 0;

  switch( particularUnits || currentUnits ) {
    case 'ftAndInch': {
      if( !String( t ).match( feetInchRegexp ) ) {
        return t;
      }

      let { feet: f, inch: i } = feetInchStrParse( String( t ) );

      return `${ f ? `${ f }'` : '' }${ i
        ? `${ Number( ( Number( i ) ).toFixed( 2 ) ) }"`
        : ''
      }`;
    }
    case 'inch': rtrn = Number( ( Number( t ) ).toFixed( 2 ) ); break;
    case 'cm': rtrn = Number( ( Number( t ) ).toFixed( 1 ) ); break;
    case 'mm': rtrn = Number( ( Number( t ) ).toFixed( 0 ) ); break;
    case 'm': rtrn = Number( ( Number( t ) ).toFixed( 3 ) ); break;
    default: break;
  }

  return hasDotEnd ? `${ rtrn }.` : rtrn;
}

/** @type { redux.GetLabelForNewUnits } */
export function getLabelForNewUnits( value, units ) {
  return /** @type { UnitsInputv2Value } */( String(
    ( units === 'ftAndInch' )
      ? roundForUi( reversedUnitMapping.ftAndInch( value ), units )
      : roundForUi( reversedUnitMapping[ units ]( value ), units )
  ) );
}

