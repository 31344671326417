import {
  Group, Mesh, MeshBasicMaterial, Shape, ShapeGeometry
} from 'three';
import { createGeometry } from 'c/ThreeJsWrap/Viewer/core/helpers/geometry';
import Storage from 'scr/utilitiesStorage';

export default class Roof extends Group {
  constructor(name, shape) {
    const shape_ = new Shape();
    let minX = shape[0].x;
    let maxX = shape[0].x;
    let minY = shape[0].y;
    let maxY = shape[0].y;

    shape_.moveTo(shape[0].x, shape[0].y);
    for (let i = 1; i < shape.length; i += 1) {
      shape_.lineTo(shape[i].x, shape[i].y)
      if (shape[i].x < minX) { minX = shape[i].x }
      if (shape[i].y < minY) { minY = shape[i].y }
      if (shape[i].x > maxX) { maxX = shape[i].x }
      if (shape[i].y > maxY) { maxY = shape[i].y }
    }
    shape_.lineTo(shape[0].x, shape[0].y);
    super();
    this.width = maxX - minX;
    this.depth = maxY - minY;
    let mesh = new Mesh(createGeometry(new ShapeGeometry(shape_)), Storage.get('namesToMaterials').service.invisible);
    this.name = name;
    mesh.name = name;
    mesh.isCollidable = true;
    mesh.isSnapable = true;
    this.mesh = mesh;
    this.add(mesh);
    this.isRoof = true;
  }
}
