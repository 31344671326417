/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { batchActions } from 'redux-batched-actions';
import { batchActionsWithTempl } from '@r/storeUtils';
import styled from 'styled-components';
import Storage from 'scr/utilitiesStorage';
import {
  WithCountertop
} from 'c/Cabinet/cabinetdesigner/src/structured/singletonCabinet/cabinets/helpers/WithCountertop';
import {
  reversedUnitMapping as rUM, roundForUi, unitMapping as uM
} from 'scr/metricsConversion';
import {
  UnitsInputValue
} from '@npmrost/storybook/stories/Vesta/Inputs/UnitsInput';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import modalStyl from 'c/CalculationLayer/Modal/index.styl';
import UnitsInputWithUnits from 'c/CalculationLayer/utils/UnitsInputWithUnits';
import { UnitsInputv2 } from 'c/CalculationLayer/utils/UnitsInputv2';
import { className } from '@npmrost/utils';
import CheckChangedValue from 'c/shared/borderForChangedValue';
import {
  regExForNumber
} from 'c/CabinetRightSideMenu/RightMenuForFloorPlan/components/utils';
// @ts-ignore
import isEqual from 'lodash/isEqual';


const InputsWrap = styled.div`
  > * {
    margin-bottom: 10px;
  }
`;
type _ITo4Walls = redux._IStore['selectedCabinet']['to4Walls'];
type actions = redux._IActions;

type allowedActionNamesForLabel = 'SET_SELECTED_CABINET_MINUS_OX_LABEL' |
  'SET_SELECTED_CABINET_PLUS_OX_LABEL' |
  'SET_SELECTED_CABINET_MINUS_OZ_LABEL' |
  'SET_SELECTED_CABINET_PLUS_OZ_LABEL';

type allowedActionNamesForValue = 'SET_SELECTED_CABINET_MINUS_OX_VALUE' |
  'SET_SELECTED_CABINET_PLUS_OX_VALUE' |
  'SET_SELECTED_CABINET_MINUS_OZ_VALUE' |
  'SET_SELECTED_CABINET_PLUS_OZ_VALUE';

const batch = batchActions as batchActionsWithTempl<
  actions[ allowedActionNamesForLabel | allowedActionNamesForValue ]
>;

interface _IStateProps {
  oXPlus: _ITo4Walls['oXPlus'];
  oXMinus: _ITo4Walls['oXMinus'];
  oZMinus: _ITo4Walls['oZMinus'];
  oZPlus: _ITo4Walls['oZPlus'];
  units: redux._IStore['projectFor3d']['units'];
}

interface _IDispatchProps {
  oXPlus: _ITo4Walls['oXPlus'];
  oXMinus: _ITo4Walls['oXMinus'];
  oZMinus: _ITo4Walls['oZMinus'];
  oZPlus: _ITo4Walls['oZPlus'];
  dispatch: Dispatch< ReturnType<typeof batch> >;
  measurePoint: string;
  rightSideCabinetMenuMode: redux._IStore['flags']['rightSideCabinetMenuMode'];
  changePosition: string;
}

type State = {
  oXMinusNew: _ITo4Walls['oXPlus'];
  oXPlusNew: _ITo4Walls['oXMinus'];
  oZMinusNew: _ITo4Walls['oZMinus'];
  oZPlusNew: _ITo4Walls['oZPlus'];
}

function getDistance( store: redux._IStore, prop: keyof _ITo4Walls ) {
  return store.selectedCabinet.to4Walls[ prop ];
}

type direction = 'minusX' | 'minusY' | 'plusX' | 'plusY';
type distancesTo4Walls = {
  [s in direction ]: _ITo4Walls[keyof _ITo4Walls]['value']
};


export const To4Walls = connect<
  _IStateProps, _IDispatchProps, {}, redux._IStore
>(
  ( s ) => ( {
    oXMinus: getDistance( s, 'oXMinus' ),
    oXPlus: getDistance( s, 'oXPlus' ),
    oZMinus: getDistance( s, 'oZMinus' ),
    oZPlus: getDistance( s, 'oZPlus' ),
    rightSideCabinetMenuMode: s.flags.rightSideCabinetMenuMode,
    units: s.projectFor3d.units
  } )
)(
  class To4Walls extends Component<_IStateProps & _IDispatchProps> {
    constructor( p: To4Walls['props'] ) {
      super( p );

      this.state = {
        oXMinusNew: { label: '0', value: 0 },
        oXPlusNew: { label: '0', value: 0 },
        oZMinusNew: { label: '0', value: 0 },
        oZPlusNew: { label: '0', value: 0 }
      } as State;
      const value = p.measurePoint === 'toCenter' ? 'center' : 'edge';
      this.updateInStore( value );
    }

    componentDidMount(): void {
      const {
        oXMinus,
        oXPlus,
        oZMinus,
        oZPlus
      } = this.props;

      this.setState( {
        oXMinusNew: oXMinus,
        oXPlusNew: oXPlus,
        oZMinusNew: oZMinus,
        oZPlusNew: oZPlus
      } );
    }

    componentDidUpdate( prevProps: _IDispatchProps ): void {
      const { measurePoint } = this.props;
      const newState = { ...this.state } as State;
      const {
        oXMinus,
        oXPlus,
        oZMinus,
        oZPlus,
        changePosition,
        rightSideCabinetMenuMode
      } = this.props;

      const [cabinet] = Storage.get( 'selectedObject' );

      if( !cabinet ) {
        return;
      }

      if(
        prevProps.rightSideCabinetMenuMode !== rightSideCabinetMenuMode &&
        rightSideCabinetMenuMode === 'editCabinetOnScene'
      ) {
        const value = measurePoint === 'toCenter' ? 'center' : 'edge';
        this.updateInStore( value );
      }

      if( prevProps.measurePoint !== measurePoint ) {
        const value = measurePoint === 'toCenter' ? 'center' : 'edge';
        this.updateInStore( value );
      }
      if( !isEqual( oXMinus, prevProps.oXMinus ) ) {
        newState.oXMinusNew = oXMinus;
      }
      if( !isEqual( oXPlus, prevProps.oXPlus ) ) {
        newState.oXPlusNew = oXPlus;
      }
      if( !isEqual( oZMinus, prevProps.oZMinus ) ) {
        newState.oZMinusNew = oZMinus;
      }
      if( !isEqual( oZPlus, prevProps.oZPlus ) ) {
        newState.oZPlusNew = oZPlus;
      }
      if( !isEqual( newState, this.state ) ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState( newState );
      }

      if( prevProps.changePosition !== changePosition ) {
        const value = measurePoint === 'toCenter' ? 'center' : 'edge';
        // @ts-ignore
        this.getSelected().updateMatrixWorld( true );
        this.updateInStore( value );
      }
    }

    setDistance = ( dir: direction ) => () => {
      const selected = this.getSelected();
      const { measurePoint } = this.props;
      if( selected ) {
        const { units } = this.props;
        const {
          // @ts-ignore
          oXMinusNew,
          // @ts-ignore
          oXPlusNew,
          // @ts-ignore
          oZMinusNew,
          // @ts-ignore
          oZPlusNew
        } = this.state;
        let label = '0';
        let side: 'left' | 'right' | 'top' | 'bottom';

        switch( dir ) {
          case 'minusX':
            side = 'left';
            label = oXMinusNew.label;
            break;
          case 'plusX':
            side = 'right';
            label = oXPlusNew.label;
            break;
          case 'minusY':
            side = 'top';
            label = oZMinusNew.label;
            break;
          default:
            side = 'bottom';
            label = oZPlusNew.label;
        }

        const value = units === 'ftAndInch'
          ? uM.ftAndInch( label )
          : uM[ units ]( Number( label ) );

        const toPoint = measurePoint === 'toCenter'
          ? 'center'
          : side;

        const measurePointValue = measurePoint === 'toCenter'
          ? 'center'
          : 'edge';

        selected.vestaObject
        // @ts-ignore
          .setDistanceToWall( side, toPoint, value );
        Storage.get( 'viewer' ).renderOnDemand.set();


        setTimeout( () => this.updateInStore( measurePointValue ), 50 );
      }
    }


    getSelected() {
      return Storage.get( 'selectedObject' )[ 0 ] as WithCountertop;
    }

    fromInches = ( value: inches ) => {
      return roundForUi( rUM[ this.props.units ]( value ), this.props.units );
    }

    handleChangeInput = ( key: string ) => ( value: string ) => {
      const state = { ...this.state };
      if( value.match( regExForNumber ) ) {
        // @ts-ignore
        state[ key ] = { label: value, value: Number( value ) };
      }
      if( !value ) {
        // @ts-ignore
        state[ key ] = { label: '0', value: 0 };
      }

      this.setState( state );
    };

    updateInStore ( measurePoint?: string ) {
      const {
        minusX: oXMinus,
        plusX: oXPlus,
        minusY: oZMinus,
        plusY: oZPlus
      } = this.getSelected()
        .vestaObject
        // @ts-ignore
        .getDistanceToWalls( measurePoint || 'edge' ) as distancesTo4Walls;

      const { fromInches } = this;

      this.props.dispatch(
        batch( [
          { type: 'SET_SELECTED_CABINET_MINUS_OX_VALUE', value: oXMinus },
          {
            type: 'SET_SELECTED_CABINET_MINUS_OX_LABEL',
            value: fromInches( oXMinus ) as UnitsInputValue
          },

          { type: 'SET_SELECTED_CABINET_PLUS_OX_VALUE', value: oXPlus },
          {
            type: 'SET_SELECTED_CABINET_PLUS_OX_LABEL',
            value: fromInches( oXPlus ) as UnitsInputValue
          },

          { type: 'SET_SELECTED_CABINET_MINUS_OZ_VALUE', value: oZMinus },
          {
            type: 'SET_SELECTED_CABINET_MINUS_OZ_LABEL',
            value: fromInches( oZMinus ) as UnitsInputValue
          },

          { type: 'SET_SELECTED_CABINET_PLUS_OZ_VALUE', value: oZPlus },
          {
            type: 'SET_SELECTED_CABINET_PLUS_OZ_LABEL',
            value: fromInches( oZPlus ) as UnitsInputValue
          }
        ] )
      );

    }

    render() {
      const {
        props: {
          oXMinus,
          oXPlus,
          oZMinus,
          oZPlus,
          units
        },
        setDistance
      } = this;

      const {
        // @ts-ignore
        oXMinusNew,
        // @ts-ignore
        oXPlusNew,
        // @ts-ignore
        oZMinusNew,
        // @ts-ignore
        oZPlusNew
      } = this.state;

      return (
        <div>
          <h3>Distance to 4 walls</h3>
          <InputsWrap>
            <CheckChangedValue changed={ !isEqual( oXMinus, oXMinusNew ) }>
              <LabeledField
                className={
                  `${ modalStyl.commonLabeledField } shortLabel wider`
                }
                name='To left'
              >
                <UnitsInputWithUnits units={ units }>
                  <UnitsInputv2
                    units={ units }
                    className={
                      `${
                        modalStyl.commonInput
                      } wide` as className<'input'>
                    }
                    onChange={ this.handleChangeInput( 'oXMinusNew' ) }
                    // @ts-ignore
                    onBlur={ setDistance( 'minusX' ) }
                    value={
                      oXMinusNew.label
                    }
                  />
                </UnitsInputWithUnits>
              </LabeledField>
            </CheckChangedValue>
            <CheckChangedValue changed={ !isEqual( oXPlus, oXPlusNew ) }>
              <LabeledField
                className={
                  `${ modalStyl.commonLabeledField } shortLabel wider`
                }
                name='To right'
              >
                <UnitsInputWithUnits units={ units }>
                  <UnitsInputv2
                    units={ units }
                    className={
                      `${
                        modalStyl.commonInput
                      } wide` as className<'input'>
                    }
                    onChange={ this.handleChangeInput( 'oXPlusNew' ) }
                    // @ts-ignore
                    onBlur={ setDistance( 'plusX' ) }
                    value={
                      oXPlusNew.label
                    }
                  />
                </UnitsInputWithUnits>
              </LabeledField>
            </CheckChangedValue>
            <CheckChangedValue changed={ !isEqual( oZPlus, oZPlusNew ) }>
              <LabeledField
                className={
                  `${ modalStyl.commonLabeledField } shortLabel wider`
                }
                name='Backward'
              >
                <UnitsInputWithUnits units={ units }>
                  <UnitsInputv2
                    units={ units }
                    className={
                      `${
                        modalStyl.commonInput
                      } wide` as className<'input'>
                    }
                    onChange={ this.handleChangeInput( 'oZPlusNew' ) }
                    // @ts-ignore
                    onBlur={ setDistance( 'plusY' ) }
                    value={
                      oZPlusNew.label
                    }
                  />
                </UnitsInputWithUnits>
              </LabeledField>
            </CheckChangedValue>
            <CheckChangedValue changed={ !isEqual( oZMinus, oZMinusNew ) }>
              <LabeledField
                className={
                  `${ modalStyl.commonLabeledField } shortLabel wider`
                }
                name='Forward'
              >
                <UnitsInputWithUnits units={ units }>
                  <UnitsInputv2
                    units={ units }
                    className={
                      `${
                        modalStyl.commonInput
                      } wide` as className<'input'>
                    }
                    onChange={ this.handleChangeInput( 'oZMinusNew' ) }
                    // @ts-ignore
                    onBlur={ setDistance( 'minusY' ) }
                    value={
                      oZMinusNew.label
                    }
                  />
                </UnitsInputWithUnits>
              </LabeledField>
            </CheckChangedValue>
          </InputsWrap>
        </div>
      );
    }
  }
);
