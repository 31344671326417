// @ts-check
import { simpleSet } from '@r/reducers/utils';
import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';

const fullNameComb = simpleSet as redux.reducers.user.fullName;
const fullName = fullNameComb(
  'SET_USER_FULL_NAME',
  '' as redux._IStore['user']['fullName']
);


const passwordComb = simpleSet as redux.reducers.user.password;
const password = passwordComb(
  'SET_USER_PASSWORD',
  '' as redux._IStore['user']['password']
);

const confirmPasswordComb = simpleSet as redux.reducers.user.confirmPassword;
const confirmPassword = confirmPasswordComb(
  'SET_USER_CONFIRM_PASSWORD',
  '' as redux._IStore['user']['confirmPassword']
);


const loginComb = simpleSet as redux.reducers.user.login;
const login = loginComb(
  'SET_USER_LOGIN',
  '' as redux._IStore['user']['login']
);


const loginErrorComb = simpleSet as redux.reducers.user.loginError;
const loginError = loginErrorComb(
  'SET_USER_LOGIN_ERROR',
  null
);

const signupErrorComb = simpleSet as redux.reducers.user.signupError;
const signupError = signupErrorComb(
  'SET_USER_SIGNUP_ERROR',
  null
);

const autologinAttemptedComb = simpleSet as
  redux.reducers.user.autologinAttempted;
const autologinAttempted = autologinAttemptedComb(
  'SET_AUTOLOGIN_ATTEMPTED',
  false
);

const loginSuccessComb = simpleSet as redux.reducers.user.loginSuccess;
const loginSuccess = loginSuccessComb(
  'SET_LOGIN_SUCCESS',
  false
);

const userComb = combineReducers as combRed<redux._IStore['user'], AllActions>;


export const user = userComb( {
  fullName,
  password,
  confirmPassword,
  login,
  loginError,
  signupError,
  autologinAttempted,
  loginSuccess
} );

