import {
  unitObj,
  processUnitSetLabelAction,
  processUnitSetValueAction,
  processSetUnitsAction
} from '@r/reducers/utils';
import { AllActions } from '@r/storeUtils';

type widthDepth = redux._IStore['projectFor3d']['roomWidthDepth'];
const roomWidthDepthDefault: widthDepth = {
  single: {
    width: { ...unitObj }
  },
  'L-shape': {
    width: { ...unitObj },
    depth: { ...unitObj }
  },
  'U-shape': {
    width: { ...unitObj },
    depth: { ...unitObj },
    depth2: { ...unitObj }
  },
  full: {
    width: { ...unitObj },
    depth: { ...unitObj }
  }
};
export const roomWidthDepth = (
  state = roomWidthDepthDefault,
  action: AllActions
) => {
  switch( action.type ) {
    case 'SET_FULL_FLOORPLAN_DEPTH_LABEL': {
      return {
        ...state,
        full: {
          ...state.full,
          depth: processUnitSetLabelAction( state.full.depth, action )
        }
      };
    }
    case 'SET_FULL_FLOORPLAN_DEPTH_VALUE': {
      return {
        ...state,
        full: {
          ...state.full,
          depth: processUnitSetValueAction( state.full.depth, action )
        }
      };
    }
    case 'SET_FULL_FLOORPLAN_WIDTH_LABEL': {
      return {
        ...state,
        full: {
          ...state.full,
          width: processUnitSetLabelAction( state.full.width, action )
        }
      };
    }
    case 'SET_FULL_FLOORPLAN_WIDTH_VALUE': {
      return {
        ...state,
        full: {
          ...state.full,
          width: processUnitSetValueAction( state.full.width, action )
        }
      };
    }
    case 'SET_L_SHAPE_FLOORPLAN_DEPTH_LABEL': {
      return {
        ...state,
        'L-shape': {
          ...state[ 'L-shape' ],
          depth: processUnitSetLabelAction( state[ 'L-shape' ].depth, action )
        }
      };
    }
    case 'SET_L_SHAPE_FLOORPLAN_DEPTH_VALUE': {
      return {
        ...state,
        'L-shape': {
          ...state[ 'L-shape' ],
          depth: processUnitSetValueAction( state[ 'L-shape' ].depth, action )
        }
      };
    }
    case 'SET_L_SHAPE_FLOORPLAN_WIDTH_LABEL': {
      return {
        ...state,
        'L-shape': {
          ...state[ 'L-shape' ],
          width: processUnitSetLabelAction( state[ 'L-shape' ].width, action )
        }
      };
    }
    case 'SET_L_SHAPE_FLOORPLAN_WIDTH_VALUE': {
      return {
        ...state,
        'L-shape': {
          ...state[ 'L-shape' ],
          width: processUnitSetValueAction( state[ 'L-shape' ].width, action )
        }
      };
    }
    case 'SET_SINGLE_FLOORPLAN_WIDTH_LABEL': {
      return {
        ...state,
        single: {
          ...state.single,
          width: processUnitSetLabelAction( state.single.width, action )
        }
      };
    }
    case 'SET_SINGLE_FLOORPLAN_WIDTH_VALUE': {
      return {
        ...state,
        single: {
          ...state.single,
          width: processUnitSetValueAction( state.single.width, action )
        }
      };
    }
    case 'SET_U_SHAPE_FLOORPLAN_DEPTH2_LABEL': {
      return {
        ...state,
        'U-shape': {
          ...state[ 'U-shape' ],
          depth2: processUnitSetLabelAction( state[ 'U-shape' ].depth2, action )
        }
      };
    }
    case 'SET_U_SHAPE_FLOORPLAN_DEPTH2_VALUE': {
      return {
        ...state,
        'U-shape': {
          ...state[ 'U-shape' ],
          depth2: processUnitSetValueAction( state[ 'U-shape' ].depth2, action )
        }
      };
    }
    case 'SET_U_SHAPE_FLOORPLAN_DEPTH_LABEL': {
      return {
        ...state,
        'U-shape': {
          ...state[ 'U-shape' ],
          depth: processUnitSetLabelAction( state[ 'U-shape' ].depth, action )
        }
      };
    }
    case 'SET_U_SHAPE_FLOORPLAN_DEPTH_VALUE': {
      return {
        ...state,
        'U-shape': {
          ...state[ 'U-shape' ],
          depth: processUnitSetValueAction( state[ 'U-shape' ].depth, action )
        }
      };
    }
    case 'SET_U_SHAPE_FLOORPLAN_WIDTH_LABEL': {
      return {
        ...state,
        'U-shape': {
          ...state[ 'U-shape' ],
          width: processUnitSetLabelAction( state[ 'U-shape' ].width, action )
        }
      };
    }
    case 'SET_U_SHAPE_FLOORPLAN_WIDTH_VALUE': {
      return {
        ...state,
        'U-shape': {
          ...state[ 'U-shape' ],
          width: processUnitSetValueAction( state[ 'U-shape' ].width, action )
        }
      };
    }
    case 'SET_UNITS': {
      return {
        ...state,
        full: {
          ...state.full,
          depth: processSetUnitsAction( state.full.depth, action ),
          width: processSetUnitsAction( state.full.width, action )
        },
        single: {
          ...state.single,
          width: processSetUnitsAction( state.single.width, action )
        },
        'U-shape': {
          ...state[ 'U-shape' ],
          depth2: processSetUnitsAction( state[ 'U-shape' ].depth2, action ),
          width: processSetUnitsAction( state[ 'U-shape' ].width, action ),
          depth: processSetUnitsAction( state[ 'U-shape' ].depth, action )
        },
        'L-shape': {
          ...state[ 'L-shape' ],
          depth: processSetUnitsAction( state[ 'L-shape' ].depth, action ),
          width: processSetUnitsAction( state[ 'L-shape' ].width, action )
        }
      };
    }
    default:
      return state;
  }
};
