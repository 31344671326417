/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import Storage from 'scr/utilitiesStorage';
import {
  LabeledUnitsInput
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledUnitsInput';
import {
  UnitsInputValue
} from '@npmrost/storybook/stories/Vesta/Inputs/UnitsInput';
import { RadioSelect } from 'c/CalculationLayer/utils/RadioSelect';
import { LabelAndValue, inches } from '@npmrost/utils';
import { Button } from '@npmrost/storybook/stories/Vesta/Button';
import {
  WithCountertop
} from 'cSrc/structured/singletonCabinet/cabinets/helpers/WithCountertop';

interface _IState {
  width: UnitsInputValue;
  side: 'left' | 'right';
}

type _IVariant = LabelAndValue<string, _IState['side']> & { chosen: boolean };

export class FinishEnd extends PureComponent<{}, _IState> {
  constructor( p: FinishEnd['props'] ) {
    super( p );

    this.state = {
      width: '1' as UnitsInputValue,
      side: 'left'
    };
  }

  changeWidth = ( width: UnitsInputValue ) => this.setState( { width } )

  chooseVariant = ( side: _IVariant['value'] ) => this.setState( { side } )

  addFE = () => (
    Storage.get( 'selectedObject' )[ 0 ] as WithCountertop
  ).addFillerEnd(
    this.state.side,
    Number( this.state.width ) as inches
  )

  render() {
    const {
      state: {
        width: value,
        side
      },
      changeWidth: onChange,
      chooseVariant,
      addFE: onClick
    } = this;

    const variants: _IVariant[] = [
      { label: 'Left', value: 'left', chosen: side === 'left' },
      { label: 'Right', value: 'right', chosen: side === 'right' }
    ];

    return (
      <>
        <h3>Finish End</h3>
        <RadioSelect { ...{ variants, chooseVariant } } />
        <LabeledUnitsInput { ... { value, onChange, name: 'Width' } } />
        <Button primary { ...{ onClick } }>Add Finish End</Button>
      </>
    );
  }
}
