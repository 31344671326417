import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  LabeledUnitsInput
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledUnitsInput';
import {
  LabeledPercentInput
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledPercentInput';
import {
  LabeledReactSelectSingle
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledReactSelectSingle';
import {
  Materials as MaterialsIcon,
  Details as DetailsIcon
} from '@npmrost/storybook/stories/Vesta/Icons';
import Part from './Part';
import Accessories from './Accessories';
import MaterialsSets from './MaterialsSets';
import { changeValue } from '../../utils';

const Wrapper = styled.div`
  width: 40%;
  margin-left: 20px;
`;

const Container = styled.div`
  padding: 20px 30px;
  ${ ( { theme: t } ) => `
    background-color: ${ t.arraySetsBackground };
    border-radius: ${ t.borderRadius };
  ` }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.span`
  margin-left: 10px;
  ${ ( { theme: t } ) => `
    color: ${ t.darkGreyColor };
  ` }
`;

const FlexContent = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    min-width: 25px;
  }
  & > div:not(:last-child) {
    margin-right: 10px;
  }
`;

const Content = styled.div`
  & > div {
    margin-bottom: 10px;
  }
`;

class Materials extends PureComponent {
  render() {

    const {
      props: {
        dispatch,
        dataKey,
        texts: {
          title: materialsTitle,
          countertopOverhang: {
            title: countertopOverhangTitle,
            left: countertopOverhangLeftText,
            back: countertopOverhangBackText,
            right: countertopOverhangRightText
          },
          countertopLabelName,
          hingesLabelName,
          pullsLabelName,
          drawerSystemsLabelName,
          markupLabelName
        },
        countertop,
        markup: { label: markup },
        pulls,
        hinges,
        drawerSystems: {
          options: drawerSystemsOptions,
          value: drawerSystemsValue
        },
        accessories,
        countertopOverhangLeft: { label: countertopOverhangLeft },
        countertopOverhangBack: { label: countertopOverhangBack },
        countertopOverhangRight: { label: countertopOverhangRight },
        materialsSetKey,
        subGroup
      }
    } = this;

    return (
      <Wrapper>
        <Container>
          <TitleWrapper>
            { MaterialsIcon }
            <Title>{ materialsTitle }</Title>
          </TitleWrapper>
          <Content>
            <MaterialsSets
              dataKey={ dataKey }
              materialsSetKey={ materialsSetKey }
              labeled
            />
            <Part
              dataKey={ dataKey }
              labelName={ countertopLabelName }
              propName='countertop'
              data={ countertop }
              isCanceled
            />
            {
              countertop.value.usedMarkup && (
                <LabeledPercentInput
                  name={ markupLabelName }
                  value={ markup }
                  onChange={ changeValue( dispatch, dataKey, 'markup', 'percent' ) }
                />
              )
            }
            <Part
              dataKey={ dataKey }
              labelName={ pullsLabelName }
              propName='pulls'
              data={ pulls }
              isCanceled
            />
            <Part
              dataKey={ dataKey }
              labelName={ hingesLabelName }
              propName='hinges'
              data={ hinges }
            />
            <LabeledReactSelectSingle
              name={ drawerSystemsLabelName }
              options={ drawerSystemsOptions }
              chosen={ [drawerSystemsValue] }
              choose={ changeValue( dispatch, dataKey, 'drawerSystems', 'selectWithOptions', drawerSystemsOptions ) }
            />
            <Accessories
              dataKey={ dataKey }
              data={ accessories }
              subGroup={ subGroup }
            />
          </Content>
        </Container>
        <Container>
          <TitleWrapper>
            { DetailsIcon }
            <Title>{ countertopOverhangTitle }</Title>
          </TitleWrapper>
          <FlexContent>
            <LabeledUnitsInput
              name={ countertopOverhangLeftText }
              value={ countertopOverhangLeft }
              onChange={ changeValue( dispatch, dataKey, 'countertopOverhangLeft', 'unit' ) }
            />
            <LabeledUnitsInput
              name={ countertopOverhangBackText }
              value={ countertopOverhangBack }
              onChange={ changeValue( dispatch, dataKey, 'countertopOverhangBack', 'unit' ) }
            />
            <LabeledUnitsInput
              name={ countertopOverhangRightText }
              value={ countertopOverhangRight }
              onChange={ changeValue( dispatch, dataKey, 'countertopOverhangRight', 'unit' ) }
            />
          </FlexContent>
        </Container>
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        estimationPreview: {
          table: {
            materials,
            countertopOverhang
          }
        },
        materials: {
          countertop: countertopLabelName,
          hinges: hingesLabelName,
          pulls: pullsLabelName,
          drawerSystems: drawerSystemsLabelName,
          markup: markupLabelName
        }
      }
    }
  } ) => {
    return {
      texts: {
        ...materials,
        countertopOverhang,
        countertopLabelName,
        hingesLabelName,
        pullsLabelName,
        drawerSystemsLabelName,
        markupLabelName
      }
    };
  }
)( Materials );
