import { simpleSet } from '@r/reducers/utils';
import { AllActions, _ISimpleSet } from '@r/storeUtils';

const s = simpleSet as _ISimpleSet<
  redux._IConstants['SET_ROOM_TEMPLATE'],
  redux._IStore['projectFor3d']['roomTemplate'],
  AllActions
>;

export const roomTemplate = s(
  'SET_ROOM_TEMPLATE',
  {
    value: 'full',
    label: 'Rectangular floor plan',
    baseMap: '/img/square.jpg'
  }
);
