import {
  BufferGeometry,
  Geometry,
  ShapeGeometry,
  BoxGeometry,
  ExtrudeGeometry,
  Vector2
} from 'three';
import { textureSize } from './constants';

export const correctGeometryUVs = (geometry) => {
  if (geometry instanceof Geometry) {
    if (geometry instanceof ShapeGeometry || geometry instanceof ExtrudeGeometry) {
      for (let i = 0, len1 = geometry.faceVertexUvs.length; i < len1; i += 1) {
        for (
          let j = 0, len2 = geometry.faceVertexUvs[i].length;
          j < len2;
          j += 1
        ) {
          for (
            let k = 0, len3 = geometry.faceVertexUvs[i][j].length;
            k < len3;
            k += 1
          ) {
            geometry.faceVertexUvs[i][j][k].multiplyScalar(1 / textureSize);
            if (geometry.userData && geometry.userData.grainOffset) {
              geometry.faceVertexUvs[i][j][k].add(geometry.userData.grainOffset);
            }
            if (geometry.userData && geometry.userData.grainDirection) {
              geometry.faceVertexUvs[i][j][k].rotateAround(new Vector2(), geometry.userData.grainDirection);
            }


          }
        }
      }
    } else if (geometry instanceof BoxGeometry) {
      for (let i = 0; i <= 2; i += 1) {

        /* correct UVs for countertops */
        for (let j = 0; j <= 3; j += 1) {
          geometry.faceVertexUvs[0][j][i].x -= 1;
        }

        for (let j = 4; j <= 7; j += 1) {
          geometry.faceVertexUvs[0][j][i].y -= 1;
        }
        /* --------------------------- */

        for (let j = 0; j <= 3; j += 1) {
          geometry.faceVertexUvs[0][j][i].x *=
            geometry.parameters.depth / textureSize;
          geometry.faceVertexUvs[0][j][i].y *=
            geometry.parameters.height / textureSize;
        }

        for (let j = 4; j <= 7; j += 1) {
          geometry.faceVertexUvs[0][j][i].x *=
            geometry.parameters.width / textureSize;
          geometry.faceVertexUvs[0][j][i].y *=
            geometry.parameters.depth / textureSize;
        }

        for (let j = 8; j <= 11; j += 1) {
          geometry.faceVertexUvs[0][j][i].x *=
            geometry.parameters.width / textureSize;
          geometry.faceVertexUvs[0][j][i].y *=
            geometry.parameters.height / textureSize;
        }
      }

      if (geometry.userData && geometry.userData.grainDirection) {
        for (let i = 0, len1 = geometry.faceVertexUvs.length; i < len1; i += 1) {
          for (
            let j = 0, len2 = geometry.faceVertexUvs[i].length;
            j < len2;
            j += 1
          ) {
            for (
              let k = 0, len3 = geometry.faceVertexUvs[i][j].length;
              k < len3;
              k += 1
            ) {
              geometry.faceVertexUvs[i][j][k].rotateAround(new Vector2(), geometry.userData.grainDirection);
            }
          }
        }
      }
    }


    geometry.uvsNeedUpdate = true;
  } else if (geometry instanceof BufferGeometry) {
    for (let i = 0; i < geometry.attributes.uv.length; i += 1) {
      geometry.attributes.uv[i] /= textureSize;
    }
  }
};

export const createGeometry = (geometry) => {
  correctGeometryUVs(geometry);

  return geometry;
};
