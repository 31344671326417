// @ts-check
import React, { PureComponent } from 'react';
import NewProject from 'c/NewProject/index';
// import MyProjects from 'c/MyProjects/index';
import { connect } from 'react-redux';
import { IconButton } from 'c/shared/icon-button';
import { ProjectsWithSearchConnected } from 'c/MyProjects';
import Modal from './Modal';
import style from './Modal.styl';

/**
 * @typedef {{
 *  dispatch: import('@r/storeUtils').d<
 *    redux._IActions['setMainModalOpen'] |
 *    redux._IActions['setMainModalTab']
 *  >;
 *  chosenTab: redux._IStore['modals']['mainModalTab'];
 *  className: string;
 *  mainModalOpen: redux._IStore['modals']['mainModalOpen'];
 *  tabs: {
 *    newProject: string;
 *    openProject: string;
 *  }
 * }} Props
 */
/** @augments { PureComponent<Props> } */
class MainModal extends PureComponent {
  /** @type { Partial<Props> } */
  static defaultProps = {
    tabs: {
      openProject: 'Open project',
      newProject: 'New project'
    }
  }

  chooseTabInner = (
    /**
     * @type { React.MouseEvent<HTMLSpanElement> & {
     *  currentTarget: {
     *    dataset: {
     *      tab: redux._IStore['modals']['mainModalTab']
     *    }
     *  }
     * }}
     */
    { currentTarget: { dataset: { tab: value } } }
  ) => this.props.dispatch( { type: 'SET_MAIN_MODAL_TAB', value } );


  specialClose = () => {
    const { dispatch } = this.props;

    // if( projectId === null ) return;

    dispatch( { type: 'SET_MAIN_MODAL_OPEN_STATE', value: false } );
  }

  render() {
    const {
      props: {
        chosenTab, className, mainModalOpen,
        tabs
      },
      specialClose
    } = this;
    const classNameForWrap =
      className + ( mainModalOpen ? '' : ' hidden' ) +
        ( chosenTab === 'newProject' ? ' shrinked' : '' );

    return (
      <div className={ classNameForWrap }>
        <Modal className=''>
          <IconButton
            title='Close'
            variant='close'
            className={ style.close }
            onClick={ specialClose }
          />
          <div className='tabs'>

            <span
              data-tab='newProject'
              onClick={ this.chooseTabInner }
              className={ chosenTab === 'newProject' && 'chosen' || '' }
            >
              { tabs.newProject }
            </span>

            <span
              data-tab='openProject'
              onClick={ this.chooseTabInner }
              className={ chosenTab === 'openProject' && 'chosen' || '' }
            >
              { tabs.openProject }
            </span>

          </div>
          <NewProject hidden={ chosenTab !== 'newProject' } />
          <ProjectsWithSearchConnected />
        </Modal>
      </div>
    );
  }
}

export default connect(
  ( /** @type { redux._IStore } */ { modals } ) => ( {
    chosenTab: modals.mainModalTab,
    mainModalOpen: modals.mainModalOpen
  } )
)( MainModal );
