import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  BorderedTreeViewWithSearch
} from '@npmrost/storybook/stories/Vesta/TreeView/BorderedWithSearch';
import {
  addNewCabinet
} from '@r/reducers/storeParts/calculationMode/projects/currentProject/cabinets';
import {
  getItems, getValueToIItem, updateFullDataFromPart, openTheFirstest
} from '@npmrost/storybook/stories/Vesta/Choice/TreeViewSelect';

const Wrapper = styled.div`
  padding-right: 7px;
`;

class Catalog extends PureComponent {
  chooseCabinet = ( { data, chosenItem } ) => {
    let {
      dispatch,
      cabinets
    } = this.props;

    const hash = data.reduce( ( acc, it ) => ( {
      ...acc,
      ...getValueToIItem( it )
    } ), {} );

    dispatch( {
      type: 'SET_CALCULATION_MODE_CABINETS',
      value: JSON.parse( JSON.stringify( cabinets ) )
        .map( ( it ) => updateFullDataFromPart( it, hash ) )
    } );

    if ( chosenItem ) {
      dispatch( addNewCabinet( chosenItem ) );
    }
  }

  searchByName = ( searchText ) => {
    const {
      dispatch,
      cabinets
    } = this.props;

    const copiedCabinets = JSON.parse( JSON.stringify( cabinets ) );

    const filtered = getItems( copiedCabinets, searchText )
      .map( ( item, i ) => openTheFirstest( item, searchText !== '' && i === 0 ) );

    const hash = filtered.reduce( ( acc, it ) => ( {
      ...acc,
      ...getValueToIItem( it )
    } ), {} );

    dispatch( {
      type: 'SEARCH_CALCULATION_MODE_CABINET_BY_NAME',
      value: searchText
    } );

    dispatch( {
      type: 'SET_CALCULATION_MODE_CABINETS',
      value: copiedCabinets
        .map( ( it ) => updateFullDataFromPart( it, hash ) )
    } );
  }

  render() {

    const {
      props: {
        texts: {
          searchCabinetByName
        },
        cabinets,
        searchText
      },
      chooseCabinet,
      searchByName
    } = this;

    return (
      <Wrapper>
        <BorderedTreeViewWithSearch
          placeholder={ searchCabinetByName }
          searchText={ searchText }
          items={ getItems( cabinets, searchText ) }
          choose={ chooseCabinet }
          setSearchText={ searchByName }
        />
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: texts
    },
    calculationMode: {
      cabinets,
      search: {
        cabinetName: searchText
      }
    }
  } ) => {
    return {
      cabinets,
      texts,
      searchText
    };
  }
)( Catalog );
