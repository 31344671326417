import { Group, Mesh, BoxGeometry } from 'three';
import Storage from 'scr/utilitiesStorage';
import { createGeometry } from '../helpers/geometry';

export default class Handle extends Group {
    mesh: Mesh;

    constructor() {
      super();
      this.mesh = new Mesh(
        createGeometry( new BoxGeometry( 2 / 2.54, 16 / 2.54, 2 / 2.54 ) ),
        Storage.get( 'materials' ).handle[ 0 ]
          .material

      );
      this.add( this.mesh );
    }

    rebuildGeometry() {

    }
}
