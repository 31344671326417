function DrawingToolbar (params) {

  var toolbarElement;
  var buttonEvent = undefined;
  var activeButton = undefined;
  var expandedButton = undefined;
  var iconPath = "/img/editshape/toolbar/";

  function init(params) {
    if (params.iconPath != undefined) {
      iconPath = params.iconPath;
    }

    toolbarElement = document.getElementById('drawingbar');
    toolbarElement.innerHTML = '';
    buttonEvent = params.onclick;

    addButtons(toolbarElement, params.data, false);
  }

  function addButtons(root, data, isChild) {
    for (var index in data) {
      addButton(root, data[index], isChild);
    }
  }

  function addButton(root, item, isChild) {
    var button = document.createElement("div");
    button.setAttribute("class", "toolbar-button");

    var image = document.createElement("img");
    image.setAttribute("src", iconPath + item.icon);

    image.onclick = function () {
      if (item.submenu) {
        if (expandedButton) {
          if (expandedButton != button) {
            expandedButton.classList.remove("expanded");
            button.classList.add("expanded");
            expandedButton = button;
          }
        } else {
          button.classList.add("expanded");
          expandedButton = button;
        }
        
      } else {
        if (activeButton) {
          if (activeButton != button) {
            activeButton.classList.remove("active");
            clearActiveElement(activeButton);
            button.classList.add("active");
            activeButton = button;
          }
        } else {
          button.classList.add("active");
          activeButton = button;
        }

        if (expandedButton) {
          expandedButton.classList.remove("expanded");
          expandedButton = null;
        }

        if (item.onclick) {
          item.onclick();
        } else if (buttonEvent) {
          buttonEvent(item.id);
        }

        if (isChild) {
          var parentDivTag = root.closest('.toolbar-button');
          var imgTag = parentDivTag.querySelector('img');
          imgTag.src = iconPath + item.icon;
          parentDivTag.classList.add("active");
        }
      }
    };

    button.appendChild(image);

    if (item.submenu) {
      var submenu = document.createElement("div");
      submenu.setAttribute("class", "sub");
      addButtons(submenu, item.submenu, true);
      button.appendChild(submenu);
    }

    root.appendChild(button);
  }

  function clearActiveElement(element) {
    var rootNode = element.closest('#drawingbar');
    var elements = rootNode.querySelectorAll('.active');
    if (!elements) return;
    for (var i = 0; i < elements.length; i++) {
      elements[i].classList.remove("active");
    }
  }

  init(params);
}

export default DrawingToolbar;