import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { main } from './main';
import { editProject } from './editProject';
import { account } from './account';

const modalsComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['modals'],
    AllActions
  >;

export const modals = modalsComb( {
  main,
  editProject,
  account
} );
