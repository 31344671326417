import { combineReducers } from 'redux';

import {
  getTreeViewStructure, groupData, getTreeViewDefaultValue
} from '@r/reducers/storeParts/calculationMode/utils';

import { value } from './value';
import { options } from './options';
import { searchText } from './searchText';

export const pulls = combineReducers( {
  value,
  options,
  searchText
} );

export function setDefaultPulls() {
  return ( dispatch, getStore ) => {
    const { calculationMode: { materialsFromDb } } = getStore();

    const options = getTreeViewStructure(
      groupData( materialsFromDb.Pull, ['type'] ),
      'name'
    );

    dispatch( {
      type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_PULLS_OPTIONS',
      value: options
    } );

    dispatch( {
      type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_PULLS_VALUE',
      value: getTreeViewDefaultValue( options )
    } );
  };
}
