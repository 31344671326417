
import * as THREE from 'three';
import {Model} from './index';
import { createCabinetFromConfig } from 'c/Cabinet/cabinetdesigner/src/structured/singletonCabinet/core/cabinetTypes';
import lodash from 'lodash';
import { createObjectFromConfig } from 'c/ThreeJsWrap/Viewer/core/cabinetdesigner/helpers';

class CabinetView extends Model {
  constructor(props) {
    super(props)
  }

  load3dModel() {
    const {src, initControl} = this.props;
    // src : json for item

    // make new object
    let obj3d = null;
    if (src.hasOwnProperty('attributes')) { // new cabinet approach
      obj3d = createObjectFromConfig(src);
      if (obj3d.mountTypes.includes('floor')) {
        obj3d.rotateX(-Math.PI/2);
      }
    } else if (src.hasOwnProperty('width')) { // old cabinet approach
      obj3d = createCabinetFromConfig(src);
    } else { // item
    }

    if (!obj3d) return;

    const orgToMove = new THREE.Vector3();
    
    // initControl = true; // always
    let init = initControl || !this.obj3d;
    if (!init && this.src) {
      if (this.src.hasOwnProperty('attributes') != src.hasOwnProperty('attributes')) init = true;
    }

    if (init) { // initialize camera control
      const bb = new THREE.Box3().setFromObject(obj3d);
      bb.getCenter(orgToMove);
      orgToMove.negate();

      const size = new THREE.Vector3();
      bb.getSize(size);
      const cameraOff = size.length() * 1.5;
      this.camera.position.set(0, 0, cameraOff);

      this.initCameraControl();
    } else if (this.obj3d) {
      orgToMove.copy(this.obj3d.position).sub(obj3d.position);
    }
    obj3d.translateX(orgToMove.x);
    obj3d.translateY(orgToMove.y);
    obj3d.translateZ(orgToMove.z);

    // set to new object
    if (this.obj3d) this.removeObject(this.obj3d);
    this.obj3d = obj3d;

    this.src = lodash.cloneDeep(src);
  }
  try3dModel() {
    this.obj3d.openAllDoors(this.props.doorsAngle * Math.PI / 180);
  }
}

export default CabinetView