
import {
    Group, Vector2, Box3, Vector3
} from 'three';

import Corner from './Corner';
import Wall3D from './Wall3D';
import Room from './Room';
import Utils from './utils';
import Floorplan from './Floorplan';

export default class Stage extends Group {
    corners: Corner[] = [];
    walls3D: Wall3D[] = [];
    rooms: Room[] = [];
    floorplan: Floorplan;
    isStage: boolean;

    constructor() {
        super();
        this.isStage = true;
    }

    addCorner(corner: Vector2 | Corner) {
        let result;
        if (corner.isVector2) {
            result = this.findCorner(corner)
            if (!result) {
                //console.log('new corner', corner);
                result = new Corner(corner);
                this.corners.push(result);
                this.add(result);
                //return result;
            }
        } else {
            result = corner;
            //return corner;
        }
        result.stage = this;
        result.rebuildGeometry();
        return result;
    }

    addWall3D(fromPosition: Vector2 | Corner | Wall3D, toPosition?: Vector2 | Corner, depth?: number, height?: number) {
        let result;
        if (fromPosition.isWall3D) {
            result = fromPosition;
        } else {
            result = new Wall3D(this.addCorner(fromPosition), this.addCorner(toPosition), depth, height);
        }
        this.walls3D.push(result);
        result.stage = this;
        this.add(result);

        /*  //added Wall intersects other walls?
          /let intersections = this.findIntersectedWalls3D(result);
          console.log('intersectedWalls', intersections);
  
          if (intersections.length > 0) {
              let points = [];
              for (let i = 0; i < intersections.length; i += 1) {
                  points.push(intersections[i].point);
                  this.divideWall3D(intersections[i].wall, [intersections[i].point]);
              }
  
              this.divideWall3D(result, points);
          }*/

        return result;
    }

    private findCorner(position: Vector2): Corner | undefined {
        for (let i = 0; i < this.corners.length; i += 1) {
            if (this.corners[i].position.x === position.x &&
                this.corners[i].position.z === position.y) {
                return this.corners[i];

            }
        }
        return;


    }

    findIntersectedWalls3D(wall3D: Wall3D): { wall: Wall3D, point: Vector2 }[] {
        const result = [];
        let ip;
        for (let i = 0; i < this.walls3D.length; i += 1) {
            if (this.walls3D[i] === wall3D) continue;
            ip = Utils.lineLineIntersectPoint(
                wall3D.from,
                wall3D.to,
                this.walls3D[i].from,
                this.walls3D[i].to)
            if (ip) {
                result.push({ wall: this.walls3D[i], point: ip })
            }
        }
        return result;
    }

    divideWall3D(wall3D: Wall3D, points: Vector2[]) {
        const newPoints = [];
        //console.warn('divideWall3D', wall3D.from.position, wall3D.to.position, points)
        for (let i = 0; i < points.length; i += 1) {
            if (
                !(Math.abs(points[i].x - wall3D.from.x) <= 1e-5 && Math.abs(points[i].y - wall3D.from.y) <= 1e-5) &&
                !(Math.abs(points[i].x - wall3D.to.x) <= 1e-5 && Math.abs(points[i].y - wall3D.to.y) <= 1e-5)
            ) {
                newPoints.push(points[i]);
            }
        }

        newPoints.sort(function (a, b) {
            let from = new Vector2(wall3D.from.x, wall3D.from.y);
            let distanceA = from.clone().sub(a).length();
            let distanceB = from.clone().sub(b).length();
            return distanceA - distanceB;
        });
        // console.log('wall : ', wall3D.from.x, wall3D.from.y, wall3D.to.x, wall3D.to.y, points, deleteWall);


        if (newPoints.length === 0) { return }
        let newWall;
        this.removeWall3D(wall3D);

        newWall = new Wall3D(this.addCorner(wall3D.from), this.addCorner(newPoints[0]), wall3D.depth, wall3D.height);
        this.addWall3D(newWall);
        //this.walls3D.push(newWall);
        //this.add(newWall);


        for (let i = 0; i < newPoints.length - 1; i += 1) {
            newWall = new Wall3D(this.addCorner(newPoints[i]), this.addCorner(newPoints[i + 1]), wall3D.depth, wall3D.height);
            this.addWall3D(newWall);
            //this.walls3D.push(newWall);
            //this.add(newWall);
        }

        newWall = new Wall3D(this.addCorner(newPoints[newPoints.length - 1]), this.addCorner(wall3D.to), wall3D.depth, wall3D.height);
        this.addWall3D(newWall);
        //this.walls3D.push(newWall);
        //this.add(newWall);

    }

    removeWall3D(wall3D: Wall3D) {
        for (let i = 0; i < this.walls3D.length; i += 1) {
            if (this.walls3D[i] === wall3D) {
                this.remove(wall3D);
                this.walls3D.splice(i, 1);
            }
        }
    }

    removeCorner(corner: Corner) {
        for (let i = 0; i < this.corners.length; i += 1) {
            if (this.corners[i] === corner) {
                this.remove(corner);
                this.corners.splice(i, 1);
            }
        }
    }

    mergeCorners(from: Corner, to: Corner) {
        if (from === to) return;

        for (let i = 0; i < this.walls3D.length; i += 1) {
            if ((this.walls3D[i].from === from && this.walls3D[i].to === to) ||
                (this.walls3D[i].from === to && this.walls3D[i].to === from)) {
                this.removeWall3D(this.walls3D[i]);
            }
        }

        const fromWalls = from.getNeighbourWalls3D();
        for (let i = 0; i < fromWalls.length; i += 1) {
            if (fromWalls[i].from === from) {
                fromWalls[i].changeFromTo(to);
            }
            if (fromWalls[i].to === from) {
                fromWalls[i].changeToTo(to);
            }
        }
        this.removeCorner(from);
        to.rebuildGeometry();
    }

    /*
    * Find the "rooms" in our planar straight-line graph. Rooms are set of the
    * smallest (by area) possible cycles in this graph. @param corners The
    * corners of the floorplan. @returns The rooms, each room as an array of
    * corners.
    */
    findRooms() {

        let scope = this;

        function _calculateTheta(previousCorner, currentCorner, nextCorner) {
            let theta = Utils.angle2pi(new Vector2(previousCorner.x - currentCorner.x, previousCorner.y - currentCorner.y), new Vector2(nextCorner.x - currentCorner.x, nextCorner.y - currentCorner.y));
            return theta;
        }

        function _removeDuplicateRooms(roomArray) {
            let results = [];
            let lookup = {};
            let hashFunc = function (corner) {
                return corner.id;
            };
            let sep = '-';
            for (let i = 0; i < roomArray.length; i++) {
                // rooms are cycles, shift it around to check uniqueness
                let add = true;
                let room = roomArray[i];
                for (let j = 0; j < room.length; j++) {
                    let roomShift = Utils.cycle(room, j);
                    var str = Utils.map(roomShift, hashFunc).join(sep);
                    if (lookup.hasOwnProperty(str)) {
                        add = false;
                    }
                }
                if (add) {
                    results.push(roomArray[i]);
                    lookup[str] = true;
                }
            }
            return results;
        }

        function _findTightestCycle(firstCorner, secondCorner) {
            let stack = [];
            let next = { corner: secondCorner, previousCorners: [firstCorner] };
            let visited = {};
            visited[firstCorner.id] = true;

            while (next) {
                // update previous corners, current corner, and visited corners
                let currentCorner = next.corner;
                visited[currentCorner.id] = true;

                // did we make it back to the startCorner?
                if (next.corner === firstCorner && currentCorner !== secondCorner) {
                    return next.previousCorners;
                }

                let addToStack = [];
                let adjacentCorners = next.corner.getNeighbourCorners();
                for (let i = 0; i < adjacentCorners.length; i++) {
                    let nextCorner = adjacentCorners[i];

                    // is this where we came from?
                    // give an exception if its the first corner and we aren't
                    // at the second corner
                    if (nextCorner.id in visited && !(nextCorner === firstCorner && currentCorner !== secondCorner)) {
                        continue;
                    }

                    // nope, throw it on the queue
                    addToStack.push(nextCorner);
                }

                let previousCorners = next.previousCorners.slice(0);
                previousCorners.push(currentCorner);
                if (addToStack.length > 1) {
                    // visit the ones with smallest theta first
                    let previousCorner = next.previousCorners[next.previousCorners.length - 1];
                    addToStack.sort(function (a, b) { return (_calculateTheta(previousCorner, currentCorner, b) - _calculateTheta(previousCorner, currentCorner, a)); });
                }

                if (addToStack.length > 0) {
                    // add to the stack
                    addToStack.forEach((corner) => {
                        stack.push({ corner: corner, previousCorners: previousCorners });
                    });
                }

                // pop off the next one
                next = stack.pop();
            }
            return [];
        }

        function _getRoomsFromCornersArray(cornersArray) {
            const result = [];
            for (let i = 0; i < cornersArray.length; i += 1) {
                let room = new Room(scope, cornersArray[i])
                result.push(room);
                scope.add(room);
            }
            return result;
        }

        // find tightest loops, for each corner, for each adjacent
        // TODO: optimize this, only check corners with > 2 adjacents, or
        // isolated cycles
        let loops = [];

        this.corners.forEach((firstCorner) => {
            firstCorner.getNeighbourCorners().forEach((secondCorner) => {
                loops.push(_findTightestCycle(firstCorner, secondCorner));
            });
        });

        // remove duplicates
        let uniqueLoops = _removeDuplicateRooms(loops);
        // remove CW loops
        let uniqueCCWLoops = Utils.removeIf(uniqueLoops, Utils.isClockwise);

        this.rooms = _getRoomsFromCornersArray(uniqueCCWLoops);
        return this.rooms;
    }

    getBoundingBox() {
        return new Box3().setFromObject(this);
    }

    removeWalls2D() {
        //remove all walls2D from walls3D 
        for (let i = 0; i < this.walls3D.length; i += 1) {
            this.walls3D[i].remove(this.walls3D[i].wall2D_1);
            this.walls3D[i].remove(this.walls3D[i].wall2D_2);
        }
    }

    saveFloorItems() {
        //save all floor Items to return them after rooms are regenerated
        const floorCabinets = [];
        this.traverse((obj) => {
            if (obj.getType && ['base', 'tall', 'tallAppliance', 'baseAppliance'].indexOf(obj.getType()) !== -1) {
                floorCabinets.push({ obj: obj, matrixWorld: obj.matrixWorld.clone() });
            }
        })
        return floorCabinets;
    }

    saveWallItems() {
        //save wall Items on merged walls2D to return them after rooms are regenerated
        const wallCabinets = [];
        const mergedWalls2D = [];

        for (let i = 0; i < this.rooms.length; i += 1) {
            this.rooms[i].traverse((obj) => {
                if (obj.isWall) {
                    mergedWalls2D.push(obj);
                };
            })
        }

        for (let i = 0; i < mergedWalls2D.length; i += 1) {
            mergedWalls2D[i].traverse((obj) => {
                if (obj.getType && (obj.getType() === 'upper')) {
                    wallCabinets.push(obj);
                }
            })
        }
        return wallCabinets;

    }

    restoreFloorItems(floorCabinets) {
        let newWall2D;
        for (let i = 0; i < floorCabinets.length; i += 1) {
            //floorCabinets[i].obj.matrixWorld.copy(floorCabinets[i].matrixWorld);
            // floorCabinets[i].obj.matrix.copy(floorCabinets[i].matrixWorld);
            newWall2D = this.getNewWall2DForItem(floorCabinets[i].obj);
            if (!newWall2D || !newWall2D.room) {
                this.attach(floorCabinets[i].obj);
            } else {
                newWall2D.room.floor2D.mountPlane.attach(floorCabinets[i].obj);
                floorCabinets[i].obj.position.z = 0;
            }
        }
    }

    restoreWallItems(wallCabinets) {
        for (let i = 0; i < wallCabinets.length; i += 1) {
            this.getNewWall2DForItem(wallCabinets[i]).mountPlane.add(wallCabinets[i]);
        }
    }

    getNewWall2DForItem(item) {

        //get all walls2D on stage
        let nearestWall2D;
        let minDistance = 1e10;
        const walls2D = [];
        this.traverse((obj) => {
            if (obj.isWall) {
                walls2D.push(obj)
            }
        })

        let itemWorldPosition = new Vector3();
        item.getWorldPosition(itemWorldPosition);
        //get min distance to wall2D
        for (let i = 0; i < walls2D.length; i += 1) {
            let distance = Math.abs(walls2D[i].getPlane().distanceToPoint(itemWorldPosition.clone()));
            if (distance < minDistance) {
                minDistance = distance;
                nearestWall2D = walls2D[i];
            }
        }

        return nearestWall2D;

    }

    rebuildGeometry() {
        const floorCabinets = this.saveFloorItems()
        // const wallCabinets = this.saveWallItems()

        this.removeWalls2D();

        for (let j = 0; j < this.rooms.length; j += 1) {
            this.remove(this.rooms[j]);
        }
        this.findRooms();

        this.restoreFloorItems(floorCabinets);
        // this.restoreWallItems(wallCabinets);
    }

}
