
import {
  Group, Box3, Vector3, MeshBasicMaterial, ShapeGeometry, Shape, DoubleSide, Mesh
} from 'three';

import Storage from 'scr/utilitiesStorage';
import Stage from './Stage';


export default class Floorplan extends Group {
  stages: Stage[] = [];

  constructor() {
    super();
    this.isFloorplan = true;
    this.createZeroAxisMarker();
  }

  addStage( stage: Stage ) {
    const viewer = Storage.get( 'viewer' );
    let result = stage || new Stage();
    result.floorplan = this;
    this.stages.push( result );
    this.add( result );
    result.position.set( 0, ( this.stages.length - 1 ) * 96, 0 );
    if ( viewer && viewer.floorplanModeParams ) {
      this.switchToStage( result );
      viewer.floorplan2.rebuildGeometry();
    }


    return result;
  }

  rebuildGeometry() {

    for ( let i = 0; i < this.stages.length; i += 1 ) {
      this.stages[ i ].rebuildGeometry();
    }

    const viewer = Storage.get( 'viewer' );
    if ( viewer ) {
      if ( viewer.floorplanMode ) {
        this.disableLights();
      }

      viewer.renderOnDemand.set();
      const bBoxSize = new Vector3();
      this.getBoundingBox().getSize( bBoxSize );
      viewer.controls.maxDistance = 5 * Math.max( bBoxSize.x, bBoxSize.y, bBoxSize.z ) + 1000;
      viewer.floorplanCamera.y += 1000;
      viewer.controls.update();
    }
  }

  getBoundingBox() {
    return new Box3().setFromObject( this );
  }

  disableLights() {
    this.traverse( ( obj ) => {
      if ( obj.isPointLight ) {
        obj.visible = false;
      }
    } );
  }

  enableLights() {
    this.traverse( ( obj ) => {
      if ( obj.isPointLight ) {
        obj.visible = true;
      }
    } );

  }

  createZeroAxisMarker() {
    const geometry = new ShapeGeometry(
      new Shape()
        .moveTo( -1, 10 )
        .lineTo( 1, 10 )
        .lineTo( 1, 1 )
        .lineTo( 10, 1 )
        .lineTo( 10, -1 )
        .lineTo( 1, -1 )
        .lineTo( 1, -10 )
        .lineTo( -1, -10 )
        .lineTo( -1, -1 )
        .lineTo( -10, -1 )
        .lineTo( -10, 1 )
        .lineTo( -1, 1 )
        .lineTo( -1, 10 ) )
      .rotateX( -Math.PI / 2 );
    const material = new MeshBasicMaterial( {
      color: 0xff0000, visible: true, transparent: true, opacity: 0.99, side: DoubleSide, depthTest: false
    } );
    const marker = new Mesh( geometry, material );
    this.marker = marker;
    this.add( marker );
  }

  changeCurrentStage( stage ) {

    const viewer = Storage.get( 'viewer' );

    viewer.scene.dragDropManager.object3dF = null;
    viewer.floorplanModeParams.currentStage = stage;
    viewer.setFloorplanVisibility( true );
    viewer.renderOnDemand.set();
  }

  switchToStage( stage ) {
    const viewer = Storage.get( 'viewer' );
    viewer.floorplanModeParams.currentStage = stage;
    // viewer.useFloorplanMode();
    viewer.setFloorplanVisibility( true );

    viewer.renderOnDemand.set();
  }

  removeStage( stage ) {
    if ( this.stages.length > 0 ) {
      const viewer = Storage.get( 'viewer' );
      viewer.scene.dragDropManager.object3dF = null;
      this.stages.splice( this.stages.indexOf( stage ), 1 );
      this.remove( stage );
      stage = null;
      viewer.floorplanModeParams.currentStage = this.stages[ 0 ];
    }
  }

}
