import React, { PureComponent } from 'react';
import Storage from 'scr/utilitiesStorage';
import {
  GenericCabinet
} from 'c/Cabinet/cabinetdesigner/src/structured/singletonCabinet/core/GenericCabinet';
import ThemeButton from 'c/shared/ThemeButton';
import {
  WallCabinet
} from 'cSrc/structured/singletonCabinet/cabinets/helpers/WallCabinet';
import styled from 'styled-components';
import { YMovement } from './components/YMovement';
import { To4Walls } from './components/to4Walls';
import { DistanceToWall } from './components/DistanceToWall';
import style from './index.styl';

interface _IYMovement {
  label: string;
  value: 'on' | 'off';
  chosen: boolean;
}

const Header = styled.h3`
  font-weight: 400;
  text-align: center;
`;

class Position extends PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    cabinetType: 'upper',
    yAxisMovement: 'off',
    measurePoint: 'toEdge',
    changePosition: ''
  };

  componentDidMount(): void {
    const cabinet =
      Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet as WallCabinet;
    if( cabinet ) {
      const cabinetType = cabinet.getType();
      let { yAxisMovement } = this.state;
      if( cabinet.getCanMoveVertically ) {
        yAxisMovement = cabinet.getCanMoveVertically() ? 'on' : 'off';
      }

      this.setState( {
        cabinetType,
        yAxisMovement
      } );
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(): void {
    const cabinet =
      Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet as WallCabinet;
    if( cabinet ) {
      const cabinetType = cabinet.getType();
      let { yAxisMovement } = this.state;
      if( cabinet.getCanMoveVertically ) {
        yAxisMovement = cabinet.getCanMoveVertically() ? 'on' : 'off';
      }

      if( this.state.cabinetType !== cabinetType ) {
        this.setState( {
          cabinetType,
          yAxisMovement
        } );
      }
    }
  }

  getCabinet() {
    const [cabinet] = Storage.get( 'selectedObject' );
    if( cabinet === null ) {
      throw new Error( 'Selected cabinet is null.' );
    }

    return cabinet as CabD.A.GenericCabinet;
  }

  getConfig() {
    return this.getCabinet().getConfig() as
      CabD.A.ContainerConfigs.ContainerConfig;
  }

  toLeft = () => {
    this.getCabinet().fitByWidthToLeft();
    this.setState( {
      changePosition: 'toLeft'
    } );
  };

  toCenter = () => {
    this.getCabinet().centerByWidthToSpace();
    this.setState( {
      changePosition: 'toCenter'
    } );
  };

  toRight = () => {
    this.getCabinet().fitByWidthToRight();
    this.setState( {
      changePosition: 'toRight'
    } );
  };

  fill = () => {
    this.getCabinet().fitByWidthToSpace();
    this.setState( {
      changePosition: 'fill'
    } );
  };

  toggleYAxisMovement = ( yAxisMovement: _IYMovement['value'] ) => {
    const cabinet = Storage.get( 'selectedObject' )[ 0 ] as WallCabinet;

    this.setState( { yAxisMovement } );
    cabinet.setCanMoveVertically( yAxisMovement === 'on' );
  };

  handleChangeMeasurePoint = ( measurePoint: string ) => {
    this.setState( {
      measurePoint
    } );
  };

  renderPositionControls = () => {
    const { cabinetType } = this.state;

    if( cabinetType !== 'upper' &&
        cabinetType !== 'base' &&
        cabinetType !== 'tall'
    ) {
      return null;
    }

    return (
      <div className='position-controls'>
        <h3>Position controls</h3>
        <div className='topBtns'>
          <ThemeButton onClick={ this.toLeft }>
            To Left
          </ThemeButton>
          <ThemeButton onClick={ this.toCenter }>
            To Center
          </ThemeButton>
          <ThemeButton onClick={ this.toRight }>
            To Right
          </ThemeButton>
        </div>
        <div className='bottomBtn'>
          <ThemeButton onClick={ this.fill }>
            Fill
          </ThemeButton>
        </div>
      </div>
    );
  };

  render() {
    const {
      cabinetType,
      yAxisMovement,
      measurePoint,
      changePosition
    } = this.state;

    return (
      <div className={ style.position }>
        {this.renderPositionControls()}
        <div className='distance-to-wall'>
          <DistanceToWall
            onChangeMeasurePoint={ this.handleChangeMeasurePoint }
            cabinetType={ cabinetType }
            changePosition={ changePosition }
          />
        </div>
        {(
          cabinetType.includes( 'upper' ) ||
          cabinetType.includes( 'wall' )
        ) && (
          <div>
            <Header>Y-axis movement</Header>
            <YMovement
              chosenVariant={ yAxisMovement }
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              chooseVariant={ this.toggleYAxisMovement }
            />
          </div>
        )}
        { ( cabinetType.includes( 'base' ) ||
          cabinetType.includes( 'tall' ) ) &&
        (
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          <To4Walls
            measurePoint={ measurePoint }
            changePosition={ changePosition }
          />
        ) }
      </div>
    );
  }
}

export default Position;
