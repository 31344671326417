/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { _ISimpleSet, _ISimpleAction, AllActions } from '@r/storeUtils';
import { simpleSet } from '@r/reducers/utils';

declare global {
  namespace redux {
    namespace store {
      interface _ISelectedCabinet {
        swing: null | 'left' | 'right';
      }
    }

    interface _IConstants {
      SET_SELECTED_CABINET_SWING: 'SET_SELECTED_CABINET_SWING';
    }

    interface _IActions {
      SET_SELECTED_CABINET_SWING: _ISimpleAction<
        _IConstants['SET_SELECTED_CABINET_SWING'],
        _IStore['selectedCabinet']['swing']
      >;
    }
  }
}

const s = simpleSet as _ISimpleSet<
  redux._IConstants['SET_SELECTED_CABINET_SWING'],
  redux._IStore['selectedCabinet']['swing'],
  AllActions
>;

export const swing = s(
  'SET_SELECTED_CABINET_SWING', null
);
