import React, { PureComponent } from 'react';
import { RadioSelect } from 'c/CalculationLayer/utils/RadioSelect';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Storage from 'scr/utilitiesStorage';
import style from './index.styl';

interface _IStateProps {
  side: redux._IStore['selectedCabinet']['crownMolding']['side'];
}

interface _IDispatchProps {
  dispatch: Dispatch<
    redux._IActions['SET_SELECTED_CABINET_CROWN_MOLDING_SIDE']
    >;
}

type sideVal = redux._IStore['selectedCabinet']['crownMolding']['side'];

interface _IVariant<V> {
  label: string;
  value: V;
  chosen: boolean;
}

export const Settings = connect<
  _IStateProps, _IDispatchProps, {}, redux._IStore
  >(
    ( s ) => ( {
      side: s.selectedCabinet.crownMolding.side
    } )
  )(
    class Settings extends PureComponent< _IStateProps & _IDispatchProps > {
      constructor( p: Settings['props'] ) {
        super( p );

        this.props.dispatch( {
          type: 'SET_SELECTED_CABINET_CROWN_MOLDING_SIDE',
          value: this.getBevels()
        } );
      }

      getSelectedObject = () => {
        const selectedObj = Storage.get( 'selectedObject' );
        if( selectedObj ) {
          return selectedObj[ 0 ];
        }

        return null;
      };

      getBevels() {
        const cM = this.getSelectedObject();
        if( !cM ) {
          return 'none';
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const { leftBevel, rightBevel } = cM;

        if( !leftBevel && !rightBevel ) return 'none';
        if( leftBevel && rightBevel ) return 'both';
        if( leftBevel && !rightBevel ) return 'left';

        return 'right';
      }

      chooseSide = ( value: _IVariant< sideVal >['value'] ) => {
        this.props.dispatch( {
          type: 'SET_SELECTED_CABINET_CROWN_MOLDING_SIDE',
          value: this.getBevels()
        } );

        const cM = this.getSelectedObject();
        if( !cM ) {
          return;
        }


        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        // eslint-disable-next-line prefer-reflect,prefer-spread
        cM.setBevels.apply( cM,
          value === 'both' ? [true, true]
            : value === 'none' ? [false, false]
              : value === 'left' ? [true, false]
                : [false, true]
        );

        Storage.get( 'viewer' ).renderOnDemand.set();

        setTimeout( () => this.props.dispatch( {
          type: 'SET_SELECTED_CABINET_CROWN_MOLDING_SIDE',
          value: this.getBevels()
        } ), 50 );
      }

      render() {
        const {
          props: {
            side
          },
          chooseSide
        } = this;

        const variantsForSide: _IVariant< sideVal >[] = [
          { label: 'Left', value: 'left', chosen: side === 'left' },
          { label: 'Right', value: 'right', chosen: side === 'right' },
          { label: 'Both', value: 'both', chosen: side === 'both' },
          { label: 'None', value: 'none', chosen: side === 'none' }
        ];

        return (
          <div className={ style.settingsBlock }>
            <div className='settingsHolder'>
              <h4>Choose side</h4>
              <RadioSelect< _IVariant<sideVal> > { ...{
                variants: variantsForSide,
                chooseVariant: chooseSide
              } }
              />
            </div>
          </div>
        );
      }
    }
  );
