import DoAction from './DoAction';

class DoAddRemoveEntity extends DoAction {
    constructor(entity, add_remove, parent) {
        super();

        this.entity = entity;
        this.parent = parent ? parent : entity.parent;
        this.add_remove = add_remove === undefined ? true : add_remove;
    }
	redo() {
		if (this.add_remove) this.addObject();
		else this.removeObject();
	}
	undo() {
		if (this.add_remove) this.removeObject();
		else this.addObject();
    }
    addObject() {
        this.parent.add(this.entity);
        this.entityMgr.updateShape();
        this.updateRender();
    }
    removeObject() {
        this.parent.remove(this.entity);
        this.entityMgr.updateShape();
        this.updateRender();
    }
}

export default DoAddRemoveEntity;