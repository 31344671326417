import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { base } from './base';
import { upper } from './upper';
import { tall } from './tall';

const cabinetsComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['projects']['currentProject']['settings']['dimensions']['cabinets'],
    AllActions
  >;

export const cabinets = cabinetsComb( {
  base,
  upper,
  tall
} );
