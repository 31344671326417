import { Step } from "./BaseCmd";

// command manager
class CmdMgr {
    curCmd;
    viewer;

    constructor(viewer) {
        this.viewer = viewer;
    }

    activateCmd(cmd) {
        if (this.curCmd) {
            this.curCmd.deActivate();
        }
        if (cmd) {
            cmd.viewer = this.viewer;
            cmd.doActivate();
        }
        this.curCmd = cmd;
    }
    isIdle() {
        return !this.curCmd || this.curCmd.isIdle;
    }
    drawSnap(ctx) {
        if (this.curCmd) this.curCmd.drawSnap(ctx);
    }

    onMouseMove(e) {
        if (this.curCmd) this.curCmd.onMouseMove(e);
        this.viewer.drawSnap();
    }
    onMouseClick(e) {
        if (this.curCmd) this.curCmd.onMouseClick(e);
        this.viewer.drawSnap();
    }
    onMouseWheel(e) {
        if (this.curCmd) this.curCmd.onMouseWheel(e);
        this.viewer.drawSnap();
    }
    onKeyDown(e) {
        if (this.curCmd) this.curCmd.onKeyDown(e);
        this.viewer.drawSnap();
    }
}

export default CmdMgr;