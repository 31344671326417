export default class RenderOnDemand {
  constructor () {
    this.flag = false;
  }

  set () {
    this.flag = true;
  }

  reset () {
    this.flag = false;
  }

  check () {
    return this.flag;
  }
}
