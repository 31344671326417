import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { value } from './value';
import { options } from './options';

const shippingCostComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['settings']['information']['shippingCost'],
    AllActions
  >;

export const shippingCost = shippingCostComb( {
  value,
  options
} );

export function setDefaultShippingCost() {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        adminSettings: {
          projectSettings: {
            shippingCost
          }
        }
      }
    } = getStore();

    const options = shippingCost
      .map( ( { name, value: { value } } ) => ( {
        label: name,
        value
      } ) );

    dispatch( {
      type: 'SET_CALCULATION_MODE_SHIPPING_COST_OPTIONS',
      value: options
    } );

    const value = options[ 0 ] || { label: '', value: 0 };

    dispatch( {
      type: 'SET_CALCULATION_MODE_SHIPPING_COST_VALUE',
      value
    } );

    dispatch( {
      type: 'SET_CALCULATION_MODE_SHIPPING_COST_MANUAL_LABEL',
      value: value.value ? String( value.value ) : ''
    } );

    dispatch( {
      type: 'SET_CALCULATION_MODE_SHIPPING_COST_MANUAL_VALUE',
      value: value.value
    } );
  };
}
