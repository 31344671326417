import {
  Matrix4, Quaternion, Raycaster, Vector3, Vector4, Euler
} from 'three';
import { getTopLevelObject } from './core/helpers/object3d';

export default class SnappingDetector {
  constructor(scene, tolerance) {
    this.tolerance = tolerance;
    this.raycaster = new Raycaster();
    this.scene = scene;
  }

  // eslint-disable-next-line complexity
  check(object3d, intersectionObjects) {
    const result = { x: null, y: null };
    let direction4;
    let intersections;
    const initialRotation = object3d.initialRotation
      ? -object3d.initialRotation
      : 0;

    let origin4 = [
      new Vector4(0, 0, 0.0001, 1)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(0, -object3d.getSizes().height / 2 + 0.0001, 0.0001, 1)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(0, object3d.getSizes().height / 2 - 0.0001, 0.0001, 1)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(0, 0, object3d.getSizes().depth / 2, 1)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(
        0,
        -object3d.getSizes().height / 2 + 0.0001,
        object3d.getSizes().depth / 2,
        1
      )
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(
        0,
        object3d.getSizes().height / 2 - 0.0001,
        object3d.getSizes().depth / 2,
        1
      )
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(0, 0.0001, object3d.getSizes().depth - 0.0001, 1)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(
        0,
        -object3d.getSizes().height / 2 + 0.0001,
        object3d.getSizes().depth - 0.0001,
        1
      )
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(
        0,
        object3d.getSizes().height / 2 - 0.0001,
        object3d.getSizes().depth - 0.0001,
        1
      )
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld)
    ];

    for (let i = 0; i < 9; i += 1) {
      direction4 = new Vector4(1, 0, 0, 0)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld);
      this.raycaster.set(
        new Vector3(origin4[i].x, origin4[i].y, origin4[i].z),
        new Vector3(direction4.x, direction4.y, direction4.z).normalize()
      );

      intersections = this.raycaster.intersectObjects(intersectionObjects);

      if (
        intersections.length > 0 &&
        intersections[0].distance <=
        this.tolerance + object3d.getSizes().width / 2 &&
        (!result.plusX ||
          intersections[0].distance <= result.plusX.distance)
      ) {
        result.plusX = intersections[0];
        result.plusX.distance = intersections[0].distance;
        result.plusX.axis = 'x';
      }

      direction4 = new Vector4(-1, 0, 0, 0)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld);
      this.raycaster.set(
        new Vector3(origin4[i].x, origin4[i].y, origin4[i].z),
        new Vector3(direction4.x, direction4.y, direction4.z).normalize()
      );

      intersections = this.raycaster.intersectObjects(intersectionObjects);

      if (
        intersections.length > 0 &&
        intersections[0].distance <=
        this.tolerance + object3d.getSizes().width / 2 &&
        (!result.minusX ||
          intersections[0].distance <= result.minusX.distance)
      ) {
        result.minusX = intersections[0];
        result.minusX.distance = intersections[0].distance;

        result.minusX.axis = 'x';
      }
    }

    origin4 = [
      new Vector4(0, 0, 0.0001, 1)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(-object3d.getSizes().width / 2 + 0.0001, 0, 0.0001, 1)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(object3d.getSizes().width / 2 - 0.0001, 0, 0.0001, 1)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(0, 0, object3d.getSizes().depth / 2, 1)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(
        -object3d.getSizes().width / 2 + 0.0001,
        0,
        object3d.getSizes().depth / 2,
        1
      )
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(
        object3d.getSizes().width / 2 - 0.0001,
        0,
        object3d.getSizes().depth / 2,
        1
      )
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(0, 0, object3d.getSizes().depth - 0.0001, 1)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(
        -object3d.getSizes().width / 2 + 0.0001,
        0,
        object3d.getSizes().depth - 0.0001,
        1
      )
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld),
      new Vector4(
        object3d.getSizes().width / 2 - 0.0001,
        0,
        object3d.getSizes().depth - 0.0001,
        1
      )
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld)
    ];

    for (let i = 0; i < 9; i += 1) {
      direction4 = new Vector4(0, 1, 0, 0)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld);
      this.raycaster.set(
        new Vector3(origin4[i].x, origin4[i].y, origin4[i].z),
        new Vector3(direction4.x, direction4.y, direction4.z).normalize()
      );

      intersections = this.raycaster.intersectObjects(intersectionObjects);

      if (
        intersections.length > 0 &&
        intersections[0].distance <=
        this.tolerance + object3d.getSizes().height / 2 &&
        (!result.plusY ||
          intersections[0].distance <= result.plusY.distance)
      ) {
        result.plusY = intersections[0];
        result.plusY.distance = intersections[0].distance;
        result.plusY.axis = 'y';
      }

      direction4 = new Vector4(0, -1, 0, 0)
        .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
        .applyMatrix4(object3d.matrixWorld);
      this.raycaster.set(
        new Vector3(origin4[i].x, origin4[i].y, origin4[i].z),
        new Vector3(direction4.x, direction4.y, direction4.z).normalize()
      );

      intersections = this.raycaster.intersectObjects(intersectionObjects);

      if (
        intersections.length > 0 &&
        intersections[0].distance <=
        this.tolerance + object3d.getSizes().height / 2 &&
        (!result.minusY ||
          intersections[0].distance <= result.minusY.distance)
      ) {
        result.minusY = intersections[0];

        result.minusY.distance = intersections[0].distance;

        result.minusY.axis = 'y';
      }
    }

    // Countertop snapping /////////////////////////////////////////////////////////
    if (object3d.countertop) {

      let origin = [
        new Vector4(
          0,
          0,
          object3d.getSizes().depth + object3d.countertop.height / 2,
          1)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(object3d.matrixWorld),
        new Vector4(
          0,
          object3d.getSizes().height / 2 - 0.0001,
          object3d.getSizes().depth + object3d.countertop.height / 2,
          1)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(object3d.matrixWorld),
        new Vector4(
          0,
          -object3d.getSizes().height / 2 + 0.0001,
          object3d.getSizes().depth + object3d.countertop.height / 2,
          1)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(object3d.matrixWorld)
      ];

      for (let i = 0; i < 3; i += 1) {
        direction4 = new Vector4(-1, 0, 0, 0)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(object3d.matrixWorld);

        this.raycaster.set(
          new Vector3(origin[i].x, origin[i].y, origin[i].z),
          new Vector3(direction4.x, direction4.y, direction4.z).normalize()
        );

        intersections = this.raycaster.intersectObjects(intersectionObjects);

        if (
          intersections.length > 0 &&
          intersections[0].distance <=
          this.tolerance + object3d.countertop.getOverhang().left + object3d.getSizes().width / 2 &&
          (!result.minusX ||
            intersections[0].distance - object3d.countertop.getOverhang().left <= result.minusX.distance)
        ) {
          result.minusX = intersections[0];
          result.minusX.distance = intersections[0].distance - object3d.countertop.getOverhang().left;
          result.minusX.axis = 'x';
        }


        direction4 = new Vector4(1, 0, 0, 0)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(object3d.matrixWorld);
        this.raycaster.set(
          new Vector3(origin[i].x, origin[i].y, origin[i].z),
          new Vector3(direction4.x, direction4.y, direction4.z).normalize()
        );

        intersections = this.raycaster.intersectObjects(intersectionObjects);

        if (
          intersections.length > 0 &&
          intersections[0].distance <=
          this.tolerance + object3d.countertop.getOverhang().right + object3d.getSizes().width / 2 &&
          (!result.plusX ||
            intersections[0].distance - object3d.countertop.getOverhang().right <= result.plusX.distance)
        ) {
          result.plusX = intersections[0];
          result.plusX.distance = intersections[0].distance - object3d.countertop.getOverhang().right;
          result.plusX.axis = 'x';
        }
      }

      origin = [
        new Vector4(
          0,
          0,
          object3d.getSizes().depth + object3d.countertop.height / 2,
          1)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(object3d.matrixWorld),
        new Vector4(
          object3d.getSizes().width / 2 - 0.0001,
          0,
          object3d.getSizes().depth + object3d.countertop.height / 2,
          1)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(object3d.matrixWorld),
        new Vector4(
          -object3d.getSizes().width / 2 + 0.0001,
          0,
          object3d.getSizes().depth + object3d.countertop.height / 2,
          1)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(object3d.matrixWorld)
      ];

      for (let i = 0; i < 3; i += 1) {

        direction4 = new Vector4(0, 1, 0, 0)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(object3d.matrixWorld);
        this.raycaster.set(
          new Vector3(origin[i].x, origin[i].y, origin[i].z),
          new Vector3(direction4.x, direction4.y, direction4.z).normalize()
        );
        intersections = this.raycaster.intersectObjects(intersectionObjects);

        if (
          intersections.length > 0 &&
          intersections[0].distance <=
          this.tolerance + object3d.countertop.getOverhang().bottom + object3d.getSizes().height / 2 &&
          (!result.plusY ||
            intersections[0].distance - object3d.countertop.getOverhang().bottom <= result.plusY.distance)
        ) {
          result.plusY = intersections[0];
          result.plusY.distance = intersections[0].distance - object3d.countertop.getOverhang().bottom;
          result.plusY.axis = 'y';

        }

        direction4 = new Vector4(0, -1, 0, 0)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(object3d.matrixWorld);
        this.raycaster.set(
          new Vector3(origin[i].x, origin[i].y, origin[i].z),
          new Vector3(direction4.x, direction4.y, direction4.z).normalize()
        );

        intersections = this.raycaster.intersectObjects(intersectionObjects);

        if (
          intersections.length > 0 &&
          intersections[0].distance <=
          this.tolerance + object3d.countertop.getOverhang().top + object3d.getSizes().height / 2 &&
          (!result.minusY ||
            (intersections[0].distance - object3d.countertop.getOverhang().top <= result.minusY.distance))
        ) {
          result.minusY = intersections[0];
          result.minusY.distance = intersections[0].distance - object3d.countertop.getOverhang().top;

          result.minusY.axis = 'y';
        }
      }


    }

    if (result.minusX) {
      if (result.plusX) {
        if (result.plusX.distance < result.minusX.distance) {
          result.x = result.plusX;
        } else {
          result.minusX.distance *= -1;
          result.x = result.minusX;
        }
      } else {
        result.minusX.distance *= -1;
        result.x = result.minusX;
      }
    } else {
      result.x = result.plusX;
    }

    if (result.minusY) {
      if (result.plusY) {
        if (result.plusY.distance < result.minusY.distance) {
          result.y = result.plusY;
        } else {
          result.minusY.distance *= -1;
          result.y = result.minusY;
        }
      } else {
        result.minusY.distance *= -1;
        result.y = result.minusY;
      }
    } else {
      result.y = result.plusY;
    }


    // /////////////////////////////////////////////////////////////////////////////

    return result;
  }

  checkSnappingWithObject(object3d, iObjects) {
    let result = null;
    let intersectionObjects;
    const objects = [];
    const mount = object3d.parent ? object3d.parent.plane : null;

    if (!mount || !mount.isMount || !mount.isMountPlane) {
      return false;
    }

    object3d.traverseVisible((obj) => {
      objects.push(obj);
    });

    if (iObjects) {
      intersectionObjects = iObjects;
    } else {
      intersectionObjects = [];
      this.scene.traverseVisible((obj) => {
        if (obj.isSnapable && obj.isMesh && objects.indexOf(obj) === -1) {
          intersectionObjects.push(obj);
        }
      });
    }

    result = this.check(object3d, intersectionObjects);

    return result;
  }

  // eslint-disable-next-line complexity
  snapToObject(object3d, snapping) {
    if (!snapping) {
      return;
    }
    let snappingDistanceX = null;
    let snappingDistanceY = null;
    const initialRotation = object3d.initialRotation
      ? -object3d.initialRotation
      : 0;
    const floorMounting = object3d.parent.plane && object3d.parent.plane.isMount &&
      object3d.parent.plane.mountSlotTypes.indexOf('floor') !== -1;
    const wallMounting = object3d.parent.plane && object3d.parent.plane.isMount &&
      object3d.parent.plane.mountSlotTypes.indexOf('wall') !== -1;
    if (snapping.x) {
      if (snapping.x.distance > 0) {
        snappingDistanceX = snapping.x.distance - object3d.getSizes().width / 2 - 0.0001;
      } else {
        snappingDistanceX = snapping.x.distance + object3d.getSizes().width / 2 + 0.0001;
      }
    }

    if (snapping.y) {
      if (snapping.y.distance > 0) {
        snappingDistanceY = snapping.y.distance - object3d.getSizes().height / 2 - 0.0001;
      } else {
        snappingDistanceY = snapping.y.distance + object3d.getSizes().height / 2 + 0.0001;
      }
    }

    const wallSnapOnYAxis = (obj) => {
      // Console.log(obj);
      const height = obj.getSizes ? obj.getSizes().height : 0;

      let min = Math.min(
        snappingDistanceY ? Math.abs(snappingDistanceY) : Infinity,

        obj.parent === object3d.parent
          ? Math.abs(object3d.position.y - obj.position.y)
          : Infinity,
        obj.parent === object3d.parent
          ? Math.abs(object3d.position.y - obj.position.y - height / 2)
          : Infinity,
        obj.parent === object3d.parent
          ? Math.abs(object3d.position.y - obj.position.y + height / 2)
          : Infinity
      );
      if (min === Infinity) {
        min = -1;
      }
      if (min === Math.abs(object3d.position.y - obj.position.y)) {
        object3d.position.y = obj.position.y;
        // Console.log('y1');
        return;
      }
      if (min === Math.abs(object3d.position.y - obj.position.y - height / 2)) {
        object3d.position.y = obj.position.y + height / 2;
        // Console.log('y2', object3d.position.y);
        return;
      }
      if (min === Math.abs(object3d.position.y - obj.position.y + height / 2)) {
        object3d.position.y = obj.position.y - height / 2;
        // Console.log('y3');
        return;
      }
      if (min === Math.abs(snappingDistanceY)) {
        // Object3d.position.y += snappingDistanceY;
        const a = new Vector4(0, snappingDistanceY, 0, 0)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
        object3d.position.add(new Vector3(a.x, a.y, a.z));
        // Console.log('y4');

      }
    };

    if (wallMounting && snapping.x) {
      // Console.log('snappingX', snapping.x.object.name);
      wallSnapOnYAxis(getTopLevelObject(snapping.x.object));
      if (!snapping.y) {
        const a = new Vector4(snappingDistanceX, 0, 0, 0)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
        object3d.position.add(new Vector3(a.x, a.y, a.z));
      }
    }

    const wallSnapOnXAxis = (obj) => {
      // Console.log(obj);
      const width = obj.getSizes ? obj.getSizes().width : 0;

      let min = Math.min(
        snappingDistanceX ? Math.abs(snappingDistanceX) : Infinity,
        obj.parent === object3d.parent
          ? Math.abs(object3d.position.x - obj.position.x)
          : Infinity,
        obj.parent === object3d.parent
          ? Math.abs(object3d.position.x - obj.position.x - width / 2)
          : Infinity,
        obj.parent === object3d.parent
          ? Math.abs(object3d.position.x - obj.position.x + width / 2)
          : Infinity
      );
      // Console.log(min);
      if (min === Infinity) {
        min = -1;
        // Console.log('x--1');
      }
      if (min === Math.abs(object3d.position.x - obj.position.x)) {
        object3d.position.x = obj.position.x;
        // Console.log('x1');
        return;
      }
      if (min === Math.abs(object3d.position.x - obj.position.x - width / 2)) {
        object3d.position.x = obj.position.x + width / 2;
        // Console.log('x2');
        return;
      }
      if (min === Math.abs(object3d.position.x - obj.position.x + width / 2)) {
        object3d.position.x = obj.position.x - width / 2;
        // Console.log('x3');
        return;
      }
      if (min === Math.abs(snappingDistanceX)) {
        // Object3d.position.x += snappingDistanceX;
        const a = new Vector4(snappingDistanceX, 0, 0, 0)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
        object3d.position.add(new Vector3(a.x, a.y, a.z));
        // Console.log('x4');

      }
    };

    if (wallMounting && snapping.y) {
      // Console.log('snappingY', snapping.y.object.name);
      wallSnapOnXAxis(getTopLevelObject(snapping.y.object));
      if (!snapping.x) {
        const a = new Vector4(0, snappingDistanceY, 0, 0)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
        object3d.position.add(new Vector3(a.x, a.y, a.z));
      }

    }

    const snapOnYAxis = (obj) => {
      const sizes = obj.vestaObject.getSizes
        ? new Vector3(
          obj.vestaObject.getSizes().width,
          obj.vestaObject.getSizes().height,
          obj.vestaObject.getSizes().depth
        )
        : new Vector3();
      const q1 = new Quaternion();
      const q2 = new Quaternion();
      obj.getWorldQuaternion(q1);
      object3d.getWorldQuaternion(q2);
      let height = Math.abs(sizes.clone().applyQuaternion(q1.clone().premultiply(q2.clone().conjugate())).z);
      const objpos = obj.position.clone()
        .applyMatrix4(obj.parent.matrixWorld)
        .applyMatrix4(new Matrix4().getInverse(object3d.matrixWorld));
      let min = Math.min(
        snappingDistanceY ? Math.abs(snappingDistanceY) : Infinity,
        Math.abs(objpos.z - obj.position.z * 0),
        Math.abs(objpos.z - obj.position.z * 0 - height / 2),
        Math.abs(objpos.z - obj.position.z * 0 + height / 2),
        Math.abs(objpos.z - object3d.getSizes().height / 2 + height / 2),
        Math.abs(objpos.z + object3d.getSizes().height / 2 - height / 2)
      );
      if (min === Infinity) {
        min = -1;
        // console.log('y--1');
      }
      if (Math.abs(min - Math.abs(objpos.z - obj.position.z * 0)) <= 0.001) {
        const a = new Vector4(0, 0, objpos.z, 0)
          .applyMatrix4(object3d.matrixWorld)
          // .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().getInverse(object3d.parent.matrixWorld));
        object3d.position.add(a);
        // console.log('y1');
        return;
      }
      if (Math.abs(min - Math.abs(objpos.z - object3d.getSizes().height / 2 + height / 2)) <= 0.001) {
        const a = new Vector4(0, 0, objpos.z - object3d.getSizes().height / 2 + height / 2, 0)
          .applyMatrix4(object3d.matrixWorld)
          // .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().getInverse(object3d.parent.matrixWorld));
        object3d.position.add(a);
        // console.log('y6');
        return;
      }
      if (Math.abs(min - Math.abs(objpos.z + object3d.getSizes().height / 2 - height / 2)) <= 0.001) {
        const a = new Vector4(0, 0, objpos.z + object3d.getSizes().height / 2 - height / 2, 0)
          .applyMatrix4(object3d.matrixWorld)
          // .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().getInverse(object3d.parent.matrixWorld));
        object3d.position.add(a);
        // console.log('y7');
        return;
      }
      if (Math.abs(min - Math.abs(objpos.z - obj.position.z * 0 - height / 2)) <= 0.001) {
        const a = new Vector4(0, 0, objpos.z - obj.position.z * 0 - height / 2, 0)
          .applyMatrix4(object3d.matrixWorld)
          // .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().getInverse(object3d.parent.matrixWorld));
        object3d.position.add(a);
        // console.log('y2', object3d.position.y);
        return;
      }
      if (Math.abs(min - Math.abs(objpos.z - obj.position.z * 0 + height / 2)) <= 0.001) {
        const a = new Vector4(0, 0, objpos.z - obj.position.z * 0 + height / 2, 0)
          .applyMatrix4(object3d.matrixWorld)
          // .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().getInverse(object3d.parent.matrixWorld));
        object3d.position.add(a);
        // console.log('y3');
        return;
      }
      if (Math.abs(min - Math.abs(snappingDistanceY)) <= 0.001) {
        // Object3d.position.y += snappingDistanceY;
        const a = new Vector4(0, snappingDistanceY, 0, 0)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
        object3d.position.add(new Vector3(a.x, a.y, a.z));
        // console.log('y4');

      }
    };


    const snapOnXAxis = (obj) => {
      const sizes = obj.vestaObject.getSizes
        ? new Vector3(
          obj.vestaObject.getSizes().width,
          obj.vestaObject.getSizes().height,
          obj.vestaObject.getSizes().depth
        )
        : new Vector3();
      const q1 = new Quaternion();
      const q2 = new Quaternion();
      obj.getWorldQuaternion(q1);
      object3d.getWorldQuaternion(q2);
      let width = Math.abs(sizes.clone().applyQuaternion(q1.clone().premultiply(q2.clone().conjugate())).x);
      const objpos = obj.position.clone()
        .applyMatrix4(obj.parent.matrixWorld)
        .applyMatrix4(new Matrix4().getInverse(object3d.matrixWorld));
      let min = Math.min(
        snappingDistanceX ? Math.abs(snappingDistanceX) : Infinity,
        obj.parent === object3d.parent
          ? Math.abs(objpos.x - obj.position.x * 0)
          : Math.abs(objpos.x - obj.position.x * 0),
        obj.parent === object3d.parent
          ? Math.abs(objpos.x - obj.position.x * 0 - width / 2)
          : Math.abs(objpos.x - obj.position.x * 0 - width / 2),
        obj.parent === object3d.parent
          ? Math.abs(objpos.x - obj.position.x * 0 + width / 2)
          : Math.abs(objpos.x - obj.position.x * 0 + width / 2),
        obj.parent === object3d.parent
          ? Math.abs(objpos.x + object3d.getSizes().width / 2 - width / 2)
          : Math.abs(objpos.x + object3d.getSizes().width / 2 - width / 2),
        obj.parent === object3d.parent
          ? Math.abs(objpos.x - object3d.getSizes().width / 2 + width / 2)
          : Math.abs(objpos.x - object3d.getSizes().width / 2 + width / 2)
      );

      if (min === Infinity) {
        min = -1;
        // console.log('x--1');
      }
      if (Math.abs(min - Math.abs(objpos.x - obj.position.x * 0)) <= 0.0001) {
        // object3d.position.x = obj.position.x;
        const a = new Vector4(objpos.x, 0, 0, 0)
          .applyMatrix4(object3d.matrixWorld)
          // .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().getInverse(object3d.parent.matrixWorld));
        object3d.position.add(a);
        // console.log('x1', a);
        return;
      }
      if (Math.abs(min - Math.abs(objpos.x + object3d.getSizes().width / 2 - width / 2)) <= 0.0001) {
        // object3d.position.x = obj.position.x;
        const a = new Vector4(objpos.x + object3d.getSizes().width / 2 - width / 2, 0, 0, 0)
          .applyMatrix4(object3d.matrixWorld)
          // .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().getInverse(object3d.parent.matrixWorld));
        object3d.position.add(a);
        // console.log('x6', a);
        return;
      }
      if (Math.abs(min - Math.abs(objpos.x - object3d.getSizes().width / 2 + width / 2)) <= 0.0001) {
        // object3d.position.x = obj.position.x;
        const a = new Vector4(objpos.x - object3d.getSizes().width / 2 + width / 2, 0, 0, 0)
          .applyMatrix4(object3d.matrixWorld)
          // .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().getInverse(object3d.parent.matrixWorld));
        object3d.position.add(a);
        // console.log('x7', a);
        return;
      }
      if (Math.abs(min - Math.abs(objpos.x - obj.position.x * 0 + width / 2)) <= 0.0001) {
        // object3d.position.x = obj.position.x + width / 2;
        const a = new Vector4(objpos.x - obj.position.x * 0 + width / 2, 0, 0, 0)
          .applyMatrix4(object3d.matrixWorld)
          // .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().getInverse(object3d.parent.matrixWorld));
        object3d.position.add(a);
        // console.log('x2', a);
        return;
      }
      if (Math.abs(min - Math.abs(objpos.x - obj.position.x * 0 - width / 2)) <= 0.0001) {
        // object3d.position.x = obj.position.x - width / 2;
        const a = new Vector4(objpos.x - obj.position.x * 0 - width / 2, 0, 0, 0)
          .applyMatrix4(object3d.matrixWorld)
          // .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().getInverse(object3d.parent.matrixWorld));
        object3d.position.add(a);
        // console.log('x3', a);
        return;
      }
      if (Math.abs(min - Math.abs(snappingDistanceX)) <= 0.0001) {
        // Object3d.position.x += snappingDistanceX;
        const a = new Vector4(snappingDistanceX, 0, 0, 0)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
        object3d.position.add(new Vector3(a.x, a.y, a.z));
        // console.log('x4', a);

      }
    };

    /* if (wallMounting && snapping.x) {
      console.log('snappingX');
      if (
        snapping.x.object &&
        snapping.x.object.parent &&
        !snapping.x.object.parent.isMount &&
        getTopLevelObject(snapping.x.object).isWall
      ) { } else {
        snapOnYAxis(getTopLevelObject(snapping.x.object));
      }
      if (false && !snapping.y) {
        const a = new Vector4(snappingDistanceX, 0, 0, 0)
          //.applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
        object3d.position.add(new Vector3(a.x, a.y, a.z));
      }
      // Console.log('x5');
    }

    if (wallMounting && snapping.y) {
      console.log('snappingY');
      if (
        snapping.y.object &&
        snapping.y.object.parent &&
        !snapping.y.object.parent.isMount &&
        getTopLevelObject(snapping.y.object).isWall
      ) { } else {
        snapOnXAxis(getTopLevelObject(snapping.y.object));
      }
      if (false && !snapping.x) {
        const a = new Vector4(0, snappingDistanceY, 0, 0)
          //.applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
        object3d.position.add(new Vector3(a.x, a.y, a.z));
      }
      // Console.log('y5');
    } */


    if (floorMounting) {
      if (snapping.x && !snapping.y) {
        if (
          snapping.x.object &&
          snapping.x.object.parent &&
          !snapping.x.object.parent.isMount &&
          (getTopLevelObject(snapping.x.object).isWall ||
            object3d.isFiller || object3d.isFillerEnd
          )
        ) {
          const q1 = new Quaternion();
          const q2 = new Quaternion();
          const xx = snapping.x.object.rotation.x;
          snapping.x.object.rotation.x = 0;
          snapping.x.object.getWorldQuaternion(q1);
          snapping.x.object.rotation.x = xx;
          object3d.parent.plane.getWorldQuaternion(q2);
          object3d.quaternion.copy(
            q1.clone().premultiply(q2.clone().conjugate())
          );

        }

        if (
          snapping.x.object &&
          snapping.x.object.parent &&
          !snapping.x.object.parent.isMount &&
          getTopLevelObject(snapping.x.object).isWall
        ) { } else {
          snapOnYAxis(getTopLevelObject(snapping.x.object));
        }

        const a = new Vector4(snappingDistanceX, 0, 0, 0)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
        object3d.position.add(new Vector3(a.x, a.y, a.z));

      }

      if (snapping.y && !snapping.x) {
        if (
          snapping.y.object &&
          snapping.y.object.parent &&
          !snapping.y.object.parent.isMount &&
          (getTopLevelObject(snapping.y.object).isWall ||
            object3d.isFiller || object3d.isFillerEnd
          )
        ) {
          const q1 = new Quaternion();
          const q2 = new Quaternion();
          const xx = snapping.y.object.rotation.x;
          snapping.y.object.rotation.x = 0;
          snapping.y.object.getWorldQuaternion(q1);
          snapping.y.object.rotation.x = xx;

          object3d.parent.plane.getWorldQuaternion(q2);
          object3d.quaternion.copy(
            q1.clone().premultiply(q2.clone().conjugate())
          );

        }

        if (
          snapping.y.object &&
          snapping.y.object.parent &&
          !snapping.y.object.parent.isMount &&
          getTopLevelObject(snapping.y.object).isWall
        ) { } else {
          snapOnXAxis(getTopLevelObject(snapping.y.object));
        }

        const a = new Vector4(0, snappingDistanceY, 0, 0)
          .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
          .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
        object3d.position.add(new Vector3(a.x, a.y, a.z));
      }

      if (snapping.x && snapping.y) {
        if (
          snapping.x.object &&
          snapping.x.object.parent &&
          !snapping.x.object.parent.isMount &&
          getTopLevelObject(snapping.x.object).isWall &&
          snapping.y.object &&
          snapping.y.object.parent &&
          !snapping.y.object.parent.isMount &&
          getTopLevelObject(snapping.y.object).isWall
        ) {
          let a = new Vector4(snappingDistanceX, 0, 0, 0)
            .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
            .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
          object3d.position.add(new Vector3(a.x, a.y, a.z));

          a = new Vector4(0, snappingDistanceY, 0, 0)
            .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
            .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
          object3d.position.add(new Vector3(a.x, a.y, a.z));

          return;
        }

        if (
          snapping.x.object &&
          snapping.x.object.parent &&
          !snapping.x.object.parent.isMount &&
          getTopLevelObject(snapping.x.object).isWall
        ) {
          const q1 = new Quaternion();
          const q2 = new Quaternion();
          const xx = snapping.x.object.rotation.x;
          snapping.x.object.rotation.x = 0;
          snapping.x.object.getWorldQuaternion(q1);
          snapping.x.object.rotation.x = xx;
          object3d.parent.plane.getWorldQuaternion(q2);
          object3d.quaternion.copy(
            q1.clone().premultiply(q2.clone().conjugate())
          );

          let a = new Vector4(snappingDistanceX, 0, 0, 0)
            .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
            .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
          object3d.position.add(new Vector3(a.x, a.y, a.z));

          // snapOnXAxis(getTopLevelObject(snapping.y.object));

          a = new Vector4(0, snappingDistanceY, 0, 0)
            .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
            .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
          object3d.position.add(new Vector3(a.x, a.y, a.z));

          return;
        }

        if (
          snapping.y &&
          snapping.y.object &&
          snapping.y.object.parent &&
          !snapping.y.object.parent.isMount &&
          getTopLevelObject(snapping.y.object).isWall
        ) {
          const q1 = new Quaternion();
          const q2 = new Quaternion();
          const xx = snapping.y.object.rotation.x;
          snapping.y.object.rotation.x = 0;
          snapping.y.object.getWorldQuaternion(q1);
          snapping.y.object.rotation.x = xx;
          object3d.parent.plane.getWorldQuaternion(q2);
          object3d.quaternion.copy(
            q1.clone().premultiply(q2.clone().conjugate())
          );

          let a = new Vector4(0, snappingDistanceY, 0, 0)
            .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
            .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
          object3d.position.add(new Vector3(a.x, a.y, a.z));

          // snapOnYAxis(getTopLevelObject(snapping.x.object));

          a = new Vector4(snappingDistanceX, 0, 0, 0)
            .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
            .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
          object3d.position.add(new Vector3(a.x, a.y, a.z));

          return;
        }

        snapOnXAxis(getTopLevelObject(snapping.y.object));
        snapOnYAxis(getTopLevelObject(snapping.x.object)
        );

      }

    }


    /*  if (snapping.y && floorMounting) {
        console.log('snappingY2', snapping);

        if (
          snapping.y &&
          snapping.y.object &&
          snapping.y.object.parent &&
          !snapping.y.object.parent.isMount &&
          getTopLevelObject(snapping.y.object).isWall &&
          floorMounting &&
          (!snapping.x ||
            (snapping.x &&
              snapping.x.object &&
              !getTopLevelObject(snapping.x.object).isWall
            )
          )

        ) {
          // console.log( 'rotationY' );

          const q1 = new Quaternion();
          const q2 = new Quaternion();
          const xx = snapping.y.object.rotation.x;
          snapping.y.object.rotation.x = 0;
          snapping.y.object.getWorldQuaternion(q1);
          snapping.y.object.rotation.x = xx;
          object3d.parent.plane.getWorldQuaternion(q2);
          object3d.quaternion.copy(
            q1.clone().premultiply(q2.clone().conjugate())
          );
          // object3d.rotation.y = (new Euler().setFromQuaternion(q1.clone().premultiply(q2.clone().conjugate()))).y;
          if (true || !snapping.x) {
            const a = new Vector4(0, snappingDistanceY, 0, 0)
              .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
              .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
            object3d.position.add(new Vector3(a.x, a.y, a.z));
            // Console.log('y5');
          }

        } else {

          snapOnXAxis(getTopLevelObject(snapping.y.object));
          if ((!snapping.x ||
            (snapping.x &&
              snapping.x.object &&
              getTopLevelObject(snapping.x.object).isWall
            )
          )) {
            const a = new Vector4(0, snappingDistanceY, 0, 0)
              .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
              .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
            object3d.position.add(new Vector3(a.x, a.y, a.z));
            // Console.log('y5');
          }
        }

      }

      if (snapping.x && floorMounting) {
        console.log('snappingX2', snapping);
        if (
          snapping.x &&
          snapping.x.object &&
          snapping.x.object.parent &&
          !snapping.x.object.parent.isMount &&
          getTopLevelObject(snapping.x.object).isWall &&
          floorMounting &&
          (!snapping.y ||
            (snapping.y &&
              snapping.y.object &&
              !getTopLevelObject(snapping.y.object).isWall
            )
          )
        ) {
          // console.log( 'rotationX' );

          const q1 = new Quaternion();
          const q2 = new Quaternion();
          const xx = snapping.x.object.rotation.x;
          snapping.x.object.rotation.x = 0;
          snapping.x.object.getWorldQuaternion(q1);
          snapping.x.object.rotation.x = xx;
          object3d.parent.plane.getWorldQuaternion(q2);
          object3d.quaternion.copy(
            q1.clone().premultiply(q2.clone().conjugate())
          );
          // object3d.rotation.y = (new Euler().setFromQuaternion(q1.clone().premultiply(q2.clone().conjugate()))).y;

          if (true || !snapping.y) {
            const a = new Vector4(snappingDistanceX, 0, 0, 0)
              .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
              .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
            object3d.position.add(new Vector3(a.x, a.y, a.z));
            // Console.log('x5');
          }
        } else {

          snapOnYAxis(getTopLevelObject(snapping.x.object));
          if (!snapping.y ||
            (snapping.y &&
              snapping.y.object &&
              getTopLevelObject(snapping.y.object).isWall
            )) {
            const a = new Vector4(snappingDistanceX, 0, 0, 0)
              .applyMatrix4(new Matrix4().makeRotationX(initialRotation))
              .applyMatrix4(new Matrix4().makeRotationFromEuler(object3d.rotation));
            object3d.position.add(new Vector3(a.x, a.y, a.z));
            // Console.log('x5');
          }
        }


      } */
  }
}
