/* eslint-disable @typescript-eslint/no-explicit-any */
import { simpleSet } from '@r/reducers/utils';
import { ThunkAction } from 'redux-thunk';

const s = simpleSet as redux.reducers.texts;

export const texts = s(
  'SET_TEXTS',
  {} as any
);

export function getTextsFromDb():
  ThunkAction<void, redux._IStore, void, redux._IActions['setTexts']> {

  return async ( dispatch ) => {
    await Promise.resolve( true );

    dispatch( {
      type: 'SET_TEXTS',
      value: {
        cornerMenu: {
          file: {
            title: 'File...',
            newProject: 'New Project',
            openProject: 'Open Project',
            saveProject: 'Save',
            projectSettings: 'Project Settings',
            exportToExcel: 'Export to *.xlsx',
            importFrom3d: 'Import from 3D',
            print: 'Print',
            account: 'Account',
            help: 'Help',
            admin: 'Admin'
          },
          view: {
            title: 'View',
            '3d': '3D view',
            calculationMode: 'Calculation mode'
          }
        },
        calculationMode: {
          mainModalTabs: ['Create New Project', 'Open Existing Project'],
          newProjectTabs: ['Project information', 'Dimensions', 'Materials'],
          rightMenuTabs: ['Catalog', 'Default dimensions', 'Default materials'],
          createProject: 'Create',
          openProject: 'Open',
          saveProject: 'Save',
          searchCabinetByName: 'search by cabinet name...',
          searchProjectByTitle: 'search by project title...',
          search: 'Search...',
          createdOn: 'Created on',
          modifiedOn: 'Modified on',
          noProjects: 'You haven\'t any projects',
          noProjectsBySuchCriteria: 'No projects with such criteria',
          projectTagsLetters: {
            estimating: 'E',
            inProgress: 'P',
            completed: 'C'
          },
          projectInformation: {
            projectName: 'Project name',
            tag: 'Project tag',
            units: 'Units',
            percentageLoss: 'Customer type',
            customerName: 'Customer name',
            customerAddress: 'Customer address',
            projectNumber: 'Project number',
            shippingCost: 'Shipping price',
            shippingCostManual: 'Shipping price manual',
            withInstallation: 'With installation',
            shippingAddress: 'Shipping address',
            sameAsCustomerAddress: 'Same as customer address',
            shippingDate: 'Shipping date',
            projectTags: ['Estimating', 'In progress', 'Completed']
          },
          dimensions: {
            title: 'Default dimensions for adding cabinets',
            height: 'Height',
            depth: 'Depth',
            shelvesQty: 'QTY Shelves',
            baseCabinet: 'Base cabinet',
            upperCabinet: 'Upper cabinet',
            tallCabinet: 'Tall cabinet',
            toeKickHeight: 'Toe kick height',
            toeKickType: 'Toe kick type',
            topDrawerHeight: 'Top drawer height',
            toeKickTypeOptions: ['to floor', 'leg leveler']
          },
          materials: {
            title: 'Default materials for adding cabinets',
            setName: 'Set',
            core: 'Core',
            doorsDrawers: 'Doors / Drawers',
            doorGrainDirection: 'Door grain direction',
            drawerGrainDirection: 'Drawer grain direction',
            edgebanding: 'Edgebanding',
            countertop: 'Countertop',
            mouldings: 'Mouldings',
            hardware: 'Hardware',
            pulls: 'Pulls',
            hinges: 'Hinges',
            legs: 'Legs',
            drawerSystems: 'Drawer systems',
            markup: 'Markup',
            grainDirectionOptions: ['Vertical', 'Horizontal'],
            countertopNone: 'None'
          },
          estimationPreview: {
            title: 'Estimation preview',
            summary: {
              totalMaterialsCost: 'Total price of material',
              totalLabourCost: 'Total price of labour',
              totalDoorsDrawersCost: 'Doors and Drawers total price',
              shippingCost: 'Price of shipping',
              installationCost: 'Price of installation',
              totalCost: 'Total',
              percent: '%',
              finalCost: 'Final price for the client'
            },
            table: {
              header: {
                number: '№',
                name: 'Object',
                setOfMaterials: 'Set of materials',
                width: 'Width',
                height: 'Height',
                depth: 'Depth',
                fullDepth: 'Full depth',
                comment: 'Comment',
                cost: 'Price'
              },
              details: {
                title: 'Details',
                swingOptions: ['Left swing', 'Right swing'],
                leftSideFillerWidth: 'Left side filler width',
                rightSideFillerWidth: 'Right side filler width',
                leftFinEnd: 'Left fin end',
                rightFinEnd: 'Right fin end',
                finEndOptions: ['applied', 'integrated', 'none'],
                adjustableShelfQty: 'Adjustable Shelf Qty',
                fixedShelfQty: 'Fixed Shelf Qty',
                valanceHeight: 'Valance height'
              },
              materials: {
                title: 'Materials',
                accessories: 'Accessories'
              },
              countertopOverhang: {
                title: 'Countertop overhang',
                left: 'Left',
                back: 'Back',
                right: 'Right'
              },
              additionalParts: {
                toeKickFillers: 'Toe kick and fillers',
                valance: 'Light Valance',
                lessThanOneSheet: 'Less than 1 sheet',
                dimensions: {
                  length: 'Length',
                  width: 'Width',
                  height: 'Height',
                  depth: 'Depth',
                  count: 'Count',
                  area: 'Area',
                  cost: 'Price',
                  labour: 'Labour'
                }
              }
            }
          },
          accountModal: {
            title: 'Account',
            fullName: 'Full name',
            email: 'Email',
            changePasswordQuestion: 'Do you want to change password?',
            oldPassword: 'Old password',
            newPassword: 'New password',
            confirmPassword: 'Confirm password',
            logOut: 'Log out',
            deleteAccount: 'Delete account',
            goToAdminMode: 'Go to admin mode',
            save: 'Save',
            cancel: 'Cancel'
          },
          adminMode: {
            title: 'Administrator Mode',
            dimensionsTitle: 'Global settings: dimensions',
            materialsTitle: 'Global settings: materials',
            save: 'Save',
            exit: 'Exit',
            cancel: 'Cancel',
            excelFile: 'Excel file (*.xlsx only)',
            download: 'Download',
            upload: 'Upload',
            percentageLossTitle: 'Customer type',
            percentageLossNamePlaceholder: 'Name',
            percentageLossValuePlaceholder: '%',
            shippingCostTitle: 'Shipping price',
            shippingCostNamePlaceholder: 'Zone',
            shippingCostValuePlaceholder: '$'
          }
        }
      }
    } );
  };
}
