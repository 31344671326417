import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { mainModal } from './mainModal';
import { newProject } from './newProject';
import { rightMenu } from './rightMenu';

const tabsComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['tabs'],
    AllActions
  >;

export const tabs = tabsComb( {
  mainModal,
  newProject,
  rightMenu
} );
