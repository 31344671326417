import {
  processUnitSetLabelAction,
  processUnitSetValueAction,
  processSetUnitsAction
} from '@r/reducers/utils';
import { red, AllActions } from '@r/storeUtils';
import { UnitsInputv2Value } from 'decl/general/html';
import { inches } from 'decl/general/units';

export const wDepth: red<
  redux._IStore['projectFor3d']['wDepth'],
  AllActions
  > = ( state = {
    label: '1' as UnitsInputv2Value,
    value: 6 as inches
  }, action ) => {
    switch( action.type ) {
      case 'SET_W_DEPTH_LABEL':
        return processUnitSetLabelAction( state, action );
      case 'SET_W_DEPTH_VALUE':
        return processUnitSetValueAction( state, action );
      case 'SET_UNITS':
        return processSetUnitsAction( state, action );
      default:
        return state;
    }
  };
