import MaterialsSelect from 'c/Object3dModal/Info/Materials/index';
import React, { PureComponent } from 'react';

import { RadioSelect } from 'c/CalculationLayer/utils/RadioSelect';
import { connect } from 'react-redux';
import { d as Disp } from '@r/storeUtils';

interface _IMaterialSetVariant {
  label: string;
  value: redux._IStore['flags']['cabinetSetMaterialMode'];
  chosen: boolean;
}

interface _IDoorOrBodyVariants {
  label: string;
  value: redux._IStore['flags']['cabinetSetMaterialOnDoorOrBody'];
  chosen: boolean;
}
class RadioSelectForMaterialSetMode
  extends RadioSelect<_IMaterialSetVariant> {}

class MaterialDoorOrBodyMode
  extends RadioSelect<_IDoorOrBodyVariants> {}

const Connected = connect( (
  { flags: { cabinetSetMaterialMode: v } }: redux._IStore
) => {
  const variants: RadioSelectForMaterialSetMode['props']['variants'] = [
    {
      label: 'Single',
      value: 'singleCabinet',
      chosen: v === 'singleCabinet'
    },
    {
      label: 'All',
      value: 'allCabinets',
      chosen: v === 'allCabinets'
    },
    {
      label: 'All under countertop',
      value: 'allUnderCountertop',
      chosen: v === 'allUnderCountertop'
    }
  ];

  return { variants };
},
( d: Disp< redux._IActions['setCabinetSetMaterialMode'] > ) => {

  const chooseVariant:
    RadioSelectForMaterialSetMode['props']['chooseVariant'] = ( value ) => {
      d( { type: 'SET_CABINET_SET_MATERIAL_MODE', value } );
    };

  return { chooseVariant };
}
)( RadioSelectForMaterialSetMode );

const DoorOrBodyMaterialRadioButton = connect( (
  { flags: { cabinetSetMaterialOnDoorOrBody: v } }: redux._IStore
) => {
  const variants: MaterialDoorOrBodyMode['props']['variants'] = [
    {
      label: 'Body',
      value: 'body',
      chosen: v === 'body'
    },
    {
      label: 'Door',
      value: 'door',
      chosen: v === 'door'
    }
  ];

  return { variants };
},
( d: Disp< redux._IActions['setCabinetSetMaterialOnDoorOrBody'] > ) => {

  const chooseVariant:
    MaterialDoorOrBodyMode['props']['chooseVariant'] = ( value ) => {
      d( { type: 'SET_CABINET_SET_MATERIAL_ON_DOOR_OR_BODY', value } );
    };

  return { chooseVariant };
}
)( MaterialDoorOrBodyMode );


export const Materials = connect(
  ( s: redux._IStore ) => ( {
    forForcedRender: s.flags.rightSideCabinetMenuMode
  } )
)(
  class Materials extends PureComponent<{forForcedRender: redux._IStore['flags']['rightSideCabinetMenuMode'] }> {
    render() {
      return (
        <>
          <Connected />
          <DoorOrBodyMaterialRadioButton />
          <MaterialsSelect forForcedRender={ this.props.forForcedRender } forRightSide />
        </>
      );
    }
  }
);
