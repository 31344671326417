/* eslint-disable jsx-a11y/label-has-associated-control */
// @ts-check
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import style from './index.styl';

/**
 * @typedef {{
 *  tags: {
 *    estimating: string;
 *    inProgress: string;
 *    completed: string;
 *  };
 *  tag: redux.store['projectFor3d']['projectTag'];
 *  dispatch: redux.d<redux.actions['setProjectTag']>;
 * }} Props
 */
/** @augments { PureComponent<Props> } */
export class ProjectTag extends PureComponent {
  /** @type { Partial<Props> } */
  static defaultProps = {
    tags: {
      estimating: 'Estimating',
      inProgress: 'In Progress',
      completed: 'Completed'
    }
  }

  onClick = (
    /**
     * @type { React.MouseEvent<HTMLLabelElement> & {
     *  currentTarget: { dataset: { tag: keyof Props['tags']
     * }}}}
     */ { currentTarget: { dataset: { tag } } } ) => {
    this.props.dispatch( { type: 'SET_PROJECT_TAG', value: tag } );
  }

  render() {
    const { props: { tags, tag }, onClick } = this;

    return(
      <div className={ `${ style.projectTag } projectTag` }>
        <h3>Project tag</h3>
        <div>
          {
            Object.keys( tags ).map( ( i ) => {
              const innerTag = /** @type { keyof Props['tags'] } */( i );

              return (
                <label
                  key={ innerTag }
                  onClick={ onClick }
                  data-tag={ innerTag }
                  className={ tag === innerTag ? 'chosen' : '' }
                >
                  { tags[ innerTag ] }
                </label>
              );
            } )
          }
        </div>
      </div>
    );
  }
}

export default connect(
  ( /** @type { redux.store }  */s ) => ( { tag: s.projectFor3d.projectTag } )
)(
  /** @type {import('react').ComponentType<never>} */ (
    /** @type { unknown } */ ( ProjectTag )
  )
);
