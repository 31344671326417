/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable no-fallthrough */
// @ts-check
import React, { PureComponent } from 'react';
import Storage from 'scr/utilitiesStorage';
import { connect } from 'react-redux';
import style from './index.styl';
import DoSetEntityMaterial from 'c/ThreeJsWrap/Viewer/UndoRedo/DoSetEntityMaterial';
import { toDoSetMaterialByName } from 'c/ThreeJsWrap/Viewer/core/helpers/object3d';

/** @typedef { import('cSrc/structured/singletonCabinet/core/GenericCabinet').GenericCabinet } GenCab */
/** @typedef { import('cSrc/structured/singletonCabinet/cabinets/helpers/WithCountertop').WithCountertop } WithCount */
export default connect(
  ( /** @type { redux.store } */ { flags: f } ) => ( {
    setMaterialMode: f.cabinetSetMaterialMode,
    doorOrBodyMode: f.cabinetSetMaterialOnDoorOrBody
  } )
)(
  /**
   * @typedef {{
   *  setMaterialMode: redux.store['flags']['cabinetSetMaterialMode'];
   *  forRightSide: boolean;
   *  doorOrBodyMode: redux.store['flags']['cabinetSetMaterialOnDoorOrBody'];
   *  forForcedRender?: redux.store['flags']['rightSideCabinetMenuMode'];
   * }} MaterialsProps
   */
  /**  @augments { PureComponent<MaterialsProps> } */
  class Materials extends PureComponent {
    chooseMaterial = ( type ) => (
      /** @type { React.MouseEvent<HTMLDivElement> & { currentTarget: { dataset: { name: string } } } } */
      { currentTarget: { dataset: { name } } }
    ) => {
      this.forceUpdate();

      const selected = Storage.get( 'selectedObject' );
      const [possibleCabinet] = selected;

      if( possibleCabinet === null ) {
        return;
      }

      if( type === 'crownMolding' ) {
        Storage.get( 'selectedObject' )[ 0 ].vestaObject.setMaterialByName( {
          crownMolding: name
        } );
        return;
      }
      if( possibleCabinet.vestaObject.getType().match( 'cabinet' ) ) {
        this.chooseCabinetMaterial(
          name,
          /** @type { GenCab } */ ( possibleCabinet )
        );
        return;
      }

      const selectedArr = /** @type { any } */( selected );

      for (let i = 0; i < selectedArr.length; i++) {
        const { vestaObject } = selectedArr[i];
        const type = vestaObject.getType();
        const t = this.findType( type );
        vestaObject.setMaterialByName( { [ t ]: name } );
      }
    }

    chooseCabinetMaterial = (
      /** @type { string } */ name,
      /** @type { GenCab } */ {vestaObject}
    ) => {
      const part = this.props.doorOrBodyMode === 'body' ? 'cabinet' : 'door';
      const defaultMaterials = Storage.get( 'defaultMaterials' );
      const viewer = Storage.get('viewer');

      switch(this.props.setMaterialMode) {
        case 'allCabinets': {
          viewer.scene.toDoSetAllCabinetMaterialsByName(name, part);
          if (part === 'cabinet') {
            defaultMaterials.cabinetMaterial = name;
          } else {
            defaultMaterials.doorMaterial = name;
          }
          break;
        }
        // @ts-ignore
        case 'allUnderCountertop': {
          const cabinet = /** @type { WithCount } */ (vestaObject.getParent());
          if (cabinet.toDoSetMaterialByNameForCabinetsUnderCountertop !== undefined) {
            cabinet.toDoSetMaterialByNameForCabinetsUnderCountertop(name, part);
          }
          break;
        }
        default:
          toDoSetMaterialByName(vestaObject, name, part, viewer.doMgr);
      }
    }

    findType = ( type ) => {
      switch ( type ) {
        case 'cabinet':
          return 'cabinet';
        case 'cabinet/withCountetop':
          return 'cabinet';
          // remove this case after added material for crownMolding
        case 'crownMolding':
          return 'crownMolding';
        default:
          return type;
      }
    };

    render() {
      const {
        props: { forRightSide },
        chooseMaterial,
        findType
      } = this;

      const [firstSelected] = Storage.get( 'selectedObject' );
      if( !firstSelected ) {
        return null;
      }

      const type = firstSelected.vestaObject.getType();
      const t = findType( type );

      const materials = Storage.get( 'materials' )[ t ];
      if( !materials ) {
        return null;
      }

      return (
        <div className={ style.materialsBlock }>
          <div
            className={
              `materialsHolder ${ forRightSide ? 'forRightSide' : '' }`
            }
          >
            {
              materials.map(
                ( { img, name, uiName } ) => (
                  <div
                    key={ name }
                    data-name={ name }
                    onClick={ chooseMaterial( t ) }
                  >
                    <img src={ img } alt='' />
                    { uiName }
                  </div>
                )
              )
            }
          </div>
        </div>
      );
    }
  }
);