import Info from './Info';
import { tab } from './interfaces';


const tabs: tab[] = [
  {
    name: 'info',
    uiName: 'Info',
    comp: Info,
    afterFunc: () => 'inverse'
  }
];

export default tabs;
