/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Modal } from '@npmrost/storybook/stories/Vesta/Modal/index';
import { d } from '@r/storeUtils';
import Main from './Main';
import EditProject from './EditProject';
import Account from './Account';

const Holder = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

interface _IProps {
  mayClose: string;
  modals: redux._IStore['calculationMode']['modals'];
  dispatch: d<
    redux._IActions['setCalculationModeMainModalTab'] |
    redux._IActions['toggleCalculationModeMainModal'] |
    redux._IActions['toggleCalculationModeEditProjectModal'] |
    redux._IActions['toggleCalculationModeAccountModal']
  >;
}

class CalcModal extends PureComponent<_IProps> {

  closeMainModal = () => {
    const { dispatch } = this.props;

    dispatch( {
      type: 'TOGGLE_CALCULATION_MODE_MAIN_MODAL',
      value: false
    } );

    dispatch( {
      type: 'SET_CALCULATION_MODE_MAIN_MODAL_TAB',
      value: 'createNewProject'
    } );
  }

  closeEditProjectModal = () => {
    const { dispatch } = this.props;

    dispatch( {
      type: 'TOGGLE_CALCULATION_MODE_EDIT_PROJECT_MODAL',
      value: false
    } );
  }

  closeAccountModal = () => {
    const { dispatch } = this.props;

    dispatch( {
      type: 'TOGGLE_CALCULATION_MODE_ACCOUNT_MODAL',
      value: false
    } );
  }

  render() {

    const {
      props: {
        mayClose,
        modals: {
          main: mainModalIsOpen,
          editProject: editProjectModalIsOpen,
          account: accountModalIsOpen
        }
      },
      closeMainModal,
      closeEditProjectModal,
      closeAccountModal
    } = this;

    const modalProps = {
      width: '900px',
      height: '90vh',
      close: () => {}
    } as any;

    if ( mayClose ) {
      if ( mainModalIsOpen ) {
        modalProps.close = closeMainModal;
      } else if ( editProjectModalIsOpen ) {
        modalProps.close = closeEditProjectModal;
      } else if ( accountModalIsOpen ) {
        modalProps.width = '600px';
        modalProps.close = closeAccountModal;
      }
    } else {
      modalProps.CloseButtonContent = <></>;
    }

    return (
      <>
        <Modal
          { ...modalProps }
        >
          <Holder>
            <Main
              isOpen={ mainModalIsOpen }
              close={ closeMainModal }
            />
            <EditProject
              isOpen={ editProjectModalIsOpen }
              close={ closeEditProjectModal }
            />
            <Account
              isOpen={ accountModalIsOpen }
              close={ closeAccountModal }
            />
          </Holder>
        </Modal>
      </>
    );
  }
}

export default connect(
  ( s: redux._IStore ): Omit<_IProps, 'dispatch'> => {
    return {
      mayClose: s.calculationMode.projects.currentProject.projectId,
      modals: s.calculationMode.modals
    };
  }
)( CalcModal );
