import { Utils } from 's/scripts/core/utils.js';
import * as selUtils from 'cSrc/structured/bare/selectedUtils.js';
import { genericRaycast } from'cSrc/structured/bare/genericRaycast.js';
import { addCube } from 'cSrc/structured/bare/addCube.js';
import { setWidthDependingOnDoors } from 'cSrc/structured/kitchens/utils/pureUtils.js';
import getBp, { updateThings } from 'scr/getBlueprintObject.js'; 


function getReflectionCube(){
	const path = "./img/materials/env/temple/";
	const format = '.png';
	const urls = [
			path + 'px' + format, path + 'nx' + format,
			path + 'py' + format, path + 'ny' + format,
			path + 'pz' + format, path + 'nz' + format
	];
	let reflectionCube = new THREE.CubeTextureLoader().load(urls);
	reflectionCube.format = THREE.RGBFormat;
	return reflectionCube;
}

async function loadTripletWithEnvMap(pathToImg){
  let textures = [
    pathToImg,
    pathToImg.replace(/basecolor\.jpg$/, 'roughness.jpg'),
    pathToImg.replace(/basecolor\.jpg$/, 'normal.jpg'),
  ];

  return await Promise.all(textures.map(it => 
    new Promise(function(r){
      new THREE.TextureLoader().load( it, function(t){
        r(t);
      })
    })
  ).concat(getReflectionCube()));
}
/**
 * @typedef { Object } cabinetTypesByItem
 * @property { "stove" } stove stove name constant
 * @property { "hood" } hood hood name constant
 * @property { "sink" } sink sink name constant
 * @property { "dishwasher" } dishwasher dishwasher name constant
 */
export const cabinetTypesByItem = {
  stove: "stove",
  hood: "hood",
  sink: "sink",
  dishwasher: "dishwasher",
  fridge: "fridge"
};

/**
 * @typedef { Object } OwnerPartForMat
 * @property { "DEFAULT" } DEFAULT applied to all body parts of cabinet
 * @property { "TOP" } TOP applied to countertop-part
 * @property { "DOOR" } DOOR applied to door-parts
 */
export const OwnerPartForMat = {
  DEFAULT: "BODY",
  TOP: "TOP",
  DOOR: "DOOR",
};

export default class BareContainer {
  metadata={}
  dragOffset=new THREE.Vector3()
  __texture = null
  _type = cabinetTypesByItem.stove
  /**
   * Only for empty containers, holds reference to the item inserted inside 
   * container
   * @member BareContainer#_item
   * @type Object
   */
  _item = null
  /**
   * Flag that is used inside cabinets to show their connectin with "containers
   * that are cabinets"
   * @member BareContainer#_isCabinet
   * @type Boolean
   */
  _isCabinet = false;
  /**
   * Flag that is used inside cabinets to show their connectin with "containers
   * that are kitchens"
   * @member BareContainer#_isKitchen
   * @type Boolean
   */
  _isKitchen = false;
  /**
   * Flag that is used inside cabinets to show their connectin with "containers
   * that are topCorner containers"
   * @member BareContainer#_isTopCorner
   * @type Boolean
   */
  _isTopCorner = false;
  /**
	 * Empty containers that are used inside row-based containers in order to fill 
   * holes created when top row width is not equal to bottom row width. Corks are 
   * added when row-based container "closes". For example, 
   * if top row width is 100cm and bottom row is 200cm, cork will be added if we 
   * insert tall cabinet next and will be equal to the 100cm. But when we do some 
   * changes to row-based container, we remove all corks and after changes recreate 
   * them, so there is impossible to have more than one cork inside row-based 
   * container
	 * @external RowBasedCork
	 */
  /**
   * Flag that is used inside cabinets to show their connectin with "containers
   * that are [corks]{@link external:RowBasedCork}"
   * @member BareContainer#_isCork
   * @type Boolean
   */
  _isCork = false;
  /**
	 * <pre>
   * Empty containers that is used to move windows in an empty kitchen. Idea is 
   * as follows:
   * - add window to an empty kitchen
   * - click on distance from window center to the left side
   * - input distance to the left side
   * - insert filler component to the left of the window
   * </pre>
	 * @external FillerContainer
   * @see [Filler meaning]{@link BareContainer#_isWindowFiller}
	 */
  /**
   * Flag that is used inside cabinets to show their connectin with "containers
   * that are [fillers]{@link external:FillerContainer}"
   * @member BareContainer#_isWindowFiller
   * @type Boolean
   */
  _isWindowFiller = false;
  /**
	 * TallContainer that is inserted into the {@link ConstructableKitchen} 
   * at creation time to create margin on the left and have ability to:
   * <ul>
   *  <li style="list-style-type: circle">Insert window at the leftmost side of the kitchen</li>
   *  <li style="list-style-type: circle">
   *    Insert cabinets that will have ability to open (handles take 
   *    some space when opened, so we need some spare space)
   *  </li>
   * </ul>
	 * @external MarginContainer
	 */
  /**
   * Flag that is used inside cabinets to show their connectin with "containers
   * that are [margins]{@link external:MarginContainer}"
   * @member BareContainer#_isMargin
   * @type Boolean
   */
  _isMargin = false;
  /**
   * Bare container class. The deepest root of the cabinet | kitchen | container 
   * hierarchy
   * @memberof VestaApp.CabinetDesigner
   * @constructs BareContainer
   */
  constructor(){ }

  updateHighlight(){}
  mouseOver(){}
  mouseOff(){}
  moveToPosition(){}

  switchWireframe(){}
  clickReleased(){}
  customIntersectionPlanes(){}
  objectHalfSize(){}
  getProportionalResize(){}
  getMetaData(){} 
  removed(){}

  setSelected()     { selUtils.setSelected.call(this);    }
  setUnselected()   { selUtils.setUnselected.call(this);  }
  raycast(...rest)  { genericRaycast.call(this, ...rest); }
  addCube()         { addCube.call(this);                 }
  /**
   * Utility function that updates cube and reselects cabinet if it was selected
   */
  refreshHighlightAndCube(){
    const isSelected = this._cube.highlighted;
    isSelected && this.setUnselected();
    this.addCube();
    isSelected && this.setSelected();
  }

  getHeight()       { return this.size.height;  }
  getWidth()        { return this.size.width;   }
  getDepth()        { return this.size.depth;   }
  setFixed(fixed)   { this.fixed = fixed;       }

  clickPressed(intersection){
    this.dragOffset.copy(intersection.point).sub(this.position);
  }

  clickDragged(intersection) {
    if (intersection) {
      this.moveToPosition(
        {
          ...intersection.point.sub(this.dragOffset),
          y: this.origin.position.y
        }, 
        intersection
      );
    }
  }

  rotate(intersection){
    if (intersection) 
    {
      if(intersection.point.y > 0.001){
        this.rotation.y = intersection.point.y;
        return;
      }
      var angle = Utils.angle(new THREE.Vector2(0, 1), 
        new THREE.Vector2(intersection.point.x - 
          this.position.x,intersection.point.z - this.position.z)
        );
      var snapTolerance = Math.PI / 16.0;
      // snap to intervals near Math.PI/2
      for (var i = -4; i <= 4; i++) 
      {
        if (Math.abs(angle - (i * (Math.PI / 2))) < snapTolerance) 
        {
          angle = i * (Math.PI / 2);
          break;
        }
      }
      this.rotation.y = angle;
    }
  }

  remove() { }
  resize() { }

  showOwnBounds(flag){ this.showBounds(flag); }

  // this specific method exists so we can call it after
  // we created meaningful part during BoxedContainer creation
  // and so we can tweak BoxedContainer`s metrics to our needs
  // NOTE that `this` inside it should point to the 
  //  BoxedContainer.component.backContent.component.meaningful 
  afterCreate(){
    let boxedC = this.parent.parent;

    // let k = boxedC;
    // while(k && !k._isKitchen) {
    //   if (!k.parent) break;
    //   k = k.parent;
    // }

    setWidthDependingOnDoors(boxedC, this.constructor);
  }
  /** 
    * Universal method for adding textures both for cabinets and kitchens
    * works by traversing down the chain starting from `this.origin` and 
    * when it encounters mesh that is marked with _isPanelPartPreset (i.e.
    * special property that is added to Panel class in part_presets.js) it 
    * changes it`s texture to the new one
    * 
    * @param {string} pathToImg - path to the texture image, e.g. 
    *   /img/cabinetTextures/205_TobaccoCherry_CerisierTabac_w4800xh5128.jpg
    */
  setTexture(pathToImg){
    loadTripletWithEnvMap(pathToImg)
    .then(([ map, roughnessMap, normalMap, envMap ]) => {

      this.origin.traverse(item => {
        if (!item.isMesh || !item.userData._isPanelPartPreset) return;

        // test-cases to apply submaterial to sub-parts of cabinet.
        // if (item.userData._ownerPart != OwnerPartForMat.DOOR) return;
        // if (item.userData._ownerPart != OwnerPartForMat.TOP) return;
        // if (item.userData._ownerPart != OwnerPartForMat.DEFAULT) return;

        item.material.forEach((mat, i) => {
          // item.material [ i ] = new THREE.MeshPhysicalMaterial({
          //   map: texture
          // });
          item.material [ i ] = new THREE.MeshPhysicalMaterial({
            map,
            roughnessMap,
            normalMap,
            metalness: 0,
            envMap,
            transparent: true,
            opacity: 1.0,
            wireframe: false,
          });

          item.material[ i ].needsUpdate = true;
        });
      });

      this.__texture = pathToImg;
      setTimeout(updateThings, 100);
    })
    // new THREE.TextureLoader().load( pathToImg, texture => {
      
    // });
  }

  getTexture(){ return this.__texture; }

  // this method is used exclusively by cabinets
  setItemType(type){
    this._type = type;
  }
  
  
  convertToOverlapped(undo){ }
}