/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable prefer-reflect */
/* eslint-disable no-self-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
// @ts-check
import { getDefault } from './GeneralConfigs';
/**
 * @type {{
 *  Panel: (keyof CabD.A.ContainerConfigs.PanelConfig)[]
 *  Door: (keyof CabD.A.ContainerConfigs.DoorConfig)[]
 *  Container: (keyof CabD.A.ContainerConfigs.ContainerConfig)[]
 * }}
 */
const configVars = {
  Panel: ['name', 'type', 'width', 'depth', 'height', 'panelParent'],
  Door: [
    'name', 'handleOrientation', 'handlePosition',
    'type', 'width', 'depth', 'height'
  ],
  Container: [
    'name', 'constrName', 'isTopCorner', 'isTopLevel',
    'layout', 'children', 'mountTypes', 'width', 'depth',
    'height', 'type', 'cabinetMaterialName',
    'countertopMaterialName', 'doorMaterialName'
  ]
};

function shouldRemoveProp(
  /** @type { keyof CabD.A.ContainerConfigs.cabinetConfig } */ prop,
  /** @type { boolean } */ isTopLevel
) {
  if( !isTopLevel ) {
    return true;
  }

  if( [
    'cabinetMaterialName',
    'countertopMaterialName',
    'doorMaterialName'
  ].indexOf( prop ) !== -1 ) {
    return false;
  }

  return true;
}

/**
 * @type {(
 *    { config }:
 *    { config: Partial<CabD.A.ContainerConfigs.cabinetConfig> }
 *  ) => Partial<CabD.A.ContainerConfigs.cabinetConfig>
 * }
 */
export default function removeExcessiveFromConfig ( { config } ) {
  /** @type { Partial<CabD.A.ContainerConfigs.cabinetConfig> } */
  let cleanedConfig = {};
  const type = config.type || 'Container';
  const arr = configVars[ type ];

  /** @type { keyof typeof config } */
  let key;
  // eslint-disable-next-line no-restricted-syntax
  for( key in config ) {
    if( arr.indexOf( key ) === -1 ) continue;
    if( config[ key ] === undefined ) continue;
    if(
      // @ts-ignore
      shouldRemoveProp( key, config.isTopLevel ) &&
      getDefault( key ) === config[ key ]
    ) continue;

    // @ts-ignore
    cleanedConfig[ key ] = config[ key ];
  }

  if( type === 'Panel' || type === 'Door' ) {
    return cleanedConfig;
  }

  config =
  /** @type { Partial<CabD.A.ContainerConfigs.ContainerConfig> } */(
      config
    );
  cleanedConfig =
  /**
   * @type { Partial<CabD.A.ContainerConfigs.ContainerConfig> }
   */( cleanedConfig );

  cleanedConfig.children = ( config.children || [] )
    .map( ( it ) => removeExcessiveFromConfig( { config: it } ) );

  if( cleanedConfig.children.length === 0 ) {
    delete cleanedConfig.children;
  }

  return cleanedConfig;
}
