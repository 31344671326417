// @ts-check
import {
  Matrix4, Raycaster, Vector2, Vector3
} from 'three';
import {
  isCabinet,
  getTopLevelObject,
  getClosestLevelObject,
  getRelatedObjects,
  isFloorMounted,
  isWallMounted,
  isHoleableWithWall,
  getWall3D,
} from 'c/ThreeJsWrap/Viewer/core/helpers/object3d';
import Storage from 'scr/utilitiesStorage';
import IntersectionDetector from './IntersectionDetector';
import SnappingDetector from './SnappingDetector';
import Utils from './floorplan/utils';
import DoAddRemoveEntity from './UndoRedo/DoAddRemoveEntity';
import DoSetEntityMatrix from './UndoRedo/DoSetEntityMatrix';
import DoRefreshWallHoles from './UndoRedo/DoRefreshWallHoles';
import DoSetDefaultLocation from './UndoRedo/DoSetDefaultLocation';

export default class DragDropManager {
  constructor( scene, camera ) {
    this.scene = scene;
    this.camera = camera;

    // if (camera instanceof Camera) {
    //   this.camera = camera;
    // } else {
    //   Object.defineProperty(this, 'camera', {get: camera});
    // }

    this.mouse = new Vector2();
    this.raycaster = new Raycaster();
    this.intersects = null;
    this.intersectionDetector = new IntersectionDetector( this.scene );
    this.allowPasteObject3D = false;

    this.lastValidPosition = null;
    this.transformInfo = null; // for undo of transform

    this.snappingDetector = new SnappingDetector( this.scene, Storage.get( 'snappingTolerance' ) );
    this.snapping = null;
    this.viewer = this.scene.viewer;

    this.dragFromUI = null; // for dragging info from UI, so add only.

    this.addListeners();
  }

  setObject3DF( object3dF ) {
    this.object3dF = object3dF;
    if ( object3dF ) {
      this.basicPosition = this.object3dF.getPosition();
    }
  }

  removeListeners() {
    this.viewer.canvas.removeEventListener( 'mouseup', this.mouseup, false );
    this.viewer.canvas.removeEventListener( 'drop', this.drop, false );
    this.viewer.canvas.removeEventListener( 'mousedown', this.mousedown, false );
    this.viewer.canvas.removeEventListener( 'mousemove', this.mousemove, false );
    this.viewer.canvas.removeEventListener( 'dblclick', this.dblclick, false );

    this.viewer.canvas.removeEventListener( 'mousemove', this.mousemoveF, false );
    this.viewer.canvas.removeEventListener( 'mousedown', this.mousedownF, false );
    this.viewer.canvas.removeEventListener( 'mouseup', this.mouseupF, false );
    this.viewer.canvas.removeEventListener( 'dblclick', this.dblclickF, false );
  }

  addListeners() {
    this.mouseup = () => {
      if ( !this.viewer.floorplanMode ) {
        // console.log( 'mouseup' );
        this.stopDrag();
      }

      this.dragFromUI = null;
    };
    this.drop = () => {
      if ( !this.viewer.floorplanMode ) {
        if ( isFloorMounted( this.object3d ) ) {
          this.scene.defaultLocation.floor.lastObject = this.object3d;
        }
        if ( isWallMounted( this.object3d ) ) {
          this.scene.defaultLocation.wall.lastObject = this.object3d;
        }

        this.stopDrag();
      }
    };
    this.mousedown = ( event ) => {
      if ( !this.viewer.floorplanMode ) {
        if ( event.which === 1 ) {
          if ( !this.object3d ) {
            this.raycastToAllObjectsAndDrag();
            if ( !( this.intersects && this.intersects.object && this.intersects.object.isTransformControls ) ) {
              this.viewer.outlinePass.selectedObjects = [];
              this.viewer.dispatch( {
                type: 'objectselected',
                data: {
                  object: [null],
                  mouse: {
                    x: event.clientX,
                    y: event.clientY
                  }
                }
              } );
              this.viewer.transformControls.detach();
            }

            this.viewer.renderOnDemand.set();
          }
        }
      }
      if ( this.object3d ) {
        this.transformInfo = { // for undo to set matrix of entity.
          entity: this.object3d,
          orgMatrix: this.object3d.matrix.clone(),
          orgParent: this.object3d.parent,
          orgDefLoc: this.viewer.scene.cloneDefaultLocation(), // for undo
        };
      } else this.dragFromUI = null;
    };
    this.mousemove = ( event ) => {
      if ( !this.viewer.floorplanMode ) {
        this.mouse.x = ( event.clientX / this.viewer.canvas.clientWidth ) * 2 - 1;
        this.mouse.y = -( event.clientY / this.viewer.canvas.clientHeight ) * 2 + 1;

        // Drag&Drop
        if ( this.object3d ) {
          this.viewer.renderOnDemand.set();
          if ( this.raycastToMountObjects() ) {
            // Some mount object in raycast
            this.intersectionDetector.update();
            if ( this.intersectionDetector.checkSceneIntersectionsWithObject( this.object3d ) ) {
              // Collision detected
              this.snapping = this.snappingDetector.checkSnappingWithObject(
                this.object3d
              );
              // Autofill by width
              if ( isCabinet( this.object3d ) &&
                !this.object3d.isAppliance &&
                ( isWallMounted( this.object3d ) || ( this.object3d.countertop && isFloorMounted( this.object3d ) ) ) &&
                this.snapping.minusX &&
                this.snapping.plusX &&
                ( this.object3d.countertop && ( ( Math.abs( this.snapping.plusX.distance ) + Math.abs( this.snapping.minusX.distance ) ) <
                  ( this.object3d.getSizes().width + 24 + this.object3d.countertop.getOverhang().left + this.object3d.countertop.getOverhang().right ) ) ||
                  ( !this.object3d.countertop && ( ( Math.abs( this.snapping.plusX.distance ) + Math.abs( this.snapping.minusX.distance ) ) <
                    ( this.object3d.getSizes().width + 24 ) ) )
                ) ) {
                this.autoFitByWidth();
              } else {
                this.autoFitByWidthUndo();
              }

              this.snapping = this.snappingDetector.checkSnappingWithObject( this.object3d );

              // Trying to snap
              this.snappingDetector.snapToObject( this.object3d, this.snapping );

              if ( this.intersectionDetector.checkSceneIntersectionsWithObject( this.object3d ) ) {
                // console.log('collision detected after snapping');
                // Collision detected after snapping
                if ( this.lastValidPosition.parent ) {
                  this.lastValidPosition.parent.add( this.object3d );
                  this.lastValidPosition.matrix.decompose( this.object3d.position, this.object3d.quaternion, this.object3d.scale );
                }

                this.allowPasteObject3D = false;
                // Console.log('collision after snapping');
              } else {
                // No collisions detected after snapping
                this._saveObjectPosition();
                this.object3d.snapping = this.snapping;
                this.allowPasteObject3D = true;
              }
            } else {
              // No collisions detected
              this.snapping = this.snappingDetector.checkSnappingWithObject( this.object3d );

              if ( isCabinet( this.object3d ) &&
                !this.object3d.isAppliance &&
                ( isWallMounted( this.object3d ) || ( this.object3d.countertop && isFloorMounted( this.object3d ) ) ) &&
                this.snapping.minusX &&
                this.snapping.plusX &&
                ( this.object3d.countertop && ( ( Math.abs( this.snapping.plusX.distance ) + Math.abs( this.snapping.minusX.distance ) ) <
                  ( this.object3d.getSizes().width + 24 + this.object3d.countertop.getOverhang().left + this.object3d.countertop.getOverhang().right ) ) ||
                  ( !this.object3d.countertop && ( ( Math.abs( this.snapping.plusX.distance ) + Math.abs( this.snapping.minusX.distance ) ) <
                    ( this.object3d.getSizes().width + 24 ) ) )
                ) ) {

                this.autoFitByWidth();
              } else {
                this.autoFitByWidthUndo();
              }

              this.allowPasteObject3D = true;
              this.snapping = this.snappingDetector.checkSnappingWithObject( this.object3d );

              const beforeSnappingPosition = this.object3d.position.clone();
              this.snappingDetector.snapToObject( this.object3d, this.snapping );

              if ( this.intersectionDetector.checkSceneIntersectionsWithObject( this.object3d ) ) {
                // Restore object position because after snapping it collises with other
                this.object3d.position.copy( beforeSnappingPosition );
              } else {
                this.object3d.snapping = this.snapping;
              }

              this._saveObjectPosition();
            }
          } else {
            /* No mount objects in raycast */
            this.allowPasteObject3D = false;
          }
        }
      }
    };
    this.dblclick = ( event ) => {
      if ( !this.viewer.floorplanMode ) {
        this.mouse.x = ( event.clientX / this.viewer.canvas.clientWidth ) * 2 - 1;
        this.mouse.y = -( event.clientY / this.viewer.canvas.clientHeight ) * 2 + 1;
        this.raycastToAllObjects();

        if ( !this.object3d && this.intersects && this.intersects.object ) {
          const closestLevelObject = getClosestLevelObject( this.intersects.object );
          const topLevelObject = getTopLevelObject( this.intersects.object );
          if ( isCabinet( closestLevelObject ) && isFloorMounted( topLevelObject ) ) {
            this.viewer.transformControls.attach( topLevelObject );

            this.transformInfo = { // for undo to set matrix of entity.
              entity: topLevelObject,
              orgMatrix: topLevelObject.matrix.clone(),
              orgParent: topLevelObject.parent,
              orgDefLoc: this.viewer.scene.cloneDefaultLocation(), // for undo
            };
          } else {
            this.viewer.transformControls.detach();
          }

          const relatedObjects = getRelatedObjects( closestLevelObject );
          for ( let i = 0; i < relatedObjects.length; i += 1 ) {
            relatedObjects[ i ].updateMatrixWorld( true );
          }

          this.viewer.outlinePass.selectedObjects = relatedObjects;
          const selectObject3dAction = {
            type: 'objectselected',
            data: {
              object: relatedObjects,
              mouse: {
                x: event.clientX,
                y: event.clientY
              }
            }
          };

          this.viewer.dispatch( selectObject3dAction );
          this.viewer.renderOnDemand.set();
        }
      }
    };

    this.viewer.canvas.addEventListener( 'mouseup', this.mouseup, false );
    this.viewer.canvas.addEventListener( 'drop', this.drop, false );
    this.viewer.canvas.addEventListener( 'mousedown', this.mousedown, false );
    this.viewer.canvas.addEventListener( 'mousemove', this.mousemove, false );
    this.viewer.canvas.addEventListener( 'dblclick', this.dblclick, false );

    // this.viewer.canvas.addEventListener( 'dragover', ( event ) => {
    //   console.log( 'dragover' );
    //   event.preventDefault();
    //   event.stopPropagation();
    //   mousemove( event );
    // }, false );
    // this.viewer.canvas.addEventListener( 'dragleave', ( event ) => {
    //   console.log( 'dragleave' );
    //   event.preventDefault();
    //   event.stopPropagation();
    // }, false );
    // this.viewer.canvas.addEventListener( 'dragenter', ( event ) => {
    //   // here we can clear dragged image
    //   console.log( 'dragenter' );
    //   event.preventDefault();
    //   event.stopPropagation();
    // }, false );

    // floorplan mode events /////////////////////////////
    const snapToGrid = ( position ) => {
      if ( this.viewer.floorplanModeParams.snapToGrid.small ) {
        position.x = Math.round( position.x );
        position.z = Math.round( position.z );
      } else if ( this.viewer.floorplanModeParams.snapToGrid.big ) {
        position.x = Math.round( position.x / 10 ) * 10;
        position.z = Math.round( position.z / 10 ) * 10;
      } else {
        position.x = Math.round( position.x * 10000 ) / 10000;
        position.z = Math.round( position.z * 10000 ) / 10000;
      }

      return position;
    };

    const createWallUsingMouse = () => {
      if ( !this.intersects ) {
        let from = new Vector3( this.mouse.x, this.mouse.y, 0 ).unproject( this.camera );
        snapToGrid( from );
        let to = new Vector3( this.mouse.x, this.mouse.y, 0 ).unproject( this.camera );
        snapToGrid( to );
        let newWall = this.viewer.floorplanModeParams.currentStage.addWall3D(
          new Vector2( from.x, from.z ),
          new Vector2( to.x - 0.777, to.z )

        );
        // because cannot create two points with the same coords
        newWall.to.x = to.x;
        this.setObject3DF( newWall.to );
      }
      if ( this.intersects && this.intersects.object && this.intersects.object.isCorner ) {
        let to = new Vector3( this.mouse.x, this.mouse.y, 0 ).unproject( this.camera );
        snapToGrid( to );
        let newWall = this.viewer.floorplanModeParams.currentStage.addWall3D(
          this.intersects.object,
          new Vector2( to.x - 0.777, to.z )
        );
        // because cannot create two points with the same coords
        newWall.to.x = to.x;
        this.setObject3DF( newWall.to );
        this.basicPosition = new Vector2( this.intersects.object.x, this.intersects.object.y );
      }
      if ( this.intersects && this.intersects.object && this.intersects.object.isWall3D ) {
        // this.intersects.point nearest to (to - from)
        let wall3D = this.intersects.object;
        let dividePoint = Utils.projectPointOnLine(
          wall3D.to.position.clone(),
          wall3D.from.position.clone(),
          this.intersects.point );

        wall3D.stage.divideWall3D( wall3D, [new Vector2( dividePoint.x, dividePoint.z )] );
        let to = new Vector3( this.mouse.x, this.mouse.y, 0 ).unproject( this.camera );
        snapToGrid( to );
        let newWall = this.viewer.floorplanModeParams.currentStage.addWall3D(
          new Vector2( dividePoint.x, dividePoint.z ),
          new Vector2( to.x - 0.777, to.z - 0.777 )
        );
        // because cannot create two points with the same coords
        newWall.to.x = to.x;
        newWall.to.z = to.z;
        this.setObject3DF( newWall.to );
        this.basicPosition = new Vector2( dividePoint.x, dividePoint.z );

      }
    };

    this.mousemoveF = ( event ) => {
      if ( this.viewer.floorplanMode ) {
        this.mouse.x = ( event.clientX / this.viewer.canvas.clientWidth ) * 2 - 1;
        this.mouse.y = -( event.clientY / this.viewer.canvas.clientHeight ) * 2 + 1;

        if ( this.viewer.floorplanModeParams.instruments.moveObject.enabled ) {
          if ( this.object3dF ) {
            let sceneCoords = new Vector3( this.mouse.x, this.mouse.y, 0 ).unproject( this.camera );
            snapToGrid( sceneCoords );
            if ( !( sceneCoords.x === this.basicPosition.x &&
              sceneCoords.z === this.basicPosition.y ) ) {
              this.object3dF.moveTo( sceneCoords.x, sceneCoords.z, this.viewer.floorplanModeParams.orthoMode, this.basicPosition );

            }

          } else {
            this.raycastToAllFloorplanObjects();
          }
        }

        if ( this.viewer.floorplanModeParams.instruments.selectObject.enabled ||
          this.viewer.floorplanModeParams.instruments.addWall.enabled ) {
          if ( !this.object3dF ) {
            this.raycastToAllFloorplanObjects();
          }
        }

        this.viewer.renderOnDemand.set();
      }
    };
    this.mousedownF = () => {
      if ( this.viewer.floorplanMode ) {

        if ( this.viewer.floorplanModeParams.instruments.addWall.enabled ||
          this.viewer.floorplanModeParams.instruments.moveObject.enabled ) {


          if ( this.viewer.floorplanModeParams.instruments.addWall.enabled ) {
            this.viewer.floorplanModeParams.instruments.moveObject.enabled = false;
          }
          if ( this.viewer.floorplanModeParams.instruments.moveObject.enabled &&
            !this.object3dF ) {
            if ( this.intersects ) {
              this.setObject3DF( this.intersects.object );
            } else {
              this.setObject3DF( null );
            }
          }
        }

        if ( this.viewer.floorplanModeParams.instruments.selectObject.enabled ) {
          this.raycastToAllFloorplanObjects();
          if ( this.intersects ) {
            this.setObject3DF( this.intersects.object );
          } else {
            this.setObject3DF( null );
          }

          this.viewer.renderOnDemand.set();
        }
        if ( this.viewer.floorplanModeParams.instruments.editWall3Ddimension.enabled ) {
          this.viewer.floorplanModeParams.instruments.editWall3Ddimension.enabled = false;
          this.viewer.floorplanModeParams.instruments.selectObject.enabled = true;
        }
      }
    };
    this.mouseupF = () => {
      if ( this.viewer.floorplanMode ) {
        if ( this.viewer.floorplanModeParams.instruments.addWall.enabled ||
          this.viewer.floorplanModeParams.instruments.moveObject.enabled ) {
          if ( this.object3dF ) {
            if ( this.object3dF.isCorner ) {

              let nearestWall3D = this.object3dF.getNearestWall3D();
              if ( nearestWall3D.distance < 10 ) {
                let dividePoint = Utils.projectPointOnLine(
                  nearestWall3D.object.to.position,
                  nearestWall3D.object.from.position,
                  this.object3dF.position
                );

                this.object3dF.stage.divideWall3D( nearestWall3D.object, [new Vector2( dividePoint.x, dividePoint.z )] );
                this.object3dF.stage.floorplan.rebuildGeometry();
              }

              let nearestCorner = this.object3dF.getNearestCorner();
              if ( nearestCorner.distance < 10 ) {
                this.object3dF.stage.mergeCorners( this.object3dF, nearestCorner.object );
                this.object3dF = nearestCorner.object;
              }



              let neighbourWalls3D = this.object3dF.getNeighbourWalls3D();
              for ( let j = 0; j < neighbourWalls3D.length; j += 1 ) {
                let intersections = neighbourWalls3D[ j ].stage.findIntersectedWalls3D( neighbourWalls3D[ j ] );
                if ( intersections.length > 0 ) {
                  let points = [];
                  for ( let i = 0; i < intersections.length; i += 1 ) {
                    points.push( intersections[ i ].point );
                    intersections[ i ].wall.stage.divideWall3D( intersections[ i ].wall, [intersections[ i ].point] );
                  }

                  neighbourWalls3D[ j ].stage.divideWall3D( neighbourWalls3D[ j ], points );
                }
              }

              this.object3dF.rebuildGeometry();
              this.object3dF.stage.floorplan.rebuildGeometry();

            }
            if ( this.object3dF.isWall3D ) {
              // added Wall intersects other walls?
              let intersections = this.object3dF.stage.findIntersectedWalls3D( this.object3dF );
              if ( intersections.length > 0 ) {
                let points = [];
                for ( let i = 0; i < intersections.length; i += 1 ) {
                  points.push( intersections[ i ].point );
                  intersections[ i ].wall.stage.divideWall3D( intersections[ i ].wall, [intersections[ i ].point] );
                }

                this.object3dF.stage.divideWall3D( this.object3dF, points );
              }
            }
          }


          this.setObject3DF( null );
          this.viewer.floorplan2.rebuildGeometry();

        }

        if ( this.viewer.floorplanModeParams.instruments.selectObject.enabled ) {

        }
      }
    };
    this.dblclickF = ( event ) => {
      if ( this.viewer.floorplanMode ) {
        if ( this.viewer.floorplanModeParams.instruments.addWall.enabled ) {
          this.mouse.x = ( event.clientX / this.viewer.canvas.clientWidth ) * 2 - 1;
          this.mouse.y = -( event.clientY / this.viewer.canvas.clientHeight ) * 2 + 1;

          this.raycastToAllFloorplanObjects( );
          createWallUsingMouse();

          this.viewer.floorplanModeParams.instruments.moveObject.enabled = true;
          this.viewer.renderOnDemand.set();
        }
        if ( this.viewer.floorplanModeParams.instruments.selectObject.enabled ) {
          this.raycastToAllFloorplanObjects( true );
          if ( this.intersects && this.intersects.object && this.intersects.object.isFloorplanDimension ) {
            this.viewer.floorplanModeParams.instruments.editWall3Ddimension.enabled = true;
            this.viewer.floorplanModeParams.instruments.selectObject.enabled = false;

          }
        }
      }
    };

    this.viewer.canvas.addEventListener( 'mousemove', this.mousemoveF, false );
    this.viewer.canvas.addEventListener( 'mousedown', this.mousedownF, false );
    this.viewer.canvas.addEventListener( 'mouseup', this.mouseupF, false );
    this.viewer.canvas.addEventListener( 'dblclick', this.dblclickF, false );
  }

  startDragFromUI( object3d ) { // started dragging from UI, so to add only.
    this.startDrag( object3d );
    this.dragFromUI = {
      orgDefLoc: this.viewer.scene.cloneDefaultLocation() // for undo
    };
  }

  startDrag( object3d ) {
    this.viewer.controls.saveState();
    this.viewer.controls.enabled = false;
    if ( this.object3d && this.object3d !== object3d ) {
      this.stopDrag();
    }

    if ( !object3d ) {
      return false;
    }

    this.intersects = null;
    this.object3d = object3d;

    this._saveObjectPosition();

    this.raycastObjects = [];
    this.scene.traverseVisible( ( obj ) => {
      if (
        obj.isMesh &&
        obj.isMount &&
        ( ( obj.isMountPoint && obj.parent.children.length <= 1 ) ||
          obj.isMountPlane || obj.isMountLine
        ) &&
        this.slotTypeMatches( obj.mountSlotTypes, this.object3d.mountTypes ) &&
        !obj.isTransformControlsPlane
      ) {
        obj.parent.setMaterialToUnselected();
        this.raycastObjects.push( obj );
      }
    } );
    this.viewer.renderOnDemand.set();

    return true;
  }

  stopDrag() {
    this.viewer.cubeCamera.flag = true;
    if ( !this.viewer.firstPersonControls.enabled ) {
      if ( this.object3d ) {
        this.viewer.controls.reset();
        Reflect.deleteProperty( this.object3d.userData, '_beforeAutoFitWidth' );
      }

      this.viewer.controls.enabled = Storage.get( 'viewer' ).isCameraUnlocked;
    }

    this.scene.traverseVisible( ( obj ) => {
      if ( obj.isMesh && obj.isMount && !obj.isTransformControlsPlane ) {
        obj.parent.setMaterialToInvisible();
      }
    } );

    this.raycastObjects = [];
    if ( !this.allowPasteObject3D && this.object3d ) {
      if ( this.lastValidPosition.parent ) {
        this.lastValidPosition.parent.add( this.object3d );
      } else if ( this.object3d.parent ) {
        this.object3d.parent.remove( this.object3d );
      }

      this.lastValidPosition.matrix.decompose( this.object3d.position, this.object3d.quaternion, this.object3d.scale );
      this.object3d.updateMatrix();
    }

    if ( this.snapping && this.object3d && this.object3d.countertop ) {
      // удалить все baseCountertop  =  this
      this.scene.traverse( ( obj ) => {
        if (
          isCabinet( obj ) &&
          obj.countertop &&
          obj.countertop.baseCountertop === this.object3d.countertop
        ) {
          obj.countertop.baseCountertop = null;
        }
      } );
      // поставить этот - всем общим как baseCountertop
      this.object3d.countertop.updateAllMergedUvsOffset( this.object3d.countertop.baseCountertop || this.object3d.countertop );
    }

    const doMgr = this.viewer.doMgr;
    const onceMgr = this.viewer.onceActionsMgr;

    if (this.dragFromUI) {
      const entity = this.object3d;
      if (isHoleableWithWall(entity)) onceMgr.registerRefreshWallHoles(getWall3D(entity)); // refresh holes

      const defaultLocationBefore = this.dragFromUI.orgDefLoc;
      const defaultLocationAfter = this.viewer.scene.cloneDefaultLocation();
      doMgr.registerDo(new DoAddRemoveEntity(entity, true, null, {defaultLocationBefore, defaultLocationAfter}));
    } else if (this.transformInfo) {
      let matBefore = this.transformInfo.orgMatrix;
      let matAfter = this.transformInfo.entity.matrix.clone();
      if (!matBefore.equals(matAfter)) {
        const entity = this.transformInfo.entity;
        const orgParent = this.transformInfo.orgParent;
        const curParent = entity.parent;
        const defaultLocationBefore = this.transformInfo.orgDefLoc;
        const defaultLocationAfter = this.viewer.scene.cloneDefaultLocation();
  
        doMgr.beginDo();
        if (this.transformInfo.orgParent !== entity.parent) {
          doMgr.registerDo(new DoAddRemoveEntity(entity, false, orgParent)); // remove from old.
          doMgr.registerDo(new DoAddRemoveEntity(entity, true, curParent)); // add to another.
        }
        doMgr.registerDo(new DoSetDefaultLocation(defaultLocationBefore, defaultLocationAfter)); // default location to place new entity.
        doMgr.registerDo(new DoSetEntityMatrix(entity, matBefore, matAfter)); // matrix
        if (isHoleableWithWall(entity)) {
          const drwh = new DoRefreshWallHoles([orgParent, curParent]); // refresh holes on walls.
          drwh.redo();
          doMgr.registerDo(drwh);
        }
        doMgr.endDo();
      }
    }

    this.dragFromUI = null;
    this.transformInfo = null;

    this.intersects = null;
    this.object3d = null;
    this.lastValidPosition = null;
  }

  slotTypeMatches( mountSlotTypes, slotTypes ) {
    if ( !( slotTypes instanceof Array ) ) {
      return false;
    }

    for ( let i = 0; i < mountSlotTypes.length; i += 1 ) {
      for ( let j = 0; j < slotTypes.length; j += 1 ) {
        if ( mountSlotTypes[ i ] === slotTypes[ j ] ) {
          return true;
        }
      }
    }

    return false;
  }

  _saveObjectPosition() {
    this.object3d.updateMatrix();
    this.lastValidPosition = {
      matrix: this.object3d.matrix.clone(),
      parent: this.object3d.parent
    };
  }

  switchMountToUnselected( mount ) {
    if ( mount === null || mount.object === null || !mount.object.isMount ) {
      return;
    }

    mount.object.parent.setMaterialToUnselected();

    if ( this.object3d.parent ) {
      // This.object3d.parent.remove(this.object3d);
    }

    this.intersects = null;
  }

  switchMountToSelected( mount ) {
    if ( this.object3d.unmountFrom && this.object3d.parent && this.object3d.parent.parent ) {
      // this.object3d.unmountFrom( this.object3d.parent );
      // const parent = this.object3d.parent;
      // parent.remove( this.object3d );
      // parent.parent.rebuildGeometry();
    }

    mount.object.parent.setMaterialToSelected();
    if ( !this.object3d.parent ) {
      if ( isFloorMounted( this.object3d ) ) {
        this.scene.defaultLocation.floor.lastObject = this.object3d;
      }
      if ( isWallMounted( this.object3d ) ) {
        this.scene.defaultLocation.wall.lastObject = this.object3d;
      }
    }

    mount.object.parent.add( this.object3d );
    if ( this.object3d.mountTo ) {
      // this.object3d.mountTo( mount.object.parent );
      // console.log( this.object3d.parent );
      // this.object3d.parent.parent.rebuildGeometry();
    }

    this.refreshMountPosition( mount );

    this.intersects = mount;
  }

  refreshMountPosition( mount ) {

    if ( mount.object.isMountPlane ) {
      this.refreshMountPositionPlane( mount );
    }
    if ( mount.object.isMountLine ) {
      this.refreshMountPositionPlane( mount );
    }
    if ( mount.object.isMountPoint ) {
      this.refreshMountPositionPoint( mount );
    }
  }

  refreshMountPositionPoint( mount ) {
    this.object3d.position.copy( mount.object.position );
    // this.object3d.rotation.set(mount.object.rotation.x, mount.object.rotation.y, mount.object.rotation.z);
    /* copy(
      mount.object.position
        .clone()
        .applyMatrix4(new Matrix4().getInverse(mount.object.matrixWorld))
    ); */
  }

  refreshMountPositionPlane( mount ) {
    const yPos = this.object3d.position.y;
    this.object3d.position.copy(
      mount.point
        .clone()
        .applyMatrix4( new Matrix4().getInverse( mount.object.matrixWorld ) )
    );
    if ( isWallMounted( this.object3d ) && this.object3d.getCanMoveVertically() === false ) {
      this.object3d.position.y = yPos;
    }
    if ( isWallMounted( this.object3d ) && this.object3d.getCanMoveVertically() === null ) {
      this.object3d.position.y = Storage.get( 'cabinetsSettings' ).tall.height.value /* this.scene.floorplan2.tallCabinetHeight */ - this.object3d.getSizes().height / 2;
      this.object3d.setCanMoveVertically( false );
    }


    this.object3d.position.z += 0.0001;
  }

  raycastToMountObjects( tolerance ) {
    this.raycaster.setFromCamera( this.mouse, this.camera );
    this.raycaster.params.Points.threshold = tolerance;
    this.raycaster.linePrecision = tolerance;
    const intersections = this.raycaster.intersectObjects( this.raycastObjects );
    if ( intersections.length > 0 ) {
      // Intersection detected
      if (
        this.intersects === null ||
        !( intersections[ 0 ].object === this.intersects.object )
      ) {
        // Intersection object is changed
        this.switchMountToUnselected( this.intersects );
        this.switchMountToSelected( intersections[ 0 ] );
      } else {
        this.switchMountToSelected( intersections[ 0 ] );
        // This.refreshMountPosition(intersections[0]);
      }
    } else {
      // No intersection detected
      this.switchMountToUnselected( this.intersects );
    }

    return this.intersects;
  }

  raycastToAllObjectsAndDrag() {
    this.raycaster.setFromCamera( this.mouse, this.camera );

    this.raycastObjects = [];
    this.scene.traverseVisible( ( obj ) => {
      if ( obj.isMesh && !obj.parent.isWall && !obj.parent.isFloor && !obj.parent.isWall3D && ( isCabinet( getTopLevelObject( obj ) ) || obj.isTransformControls ) && obj.material.visible && !obj.isTransformControlsPlane ) {
        this.raycastObjects.push( obj );
      }
    } );

    const intersections = this.raycaster.intersectObjects(
      this.raycastObjects,
      false
    );
    if ( intersections.length > 0 ) {
      // Intersection detected
      if (
        this.intersects === null ||
        !( intersections[ 0 ].object === this.intersects.object )
      ) {
        this.intersects = intersections[ 0 ];
        // console.log(intersections[0].object, getTopLevelObject(intersections[0].object), isCabinet(getTopLevelObject(intersections[0].object)));
        if ( isCabinet( getTopLevelObject( intersections[ 0 ].object ) ) ) {
          this.startDrag( getTopLevelObject( intersections[ 0 ].object ) );
        }
      }
    } else {
      // No intersection detected
      this.intersects = null;
      // console.log('raycasts to : NULL');
    }
  }

  raycastToAllObjects() {
    this.raycaster.setFromCamera( this.mouse, this.camera );

    this.raycastObjects = [];
    this.scene.traverseVisible( ( obj ) => {
      if ( obj.isMesh && !obj.parent.isWall3D && obj.material.visible && !obj.isTransformControlsPlane ) {
        this.raycastObjects.push( obj );
      }
    } );

    const intersections = this.raycaster.intersectObjects(
      this.raycastObjects,
      false
    );
    if ( intersections.length > 0 ) {
      // Intersection detected
      if (
        this.intersects === null ||
        !( intersections[ 0 ].object === this.intersects.object )
      ) {
        this.intersects = intersections[ 0 ];
      }
    } else {
      this.intersects = null;
      /*
       * No intersection detected
       * console.log('raycasts to : NULL');
       */
    }
  }

  raycastToAllFloorplanObjects( withWallDimensions ) {
    this.raycaster.setFromCamera( this.mouse, this.camera );

    this.raycastObjects = [];
    this.scene.traverseVisible( ( obj ) => {
      if ( obj.isMesh &&
        obj.isRaycastedInFloorplanMode &&
        obj.material.visible &&
        ( withWallDimensions || !obj.isFloorplanDimension ) ) {
        this.raycastObjects.push( obj );
      }
    } );

    const intersections = this.raycaster.intersectObjects(
      this.raycastObjects,
      false
    );
    if ( intersections.length > 0 ) {
      // Intersection detected
      if (
        this.intersects === null ||
        !( getTopLevelObject( intersections[ 0 ].object ) === this.intersects.object )
      ) {
        let intersection = null;
        for ( let i = 0; i < intersections.length; i += 1 ) {
          if ( getTopLevelObject( intersections[ i ].object ).isCorner ) {
            intersection = intersections[ i ];
            break;
          }
        }

        if ( !intersection ) {
          intersection = intersections[ 0 ];
        }

        intersection.object = getTopLevelObject( intersection.object );
        if ( this.intersects && this.intersects.object ) {
          if ( this.intersects.object.setMaterialToUnselected ) {
            this.intersects.object.setMaterialToUnselected();
          }
        }


        intersection.object.setMaterialToSelected();
        this.intersects = intersection;
        // this.viewer.outlinePass.selectedObjects = [intersection.object];
        let selectObject3dAction = {
          type: 'objectselected',
          data: {
            object: [intersection.object],
            mouse: {
              x: event.clientX,
              y: event.clientY
            }
          }
        };
        if ( this.viewer.floorplanModeParams.instruments.selectObject.enabled ) {
          this.viewer.dispatch( selectObject3dAction );
        }
      }
    } else {
      if ( this.intersects && this.intersects.object ) {
        if ( this.intersects.object.setMaterialToUnselected ) {
          this.intersects.object.setMaterialToUnselected();
        }

        let selectObject3dAction = {
          type: 'objectselected',
          data: {
            object: [null],
            mouse: {
              x: event.clientX,
              y: event.clientY
            }
          }
        };

        this.viewer.dispatch( selectObject3dAction );
      }

      this.intersects = null;
      this.viewer.outlinePass.selectedObjects = [];
      /*
       * No intersection detected
       * console.log('raycasts to : NULL');
       */
    }
  }

  update() {
    this.intersectionDetector.update();
  }

  autoFitByWidth() {

    if ( Math.abs( Math.abs( this.snapping.plusX.distance ) + Math.abs( this.snapping.minusX.distance ) - 0.001 - this.object3d.vestaObject.getSizes().width ) >= 0.001 ) {

      // console.log('autofit');
      this.object3d.userData._beforeAutoFitWidth = this.object3d.vestaObject.getSizes().width;
      this.object3d.fitByWidthToSpace();
      // this.object3d.vestaObject.changeWidth(Math.abs(this.snapping.plusX.distance) + Math.abs(this.snapping.minusX.distance) - 0.001);
    }
  }

  autoFitByWidthUndo() {
    if ( this.object3d.userData._beforeAutoFitWidth ) {
      // console.log('autofitUndo');
      this.object3d.vestaObject.changeWidth( this.object3d.userData._beforeAutoFitWidth );
      Reflect.deleteProperty( this.object3d.userData, '_beforeAutoFitWidth' );
    }
  }
}

/*
 *Export default function DragDropWrap(scene, getBp) {
 * const getCamera = function() {
 * return getBp().three.camera;
 * };
 *
 * return new DragDropManager(scene, getCamera);
 *}
 */
