/* eslint-disable no-alert */
/* eslint-disable react/destructuring-assignment */
// import React, { Fragment as F } from 'react';
// import { connect } from 'react-redux';
// import { IconButton } from 'c/shared/icon-button';
// import style from './index.styl';
// import { simpleActionCreator as sAC } from '@r/actions/index.js';
// import { modalTabsConstants as mTC } from '@r/actions/constants.js';
// import Modal from 'c/Modal/index.jsx';
// import Button from "c/ThemeButton/index.jsx";
// import XLSX from 'xlsx';
// import getMV from 'scr/getMV.js';
// import { findCabinets } from 'cSrc/structured/kitchens/utils/pureUtils.js';
// import download from 'scr/download.js';
// import { getDirtyHacks as getDh } from 'scr/getBlueprintObject.js';

// class ProfileModal extends React.Component {
//   actions = [
//     {
//       label: "Export .xlsx from selected kitchen", uiName: "Export",
//       onClick: () => {
//
//

//
//
//
//
//

//
//

//

//
//       }
//     },
//     {
//       label: "MV ktchen export", uiName: "MV export",
//       onClick: () => {
//         const kitchen = getDh().getActiveKitchen();
//         if(!kitchen) return this.innerCloseImportExportModal();;

//         const { projectName, email } = this.props;

//         const products = [];
//         findCabinets(kitchen, it => it.metadata.itemName != "Container" && products.push(it));

//         const xml = getMV({
//           projectName,
//           email,
//           products
//         });

//         download(xml, "MVExport.xml", "text/html");
//       }
//     },
//   ]

//  innerCloseImportExportModal = () => {
//  this.props.closeImportExportModal(false);
//   }

//  render(){
//  const {
//       props: { visible, className },
//       innerCloseImportExportModal, actions
//  } = this;

//  return (
//    <div className={ className + (visible ? "" : " hidden") }>
//         <Modal className={ style.importExportModal }>
//           <h3>Select action</h3>
//           <IconButton
//             title="Close"
//             variant="close"
//             className={style.close}
//             onClick={innerCloseImportExportModal}
//           />
//           {
//             actions.map(it =>
//               <div className="importExportRow" key={ it.label }>
//                 <span>{ it.label }</span>
//                 <Button onClick={ it.onClick }>{ it.uiName }</Button>
//               </div>
//             )
//           }
//  {/* <div className="btns">
//  <Button>Save</Button>
//  <Button inverse>Cancel</Button>
//  </div> */}
//         </Modal>
//       </div>
//  );
//  }
// }

// export default connect(
//   ({
//     importExportModal: visible,
//     email, projectName
//   }) => ({ visible, email, projectName }),
//  { closeImportExportModal: sAC(mTC.SET_IMPORT_EXPORT_MODAL) }
// )(ProfileModal)


import React, { PureComponent, Dispatch } from 'react';
import { connect } from 'react-redux';
import { Modal } from '@npmrost/storybook/stories/Vesta/Modal';
import { Button } from '@npmrost/storybook/stories/Vesta/Button';
import Storage from 'scr/utilitiesStorage';
import {
  NominalNumTuple2, b, inches, USD, baseOf
} from '@npmrost/utils';
import {
  GenericCabinet
} from 'cSrc/structured/singletonCabinet/core/GenericCabinet';
import XLSX from 'xlsx';
import { roundForUi } from 'scr/metricsConversion';
import { ContainerConfig } from 'decl/cabinetdesigner/ContainerConfigs.d.ts';
import ThreeJSLoader from 'c/ThreeJsWrap/Viewer/core/loaders/THREEJSLoader';

interface _IStateProps {
  visible: redux._IStore['modals']['importExportModalVisible'];
}

interface _IDispatchProps {
  dispatch: Dispatch<
    redux._IActions['SET_IMPORT_EXPORT_MODAL_VISIBLE']
  >;
}

type cabinetWidth = NominalNumTuple2< b<inches>, 'cabinetWidth'>;
type cabinetDepth = NominalNumTuple2< b<inches>, 'cabinetDepth'>;
type cabinetHeight = NominalNumTuple2< b<inches>, 'cabinetHeight'>;

type doorWidth = NominalNumTuple2< b<inches>, 'doorWidth'>;
type doorDepth = NominalNumTuple2< b<inches>, 'doorDepth'>;
type doorHeight = NominalNumTuple2< b<inches>, 'doorHeight'>;

type XlsxCabinetsColumns = [
  string,
  cabinetWidth,
  cabinetDepth,
  cabinetHeight,
  USD,
  string
];

type XlsxDoorColumns = [
  string,
  doorWidth,
  doorDepth,
  doorHeight,
  string
]

function getPriceBasedOnWidth( width: inches ) {
  if( width < ( 18 ) ) return 150;

  return 150 + Math.ceil( ( width - ( 17 ) ) / ( 4 ) ) * 50;
}

function extractDoors(
  cont: GenericCabinet
): GenericCabinet | GenericCabinet[] {
  {
    const innerCont = cont as any;
    if( innerCont.mesh !== undefined ) {
      const type = innerCont.mesh.vestaObject.getType();
      if( type === 'panel/door' ) return cont;
    }
  }

  return cont.componentOrder
    .map( ( name ) => cont.component[ name ] )
    .reduce<GenericCabinet[]>(
      ( acc, cont ) => acc.concat(
        extractDoors( cont as unknown as GenericCabinet )
      ),
      []
    );
}

function getMaterialUiName(
  materials: typeof Storage['hash']['materials'],
  material: string
) {
  if( !materials ) throw new Error( 'Materials is absent.' );

  const uiMaterialName = (
    materials.cabinet.filter( ( { name } ) => name === material )[ 0 ] ||
    { uiName: material }
  ).uiName;

  return uiMaterialName;
}

export const ImportExportModal = connect<
_IStateProps, _IDispatchProps, {}, redux._IStore
>(
  ( s ) => ( {
    visible: s.modals.importExportModalVisible
  } )
)(
  class ImportExportModal extends PureComponent<
    _IStateProps & _IDispatchProps
  > {
    closeModal = () => this.props.dispatch( {
      type: 'SET_IMPORT_EXPORT_MODAL_VISIBLE', value: false
    } )

    import3dModel = () => {
      var fileInput = document.createElement( 'input' );
      fileInput.multiple = true;
      fileInput.type = 'file';
      fileInput.addEventListener( 'change', function ( _e ) {
        console.log(fileInput.files);
        var loader = new ThreeJSLoader();
        loader.loadFiles(fileInput.files);
      } );
      fileInput.click();
      this.closeModal();
    }

    exportSceneToXlsx = () => {
      const materials = Storage.get( 'materials' );

      const cabinets = Storage.get( 'viewer' )
        .scene.getAllCabinets() as GenericCabinet[];

      const xslxCabinets: XlsxCabinetsColumns[] = cabinets.map( ( cab ) => {
        const config = cab.getConfig() as ContainerConfig;


        return [
          config.name || '',

          roundForUi( config.width || 0, 'inch' ) as
            baseOf<typeof config.width> as XlsxCabinetsColumns[1],
          roundForUi( config.depth || 0, 'inch' ) as
            baseOf<typeof config.depth> as XlsxCabinetsColumns[2],
          roundForUi( config.height || 0, 'inch' ) as
            baseOf<typeof config.height> as XlsxCabinetsColumns[3],

          String(
            getPriceBasedOnWidth( config.width || 0 as inches ) *
              ( cab.getType() === 'tall' ? 1.5 : 1 )
          ) as USD,

          getMaterialUiName( materials, config.cabinetMaterialName )
        ];
      } );

      const xlsxDoors: XlsxDoorColumns[] = cabinets
        .reduce<GenericCabinet[]>(
          ( acc, cab ) => acc.concat( extractDoors( cab ) ), []
        )
        .map( ( door ) => {
          const sizes = door.getSizes();

          // eslint-disable-next-line func-name-matching
          const cabinet = ( function getCabinetParent( cab: GenericCabinet | null ): GenericCabinet | null {
            if( !cab ) return null;

            if( cab.getType && cab.getType() !== '' ) {
              return cab;
            }

            return getCabinetParent( cab.parent as GenericCabinet | null );
          }( door ) );


          const materialName = cabinet
            ? ( cabinet.getConfig() as ContainerConfig ).doorMaterialName
            : '';

          return [
            'Door',
            sizes.width as XlsxDoorColumns[1],
            sizes.depth as XlsxDoorColumns[2],
            sizes.height as XlsxDoorColumns[3],
            getMaterialUiName( materials, materialName )
          ] as XlsxDoorColumns;
        } );

      const data = [
        ['Cabinets'],
        ['Name', 'W', 'D', 'H', 'Price', 'Body Material']
      ]
        .concat(
          xslxCabinets as string[][]
        )
        .concat( [[
          '', '', '', 'Total Sum:', String(
            xslxCabinets
              .reduce( ( acc, cab ) => acc + Number( cab[ 4 ] ), 0 )
          )
        ]] )
        .concat( [[''], ['']] )
        .concat( [
          ['Doors'],
          ['', 'W', 'D', 'H', 'Door Material']
        ] )
        .concat( xlsxDoors as string [][] );

      const wb = XLSX.utils.book_new();
      let ws = XLSX.utils.aoa_to_sheet( data );
      XLSX.utils.book_append_sheet( wb, ws, 'Scene Total' );
      XLSX.writeFile( wb, 'out.xlsx' );

      this.closeModal();
    }

    render() {
      const {
        closeModal,
        import3dModel,
        exportSceneToXlsx,
        props: { visible }
      } = this;

      return (
        <Modal
          zIndex={ visible ? undefined : -1 }
          close={ closeModal }
        >
          <h1>Import / Export</h1>
          <div style={{display:'flex', flexDirection:'column'}}>
            <Button primary onClick={ import3dModel }>
              Import 3D Model from Local
            </Button>
            <br></br>
            <Button primary onClick={ exportSceneToXlsx }>
              Export Scene to xlsx
            </Button>
          </div>
        </Modal>
      );
    }
  }
);
