/* eslint-disable no-restricted-syntax */
import {
  getTreeViewStructure, groupData
} from '@r/reducers/storeParts/calculationMode/utils';
import { simpleSet } from '@r/reducers/utils';
import { getCabinets } from 'services/cabinets/cabinets.service';

export const cabinets = simpleSet(
  'SET_CALCULATION_MODE_CABINETS',
  []
);

function restructureLang( cabinets, lang ) {
  return cabinets.map( ( item ) => {
    return {
      ...item,
      ...item[ lang ]
    };
  } );
}

export function setCabinets() {
  return async ( dispatch, getStore ) => {
    const { language } = getStore();

    const cabinets = getTreeViewStructure(
      groupData(
        restructureLang(
          await getCabinets( language ),
          language
        ),
        ['groupUi', 'carcasseUi', 'subGroupUi']
        // ['groupUi', 'subGroupUi', 'carcasseUi']
      ),
      'name'
    );

    dispatch( {
      type: 'SET_CALCULATION_MODE_CABINETS',
      value: cabinets
    } );
  };
}
