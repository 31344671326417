import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { materialsSets } from './materialsSets';
import { countertop } from './countertop';
import { pulls } from './pulls';
import { hinges } from './hinges';
import { legs } from './legs';
import { drawerSystems } from './drawerSystems';
import { markup } from './markup';

const materialsComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['projects']['currentProject']['settings']['materials'],
    AllActions
  >;

export const materials = materialsComb( {
  materialsSets,
  countertop,
  pulls,
  hinges,
  legs,
  drawerSystems,
  markup
} );
