import { Vector3, Vector2, Euler } from 'three';
import Storage from 'scr/utilitiesStorage';
import Panel from './Panel';
import Part from './Part';
import Cabinet from './Cabinet';
import Countertop from './Countertop';

const items = {
  Panel,
  Part,
  Cabinet,
  Countertop
};


export type parsedValue = {
                type: string;
                value: string;
            }
export type parsedArray = parsedValue[];
export type textShape = {
                point: textVector2;
                type: string;
            }[];
export type textVector3 = {
                x: number | parsedArray;
                y: number | parsedArray;
                z: number | parsedArray;
            };
export type textVector2 = {
                x: number | parsedArray;
                y: number | parsedArray;
            };
export type textAttributes = {
                key: string;
                value: number | string | parsedArray;
            }

export function parseArray ( arr: parsedArray ) {
  let result = '';

  for ( let i = 0; i < arr.length; i += 1 ) {
    switch( arr[ i ].type ) {
      case 'operator':
        result += arr[ i ].value;
        break;
      case 'constant':
        result += arr[ i ].value;
        break;
      case 'size':
        result += `this.getSize()['${ arr[ i ].value }']`;
        break;
      case 'attribute':
        result += `this.getAttributeValue('${ arr[ i ].value }')`;
        break;
      case 'parentSize':
        result += `this.parent.getSize()['${ arr[ i ].value }']`;
        break;
      case 'parentAttribute':
        result += `this.parent.getAttributeValue('${ arr[ i ].value }')`;
        break;
      default:
        console.error( 'Unexpected JSON content : ', arr[ i ] );

        return '';
    }
  }

  return result;
}


export function createObjectFromConfig ( config ) {
  const result = new items[ config.constrName ]( config );

  return result;
}

export function parseAttributes ( json ) {
  const result = {};
  for ( let i = 0; i < json.length; i += 1 ) {
    if ( json[ i ].value instanceof Array ) {
      result[ json[ i ].key ] = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].value] ) };
    } else {
      result[ json[ i ].key ] = { type: 'value', value: json[ i ].value };
    }
  }

  return result;
}

export function parseVector2( json ) {
  const result = {};
  if ( json.x instanceof Array ) {
    result.x = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json.x] ) };
  } else {
    result.x = { type: 'value', value: json.x };
  }
  if ( json.y instanceof Array ) {
    result.y = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json.y] ) };
  } else {
    result.y = { type: 'value', value: json.y };
  }

  return result;

}

export function parseVector3( json ) {

  const result = {};
  if ( json.x instanceof Array ) {
    result.x = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json.x] ) };
  } else {
    result.x = { type: 'value', value: json.x };
  }
  if ( json.y instanceof Array ) {
    result.y = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json.y] ) };
  } else {
    result.y = { type: 'value', value: json.y };
  }
  if ( json.z instanceof Array ) {
    result.z = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json.z] ) };
  } else {
    result.z = { type: 'value', value: json.z };
  }

  return result;
}

export function parseShape( json ) {
  const result = [];
  for ( let i = 0; i < json.length; i += 1 ) {
    let element = {};
    if ( json[ i ].x !== undefined ) {
      if ( json[ i ].x instanceof Array ) {
        element.x = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].x] ) };
      } else {
        element.x = { type: 'value', value: json[ i ].x };
      }
    }
    if ( json[ i ].y !== undefined ) {
      if ( json[ i ].y instanceof Array ) {
        element.y = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].y] ) };

      } else {
        element.y = { type: 'value', value: json[ i ].y };
      }
    }
    if ( json[ i ].center !== undefined ) {
      element.center = {};
      if ( json[ i ].center.x instanceof Array ) {
        element.center.x = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].center.x] ) };
      } else {
        element.center.x = { type: 'value', value: json[ i ].center.x };
      }
      if ( json[ i ].center.y instanceof Array ) {
        element.center.y = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].center.y] ) };

      } else {
        element.center.y = { type: 'value', value: json[ i ].center.y };
      }
    }
    if ( json[ i ].radius !== undefined ) {
      if ( json[ i ].radius instanceof Array ) {
        element.radius = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].radius] ) };
      } else {
        element.radius = { type: 'value', value: json[ i ].radius };
      }
    }
    if ( json[ i ].startAngle !== undefined ) {
      if ( json[ i ].startAngle instanceof Array ) {
        element.startAngle = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].startAngle] ) };
      } else {
        element.startAngle = { type: 'value', value: json[ i ].startAngle };
      }
    }
    if ( json[ i ].endAngle !== undefined ) {
      if ( json[ i ].startAngle instanceof Array ) {
        element.endAngle = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].endAngle] ) };
      } else {
        element.endAngle = { type: 'value', value: json[ i ].endAngle };
      }
    }
    if ( json[ i ].clockwise !== undefined ) {
      if ( json[ i ].clockwise instanceof Array ) {
        element.clockwise = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].clockwise] ) };
      } else {
        element.clockwise = { type: 'value', value: json[ i ].clockwise };
      }
    }
    if ( json[ i ].controlPoint1 !== undefined ) {
      element.controlPoint1 = {};
      if ( json[ i ].controlPoint1.x instanceof Array ) {
        element.controlPoint1.x = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].controlPoint1.x] ) };
      } else {
        element.controlPoint1.x = { type: 'value', value: json[ i ].controlPoint1.x };
      }
      if ( json[ i ].controlPoint1.y instanceof Array ) {
        element.controlPoint1.y = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].controlPoint1.y] ) };

      } else {
        element.controlPoint1.y = { type: 'value', value: json[ i ].controlPoint1.y };
      }
    }
    if ( json[ i ].controlPoint2 !== undefined ) {
      element.controlPoint2 = {};
      if ( json[ i ].controlPoint2.x instanceof Array ) {
        element.controlPoint2.x = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].controlPoint2.x] ) };
      } else {
        element.controlPoint2.x = { type: 'value', value: json[ i ].controlPoint2.x };
      }
      if ( json[ i ].controlPoint2.y instanceof Array ) {
        element.controlPoint2.y = { type: 'parsedValue', parsedValue: Reflect.apply( parseArray, this, [json[ i ].controlPoint2.y] ) };

      } else {
        element.controlPoint2.y = { type: 'value', value: json[ i ].controlPoint2.y };
      }
    }

    element.type = json[ i ].type;
    result.push( element );
  }

  return result;
}

export function buildShape(jshape, owner) {
  const shape = new THREE.Shape();
  let center, radius, startAngle, endAngle, clockwise, controlPoint1, controlPoint2;
  
  // moveTo first
  let point = Reflect.apply(calculateVector2, owner, [jshape[0]]);
  shape.moveTo(point.x, point.y);
  // pathTo nexts
  for (let i = 1; i < jshape.length; i += 1) {
    if (jshape[i].type === 'lineTo') {
      point = Reflect.apply(calculateVector2, owner, [jshape[i]]);
      shape.lineTo(point.x, point.y);
    } else if (jshape[i].type === 'arcTo') {
      center = Reflect.apply(calculateVector2, owner, [jshape[i].center]);
      radius = Reflect.apply(calculateValue, owner, [jshape[i].radius]);
      startAngle = Reflect.apply(calculateValue, owner, [jshape[i].startAngle]);
      endAngle = Reflect.apply(calculateValue, owner, [jshape[i].endAngle]);
      clockwise = Reflect.apply(calculateValue, owner, [jshape[i].clockwise]);
      shape.absarc(center.x, center.y, radius, startAngle, endAngle, clockwise);
    } else if (jshape[i].type === 'bezierCurveTo') { // QuadraticBezier
      point = Reflect.apply(calculateVector2, owner, [jshape[i]]);
      controlPoint1 = Reflect.apply(calculateVector2, owner, [jshape[i].controlPoint1]);
      shape.quadraticCurveTo(controlPoint1.x, controlPoint1.y, point.x, point.y);
    } else if (jshape[i].type === '3bezierCurveTo') { // CubicBezier
      point = Reflect.apply(calculateVector2, owner, [jshape[i]]);
      controlPoint1 = Reflect.apply(calculateVector2, owner, [jshape[i].controlPoint1]);
      controlPoint2 = Reflect.apply(calculateVector2, owner, [jshape[i].controlPoint2]);
      shape.bezierCurveTo(controlPoint1.x, controlPoint1.y, controlPoint2.x, controlPoint2.y, point.x, point.y);
    }
  }
  return shape;
}

export function calculateValue( value ) {
  if ( value.type === 'parsedValue' ) {
    return Reflect.apply(
      new Function(
        `return ${ value.parsedValue }` ),
      this,
      []
    );
  }
  if ( value.type === 'value' ) {
    return value.value;
  }
}
export function calculateVector2( value ) {
  const result = new Vector2();
  result.x = Reflect.apply( calculateValue, this, [value.x] );
  result.y = Reflect.apply( calculateValue, this, [value.y] );

  return result;
}

export function calculateVector3( value ) {
  const result = new Vector3();
  result.x = Reflect.apply( calculateValue, this, [value.x] );
  result.y = Reflect.apply( calculateValue, this, [value.y] );
  result.z = Reflect.apply( calculateValue, this, [value.z] );

  return result;
}

export function calculateEuler( value ) {
  const result = new Euler();
  result.x = Reflect.apply( calculateValue, this, [value.x] );
  result.y = Reflect.apply( calculateValue, this, [value.y] );
  result.z = Reflect.apply( calculateValue, this, [value.z] );

  return result;
}


window.addEventListener( 'keydown', ( event ) => {
  if ( event.keyCode === 74 /* J */ ) {
    /* if ( window.item ) {
      window.item.parent.remove( window.item );
    } */

    const item = createObjectFromConfig( JSON.parse( prompt( 'Enter cabinet JSON' ) ) );
    window.item = item;
    item.position.set( 100, -100, 0 );
    Storage.get( 'viewer' ).floorplan2.stages[ 0 ].rooms[ 0 ].floor2D.mountPlane.add( item );
    Storage.get( 'viewer' ).renderOnDemand.set();
  }
} );
