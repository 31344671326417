
import {
  Group, Mesh, ShapeGeometry, Plane, Vector2, Math as TMath
} from 'three';
import { createGeometry } from 'c/ThreeJsWrap/Viewer/core/helpers/geometry';
import Storage from 'scr/utilitiesStorage';
import { Vector3 } from 'three/src/math/Vector3';
import MountPlane from '../MountPlane';

export default class Wall2D extends Group {
  constructor( name, width, height, shape, materialName ) {
    super();
    this.width = width;
    this.height = height;
    this.isWall = true;
    this.isTopLevel = true;
    this._room = null;
    this._wall3D = null;

    let mesh;

    mesh = new Mesh(
      createGeometry( new ShapeGeometry( shape ) ),
      Storage.get( 'namesToMaterials' ).wall[ materialName ]
    );
    this.mountPlane = new MountPlane( 'wall', shape );
    this.add( this.mountPlane );


    mesh.isCollidable = true;
    mesh.isSnapable = true;
    mesh.name = name;
    mesh.castShadow = true;
    mesh.receiveShadow = true;

    this.add( mesh );
    this.mesh = mesh;
    this.name = name;


    this.vestaObject.setMaterialByName = ( mat ) => {
      const viewer = Storage.get( 'viewer' );
      const newMaterial = Storage.get( 'namesToMaterials' ).wall[ mat.wall ];
      if ( !newMaterial ) {
        return;
      }

      for ( let i = 0; i < Storage.get( 'materials' ).wall.length; i += 1 ) {
        Storage.get( 'materials' ).wall[ i ].material.dispose();
        if ( Storage.get( 'materials' ).wall[ i ].material.map ) {
          Storage.get( 'materials' ).wall[ i ].material.map.dispose();
        }
        if ( Storage.get( 'materials' ).wall[ i ].material.roughnessMap ) {
          Storage.get( 'materials' ).wall[ i ].material.roughnessMap.dispose();
        }
        if ( Storage.get( 'materials' ).wall[ i ].material.normalMap ) {
          Storage.get( 'materials' ).wall[ i ].material.normalMap.dispose();
        }

      }

      this.mesh.material = newMaterial;
      viewer.renderOnDemand.set();
      viewer.cubeCamera.flag = true;

      this.vestaObject.setMaterialByNameDirty( mat.wall );
    };

    this.vestaObject.setMaterialByNameDirty( materialName );
    this.vestaObject.setType( 'wall' );
  }

  rebuildGeometry( width, height, shape ) {
    this.width = width;
    this.height = height;

    const geometry = new ShapeGeometry( shape );
    geometry.userData = {};
    geometry.userData.grainDirection = this.grainDirection;
    geometry.userData.grainOffset = this.grainOffset;

    this.mesh.geometry = createGeometry( geometry );
    this.mountPlane.rebuildGeometry( shape );
  }

  get room() {
    return this._room;
  }

  getPlane() {
    this.updateMatrix();
    this.updateMatrixWorld( true );
    let p1 = new Vector3(
      this.mesh.geometry.parameters.shapes.curves[ 0 ].v1.x,
      this.mesh.geometry.parameters.shapes.curves[ 0 ].v1.y,
      0 )
      .applyMatrix4( this.matrix );

    let p2 = new Vector3(
      this.mesh.geometry.parameters.shapes.curves[ 1 ].v1.x,
      this.mesh.geometry.parameters.shapes.curves[ 1 ].v1.y,
      0 )
      .applyMatrix4( this.matrix );
    let p3 = new Vector3(
      this.mesh.geometry.parameters.shapes.curves[ 2 ].v1.x,
      this.mesh.geometry.parameters.shapes.curves[ 2 ].v1.y,
      0 )
      .applyMatrix4( this.matrix );
    if ( this.wall3D ) {
      p1.applyMatrix4( this.wall3D.matrixWorld );
      p2.applyMatrix4( this.wall3D.matrixWorld );
      p3.applyMatrix4( this.wall3D.matrixWorld );
    }

    return new Plane().setFromCoplanarPoints( p1, p2, p3 );
  }

  set room( room ) {
    this._room = room;
  }

  get wall3D() {
    return this._wall3D;
  }

  set wall3D( wall3D ) {
    this._wall3D = wall3D;
  }

  getMaxUV() {
    let maxX = 0;
    let maxY = 0;
    for ( let i = 0; i < this.mesh.geometry.faceVertexUvs.length; i += 1 ) {
      for ( let j = 0; j < this.mesh.geometry.faceVertexUvs[ i ].length; j += 1 ) {
        for ( let k = 0; k < this.mesh.geometry.faceVertexUvs[ i ][ j ].length; k += 1 ) {
          if ( this.mesh.geometry.faceVertexUvs[ i ][ j ][ k ].x > maxX ) {
            maxX = this.mesh.geometry.faceVertexUvs[ i ][ j ][ k ].x;
          }
          if ( this.mesh.geometry.faceVertexUvs[ i ][ j ][ k ].y > maxY ) {
            maxY = this.mesh.geometry.faceVertexUvs[ i ][ j ][ k ].y;
          }
        }
      }
    }


    return new Vector2( maxX, maxY );
  }

  setGrainDirection( grainDirection ) {
    this.grainDirection = grainDirection * TMath.DEG2RAD;
    if ( this.parent ) {
      this.parent.rebuildGeometry();
    }

    Storage.get( 'viewer' ).renderOnDemand.set();
  }

  getGrainDirection() {
    return this.grainDirection * TMath.RAD2DEG;
  }

  setGrainOffset( grainOffset ) {
    this.grainOffset = grainOffset;
    if ( this.parent ) {
      this.parent.rebuildGeometry();
    }

    Storage.get( 'viewer' ).renderOnDemand.set();
  }
}
