/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { _ISimpleAction, _ISimpleSet, AllActions } from '@r/storeUtils';
import { ProjectTag } from 'c/NewProject/ProjectTag';
import feathersClient from 'scr/getFeathers';
import { ThunkAction } from 'redux-thunk';
import { MongoObjectId } from '@npmrost/utils';
import { simpleSet } from './utils';

/**
 * @example '2011-10-05T14:48:00.000Z'
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
 */
type Timestamp = string & { __brand: 'Timestamp '};
type RealtiveVestaUrl = string & { __brand: 'RealtiveVestaUrl' };

export interface _IProject3d {
  _id: MongoObjectId;
  projectName: string;
  createdAt: Timestamp;
  projectTag: keyof ProjectTag['props']['tags'];
  json: string;
  img: RealtiveVestaUrl;
}

declare global {
  namespace redux {
    interface _IStore {
      projectsFor3d: {
        state: 'loading';
        projectId: MongoObjectId | null;
      } | {
        state: 'idle';
        projectId: MongoObjectId | null;
        projects: _IProject3d[];
      };
    }

    interface _IConstants {
      SET_PROJECTS_FOR_3D: 'SET_PROJECTS_FOR_3D';
    }

    interface _IActions {
      SET_PROJECTS_FOR_3D: _ISimpleAction<
        _IConstants['SET_PROJECTS_FOR_3D'],
        _IStore['projectsFor3d']
      >;
    }
  }
}

type defaultState = redux._IStore['projectsFor3d'];
type setProjects3d = redux._IConstants['SET_PROJECTS_FOR_3D'];

export const projectsFor3d = simpleSet<setProjects3d, defaultState>(
  'SET_PROJECTS_FOR_3D', { state: 'loading', projectId: null }
);


interface _IProjectsServiceResponse {
  data: _IProject3d[];
  total: number;
  skip: number;
  limit: number;
}

interface _IGenericThunk<A extends AllActions, Arg = undefined >{
  ( arg?: Arg ): ThunkAction<Promise<void>, redux._IStore, {}, A>;
}

export const loadProjectsFor3d: _IGenericThunk<redux._IActions['SET_PROJECTS_FOR_3D']> = () => {
  return async ( dispatch, getState ) => {
    const { projectsFor3d } = getState();
    // await feathersClient.service( 'api/projects' ).remove( null, {} );

    const resp: _IProjectsServiceResponse = await feathersClient.service( 'api/projects' ).find( {
      query: {
        $select: ['_id', 'projectName', 'img', 'projectTag', 'createdAt'],
        $limit: 100
      }
    } );

    dispatch( {
      type: 'SET_PROJECTS_FOR_3D',
      value: {
        projects: resp.data,
        state: 'idle',
        projectId: projectsFor3d.projectId
      }
    } );
  };
};

export const saveProject: _IGenericThunk<redux._IActions['SET_PROJECTS_FOR_3D'], string> = ( json = '' ) => {
  return async ( dispatch, getState ) => {
    const { projectFor3d: { projectName, projectTag } } = getState();
    const project: Omit<_IProject3d, 'createdAt' | '_id'> = {
      img: '' as _IProject3d['img'],
      json,
      projectName,
      projectTag
    };

    const rtrn = await feathersClient.service( 'api/projects' ).create( project );

    dispatch( { type: 'SET_PROJECTS_FOR_3D', value: { projectId: rtrn._id as MongoObjectId, state: 'loading' } } );
    dispatch( loadProjectsFor3d() );

    // eslint-disable-next-line no-alert
    alert( 'Project saved' );
  };
};

export const updateProject: ( json: string ) => ThunkAction<Promise<void>, redux._IStore, {}, redux._IActions['setMainModalOpen']> = ( json ) => {
  return async ( dispatch, getState ) => {
    const {
      projectsFor3d: { projectId },
      projectFor3d: { projectName, projectTag }
    } = getState();

    await feathersClient.service( 'api/projects' ).patch( projectId, {
      json,
      projectTag,
      projectName
    } );

    dispatch( loadProjectsFor3d() );

    // eslint-disable-next-line no-alert
    alert( 'Project updated' );
  };
};

export const renameProject: ( id: MongoObjectId, name: string ) => ThunkAction<Promise<void>, redux._IStore, {}, never>
  = ( id, projectName ) => async ( dispatch ) => {
    await feathersClient.service( 'api/projects' ).patch( id, { projectName } );

    dispatch( loadProjectsFor3d() );
    // eslint-disable-next-line no-alert
    alert( 'Project renamed' );
  };

export const duplicateProject: ( id: MongoObjectId, name: string ) => ThunkAction<Promise<void>, redux._IStore, {}, never>
  = ( id, projectName ) => async ( dispatch ) => {
    const { _id, projectName: p, ...original } = await feathersClient.service( 'api/projects' ).get( id );
    await feathersClient.service( 'api/projects' ).create( { ...original, projectName } );

    dispatch( loadProjectsFor3d() );
    // eslint-disable-next-line no-alert
    alert( 'Project duplicated' );
  };

export const deleteProject: ( id: MongoObjectId ) => ThunkAction<Promise<void>, redux._IStore, {}, never>
  = ( id ) => async ( dispatch, getState ) => {
    const { projectId } = getState().projectsFor3d;
    if( projectId === id ) {
      // eslint-disable-next-line no-alert
      return void alert( 'Cannot delete currently opened project' );
    }

    await feathersClient.service( 'api/projects' ).remove( id );

    dispatch( loadProjectsFor3d() );
    // eslint-disable-next-line no-alert
    alert( 'Project deleted' );
  };
