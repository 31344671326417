// @ts-check
import React, { PureComponent } from 'react';
import sanitizeUnits from 'scr/sanitizeUnits';
import DoSetEntityMatrix from 'c/ThreeJsWrap/Viewer/UndoRedo/DoSetEntityMatrix';
import { isHoleableWithWall } from 'c/ThreeJsWrap/Viewer/core/helpers/object3d';
import DoRefreshWallHoles from 'c/ThreeJsWrap/Viewer/UndoRedo/DoRefreshWallHoles';

/**
 * @typedef {{
 *  units: redux.store['projectFor3d']['units'];
 *  onChange: react.components.utils.UnitsInputv2OnChange;
 *  className?: className<'input'>;
 *  value: UnitsInputv2Value;
 * }} Props
 */

/** @augments { PureComponent<Props> } */
export class UnitsInputv2 extends PureComponent {
  onChange = (
    /** @type { React.ChangeEvent<HTMLInputElement> } */
    { target: { value } }
  ) => {
    const { units, onChange } = this.props;
    const rez = sanitizeUnits(
      units,
      /** @type { UnitsInputv2Value } */ ( value )
    );

    if( !rez.isValid ) return;

    onChange( rez.value );
  };

  onBlur = () => {
    this.toDoBlur();
  };

  handleKeyDownEnter = ( e ) => {
    if(e.keyCode === 13) {
      this.toDoBlur();
    }
  };

  toDoBlur = () => {
    const {onBlur} = this.props;
    if (!onBlur) return;

    const entity = Storage.get('selectedObject')[0];
    const before = entity.matrix.clone();
    
    onBlur();

    entity.updateMatrix();
    const after = entity.matrix.clone();

    if (!before.equals(after)) {
      const viewer = Storage.get('viewer');
      const doMgr = viewer.doMgr;

      doMgr.beginDo();
      doMgr.registerDo(new DoSetEntityMatrix(entity, before, after));
      if (isHoleableWithWall(entity)) {
        const drwh = new DoRefreshWallHoles(entity); // refresh holes on walls.
        drwh.redo();
        doMgr.registerDo(drwh);
      }
      doMgr.endDo();
    }
  }

  render() {
    const {
      props: { className = '', value, ...rest }
    } = this;

    return (
      <input { ...{
        ...rest,
        value,
        type: 'text',
        onChange: this.onChange,
        onBlur: this.onBlur,
        onKeyDown: this.handleKeyDownEnter,
        className: `${ className } unitsInput`
      } }
      />
    );
  }

}
