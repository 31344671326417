/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { _ISimpleSet, _ISimpleAction, AllActions } from '@r/storeUtils';
import { simpleSet } from '@r/reducers/utils';
import { quantity } from '@npmrost/utils';

declare global {
  namespace redux {
    namespace store {
      interface _ISelectedCabinet {
        shelves: quantity;
      }
    }

    interface _IConstants {
      SET_SELECTED_CABINET_SHELVES_QTY: 'SET_SELECTED_CABINET_SHELVES_QTY';
    }

    interface _IActions {
      SET_SELECTED_CABINET_SHELVES_QTY: _ISimpleAction<
        _IConstants['SET_SELECTED_CABINET_SHELVES_QTY'],
        _IStore['selectedCabinet']['shelves']
      >;
    }
  }
}

const s = simpleSet as _ISimpleSet<
  redux._IConstants['SET_SELECTED_CABINET_SHELVES_QTY'],
  redux._IStore['selectedCabinet']['shelves'],
  AllActions
>;

export const shelves = s(
  'SET_SELECTED_CABINET_SHELVES_QTY', 0 as quantity
);
