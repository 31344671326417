import React from 'react';
import { connect } from 'react-redux';
import { batchActions } from 'redux-batched-actions';

import {
  saveProject as saveProjectAction
} from '@r/reducers/storeParts/calculationMode/projects/currentProject';

import WithIcon from './WithIcon';
import style from './index.styl';

/*
 * function calcModeModalUtils( forOpenProject ) {
 *   if( !this.props.calculationMode ) return false;
 */

/*
 *   this.props.chooseCalcModalTab(
 *     Object.keys( calcModeModalTabs )[ forOpenProject ? 1 : 0 ]
 *   );
 *   this.props.setCalcModalOpen( true );
 */

/*
 *   return true;
 * }
 */

/*
 * this.content = [
 *   [
 *     {
 *       img: ,
 *       text: 'New&nbsp;Project',
 *       onClick: () => {
 *         if( calcModeModalUtils.call( this ) ) return;
 */

/*
 *         this.props.setMainModalTab( mMT.newProject );
 *         p.setMainModalOpen( true );
 *       }
 *     },
 *     {
 *       img: '/img/ImportExport.svg',
 *       text: 'Import,&nbsp;Export',
 *       onClick: this.innerOpenImportExportModal
 *     }
 *   ],
 *   [
 *     {
 *       img: '/img/OpenProject.svg',
 *       text: 'Open&nbsp;Project',
 *       onClick: () => {
 *         if( calcModeModalUtils.call( this, true ) ) return;
 */

/*
 *         this.props.setMainModalTab( mMT.existingProject );
 *         p.setMainModalOpen( true );
 *       }
 *     },
 *     { img: '/img/Print.svg', text: 'Print' }
 *   ],
 *   [
 *     { img: '/img/Save.svg', text: 'Save', onClick: this.saveProjectInner },
 *     { img: '/img/Profile.svg', text: 'Account', onClick: this.toggleAccountModalInner }
 *   ],
 *   [
 *     {
 *       img: '/img/Settings.svg',
 *       text: 'Project&nbsp;Settings',
 *       onClick: this.toggleSettingsModalInner
 *     },
 *     { img: '/img/Help.svg', text: 'Help' }
 *   ]
 * ];
 */
class FileMenu extends React.PureComponent {
  openNewProjectModal = () => {
    const { dispatch } = this.props;

    dispatch( {
      type: 'TOGGLE_CALCULATION_MODE_MAIN_MODAL',
      value: true
    } );

    dispatch( {
      type: 'SET_CALCULATION_MODE_MAIN_MODAL_TAB',
      value: 'createNewProject'
    } );
  }

  openExistingProjectsModal = () => {
    const { dispatch } = this.props;

    dispatch( {
      type: 'TOGGLE_CALCULATION_MODE_MAIN_MODAL',
      value: true
    } );

    dispatch( {
      type: 'SET_CALCULATION_MODE_MAIN_MODAL_TAB',
      value: 'openExistingProject'
    } );
  }

  saveCurrentProject = () => {
    const { dispatch, hideMenu } = this.props;

    dispatch( saveProjectAction() )
      .then( () => {
        hideMenu();
      } )
      .catch( ( err ) => {
        alert( err );
      } );
  }

  openEditProjectModal = () => {
    const { dispatch } = this.props;

    dispatch( {
      type: 'TOGGLE_CALCULATION_MODE_EDIT_PROJECT_MODAL',
      value: true
    } );
  }

  openAccountModal = () => {
    // const { dispatch } = this.props;

    // dispatch( {
    //   type: 'TOGGLE_CALCULATION_MODE_ACCOUNT_MODAL',
    //   value: true
    // } );
  }

  switchToAdminMode = () => {
    const { generalViewMode, dispatch } = this.props;

    if ( generalViewMode === 'admin' ) {
      return;
    }

    dispatch( batchActions( [
      { type: 'SET_GENERAL_VIEW_MODE', value: 'admin' },
      { type: 'SET_CORNER_MENU_VISIBLE', value: false },
      { type: 'SET_RIGHT_SIDE_CABINET_MENU_OPEN', value: false }
    ] ) );
  }

  render() {
    const {
      props: {
        hidden,
        texts: {
          newProject,
          openProject,
          saveProject,
          projectSettings,
          exportToExcel,
          importFrom3d,
          print,
          account,
          help,
          admin
        },
        userLogin
      },
      openNewProjectModal,
      openExistingProjectsModal,
      saveCurrentProject,
      openEditProjectModal,
      openAccountModal,
      switchToAdminMode
    } = this;

    return (
      <div
        style={ hidden ? { display: 'none' } : {} }
        className={ style.fileMenu }
      >
        <div className='row'>
          <div onClick={ openNewProjectModal }>
            <WithIcon img='/img/NewProject.svg'>
              { newProject }
            </WithIcon>
          </div>

          <div>
            <WithIcon img='/img/ImportExport.svg'>
              { importFrom3d }
            </WithIcon>
          </div>
        </div>

        <div className='row'>
          <div onClick={ openExistingProjectsModal }>
            <WithIcon img='/img/OpenProject.svg'>
              { openProject }
            </WithIcon>
          </div>

          <div>
            <WithIcon img='/img/Print.svg'>
              { print }
            </WithIcon>
          </div>
        </div>

        <div className='row'>
          <div onClick={ saveCurrentProject }>
            <WithIcon img='/img/Save.svg'>
              { saveProject }
            </WithIcon>
          </div>

          <div onClick={ openAccountModal }>
            <WithIcon img='/img/Profile.svg'>
              { account }
            </WithIcon>
          </div>
        </div>

        <div className='row'>
          <div onClick={ openEditProjectModal }>
            <WithIcon img='/img/Settings.svg'>
              { projectSettings }
            </WithIcon>
          </div>

          <div>
            <WithIcon img='/img/Help.svg'>
              { help }
            </WithIcon>
          </div>
        </div>

        {
          (
            userLogin === 'calc' ||
            userLogin === 'garenceva@gmail.com'
          ) && (
            <div className='row'>
              <div onClick={ switchToAdminMode }>
                <WithIcon img='/img/Profile.svg'>
                  { admin }
                </WithIcon>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export default connect(
  ( {
    texts: {
      cornerMenu: {
        file: texts
      }
    },
    generalViewMode,
    user: { login }
  } ) => ( {
    texts,
    generalViewMode,
    userLogin: login
  } )
)( FileMenu );
