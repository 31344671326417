/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable react/no-danger */
import React, { PureComponent, Dispatch } from 'react';
import { connect } from 'react-redux';
import { batchActions, BatchAction } from 'redux-batched-actions';
import Storage from 'scr/utilitiesStorage';
import { batchActionsWithTempl, d } from '@r/storeUtils';
import { Button } from '@npmrost/storybook/stories/Vesta/Button';
import {
  KonvaStage, clearStageAndDrawBackground
} from 'c/Modal/ModalFor2d/KonvaStage';
import style from './index.styl';

type toCalculationEventsBatch = redux._IActions['setCornerMenuVisible'] |
  redux._IActions['setGeneralViewMode'] |
  redux._IActions['setRightSideCabinetMenuOpen'];

const b = batchActions as batchActionsWithTempl<
  redux._IActions['setWireframeMode'] |
  redux._IActions['setOrthographicMode'] |
  redux._IActions['setModalFor2dVisible'] |
  redux._IActions['setLockViewMode'] |
  redux._IActions['setShowCurrentItem'] |
  redux._IActions['setCameraPerItem'] |
toCalculationEventsBatch
>;

interface _IRectOrCircleProps {
  value: boolean;
}
class Circle extends PureComponent<_IRectOrCircleProps> {
  render() {
    const { value } = this.props;

    return (
      <div className={ `circle ${ value ? 'checked' : '' }` } />
    );
  }
}

class Square extends PureComponent<_IRectOrCircleProps> {
  render() {
    const { value } = this.props;

    return (
      <div
        className='square'
        dangerouslySetInnerHTML={ { __html: value ? '&#10004;' : '' } }
      />
    );
  }
}

export function switchToCalculation(
  dispatch: Dispatch<BatchAction>,
  b: batchActionsWithTempl<toCalculationEventsBatch>
) {
  dispatch( b( [
    { type: 'SET_GENERAL_VIEW_MODE', value: 'calculation' },
    { type: 'SET_CORNER_MENU_VISIBLE', value: false },
    { type: 'SET_RIGHT_SIDE_CABINET_MENU_OPEN', value: false }
  ] ) );
}

interface _IProps {
  hidden: boolean;
  generalViewMode: redux._IStore['flags']['generalViewMode'];
  dispatch: d<BatchAction>;
  wireframeMode: redux._IStore['flags']['wireframeMode'];
  orthographicMode: redux._IStore['flags']['orthographicMode'];
  lockViewMode: redux._IStore['flags']['lockViewMode'];
  showCurrentItem: redux._IStore['flags']['showCurrentItem'];
  cameraPerItem: redux._IStore['flags']['cameraPerItem'];
  visible: redux._IStore['modals']['object3dModal']['visible'];
}

class ViewMenu extends PureComponent<_IProps> {
  componentDidUpdate( prevProps: _IProps ) {
    const {
      props: {
        visible
      },
      resetCameraPerItemAndShowCurrentItem
    } = this;
    if( !visible && visible !== prevProps.visible ) {
      resetCameraPerItemAndShowCurrentItem();
    }
  }

  rightAndCornerMenuVisibility = (
    value: boolean,
    viewMode: _IProps['generalViewMode']
  ) => {
    this.props.dispatch(
      b( [
        { type: 'SET_GENERAL_VIEW_MODE', value: viewMode },
        { type: 'SET_CORNER_MENU_VISIBLE', value },
        { type: 'SET_RIGHT_SIDE_CABINET_MENU_OPEN', value }
      ] )
    );
  }

  exitWalkthrough = ( e: React.KeyboardEvent ) => {
    if ( e.keyCode !== 27 ) return;

    this.switchTo3d();
    // @ts-ignore
    document.removeEventListener( 'keyup', this.exitWalkthrough );
  }

  switchToWalkthrough = () => {
    if ( this.props.orthographicMode ) return;

    this.rightAndCornerMenuVisibility( false, 'walkthrough' );

    const viewer = Storage.get( 'viewer' );
    viewer.useFirstPersonControls();

    // @ts-ignore
    document.addEventListener( 'keyup', this.exitWalkthrough );
  }

  switchTo3d = () => {
    if ( this.props.generalViewMode === '3d' ) {
      return;
    }

    this.rightAndCornerMenuVisibility( true, '3d' );

    const viewer = Storage.get( 'viewer' );
    viewer.useOrbitControls();
  }

  toggleShowCurrentItem = () => {
    const { dispatch, visible, showCurrentItem } = this.props;
    const viewer = Storage.get( 'viewer' );
    const selectedObject = Storage.get( 'selectedObject' );
    if( visible && selectedObject[ 0 ] ) {
      dispatch( b( [{
        type: 'SET_SHOW_CURRENT_ITEM', value: !showCurrentItem
      }] ) );

      if( !showCurrentItem ) {
        viewer.scene.setVisibleOnlyItem( selectedObject[ 0 ] );
      } else {
        viewer.scene.restoreVisibilityState();
      }
    } else if( showCurrentItem ) {
      viewer.scene.restoreVisibilityState();
      dispatch( b( [{
        type: 'SET_SHOW_CURRENT_ITEM', value: !showCurrentItem
      }] ) );
    }
  };

  toggleCameraPerItem = () => {
    const { dispatch, visible, cameraPerItem } = this.props;
    const viewer = Storage.get( 'viewer' );
    const selectedObject  = Storage.get( 'selectedObject' );
    if( visible && selectedObject[ 0 ]) {
      dispatch( b( [{
        type: 'SET_CAMERA_PER_ITEM', value: !cameraPerItem
      }] ) );

      viewer.fitCameraToObjectFront(selectedObject[ 0 ])
    }
  };

  resetCameraPerItemAndShowCurrentItem = () => {
    const {
      dispatch,
      visible,
      cameraPerItem
    } = this.props;

    if( !visible && cameraPerItem ) {
      dispatch( b( [{
        type: 'SET_CAMERA_PER_ITEM', value: !cameraPerItem
      }] ) );
    }
  };

  switchToFloorPlan = () => {
    if ( this.props.generalViewMode === 'floorPlan' ) {
      return;
    }

    this.rightAndCornerMenuVisibility( true, 'floorPlan' );

    const viewer = Storage.get( 'viewer' );
    viewer.useFloorplanMode();
  }

  switchToCalculationMode = () => {
    if ( this.props.generalViewMode === 'calculation' ) {
      return;
    }

    switchToCalculation( this.props.dispatch, b );
  }

  toggleWireframe = () => {
    const { dispatch, wireframeMode } = this.props;
    dispatch( b( [{
      type: 'SET_WIREFRAME_MODE', value: !wireframeMode
    }] ) );

    const viewer = Storage.get( 'viewer' );
    if ( wireframeMode ) {
      viewer.scene.resetWireframeMode();
    } else {
      viewer.scene.setWireframeMode();
    }

    viewer.renderOnDemand.set();
  }

  toggleLockView = () => {
    const { dispatch, lockViewMode } = this.props;
    dispatch( b( [{
      type: 'SET_LOCK_VIEW_MODE', value: !lockViewMode
    }] ) );
    const viewer = Storage.get( 'viewer' );
    if ( lockViewMode ) {
      viewer.unlockCamera();
    } else {
      viewer.lockCamera();
    }
  };

  toggleOrthographic = () => {
    const { dispatch, orthographicMode } = this.props;
    dispatch( b( [{
      type: 'SET_ORTHOGRAPHIC_MODE', value: !orthographicMode
    }] ) );

    const viewer = Storage.get( 'viewer' );
    if ( orthographicMode ) {
      viewer.usePerspectiveCamera();
    } else {
      viewer.useOrthographicCamera();
    }

    viewer.renderOnDemand.set();
  }

  // @ts-ignore
  showHiddenLineView = () => {
    if( !this.canUseHiddenLineView() ) {
      // eslint-disable-next-line no-alert
      return void alert( 'Cannot use Hidden Line mode while not in ' +
        '3d or while either wireframe or ortho modes are active.' );
    }

    const { scene } = Storage.get( 'viewer' );
    // createStandard3DView
    const { canvas } = scene.createStandard3DView();

    clearStageAndDrawBackground( KonvaStage, canvas );
    this.props.dispatch(
      b( [
        { type: 'SET_MODAL_FOR_2D_VISIBLE', value: true }
      ] )
    );

    return null;
  }

  private canUseHiddenLineView() {
    return this.props.generalViewMode === '3d' &&
      this.props.orthographicMode === false &&
      this.props.wireframeMode === false;
  }

  render() {
    const {
      props: {
        hidden,
        generalViewMode,
        wireframeMode,
        orthographicMode,
        lockViewMode,
        showCurrentItem,
        cameraPerItem,
        visible
      },
      switchTo3d,
      switchToFloorPlan,
      switchToCalculationMode,
      switchToWalkthrough,
      toggleWireframe,
      toggleLockView,
      toggleOrthographic,
      showHiddenLineView,
      toggleShowCurrentItem,
      toggleCameraPerItem
    } = this;

    return (
      <>
        <div
          style={ hidden ? { display: 'none' } : {} }
          className={ style.viewMenu }
        >

          <div>

            {generalViewMode !== 'floorPlan' &&
            (
              <div
                className={ `viewRow ${ orthographicMode ? 'dis' : '' }` }
                onClick={ switchToWalkthrough }
              >
                <Circle value={ generalViewMode === 'walkthrough' } />
                {'Walkthrough'}
              </div>
            )}

            <div className='viewRow' onClick={ switchToFloorPlan }>
              <Circle value={ generalViewMode === 'floorPlan' } />
              {'Floor plan'}
            </div>

            <div className='viewRow' onClick={ switchTo3d }>
              <Circle value={ generalViewMode === '3d' } />
              {'3D view'}
            </div>

            {generalViewMode !== 'floorPlan' &&
            (
              <div className='viewRow' onClick={ switchToCalculationMode }>
                <Circle value={ generalViewMode === 'calculation' } />
                {'Calculation mode'}
              </div>
            )}

          </div>

          {generalViewMode !== 'floorPlan' &&
          (
            <div>

              <div className='viewRow' onClick={ toggleWireframe }>
                <Square value={ wireframeMode } />
                {'Wireframe'}
              </div>

              <div className='viewRow' onClick={ toggleLockView }>
                <Square value={ lockViewMode } />
                {'Lock view'}
              </div>

              <div className='viewRow' onClick={ toggleOrthographic }>
                <Square value={ orthographicMode } />
                {'Ortho mode'}
              </div>

              <div className={ `viewRow ${ visible || showCurrentItem ? '' : 'blocked' }` } onClick={ toggleShowCurrentItem }>
                <Square value={ showCurrentItem } />
                {'Show current item'}
              </div>

              <div className={ `viewRow ${ visible ? '' : 'blocked' }` } onClick={ toggleCameraPerItem }>
                <Square value={ cameraPerItem && visible } />
                {'Camera per item'}
              </div>


              <div className='viewRow'>
                <Button inverse onClick={ showHiddenLineView }>Hidden line</Button>
              </div>

            </div>
          )}
        </div>
        {/* {
          calculationMode ? null
            : <FloorplanTo3D onClick={ to3D } />
        } */}

      </>
    );
  }
}

export default connect(
  ( { flags, modals }: redux._IStore ) => {
    return {
      generalViewMode: flags.generalViewMode,
      wireframeMode: flags.wireframeMode,
      orthographicMode: flags.orthographicMode,
      lockViewMode: flags.lockViewMode,
      cameraPerItem: flags.cameraPerItem,
      showCurrentItem: flags.showCurrentItem,
      visible: modals.object3dModal.visible
    };
  }
)( ViewMenu );
