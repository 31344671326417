import { inches } from '@npmrost/utils/lib';

export const BackFinishEnd: Partial<CabD.A.ContainerConfigs.ContainerConfig> = {
  height: 36 as inches,
  depth: 1.75 as inches,
  width: 36 as inches,
  name: 'EmptyAssembly',
  layout: 'DEPTH',
  isTopLevel: true,
  children: [
    {
      name: 'BackFinishPanel',
      depth: 0.75 as inches,
      type: 'Panel'
    },
    {}
  ],
  countertopMaterialName: 'marblewhite005'
};

