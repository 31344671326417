import React, { PureComponent } from 'react';

import style from './Modal.styl';

export default class Modal extends PureComponent {
  render() {
    const { children, className } = this.props;

    return (
      <>
        <div
          onClick={ () => this.props.hideModal && this.props.hideModal() }
          className={ style.overlay }
        />
        <div className={ `${ style.modalReact } ${ className }` }>
          {children}
        </div>
      </>
    );
  }

}
