export class CollidableMesh extends THREE.Mesh {
    isCollidable = true;

    isSnapable = true;

    markAsCollided () {
      this.material.visible = true;
      this.material.color.set( 0xff0000 );
    }

    markAsNonCollided () {
      this.material.visible = false;
      this.material.color.set( 0x00ff00 );
    }
}
