/* eslint-disable default-case */
// @ts-check
import React from 'react';
import './index.styl';
import { connect } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import InitViewer from './Viewer';

/**
 * @type {(
 *  roomWidthDepth: ThreeJSWrapProps['roomWidthDepth'],
 *  rest: {
 *    type: ThreeScene.floorplan['type'];
 *    height: ThreeScene.floorplan['height'];
 *    defaultWallMaterialName: ThreeScene.floorplan['defaultFloorMaterialName'];
 *    defaultFloorMaterialName: ThreeScene.floorplan['defaultWallMaterialName'];
 *    tallCabinetHeight: ThreeJSWrapProps['tallCabinetHeight'];
 *  }
 * ) => ThreeScene.floorplan }
 */
function getFloorplan( roomWidthDepth, rest ) {
  const {
    full, single, 'U-shape': uShape,
    'L-shape': lShape
  } = roomWidthDepth;

  switch( rest.type ) {
    case 'full':
      return {
        ...rest,
        type: rest.type,
        width: full.width.value,
        depth: full.depth.value
      };
    case 'single':
      return {
        ...rest,
        type: rest.type,
        width: single.width.value
      };
    case 'L-shape':
      return {
        ...rest,
        type: rest.type,
        width: lShape.width.value,
        depth: lShape.depth.value
      };
    case 'U-shape':
      return {
        ...rest,
        type: rest.type,
        width: uShape.width.value,
        depth: uShape.depth.value,
        depth2: uShape.depth2.value
      };
  }
}

const bWithTempl = /**
  @type {redux.batchActionsWithTempl<
    redux._IActions['setSceneInitialized'] |
    redux._IActions['setMainModalOpen']
  >} */( batchActions );
/**
 * @typedef {{
 *  initHappened: redux._IStore['initHappened'];
 *  dispatch: redux.d<import('redux-batched-actions').BatchAction>;
 *  height: redux._IStore['projectFor3d']['wHeight'];
 *  roomTemplate: redux._IStore['projectFor3d']['roomTemplate'];
 *  roomWidthDepth: redux._IStore['projectFor3d']['roomWidthDepth'];
 *  defaultWallMaterialName: redux._IStore['projectFor3d']['walls']['value'];
 *  defaultFloorMaterialName: redux._IStore['projectFor3d']['floor']['value'];
 *  tallCabinetHeight:  redux._IStore['projectFor3d']['cabinetsSettings']['tall']['height']['value']
 * }} ThreeJSWrapProps
 */

/**
 * @augments { React.PureComponent<ThreeJSWrapProps> }
 */
class ThreeJSWrap extends React.PureComponent {
  id = 'sceneCanvas';

  /** @type { React.RefObject<HTMLDivElement> } */
  rootRef = React.createRef()

  componentDidMount() {
    const {
      height, roomTemplate, roomWidthDepth,
      defaultWallMaterialName,
      defaultFloorMaterialName,
      tallCabinetHeight
    } = this.props;

    InitViewer( {
      cb: this.threeJsLoaded,
      elem: this.rootRef.current,
      floorplan: getFloorplan(
        roomWidthDepth, {
          type: roomTemplate.value,
          height: height.value,
          defaultWallMaterialName,
          defaultFloorMaterialName,
          tallCabinetHeight
        }
      )
    } );
  }

  // eslint-disable-next-line react/destructuring-assignment
  threeJsLoaded = () => this.props.dispatch(
    bWithTempl( [
      { type: 'SET_MAIN_MODAL_OPEN_STATE', value: false },
      { type: 'SET_SCENE_INITIALIZED', value: true }
    ] )
  );


  render() {
    if( !this.props.initHappened ) {
      return null;
    }

    return (
      <div ref={ this.rootRef } id={ this.id } />
    );
  }
}

export default connect(
  ( /** @type { redux._IStore } */{
    initHappened,
    projectFor3d: {
      wHeight: height,
      roomTemplate,
      roomWidthDepth,
      walls,
      floor,
      cabinetsSettings: {
        tall: { height: { value: tallCabinetHeight } }
      }
    }
  } ) => ( {
    initHappened,
    height,
    roomTemplate,
    roomWidthDepth,
    defaultWallMaterialName: walls.value,
    defaultFloorMaterialName: floor.value,
    tallCabinetHeight
  } )
)( ThreeJSWrap );
