import {
  Group,
  Box3,
  Vector3,
  Matrix4,
  Euler,
  Path
} from 'three';
import Storage from 'scr/utilitiesStorage';
import { getFromGroup, getFromModel } from './helpers';
import { getTopLevelObject } from '../helpers/object3d';


/* non auto-centered appliance with any mount types */

export default class Appliance extends Group {
  constructor( source, dimensions, meta ) {
    super();
    
    this.vestaObject.setType('item');

    this.userData.source = source;
    this.userData.rotation = new Euler( 0, 0, 0 );
    this.userData.dimensions = { width: dimensions.width, height: dimensions.height, depth: dimensions.depth };
    this.userData.center = { x: 0, y: 0, z: 0.5 };
    this.userData.addBox = false;

    if (meta.name === 'Sink') {
      this.mountTypes = ['sink'];
    }

    this.initialRotation = 0;
    this.isTopLevel = true;
    this.isAppliance = true;
    this.init();
  }

  getSizes() {
    if ( this.userData.bBox ) {
      return {
        width: this.scale.x * ( this.userData.bBox.max.x - this.userData.bBox.min.x ),
        height: this.scale.y * ( this.userData.bBox.max.y - this.userData.bBox.min.y ),
        depth: this.scale.z * ( this.userData.bBox.max.z - this.userData.bBox.min.z )

      };
    }

    return {
      width: 1,
      depth: 1,
      height: 1
    };
  }

  /* mountTo( mount ) {
    console.log( 'mountTo', this, mount );
    if ( mount.parent.isCountertop ) {
      mount.parent.holes = [
        new Path()
          .moveTo( -( this.getSizes().width - 1 ) / 2, -( this.getSizes().depth - 1 ) / 2 )
          .lineTo( ( this.getSizes().width - 1 ) / 2, -( this.getSizes().depth - 1 ) / 2 )
          .lineTo( ( this.getSizes().width - 1 ) / 2, ( this.getSizes().depth - 1 ) / 2 )
          .lineTo( -( this.getSizes().width - 1 ) / 2, ( this.getSizes().depth - 1 ) / 2 )
          .lineTo( -( this.getSizes().width - 1 ) / 2, -( this.getSizes().depth - 1 ) / 2 )
      ];
    }

    // mount.parent.rebuildGeometry();

  } */

  /* unmountFrom( mount ) {
     console.log( 'unmountFrom', this, mount );
     if ( mount.parent.isCountertop ) {
       mount.parent.holes = [];

     }

     // mount.parent.rebuildGeometry();
   } */

  _canMoveVertically = null;

  setCanMoveVertically( v ) {
    this._canMoveVertically = v;
  }

  getCanMoveVertically() {
    return this._canMoveVertically;
  }

  init() {

    if ( this.userData.source.isGroup ) {
      this.rotation.copy( this.userData.rotation );
      getFromGroup(
        this.userData.source,
        this.userData.rotation,
        this.userData.center,
        this.userData.addBox,
        false )
        .then( ( obj ) => {

          obj.rotation.copy( new Euler( 0, 0, 0 ) );

          this.add( obj );
          this.initParameters();

          obj.traverse( ( o ) => {
            if ( o.isMesh ) {
              o.castShadow = true;
              o.receiveShadow = true;
            }
          } );

          this.vestaObject.changeWidth( this.userData.dimensions.width );
          this.vestaObject.changeDepth( this.userData.dimensions.depth );
          this.vestaObject.changeHeight( this.userData.dimensions.height );

          Storage.get( 'viewer' ).renderOnDemand.set();

        } );
    } else {
      this.rotation.copy( this.userData.rotation );
      getFromModel(
        this.userData.source,
        this.userData.rotation,
        this.userData.center,
        this.userData.addBox,
        false )
        .then( ( obj ) => {

          obj.rotation.copy( new Euler( 0, 0, 0 ) );

          this.add( obj );

          this.initParameters();

          obj.traverse( ( o ) => {
            if ( o.isMesh ) {
              o.castShadow = true;
              o.receiveShadow = true;
            }
          } );

          this.vestaObject.changeWidth( this.userData.dimensions.width );
          this.vestaObject.changeDepth( this.userData.dimensions.depth );
          this.vestaObject.changeHeight( this.userData.dimensions.height );

          if ( this.userData.matrix ) {
            new Matrix4().fromArray( this.userData.matrix ).decompose( this.position, this.quaternion, new Vector3() );
          }

          Storage.get( 'viewer' ).renderOnDemand.set();
        } );
    }
  }

  initParameters() {
    this.isDragable = true;
    const parent = this.parent;
    this.parent = null;
    this.userData.bBox = new Box3().setFromObject( this );
    this.parent = parent;

    this.vestaObject.getSizes = () => {
      return {
        width: this.scale.x * ( this.userData.bBox.max.x - this.userData.bBox.min.x ),
        height: this.scale.y * ( this.userData.bBox.max.y - this.userData.bBox.min.y ),
        depth: this.scale.z * ( this.userData.bBox.max.z - this.userData.bBox.min.z )
      };
    };


    this.vestaObject.changeWidth = ( width ) => {
      if ( width > 0 ) {
        this.scale.x = width / ( this.userData.bBox.max.x - this.userData.bBox.min.x );
      }

    };
    this.vestaObject.changeHeight = ( height ) => {
      if ( height > 0 ) {
        this.scale.y = height / ( this.userData.bBox.max.y - this.userData.bBox.min.y );
      }

    };
    this.vestaObject.changeDepth = ( depth ) => {
      if ( depth > 0 ) {
        this.scale.z = depth / ( this.userData.bBox.max.z - this.userData.bBox.min.z );
      }
    };

    this.vestaObject.getDistanceToWallEnds = ( toPoint ) => {
      if ( !this.parent || ( toPoint !== 'center' && toPoint !== 'left' && toPoint !== 'right' ) ) {
        return null;
      }

      const topLevel = getTopLevelObject( this.parent );
      if ( toPoint === 'center' ) {

        return ( {
          minusX: this.position.x,
          plusX: topLevel.width - this.position.x,
          minusY: this.position.y,
          plusY: topLevel.height - this.position.y
        } );
      }
      if ( toPoint === 'left' ) {
        return ( {
          minusX: this.position.x - this.getSizes().width / 2,
          plusX: topLevel.width - this.position.x + this.getSizes().width / 2,
          minusY: this.position.y,
          plusY: topLevel.height - this.position.y
        } );
      }
      if ( toPoint === 'right' ) {
        return ( {
          minusX: this.position.x + this.getSizes().width / 2,
          plusX: topLevel.width - this.position.x - this.getSizes().width / 2,
          minusY: this.position.y,
          plusY: topLevel.height - this.position.y
        } );
      }
    };

    this.vestaObject.setDistanceToWallEnd = ( side, toPoint, distance ) => {
      const topLevel = getTopLevelObject( this.parent );
      if ( ( ['left', 'right', 'bottom', 'top'].indexOf( side ) === -1 ) ||
        distance < 0 ||
        !this.parent ) {
        return;
      }
      if ( side === 'left' ) {
        if ( toPoint === 'center' ) {
          this.position.x = distance;
        }
        if ( toPoint === 'left' ) {
          this.position.x = distance + this.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          this.position.x = distance - this.getSizes().width / 2;
        }
      }

      if ( side === 'bottom' ) {
        if ( toPoint === 'center' ) {
          this.position.y = distance;
        }
        if ( toPoint === 'bottom' ) {
          this.position.y = distance + this.getSizes().height / 2;
        }
        if ( toPoint === 'top' ) {
          this.position.y = distance - this.getSizes().height / 2;
        }

      }

      if ( side === 'top' ) {
        if ( toPoint === 'center' ) {
          this.position.y = topLevel.height - distance;
        }
        if ( toPoint === 'bottom' ) {
          this.position.y = topLevel.height - distance + this.getSizes().height / 2;
        }
        if ( toPoint === 'top' ) {
          this.position.y = topLevel.height - distance - this.getSizes().height / 2;
        }
      }

      if ( side === 'right' ) {
        if ( toPoint === 'center' ) {
          this.position.x = topLevel.width - distance;
        }
        if ( toPoint === 'right' ) {
          this.position.x = topLevel.width - distance - this.getSizes().width / 2;
        }
        if ( toPoint === 'left' ) {
          this.position.x = topLevel.width - distance + this.getSizes().width / 2;
        }
      }
    };


    this.getType = () => {
      return 'wallAppliance';
    };

  }
}
