/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { enableBatching } from 'redux-batched-actions';
import Storage from 'scr/utilitiesStorage';
import { inches } from 'decl/general/units';
import { AllActions } from '@r/storeUtils';
import { rootReducer } from './reducers/index';

const snapSensitivity = 20 as inches;

// /** @type { redux.store } */
// const initialState = {
//   assetsLoaded: false,
//   walls: { value: '', label: '', baseMap: '' },
//   floor: { value: '', label: '', baseMap: '' }
//   /*
//    * initHappened: false,
//    * sceneInitialized: false,
//    * object3dModal: object3dModalDefault,
//    * singletonCabinetJsonModalOpen: false,
//    */

//   /*
//    * rightSideCabinetMenuOpen: true,
//    * rightSideCabinetMenuMode: 'addCabinet',
//    */

//   /*
//    * roomTemplate: {
//    *   value: 'full',
//    *   label: 'Rectangular floor plan',
//    *   baseMap: '/img/square.jpg'
//    * },
//    */

//   // roomWidthDepth: roomWidthDepthDefault,


//   // walls: { value: '', label: '', baseMap: '' },
//   // floor: { value: '', label: '', baseMap: '' },
//   // wHeight: /** @type { inches } */( 96 ),

//   /*
//    * snapSensitivity,
//    * projectTag: 'Estimating',
//    */

//   /*
//    * generalViewMode: '3d',
//    * cornerMenuVisible: true,
//    * wireframeMode: false,
//    */

//   // projectName: 'Test project',

//   /*
//    * units: 'inch',
//    * projectId: '',
//    */


//   /*
//    * emailConfirmed: false,
//    * email: '',
//    * fullName: '',
//    * password: '',
//    * confirmPassword: '',
//    * signUpError: null,
//    * logInError: null
//    */

//   /*
//    * accountUpdateError: '',
//    * accountUpdateSuccess: '',
//    * accountUpdateLoading: false,
//    */

//   /*
//    * oldPassword: '',
//    * autologinAttempted: false,
//    * // ==============================================================
//    * mainModalOpen: true,
//    * // Modal tab (for new project creation or list my projects)
//    * chosenTab: mMT.newProject,
//    * // ==============================================================
//    */


//   /*
//    * // ==== items modal visibility control =============
//    * itemsMenuVisible: false,
//    * itemsMenuPreffered: '',
//    * // ======= account modal visibility flag ===========
//    * accountModalVisible: false,
//    * // ======= settings modal visibility flag ==========
//    * settingsModalVisible: false,
//    * // =========== orthographic mode flag ==============
//    * orthographicMode: false,
//    */

//   // wThickness: 10 / 2.54,
//   // // ================ myProjects =======================
//   // myProjects: myProjectsDefault,
//   // // Dimensions for wall, floor, item in 3d
//   // '3dDimensions': dimensions,
//   // // And for walls in floorplan
//   // floorplanDimensions,
//   // // ==== controller special mode (click on cabinet) ======
//   // controllerAdvancedMode: false,
//   // // =========== modal for cabinet items ==================
//   // cabinetItemsModalVisible: false,
//   // // === ruler state binding, also present in controller ===
//   // rulerMode: false,
//   // // =========== modal for importing and exporting =========
//   // importExportModal: false,
//   // /*
//   //  * Flag for working with cabinets in advanced mode, so we can move
//   //  * myComponent functionality here
//   //  */
//   // advancedModeCabinet: false,
//   // // ============================================================
//   // kitchenCropped: false,
//   // /*
//   //  * Special field that consists of heights applied
//   //  * to upper and base cabinets. it`s main aim is to
//   //  * control constructableKitchen`s upper and base rows
//   //  * general height
//   //  */
//   // rowsHeight,
//   // // Cabinet position state (topShallow and bottomShallow heights)
//   // cabinetPosition,
//   // /*
//   //  * Flag for cabinet mode where we add sibling for chosen cabinet
//   //  * possible values: false | "left" | "right"
//   //  * "left" and "right" denote sibling insert placement
//   //  */
//   // cabinetSiblingAddMode: false,
//   // /*
//   //  * Cabinet settings (before insertion) flag
//   //  * false | constructorName (e.g. UpperContainer)
//   //  */
//   // beforeInsertCabinetModal: false,
//   // // == metrics for top-corner: left width, right width and left depth ==
//   // topCornerMetrics,
//   // /*
//   //  * Calculation mode flag, it`s similar to the walkthrough, floorplan
//   //  * and 3D modes in that it "takes precedence" i.e. it will cover other
//   //  * layers using z-index functionality
//   //  */
//   // calculationMode: false,
//   // calculationModeProps: calculationModePropsDefault,
//   // /*
//   //  * ===================================================================
//   //  * flag for L-shaped kitchen traversion possibility, disables/enables L|C|R
//   //  * buttons in advanced mode (for simple constructable - disabled)
//   //  */
//   // advancedModeKitchenTraverse: false,
//   // /*
//   //  * ===================================================================
//   //  * settings for kitchens base|upper|tall height|depth|qtyOfShelves, etc
//   //  */
//   // kitchenSettings
// };

Storage.set( 'snappingTolerance', snapSensitivity );
// const enableBatch = enableBatching as _IEnableBatching<redux._IStore, AllActions>;

const store = createStore<redux._IStore, AllActions, {}, {}>(
  // @ts-ignore
  enableBatching( rootReducer ),
  undefined,
  composeWithDevTools(
    applyMiddleware( thunk )
  )
);

export default store;
