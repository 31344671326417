import {Step} from "./BaseCmd";
import DraftCmd from "./DraftCmd";
import * as THREE from 'three';
import {createCurveEntity} from '../entity/CurveEntityEx';
import DoAddRemoveEntity from "../UndoRedo/DoAddRemoveEntity";
import { DrawSnap, Constants, CommonUtil, CurveUtil } from "../common";

class QuadraticBezierCmd extends DraftCmd {
    draftEntity = null;
    
    v0 = new THREE.Vector2();
    v1 = new THREE.Vector2();
    v2 = new THREE.Vector2();

    doActivate() {
        super.doActivate();
        this.setCursor('bezier2', true);
    }
    initCmd() {
        super.initCmd();
        this.draftEntity = null;
    }
    doEscape() {
        if (this.draftEntity) {
            this.draftEntity.dispose();
            this.updateRender();
        }
        super.doEscape();
    }
    onMouseMove(e) {
        super.onMouseMove(e);

        const pos = this.tracker.trackPos2d(true);
        if (!pos) return;

        if (this.isIdle) return;
        const curve = this.draftEntity.curve;

        switch(this.step) {
            case Step.step1: {
                curve.v1.copy(pos);
                curve.v2.copy(pos);
                break;
            }
            case Step.step2: {
                curve.v2.copy(pos);
                break;
            }
        }

        this.draftEntity.updateEntity();
        this.updateRender();
    }
    onMouseClick(e) {
        super.onMouseClick(e);
        
        const pos = this.tracker.trackPos2d(true);
        if (!pos) return;

        switch(this.step) {
            case Step.step0: {
                this.draftEntity = createCurveEntity(new THREE.QuadraticBezierCurve(pos.clone(), pos.clone(), pos.clone()));
                this.entityMgr.draftG.add(this.draftEntity);

                this.step = Step.step1;
                break;
            }
            case Step.step1: {
                this.step = Step.step2;
                break;
            }
            case Step.step2: {
                const curve = this.draftEntity.curve;
                if (this.equalsPoint(curve.v0, curve.v1) && this.equalsPoint(curve.v0, curve.v2)) {}
                else { // end with undo/redo
                    const doaction = new DoAddRemoveEntity(this.draftEntity, true, this.entityMgr.curveG);
                    this.doMgr.registerDo(doaction);
                    doaction.redo();

                    this.initCmd();
                }
                break;
            }
        }
    }
    drawSnap(ctx) {
        super.drawSnap(ctx);
        
        if (this.isIdle) return;

        const curve = this.draftEntity.curve;

        this.v0.copy(this.viewer.uds2view(curve.v0));
        this.v1.copy(this.viewer.uds2view(curve.v1));
        this.v2.copy(this.viewer.uds2view(curve.v2));

        DrawSnap.drawStickerLine(ctx, this.v0, this.v1);
        DrawSnap.drawStickerLine(ctx, this.v1, this.v2);

        DrawSnap.drawSticker(ctx, this.v0);
        DrawSnap.drawSticker(ctx, this.v1);
        DrawSnap.drawSticker(ctx, this.v2);
    }
}

export default QuadraticBezierCmd;