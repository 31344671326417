import React, { PureComponent } from 'react';
import SimpleInput from 'c/CalculationLayer/utils/SimpleInput';
import Storage from 'scr/utilitiesStorage';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import _ from 'lodash';
import style from './index.styl';

interface _IProps {
  visible: boolean;
  hideModal: any;
  dimensions: any;
  mouseCoordinates: any;
}

class FloorPlanModal extends PureComponent<_IProps> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    value: ''
  };

  componentDidUpdate( prevProps: _IProps ): void {
    const { dimensions } = this.props;
    if( !_.isEqual( dimensions, prevProps.dimensions ) && dimensions ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState( {
        value: String( dimensions.value )
      } );
    }
  }

  handleChangeInput = ( value: string ) => {
    const checkRegExp = /^\d*\.?\d{0,2}$/;
    if( value.match( checkRegExp ) ) {
      let newValue = value;
      if( value.length > 1 && value[ 0 ] === '0' && value[ 1 ] !== '.' ) {
        newValue = value.substr( 1 );
      }

      this.setState( {
        value: newValue
      } );
    }

    if( !value ) {
      this.setState( {
        value: '0'
      } );
    }
  };

  handleCloseModal = ( side: 'left'|'right'|'center'|'top'|'bottom' ) => () => {
    const { dimensions } = this.props;
    const { value } = this.state;
    const numberOfValue = Number( value );
    const wall3d = dimensions.parent;
    const angle = wall3d.get2DAngle();
    const wallWidth = dimensions.value;
    let origin = side;
    if ( ( angle > Math.PI / 4 && angle < 3 * Math.PI / 4 ) ||
      ( ( angle > 5 * Math.PI / 4 && angle < 7 * Math.PI / 4 ) ) ) {
      if ( numberOfValue < wallWidth ) {
        switch ( side ) {
          case 'left':
            origin = 'top';
            break;
          case 'center':
            origin = 'center';
            break;
          default:
            origin = 'bottom';
            break;
        }
      } else {
        switch ( side ) {
          case 'left':
            origin = 'bottom';
            break;
          case 'center':
            origin = 'center';
            break;
          default:
            origin = 'top';
            break;
        }
      }
    }
    if ( numberOfValue < wallWidth ) {
      switch ( side ) {
        case 'left':
          origin = 'right';
          break;
        case 'center':
          origin = 'center';
          break;
        default:
          origin = 'left';
          break;
      }
    } else {
      switch ( side ) {
        case 'left':
          origin = 'right';
          break;
        case 'center':
          origin = 'center';
          break;
        default:
          origin = 'left';
          break;
      }
    }
    if( dimensions ) {
      const wall3d = dimensions.parent;
      if ( wall3d && wall3d.isWall3D ) {
        const viewer = Storage.get( 'viewer' );
        if( wall3d.wall2D1Dimension === dimensions ) {
          wall3d.setWall2D1Width( Number( value ), origin );
          viewer.floorplanModeParams.instruments.editWall3Ddimension.enabled = false;
          viewer.floorplanModeParams.instruments.selectObject.enabled = true;
        }
        if( wall3d.wall2D2Dimension === dimensions ) {
          wall3d.setWall2D2Width( Number( value ), origin );
          viewer.floorplanModeParams.instruments.editWall3Ddimension.enabled = false;
          viewer.floorplanModeParams.instruments.selectObject.enabled = true;
        }
      }
    }

    const { hideModal } = this.props;
    if( hideModal ) hideModal();
  };

  handleChangeButtonLabel = ( side: string ) => {
    const { value } = this.state;
    const numberOfValue = Number( value );
    const { dimensions } = this.props;
    const wall3d = dimensions.parent;
    const angle = wall3d.get2DAngle();
    const wallWidth = dimensions.value;

    if ( ( angle > Math.PI / 4 && angle < 3 * Math.PI / 4 ) ||
      ( ( angle > 5 * Math.PI / 4 && angle < 7 * Math.PI / 4 ) ) ) {
      if ( numberOfValue < wallWidth ) {
        switch ( side ) {
          case 'left':
            return '/img/floor-arrows/top.png';
          case 'center':
            return '/img/floor-arrows/center-vert-decrease.png';
          default:
            return '/img/floor-arrows/bottom.png';
        }
      } else {
        switch ( side ) {
          case 'left':
            return '/img/floor-arrows/bottom.png';
          case 'center':
            return '/img/floor-arrows/center-vert-increase.png';
          default:
            return '/img/floor-arrows/top.png';
        }
      }
    }
    if ( numberOfValue < wallWidth ) {
      switch ( side ) {
        case 'left':
          return '/img/floor-arrows/left.png';
        case 'center':
          return '/img/floor-arrows/center-hor-decrease.png';
        default:
          return '/img/floor-arrows/right.png';
      }
    } else {
      switch ( side ) {
        case 'left':
          return '/img/floor-arrows/right.png';
        case 'center':
          return '/img/floor-arrows/center-hor-increase.png';
        default:
          return '/img/floor-arrows/left.png';
      }
    }
  };

  render() {
    const { visible, dimensions, mouseCoordinates } = this.props;
    const { value } = this.state;

    if( !visible || !dimensions || !mouseCoordinates ) {
      return null;
    }

    const { x, y } = mouseCoordinates;

    return (
      <div
        style={ { top: `${ y - 50 }px`, left: `${ x - 110 }px` } }
        className={ style.root }
      >
        <div className='buttons'>
          <button
            className='button'
            onClick={ this.handleCloseModal( 'left' ) }
          >
            <img
              className='arrow'
              alt='left'
              src={ this.handleChangeButtonLabel( 'left' ) }
            />
          </button>
          <button
            className='button'
            onClick={ this.handleCloseModal( 'center' ) }
          >
            <img
              className='arrow-center'
              alt='center'
              src={ this.handleChangeButtonLabel( 'center' ) }
            />
          </button>
          <button
            className='button'
            onClick={ this.handleCloseModal( 'right' ) }
          >
            <img
              className='arrow'
              alt='right'
              src={ this.handleChangeButtonLabel( 'right' ) }
            />
          </button>
        </div>
        <SimpleInput
          value={ value }
          onChange={ this.handleChangeInput }
          className='modal-input'
        />
      </div>
    );
  }
}

export default FloorPlanModal;
