import { MongoObjectId } from '@npmrost/utils';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import feathersClient from 'scr/getFeathers';
import Storage from 'scr/utilitiesStorage';
import { Unsubscribe } from 'redux';
import store from '../store';
/**
 * DISCLAIMER
 * This file exists separately from ./projectsFor3d.ts because it needs
 * to import store in order to subscribe to it. If we do that in
 * beforementioned file we will get cycle dependency
 *
 */

function isSceneInitialized( s: redux._IStore ) {
  return s.sceneInitialized;
}


function loadSaved(
  dispatch: ThunkDispatch<
    redux._IStore,
    {},
    | redux._IActions['setMainModalOpen']
    | redux._IActions['SET_PROJECTS_FOR_3D']
  >,
  json: string,
  projectId: MongoObjectId,
  store: redux._IStore
) {
  const viewer = Storage.get( 'viewer' );
  viewer.lastSavedProject = JSON.parse( json );
  viewer.loadSaved();

  dispatch( { type: 'SET_PROJECTS_FOR_3D', value: { ...store.projectsFor3d, projectId } } );
  dispatch( { type: 'SET_MAIN_MODAL_OPEN_STATE', value: false } );
}

export const loadProject: ( id: MongoObjectId ) => ThunkAction<
  Promise<void>, redux._IStore, {},
  | redux._IActions['setMainModalOpen']
  | redux._IActions['initHappened']
> = ( id ) => {
  return async ( dispatch ) => {
    const { json } = await feathersClient.service( 'api/projects' ).get( id );

    if( isSceneInitialized( store.getState() ) ) {
      return loadSaved( dispatch, json, id, store.getState() );
    }

    const hack = { finished: false } as { unsubscribe: Unsubscribe; finished: boolean };
    hack.unsubscribe = store.subscribe( () => {
      if( !isSceneInitialized( store.getState() ) ) return;

      hack.unsubscribe();
      if( !hack.finished ) {
        loadSaved( dispatch, json, id, store.getState() );
      }

      hack.finished = true;
    } );

    dispatch( { type: 'SET_INIT_HAPPENED', value: true } );
  };
};
