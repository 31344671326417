/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
// @ts-check
import Storage from 'scr/utilitiesStorage';
import { PRESETS } from '../../container/index';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Door from '../../core/Door';

const { WIDTH, DEPTH, HEIGHT } = PRESETS.LAYOUT;

/** @typedef { import('decl/cabinetdesigner/') } CabDesigner */
/** @typedef { import('decl/cabinetdesigner/ContainerConfigs').DefaultConfigsKeyIntersection } KeysIntersect  */
/** @typedef { import('decl/cabinetdesigner/ContainerConfigs').PanelConfig } panelConfig */
/** @typedef { import('decl/cabinetdesigner/ContainerConfigs').DoorConfig } DoorConfig */
/** @typedef { import('decl/cabinetdesigner/ContainerConfigs').ContainerConfig } ContainerConfig */

/** @type { KeysIntersect } */
const defaultMap = {
  name: '',
  width: /** @type { inches } */ ( -1 ),
  depth: /** @type { inches } */ ( -1 ),
  height: /** @type { inches } */ ( -1 ),
  type: 'Container',
  isTopLevel: false,
  children: [],
  layout: HEIGHT,
  mountTypes: ['floor'],
  constrName: 'WithCountertop',
  isTopCorner: false,
  countertopMaterialName: 'marblewhite005',
  handlePosition: {
    oX: { type: 'center', value: null },
    oY: { type: 'center', value: null },
    oZ: 'onSurface'
  },
  handleOrientation: 'HORIZONTAL',
  grainDirection: /**
    @type { import('decl/cabinetdesigner/core').radians }
  */ ( 0 ),
  panelParent: 'body',
  countertopOverhangLeft: /** @type { inches } */( 0 ),
  countertopOverhangRight: /** @type { inches } */( 0 )
};

/** @typedef {import('@npmrost/utils').inches} inches */

export function getDefault (
  /**
   * @type { keyof KeysIntersect }
   */ prop,
  /** @type { typeof defaultMap } */ defaultsHash = defaultMap
) {
  return defaultsHash[ prop ];
}

/**
 * Function to fill config for Panel, Door or Container with
 * default values where they are absent.
 * NOTE that it intended to only be used on Panel, Door or
 * Container
 */
export function getDefaultsIfAbsent (
  /**
   * @type { Partial<KeysIntersect> }
   */
  config,
  /** @type { typeof defaultMap } */ defaultsHash = defaultMap
) {
  /** @type { keyof KeysIntersect } */
  let prop;

  // eslint-disable-next-line guard-for-in
  for ( prop in defaultsHash ) {
    if ( prop === 'handlePosition' ) {
      const {
        oX = /** @type { Door['handlePosition'] } */ ( getDefault(
          'handlePosition',
          defaultsHash
        ) ).oX,
        oY = /** @type { Door['handlePosition'] } */ ( getDefault(
          'handlePosition',
          defaultsHash
        ) ).oY,
        oZ = /** @type { Door['handlePosition'] } */ ( getDefault(
          'handlePosition',
          defaultsHash
        ) ).oZ
      } = config[ prop ] || {};

      config[ prop ] = { oX, oY, oZ };
      // eslint-disable-next-line no-continue
      continue;
    }

    if ( config[ prop ] === undefined ) {
    /** @type { ReturnType<typeof getDefault> } */
      (
      /** @type { unknown } */( config[ prop ] )
      ) = getDefault( prop, defaultsHash );
    }
  }

  return (
    /** @type { KeysIntersect } */ ( config )
  );
}

export function getPanel (
  /** @type { Partial<panelConfig> } */
  config = {}
) {
  const {
    width, depth, height, name = 'Panel',
    grainDirection,
    panelParent
  } = getDefaultsIfAbsent( config );

  /** @type { panelConfig } */
  const panel = {
    width,
    depth,
    height,
    type: 'Panel',
    name,
    grainDirection,
    panelParent
  };

  return panel;
}

export function getDoor (
  /**
   * @type { Omit<Partial<DoorConfig>, 'handlePosition'>
   *  & { handlePosition?: Partial<Door['handlePosition']> } }
   *
   */
  config = {}
) {
  const {
    width,
    depth,
    height,
    handlePosition,
    handleOrientation,
    name = 'Door',
    grainDirection
  } = getDefaultsIfAbsent( config );

  /** @type { DoorConfig } */
  const door = {
    width,
    depth,
    height,
    handleOrientation,
    handlePosition,
    type: 'Door',
    name,
    grainDirection,
    panelParent: 'door'
  };

  return door;
}

export function getContainer (
  /** @type { Partial<ContainerConfig> } */
  config = {}
) {
  const {
    width,
    depth,
    height,
    children,
    mountTypes,
    layout,
    isTopLevel,
    name = 'Container',
    constrName,
    isTopCorner,
    countertopMaterialName,
    cabinetMaterialName,
    doorMaterialName,
    countertopOverhangRight,
    countertopOverhangLeft
  } = getDefaultsIfAbsent( config );

  /** @type { ContainerConfig } */
  const container = {
    name,
    width,
    depth,
    height,
    type: 'Container',
    children,
    mountTypes,
    layout,
    isTopLevel,
    constrName,
    isTopCorner,
    countertopMaterialName,
    cabinetMaterialName,
    doorMaterialName,
    countertopOverhangRight,
    countertopOverhangLeft
  };

  return container;
}

/** @typedef { import('decl/cabinetdesigner/ContainerConfigs').GeneralBodyConfig } GeneralBodyConfig */
export function getGeneralBody (
  /** @type { Partial<GeneralBodyConfig> } */
  config = {},
  /** @type { Partial<GeneralBodyConfig> } */ center
) {
  const {
    isTopLevel = false,
    mountTypes,
    leftPanel = /** @type { inches } */ ( -1 ),
    rightPanel = /** @type { inches } */ ( -1 ),
    topPanel = /** @type { inches } */ ( -1 ),
    bottomPanel = /** @type { inches } */ ( -1 ),
    backPanel = /** @type { inches } */ ( -1 )
  } = config;

  const name = 'GeneralBody';
  /** @type { Partial<GeneralBodyConfig> } */
  const body = {
    name,
    isTopLevel,
    mountTypes,
    layout: WIDTH,
    children: [
      getPanel( { width: leftPanel, name: `${ name }--LeftPanel` } ),
      {
        layout: DEPTH,
        children: [
          getPanel( { depth: backPanel, name: `${ name }--BackPanel` } ),
          {
            layout: PRESETS.LAYOUT.HEIGHT,
            children: [
              getPanel( {
                height: bottomPanel, name: `${ name }--BottomPanel`
              } ),
              center,
              getPanel( { height: topPanel, name: `${ name }--TopPanel` } )
            ]
          }
        ]
      },
      getPanel( { width: rightPanel, name: `${ name }--RightPanel` } )
    ]
  };

  return body;
}

export function getGeneralBottom (
  /** @type { Partial<import('decl/cabinetdesigner/ContainerConfigs').GeneralBottomConfig> } */
  config = {}
) {
  const {
    totalHeight = /** @type { inches } */ ( -1 ),
    frontSpaceDepth = /** @type { inches } */ ( -1 ),
    leftPanel = /** @type { inches } */ ( -1 ),
    rightPanel = /** @type { inches } */ ( -1 ),
    backPanel = /** @type { inches } */ ( -1 ),
    frontPanel = /** @type { inches } */ ( -1 )
  } = config;

  const name = 'GeneralBottom';

  return {
    name,
    layout: DEPTH,
    height: totalHeight,
    children: [
      {
        layout: WIDTH,
        children: [
          getPanel( { width: leftPanel, name: `${ name }--LeftPanel` } ),
          {
            layout: DEPTH,
            children: [
              getPanel( { depth: backPanel, name: `${ name }--BackPanel` } ),
              getContainer( { name: `${ name }--CenterShallow` } ),
              getPanel( { depth: frontPanel, name: `${ name }--FrontPanel` } )
            ]
          },
          getPanel( { width: rightPanel, name: `${ name }--RightPanel` } )
        ]
      },
      getContainer( {
        depth: frontSpaceDepth, name: `${ name }--FrontPanel`
      } )
    ]
  };
}

export function getWrappedDoor (
  /** @type { Partial<import('decl/cabinetdesigner/ContainerConfigs').GeneralWrappedDoorConfig> } */
  config = {}
) {
  const {
    doorSpaceDepth = /** @type { inches } */ ( -1 ),
    doorSpaceHeight = /** @type { inches } */ ( -1 ),
    doorSpaceWidth = /** @type { inches } */ ( -1 ),
    upperSpace = /** @type { inches } */ ( -1 ),
    bottomSpace = /** @type { inches } */ ( -1 ),
    leftSpace = /** @type { inches } */ ( -1 ),
    rightSpace = /** @type { inches } */ ( -1 ),
    doorConfig: {
      handleOrientation = 'HORIZONTAL',
      handlePosition: {
        oX = /** @type { Door['handlePosition']['oX'] } */ ( {
          type: 'center',
          value: null
        } ),
        oY = /** @type { Door['handlePosition']['oY'] } */( {
          type: 'center',
          value: null
        } ),
        oZ = 'onSurface'
      } = {}
    } = {},
    name = 'WrappedDoor'
  } = config;

  return {
    layout: HEIGHT,
    depth: doorSpaceDepth,
    width: doorSpaceWidth,
    height: doorSpaceHeight,
    name,
    children: [
      getContainer( { height: bottomSpace, name: `${ name }--BottomSpace` } ),
      {
        layout: WIDTH,
        children: [
          getContainer( { width: leftSpace, name: `${ name }--LeftSpace` } ),
          {
            layout: DEPTH,
            children: [
              getDoor( {
                handleOrientation,
                handlePosition: { oX, oY, oZ },
                name: `${ name }--DoorPanel`
              } )
            ]
          },
          getContainer( { width: rightSpace, name: `${ name }--RightSpace` } )
        ]
      },
      getContainer( { height: upperSpace, name: `${ name }--TopSpace` } )
    ]
  };
}
