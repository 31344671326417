import { inches, px } from 'decl/general/units';
import { img } from 'decl/general/image';
// eslint-disable-next-line import/no-cycle
import VestaObject3D from 'c/Cabinet/cabinetdesigner/src/structured/singletonCabinet/core/VestaObject3D';

export interface _IGenericFloorplan {
  type: keyof redux._IStore['projectFor3d']['roomWidthDepth'];
  defaultWallMaterialName: string;
  defaultFloorMaterialName: string;
  tallCabinetHeight: inches;
}
export interface _ISingleFloorplan extends _IGenericFloorplan {
  type: 'single';
  width: inches;
  height: inches;
}


export interface _IObject3D extends THREE.Object3D {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  setName( name: string );
    name: any;
  vestaObject: VestaObject3D<THREE.Object3D>;
}

export interface _ILshapeFloorplan extends _IGenericFloorplan {
  depth: inches;
  height: inches;
  width: inches;
  type: 'L-shape';
}

export interface _IUshapeFloorplan extends _IGenericFloorplan {
  width: inches;
  depth: inches;
  depth2: inches;
  height: inches;
  type: 'U-shape';
}

export interface _IFullFloorplan extends _IGenericFloorplan {
  width: inches;
  depth: inches;
  height: inches;
  type: 'full';
}

export type floorplan = _ISingleFloorplan | _ILshapeFloorplan
  | _IUshapeFloorplan | _IFullFloorplan;

export type threeEventTypes = 'objectselected' |
  'collisionDuringInsert' |
  'load';


export type selectionEvents = _ISelectObject3d |
  _IRemoveSelectionClick;

export interface _ISelectObject3d {
  type: 'objectselected';
  data: {
    object: _IObject3D[];
    mouse: {
      x: px;
      y: px;
    };
  };
}

export interface _IRemoveSelectionClick {
  type: 'objectselected';
  data: {
    object: [ null ];
  };
}


export type Object3dTypes =
  'wall' |
  'floor' |
  'VestaObject3D' |

  'panel/body' |
  'panel/door' |
  'panel/glass' |

  'handle' |
  'countertop' |
  'crownMolding' |

  'item' |
  'item/window' |

  'cabinet' |
  'cabinet/withCountetop' |
  'shape';

export type loaderMaterials = {
  [key in Object3dTypes]: {
    name: string;
    material: THREE.MeshPhysicalMaterial;
    uiName: string;
    img: img;
  }[]
}

export type crownMoldings = {
  isPresented: boolean;
  defaultMaterial: string;
  defaultShape: string;
}
