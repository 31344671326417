import { getLabelForNewUnits } from 'scr/metricsConversion';
import { UnitsInputv2Value } from 'decl/general/html';
import { inches } from 'decl/general/units';
import { _IAnyUnitsShape, _IAction, AllActions } from '@r/storeUtils';
import { Reducer } from 'redux';


export function simpleSet<
  Type extends keyof redux._IConstants,
  State
>( actionType: Type, defState: State ): Reducer<State, AllActions> {
  return function simpleSetInner( state = defState, action ) {
    switch( action.type ) {
      case actionType:
        return action.value as unknown as State;

      default:
        return state;
    }
  };
}


export const unitObj = {
  label: '1' as UnitsInputv2Value,
  value: 200 as inches
};


export function processUnitSetLabelAction(
  state: _IAnyUnitsShape,
  action: _IAction<UnitsInputv2Value>
) {
  return {
    ...state,
    label: action.value
  };
}


export function processUnitSetValueAction(
  state: _IAnyUnitsShape,
  action: _IAction<inches>
) {
  return {
    ...state,
    value: action.value
  };
}


export function processSetUnitsAction(
  state: _IAnyUnitsShape,
  { value }: redux._IActions['setUnits'] |
    redux._IActions['setCalculationModeUnits']
) {
  return {
    ...state,
    label: getLabelForNewUnits( state.value, value )
  };
}

// /**
//  *
//  * @type {
//  *  redux.getReducerForUnits<{ label: UnitsInputv2Value, value: inches }>
//  * }
//  */
// export function getReducerForUnits( {
//   labelAction,
//   valueAction,
//   setUnitsAction,
//   defaultState
// } ) {
//   return function _(
//     state = defaultState,
//     action
//   ) {
//     switch( action.type ) {
//       case labelAction:
//         return {
//           ...state,
//           label: action.value
//         };
//       case 'SET_FULL_FLOORPLAN_DEPTH_VALUE':
//         return {
//           ...state,
//           value: action.value
//         };
//       case 'SET_UNITS': {
//         return {
//           ...state,
//           label: getLabelForNewUnits( state.value, action.value )
//         };
//       }
//       default:
//         return state;
//     }
//   };
// }

/*
 * export function transformPropToConstant ( prop, process ) {
 *   prop = prop.replace( /([A-Z])/g, '_$1' );
 */

/*
 *   return process( prop );
 * }
 */

/*
 * export function loginSignupProcess ( str ) {
 *   return `SET_${ str.toUpperCase() }`;
 * }
 */

/*
 * export const dimensions = {
 *   width: '',
 *   height: '',
 *   depth: ''
 * };
 */

/*
 * export const floorplanDimensions = {
 *   length: '',
 *   height: '',
 *   thickness: ''
 * };
 */

/*
 * export const topCornerMetrics = {
 *   leftWidth: '',
 *   rightWidth: '',
 *   leftDepth: ''
 * };
 */

/*
 * export const rowsHeight = {
 *   upper: 0,
 *   base: 0
 * };
 */

/*
 * export const cabinetPosition = {
 *   top: 0,
 *   bottom: 0
 * };
 */

/*
 * export function for3dDimensions ( state = dimensions, action ) {
 *   const {
 *     width = state.width,
 *     height = state.height,
 *     depth = state.depth
 *   } = action;
 */

/*
 *   switch( action.type ) {
 *     case aFD.SET_3D_DIMENSIONS:
 *       return { width, height, depth };
 */

/*
 *     case mTC.SET_UNITS:
 *       const { value: u, prev } = action;
 */

/*
 *       return {
 *         width: reversedUnitMapping[ u ]( unitMapping[ prev ]( width ) ),
 *         height: reversedUnitMapping[ u ]( unitMapping[ prev ]( height ) ),
 *         depth: reversedUnitMapping[ u ]( unitMapping[ prev ]( depth ) )
 *       };
 */

/*
 *     default:
 *       return state;
 *   }
 * }
 */

/*
 * export function forFloorplanDimensions ( state = dimensions, action ) {
 *   const {
 *     length = state.length,
 *     height = state.height,
 *     thickness = state.thickness
 *   } = action;
 */

/*
 *   switch( action.type ) {
 *     case aFD.SET_FLOORPLAN_DIMENSIONS:
 *       return { length, height, thickness };
 */

/*
 *     case mTC.SET_UNITS:
 *       const { value: u, prev } = action;
 */

/*
 *       return {
 *         length: reversedUnitMapping[ u ]( unitMapping[ prev ]( length ) ),
 *         height: reversedUnitMapping[ u ]( unitMapping[ prev ]( height ) ),
 *         thickness: reversedUnitMapping[ u ]( unitMapping[ prev ]( thickness ) )
 *       };
 */

/*
 *     default:
 *       return state;
 *   }
 * }
 */

/*
 * export function forTopCornerMetrics ( state = topCornerMetrics, action ) {
 *   const {
 *     leftWidth = state.leftWidth,
 *     rightWidth = state.rightWidth,
 *     leftDepth = state.leftDepth
 *   } = action;
 */

/*
 *   switch( action.type ) {
 *     case aFD.SET_TOP_CORNER_METRICS:
 *       return { leftWidth, rightWidth, leftDepth };
 */

/*
 *     case mTC.SET_UNITS:
 *       const { value: u, prev } = action;
 */

/*
 *       return {
 *         leftWidth: reversedUnitMapping[ u ]( unitMapping[ prev ]( leftWidth ) ),
 *         rightWidth: reversedUnitMapping[ u ]( unitMapping[ prev ]( rightWidth ) ),
 *         leftDepth: reversedUnitMapping[ u ]( unitMapping[ prev ]( leftDepth ) )
 *       };
 */

/*
 *     default:
 *       return state;
 *   }
 * }
 */

/*
 * export function forRowsHeight ( state = rowsHeight, action ) {
 *   const {
 *     value: {
 *       upper = state.upper,
 *       base = state.base
 *     } = {}
 *   } = action;
 */

/*
 *   switch( action.type ) {
 *     case kRH.SET_KITCHEN_ROWS_HEIGHT:
 *       return { upper, base };
 */

/*
 *     default:
 *       return state;
 *   }
 * }
 */

/*
 * export function forCabinetPosition ( state = cabinetPosition, action ) {
 *   const { top = state.top, bottom = state.bottom } = action.value || {};
 */

/*
 *   switch( action.type ) {
 *     case cP.SET_SELECTED_CABINET_POSITION:
 *       return { top, bottom };
 */

/*
 *     default:
 *       return state;
 *   }
 * }
 */


/*
 * export const calculationModePropsDefault = {
 *   onlyCalculation: false,
 *   calculationModalVisibility: true,
 *   modalTab: Object.keys( calcModeModalTabs )[ 0 ],
 *   projectSettings: {
 *     // Information
 *     projectName: 'Test Project',
 *     projectTag: projectTags.estimating,
 *     customerName: '',
 *     customerAddress: '',
 *     projectNumber: '',
 *     shippingAddress: '',
 *     shippingDate: ( new Date().toISOString().match( /[^T]+/ ) ),
 */

/*
 *     // Dimensions
 *     baseCabinet: {
 *       width: '24',
 *       height: '34.75',
 *       depth: '23.5',
 *       qtyShelves: '1'
 *     },
 */

/*
 *     upperCabinet: {
 *       width: '24',
 *       height: '36',
 *       depth: '11.75',
 *       qtyShelves: '2'
 *     },
 */

/*
 *     tallCabinet: {
 *       width: '36',
 *       height: '84',
 *       depth: '26',
 *       qtyShelves: '4'
 *     },
 */

/*
 *     toeKickHeight: '4.75',
 *     topDrawerHeight: '6',
 *     toeKickType: toeKickTypes[ 0 ].value,
 *     materialThickness: '0.75',
 */

/*
 *     // Materials
 *     countertops: {},
 *     mouldings: mouldingTypes[ 0 ].value,
 *     hardware: hardwareTypes[ 0 ].value,
 */

//     /*
//      * MaterialSets
//      * materialsSets: {
//      *   sets: [{
//      *     core: materialsSetsCores[0].items[0].value,
//      *     doorsDrawers: materialsSetsDoorsDrawers[0].items[3].value,
//      *     doorGrainDirection: materialsSetsDoorsGrainDirection[0].value,
//      *     drawerGrainDirection: materialsSetsDrawerGrainDirection[0].value
//      *   }],
//      *   keys: [ 0 ],
//      *   counter: 0,
//      * },
//      */
//     materialsSets: {
//       sets: [],
//       keys: [],
//       counter: 0
//     },

/*
 *     pulls: {},
 *     hinges: {},
 *     accessories: [],
 */

/*
 *     edgebanding: ''
 *   },
 */

/*
 *   cabinets: {
 *     arr: [],
 *     content: [],
 *     keys: [],
 *     counter: 0,
 *     filteredArr: []
 *   },
 *   materialCost: 0,
 *   costOfLabour: 0,
 *   costOfShippingInstallation: 0,
 *   edgebandingTotal: [],
 *   countertopTotal: [],
 *   pullsTotal: [],
 *   hingesTotal: [],
 */

/*
 *   materialsFromDb: {}
 * };
 */

/*
 * function calcStateComposer ( state, path, value ) {
 *   const pathArr = path.split( '.' );
 *   const rtrnState = { ...state };
 *   let buf = rtrnState;
 */

/*
 *   for( let i = 0; i < pathArr.length; i++ ) {
 *     const cur = pathArr[ i ];
 */

/*
 *     if( ( i + 1 ) == pathArr.length ) {
 *       buf[ cur ] = value;
 *       break;
 *     }
 */

/*
 *     buf[ cur ] = { ...buf[ cur ] };
 *     buf = buf[ cur ];
 *   }
 */

/*
 *   return rtrnState;
 * }
 */

/*
 * export function forCalculationModeProps ( state = calculationModePropsDefault, action ) {
 *   const s = state; const v = action.value;
 */

//   const mapping = {
//     [ cMC.SET_ONLY_CALCULATION_MODE ]: 'onlyCalculation',
//     [ cMC.SET_CALCULATION_MODAL_VISIBILITY ]: 'calculationModalVisibility',
//     [ cMC.SET_CALCULATION_MODAL_TAB ]: 'modalTab',
//     // ProjectSettings
//     /* Information */
//     [ cMC.SET_CALCULATION_PROJECT_NAME ]: 'projectSettings.projectName',
//     [ cMC.SET_CALCULATION_PROJECT_TAG ]: 'projectSettings.projectTag',
//     [ cMC.SET_CALCULATION_CUSTOMER_NAME ]: 'projectSettings.customerName',
//     [ cMC.SET_CALCULATION_CUSTOMER_ADDRESS ]: 'projectSettings.customerAddress',
//     [ cMC.SET_CALCULATION_PROJECT_NUMBER ]: 'projectSettings.projectNumber',
//     [ cMC.SET_CALCULATION_SHIPPING_ADDRESS ]: 'projectSettings.shippingAddress',
//     [ cMC.SET_CALCULATION_SHIPPING_DATE ]: 'projectSettings.shippingDate',

//     /* Dimensions */
//     // BaseCabinet
//     [ cMC.SET_CALCULATION_BASE_CABINET_HEIGHT ]: 'projectSettings.baseCabinet.height',
//     [ cMC.SET_CALCULATION_BASE_CABINET_DEPTH ]: 'projectSettings.baseCabinet.depth',
//     [ cMC.SET_CALCULATION_BASE_CABINET_QTY_SHELVES ]: 'projectSettings.baseCabinet.qtyShelves',
//     // UpperCabinet
//     [ cMC.SET_CALCULATION_UPPER_CABINET_HEIGHT ]: 'projectSettings.upperCabinet.height',
//     [ cMC.SET_CALCULATION_UPPER_CABINET_DEPTH ]: 'projectSettings.upperCabinet.depth',
//     [ cMC.SET_CALCULATION_UPPER_CABINET_QTY_SHELVES ]: 'projectSettings.upperCabinet.qtyShelves',
//     // TallCabinet
//     [ cMC.SET_CALCULATION_TALL_CABINET_HEIGHT ]: 'projectSettings.tallCabinet.height',
//     [ cMC.SET_CALCULATION_TALL_CABINET_DEPTH ]: 'projectSettings.tallCabinet.depth',
//     [ cMC.SET_CALCULATION_TALL_CABINET_QTY_SHELVES ]: 'projectSettings.tallCabinet.qtyShelves',

/*
 *     [ cMC.SET_CALCULATION_TOE_KICK_HEIGHT ]: 'projectSettings.toeKickHeight',
 *     [ cMC.SET_CALCULATION_TOP_DRAWER_HEIGHT ]: 'projectSettings.topDrawerHeight',
 *     [ cMC.SET_CALCULATION_TOE_KICK_TYPE ]: 'projectSettings.toeKickType',
 *     [ cMC.SET_CALCULATION_MATERIAL_THICKNESS ]: 'projectSettings.materialThickness',
 */

//     /* Materials */

//     /* Materials sets */
//     [ cMC.SET_CALCULATION_MATERIAL_SETS_SETS ]: 'projectSettings.materialsSets.sets',
//     [ cMC.SET_CALCULATION_MATERIAL_SETS_KEYS ]: 'projectSettings.materialsSets.keys',
//     [ cMC.SET_CALCULATION_MATERIAL_SETS_COUNTER ]: 'projectSettings.materialsSets.counter',

/*
 *     [ cMC.SET_CALCULATION_COUNTERTOPS ]: 'projectSettings.countertops',
 *     [ cMC.SET_CALCULATION_MOULDINGS ]: 'projectSettings.mouldings',
 *     [ cMC.SET_CALCULATION_HARDWARE ]: 'projectSettings.hardware',
 */

/*
 *     [ cMC.SET_CALCULATION_PULLS ]: 'projectSettings.pulls',
 *     [ cMC.SET_CALCULATION_HINGES ]: 'projectSettings.hinges',
 *     [ cMC.SET_CALCULATION_ACCESSORIES ]: 'projectSettings.accessories',
 */

//     [ cMC.SET_CALCULATION_EDGEBANDING ]: 'projectSettings.edgebanding',

/*
 *     [ cMC.LOAD_MATERIALS ]: 'materialsFromDb',
 *     [ cMC.LOAD_CABINETS ]: 'cabinets.content',
 */

/*
 *     // Cabinets
 *     [ cMC.SET_CALCULATION_CABINETS_ARR ]: 'cabinets.arr',
 *     [ cMC.SET_CALCULATION_CABINETS_KEYS ]: 'cabinets.keys',
 *     [ cMC.SET_CALCULATION_FILTERED_CABINETS_ARR ]: 'cabinets.filteredArr',
 *     [ cMC.SET_CALCULATION_CABINETS_COUNTER ]: 'cabinets.counter',
 */

/*
 *     // Costs
 *     [ cMC.SET_CALCULATION_MATERIAL_COST ]: 'materialCost',
 *     [ cMC.SET_CALCULATION_COST_OF_LABOUR ]: 'costOfLabour',
 *     [ cMC.SET_CALCULATION_COST_OF_SHIPPING_INSTALLATION ]: 'costOfShippingInstallation',
 *     [ cMC.SET_CALCULATION_TOTAL_EDGEBANDING ]: 'edgebandingTotal',
 *     [ cMC.SET_CALCULATION_TOTAL_COUNTERTOP ]: 'countertopTotal',
 *     [ cMC.SET_CALCULATION_TOTAL_TOE_KICK_FILLERS ]: 'toeKickFillersTotal',
 *     [ cMC.SET_CALCULATION_TOTAL_VALANCE ]: 'valanceTotal',
 *     [ cMC.SET_CALCULATION_TOTAL_PULLS ]: 'pullsTotal',
 *     [ cMC.SET_CALCULATION_TOTAL_HINGES ]: 'hingesTotal'
 *   };
 */

/*
 *   if( action.type in mapping ) {
 *     return calcStateComposer( s, mapping[ action.type ], v );
 *   }
 */

/*
 *   return s;
 * }
 */


/*
 * export const kitchenSettings = {
 *   visible: false,
 */

/*
 *   leftCornerLength: 600 * 2.54,
 *   rightCornerLength: 800 * 2.54,
 *   topDrawerHeight: 6 * 2.54,
 *   countertopOverhangBack: 5 * 2.54
 * };
 */

/*
 * export function forKitchenSettings ( state = kitchenSettings, action ) {
 *   const s = state;
 *   const v = action.value;
 */

/*
 *   const mapping = {
 *     [ kSc.SET_KITCHEN_SETTINGS_MODAL_VISIBLE ]: 'visible'
 *   };
 */

/*
 *   if( action.type in mapping ) {
 *     return calcStateComposer( s, mapping[ action.type ], v );
 *   }
 */

/*
 *   return s;
 * }
 */

// /** @type { redux.store['object3dModal'] } */
// export const object3dModal = {
//   visible: false,
//   x: 0,
//   y: 0
// };

// export function forObject3dModal (
//   state = object3dModal,
//   /**
//    * @type { redux.actions.object3dModal.setModalVisibleActions }
//    */action
// ) {
//   switch( action.type ) {
//     case o3dMc.SET_OBJECT_3D_MODAL_VISIBLE: {
//       const d = action.data;

/*
 *       if( !d.visible ) {
 *         return { ...state, visible: false };
 *       }
 */

/*
 *       return {
 *         ...state,
 *         visible: true,
 *         x: d.x,
 *         y: d.y
 *       };
 *     }
 */

/*
 *     default:
 *       return state;
 *   }
 * }
 */
