/* eslint-disable @typescript-eslint/ban-ts-ignore */
// eslint-disable-next-line import/no-cycle
import getCabinet, {
  // eslint-disable-next-line import/named
  getCabinetArg
} from 'cSrc/structured/singletonCabinet/core/cabinetTypes';
// eslint-disable-next-line import/no-cycle
import {
  createObjectFromConfig
} from 'c/ThreeJsWrap/Viewer/core/cabinetdesigner/helpers';
// eslint-disable-next-line import/no-cycle
import Storage from 'scr/utilitiesStorage';
import { img } from '@npmrost/utils';
// eslint-disable-next-line import/no-cycle
import * as Items from 'c/ThreeJsWrap/Viewer/core/Items/Items';
// eslint-disable-next-line max-len
import * as cabinets from 'c/ThreeJsWrap/Viewer/core/cabinetdesigner/configs/cabinets';

export interface _IItemFromJson {
  name: string;
  image: img;
  id: number;
  format: 'cabinet' | 'item' | 'newCabinet';
  comp: ( getCabinetArg['constr'] )| keyof typeof Items;
  key: string;
}

export function getObjectForDrag(
  jsonRepresentation: _IItemFromJson
) {
  if( jsonRepresentation.format === 'cabinet' ) {
    const { comp: constr } = jsonRepresentation;

    const cabinet = getCabinet( { constr: constr as getCabinetArg['constr'] } );
    cabinet.refreshHighlightAndCube();
    cabinet.setPosition_SetState( true );

    return cabinet;
  }

  if( jsonRepresentation.format === 'newCabinet' ) {
    const { comp: constr } = jsonRepresentation;

    // @ts-ignore
    // eslint-disable-next-line import/namespace
    const cabinet = createObjectFromConfig( cabinets[ constr ] );

    return cabinet;
  }

  if( jsonRepresentation.format === 'item' ) {
    const assets = Storage.get( 'assets' );
    const { data, sizes = {} } = assets.item
      .filter(
        ( it: { key: string } ) => it.key === jsonRepresentation.key
      )[ 0 ];

    return new Items[
      // eslint-disable-next-line import/namespace
      jsonRepresentation.comp as keyof typeof Items
    ]( data, sizes, jsonRepresentation );
  }

  return null;
}
