import _each from 'lodash/each';
import _filter from'lodash/filter';

/**
 * Base class that helps to emit/listen events
 */
class EventEmitter {
  /**
   * @constructor
   */
  constructor() {
    let eventListeners_ = {};

    /**
     * Emits an event, calling specified previously event listener
     * @param {ViewerEvent} event Event
     */
    this.dispatch = ( event ) => {
      if ( eventListeners_[ event.type ] ) {
        _each( eventListeners_[ event.type ], ( callback ) => {
          callback( event );
        } );
      }
    };

    /**
     * Adds listener for event with specified callback
     * @param { ThreeScene.viewerEvents.threeEventTypes } eventType Event name
     * @param {function} callback Function that will be executed when event is fired
     */
    this.addEventListener = ( eventType, callback ) => {
      if ( !eventListeners_[ eventType ] ) {
        eventListeners_[ eventType ] = [];
      }

      eventListeners_[ eventType ].push( callback );
    };

    /**
     * Removes callback for target event
     * @param { ThreeScene.viewerEvents.threeEventTypes} eventType Event name
     * @param {function} callback Function that will be removed
     */
    this.removeEventListener = ( eventType, callback ) => {
      if ( !eventListeners_[ eventType ] ) {
        return;
      }

      eventListeners_[ eventType ] = _filter( eventListeners_[ eventType ], ( listener ) => {
        return listener !== callback;
      } );
    };

    /**
     * Removes all callbacks for target event
     * @param {string} eventType Event name
     */
    this.removeEventListeners = ( eventType ) => {
      eventListeners_[ eventType ] = [];
    };
  }
}

export default EventEmitter;
