import React, { PureComponent } from 'react';
import Storage from 'scr/utilitiesStorage';
import {
  GenericCabinet
} from 'cSrc/structured/singletonCabinet/core/GenericCabinet';
import { Upper } from './Upper';
import { Base } from './Base';

export class AdditionalSettings extends PureComponent {
  render(): JSX.Element | null {
    const cabinet = Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet;

    switch( cabinet.getType() ) {
      case 'upper':
        return <Upper />;
      case 'base':
        return <Base />;
      default:
        return null;
    }
  }
}
