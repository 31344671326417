import { inches } from '@npmrost/utils/lib';

export const TallRightFinishPanel:
  Partial<CabD.A.ContainerConfigs.ContainerConfig> =
{
  height: 36 as inches,
  depth: 24 as inches,
  width: 1.75 as inches,
  name: 'EmptyAssembly',
  constrName: 'FloorCabinet',
  layout: 'WIDTH',
  isTopLevel: true,
  isFillerEnd: true,
  children: [
    {
      name: 'FinishPanel',
      width: 0.75 as inches,
      type: 'Panel',
      panelParent: 'door'
    },
    {}
  ],
  countertopMaterialName: 'marblewhite005'
};
