import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { projectName } from './projectName';
import { tag } from './tag';
import { percentageLoss } from './percentageLoss';
import { customerName } from './customerName';
import { customerAddress } from './customerAddress';
import { projectNumber } from './projectNumber';
import { shippingCost } from './shippingCost';
import { shippingCostManual } from './shippingCostManual';
import { withInstallation } from './withInstallation';
import { shippingAddress } from './shippingAddress';
import { shippingDate } from './shippingDate';
import { units } from './units';
import { sameAsCustomerAddress } from './sameAsCustomerAddress';

const informationComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['settings']['information'],
    AllActions
  >;

export const information = informationComb( {
  projectName,
  tag,
  percentageLoss,
  customerName,
  customerAddress,
  projectNumber,
  shippingCost,
  shippingCostManual,
  withInstallation,
  shippingAddress,
  shippingDate,
  units,
  sameAsCustomerAddress
} );
