import { inches } from '@npmrost/utils/lib';

export const OneDoorBlindLeftUpper:
  Partial<CabD.A.ContainerConfigs.ContainerConfig> = {
    layout: 'DEPTH',
    height: 30,
    depth: 13,
    width: 36,
    name: '1 Door Blind Left Upper',
    isTopLevel: true,
    mountTypes: [
      'wall'
    ],
    constrName: 'WallCabinet',
    children: [
      {
        name: 'UpperCarcassAssembly',
        layout: 'WIDTH',
        children: [
          {
            name: 'UpperLeftSide',
            type: 'Panel',
            width: 0.5
          },
          {
            name: 'UpperCarcassAssembly--InteriorAssembly',
            children: [
              {
                name: 'UpperBottom',
                height: 0.5,
                type: 'Panel'
              },
              {
                name: 'UpperInteriorAssembly',
                layout: 'DEPTH',
                children: [
                  {
                    name: 'UpperBack',
                    type: 'Panel',
                    depth: 0.5
                  },
                  {
                    name: 'UpperInterior',
                    children: [
                      {
                        name: 'Interior',
                        children: [
                          {},
                          {
                            height: 0.5,
                            type: 'Panel',
                            name: 'Shelf'
                          },
                          {},
                          {
                            height: 0.5,
                            type: 'Panel',
                            name: 'Shelf'
                          },
                          {}
                        ]
                      }
                    ]
                  },
                  {
                    name: 'BlindPanelAssembly',
                    depth: 0.5,
                    layout: 'WIDTH',
                    children: [
                      {
                        name: 'BlindPanel',
                        type: 'Panel',
                        width: 13
                      },
                      {}
                    ]
                  }
                ]
              },
              {
                name: 'UpperTop',
                type: 'Panel',
                height: 0.5
              }
            ]
          },
          {
            name: 'UpperRightSide',
            width: 0.5,
            type: 'Panel'
          }
        ]
      },
      {
        name: 'FrontExteriorAssembly',
        layout: 'WIDTH',
        depth: 0.5,
        children: [
          {
            name: 'LeftDoorOverlay',
            width: 0.25
          },
          {
            name: 'FrontAssembly',
            children: [
              {
                name: 'BottomReveal',
                height: 0.25
              },
              {
                name: 'Doors',
                layout: 'WIDTH',
                children: [
                  {
                    width: 13
                  },
                  {
                    children: [
                      {
                        handleOrientation: 'VERTICAL',
                        handlePosition: {
                          oX: {
                            type: 'toLeft',
                            value: 3
                          },
                          oY: {
                            type: 'toBottom',
                            value: 3
                          },
                          oZ: 'onSurface'
                        },
                        type: 'Door',
                        name: 'Door--DoorPanel'
                      }
                    ]
                  }
                ]
              },
              {
                name: 'TopReveal',
                height: 0.25
              }
            ]
          },
          {
            name: 'RightDoorOverlay',
            width: 0.635
          }
        ]
      }
    ],
    countertopMaterialName: 'marblewhite005'
  };

