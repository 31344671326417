import { simpleSet } from '@r/reducers/utils';
import {
  getItems, getValueToIItem, openTheFirstest, updateFullDataFromPart
} from '@npmrost/storybook/stories/Vesta/Choice/TreeViewSelect';
import { pieCutCarcasses } from 'c/CalculationLayer/Preview/calculations';

export const cabinets = simpleSet(
  'SET_CALCULATION_MODE_CURRENT_PROJECT_CABINETS',
  {
    data: [],
    counter: 0,
    keys: []
  }
);

function addDepthAndHeightIfPresent( sourceCabinet, reduxCabinet ) {
  if( sourceCabinet.depth ) {
    // eslint-disable-next-line no-param-reassign
    reduxCabinet.depth = { label: sourceCabinet.depth, value: Number( sourceCabinet.depth ) };
  }

  if( sourceCabinet.height ) {
    // eslint-disable-next-line no-param-reassign
    reduxCabinet.height = { label: sourceCabinet.height, value: Number( sourceCabinet.height ) };
  }
}

export function addNewCabinet( cabinet ) {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        projects: {
          currentProject: {
            cabinets: {
              data,
              counter,
              keys
            },
            settings: {
              materials: {
                materialsSets,
                countertop,
                markup,
                pulls,
                hinges,
                legs,
                drawerSystems
              },
              dimensions: {
                toeKickHeight,
                toeKickType,
                topDrawerHeight,
                cabinets: {
                  [ cabinet.group.toLowerCase() ]: dimensions
                }
              }
            }
          }
        }
      }
    } = getStore();

    const {
      width,
      doorQty,
      pullQty,
      hingeQty,
      legPackagesQty,
      drawerSlideQty,
      dividers,
      divisions,
      adjustableShelfQty
    } = cabinet;

    const cabinetWidth = { label: width, value: Number( width ) };

    const newCabinet = {
      ...dimensions,
      fullDepth: {
        ...cabinetWidth
      },
      ...cabinet,
      width: cabinetWidth,
      doorQty: doorQty
        .split( ';' )
        .reduce( ( acc, value ) => Number( acc ) + Number( value ), 0 ),
      pullQty: Number( pullQty ),
      hingeQty: Number( hingeQty ),
      legPackagesQty: Number( legPackagesQty ),
      drawerSlideQty: Number( drawerSlideQty ),
      dividers: Number( dividers ),
      divisions: Number( divisions ),
      comment: '',
      countertop: {
        ...countertop,
        searchText: ''
      },
      markup,
      pulls: {
        ...pulls,
        searchText: ''
      },
      hinges: {
        ...hinges,
        searchText: ''
      },
      legs,
      drawerSystems,
      accessories: [],
      toeKickHeight,
      toeKickType,
      topDrawerHeight,
      swing: 'leftSwing',
      leftSideFillerWidth: { label: '', value: 0 },
      rightSideFillerWidth: { label: '', value: 0 },
      leftFinEnd: 'none',
      rightFinEnd: 'none',
      adjustableShelfQty: Number( adjustableShelfQty ),
      // adjustableShelfQty: dimensions.shelvesQty,
      fixedShelfQty: 0,
      valanceHeight: { label: '', value: 0 },
      countertopOverhangLeft: { label: '', value: 0 },
      countertopOverhangRight: { label: '', value: 0 },
      countertopOverhangBack: { label: '', value: 0 },
      materialsSetKey: materialsSets.keys[ 0 ]
    };

    addDepthAndHeightIfPresent( cabinet, newCabinet );
    const newCounter = counter + 1;
    const newKeys = [...keys, newCounter];
    const newData = [...data, newCabinet];

    dispatch( {
      type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_CABINETS',
      value: {
        data: newData,
        counter: newCounter,
        keys: newKeys
      }
    } );
  };
}

export function updateCabinet( key, prop, value ) {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        projects: {
          currentProject: {
            cabinets: {
              data,
              counter,
              keys
            }
          }
        }
      }
    } = getStore();

    const keyIndex = keys.indexOf( key );

    if ( keyIndex === -1 ) return;

    const newData = data.slice();

    if ( value && value.data ) {
      const newPropData = JSON.parse(
        JSON.stringify( newData[ keyIndex ][ prop ] )
      );

      const hash = value.data.reduce( ( acc, it ) => ( {
        ...acc,
        ...getValueToIItem( it )
      } ), {} );

      const options = JSON.parse(
        JSON.stringify( newPropData.options )
      );

      newPropData.options = options
        .map( ( it ) => updateFullDataFromPart( it, hash ) );

      if ( value.chosenItem ) {
        newPropData.value = value.chosenItem;
      }

      newData[ keyIndex ][ prop ] = newPropData;

      // const newValue = value.chosenItem || newData[ keyIndex ][ prop ].value;

      // newData[ keyIndex ][ prop ] = { options: value.data, value: newValue };
    } else if ( value && value.treeViewSearchText !== undefined ) {
      const { treeViewSearchText: searchText } = value;

      const newPropData = JSON.parse(
        JSON.stringify( newData[ keyIndex ][ prop ] )
      );

      newPropData.searchText = searchText;

      const options = JSON.parse(
        JSON.stringify( newPropData.options )
      );

      const filtered = getItems( options, searchText )
        .map( ( item, i ) => openTheFirstest( item, searchText !== '' && i === 0 ) );

      const hash = filtered.reduce( ( acc, it ) => ( {
        ...acc,
        ...getValueToIItem( it )
      } ), {} );

      newPropData.options = options
        .map( ( it ) => updateFullDataFromPart( it, hash ) );

      newData[ keyIndex ][ prop ] = newPropData;
    } else {
      const {
        carcasse,
        width: { value: width },
        fullDepth: { value: fullDepth }
      } = newData[ keyIndex ];

      if ( pieCutCarcasses.includes( carcasse ) ) {
        if ( prop === 'width' ) {
          const diffWidth = value.value - width;
          const newFullDepth = fullDepth + diffWidth;

          newData[ keyIndex ].fullDepth.label = String( newFullDepth );
          newData[ keyIndex ].fullDepth.value = newFullDepth;
        }
      }

      newData[ keyIndex ][ prop ] = value;
    }

    dispatch( {
      type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_CABINETS',
      value: {
        data: newData,
        counter,
        keys
      }
    } );
  };
}

export function copyCabinet( key ) {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        projects: {
          currentProject: {
            cabinets: {
              data,
              counter,
              keys
            }
          }
        }
      }
    } = getStore();

    const keyIndex = keys.indexOf( key );

    if ( keyIndex === -1 ) return;

    const newCounter = counter + 1;

    const newData = data.slice();
    const newKeys = keys.slice();

    newData.splice( keyIndex + 1, 0, { ...newData[ keyIndex ] } );
    newKeys.splice( keyIndex + 1, 0, newCounter );

    dispatch( {
      type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_CABINETS',
      value: {
        data: newData,
        counter: newCounter,
        keys: newKeys
      }
    } );
  };
}

export function removeCabinet( key ) {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        projects: {
          currentProject: {
            cabinets: {
              data,
              counter,
              keys
            }
          }
        }
      }
    } = getStore();

    const keyIndex = keys.indexOf( key );

    if ( keyIndex === -1 ) return;

    const newData = data.slice();
    const newKeys = keys.slice();

    newData.splice( keyIndex, 1 );
    newKeys.splice( keyIndex, 1 );

    dispatch( {
      type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_CABINETS',
      value: {
        data: newData,
        counter,
        keys: newKeys
      }
    } );
  };
}

export function updateCabinetsSettings( prop, value, group ) {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        projects: {
          currentProject: {
            cabinets: {
              data,
              counter,
              keys
            }
          }
        }
      }
    } = getStore();

    const newData = data.slice();

    for ( let i = 0; i < newData.length; i++ ) {
      if ( group ) {
        const { group: cabinetGroup } = newData[ i ];

        if ( group.toLowerCase() === cabinetGroup.toLowerCase() ) {
          newData[ i ][ prop ] = value;
        }
      } else {
        newData[ i ][ prop ] = value;
      }
    }

    dispatch( {
      type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_CABINETS',
      value: {
        data: newData,
        counter,
        keys
      }
    } );
  };
}
