/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  LabeledTextInput
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledTextInput';
import {
  LabeledTextArea
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledTextArea';
import {
  LabeledRadioSelect
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledRadioSelect';
import {
  LabeledReactSelectSingle
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledReactSelectSingle';
import styled from 'styled-components';
import {
  LabeledField
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledField';
import { Checkbox } from '@npmrost/storybook/stories/Vesta/Inputs/Checkbox';
import {
  LabeledDateInput
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledDateInput';
import {
  LabeledPercentInput
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledPercentInput';

const Wrapper = styled.div`
  flex-grow: 1;
  padding-right: 70px;
  & > * {
    margin-bottom: 15px;
  }
`;

class Tags extends LabeledRadioSelect {}

const StyledLabeledRadioSelect = styled( Tags )`
  flex-direction: column;
  align-items: flex-start;
  label {
    margin-bottom: 15px;
  }
  .option {
    white-space: nowrap;
  }
`;

const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  & > div {
    height: 100%;
  }
  .label {
    white-space: nowrap;
    ${ ( { theme: t } ) => `
      color: ${ t.blackTextColor };
      font-size: ${ t.secondaryFontSize };
    ` }
  }
`;

class CheckboxWrapper extends PureComponent {

  onClick = () => {
    const { checked, onChange } = this.props;

    onChange( !checked );
  }

  render() {
    const { props: { checked, label }, onClick } = this;

    return (
      <StyledCheckboxWrapper onClick={ onClick }>
        <Checkbox
          checked={ checked }
          onChange={ () => {
            onClick();
          } }
        />
        <span className='label'>{ label }</span>
      </StyledCheckboxWrapper>
    );
  }
}

class ProjectInformation extends PureComponent {

  setProjectName = ( value ) => {
    const { currentProject, dispatch } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_PROJECT_NAME`,
      value
    } );
  }

  setProjectTag = ( [{ value }] ) => {
    const { currentProject, dispatch } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_PROJECT_TAG`,
      value
    } );
  }

  setPercentageLoss = ( [arg] ) => {
    const { currentProject, dispatch } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_PROJECT_PERCENTAGE_LOSS_VALUE`,
      value: arg
    } );
  }

  setCustomerName = ( value ) => {
    const { currentProject, dispatch } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_CUSTOMER_NAME`,
      value
    } );
  }

  setCustomerAddress = ( value ) => {
    const {
      props: {
        currentProject,
        dispatch,
        settings: {
          sameAsCustomerAddress
        }
      },
      setShippingAddress
    } = this;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_CUSTOMER_ADDRESS`,
      value
    } );

    if ( sameAsCustomerAddress ) {
      setShippingAddress( value );
    }
  }

  setProjectNumber = ( value ) => {
    const { currentProject, dispatch } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_PROJECT_NUMBER`,
      value
    } );
  }

  setShippingCost = ( [arg] ) => {
    const { currentProject, dispatch } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_SHIPPING_COST_VALUE`,
      value: arg
    } );

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_SHIPPING_COST_MANUAL_LABEL`,
      value: arg.value ? String( arg.value ) : ''
    } );

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_SHIPPING_COST_MANUAL_VALUE`,
      value: Number( arg.value )
    } );
  }

  setShippingCostManual = ( value ) => {
    const {
      currentProject,
      dispatch
    } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_SHIPPING_COST_MANUAL_LABEL`,
      value
    } );

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_SHIPPING_COST_MANUAL_VALUE`,
      value: Number( value )
    } );
  }

  setWithInstallation = ( value ) => {
    const { currentProject, dispatch } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_WITH_INSTALLATION`,
      value
    } );
  }

  setShippingAddress = ( value ) => {
    const { currentProject, dispatch } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_SHIPPING_ADDRESS`,
      value
    } );
  }

  setSameAsCustomerAddress = ( value ) => {
    const {
      props: {
        currentProject,
        dispatch,
        settings: {
          customerAddress
        }
      },
      setShippingAddress
    } = this;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_SAME_AS_CUSTOMER_ADDRESS`,
      value
    } );

    if ( value ) {
      setShippingAddress( customerAddress );
    } else {
      setShippingAddress( '' );
    }
  }

  setShippingDate = ( value ) => {
    const { currentProject, dispatch } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : '';

    dispatch( {
      type: `SET_CALCULATION_MODE${ typeText }_SHIPPING_DATE`,
      value
    } );
  }

  render() {
    const {
      props: {
        className,
        texts: {
          projectName: projectNameText,
          tag: tagText,
          percentageLoss: percentageLossText,
          customerName: customerNameText,
          customerAddress: customerAddressText,
          projectNumber: projectNumberText,
          shippingCost: shippingCostText,
          shippingCostManual: shippingCostManualText,
          withInstallation: withInstallationText,
          shippingAddress: shippingAddressText,
          sameAsCustomerAddress: sameAsCustomerAddressText,
          shippingDate: shippingDateText,
          projectTags: projectTagsText
        },
        settings: {
          projectName,
          tag,
          percentageLoss: {
            options: percentageLossOptions,
            value: percentageLossValue
          },
          customerName,
          customerAddress,
          projectNumber,
          shippingCost: {
            options: shippingCostOptions,
            value: shippingCostValue
          },
          shippingCostManual: {
            label: shippingCostManualLabel
          },
          withInstallation,
          shippingAddress,
          sameAsCustomerAddress,
          shippingDate
        }
      },
      setProjectName,
      setProjectTag,
      setPercentageLoss,
      setCustomerName,
      setCustomerAddress,
      setProjectNumber,
      setShippingCost,
      setShippingCostManual,
      setWithInstallation,
      setShippingAddress,
      setSameAsCustomerAddress,
      setShippingDate
    } = this;

    const projectTags = ['estimating', 'inProgress', 'completed'];

    const tagOptions =
      projectTagsText.map( ( item, index ) => ( {
        label: item,
        value: projectTags[ index ]
      } ) );

    return (
      <Wrapper { ...{ className } }>
        <LabeledTextInput
          name={ projectNameText }
          value={ projectName }
          onChange={ setProjectName }
        />
        <StyledLabeledRadioSelect
          name={ tagText }
          options={ tagOptions }
          chosen={ [{ label: tag, value: tag }] }
          choose={ setProjectTag }
        />
        <LabeledReactSelectSingle
          name={ percentageLossText }
          options={ percentageLossOptions }
          chosen={ [percentageLossValue] }
          choose={ setPercentageLoss }
        />
        <LabeledTextInput
          name={ customerNameText }
          value={ customerName }
          onChange={ setCustomerName }
        />
        <LabeledTextArea
          name={ customerAddressText }
          value={ customerAddress }
          onChange={ setCustomerAddress }
        />
        <LabeledTextInput
          name={ projectNumberText }
          value={ projectNumber }
          onChange={ setProjectNumber }
        />
        <LabeledReactSelectSingle
          name={ shippingCostText }
          options={ shippingCostOptions }
          chosen={ [shippingCostValue] }
          choose={ setShippingCost }
        />
        {
          shippingCostOptions.length > 0 &&
          shippingCostOptions[ shippingCostOptions.length - 1 ].label === shippingCostValue.label &&
          shippingCostOptions[ shippingCostOptions.length - 1 ].value === shippingCostValue.value && (
            <LabeledPercentInput
              name={ shippingCostManualText }
              value={ shippingCostManualLabel }
              onChange={ setShippingCostManual }
            />
          )
        }
        <LabeledField
          name={ withInstallationText }
        >
          <CheckboxWrapper
            checked={ withInstallation }
            onChange={ setWithInstallation }
            label=''
          />
        </LabeledField>
        <LabeledTextArea
          name={ shippingAddressText }
          value={ shippingAddress }
          onChange={ setShippingAddress }
          disabled={ sameAsCustomerAddress }
        />
        <LabeledField
          name=''
        >
          <CheckboxWrapper
            checked={ sameAsCustomerAddress }
            onChange={ setSameAsCustomerAddress }
            label={ sameAsCustomerAddressText }
          />
        </LabeledField>
        <LabeledDateInput
          name={ shippingDateText }
          value={ shippingDate }
          onChange={ setShippingDate }
        />
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        projectInformation: texts
      }
    }
  } ) => {
    return {
      texts
    };
  }
)( ProjectInformation );
