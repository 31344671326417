import React, { PureComponent } from 'react';
import { Arrow } from '../CabinetItem';
import Details from './Details';

export default class CabinetAdditionalPart extends PureComponent {
  constructor( props ) {
    super( props );
    this.state = {
      isOpen: false
    };
  }

  toggleDetails = () => {
    this.setState( ( { isOpen } ) => ( {
      isOpen: !isOpen
    } ) );
  }

  render() {

    const {
      props: {
        index,
        name,
        data,
        cost
      },
      state: {
        isOpen
      },
      toggleDetails
    } = this;

    return (
      <>
        <div className='row' key={ name + index }>
          <div className='column columnNumber'>
            <span>{ index }</span>
          </div>
          <div className='column columnName'>
            <span>{ name }</span>
            <Arrow isOpen={ isOpen } onClick={ toggleDetails } />
          </div>
          <div className='column columnSetOfMaterials' />
          <div className='column columnUnit' />
          <div className='column columnUnit' />
          <div className='column columnUnit' />
          <div className='column columnComment' />
          <div className='column columnCost'>
            {
              cost !== undefined && (
                <span>
                  $
                  { cost }
                </span>
              )
            }
          </div>
          <div className='column columnActions' />
        </div>
        {
          isOpen && <Details { ...{ index, data } } />
        }
      </>
    );
  }
}
