export default ( {
  carcasse,
  height: { value: height },
  width: { value: width },
  depth: { value: depth },
  fullDepth: { value: fullDepth },
  toeKickHeight: { value: toeKickHeight },
  toeKickType,
  adjustableShelfQty,
  fixedShelfQty,
  leftFinEnd,
  rightFinEnd,
  doorQty,
  valanceHeight: { value: valanceHeight },
  drawers,
  coreThickness,
  otherThickness
} ) => {

  const core = [
    {
      name: 'Left side',
      length: toeKickType === 'toFloor'
        ? height
        : height - toeKickHeight,
      width: depth,
      qty: leftFinEnd === 'integrated' ? 0 : 1,
      thickness: coreThickness
    },
    {
      name: 'Right side',
      length: toeKickType === 'toFloor'
        ? height
        : height - toeKickHeight,
      width: depth,
      qty: rightFinEnd === 'integrated' ? 0 : 1,
      thickness: coreThickness
    },
    {
      name: 'Back',
      length: height - toeKickHeight,
      width: width - 2 * coreThickness,
      qty: 1,
      thickness: coreThickness
    },
    {
      name: 'Back 2',
      length: 0,
      width: 0,
      qty: 0,
      thickness: coreThickness
    },
    {
      name: 'Bottom',
      length: width - 2 * coreThickness,
      width: depth,
      qty: 1,
      thickness: coreThickness
    },
    {
      name: 'Front top stretcher',
      length: width - 2 * coreThickness,
      width: 4,
      qty: 1,
      thickness: coreThickness
    },
    {
      name: 'Back top stretcher',
      length: width - 2 * coreThickness,
      width: 4,
      qty: 1,
      thickness: coreThickness
    },
    {
      name: 'Shelf',
      length: width - 2 * coreThickness,
      width: depth - coreThickness,
      qty: adjustableShelfQty + fixedShelfQty,
      thickness: coreThickness
    }
  ];

  const doorsQuantity = Number( doorQty );

  const other = [
    {
      name: 'Doors',
      length: height - toeKickHeight + valanceHeight,
      width: width / ( doorsQuantity || 1 ),
      qty: doorsQuantity,
      thickness: otherThickness
    },
    {
      name: 'Doors 2',
      length: 0,
      width: 0,
      qty: 0,
      thickness: otherThickness
    },
    {
      name: 'Blind Panel',
      length: 0,
      width: 0,
      qty: 0,
      thickness: otherThickness
    },
    {
      name: 'Applied FE',
      length: height,
      width: depth + otherThickness,
      qty: 0,
      thickness: otherThickness
    }
  ];

  let appliedQty = 0;
  // if (leftFinEnd !== 'none') appliedQty++;
  // if (rightFinEnd !== 'none') appliedQty++;

  // eslint-disable-next-line no-warning-comments
  // TODO: change to calculation of left and right separated
  if ( leftFinEnd === 'applied' || leftFinEnd === 'integrated' ) appliedQty++;
  if ( rightFinEnd === 'applied' || rightFinEnd === 'integrated' ) appliedQty++;

  if ( appliedQty ) {
    other[ 1 ].qty = appliedQty;
  }

  for ( let i = 0; i < drawers.length; i++ ) {
    other.push( {
      name: 'Drawer',
      length: width,
      width: drawers[ i ],
      qty: 1,
      thickness: otherThickness
    } );
  }

  if ( [
    'Pie Cut Corner Base',
    'Open Pie Cut Corner Base'
  ].includes( carcasse ) ) {
    // Back 2
    core[ 3 ].length = height - toeKickHeight;
    core[ 3 ].width = fullDepth - coreThickness;
    core[ 3 ].qty = 1;

    // Bottom
    core[ 4 ].width = fullDepth - 2 * coreThickness;

    // Front top stretcher
    core[ 5 ].qty = 2;

    // Shelf
    core[ 7 ].width = fullDepth - 2 * coreThickness;

    if ( carcasse === 'Open Pie Cut Corner Base' ) {
      // Doors
      other[ 0 ].length = 0;
      other[ 0 ].width = 0;
      other[ 0 ].qty = 0;

      return { core, other };
    }

    // Doors
    other[ 0 ].length = height - toeKickHeight;
    other[ 0 ].width = width - depth;
    other[ 0 ].qty = 1;

    // Doors 2
    other[ 1 ].length = height - toeKickHeight;
    other[ 1 ].width = fullDepth - depth;
    other[ 1 ].qty = 1;

    return { core, other };
  }

  if ( carcasse === 'Blind Corner Base' ) {
    // Shelf
    core[ 7 ].width = depth - 2 * coreThickness;

    // Doors
    other[ 0 ].width = ( width - depth - 2 ) / ( doorsQuantity || 1 );

    // Blind Panel
    other[ 2 ].length = height - toeKickHeight;
    other[ 2 ].width = depth + 2;
    other[ 2 ].qty = 1;

    return { core, other };
  }

  return { core, other };
};
