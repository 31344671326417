import { combineReducers } from 'redux';
import { currentProject } from './currentProject';
import { chosenTags } from './chosenTags';
import { existingProjects } from './existingProjects';

export const projects = combineReducers( {
  currentProject,
  chosenTags,
  existingProjects
} );
