export default ( {
  leftFinEnd,
  rightFinEnd,
  coreParts,
  coreCost,
  coreName,
  coreSheetArea,
  coreSheetCost,
  otherParts,
  otherCost,
  otherName,
  otherSheetArea,
  otherSheetCost,
  totalMaterials
} ) => {
  const newTotalMaterials = { ...totalMaterials };

  const {
    length: leftLength,
    width: leftWidth,
    qty: leftQty
  } = coreParts[ 0 ];

  const {
    length: rightLength,
    width: rightWidth,
    qty: rightQty
  } = coreParts[ 1 ];

  console.log( `Core sheet area: ${ coreSheetArea }` );
  console.log( `Doors / drawers sheet area: ${ otherSheetArea }` );

  const leftArea = leftLength * leftWidth * leftQty / 144;
  const rightArea = rightLength * rightWidth * rightQty / 144;

  console.log( `Left side area: ${ leftArea }` );
  console.log( `Right side area: ${ rightArea }` );

  let coreArea = coreParts
    .slice( 2 )
    .map( ( item ) => item.length * item.width * item.qty )
    .reduce( ( acc, value ) => acc + value ) / 144;

  let otherArea = otherParts
    .map( ( item ) => item.length * item.width * item.qty )
    .reduce( ( acc, value ) => acc + value ) / 144;

  console.log( `Core area (without Left and Right sides): ${ coreArea }` );
  console.log( `Doors / Drawers area: ${ otherArea }` );

  if ( leftFinEnd === 'integrated' ) {
    otherArea += leftArea;
    console.log( `Left fin end is integrated, Doors / Drawers area should be: ${ otherArea }` );
  } else {
    coreArea += leftArea;
    console.log( `Left fin end isn't integrated, Core area should be: ${ coreArea }` );
  }

  if ( rightFinEnd === 'integrated' ) {
    otherArea += rightArea;
    console.log( `Right fin end is integrated, Doors / Drawers area should be: ${ otherArea }` );
  } else {
    coreArea += rightArea;
    console.log( `Right fin end isn't integrated, Core area should be: ${ coreArea }` );
  }

  if ( newTotalMaterials[ coreName ] ) {
    newTotalMaterials[ coreName ].area += coreArea;
  } else {
    newTotalMaterials[ coreName ] = {
      area: coreArea,
      cost: coreCost,
      sheetArea: coreSheetArea,
      sheetCost: Number( coreSheetCost )
    };
  }

  if ( newTotalMaterials[ otherName ] ) {
    newTotalMaterials[ otherName ].area += otherArea;
  } else {
    newTotalMaterials[ otherName ] = {
      area: otherArea,
      cost: otherCost,
      sheetArea: otherSheetArea,
      sheetCost: Number( otherSheetCost )
    };
  }

  const corePartsCost = Number( ( coreArea * coreCost ).toFixed( 2 ) );
  const otherPartsCost = Number( ( otherArea * otherCost ).toFixed( 2 ) );

  console.log( `Core parts total cost: ${ corePartsCost }` );
  console.log( `Doors / Drawers parts total cost: ${ otherPartsCost }` );

  // const leftCost = leftFinEnd === 'integrated' ? otherCost : coreCost;
  // const rightCost = rightFinEnd === 'integrated' ? otherCost : coreCost;

  // const leftSide = leftLength * leftWidth * leftQty / 144 * leftCost;
  // const rightSide = rightLength * rightWidth * rightQty / 144 * rightCost;

  // const corePartsCost = Number( ( leftSide + rightSide + ( coreParts
  //   .slice( 2 )
  //   .map( ( item ) => item.length * item.width * item.qty )
  //   .reduce( ( acc, value ) => acc + value ) / 144 * coreCost )
  // ).toFixed( 2 ) );

  // const otherPartsCost = Number( ( otherParts
  //   .map( ( item ) => item.length * item.width * item.qty )
  //   .reduce( ( acc, value ) => acc + value ) / 144 * otherCost )
  //   .toFixed( 2 ) );

  return {
    cost: Number( ( corePartsCost + otherPartsCost ).toFixed( 2 ) ),
    doorsDrawersCost: Number( otherPartsCost.toFixed( 2 ) ),
    totalMaterials: newTotalMaterials
  };
};
