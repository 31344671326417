// @ts-check
import React, { PureComponent } from 'react';
import html2canvas from 'html2canvas';
import feathersClient from 'scr/getFeathers';
import LoginSignupModal from 'c/AuthModals';
import { generalLogin } from '@r/actions/utils';
import { connect } from 'react-redux';
import MainModal from 'c/Modal/MainModal';
import ThreeJsWrap from 'c/ThreeJsWrap';
import CornerMenu from 'c/CornerMenu';
import CalculationLayer from 'c/CalculationLayer';
import AdminMode from 'c/AdminMode';
import ItemsModal from 'c/Modal/ItemsModal';
import CabinetRightSideMenu from 'c/CabinetRightSideMenu';
import Object3dModal from 'c/Object3dModal';
import CameraControls from 'c/CameraControls';
import FloorPlanMode from 'c/FloorPlanMode';
import { ThemeProvider } from 'styled-components';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import _ from 'lodash';

/*
 * import { ExportModal, ProfileModal } from 'c/Modal/index';
 */
/*
 * Import CabinetItemsModal from 'c/Modal/CabinetItems.jsx';
 * import SettingsModal from 'c/Modal/SettingsModal.jsx';
 * import CabinetSettings from 'c/Modal/CabinetSettings.jsx';
 * import CameraControls from 'c/CameraControls';
 * import RulerMode from 'c/RulerMode';
 *
 * import AdministratorMode from 'c/AdministratorMode';
 * import hashes, { cornerHashes } from 'cSrc/structured/cabinets/utils/cabinetHashes.jsx';
 * import { BoxedCabinet, GenericRow } from 'cSrc/structured/cabinets';
 * import { getDirtyHacks as getDh } from 'scr/getBlueprintObject.js';
 * import { Container, PRESETS } from 'cSrc/structured/container';
 * import KitchenSettingsModal from 'c/Modal/KitchenSettingsModal.jsx';
 */
import SingletonCabinetJsonModal from 'c/Modal/SingletonCabinetJson';
import Loader from 'c/ThreeJsWrap/Viewer/objects/Loader';
import Storage, { UtilitiesStorage } from 'scr/utilitiesStorage';
import { batchActions, BatchAction } from 'redux-batched-actions';
import { ModalFor2d } from 'c/Modal/ModalFor2d';
import { batchActionsWithTempl, thunkD, AllActions } from '@r/storeUtils';
import { loaderMaterials, Object3dTypes } from 'decl/ThreeScene';
import { feathers } from 'decl/feathers';
import { getTextsFromDb } from '@r/reducers/storeParts/texts';
import { ThunkDispatch } from 'redux-thunk';
import { ImportExportModal } from 'c/Modal/ImportExportModal';
import {
  loadMaterialsFromDb
} from '@r/reducers/storeParts/calculationMode/materialsFromDb';
import {
  setDefaultAdminSettings
} from '@r/reducers/storeParts/calculationMode/adminSettings';
import {
  setDefaultSettings
} from '@r/reducers/storeParts/calculationMode/settings';
import { setCabinets } from '@r/reducers/storeParts/calculationMode/cabinets';
import {
  getExistingProjects
} from '@r/reducers/storeParts/calculationMode/projects/existingProjects';
import style from './index.styl';
import { Theme } from './Theme';
import { ItemEditorModal } from 'c/ItemEditorModal';

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
window.html2canvas = html2canvas;

/*
 * class ReactAppWithoutSceneMainModalAndLoginConnected
 *   extends React.PureComponent {
 */

/*
 *   componentDidMount() {
 *     Storage.get( 'viewer' )
 *       .addEventListener( 'collisionDuringInsert',
 *         // eslint-disable-next-line no-alert
 *         () => alert(
 *           'Impossible to add object. Collision detected.'
 *         ) );
 *   }
 */

//   render () {
//     return (
//       <>
//         {/* <CornerMenu />
//         <ItemsModal className={ ` ${ style.overlay } itemsModal` } />
//         <Object3dModal />
//         <SingletonCabinetJsonModal />
//         <CabinetRightSideMenu />
//         <ExportModal className={ `${ style.overlay } importExportModal` } />
//         <ProfileModal className={ `${ style.overlay } profileModal` } /> */}
//       </>
//     );

/*
 *   }
 * }
 */


//  {
//    /* <CameraControls/>
//                                     <FloorplanClick/>
//                                     <WallFloorClick/>
//                                     <RulerMode/>
//                                     <AdvancedModeModal />
//                                     */
//  }

//  {
//    /*
//                                     <SettingsModal className={style.overlay + ' settingsModal shrinked'}/>
//
//                                     <KitchenSettingsModal className={style.overlay + '  kitchenSettings'}/>
//                                     <SingletonCabinetJsonModal className={style.overlay + '  singletonCabinetJsonModal'}/>
//                                     <Router history={createBrowserHistory()}>
//                                       <Switch>
//                                         <Route
//                                           Path='/'
//                                           Exact
//                                           Component={CalculationLayer}
//                                         />
//                                         <Route
//                                           Path='/admin'
//                                           Component={AdministratorMode}
//                                         />
//                                       </Switch>
//                                     </Router> */
//  }

//  {
//    /* <DisableAdvancedMode /> */
//  }
//  {
//    /* <CabinetItemsModal className={ style.overlay + ' itemsModal' } /> */
//  }
//  {
//    /* <CabinetSettings className={style.overlay + '  cabinetSettings'}/> */
//  }

interface IDispatchProps{
  dispatch: ThunkDispatch<redux._IStore, {}, AllActions>;
}
const ReactAppWithoutSceneMainModalAndLoginConnected = connect()(
  class ReactAppWithoutSceneMainModalAndLoginConnected
    extends React.PureComponent<IDispatchProps> {

    // eslint-disable-next-line react/state-in-constructor
    state = {
      rightSideCabinetMenuIsPresented: true
    };

    componentDidMount() {
      const { dispatch } = this.props;

      Storage.get( 'viewer' )
        .addEventListener( 'collisionDuringInsert',
          // eslint-disable-next-line no-alert
          () => alert(
            'Impossible to add object. Collision detected.'
          ) );

      dispatch( loadMaterialsFromDb() )
        .then( () => dispatch( setDefaultAdminSettings() ) )
        .then( () => dispatch( setDefaultSettings() ) )
        .catch( console.error );

      dispatch( setCabinets() );
      dispatch( getExistingProjects() );
    }

    handleClick = () => {
      const { rightSideCabinetMenuIsPresented } = this.state;
      this.setState( {
        rightSideCabinetMenuIsPresented: !rightSideCabinetMenuIsPresented
      } );
      Storage.set(
        'rightSideMenuParameters',
        { isPresented: !rightSideCabinetMenuIsPresented }
      );
      Storage.get( 'viewer' ).onResize();
    };

    render() {
      const { rightSideCabinetMenuIsPresented } = this.state;

      return (
        <>
          <CalculationLayer />
          <AdminMode />
          <ItemsModal className={ ` ${ style.overlay } itemsModal` } />
          <CameraControls
            className={
              rightSideCabinetMenuIsPresented
                ? ''
                : 'withoutRightSideMenu'
            }
          />
          <FloorPlanMode />
          <button
            className={
              `right-side-menu-hide-button
               ${ rightSideCabinetMenuIsPresented ? '' : 'isHidden' }`
            }
            onClick={ this.handleClick }
          >
            {rightSideCabinetMenuIsPresented ? '>' : '<'}
          </button>
          <CabinetRightSideMenu
            hideRightSideMenu={ !rightSideCabinetMenuIsPresented }
          />
          <SingletonCabinetJsonModal />
          <Object3dModal />
          <ModalFor2d />
          <ImportExportModal />
          {
            /*
              corner menu should appear after ModalFor2d
              as it depends on 2d canvas
            */
          }
          <CornerMenu />
          {/* <ProfileModal className={ `${ style.overlay } profileModal` } /> */}
          <ItemEditorModal className={style.overlay} />
        </>
      );
    }
  }
);

interface _IReactAppWithMainModalProps {
  loginSuccess: redux._IStore['user']['loginSuccess'];
  autologinAttempted: redux._IStore['user']['autologinAttempted'];
  feathers: feathers;
  dispatch: thunkD<
    redux._IStore,
    {},
    redux._IActions['setAutologinAttempted']
  >;
  initHappened: redux._IStore['initHappened'];
  sceneInitialized: redux._IStore['sceneInitialized'];
}
class ReactAppWithMainModal extends PureComponent<
_IReactAppWithMainModalProps
> {
  loader = document.querySelector( 'body>#loader' )

  static defaultProps: Pick<ReactAppWithMainModal['props'], 'feathers'> = {
    feathers: feathersClient as unknown as feathers
  }

  componentDidMount () {
    const {
      props: { feathers, dispatch }
    } = this;

    // =============== autologin logic ===========
    const token = localStorage.getItem( 'feathers-jwt' );
    if ( !token ) {
      return void dispatch( { type: 'SET_AUTOLOGIN_ATTEMPTED', value: true } );
    }


    dispatch( generalLogin( { strategy: 'jwt', feathers } ) )
      .catch( ( /** @type { Error } */e ) => {
        // eslint-disable-next-line no-console
        console.error( 'Autologin error', e );
      } )
      .finally(
        () => dispatch( { type: 'SET_AUTOLOGIN_ATTEMPTED', value: true } )
      );

    return void 1;
  }

  render () {
    const {
      loader,
      props: {
        autologinAttempted, loginSuccess,
        initHappened, sceneInitialized
      }
    } = this;

    if (
      ( loginSuccess || ( !loginSuccess && autologinAttempted ) ) &&
      Boolean( loader )
    ) {
      if( loader === null ) throw new Error( 'Loader is null' );

      if ( loader.parentElement !== null ) {
        loader.parentElement.removeChild( loader );
      }

      this.loader = null;
    }

    if ( !loginSuccess ) {
      if ( autologinAttempted ) {
        return <LoginSignupModal />;
      }

      // return null;
    }

    return (
      <>
        <MainModal className={ style.overlay } />
        { initHappened && <ThreeJsWrap /> }
        {
          sceneInitialized &&
            <ReactAppWithoutSceneMainModalAndLoginConnected />
        }
      </>
    );
  }
}

type Shape = {
  name: string;
  uiName: string;
  img: string;
  shape: any;
}


type Shapes = {
  crownMoldings: Shape[];
};

const ReactAppWithMainModalConnected = connect(
  ( s: redux._IStore ) => ( {
    loginSuccess: s.user.loginSuccess,
    autologinAttempted: s.user.autologinAttempted,
    initHappened: s.initHappened,
    sceneInitialized: s.sceneInitialized
  } )
)( ReactAppWithMainModal );

const b = batchActions as batchActionsWithTempl<
  redux._IActions['setUnits'] |
  redux._IActions['assetsLoaded'] |
  redux._IActions['setWalls'] |
  redux._IActions['setFloor']
>;

interface _IProps {
  dispatch: thunkD<redux._IStore, void, BatchAction >;
  assetsLoaded: redux._IStore['assetsLoaded'];
  units: redux._IStore['projectFor3d']['units'];
  snapSensitivity: redux._IStore['projectFor3d']['snapSensitivity'];
  wHeight: redux._IStore['projectFor3d']['wHeight']['value'];
  wDepth: redux._IStore['projectFor3d']['wDepth']['value'];
  roomWidthDepth: redux._IStore['projectFor3d']['roomWidthDepth'];
  defaultMaterial:
    redux._IStore['projectFor3d']['cabinetsSettings']['defaultMaterial'];
  defaultShape:
    redux._IStore['projectFor3d']['cabinetsSettings']['defaultShape'];
  cabinetMaterial: redux._IStore['projectFor3d']['cabinetMaterial'];
  doorMaterial: redux._IStore['projectFor3d']['doorMaterial'];
  isPresented: redux._IStore['projectFor3d']['cabinetsSettings']['isPresented'];
  roomTemplate: redux._IStore['projectFor3d']['roomTemplate'];
  base: redux._IStore['projectFor3d']['cabinetsSettings']['base'];
  upper: redux._IStore['projectFor3d']['cabinetsSettings']['upper'];
  tall: redux._IStore['projectFor3d']['cabinetsSettings']['tall'];
  floor: redux._IStore['projectFor3d']['floor'];
  walls: redux._IStore['projectFor3d']['walls'];
}

export default connect(
  ( s: redux._IStore ): Omit<_IProps, 'dispatch'> => ( {
    assetsLoaded: s.assetsLoaded,
    units: s.projectFor3d.units,
    walls: s.projectFor3d.walls,
    floor: s.projectFor3d.floor,
    snapSensitivity: s.projectFor3d.snapSensitivity,
    wHeight: s.projectFor3d.wHeight.value,
    wDepth: s.projectFor3d.wDepth.value,
    roomWidthDepth: s.projectFor3d.roomWidthDepth,
    roomTemplate: s.projectFor3d.roomTemplate,
    defaultMaterial: s.projectFor3d.cabinetsSettings.defaultMaterial,
    cabinetMaterial: s.projectFor3d.cabinetMaterial,
    doorMaterial: s.projectFor3d.doorMaterial,
    defaultShape: s.projectFor3d.cabinetsSettings.defaultShape,
    isPresented: s.projectFor3d.cabinetsSettings.isPresented,
    base: s.projectFor3d.cabinetsSettings.base,
    upper: s.projectFor3d.cabinetsSettings.upper,
    tall: s.projectFor3d.cabinetsSettings.tall
  } ),
  ( dispatch: ThunkDispatch<
    redux._IStore,
    void,
    BatchAction
  > ) => ( { dispatch } )
)(
  class ReactAppWrap extends PureComponent<_IProps> {
    componentDidMount() {
      const { dispatch } = this.props;
      const loader = new Loader();

      this.setInformation( this.props );
      this.setDefaultMaterials( this.props );
      this.setDimensions( this.props );
      this.setCabinetsSettings( this.props );

      loader.addEventListener( 'load',
        ( { data: { assets, materials, shapes } }: {
          data: {
            materials: loaderMaterials;
            assets: any;
            shapes: Shapes;
          };
        } ) => {
          /** @typedef { react.scripts.UtilitiesStorage.Hash } Hash */


          const namesToMaterials = {} as Exclude<
            UtilitiesStorage['hash']['namesToMaterials'], undefined
          >;
          const types = Object.keys( materials ) as unknown as Object3dTypes[];


          types.forEach( ( type ) => {
            namesToMaterials[ type ] = {};

            const typeMaterials = materials[ type ];

            typeMaterials.forEach( ( typeMaterial ) => {
              namesToMaterials[ type ][ typeMaterial.name ]
                = typeMaterial.material;
            } );
          } );


          const namesToShapes = {} as Exclude<
            UtilitiesStorage['hash']['namesToShapes'], undefined
            >;
          const shapesTypes = Object.keys( shapes ) as unknown as Object3dTypes[];

          shapesTypes.forEach( ( shapesType ) => {
            namesToShapes[ shapesType ] = {};

            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const typeShapes = shapes[ shapesType ] as Shape[];

            typeShapes.forEach( ( shapeType: Shape ) => {
              namesToShapes[ shapesType ][ shapeType.name ]
                = shapeType.shape;
            } );
          } );

          const [defaultCrownMoldingMaterial] = materials.crownMolding;
          const [defaultCrownMoldingShape] = shapes.crownMoldings;
          Storage.set( 'namesToMaterials', namesToMaterials );
          Storage.set( 'namesToShapes', namesToShapes );
          Storage.set( 'assets', assets );
          Storage.set( 'materials', materials );
          Storage.set( 'shapes', shapes );
          Storage.set( 'rightSideMenuParameters', { isPresented: true } );
          Storage.set(
            'crownMoldings',
            {
              isPresented: true,
              defaultMaterial: defaultCrownMoldingMaterial.name,
              defaultShape: defaultCrownMoldingShape.name
            }
          );

          const { floor: [floor] } = materials;
          const [wall] = materials.wall.filter( ( wallMaterial ) => (
            wallMaterial.name === 'painted_wall'
          ) );
          const [cabinet] = materials.cabinet.filter( ( wallMaterial ) => (
            wallMaterial.name === 'K07_DewDro_PerledeRosee'
          ) );

          const defaultFloor = {
            value: floor.name,
            label: floor.uiName,
            baseMap: floor.img
          };

          const defaultWall = {
            value: wall.name,
            label: wall.uiName,
            baseMap: wall.img
          };

          const defaultCabinetMaterial = {
            value: cabinet.name,
            label: cabinet.uiName,
            baseMap: cabinet.img
          };

          const defaultDoorMaterial = {
            value: cabinet.name,
            label: cabinet.uiName,
            baseMap: cabinet.img
          };

          dispatch( getTextsFromDb() );

          dispatch(
            b( [
              { type: 'SET_ASSETS_LOADED', value: true },
              { type: 'SET_WALLS', value: defaultWall },
              { type: 'SET_DOOR_MATERIAL', value: defaultDoorMaterial },
              { type: 'SET_CABINET_MATERIAL', value: defaultCabinetMaterial },
              { type: 'SET_FLOOR', value: defaultFloor },
              { type: 'SET_UNITS', value: 'inch' }
            ] )
          );
        }
      );
    }

    componentDidUpdate(): void {
      this.setInformation( this.props );
      this.setDefaultMaterials( this.props );
      this.setDimensions( this.props );
      this.setCabinetsSettings( this.props );
    }

    setInformation = ( props: _IProps ) => {
      const {
        snapSensitivity,
        units
      } = props;

      const information = {
        snapSensitivity: snapSensitivity.value,
        units
      };

      const oldInformation = Storage.get( 'information' );

      if( !_.isEqual( oldInformation, information ) ) {
        Storage.set( 'information', information );
      }
    };

    setDefaultMaterials = ( props: _IProps ) => {
      const {
        walls,
        floor,
        doorMaterial,
        cabinetMaterial
      } = props;

      const defaultMaterials = {
        walls: walls.value,
        floor: floor.value,
        doorMaterial: doorMaterial.value,
        cabinetMaterial: cabinetMaterial.value
      };

      const oldDefaultMaterials = Storage.get( 'defaultMaterials' );

      if( !_.isEqual( oldDefaultMaterials, defaultMaterials ) ) {
        Storage.set( 'defaultMaterials', defaultMaterials );
      }

    };

    setCabinetsSettings =( props: _IProps ) => {
      const {
        base,
        upper,
        tall,
        defaultMaterial,
        defaultShape,
        isPresented
      } = props;

      const cabinetsSettings = {
        defaultMaterial,
        defaultShape,
        isPresented,
        base,
        upper,
        tall
      };

      const oldCabinetsSettings = Storage.get( 'cabinetsSettings' );

      if( !_.isEqual( oldCabinetsSettings, cabinetsSettings ) ) {
        Storage.set( 'cabinetsSettings', cabinetsSettings );
      }
    };

    setDimensions = ( props: _IProps ) => {
      const {
        wDepth,
        wHeight,
        roomTemplate,
        roomWidthDepth
      } = props;

      const roomType = roomWidthDepth[ roomTemplate.value ];
      const roomWidth = roomType.width.value;
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      const roomDepth = roomType.depth ? roomType.depth.value : 0;

      let floorPlan = 0;

      switch ( roomTemplate.value ) {
        case 'full':
          floorPlan = 4;
          break;
        case 'U-shape':
          floorPlan = 3;
          break;
        case 'L-shape':
          floorPlan = 2;
          break;
        case 'single':
          floorPlan = 1;
          break;
        default:
          break;
      }

      const dimensions = {
        wallDepth: wDepth,
        wallHeight: wHeight,
        roomWidth,
        roomDepth,
        floorPlan
      };

      const oldDimensions = Storage.get( 'dimensions' );

      if( !_.isEqual( oldDimensions, dimensions ) ) {
        Storage.set( 'dimensions', dimensions );
      }
    };

    render () {
      const { assetsLoaded } = this.props;
      if( !assetsLoaded ) return null;

      return (
        <ThemeProvider theme={ Theme }>
          <div className={ style.app }>
            <ReactAppWithMainModalConnected />
          </div>
        </ThemeProvider>
      );
    }
  }
);
