import { inches } from '@npmrost/utils/lib';

export const UpperFiller: Partial<CabD.A.ContainerConfigs.ContainerConfig> = {
  layout: 'DEPTH',
  height: 30 as inches,
  depth: 13 as inches,
  width: 2 as inches,
  name: 'UpperAssembly',
  isTopLevel: true,
  mountTypes: [
    'wall'
  ],
  constrName: 'WallCabinet',
  children: [
    {
      name: 'UpperCarcassAssembly',
      children: [
        {
          name: 'UpperBottom',
          height: 0.5 as inches,
          type: 'Panel'
        }
      ]
    },
    {
      name: 'Filler',
      layout: 'DEPTH',
      depth: 0.5 as inches,
      children: [
        {},
        {
          depth: 0.5 as inches,
          type: 'Panel',
          name: 'filler',
          panelParent: 'door'
        },
        {
          name: 'FrontExteriorAssembly',
          depth: 0.5 as inches
        }
      ]
    }
  ],
  countertopMaterialName: 'marblewhite005'
};


