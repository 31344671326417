/* eslint-disable import/no-cycle */
import {
  Matrix4, Vector3, DoubleSide, PerspectiveCamera, Vector2, Shape, OrthographicCamera, Mesh, PlaneGeometry, MeshBasicMaterial, Quaternion
} from 'three';
import Storage from 'scr/utilitiesStorage';
import { getCabinetFromConfig } from 'c/Cabinet/cabinetdesigner/src/structured/singletonCabinet/core/cabinetTypes';
import items from 'c/ItemsModalContent/items.json';
import Scene from './Scene';

import Wall2D from '../floorplan/Wall2D';
import Floor2D from '../floorplan/Floor2D';
import Ceiling2D from '../floorplan/Ceiling2D';

import { isCabinet, isFloorMounted } from '../core/helpers/object3d';
import { getObjectForDrag } from '../../../CabinetRightSideMenu/getObjectForDrag';
import Floorplan from '../floorplan/Floorplan';
import Stage from '../floorplan/Stage';
import Corner from '../floorplan/Corner';
import Wall3D from '../floorplan/Wall3D';
import Room from '../floorplan/Room';
import TransformControls from '../core/TransformControls';

type inches = number & { __brand: 'inches' };
type materialName = string & { __brand: 'materialName' };
type coordinate = number & { __brand: 'coordinate' };

interface _IWithConfig {
  config: {};
}


interface _INamed {
  name: string;
}

interface _IWithMatrix {
  matrix: _IMatrix4;
}

interface _ISerializedViewer {
  floorplan: _IFloorplan;
  perspectiveCamera: _IPerspectiveCamera;
  orthoCamera: _IOrthoCamera;
  floorplanCamera: _IOrthoCamera;
  controls: _IOrbitControls;
  orthoMode: boolean;
  floorplanMode: boolean;
  floorplanModeParams: any;
}

interface _IOrthoCamera extends _INamed, _IWithMatrix {
  left: number;
  right: number;
  top: number;
  bottom: number;
  zoom: number;
  far: number;
  near: number;
}

interface _IPerspectiveCamera extends _INamed, _IWithMatrix {
  fov: number;
  zoom: number;
  far: number;
  near: number;
}
interface _IOrbitControls {
  minDistance: number;
  maxDistance: number;
  target: _IVector3;
}

interface _IFloorplan {
  tallCabinetHeight: inches;
  defaultFloorMaterialName: materialName;
  defaultWallMaterialName: materialName;
  stages: _IStage[];
}

interface _IRoom {
  height: number;
  corners: string[];
  walls2D: string[];
  floor2D: _IFloor2D;
  ceiling2D: _ICeiling2D;
}

interface _IStage {
  corners: _ICorner[];
  walls3D: _IWall3D[];
  rooms: _IRoom[];

}

interface _ICorner {
  uuid: string;
  position: _IVector2;
}

interface _IWall3D {
  uuid: string;
  fromUUID: string;
  toUUID: string;
  depth: number;
  height: number;
  wall2D_1: _IWall2D;
  wall2D_2: _IWall2D;
}

interface _ICeiling2D extends _INamed, _IShapeGeometry, _IWithMatrix {
}

interface _IShapeGeometry {
  geometry: {
    type: string;
    shape: _IVertice2D[];
  };
}

interface _IWithMountTypes {
  mountTypes: 'wall' | 'floor'[];
}

interface _IWithInitialRotation {
  initialRotation: number;
}

interface _IMaterial {
  materialName: string;
}

interface _IFloor2D extends _INamed, _IShapeGeometry, _IMaterial, _IWithMatrix {
  children: ( _IBaseCabinet | _ITallCabinet | _IBaseAppliance | _ITallAppliance )[];
}

interface _IWall2D extends _INamed, _IShapeGeometry, _IMaterial, _IWithMatrix {
  children: ( _IWallCabinet | _IWallAppliance )[];
  width: number;
  height: number;
}

interface _IVertice2D {
  x: coordinate;
  y: coordinate;
}

type _IMatrix4 = [
  number, number, number, number,
  number, number, number, number,
  number, number, number, number,
  number, number, number, number,
]

type _IVector3 = { x: number; y: number; z: number }
type _IVector2 = { x: number; y: number }


interface _IWallCabinet extends _INamed, _IWithConfig, _IWithMountTypes, _IWithInitialRotation, _IWithMatrix { }
interface _IWallAppliance extends _INamed, _IWithConfig, _IWithMountTypes, _IWithInitialRotation, _IWithMatrix { }
interface _IBaseCabinet extends _INamed, _IWithConfig, _IWithMountTypes, _IWithInitialRotation, _IWithMatrix { }
interface _IBaseAppliance extends _INamed, _IWithConfig, _IWithMatrix, _IWithMountTypes, _IWithInitialRotation { }
interface _ITallCabinet extends _INamed, _IWithConfig, _IWithMatrix, _IWithMountTypes, _IWithInitialRotation { }
interface _ITallAppliance extends _INamed, _IWithConfig, _IWithMatrix, _IWithMountTypes, _IWithInitialRotation { }


export function serializeViewer( viewer ): _ISerializedViewer {
  viewer.floorplan2.rebuildGeometry();
  const result = {
    perspectiveCamera: new Object() as _IPerspectiveCamera,
    orthoCamera: new Object() as _IOrthoCamera,
    floorplanCamera: new Object() as _IOrthoCamera,
    controls: new Object() as _IOrbitControls,
    floorplan: new Object() as _IFloorplan,
    orthoMode: new Object() as boolean,
    floorplanMode: new Object() as boolean,
    floorplanModeParams: new Object() as any
  };

  viewer.scene.traverseVisible( ( obj ) => {
    if ( obj.isFloorplan ) {
      result.floorplan = serializeFloorplan( obj );
    }
  } );

  result.perspectiveCamera = serializePerspectiveCamera( viewer.camera );
  result.orthoCamera = serializeOrthoCamera( viewer.orthoCamera );
  result.floorplanCamera = serializeOrthoCamera( viewer.floorplanCamera );
  result.controls = serializeOrbitControls( viewer.controls );
  result.orthoMode = viewer.orthoMode;
  result.floorplanMode = viewer.floorplanMode;
  result.floorplanModeParams = {
    instruments: viewer.floorplanModeParams.instruments,
    orthoMode: viewer.floorplanModeParams.orthoMode,
    snapToGrid: viewer.floorplanModeParams.snapToGrid,
    currentStage: viewer.floorplanModeParams.currentStage.uuid
  };

  return result;
}

function serializeFloorplan( floorplan ): _IFloorplan {
  const result = {
    tallCabinetHeight: floorplan.tallCabinetHeight,
    defaultFloorMaterialName: floorplan.defaultFloorMaterialName,
    defaultWallMaterialName: floorplan.defaultWallMaterialName,
    stages: new Array<_IStage>()
  };

  for ( let i = 0; i < floorplan.stages.length; i += 1 ) {
    result.stages.push( serializeStage( floorplan.stages[ i ] ) );
  }

  return result;

}

function serializeStage( stage ): _IStage {
  const result: _IStage = {
    corners: new Array<_ICorner>(),
    walls3D: new Array<_IWall3D>(),
    rooms: new Array<_IRoom>()
  };

  for ( let i = 0; i < stage.corners.length; i += 1 ) {
    result.corners.push( serializeCorner( stage.corners[ i ] ) );
  }

  for ( let i = 0; i < stage.walls3D.length; i += 1 ) {
    result.walls3D.push( serializeWall3D( stage.walls3D[ i ] ) );
  }

  for ( let i = 0; i < stage.rooms.length; i += 1 ) {
    result.rooms.push( serializeRoom( stage.rooms[ i ] ) );
  }

  return result;
}

function serializeRoom( room ): _IRoom {
  const result: _IRoom = {
    corners: new Array<string>(),
    walls2D: new Array<string>(),
    floor2D: new Object() as _IFloor2D,
    ceiling2D: new Object() as _ICeiling2D,
    height: 0
  };

  for ( let i = 0; i < room.corners.length; i += 1 ) {
    result.corners.push( room.corners[ i ].uuid );
  }

  for ( let i = 0; i < room.walls2D.length; i += 1 ) {
    result.walls2D.push( room.walls2D[ i ].uuid );
  }

  result.floor2D = serializeFloor( room.floor2D );
  result.ceiling2D = serializeCeiling( room.ceiling2D );
  result.height = room.height;

  return result;
}

function serializeCorner( corner ): _ICorner {
  return {
    uuid: corner.uuid,
    position: { x: corner.x, y: corner.y }
  };
}

function serializeWall3D( wall3D ): _IWall3D {
  const result = {
    uuid: wall3D.uuid,
    fromUUID: wall3D.from.uuid,
    toUUID: wall3D.to.uuid,
    depth: wall3D.depth,
    height: wall3D.height,
    wall2D_1: serializeWall2D( wall3D.wall2D_1 ),
    wall2D_2: serializeWall2D( wall3D.wall2D_2 )
  };

  return result;
}

function serializePerspectiveCamera( camera ): _IPerspectiveCamera {
  const result = {
    name: camera.name,
    matrix: camera.matrix.toArray(),
    fov: camera.fov,
    zoom: camera.zoom,
    near: camera.near,
    far: camera.far
  };

  return result;
}

function serializeOrthoCamera( camera ): _IOrthoCamera {
  const result = {
    name: camera.name,
    matrix: camera.matrix.toArray(),
    left: camera.left,
    right: camera.right,
    top: camera.top,
    bottom: camera.bottom,
    near: camera.near,
    far: camera.far,
    zoom: camera.zoom
  };

  return result;
}

function serializeOrbitControls( orbitControls ): _IOrbitControls {
  const result = {
    minDistance: orbitControls.minDistance,
    maxDistance: orbitControls.maxDistance,
    target: {
      x: orbitControls.target.x,
      y: orbitControls.target.y,
      z: orbitControls.target.z
    }
  };

  return result;
}


function serializeWall2D( wall ): _IWall2D {
  const result = {
    name: wall.name,
    matrix: wall.matrix.toArray(),
    geometry: { type: 'ShapeGeometry', shape: [] },
    materialName: wall.vestaObject.getMaterialName(),
    children: [],
    width: 0,
    height: 0
  };
  for ( let i = 0; i < wall.mesh.geometry.vertices.length; i += 1 ) {
    let vertice2D: _IVertice2D = {
      x: wall.mesh.geometry.vertices[ i ].x,
      y: wall.mesh.geometry.vertices[ i ].y
    };
    result.geometry.shape.push( vertice2D );
  }

  for ( let i = 0; i < wall.mountPlane.children.length; i += 1 ) {
    if ( isCabinet( wall.mountPlane.children[ i ] ) ) {
      if ( [
        'upper'
      ].indexOf( wall.mountPlane.children[ i ].getType() ) !== -1 ) {
        result.children.push( serializeCabinet( wall.mountPlane.children[ i ] ) );
        continue;
      }
      if ( [
        'upperAppliance', 'wallAppliance'
      ].indexOf( wall.mountPlane.children[ i ].getType() ) !== -1 ) {
        result.children.push( serializeAppliance( wall.mountPlane.children[ i ] ) );
      }
    }
  }

  result.width = wall.width;
  result.height = wall.height;

  return result;
}


function serializeFloor( floor2D ): _IFloor2D {
  const result = {
    name: floor2D.name,
    matrix: floor2D.matrix.toArray(),
    geometry: { type: 'ShapeGeometry', shape: [] },
    materialName: floor2D.vestaObject.getMaterialName(),
    children: []
  };
  for ( let i = 0; i < floor2D.mesh.geometry.vertices.length; i += 1 ) {
    let vertice2D: _IVertice2D = {
      x: floor2D.mesh.geometry.vertices[ i ].x,
      y: floor2D.mesh.geometry.vertices[ i ].y
    };

    result.geometry.shape.push( vertice2D );
  }

  for ( let i = 0; i < floor2D.mountPlane.children.length; i += 1 ) {
    if ( isCabinet( floor2D.mountPlane.children[ i ] ) ) {

      if ( [
        'base', 'tall'
      ].indexOf( floor2D.mountPlane.children[ i ].getType() ) !== -1 ) {
        result.children.push( serializeCabinet( floor2D.mountPlane.children[ i ] ) );
        continue;
      }
      if ( [
        'baseAppliance', 'tallAppliance'
      ].indexOf( floor2D.mountPlane.children[ i ].getType() ) !== -1 ) {
        result.children.push( serializeAppliance( floor2D.mountPlane.children[ i ] ) );
      }
    }
  }

  return result;
}

function serializeCeiling( ceiling2D ): _ICeiling2D {
  const result = {
    name: ceiling2D.name,
    matrix: ceiling2D.matrix.toArray(),
    geometry: { type: 'ShapeGeometry', shape: [] },
    children: []
  };

  for ( let i = 0; i < ceiling2D.mesh.geometry.vertices.length; i += 1 ) {
    let vertice2D: _IVertice2D = {
      x: ceiling2D.mesh.geometry.vertices[ i ].x,
      y: ceiling2D.mesh.geometry.vertices[ i ].y
    };
    result.geometry.shape.push( vertice2D );
  }

  return result;
}

function serializeCabinet( cabinet ): _IWallCabinet | _IBaseCabinet | _ITallCabinet {

  const result = {
    name: cabinet.name,
    matrix: cabinet.matrix.toArray(),
    config: cabinet.getConfig(),
    mountTypes: cabinet.mountTypes,
    initialRotation: cabinet.initialRotation,
    type: cabinet.getType()
  };
  const { width, height, depth } = cabinet.vestaObject.getSizes();
  result.config.width = width;
  result.config.height = height;
  result.config.depth = depth;

  return result;
}

function serializeAppliance( appliance ): _IWallAppliance | _IBaseAppliance | _ITallAppliance {
  const result = {
    sizes: appliance.vestaObject.getSizes(),
    name: appliance.name,
    matrix: appliance.matrix.toArray(),
    config: appliance._config,
    mountTypes: appliance.mountTypes,
    initialRotation: appliance.initialRotation,
    type: appliance.getType()
  };

  return result;
}


function deserializePerspectiveCamera( json: _IPerspectiveCamera ) {
  const viewer = Storage.get( 'viewer' );
  const result = new PerspectiveCamera(
    json.fov,
    viewer.canvas.width / viewer.canvas.height,
    json.near,
    json.far );
  result.name = json.name;
  result.zoom = json.zoom;
  new Matrix4().fromArray( json.matrix ).decompose( result.position, result.quaternion, result.scale );
  result.updateProjectionMatrix();

  return result;
}

function deserializeOrthographicCamera( json: _IOrthoCamera ) {
  const result = new OrthographicCamera(
    json.left,
    json.right,
    json.top,
    json.bottom,
    json.near,
    json.far );

  result.name = json.name;
  result.zoom = json.zoom;
  new Matrix4().fromArray( json.matrix ).decompose( result.position, result.quaternion, result.scale );
  result.updateProjectionMatrix();

  return result;
}

function deserializeOrbitControls( json: _IOrbitControls ) {
  const viewer = Storage.get( 'viewer' );
  viewer.controls.minDistance = json.minDistance;
  viewer.controls.maxDistance = json.maxDistance;
  viewer.controls.target.set( json.target.x, json.target.y, json.target.z );
  viewer.controls.update();
}

function deserializeWall2D( json: _IWall2D ) {
  let result = new Wall2D( json.name, 0, 0, new Shape( json.geometry.shape ), json.materialName );
  new Matrix4().fromArray( json.matrix ).decompose( result.position, result.quaternion, result.scale );
  for ( let i = 0; i < json.children.length; i += 1 ) {
    if ( ['upper'].indexOf( json.children[ i ].type ) !== -1 ) {
      result.mountPlane.add( deserializeCabinet( json.children[ i ] ) );
    }
    if ( ['upperAppliance', 'wallAppliance'].indexOf( json.children[ i ].type ) !== -1 ) {
      result.mountPlane.add( deserializeAppliance( json.children[ i ] ) );
    }
  }

  result.width = json.width;
  result.height = json.height;

  return result;
}

function deserializeFloor2D( json: _IFloor2D ) {
  let result = new Floor2D( json.name, new Shape( json.geometry.shape ), json.materialName );
  new Matrix4().fromArray( json.matrix ).decompose( result.position, result.quaternion, result.scale );

  for ( let i = 0; i < json.children.length; i += 1 ) {
    if ( ['base', 'tall'].indexOf( json.children[ i ].type ) !== -1 ) {
      let cabinet = deserializeCabinet( json.children[ i ] );

      result.mountPlane.add( cabinet );
    }
    if ( ['baseAppliance', 'tallAppliance'].indexOf( json.children[ i ].type ) !== -1 ) {
      result.mountPlane.add( deserializeAppliance( json.children[ i ] ) );
    }
  }

  return result;
}

function deserializeCeiling2D( json: _ICeiling2D ) {
  let result = new Ceiling2D( json.name, new Shape( json.geometry.shape ) );
  new Matrix4().fromArray( json.matrix ).decompose( result.position, result.quaternion, result.scale );

  return result;
}

function deserializeCabinet( json: _IWallCabinet | _IBaseCabinet | _ITallCabinet ) {
  let result = getCabinetFromConfig( { config: json.config } );
  result.vestaObject.changeDepth( json.config.depth );
  // result.vestaObject.changeWidth(json.config.width);
  result.vestaObject.changeHeight( json.config.height );

  result.reloadConfig = function reloadConfig(
    /** @type { CabD.A.ContainerConfigs.ContainerConfig } */ conf
  ) {
    if ( this.parent === null ) return this;
    if ( conf === null ) return this;

    const newCab = getCabinetFromConfig( {
      config: conf,
      constr: this.getConstr(),
      cabinetSettings: { texture: '' },
      itemName: this.getMetadataName()
    } );

    newCab.position.copy( this.position );
    newCab.reloadConfig = this.reloadConfig;

    this.parent.add( newCab );
    this.parent.remove( this );

    return newCab;
  };

  new Matrix4().fromArray( json.matrix ).decompose( result.position, result.quaternion, result.scale );
  result.mountTypes = json.mountTypes;
  result.initialRotation = json.initialRotation;

  return result;
}

function deserializeAppliance( json: _IWallAppliance | _IBaseAppliance | _ITallAppliance ) {

  const id = json.config.id;
  const result = getObjectForDrag(
    /** @type { import('./getObjectForDrag')._IItemFromJson } */(
    /** @type { unknown } */ ( items.hash[ id ] )
    )
  );
  result._config = { id };
  // new Matrix4().fromArray(json.matrix).decompose(result.position, result.quaternion, new Vector3());
  result.userData.matrix = json.matrix;
  result.name = json.name;
  result.mountTypes = json.mountTypes;
  result.initialRotation = json.initialRotation;
  result.userData.dimensions.width = json.sizes.width;
  result.userData.dimensions.height = json.sizes.height;
  result.userData.dimensions.depth = json.sizes.depth;

  return result;
}

function deserializeCorner( json: _ICorner, stage ) {
  const corner = new Corner( new Vector2( json.position.x, json.position.y ) );
  stage.corners.push( corner );
  stage.add( corner );
  corner.uuid = json.uuid;

  return corner;
}

function deserializeWall3D( json: _IWall3D, stage ) {
  const from = stage.getObjectByProperty( 'uuid', json.fromUUID );
  const to = stage.getObjectByProperty( 'uuid', json.toUUID );
  const wall3D = new Wall3D( from, to, json.depth, json.height );
  wall3D.uuid = json.uuid;

  wall3D.wall2D_1 = deserializeWall2D( json.wall2D_1 );
  wall3D.wall2D_1.wall3D = wall3D;

  wall3D.wall2D_2 = deserializeWall2D( json.wall2D_2 );
  wall3D.wall2D_2.wall3D = wall3D;


  return wall3D;
}

function deserializeRoom( json: _IRoom, stage ) {
  let corners = [];
  for ( let i = 0; i < json.corners.length; i += 1 ) {
    corners.push( stage.getObjectByProperty( 'uuid', json.corners[ i ] ) );
  }

  let walls2D = [];
  for ( let i = 0; i < json.walls2D.length; i += 1 ) {
    walls2D.push( stage.getObjectByProperty( 'uuid', json.walls2D[ i ] ) );
  }

  const room = new Room( stage, corners );
  room.walls2D = walls2D;
  room.floor2D = deserializeFloor2D( json.floor2D );
  room.add( room.floor2D );
  room.ceiling2D = deserializeCeiling2D( json.ceiling2D );
  room.height = json.height;

  return room;
}

function deserializeStage( json: _IStage ) {
  const stage = new Stage();

  for ( let i = 0; i < json.corners.length; i += 1 ) {
    stage.addCorner( deserializeCorner( json.corners[ i ], stage ) );
  }


  for ( let i = 0; i < json.walls3D.length; i += 1 ) {
    stage.addWall3D( deserializeWall3D( json.walls3D[ i ], stage ) );
  }

  for ( let i = 0; i < json.rooms.length; i += 1 ) {
    let room = deserializeRoom( json.rooms[ i ], stage );
    stage.rooms.push( room );
    stage.add( room );
  }


  return stage;
}


function deserializeFloorplan( json: _IFloorplan ) {
  const floorplan = new Floorplan();
  floorplan.tallCabinetHeight = json.tallCabinetHeight;
  floorplan.defaultFloorMaterialName = json.defaultFloorMaterialName;
  floorplan.defaultWallMaterialName = json.defaultWallMaterialName;
  floorplan.stages = [];

  for ( let i = 0; i < json.stages.length; i += 1 ) {
    floorplan.addStage( deserializeStage( json.stages[ i ] ) );
  }

  return floorplan;
}


export function deserializeViewer( json: _ISerializedViewer ) {
  if( !json ) {
    return;
  }

  const viewer = Storage.get( 'viewer' );

  viewer.scene.dragDropManager.removeListeners();

  viewer.floorplanModeParams = json.floorplanModeParams;
  viewer.floorplanModeParams.floorplanGrid = new Mesh(
    new PlaneGeometry( 100000, 100000 ).rotateX( Math.PI / 2 ).translate( 0, -10, 0 ),
    new MeshBasicMaterial( { side: DoubleSide, color: 0xffffff, map: viewer.getFloorplanGridTexture() } ) );


  const scene = new Scene( viewer, json );
  const perspectiveCamera = deserializePerspectiveCamera( json.perspectiveCamera );
  const orthoCamera = deserializeOrthographicCamera( json.orthoCamera );
  const floorplanCamera = deserializeOrthographicCamera( json.floorplanCamera );
  const floorplan = deserializeFloorplan( json.floorplan );
  scene.add( floorplan );

  viewer.floorplanModeParams.currentStage = floorplan.stages[ 0 ];

  viewer.floorplan2 = floorplan;
  scene.floorplan2 = floorplan;

  const floorplanBox = viewer.floorplan2.getBoundingBox();
  const center = new Vector3();
  floorplanBox.getCenter( center );
  const min = floorplanBox.min;
  const max = floorplanBox.max;
  scene.defaultLocation = {
    wall: {
      object: viewer.floorplan2.stages[ 0 ].walls3D[ 0 ].wall2D_1.mountPlane,
      lastObject: null,
      position: new Vector3( 0, ( max.y - min.y ) * 0.75, 0 )
    },
    floor: {
      object: viewer.floorplan2.stages[ 0 ].rooms[ 0 ].floor2D.mountPlane,
      lastObject: null,
      position: new Vector3( 0, 0, 0 )
    }
  };

  scene.add( perspectiveCamera );
  scene.add( orthoCamera );
  scene.add( floorplanCamera );

  viewer.camera = perspectiveCamera;
  viewer.orthoCamera = orthoCamera;
  viewer.floorplanCamera = floorplanCamera;

  viewer.outlinePass.renderScene = scene;

  viewer.orthoMode = json.orthoMode;
  viewer.floorplanMode = json.orthoMode;

  // scene.add( viewer.transformControls );
  viewer.scene = scene;

  viewer.transformControls = new TransformControls( viewer.camera, viewer.canvas );
  viewer.transformControls.enabled = true;
  viewer.transformControls.addEventListener(
    'change',
    () => viewer.renderOnDemand.set(),
    false
  );
  viewer.transformControls.addEventListener( 'dragging-changed', ( event ) => {
    viewer.controls.enabled = !event.value;
    // this.scene.dragDropManager.enabled = !event.value;
  } );
  viewer.transformControls.detach();
  viewer.dispatch( {
    type: 'objectselected',
    data: {
      object: [null],
      mouse: {
        x: 0,
        y: 0
      }
    }
  } );
  viewer.scene.add( viewer.transformControls );

  viewer.renderPass.scene = scene;


  if ( !viewer.orthoMode && !viewer.floorplanMode ) {

    viewer.renderPass.camera = perspectiveCamera;
    viewer.outlinePass.renderCamera = perspectiveCamera;
    viewer.controls.object = perspectiveCamera;
    viewer.scene.dragDropManager.camera = perspectiveCamera;

    const floorplanBox = viewer.floorplan2.getBoundingBox();
    const min = floorplanBox.min;
    const max = floorplanBox.max;
    const maxDim = Math.max( max.x - min.x, max.z - min.z );

    viewer.firstPersonControls.enabled = false;
    viewer.camera.far = 5 * maxDim;
    viewer.controls.enabled = true;
    viewer.transformControls.enabled = true;
    // viewer.transformControls.camera = perspectiveCamera;
    viewer.setFloorplanVisibility( false );
    viewer.controls.enableRotate = true;
    viewer.scene.setDimensionsVisibility( false );

  }
  if ( viewer.orthoMode ) {
    // viewer.useOrbitControls();
    viewer.useOrthographicCamera();
    viewer.renderPass.camera = orthoCamera;
    viewer.outlinePass.renderCamera = orthoCamera;
    viewer.controls.object = orthoCamera;
    viewer.scene.dragDropManager.camera = orthoCamera;

  }
  if ( viewer.floorplanMode ) {

    viewer.renderPass.camera = floorplanCamera;
    viewer.outlinePass.renderCamera = floorplanCamera;
    viewer.controls.object = floorplanCamera;
    viewer.scene.dragDropManager.camera = floorplanCamera;

    viewer.useFloorplanMode();

  }

  deserializeOrbitControls( json.controls );
  viewer.renderOnDemand.set();


  return true;
}
