import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { information } from './information';
import { dimensions } from './dimensions';
import { materials } from './materials';

const settingsComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['projects']['currentProject']['settings'],
    AllActions
  >;

const settingsStandart = settingsComb( {
  information,
  dimensions,
  materials
} );

const defaultState = {
  information: {
    projectName: '',
    tag: 'estimating',
    customerName: '',
    percentageLoss: {
      options: [],
      value: { label: '', value: 0 }
    },
    projectNumber: '',
    customerAddress: '',
    shippingCost: {
      options: [],
      value: { label: '', value: 0 }
    },
    withInstallation: true,
    shippingAddress: '',
    sameAsCustomerAddress: false,
    shippingDate: new Date().toISOString().split( 'T' )[ 0 ],
    units: 'inch'
  },
  dimensions: {
    cabinets: {
      base: {
        height: {
          label: '37',
          value: 37
        },
        depth: {
          label: '23.5',
          value: 23.5
        },
        shelvesQty: 1
      },
      upper: {
        height: {
          label: '36',
          value: 36
        },
        depth: {
          label: '11.75',
          value: 11.75
        },
        shelvesQty: 2
      },
      tall: {
        height: {
          label: '84',
          value: 84
        },
        depth: {
          label: '26',
          value: 26
        },
        shelvesQty: 4
      }
    },
    toeKickHeight: {
      label: '',
      value: 0
    },
    topDrawerHeight: {
      label: '',
      value: 0
    },
    toeKickType: 'toFloor'
  },
  materials: {
    countertop: {
      options: [],
      value: {
        label: '',
        value: ''
      }
    },
    pulls: {
      options: [],
      value: {
        label: '',
        value: ''
      }
    },
    hinges: {
      options: [],
      value: {
        label: '',
        value: ''
      }
    },
    materialsSets: {
      data: [],
      counter: 0,
      keys: [],
      coreOptions: [],
      doorsDrawersOptions: [],
      edgebandingOptions: []
    },
    markup: {
      label: '',
      value: 0
    }
  }
};

export function settings( state = defaultState, action ) {
  switch ( action.type ) {
    case 'SET_CALCULATION_MODE_CURRENT_PROJECT_SETTINGS':
      return action.value;
    default:
      return settingsStandart( state, action );
  }
}
