import {
  DoubleSide, Group, Mesh, SphereGeometry, MeshBasicMaterial
} from 'three';

const defaultMaterialUnselected = new MeshBasicMaterial( {
  color: 0xffff00,
  transparent: true,
  opacity: 0.2,
  side: DoubleSide,
  depthTest: false
} );
const defaultMaterialSelected = new MeshBasicMaterial( {
  color: 0xffff00,
  transparent: true,
  opacity: 1.0,
  side: DoubleSide,
  depthTest: false
} );
const defaultMaterialInvisible = new MeshBasicMaterial( {
  visible: false
} );

/**
 * Represents points on which other objects (matching slot text description)  can be mounted
 */
export default class MountPoint extends Group {
  /**
        *
        * @param {Array} mountSlotTypes Text descriptions array
        * @param {Array} points Points array (each point is THREE.Vector3)
        * @param {THREE.Material} material Material if it is needed to overwrite default one
        */
  constructor( mountSlotTypes, material = defaultMaterialInvisible ) {
    const geometry = new SphereGeometry( 2, 10, 10 );

    super();
    this.point = new Mesh( geometry, material );
    this.point.isMount = true;
    this.point.isMountPoint = true;
    this.add( this.point );

    if ( mountSlotTypes instanceof Array ) {
      this.point.mountSlotTypes = mountSlotTypes;
    } else {
      this.point.mountSlotTypes = [].push( mountSlotTypes );
    }

  }

  setMaterialToUnselected() {
    this.point.material = defaultMaterialUnselected;
  }

  setMaterialToSelected() {
    this.point.material = defaultMaterialSelected;
  }

  setMaterialToInvisible() {
    this.point.material = defaultMaterialInvisible;
  }

  add( object ) {
    super.add( object );
    this.dispatchEvent( { type: 'childAdded', data: object } );

    return this;
  }

  remove( object ) {
    super.remove( object );
    this.dispatchEvent( { type: 'childRemoved', data: object } );

    return this;
  }
}
