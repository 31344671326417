import { combineReducers } from 'redux';
import { value } from './value';
import { options } from './options';

export const legs = combineReducers( {
  value,
  options
} );

export function setDefaultLegs() {
  return ( dispatch, getStore ) => {
    const { calculationMode: { materialsFromDb } } = getStore();

    const options = materialsFromDb.Leg;

    dispatch( {
      type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_LEGS_OPTIONS',
      value: options
    } );

    dispatch( {
      type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_LEGS_VALUE',
      value: options[ 0 ]
    } );
  };
}
