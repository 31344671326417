/**
 * @author mrdoob / http://mrdoob.com/
 * @author Mugen87 / https://github.com/Mugen87
 */

import {
  Euler,
  Vector3,
  Vector4
} from 'three';
import EventEmitter from './loaders/EventEmitter';

export default class FirstPersonControls extends EventEmitter {
  constructor( camera, domElement ) {
    super();
    this.domElement = domElement || document.body;
    this.isLocked = true;
    this.camera = camera;
    this.enabled = false;

    this.changeEvent = { type: 'change' };

    this.euler = new Euler( 0, 0, 0, 'YXZ' );

    this.moveForward = false;
    this.moveBackward = false;
    this.moveLeft = false;
    this.moveRight = false;
    this.canJump = false;
    this.velocity = new Vector3();
    this.direction = new Vector3();

    this.connect();
  }


  /*
   *
   * internals
   *
   */

  onMouseMove( event ) {
    if ( this.enabled ) {
      // if ( scope.isLocked === false ) return;
      let movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
      let movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;

      this.euler.setFromQuaternion( this.camera.quaternion );

      this.euler.y -= movementX * 0.005;
      this.euler.x -= movementY * 0.005;

      this.euler.x = Math.max( -Math.PI / 2, Math.min( Math.PI / 2, this.euler.x ) );

      this.camera.quaternion.setFromEuler( this.euler );
      this.dispatch( this.changeEvent );
    }
  }

  /*
   *function onPointerlockChange() {
   *
   * if ( document.pointerLockElement === scope.domElement ) {
   *
   * scope.dispatchEvent( lockEvent );
   *
   * scope.isLocked = true;
   *
   * } else {
   *
   * scope.dispatchEvent( unlockEvent );
   *
   * scope.isLocked = true;
   *
   * }
   *
   *}
   */

  /*
   *function onPointerlockError() {
   *
   * console.error( 'THREE.PointerLockControls: Unable to use Pointer Lock API' );
   *
   *}
   */

  onKeyDown( event ) {
    if ( this.enabled ) {
      switch ( event.keyCode ) {
        case 38: // up
        case 87: // w
          this.moveForward = true;
          break;
        case 37: // left
        case 65: // a
          this.moveLeft = true;
          break;
        case 40: // down
        case 83: // s
          this.moveBackward = true;
          break;
        case 39: // right
        case 68: // d
          this.moveRight = true;
          break;
        case 32: // space
          if ( this.canJump === true ) this.velocity.y += 350;
          this.canJump = false;
          break;
      }
    } else {
      this.moveForward = false;
      this.moveForward = false;
      this.moveLeft = false;
      this.moveRight = false;
    }
  }

  onKeyUp( event ) {
    if ( this.enabled ) {
      switch ( event.keyCode ) {
        case 38: // up
        case 87: // w
          this.moveForward = false;
          break;
        case 37: // left
        case 65: // a
          this.moveLeft = false;
          break;
        case 40: // down
        case 83: // s
          this.moveBackward = false;
          break;
        case 39: // right
        case 68: // d
          this.moveRight = false;
          break;
      }
    } else {
      this.moveForward = false;
      this.moveForward = false;
      this.moveLeft = false;
      this.moveRight = false;
    }
  }


  connect() {

    document.addEventListener( 'mousemove', ( event ) => {
      this.onMouseMove( event );
    }, false );
    /*
     * document.addEventListener( 'pointerlockchange', onPointerlockChange, false );
     * document.addEventListener( 'pointerlockerror', onPointerlockError, false );
     */
    document.addEventListener( 'keydown', ( event ) => {
      this.onKeyDown( event );
    }, false );
    document.addEventListener( 'keyup', ( event ) => {
      this.onKeyUp( event );
    }, false );

  }

  disconnect() {

    document.removeEventListener( 'mousemove', () => this.onMouseMove, false );
    document.removeEventListener( 'keydown', () => this.onKeyDown, false );
    document.removeEventListener( 'keyup', () => this.onKeyUp, false );
  }

  dispose() {

    this.disconnect();

  }

  getObject() { // retaining this method for backward compatibility

    return this.camera;

  }

  /*
   *getDirection () {
   *
   * let dir = new Vector3(0, 0, -1);
   *
   * return function (v) {
   *
   * return v.copy(dir).applyQuaternion(camera.quaternion);
   *
   * };
   *
   *};
   */

  lock() {

    this.domElement.requestPointerLock();

  }

  unlock() {

    document.exitPointerLock();

  }


  update( delta ) {

    if ( this.moveForward || this.moveBackward || this.moveRight || this.moveLeft ) {


      this.velocity.y -= 9.8 * 100.0 * delta; // 100.0 = mass
      this.direction.z = Number( this.moveForward ) - Number( this.moveBackward );
      this.direction.x = Number( this.moveRight ) - Number( this.moveLeft );
      this.direction.normalize(); // this ensures consistent movements in all directions
      this.velocity.z = ( this.moveForward || this.moveBackward ) ? this.direction.z * 1600.0 * delta : 0;
      this.velocity.x = ( this.moveLeft || this.moveRight ) ? -this.direction.x * 1600.0 * delta : 0;
      /*
       * if (onObject === true) {
       * velocity.y = Math.max(0, velocity.y);
       * canJump = true;
       * }
       */
      let pos = new Vector4( -this.velocity.x * delta, 0, -this.velocity.z * delta, 0 );
      this.getObject().updateMatrixWorld( true );
      pos.applyMatrix4( this.getObject().matrixWorld );
      let y = this.getObject().position.y;
      this.getObject().position.add( new Vector3( pos.x, pos.y, pos.z ) );
      this.getObject().position.y = y;
      /*
       * scope.getObject().position.y += -velocity.y * delta;
       * scope.getObject().position.y = Math.max( scope.getObject().position.y, y );
       */

      this.dispatch( this.changeEvent );
    }
  }
}


