import { simpleSet } from '@r/reducers/utils';
import {
  UnitsInputValue
} from '@npmrost/storybook/stories/Vesta/Inputs/UnitsInput';

const setCalculationModeToeKickHeightLabel = simpleSet as
  redux.reducers.calculationMode.projects.currentProject.settings.dimensions.toeKickHeight.label;

export const label = setCalculationModeToeKickHeightLabel(
  'SET_CALCULATION_MODE_CURRENT_PROJECT_TOE_KICK_HEIGHT_LABEL',
  '' as UnitsInputValue
);
