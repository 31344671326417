import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  PercentInput
} from '@npmrost/storybook/stories/Vesta/Inputs/PercentInput';
import {
  TextInput
} from '@npmrost/storybook/stories/Vesta/Inputs/TextInput';

import {
  updateItem
} from '@r/reducers/storeParts/calculationMode/adminSettings/projectSettings/shippingCost';

const Wrapper = styled.div`
  margin-top: 20px;
  margin-left: 30px;
  /* height: 100%; */
`;

const Content = styled.div`
  width: 350px;
  padding: 20px;
  ${ ( { theme: t } ) => `
    background-color: ${ t.arraySetsBackground };
    border-radius: ${ t.borderRadius };
  ` }
`;

const Title = styled.span`
  ${ ( { theme: t } ) => `
    color: ${ t.darkGreyColor };
  ` }
`;

const RowsWrapper = styled.div`
  margin-top: 15px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const StyledTextInput = styled( TextInput )`
  width: 65%;
`;

const StyledPercentInput = styled( PercentInput )`
  width: 30%;
  margin-left: 10px;
`;

class ShippingCost extends PureComponent {

  update = ( index, propName ) => ( value ) => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.dispatch( updateItem( index, propName, value ) );
  }

  render() {
    const {
      props: {
        shippingCostTitle,
        shippingCostNamePlaceholder,
        shippingCostValuePlaceholder,
        shippingCost
      },
      update
    } = this;

    return (
      <Wrapper>
        <Content>
          <Title>{ shippingCostTitle }</Title>
          {
            shippingCost.length > 0 && (
              <RowsWrapper>
                {
                  shippingCost.slice( 0, shippingCost.length - 1 )
                    .map( ( { name, value: { label } }, index ) => (
                      <Row key={ index }>
                        <StyledTextInput
                          placeholder={ shippingCostNamePlaceholder }
                          value={ name }
                          onChange={ update( index, 'name' ) }
                        />
                        <StyledPercentInput
                          placeholder={ shippingCostValuePlaceholder }
                          value={ label }
                          onChange={ update( index, 'value' ) }
                        />
                      </Row>
                    ) )
                }
                <Row>
                  <StyledTextInput
                    placeholder={ shippingCostNamePlaceholder }
                    value={ shippingCost[ shippingCost.length - 1 ].name }
                    onChange={ update( shippingCost.length - 1, 'name' ) }
                  />
                </Row>
              </RowsWrapper>
            )
          }
        </Content>
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        adminMode: {
          shippingCostTitle,
          shippingCostNamePlaceholder,
          shippingCostValuePlaceholder
        }
      }
    },
    calculationMode: {
      adminSettings: {
        projectSettings: {
          shippingCost
        }
      }
    }
  } ) => {
    return {
      shippingCostTitle,
      shippingCostNamePlaceholder,
      shippingCostValuePlaceholder,
      shippingCost
    };
  }
)( ShippingCost );
