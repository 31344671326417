export default ( {
  height: { value: height },
  width: { value: width },
  depth: { value: depth },
  toeKickHeight: { value: toeKickHeight },
  toeKickType,
  adjustableShelfQty,
  fixedShelfQty,
  leftFinEnd,
  rightFinEnd,
  doorQty,
  valanceHeight: { value: valanceHeight },
  drawers,
  coreThickness,
  otherThickness
} ) => {

  const core = [
    {
      name: 'Left side',
      length: toeKickType === 'toFloor'
        ? height
        : height - toeKickHeight,
      width: depth,
      qty: leftFinEnd === 'integrated' ? 0 : 1,
      thickness: coreThickness
    },
    {
      name: 'Right side',
      length: toeKickType === 'toFloor'
        ? height
        : height - toeKickHeight,
      width: depth,
      qty: rightFinEnd === 'integrated' ? 0 : 1,
      thickness: coreThickness
    },
    {
      name: 'Back',
      length: height - toeKickHeight,
      width: width - 2 * coreThickness,
      qty: 1,
      thickness: coreThickness
    },
    {
      name: 'Bottom',
      length: width - 2 * coreThickness,
      width: depth,
      qty: 1,
      thickness: coreThickness
    },
    {
      name: 'Full top stretcher',
      length: width - 2 * coreThickness,
      width: depth,
      qty: 1,
      thickness: coreThickness
    },
    {
      name: 'Shelf',
      length: width - 2 * coreThickness,
      width: depth - coreThickness,
      qty: adjustableShelfQty + fixedShelfQty,
      thickness: coreThickness
    }
  ];

  const doorsQuantity = Number( doorQty );

  const other = [
    {
      name: 'Doors',
      length: height - toeKickHeight + valanceHeight,
      width: width / ( doorsQuantity || 1 ),
      qty: doorsQuantity,
      thickness: otherThickness
    },
    {
      name: 'Applied FE',
      length: height,
      width: depth + otherThickness,
      qty: 0,
      thickness: otherThickness
    }
  ];

  let appliedQty = 0;
  // if (leftFinEnd !== 'none') appliedQty++;
  // if (rightFinEnd !== 'none') appliedQty++;

  // eslint-disable-next-line no-warning-comments
  // TODO: change to calculation of left and right separated
  if ( leftFinEnd === 'applied' || leftFinEnd === 'integrated' ) appliedQty++;
  if ( rightFinEnd === 'applied' || rightFinEnd === 'integrated' ) appliedQty++;

  if ( appliedQty ) {
    other[ 1 ].qty = appliedQty;
  }

  for ( let i = 0; i < drawers.length; i++ ) {
    other.push( {
      name: 'Drawer',
      length: width,
      width: drawers[ i ],
      qty: 1,
      thickness: otherThickness
    } );
  }

  return { core, other };
};
