import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import CabinetItem from './CabinetItem';
import CabinetAdditionalPart from './CabinetAdditionalPart';

const Wrapper = styled.div`
  height: 90%;
  width: calc(100% + 10px);
`;

const Row = styled.div`
  ${ ( { theme: t } ) => `
    border-bottom: 1px solid ${ t.darkGreyColor };
  ` }
`;

const StyledPerfectScrollbar = styled( PerfectScrollbar )`
  padding-right: 10px;
`;

class Cabinets extends PureComponent {
  render() {

    const {
      cabinets: { data, keys },
      edgebandingText,
      countertopText,
      hingesText,
      pullsText,
      legsText,
      drawerSystemsText,
      toeKickFillersText,
      valanceText,
      lessThanOneSheetText,
      cabinetsCostData,
      totalEdgebanding,
      totalCountertop,
      totalHinges,
      totalPulls,
      totalLegs,
      totalToeKickFillers,
      totalValance,
      totalDrawerSystems,
      totalLessThanOneSheet,
      edgebandingCost,
      countertopCost,
      hingesCost,
      pullsCost,
      legsCost,
      toeKickFillersCost,
      valanceCost,
      drawerSystemsCost,
      fullDepthIsShown
    } = this.props;

    return (
      <Wrapper>
        <StyledPerfectScrollbar>
          {
            data.map( ( item, index ) => (
              <Row>
                <CabinetItem { ...{
                  ...item,
                  index,
                  dataKey: keys[ index ],
                  cost: cabinetsCostData[ keys[ index ] ],
                  fullDepthIsShown
                } }
                />
              </Row>
            ) )
          }
          {
            data.length > 0 && (
              <>
                <Row>
                  <CabinetAdditionalPart
                    index={ data.length + 1 }
                    name={ edgebandingText }
                    data={ totalEdgebanding }
                    cost={ edgebandingCost }
                  />
                </Row>
                <Row>
                  <CabinetAdditionalPart
                    index={ data.length + 2 }
                    name={ countertopText }
                    data={ totalCountertop }
                    cost={ countertopCost }
                  />
                </Row>
                <Row>
                  <CabinetAdditionalPart
                    index={ data.length + 3 }
                    name={ hingesText }
                    data={ totalHinges }
                    cost={ hingesCost }
                  />
                </Row>
                <Row>
                  <CabinetAdditionalPart
                    index={ data.length + 4 }
                    name={ pullsText }
                    data={ totalPulls }
                    cost={ pullsCost }
                  />
                </Row>
                <Row>
                  <CabinetAdditionalPart
                    index={ data.length + 5 }
                    name={ legsText }
                    data={ totalLegs }
                    cost={ legsCost }
                  />
                </Row>
                <Row>
                  <CabinetAdditionalPart
                    index={ data.length + 6 }
                    name={ toeKickFillersText }
                    data={ totalToeKickFillers }
                    cost={ toeKickFillersCost }
                  />
                </Row>
                <Row>
                  <CabinetAdditionalPart
                    index={ data.length + 7 }
                    name={ valanceText }
                    data={ totalValance }
                    cost={ valanceCost }
                  />
                </Row>
                <Row>
                  <CabinetAdditionalPart
                    index={ data.length + 8 }
                    name={ drawerSystemsText }
                    data={ totalDrawerSystems }
                    cost={ drawerSystemsCost }
                  />
                </Row>
                {
                  totalLessThanOneSheet.length > 0 && (
                    <Row>
                      <CabinetAdditionalPart
                        index={ data.length + 9 }
                        name={ lessThanOneSheetText }
                        data={ totalLessThanOneSheet }
                      />
                    </Row>
                  )
                }
              </>
            )
          }
        </StyledPerfectScrollbar>
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    calculationMode: {
      projects: {
        currentProject: {
          cabinets
        }
      }
    },
    texts: {
      calculationMode: {
        materials: {
          edgebanding: edgebandingText,
          countertop: countertopText,
          hinges: hingesText,
          pulls: pullsText,
          legs: legsText,
          drawerSystems: drawerSystemsText
        },
        estimationPreview: {
          table: {
            additionalParts: {
              toeKickFillers: toeKickFillersText,
              valance: valanceText,
              lessThanOneSheet: lessThanOneSheetText
            }
          }
        }
      }
    }
  } ) => {
    return {
      cabinets,
      edgebandingText,
      countertopText,
      hingesText,
      pullsText,
      legsText,
      drawerSystemsText,
      toeKickFillersText,
      valanceText,
      lessThanOneSheetText
    };
  }
)( Cabinets );
