// @ts-check
import {
  Mesh, Group, ShapeGeometry, Box3, Math as TMath
} from 'three';
import { createGeometry } from 'c/ThreeJsWrap/Viewer/core/helpers/geometry';
import Storage from 'scr/utilitiesStorage';
import MountPlane from '../MountPlane';

export default class Floor2D extends Group {
  constructor( name, shape, materialName ) {
    super();
    this.isFloor = true;
    this.isTopLevel = true;
    const mesh = new Mesh(
      createGeometry( new ShapeGeometry( shape ) ),
      Storage.get( 'namesToMaterials' ).floor[ materialName ]
    );
    mesh.isCollidable = true;
    mesh.isSnapable = true;
    mesh.name = name;
    this._room = null;
    // Mesh.castShadow = true;
    mesh.receiveShadow = true;

    this.add( mesh );
    this.mesh = mesh;
    this.name = name;
    this.mountPlane = new MountPlane( 'floor', shape );
    this.add( this.mountPlane );

    this.vestaObject.setMaterialByName = ( mat ) => {
      const viewer = Storage.get( 'viewer' );
      const newMaterial = Storage.get( 'namesToMaterials' ).floor[ mat.floor ];
      if ( !newMaterial ) {
        return;
      }

      for ( let i = 0; i < Storage.get( 'materials' ).floor.length; i += 1 ) {
        Storage.get( 'materials' ).floor[ i ].material.dispose();
        if ( Storage.get( 'materials' ).floor[ i ].material.map ) {
          Storage.get( 'materials' ).floor[ i ].material.map.dispose();
        }
        if ( Storage.get( 'materials' ).floor[ i ].material.roughnessMap ) {
          Storage.get( 'materials' ).floor[ i ].material.roughnessMap.dispose();
        }
        if ( Storage.get( 'materials' ).floor[ i ].material.normalMap ) {
          Storage.get( 'materials' ).floor[ i ].material.normalMap.dispose();
        }

      }

      this.mesh.material = newMaterial;
      viewer.renderOnDemand.set();
      viewer.cubeCamera.flag = true;

      this.vestaObject.setMaterialByNameDirty( mat.floor );
    };

    this.vestaObject.setMaterialByNameDirty( materialName );
    this.vestaObject.setType( 'floor' );
  }

  rebuildGeometry( shape ) {
    const geometry = new ShapeGeometry( shape );
    geometry.userData = {};
    geometry.userData.grainDirection = this.grainDirection;

    this.mesh.geometry = createGeometry( geometry );
    this.mountPlane.rebuildGeometry( shape );
  }

  getBoundingBox() {
    return new Box3().setFromObject( this );
  }

  get room() {
    return this._room;
  }

  set room( room ) {
    this._room = room;
  }

  setGrainDirection( grainDirection ) {
    this.grainDirection = grainDirection * TMath.DEG2RAD;
    if ( this.parent ) {
      this.rebuildGeometry( this.parent.rebuildFloor2DShape() );
    }

    Storage.get( 'viewer' ).renderOnDemand.set();
  }

  getGrainDirection() {
    return this.grainDirection * TMath.RAD2DEG;
  }
}
