import { simpleSet } from '@r/reducers/utils';
import { percent } from '@npmrost/utils';

const setCalculationModeShippingCostManualValue = simpleSet as
  redux.reducers.calculationMode.settings.information.shippingCostManual.value;

export const value = setCalculationModeShippingCostManualValue(
  'SET_CALCULATION_MODE_SHIPPING_COST_MANUAL_VALUE',
  0 as percent
);
