import React, { PureComponent } from 'react';
import Button from 'c/shared/ThemeButton';
import SimpleInput from 'c/CalculationLayer/utils/SimpleInput';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import Storage from 'scr/utilitiesStorage';
import CheckChangedValue from 'c/shared/borderForChangedValue';
import { connect } from 'react-redux';
import style from './index.styl';
import {
  regExForNumber,
  replaceValue,
  replaceValueWithDot
} from './utils';

interface _IProps {
  selectedFloorPlanObject: redux._IStore['selectedFloorPlanObject'];
}

interface _IState {
  width: string;
  height: string;
  depth: string;
  angle: string;
  oldWidth: string;
  oldHeight: string;
  oldDepth: string;
  oldAngle: string;
}

class RightMenuForWall3D extends PureComponent<_IProps> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    width: '0',
    height: '0',
    depth: '0',
    angle: '0',
    oldWidth: '0',
    oldHeight: '0',
    oldDepth: '0',
    oldAngle: '0'
  } as _IState;

  componentDidMount(): void {
    const {
      selectedFloorPlanObject: {
        width,
        height,
        depth,
        angle
      }
    } = this.props;

    this.setState( {
      width: String( width ),
      height: String( height ),
      depth: String( depth ),
      angle: String( angle )
    } );
  }

  componentDidUpdate(): void {
    const {
      selectedFloorPlanObject
    } = this.props;

    const {
      oldWidth,
      oldHeight,
      oldDepth,
      oldAngle
    } = this.state;

    if( oldWidth !== String( selectedFloorPlanObject.width ) ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState( {
        oldWidth: String( selectedFloorPlanObject.width ),
        width: String( selectedFloorPlanObject.width )
      } );
    }
    if( oldHeight !== String( selectedFloorPlanObject.height ) ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState( {
        oldHeight: String( selectedFloorPlanObject.height ),
        height: String( selectedFloorPlanObject.height )
      } );
    }
    if( oldDepth !== String( selectedFloorPlanObject.depth ) ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState( {
        oldDepth: String( selectedFloorPlanObject.depth ),
        depth: String( selectedFloorPlanObject.depth )
      } );
    }
    if( oldAngle !== String( selectedFloorPlanObject.angle ) ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState( {
        angle: String( selectedFloorPlanObject.angle ),
        oldAngle: String( selectedFloorPlanObject.angle )
      } );
    }
  }

  handleChangeWidth = ( e: string ) => {
    if( e.match( regExForNumber ) ) {
      const newValue = replaceValueWithDot( e );

      this.setState( {
        width: newValue
      } );
    }
    if( !e ) {
      this.setState( {
        width: '0'
      } );
    }
  };

  handleChangeHeight = ( e: string ) => {
    if( e.match( regExForNumber ) ) {
      const newValue = replaceValueWithDot( e );

      this.setState( {
        height: newValue
      } );
    }
    if( !e ) {
      this.setState( {
        height: '0'
      } );
    }
  };

  handleChangeDepth = ( e: string ) => {
    if( e.match( regExForNumber ) ) {
      const newValue = replaceValueWithDot( e );

      this.setState( {
        depth: newValue
      } );
    }
    if( !e ) {
      this.setState( {
        depth: '0'
      } );
    }
  };

  handleChangeAngle = ( e: string ) => {
    if(
      e.match( regExForNumber ) &&
      Number( e ) > -0.01 &&
      Number( e ) < 360
    ) {
      const newValue = replaceValueWithDot( e );

      this.setState( {
        angle: newValue
      } );
    }
    if( !e ) {
      this.setState( {
        angle: '0'
      } );
    }
  };

  handleRemoveWall = () => {
    const selectedObject = Storage.get( 'selectedObject' );

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    selectedObject[ 0 ].delete();
  };

  handleApplyWidth = () => {
    const { oldWidth, width } = this.state;

    const selectedObject = Storage.get( 'selectedObject' );
    const viewer = Storage.get( 'viewer' );
    if( oldWidth !== width ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      selectedObject[ 0 ].changeWidth( Number( width ) );
    }

    this.setState( {
      oldWidth: width
    } );

    viewer.renderOnDemand.set();
  };

  handleApplyHeight = () => {
    const { oldHeight, height } = this.state;

    const selectedObject = Storage.get( 'selectedObject' );
    const viewer = Storage.get( 'viewer' );
    if( oldHeight !== height ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      selectedObject[ 0 ].changeHeight( Number( height ) );
    }

    this.setState( {
      oldHeight: height
    } );

    viewer.renderOnDemand.set();
  };

  handleApplyDepth = () => {
    const { oldDepth, depth } = this.state;

    const selectedObject = Storage.get( 'selectedObject' );
    const viewer = Storage.get( 'viewer' );
    if( oldDepth !== depth ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      selectedObject[ 0 ].changeDepth( Number( depth ) );
    }

    this.setState( {
      oldDepth: depth
    } );

    viewer.renderOnDemand.set();
  };

  handleApplyAngle = () => {
    const { oldAngle, angle } = this.state;

    const selectedObject = Storage.get( 'selectedObject' );
    const viewer = Storage.get( 'viewer' );
    if( oldAngle !== angle ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      selectedObject[ 0 ].set2DAngleDeg( Number( angle ) );
    }

    this.setState( {
      oldAngle: angle
    } );

    viewer.renderOnDemand.set();
  };

  render() {
    const {
      width,
      height,
      depth,
      angle,
      oldWidth,
      oldHeight,
      oldDepth,
      oldAngle
    } = this.state;

    let newAngle = replaceValue( angle );
    let newWidth = replaceValue( width );
    let newHeight = replaceValue( height );
    let newDepth = replaceValue( depth );

    return (
      <div className={ style.rightMenuForWall3D }>
        <Button onClick={ this.handleRemoveWall }>
          Remove Wall
        </Button>
        <h4>Wall settings</h4>
        <CheckChangedValue changed={ width !== oldWidth }>
          <LabeledField
            name='Width:'
            className='input-for-wall3d'
          >
            <SimpleInput
              value={ newWidth }
              onChange={ this.handleChangeWidth }
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              onBlur={ this.handleApplyWidth }
            />
          </LabeledField>
        </CheckChangedValue>
        <CheckChangedValue changed={ height !== oldHeight }>
          <LabeledField
            name='Height:'
            className='input-for-wall3d'
          >
            <SimpleInput
              value={ newHeight }
              onChange={ this.handleChangeHeight }
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              onBlur={ this.handleApplyHeight }
            />
          </LabeledField>
        </CheckChangedValue>
        <CheckChangedValue changed={ depth !== oldDepth }>
          <LabeledField
            name='Depth:'
            className='input-for-wall3d'
          >
            <SimpleInput
              value={ newDepth }
              onChange={ this.handleChangeDepth }
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              onBlur={ this.handleApplyDepth }
            />
          </LabeledField>
        </CheckChangedValue>
        <CheckChangedValue changed={ angle !== oldAngle }>
          <LabeledField
            name='Angle:'
            className='input-for-wall3d'
          >
            <SimpleInput
              value={ newAngle }
              onChange={ this.handleChangeAngle }
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              onBlur={ this.handleApplyAngle }
            />
          </LabeledField>
        </CheckChangedValue>
      </div>
    );
  }
}

export default connect( ( { selectedFloorPlanObject }: redux._IStore ) => {
  return {
    selectedFloorPlanObject
  };
} )( RightMenuForWall3D );
