import { combineReducers } from 'redux';
import feathersClient from 'scr/getFeathers';
import { settings } from './settings';
import { cabinets } from './cabinets';
import { projectId } from './projectId';
import { getExistingProjects } from '../existingProjects';


export const currentProject = combineReducers( {
  settings,
  cabinets,
  projectId
} );


export function createNewProject() {

  return async ( dispatch, getStore ) => {
    const {
      calculationMode: {
        settings
      }
    } = getStore();

    const cabinets = {
      data: [],
      counter: 0,
      keys: []
    };

    try {
      const { _id } = await feathersClient
        .service( 'api/calculationProjects' )
        .create( { settings, cabinets } );

      dispatch( {
        type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_SETTINGS',
        value: JSON.parse( JSON.stringify( settings ) )
      } );

      dispatch( {
        type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_CABINETS',
        value: cabinets
      } );

      dispatch( {
        type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_ID',
        value: _id
      } );

      dispatch( getExistingProjects() );

      return Promise.resolve();
    } catch ( err ) {
      return Promise.reject( err );
    }
  };
}

export function saveProject() {
  return async ( dispatch, getStore ) => {
    const {
      calculationMode: {
        projects: {
          currentProject: {
            projectId,
            settings,
            cabinets
          }
        }
      }
    } = getStore();

    try {
      await feathersClient
        .service( 'api/calculationProjects' )
        .patch( projectId, { settings, cabinets } );

      dispatch( getExistingProjects() );

      return Promise.resolve();
    } catch ( err ) {
      return Promise.reject( err );
    }
  };
}
