import { combineReducers } from 'redux';
import { AllActions, combRed } from '@r/storeUtils';
import { addWallMode } from './addWallMode';
import { moveElementMode } from './moveElementMode';
import { orthoMode } from './orthoMode';

const floorPlanToolsComb = combineReducers as
  combRed<redux._IStore['floorPlanTools'], AllActions>;

export const floorPlanTools = floorPlanToolsComb( {
  addWallMode,
  moveElementMode,
  orthoMode
} );

