import React from 'react';
import styl from './index.styl';

export default class TripletHolder extends React.PureComponent {
  render() {
    const { className = '' } = this.props;

    return (
      <div className={ `${ styl.tripletHolder } ${ className }` }>
        { this.props.children }
      </div>
    );
  }
}
