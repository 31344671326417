import DoAction from "./DoAction";

export default class DoSetEntitySize extends DoAction {
    constructor(entity, before, after) {
        super();

        this.entity = entity;
        this.before = before;
        this.after = after;
    }
	redo() {
        this.doSize(this.after);
	}
	undo() {
        this.doSize(this.before);
    }
    doSize(size) {
        const entity = this.entity.vestaObject;
        entity.changeWidth(size.width);
        entity.changeHeight(size.height);
        entity.changeDepth(size.depth);

        this.viewer.renderOnDemand.set();
    }
}