import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  RadioSelect
} from '@npmrost/storybook/stories/Vesta/Choice/RadioSelect';
import {
  LabeledUnitsInput
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledUnitsInput';
import {
  LabeledNaturalInput
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledNaturalInput';
import {
  LabeledReactSelectSingle
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledReactSelectSingle';
import { Details as Icon } from '@npmrost/storybook/stories/Vesta/Icons';
import { changeValue } from '../../utils';

const Wrapper = styled.div`
  width: 60%;
  padding: 20px 30px;
  ${ ( { theme: t } ) => `
    background-color: ${ t.arraySetsBackground };
    border-radius: ${ t.borderRadius };
  ` }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.span`
  margin-left: 10px;
  ${ ( { theme: t } ) => `
    color: ${ t.darkGreyColor };
  ` }
`;

const StyledRadioSelect = styled( RadioSelect )`
  margin-bottom: 20px;
  .option {
    padding-left: 10px;
  }
  .option:before {
    background-color: #fff;
  }
`;

const FlexContent = styled.div`
  display: flex;
`;

const LeftContent = styled.div`
  & > div {
    margin-bottom: 10px;
  }
`;

const RightContent = styled.div`
  margin-left: 30px;
  & > div {
    margin-bottom: 10px;
  }
`;

class Details extends PureComponent {
  render() {

    const {
      props: {
        dispatch,
        dataKey,
        texts: {
          title,
          swingOptions: swingOptionsText,
          leftSideFillerWidth: leftSideFillerWidthText,
          rightSideFillerWidth: rightSideFillerWidthText,
          leftFinEnd: leftFinEndText,
          rightFinEnd: rightFinEndText,
          finEndOptions: finEndOptionsText,
          adjustableShelfQty: adjustableShelfQtyText,
          fixedShelfQty: fixedShelfQtyText,
          valanceHeight: valanceHeightText,
          toeKickHeight: toeKickHeightText,
          toeKickType: toeKickTypeText,
          toeKickTypeOptions: toeKickTypeOptionsText
        },
        swing,
        leftSideFillerWidth: { label: leftSideFillerWidth },
        rightSideFillerWidth: { label: rightSideFillerWidth },
        adjustableShelfQty,
        valanceHeight: { label: valanceHeight },
        fixedShelfQty,
        toeKickHeight: { label: toeKickHeight },
        leftFinEnd,
        rightFinEnd,
        toeKickType
      }
    } = this;

    const swingOptionsArr = ['leftSwing', 'rightSwing'];

    const swingOptions = swingOptionsText
      .map( ( item, index ) => ( {
        label: item,
        value: swingOptionsArr[ index ]
      } ) );

    const swingChosen = swingOptions.find( ( item ) => item.value === swing );

    const finEndOptionsArr = ['applied', 'integrated', 'none'];

    const finEndOptions = finEndOptionsText
      .map( ( item, index ) => ( {
        label: item,
        value: finEndOptionsArr[ index ]
      } ) );

    const leftFinEndChosen = finEndOptions.find( ( item ) => item.value === leftFinEnd );
    const rightFinEndChosen = finEndOptions.find( ( item ) => item.value === rightFinEnd );

    const toeKickTypeOptionsArr = ['toFloor', 'legLeveler'];

    const toeKickTypeOptions = toeKickTypeOptionsText
      .map( ( item, index ) => ( {
        label: item,
        value: toeKickTypeOptionsArr[ index ]
      } ) );

    const toeKickTypeChosen = toeKickTypeOptions.find( ( item ) => item.value === toeKickType );

    return (
      <Wrapper>
        <TitleWrapper>
          { Icon }
          <Title>{ title }</Title>
        </TitleWrapper>
        <StyledRadioSelect
          options={ swingOptions }
          chosen={ [swingChosen] }
          choose={ changeValue( dispatch, dataKey, 'swing', 'select' ) }
        />
        <FlexContent>
          <LeftContent>
            <LabeledUnitsInput
              name={ leftSideFillerWidthText }
              value={ leftSideFillerWidth }
              onChange={ changeValue( dispatch, dataKey, 'leftSideFillerWidth', 'unit' ) }
            />
            <LabeledReactSelectSingle
              name={ leftFinEndText }
              options={ finEndOptions }
              chosen={ [leftFinEndChosen] }
              choose={ changeValue( dispatch, dataKey, 'leftFinEnd', 'select' ) }
            />
            <LabeledNaturalInput
              name={ adjustableShelfQtyText }
              value={ adjustableShelfQty }
              onChange={ changeValue( dispatch, dataKey, 'adjustableShelfQty', 'number' ) }
            />
            <LabeledUnitsInput
              name={ valanceHeightText }
              value={ valanceHeight }
              onChange={ changeValue( dispatch, dataKey, 'valanceHeight', 'unit' ) }
            />
            <LabeledReactSelectSingle
              name={ toeKickTypeText }
              options={ toeKickTypeOptions }
              chosen={ [toeKickTypeChosen] }
              choose={ changeValue( dispatch, dataKey, 'toeKickType', 'select' ) }
            />
          </LeftContent>
          <RightContent>
            <LabeledUnitsInput
              name={ rightSideFillerWidthText }
              value={ rightSideFillerWidth }
              onChange={ changeValue( dispatch, dataKey, 'rightSideFillerWidth', 'unit' ) }
            />
            <LabeledReactSelectSingle
              name={ rightFinEndText }
              options={ finEndOptions }
              chosen={ [rightFinEndChosen] }
              choose={ changeValue( dispatch, dataKey, 'rightFinEnd', 'select' ) }
            />
            <LabeledNaturalInput
              name={ fixedShelfQtyText }
              value={ fixedShelfQty }
              onChange={ changeValue( dispatch, dataKey, 'fixedShelfQty', 'number' ) }
            />
            <LabeledUnitsInput
              name={ toeKickHeightText }
              value={ toeKickHeight }
              onChange={ changeValue( dispatch, dataKey, 'toeKickHeight', 'unit' ) }
            />
          </RightContent>
        </FlexContent>
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        estimationPreview: {
          table: {
            details
          }
        },
        dimensions: {
          toeKickHeight,
          toeKickType,
          toeKickTypeOptions
        }
      }
    }
  } ) => {
    return {
      texts: {
        ...details,
        toeKickHeight,
        toeKickType,
        toeKickTypeOptions
      }
    };
  }
)( Details );
