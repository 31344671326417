export function addCube(){
  const { width, height, depth } = this.size;
  if(this._cube) this.origin.remove(this._cube);
  
  var newGeometry = new THREE.BoxGeometry(width, height, depth);
  var newMaterial = new THREE.MeshBasicMaterial({ visible: false });
  this._cube = new THREE.Mesh(newGeometry, newMaterial);
  this._cube.position.y = height / 2;
  this._cube.arrows = [];

  this.origin.add(this._cube);
}