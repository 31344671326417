import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Summary from './Summary';
import Table from './Table';
import { calculateCost, pieCutCarcasses } from './calculations';

const Wrapper = styled.div`
  margin: 65px 10px 0;
  height: calc(100vh - 65px);
  padding: 30px 30px 30px 40px;
  ${ ( { theme: t } ) => `
    background-color: ${ t.generalBackground };
    border-radius: ${ t.borderRadius };
  ` }
`;

const Title = styled.h3`
  text-align: center;
  margin: 0;
  padding-bottom: 10px;
  box-sizing: border-box;
`;

class Preview extends PureComponent {
  render() {
    const {
      texts: {
        title
      },
      cabinets,
      materialsSets,
      fixLabourCost,
      doorLabourUnit,
      drawerLabourUnit,
      cabinetsLabourUnit
    } = this.props;

    const costData = calculateCost( {
      cabinets,
      materialsSets,
      fixLabourCost,
      doorLabourUnit,
      drawerLabourUnit,
      cabinetsLabourUnit
    } );

    const fullDepthIsShown = !!cabinets.data
      .find( ( item ) => pieCutCarcasses.includes( item.carcasse ) );

    return (
      <Wrapper>
        <Title>{ title }</Title>
        <Table { ...{ ...costData, fullDepthIsShown } } />
        <Summary { ...costData } />
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: { estimationPreview }
    },
    calculationMode: {
      projects: {
        currentProject: {
          cabinets,
          settings: {
            materials: { materialsSets }
          }
        }
      },
      adminSettings: {
        fixLabourCost,
        doorLabourUnit,
        drawerLabourUnit,
        cabinetsLabourUnit
      }
    }
  } ) => {
    return {
      texts: estimationPreview,
      cabinets,
      materialsSets,
      fixLabourCost,
      doorLabourUnit,
      drawerLabourUnit,
      cabinetsLabourUnit
    };
  }
)( Preview );
