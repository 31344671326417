export default class DoBaseAction {
    constructor() {
        this.group = undefined; // flag : single(undefined), groupBegin(true), groupEnd(false)
        this.dummy = false; // is empty action ?
    }
    get isSingle() {
        return this.group===undefined;
    }
    get isGroupBegin() {
        return this.group !== undefined && this.group;
    }
    get isGroupEnd() {
        return this.group !== undefined && !this.group;
    }
    undo() {
    }
    redo() {
    }
}