import {Step} from "./BaseCmd";
import DraftCmd from "./DraftCmd";
import * as THREE from 'three';
import {createCurveEntity} from '../entity/CurveEntityEx';
import DoAddRemoveEntity from "../UndoRedo/DoAddRemoveEntity";
import { DrawSnap, Constants, CommonUtil, CurveUtil } from "../common";

class Arc3Cmd extends DraftCmd {
    draftEntity = null;
    
    vt0 = new THREE.Vector2();
    vt1 = new THREE.Vector2();
    vt2 = new THREE.Vector2();

    v0 = new THREE.Vector2();
    v1 = new THREE.Vector2();
    v2 = new THREE.Vector2();

    doActivate() {
        super.doActivate();
        this.setCursor('arc3p', true);
    }
    initCmd() {
        super.initCmd();
        this.draftEntity = null;
    }
    doEscape() {
        if (this.draftEntity) {
            this.draftEntity.dispose();
            this.updateRender();
        }
        super.doEscape();
    }
    onMouseMove(e) {
        super.onMouseMove(e);

        const pos = this.tracker.trackPos2d(true);
        if (!pos) return;

        switch(this.step) {
            case Step.step1: {
                this.vt1.copy(pos);
                break;
            }
            case Step.step2: {
                const vm = calcProject(this.vt0, this.vt1, pos);
                if (!this.equalsPoint(this.vt1, vm)) {
                    this.vt2.copy(pos);
                    const arc = CurveUtil.calcArc(this.vt0, this.vt1, this.vt2);
                    if (arc) {
                        const curve = this.draftEntity.curve;
                        curve.aX = arc.center.x;
                        curve.aY = arc.center.y;
                        curve.xRadius = curve.yRadius = arc.radius;
                        curve.aStartAngle = arc.aStart;
                        curve.aEndAngle = arc.aEnd;
                        curve.aClockwise = arc.clockWise;
                        
                        this.draftEntity.updateEntity();
                        this.draftEntity.visible = true;

                        this.updateRender();
                    }
                }
                break;
            }
        }
    }
    onMouseClick(e) {
        super.onMouseClick(e);
        
        const pos = this.tracker.trackPos2d(true);
        if (!pos) return;

        switch(this.step) {
            case Step.step0: {
                this.vt0.copy(pos);
                this.vt1.copy(this.vt0);

                this.step = Step.step1;
                break;
            }
            case Step.step1: {
                if (!this.equalsPoint(this.vt0, this.vt1)) {
                    this.vt2.copy(this.vt1);

                    this.draftEntity = createCurveEntity(new THREE.ArcCurve());
                    this.draftEntity.visible = false;
                    this.entityMgr.draftG.add(this.draftEntity);

                    this.step = Step.step2;
                }
                break;
            }
            case Step.step2: {
                if (this.draftEntity.visible) {
                    // end with undo/redo
                    const doaction = new DoAddRemoveEntity(this.draftEntity, true, this.entityMgr.curveG);
                    this.doMgr.registerDo(doaction);
                    doaction.redo();

                    this.initCmd();
                }
                break;
            }
        }
    }
    drawSnap(ctx) {
        super.drawSnap(ctx);
        
        switch(this.step) {
            case Step.step1: {
                this.v0.copy(this.viewer.uds2view(this.vt0));
                this.v1.copy(this.viewer.uds2view(this.vt1));

                DrawSnap.drawStickerLine(ctx, this.v0, this.v1);

                DrawSnap.drawSticker(ctx, this.v0);
                DrawSnap.drawSticker(ctx, this.v1);
                break;
            }
            case Step.step2: {
                this.v0.copy(this.viewer.uds2view(this.vt0));
                this.v1.copy(this.viewer.uds2view(this.vt1));
                this.v2.copy(this.viewer.uds2view(this.vt2));

                DrawSnap.drawStickerLine(ctx, this.v0, this.v1);
                DrawSnap.drawStickerLine(ctx, this.v1, this.v2);

                DrawSnap.drawSticker(ctx, this.v0);
                DrawSnap.drawSticker(ctx, this.v1);
                DrawSnap.drawSticker(ctx, this.v2);
                break;
            }
        }
    }
}

const calcProject = (() => {
    const vec = new THREE.Vector2();
    const vec2 = new THREE.Vector2();
    return (pb, pm, pe) => {
        vec.subVectors(pm, pb); // bm
        vec2.subVectors(pe, pb).normalize(); // be (normalized)

        const dot = vec.dot(vec2);
        vec2.multiplyScalar(dot); // projected on be

        return vec2.add(pb);
    }
})();

export default Arc3Cmd;