import { simpleSet } from '@r/reducers/utils';
import { getExistingProjects } from '../existingProjects';

export const chosenTags = simpleSet(
  'SET_CALCULATION_MODE_EXISTING_PROJECTS_CHOSEN_TAGS',
  []
);

export function chooseTag( tag ) {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        projects: { chosenTags }
      }
    } = getStore();

    let newChosenTags = chosenTags.slice();

    if ( newChosenTags.includes( tag ) ) {
      newChosenTags = newChosenTags.filter( ( item ) => item !== tag );
    } else {
      newChosenTags.push( tag );
    }

    dispatch( {
      type: 'SET_CALCULATION_MODE_EXISTING_PROJECTS_CHOSEN_TAGS',
      value: newChosenTags
    } );

    dispatch( getExistingProjects( { tags: newChosenTags } ) );
  };
}
