const calcValanceCost = ( {
  group,
  width: length,
  valanceHeight: height,
  materialCost,
  materialName
} ) => {

  console.log( `Material name: ${ materialName }` );
  console.log( `Material cost: ${ materialCost }` );
  console.log( `Valance length: ${ length }` );
  console.log( `Valance height: ${ height }` );

  const qty = group === 'Upper' ? 1 : 0;

  console.log( `Quantity: ${ qty }` );

  if ( !qty || !height ) return null;

  const area = Number( ( ( qty * length * height ) / 144 ).toFixed( 2 ) );

  const cost = Number(
    ( area * materialCost ).toFixed( 2 )
  );

  console.log( `Total cost: ${ cost }` );

  return {
    name: materialName,
    length,
    height,
    // doubled prices
    cost: 2 * cost,
    area
  };
};

export default ( {
  group,
  width,
  valanceHeight,
  materialCost,
  materialName,
  materialSheetArea,
  materialSheetCost,
  totalMaterials,
  totalValance
} ) => {

  const newValance = calcValanceCost( {
    group,
    width,
    valanceHeight,
    materialCost,
    materialName
  } );

  if ( !newValance ) return { totalValance, totalMaterials };

  const newTotalValance = totalValance.slice();
  const newTotalMaterials = { ...totalMaterials };

  const {
    name: newName,
    height: newHeight,
    length: newLength,
    cost: newCost,
    area
  } = newValance;

  const materialArea = area;
  // eslint-disable-next-line prefer-reflect
  delete newValance.area;

  const valanceExists = newTotalValance
    .find( ( item ) => item.name === newName );

  if ( valanceExists ) {
    let newItem = false;

    for ( let i = 0; i < newTotalValance.length; i++ ) {
      const { name, height, cost } = newTotalValance[ i ];

      if ( name === newName ) {
        if ( height === newHeight ) {
          newTotalValance[ i ].length += newLength;
          newTotalValance[ i ].cost = Number( ( cost + newCost ).toFixed( 2 ) );
        } else {
          newItem = true;
        }

        break;
      }
    }

    if ( newItem ) {
      newTotalValance.push( newValance );
    }
  } else {
    newTotalValance.push( newValance );
  }

  if ( newTotalMaterials[ materialName ] ) {
    newTotalMaterials[ materialName ].area += materialArea;
  } else {
    newTotalMaterials[ materialName ] = {
      area: materialArea,
      cost: materialCost,
      sheetArea: materialSheetArea,
      sheetCost: Number( materialSheetCost )
    };
  }

  return {
    totalValance: newTotalValance,
    totalMaterials: newTotalMaterials
  };
};
