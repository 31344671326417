import { inches } from '@npmrost/utils/lib';

// (\d+\.\d+|\d+)
// $1 as inches
export const ShakerDoorBase:
  Partial<CabD.A.ContainerConfigs.ContainerConfig> = {
    height: 36 as inches,
    depth: 24 as inches,
    width: 18 as inches,
    name: 'Shaker Door Base',
    isTopLevel: true,
    children: [
      {
        name: 'ToeKickAssembly',
        layout: 'DEPTH',
        height: 3 as inches,
        children: [
          {
            name: 'ToeKickFinishEnds',
            layout: 'WIDTH',
            children: [
              {
                width: 0.5 as inches,
                type: 'Panel',
                name: 'ToeKickAssembly--LeftPanel'
              },
              {
                name: 'ToeKickAssembly--HollowCenter'
              },
              {
                width: 0.5 as inches,
                type: 'Panel',
                name: 'ToeKickAssembly--RightPanel'
              }
            ]
          },
          {
            name: 'ToeKickAssembly--FrontPanel',
            type: 'Panel',
            depth: 0.5 as inches
          },
          {
            name: 'ToeKickAssembly--ToeKickSetBack',
            depth: 2 as inches
          }
        ]
      },
      {
        name: 'BaseAssembly',
        layout: 'DEPTH',
        children: [
          {
            name: 'BaseCarcassAssembly',
            layout: 'WIDTH',
            children: [
              {
                name: 'BaseLeftSide',
                type: 'Panel',
                width: 0.5 as inches
              },
              {
                name: 'BaseCarcassAssembly--InteriorAssembly',
                children: [
                  {
                    name: 'BaseBottom',
                    height: 0.5 as inches,
                    type: 'Panel'
                  },
                  {
                    name: 'BaseInteriorAssembly',
                    layout: 'DEPTH',
                    children: [
                      {
                        name: 'BaseBack',
                        type: 'Panel',
                        depth: 0.5 as inches
                      },
                      {
                        name: 'BaseInterior',
                        children: [
                          {
                            name: 'Interior',
                            children: [
                              {},
                              {
                                height: 0.5 as inches,
                                type: 'Panel',
                                name: 'Shelf'
                              },
                              {},
                              {
                                height: 0.5 as inches,
                                type: 'Panel',
                                name: 'Shelf'
                              },
                              {}
                            ]
                          },
                          {
                            name: 'BaseFullTop',
                            type: 'Panel',
                            height: 0.5 as inches
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                name: 'BaseRightSide',
                width: 0.5 as inches,
                type: 'Panel'
              }
            ]
          },
          {
            name: 'FrontExteriorAssembly',
            layout: 'WIDTH',
            depth: 0.75 as inches,
            children: [
              {
                name: 'LeftDoorOverlay',
                width: 0.25 as inches
              },
              {
                name: 'FrontAssembly',
                children: [
                  {
                    name: 'BottomReveal',
                    height: 0.25 as inches
                  },
                  {
                    name: 'ShakerDoorAssembly',
                    layout: 'WIDTH',
                    depth: 0.75 as inches,
                    children: [
                      {
                        width: 4 as inches,
                        type: 'Panel',
                        panelParent: 'door',
                        name: 'DoorStiles'
                      },
                      {
                        name: 'CenterDoorAssembly',
                        depth: 0.75 as inches,
                        children: [
                          {
                            height: 4 as inches,
                            type: 'Panel',
                            panelParent: 'door',
                            name: 'DoorRail'
                          },
                          {
                            name: 'ShakerCenterAssembly',
                            layout: 'DEPTH',
                            depth: 0.75 as inches,
                            children: [
                              {
                                type: 'Panel',
                                panelParent: 'door',
                                name: 'ThinCenter',
                                depth: 0.25 as inches
                              }
                            ]
                          },
                          {
                            height: 4 as inches,
                            type: 'Panel',
                            panelParent: 'door',
                            name: 'DoorRail'
                          }
                        ]
                      },
                      {
                        width: 4 as inches,
                        handleOrientation: 'VERTICAL',
                        handlePosition: {
                          oX: {
                            type: 'toRight',
                            value: 2 as inches
                          },
                          oY: {
                            type: 'toTop',
                            value: 3 as inches
                          },
                          oZ: 'onSurface'
                        },
                        type: 'Door',
                        name: 'Door--DoorPanel'
                      }
                    ]
                  },
                  {
                    name: 'TopReveal',
                    height: 0.25 as inches
                  }
                ]
              },
              {
                name: 'RightDoorOverlay',
                width: 0.25 as inches
              }
            ]
          }
        ]
      }
    ],
    countertopMaterialName: 'marblewhite005'
  };
