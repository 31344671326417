// @ts-check

/** @type { Partial<CabD.A.ContainerConfigs.ContainerConfig> } */
export const TwoDoorBase = {
  height: 36,
  depth: 24,
  width: 36,
  name: '2 Door Base',
  isTopLevel: true,
  children: [
    {
      name: 'ToeKickAssembly',
      layout: 'DEPTH',
      height: 3,
      children: [
        {
          name: 'ToeKickFinishEnds',
          layout: 'WIDTH',
          children: [
            {
              width: 0.5,
              type: 'Panel',
              name: 'ToeKickAssembly--LeftPanel'
            },
            {
              name: 'ToeKickAssembly--HollowCenter'
            },
            {
              width: 0.5,
              type: 'Panel',
              name: 'ToeKickAssembly--RightPanel'
            }
          ]
        },
        {
          name: 'ToeKickAssembly--FrontPanel',
          type: 'Panel',
          depth: 0.5
        },
        {
          name: 'ToeKickAssembly--ToeKickSetBack',
          depth: 2
        }
      ]
    },
    {
      name: 'BaseAssembly',
      layout: 'DEPTH',
      children: [
        {
          name: 'BaseCarcassAssembly',
          layout: 'WIDTH',
          children: [
            {
              name: 'BaseLeftSide',
              type: 'Panel',
              width: 0.5
            },
            {
              name: 'BaseCarcassAssembly--InteriorAssembly',
              children: [
                {
                  name: 'BaseBottom',
                  height: 0.5,
                  type: 'Panel'
                },
                {
                  name: 'BaseInteriorAssembly',
                  layout: 'DEPTH',
                  children: [
                    {
                      name: 'BaseBack',
                      type: 'Panel',
                      depth: 0.5
                    },
                    {
                      name: 'BaseInterior',
                      children: [
                        {
                          name: 'Interior',
                          children: [
                            {},
                            {
                              height: 0.5,
                              type: 'Panel',
                              name: 'Shelf'
                            },
                            {},
                            {
                              height: 0.5,
                              type: 'Panel',
                              name: 'Shelf'
                            },
                            {}
                          ]
                        },
                        {
                          name: 'BaseFullTop',
                          type: 'Panel',
                          height: 0.5
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              name: 'BaseRightSide',
              width: 0.5,
              type: 'Panel'
            }
          ]
        },
        {
          name: 'FrontExteriorAssembly',
          layout: 'WIDTH',
          depth: 0.5,
          children: [
            {
              name: 'LeftDoorOverlay',
              width: 1 / 4
            },
            {
              name: 'FrontAssembly',
              children: [
                {
                  name: 'TopReveal',
                  height: 1 / 4
                },
                {
                  name: 'Doors',
                  layout: 'WIDTH',
                  children: [
                    {
                      handleOrientation: 'VERTICAL',
                      handlePosition: {
                        oX: {
                          type: 'toRight',
                          value: 3
                        },
                        oY: {
                          type: 'toTop',
                          value: 3
                        },
                        oZ: 'onSurface'
                      },
                      type: 'Door',
                      name: 'Door--DoorPanel'
                    },
                    {
                      width: 1 / 8
                    },
                    {
                      handleOrientation: 'VERTICAL',
                      handlePosition: {
                        oX: {
                          type: 'toLeft',
                          value: 3
                        },
                        oY: {
                          type: 'toTop',
                          value: 3
                        },
                        oZ: 'onSurface'
                      },
                      type: 'Door',
                      name: 'Door--DoorPanel'
                    }
                  ]
                },
                {
                  name: 'BottomReveal',
                  height: 1 / 4
                }
              ]
            },
            {
              name: 'RightDoorOverlay',
              width: 1 / 4
            }
          ]
        }
      ]
    }
  ]
};
