/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  LabeledUnitsInput
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledUnitsInput';
import { batchActionsWithTempl, d } from '@r/storeUtils';
import {
  UnitsInputValue
} from '@npmrost/storybook/stories/Vesta/Inputs/UnitsInput';
import { unitMapping } from 'scr/metricsConversion';
import { batchActions } from 'redux-batched-actions';
import {
  LabeledReactSelectSingle
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledReactSelectSingle';
import { LabelAndValue } from '@npmrost/utils';
import {
  updateCabinetsSettings
} from '@r/reducers/storeParts/calculationMode/projects/currentProject/cabinets';
import Cabinets from './Cabinets';

const Wrapper = styled.div`
  flex-grow: 1;
  padding-right: 0;
`;

const Content = styled.div`
  margin-top: 10px;
`;

const Inputs = styled.div`
  ${ ( { adminMode }: { adminMode: boolean } ) => `
    padding-right: ${ adminMode ? '0' : '100px' };
  ` }
  & > * {
    margin-bottom: 15px;
  }
`;

const Title = styled.span`
  ${ ( { theme: t } ) => `
    color: ${ t.darkGreyColor };
  ` }
`;

type a = redux._IActions;

type labelActionTypes = a['setCalculationModeToeKickHeightLabel']['type'] |
  a['setCalculationModeTopDrawerHeightLabel']['type'] |
  a['setCalculationModeCurrentProjectToeKickHeightLabel']['type'] |
  a['setCalculationModeCurrentProjectTopDrawerHeightLabel']['type'];

type valueActionTypes = a['setCalculationModeToeKickHeightValue']['type'] |
  a['setCalculationModeTopDrawerHeightValue']['type'] |
  a['setCalculationModeCurrentProjectToeKickHeightValue']['type'] |
  a['setCalculationModeCurrentProjectTopDrawerHeightValue']['type'];

type batchAction = batchActionsWithTempl<
  a['setCalculationModeToeKickHeightLabel'] |
  a['setCalculationModeToeKickHeightValue'] |
  a['setCalculationModeTopDrawerHeightLabel'] |
  a['setCalculationModeTopDrawerHeightValue'] |
  a['setCalculationModeToeKickType'] |
  a['setCalculationModeCurrentProjectToeKickHeightLabel'] |
  a['setCalculationModeCurrentProjectToeKickHeightValue'] |
  a['setCalculationModeCurrentProjectTopDrawerHeightLabel'] |
  a['setCalculationModeCurrentProjectTopDrawerHeightValue'] |
  a['setCalculationModeCurrentProjectToeKickType']
>;

interface _IProps {
  className: string;
  settings: redux._IStore['calculationMode']['settings']['dimensions'];
  texts: redux._IStore['texts']['calculationMode'];
  units: redux._IStore['calculationMode']['settings']['information']['units'];
  currentProject: boolean;
  adminMode: boolean;
  dispatch: d<ReturnType<batchAction>>;
}

class Dimensions extends PureComponent<_IProps> {

  static defaultProps: Pick<_IProps, 'className'> = {
    className: ''
  }

  changeMetric = (
    propName: string,
    labelAction: labelActionTypes,
    valueAction: valueActionTypes
  ) => ( value: UnitsInputValue ) => {
    const { units, dispatch, currentProject } = this.props;

    const val = units === 'ftAndInch'
      ? unitMapping.ftAndInch( value )
      : unitMapping[ units ]( Number( value ) );

    dispatch(
      ( batchActions as batchAction )( [
        { type: labelAction, value },
        { type: valueAction, value: val }
      ] )
    );

    if ( currentProject ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      dispatch( updateCabinetsSettings(
        propName,
        { label: value, value: val }
      ) );
    }
  }

  setToeKickType = ( [{ value }]: Array<LabelAndValue<string, _IProps['settings']['toeKickType']>> ) => {
    const {
      currentProject,
      adminMode,
      dispatch
    } = this.props;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    dispatch(
      ( batchActions as batchAction )( [
        {
          type: `SET_CALCULATION_MODE${ typeText }_TOE_KICK_TYPE`,
          value: value as _IProps['settings']['toeKickType']
        }
      ] )
    );

    if ( currentProject ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      dispatch( updateCabinetsSettings(
        'toeKickType',
        value
      ) );
    }
  }

  render() {
    const {
      props: {
        className,
        texts: {
          dimensions: {
            title,
            toeKickHeight: toeKickHeightText,
            toeKickType: toeKickTypeText,
            topDrawerHeight: topDrawerHeightText,
            toeKickTypeOptions: toeKickTypeOptionsText
          },
          adminMode: { dimensionsTitle: adminTitle }
        },
        settings,
        settings: {
          toeKickHeight: { label: toeKickHeightLabel },
          toeKickType,
          topDrawerHeight: { label: topDrawerHeightLabel }
        },
        units,
        currentProject,
        adminMode
      },
      changeMetric,
      setToeKickType
    } = this;

    const toeKickTypeOptionsArr = ['toFloor', 'legLeveler'];

    const toeKickTypeOptions: LabelAndValue<string, _IProps['settings']['toeKickType']>[] =
      toeKickTypeOptionsText.map( ( item, index ) => ( {
        label: item,
        value: toeKickTypeOptionsArr[ index ] as
          _IProps['settings']['toeKickType']
      } ) );

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    return (
      <Wrapper { ...{ className } }>
        <Title>{ adminMode ? adminTitle : title }</Title>
        <Content>
          <Cabinets { ...{
            settings, units, currentProject, adminMode
          } }
          />
          <Inputs adminMode={ adminMode }>
            <LabeledUnitsInput
              name={ toeKickHeightText }
              value={ toeKickHeightLabel }
              onChange={ changeMetric(
                'toeKickHeight',
                `SET_CALCULATION_MODE${ typeText }_TOE_KICK_HEIGHT_LABEL`,
                `SET_CALCULATION_MODE${ typeText }_TOE_KICK_HEIGHT_VALUE`
              ) }
            />
            <LabeledReactSelectSingle<_IProps['settings']['toeKickType']>
              name={ toeKickTypeText }
              options={ toeKickTypeOptions }
              chosen={ [{
                label: toeKickTypeOptions.find( ( item ) => item.value === toeKickType ).label,
                value: toeKickType
              }] }
              choose={ setToeKickType }
            />
            <LabeledUnitsInput
              name={ topDrawerHeightText }
              value={ topDrawerHeightLabel }
              onChange={ changeMetric(
                'topDrawerHeight',
                `SET_CALCULATION_MODE${ typeText }_TOP_DRAWER_HEIGHT_LABEL`,
                `SET_CALCULATION_MODE${ typeText }_TOP_DRAWER_HEIGHT_VALUE`
              ) }
            />
          </Inputs>
        </Content>
      </Wrapper>
    );
  }
}

export default connect(
  ( s: redux._IStore ): Omit<
    _IProps,
    'className' |
    'currentProject' |
    'settings' |
    'units' |
    'dispatch' |
    'adminMode'
  > => {
    return {
      // settings: s.calculationMode.settings.dimensions,
      texts: s.texts.calculationMode
      // units: s.calculationMode.settings.information.units
    };
  }
)( Dimensions );
