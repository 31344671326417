/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { batchActionsWithTempl, d } from '@r/storeUtils';
import { unitMapping } from 'scr/metricsConversion';
import { batchActions } from 'redux-batched-actions';
import {
  UnitsInputValue, UnitsInput
} from '@npmrost/storybook/stories/Vesta/Inputs/UnitsInput';
// import { quantity } from '@npmrost/utils';
import {
  LabeledField
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledField';
import { Triplet } from '@npmrost/storybook/stories/Vesta/Triplet';
// import {
//   NaturalInput
// } from '@npmrost/storybook/stories/Vesta/Inputs/NaturalInput';
import {
  updateCabinetsSettings
} from '@r/reducers/storeParts/calculationMode/projects/currentProject/cabinets';

type a = redux._IActions;

type labelActionTypes = a['setCalculationModeUpperCabinetHeightLabel']['type'] |
  a['setCalculationModeUpperCabinetDepthLabel']['type'] |
  a['setCalculationModeCurrentProjectUpperCabinetHeightLabel']['type'] |
  a['setCalculationModeCurrentProjectUpperCabinetDepthLabel']['type'];

type valueActionTypes = a['setCalculationModeUpperCabinetHeightValue']['type'] |
  a['setCalculationModeUpperCabinetDepthValue']['type'] |
  a['setCalculationModeCurrentProjectUpperCabinetHeightValue']['type'] |
  a['setCalculationModeCurrentProjectUpperCabinetDepthValue']['type'];

type batchAction = batchActionsWithTempl<
  a['setCalculationModeUpperCabinetHeightLabel'] |
  a['setCalculationModeUpperCabinetHeightValue'] |
  a['setCalculationModeUpperCabinetDepthLabel'] |
  a['setCalculationModeUpperCabinetDepthValue'] |
  a['setCalculationModeUpperCabinetShelvesQty'] |
  a['setCalculationModeCurrentProjectUpperCabinetHeightLabel'] |
  a['setCalculationModeCurrentProjectUpperCabinetHeightValue'] |
  a['setCalculationModeCurrentProjectUpperCabinetDepthLabel'] |
  a['setCalculationModeCurrentProjectUpperCabinetDepthValue'] |
  a['setCalculationModeCurrentProjectUpperCabinetShelvesQty']
>;

interface _IProps {
  texts: redux._IStore['texts']['calculationMode'];
  settings: redux._IStore['calculationMode']['settings']['dimensions'];
  units: redux._IStore['calculationMode']['settings']['information']['units'];
  currentProject: boolean;
  adminMode: boolean;
  dispatch: d<ReturnType<batchAction>>;
}

class Upper extends PureComponent<_IProps> {

  changeMetric = (
    propName: string,
    labelAction: labelActionTypes,
    valueAction: valueActionTypes
  ) => ( value: UnitsInputValue ) => {
    const { units, dispatch, currentProject } = this.props;

    const val = units === 'ftAndInch'
      ? unitMapping.ftAndInch( value )
      : unitMapping[ units ]( Number( value ) );

    dispatch(
      ( batchActions as batchAction )( [
        { type: labelAction, value },
        { type: valueAction, value: val }
      ] )
    );

    if ( currentProject ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      dispatch( updateCabinetsSettings(
        propName,
        { label: value, value: val },
        'upper'
      ) );
    }
  }

  changeQty = ( n: string ) => {
    const { currentProject, adminMode, dispatch } = this.props;
    const value = Number( n );

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    dispatch(
      ( batchActions as batchAction )( [
        {
          type: `SET_CALCULATION_MODE${ typeText }_UPPER_CABINET_SHELVES_QTY`,
          value: value as quantity
        }
      ] )
    );

    if ( currentProject ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      dispatch( updateCabinetsSettings(
        'shelvesQty',
        value,
        'upper'
      ) );
    }
  }

  render() {
    const {
      props: {
        texts: {
          dimensions: {
            upperCabinet: upperCabinetText
          }
        },
        settings: {
          cabinets: {
            upper: {
              height: { label: hLabel },
              depth: { label: dLabel }
              // shelvesQty
            }
          }
        },
        currentProject,
        adminMode
      },
      changeMetric
      // changeQty
    } = this;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    return (
      <>
        <LabeledField name={ upperCabinetText }>
          <Triplet triple={ [
            <UnitsInput
              value={ hLabel }
              onChange={ changeMetric(
                'height',
                `SET_CALCULATION_MODE${ typeText }_UPPER_CABINET_HEIGHT_LABEL`,
                `SET_CALCULATION_MODE${ typeText }_UPPER_CABINET_HEIGHT_VALUE`
              ) }
            />,
            <UnitsInput
              value={ dLabel }
              onChange={ changeMetric(
                'depth',
                `SET_CALCULATION_MODE${ typeText }_UPPER_CABINET_DEPTH_LABEL`,
                `SET_CALCULATION_MODE${ typeText }_UPPER_CABINET_DEPTH_VALUE`
              ) }
            />,
            <></>
            // <NaturalInput
            //   value={ String( shelvesQty ) }
            //   onChange={ changeQty }
            // />
          ] }
          />
        </LabeledField>
      </>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: calculationModeTexts
    }
  }: redux._IStore ): Omit<
    _IProps,
    'settings' | 'units' | 'currentProject' | 'dispatch' | 'adminMode'
  > => {
    return {
      texts: calculationModeTexts
    };
  }
)( Upper );
