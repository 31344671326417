/**
 * Interface that describes a loader data
 * @typedef {Object} LoaderData
 * @property {number} action Number that describes loadable resource
 * @property {number} id Id of the resource
 * @property {object|null} data Data of the resource
 */

/**
 * Interface that describes a loadable resource
 * @typedef {Object} LoadableResource
 * @property {number} type Number that describes loadable resource
 * @property {number} id Id of the resource
 * @property {string} path Path of the loadable resource
 */

/**
 * Loader Actions
 * @type {Object}
 * @property {number} LoaderActions.START Start resource loading
 * @property {number} LoaderActions.FINISH Finish resource loading
 * @property {number} LoaderActions.ERROR Thrown resource loading error
 */
export const LoaderActions = {
    START: 0x0,
    FINISH: 0x1,
    ERROR: 0x2
};

/**
 * Loader Resource types
 * @type {Object}
 * @property {number} LoaderResourceTypes.OBJECT Used for text model loading
 * @property {number} LoaderResourceTypes.BUFFER_OBJECT Used for binary model loading
 * @property {number} LoaderResourceTypes.TEXTURE Used for Image loading
 * @property {number} LoaderResourceTypes.MATERIAL Used for Material loading
 */
export const LoaderResourceTypes = {
    TEXT: 0x0,
    ARRAYBUFFER: 0x1,
    BLOB: 0x2,
    JSON: 0x3,
    FORMDATA: 0x4
};

export const ResourceTypes = {
    GLTFTextObject: 'gltftextobject',
    GLTFBinaryObject: 'gltfbinaryobject',
    ObjTextObject: 'objtextobject',
    Material: 'material',
    Texture: 'texture',
    CubeTexture: 'cubetexture',
    JSON: 'json'
};

export const threadsNumber = 4;
