// @ts-check
import store from '@r/store';
import Panel from './Panel';
import Door from './Door';
import { GenericCabinet } from './GenericCabinet';
import { getDefaultsIfAbsent } from '../cabinets/helpers/GeneralConfigs';
import { WallCabinet } from '../cabinets/helpers/WallCabinet';
import { FloorCabinet } from '../cabinets/helpers/FloorCabinet';
import { WithCountertop } from '../cabinets/helpers/WithCountertop';
import {
  CornerWithCountertop
} from '../cabinets/helpers/CornerWithCountertop';
import { WallWindow } from '../cabinets/helpers/WallWindow';

const parts = {
  Panel,
  GenericCabinet,
  Door,
  WallCabinet,
  FloorCabinet,
  WithCountertop,
  CornerWithCountertop,
  WallWindow,
};

function resizeAccordingToCabinetsSetings( /** @type { GenericCabinet } */c ) {
  const {
    projectFor3d: {
      cabinetsSettings: cS
    }
  } = store.getState();
  const type = c.getType();
  if( type === '' ) {
    return;
  }

  c.vestaObject.changeDepth( cS[ type ].depth.value );
  c.vestaObject.changeHeight( cS[ type ].height.value );
}

function changeMetrics (
  /** @type { import('decl/cabinetdesigner').ChangeMetricsArg  } */
  {
    container: c, isTopLevel, width, depth, height
  }
) {
  if ( isTopLevel ) {
    c.createDimensionArrows();

    c.vestaObject.changeWidth( width );
    c.vestaObject.changeDepth( depth );
    c.vestaObject.changeHeight( height );
    resizeAccordingToCabinetsSetings( c );

    if (c.isWallWindow) c.vestaObject.changeDepth( depth );

    c.addCube();
  } else {
    c.setWidthConstraint( width );
    c.setDepthConstraint( depth );
    c.setHeightConstraint( height );
  }
}

/**
 * @type { (config: Partial<import('decl/cabinetdesigner/ContainerConfigs').cabinetConfig> ) =>
 *    Panel | Door | GenericCabinet
 * }
 */
export default function generateCabinet ( config ) {
  // Prefill config with default values where they are absent
  const d = getDefaultsIfAbsent( config );
  const {
    width, depth, height, name
  } = d;

  if ( d.type === 'Door' || d.type === 'Panel' ) {
    const panelOrDoor = new parts[ d.type ]( d );

    panelOrDoor.setWidthConstraint( width );
    panelOrDoor.setDepthConstraint( depth );
    panelOrDoor.setHeightConstraint( height );
    panelOrDoor.setName( name );

    if ( d.type === 'Panel' ) {
      return panelOrDoor;
    }

    const door = /** @type { Door } */(
      /** @type { unknown } */( panelOrDoor )
    );
    const {
      handleOrientation,
      handlePosition: { oX, oY, oZ }
    } = d;

    door.setHandleOrientation( handleOrientation );
    door.setHandlePositionAlongX( oX );
    door.setHandlePositionAlongY( oY );
    door.setHandlePositionAlongZ( oZ );

    return door;
  }

  const {
    isTopLevel, layout, children, constrName
  } = d;

  const constructorName = isTopLevel ? constrName : 'GenericCabinet';
  const container = new parts[ constructorName ]();

  container.setLayout( layout );
  container.setName( name );

  changeMetrics( {
    container, width, depth, height, isTopLevel
  } );

  if ( children !== undefined ) {
    for ( let i = 0; i < children.length; i += 1 ) {
      container.addComponent( generateCabinet( children[ i ] ) );
    }
  }

  return container;
}
