
import {
  Mesh, Group, SphereGeometry, Vector3, DoubleSide, MeshBasicMaterial, Matrix4, Vector2, FrontSide, Geometry, ExtrudeGeometry, Shape
} from 'three';

import Storage from 'scr/utilitiesStorage';
import Stage from './Stage';
import Wall3D from './Wall3D';
import Utils from './utils';

import { updateUISelectedObject } from '../core/helpers/UI';

const defaultMaterialUnselected = new MeshBasicMaterial( {
  color: 0x555555,
  transparent: true,
  opacity: 0,
  side: FrontSide
} );
const defaultMaterialSelected = new MeshBasicMaterial( {
  color: 0x3f8cba,
  transparent: true,
  opacity: 1.0,
  side: DoubleSide,
  depthTest: false
} );
const defaultMaterialInvisible = new MeshBasicMaterial( {
  visible: false
} );
const defaultMaterialZeroOpacity = new MeshBasicMaterial( {
  visible: true,
  transparent: true,
  opacity: 0
} );

// === wall3D.defaultMaterialUnselected
const wallsMergingMaterial = new MeshBasicMaterial( {
  color: 0xff0000, // 0x555555,
  transparent: false,
  side: DoubleSide
} );

export default class Corner extends Group {
  mesh: Mesh;

  stage: Stage;

  isTopLevel = true;

  isCorner = true;

  rayCastingMesh: Mesh;

  wallsMergingMesh: Mesh;

  walls3DSortedList: any;

  constructor( position: Vector2, material = defaultMaterialUnselected ) {
    super();
    const geometry = new SphereGeometry( 2, 15, 15 );
    this.mesh = new Mesh( geometry, material );
    this.rayCastingMesh = new Mesh( new SphereGeometry( 10, 10, 10 ), defaultMaterialZeroOpacity );
    this.rayCastingMesh.isRaycastedInFloorplanMode = true;
    this.wallsMergingMesh = new Mesh( new Geometry(), wallsMergingMaterial );
    this.wallsMergingMesh.rotation.set( -Math.PI / 2, 0, 0 );
    this.position.set( position.x, 0, position.y );
    this.add( this.mesh );
    this.add( this.rayCastingMesh );
    this.add( this.wallsMergingMesh );

    this.vestaObject.setType( 'corner' );

    // console.warn(this);
  }

  setMaterialToUnselected() {
    this.mesh.material = defaultMaterialUnselected;
  }

  setMaterialToSelected() {
    this.mesh.material = defaultMaterialSelected;
  }

  setMaterialToInvisible() {
    this.mesh.material = defaultMaterialInvisible;
  }

  getNeighbourCorners() {
    const result = [];

    for ( let i = 0; i < this.stage.walls3D.length; i += 1 ) {
      if ( this.stage.walls3D[ i ].from === this ) {
        result.push( this.stage.walls3D[ i ].to );
      }
      if ( this.stage.walls3D[ i ].to === this ) {
        result.push( this.stage.walls3D[ i ].from );
      }
    }


    return result;
  }

  getNeighbourWalls3D() {
    const result = [];
    for ( let i = 0; i < this.stage.walls3D.length; i += 1 ) {
      if ( this.stage.walls3D[ i ].to === this ||
        this.stage.walls3D[ i ].from === this ) {
        result.push( this.stage.walls3D[ i ] );
      }
    }


    return result;

  }

  getDistanceToCorner( corner: Corner ) {
    return Math.sqrt( Math.pow( ( this.position.x - corner.position.x ), 2 ) +
      Math.pow( ( this.position.z - corner.position.z ), 2 ) );

  }

  getDistanceToWall3D( wall3D: Wall3D ) {
    /* console.log('getDistanceToWall3D', new Vector2(this.x, this.y), new Vector2(wall3D.from.x, wall3D.from.y), new Vector2(wall3D.to.x, wall3D.to.y), Utils.pointDistanceFromLine(
     new Vector2(this.x, this.y),
       new Vector2(wall3D.from.x, wall3D.from.y),
       new Vector2(wall3D.to.x, wall3D.to.y)
     )) */
    return Utils.pointDistanceFromLine(
      new Vector2( this.x, this.y ),
      new Vector2( wall3D.from.x, wall3D.from.y ),
      new Vector2( wall3D.to.x, wall3D.to.y )
    );

  }

  getNearestCorner() {
    let minDistance = 1e10;
    let result;
    for ( let i = 0; i < this.stage.corners.length; i += 1 ) {
      if ( this.stage.corners[ i ] !== this ) {
        let distance = this.getDistanceToCorner( this.stage.corners[ i ] );
        if ( distance < minDistance ) {
          minDistance = distance;
          result = this.stage.corners[ i ];
        }
      }
    }


    return { object: result, distance: minDistance };
  }

  getNearestWall3D() {
    let minDistance = 1e10;
    let result;
    for ( let i = 0; i < this.stage.walls3D.length; i += 1 ) {
      if ( this.stage.walls3D[ i ].from !== this &&
        this.stage.walls3D[ i ].to !== this ) {
        let distance = this.getDistanceToWall3D( this.stage.walls3D[ i ] );
        if ( distance < minDistance ) {
          minDistance = distance;
          result = this.stage.walls3D[ i ];
        }
      }
    }


    return { object: result, distance: minDistance };
  }

  get x() {
    return this.position.x;
  }

  set x( value ) {
    this.position.x = value;
  }

  get y() {
    return this.position.z;
  }

  set y( value ) {
    this.position.z = value;
  }

  rebuildGeometry( _list ) {
    let list = [];
    if ( _list ) {
      list = _list;
    }

    list.push( this );
    const walls3D = this.getNeighbourWalls3D();

    this.rebuildWallsMergingMesh( walls3D );

    for ( let i = 0; i < walls3D.length; i += 1 ) {

      if ( walls3D[ i ].from !== this && list.indexOf( walls3D[ i ].from ) === -1 ) {
        walls3D[ i ].from.rebuildGeometry( list );
      }
      if ( walls3D[ i ].to !== this && list.indexOf( walls3D[ i ].to ) === -1 ) {
        walls3D[ i ].to.rebuildGeometry( list );
      }

      walls3D[ i ].rebuildGeometry();
    }

  }

  rebuildWallsMergingMesh( walls3D ) {
    const points = [];

    if ( walls3D.length < 2 ) return;

    walls3D.sort(
      ( a, b ) => {
        return ( a.getAngleAroundCorner( this ) - b.getAngleAroundCorner( this ) );
      }
    );
    let maxHeight = 0;
    for ( let i = 0; i < walls3D.length; i += 1 ) {
      let currentWall3D = walls3D[ i ];
      if ( currentWall3D.height > maxHeight ) {
        maxHeight = currentWall3D.height;
      }

      this.walls3DSortedList = walls3D;

      let nextWall3D = ( i >= ( walls3D.length - 1 ) ) ? walls3D[ 0 ] : walls3D[ i + 1 ];
      // console.log('current', currentWall3D, currentWall3D.from.x, currentWall3D.from.y, currentWall3D.to.x, currentWall3D.to.y);
      // console.log('next', nextWall3D, nextWall3D.from.x, nextWall3D.from.y, nextWall3D.to.x, nextWall3D.to.y);
      let currentWall3DBoundaries = currentWall3D.getBoundaries();
      let nextWall3DBoundaries = nextWall3D.getBoundaries();
      let angleCurrentWall3D = currentWall3D.get2DAngle();
      let angleNextWall3D = nextWall3D.get2DAngle();
      // console.log(angleCurrentWall3D, angleNextWall3D);
      if ( currentWall3D.from !== this ) {
        angleCurrentWall3D = angleCurrentWall3D >= Math.PI ? ( angleCurrentWall3D - Math.PI ) : ( angleCurrentWall3D + Math.PI );
      }
      if ( nextWall3D.from !== this ) {
        angleNextWall3D = angleNextWall3D >= Math.PI ? ( angleNextWall3D - Math.PI ) : ( angleNextWall3D + Math.PI );
      }
      // console.log(angleCurrentWall3D, angleNextWall3D);

      let k1, b1, k2, b2, x1, x2;
      let point = new Vector2();
      if ( angleCurrentWall3D === 0 ) {
        // y = c;
        k1 = 0;
        b1 = currentWall3D.from.y + currentWall3D.depth / 2;
      }
      if ( angleCurrentWall3D === Math.PI ) {
        // y = c;
        k1 = 0;
        b1 = currentWall3D.from.y - currentWall3D.depth / 2;
      } else if ( angleCurrentWall3D === Math.PI / 2 ) {
        // x = c;
        x1 = currentWall3D.from.x - currentWall3D.depth / 2;
      } else if ( angleCurrentWall3D === 3 * Math.PI / 2 ) {
        // x = c;
        x1 = currentWall3D.from.x + currentWall3D.depth / 2;
      } else {
        k1 = currentWall3DBoundaries.k;
        b1 = ( angleCurrentWall3D < Math.PI / 2 || angleCurrentWall3D >= 3 * Math.PI / 2 )
          ? currentWall3DBoundaries.b2 : currentWall3DBoundaries.b1;
      }

      if ( angleNextWall3D === 0 ) {
        // y = c;
        k2 = 0;
        b2 = nextWall3D.from.y - nextWall3D.depth / 2;
      } else if ( angleNextWall3D === Math.PI ) {
        // y = c;
        k2 = 0;
        b2 = nextWall3D.from.y + nextWall3D.depth / 2;
      } else if ( angleNextWall3D === Math.PI / 2 ) {
        // x = c;
        x2 = nextWall3D.from.x + nextWall3D.depth / 2;
      } else if ( angleNextWall3D === 3 * Math.PI / 2 ) {
        // x = c;
        x2 = nextWall3D.from.x - nextWall3D.depth / 2;
      } else {
        k2 = nextWall3DBoundaries.k;
        b2 = ( angleNextWall3D < Math.PI / 2 || angleNextWall3D >= 3 * Math.PI / 2 )
          ? nextWall3DBoundaries.b1 : nextWall3DBoundaries.b2;
      }

      // console.log('k1', k1, 'b1', b1, 'x1', x1, 'k2', k2, 'b2', b2, 'x2', x2);

      if ( x1 && x2 ) {
        if ( currentWall3D.depth >= nextWall3D.depth ) {
          point.x = x1;
        } else {
          point.x = x2;
        }

        point.y = this.y;
      }
      if ( x1 && !x2 ) {
        point.x = x1;
        point.y = k2 * x1 + b2;
      }
      if ( x2 && !x1 ) {
        point.x = x2;
        point.y = k1 * x2 + b1;
      }

      if ( !x1 && !x2 ) {
        if ( ( k1 === 0 && k2 === 0 ) || ( Math.abs( k1 - k2 ) < 0.0001 ) ) {


          point.x = this.x - Math.max( currentWall3D.depth, nextWall3D.depth ) / 2 * Math.sin( angleCurrentWall3D );
          point.y = this.y + Math.max( currentWall3D.depth, nextWall3D.depth ) / 2 * Math.cos( angleCurrentWall3D );
          // console.log(this, point);
          /* (angleCurrentWall3D < Math.PI / 2 || angleCurrentWall3D >= 3 * Math.PI / 2) ?
            (this.y + Math.max(currentWall3D.depth, nextWall3D.depth) / 2) :
            (this.y - Math.max(currentWall3D.depth, nextWall3D.depth) / 2) */
        } else {
          point.x = ( b2 - b1 ) / ( k1 - k2 );
          point.y = k1 * point.x + b1;
        }
      }

      points.push( point );
      // add point to update wall shape
      if ( currentWall3D.from === this ) {
        let v = new Vector3( point.x, 0, point.y ).applyMatrix4( new Matrix4().getInverse( currentWall3D.matrixWorld ) );
        currentWall3D.shapePoints[ 0 ] = new Vector2( v.x, -v.z );// new Vector2(point.x - currentWall3D.from.x, -point.y + currentWall3D.from.y);
      }
      if ( currentWall3D.to === this ) {
        let v = new Vector3( point.x, 0, point.y ).applyMatrix4( new Matrix4().getInverse( currentWall3D.matrixWorld ) );
        currentWall3D.shapePoints[ 2 ] = new Vector2( v.x, -v.z );// new Vector2(point.x - currentWall3D.from.x, -point.y + currentWall3D.from.y);
      }
      if ( nextWall3D.from === this ) {
        let v = new Vector3( point.x, 0, point.y ).applyMatrix4( new Matrix4().getInverse( nextWall3D.matrixWorld ) );
        nextWall3D.shapePoints[ 1 ] = new Vector2( v.x, -v.z );// new Vector2(point.x - currentWall3D.from.x, -point.y + currentWall3D.from.y);
      }
      if ( nextWall3D.to === this ) {
        let v = new Vector3( point.x, 0, point.y ).applyMatrix4( new Matrix4().getInverse( nextWall3D.matrixWorld ) );
        nextWall3D.shapePoints[ 3 ] = new Vector2( v.x, -v.z );// new Vector2(point.x - currentWall3D.from.x, -point.y + currentWall3D.from.y);
      }
    }


    let pointsSub = [];
    for ( let i = 0; i < points.length; i += 1 ) {
      pointsSub.push( new Vector2( points[ i ].x - this.x, -points[ i ].y + this.y ) );
    }

    this.wallsMergingMesh.geometry = new ExtrudeGeometry(
      new Shape( pointsSub ),
      {
        steps: 1,
        depth: maxHeight,
        bevelEnabled: false
      }
    );
  }

  getPosition() {
    return new Vector2( this.position.x, this.position.z );
  }

  moveTo( x: number, z: number, modeOrtho: boolean, basicPosition: Vector2 ) {
    if ( modeOrtho ) {
      if ( Math.abs( x - basicPosition.x ) >= Math.abs( z - basicPosition.y ) ) {
        this.moveToXZ( x, basicPosition.y );
      } else {
        this.moveToXZ( basicPosition.x, z );
      }

    } else {
      this.moveToXZ( x, z );
    }

    this.rebuildGeometry();
    updateUISelectedObject();
  }

  moveToXZ( x: number, z: number ) {
    this.position.set( Math.round( x * 10000 ) / 10000, 0, Math.round( z * 10000 ) / 10000 );
    this.rebuildGeometry();

  }

  moveToX( x: number ) {
    console.log( 'x', x );
    this.moveTo( x, this.y, true, new Vector2( this.x, this.y ) );
    this.rebuildGeometry();
    this.stage.floorplan.rebuildGeometry();
  }

  moveToZ( z: number ) {
    console.log( 'z', z );
    this.moveTo( this.x, z, true, new Vector2( this.x, this.y ) );
    this.rebuildGeometry();
    this.stage.floorplan.rebuildGeometry();
  }

  shiftTo( x: number, z: number ) {
    this.moveTo( this.x + x, this.y + z, false, new Vector2( this.x, this.y ) );
    // this.position.x += x;
    // this.position.z += z;
    this.rebuildGeometry();
  }

  shiftToX( x: number ) {
    this.position.x += x;
    this.rebuildGeometry();
  }

  shiftToZ( z: number ) {
    this.position.z += z;
    this.rebuildGeometry();
  }

  delete() {
    const walls3D = this.getNeighbourWalls3D();
    const viewer = Storage.get( 'viewer' );

    for ( let i = 0; i < walls3D.length; i += 1 ) {
      walls3D[ i ].delete();
    }

    this.stage.removeCorner( this );
    this.stage.floorplan.rebuildGeometry();
    updateUISelectedObject( [null] );
    viewer.scene.dragDropManager.object3dF = null;
    viewer.renderOnDemand.set();


  }

}
