import { RadioSelect } from 'c/CalculationLayer/utils/RadioSelect';
import { connect } from 'react-redux';

interface _IYMovement {
  label: string;
  value: string;
  chosen: boolean;
}

class RadioSelectForMovement extends RadioSelect<_IYMovement> {}

export const YMovement = connect(
  (
    _, { chosenVariant, chooseVariant }:
      {
        chosenVariant: _IYMovement['value'];
        chooseVariant: RadioSelectForMovement['props']['chooseVariant'];
      }
  ) => {
    const variants: RadioSelectForMovement['props']['variants'] = [
      {
        label: 'On',
        value: 'on',
        chosen: chosenVariant === 'on'
      },
      {
        label: 'Off',
        value: 'off',
        chosen: chosenVariant === 'off'
      }
    ];

    return { chosenVariant, variants, chooseVariant };
  }
)( RadioSelectForMovement );
