/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { _ISimpleAction, _ISimpleSet, AllActions } from '@r/storeUtils';
import { simpleSet } from '@r/reducers/utils';

declare global {
  namespace redux {
    namespace store {
      interface _IModals {
        mainModalOpen: boolean;
        mainModalTab: 'newProject' | 'openProject';
      }
    }

    interface _IConstants {
      SET_MAIN_MODAL_OPEN_STATE: 'SET_MAIN_MODAL_OPEN_STATE';
      SET_MAIN_MODAL_TAB: 'SET_MAIN_MODAL_TAB';
    }

    interface _IActions {
      setMainModalOpen: _ISimpleAction<
        _IConstants['SET_MAIN_MODAL_OPEN_STATE'],
        _IStore['modals']['mainModalOpen']
      >;
      setMainModalTab: _ISimpleAction<
        _IConstants['SET_MAIN_MODAL_TAB'],
        _IStore['modals']['mainModalTab']
      >;
    }

    namespace reducers {
      namespace modals {
        type mainModalOpen = _ISimpleSet<
          _IConstants['SET_MAIN_MODAL_OPEN_STATE'],
          _IStore['modals']['mainModalOpen'],
          AllActions
        >;
        type mainModalTab = _ISimpleSet<
          _IConstants['SET_MAIN_MODAL_TAB'],
          _IStore['modals']['mainModalTab'],
          AllActions
        >
      }
    }
  }
}

const sForOpen = simpleSet as redux.reducers.modals.mainModalOpen;

export const mainModalOpen = sForOpen( 'SET_MAIN_MODAL_OPEN_STATE', true );

const sForTab = simpleSet as redux.reducers.modals.mainModalTab;

export const mainModalTab = sForTab( 'SET_MAIN_MODAL_TAB', 'newProject' );
