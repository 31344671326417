import React, { PureComponent } from 'react';
import style from './index.styl';
/**
 * @typedef {{
 *  type: string;
 *  onClick: () => void;
 *  inverse: boolean;
 *  minimalist: boolean;
 *  className: string;
 *  text: string;
 * }} Props
 */
/** @augments { PureComponent< Partial<Props> > } */
export default class ThemeButton extends PureComponent {
  /** @type { Partial<Props> } */
  static defaultProps = {
    disabled: false,
    type: 'button',
    onClick: () => {},
    className: '',
    minimalist: false,
    inverse: false,
    text: ''
  }

  render() {
    const {
      onClick = () => {},
      text,
      inverse,
      minimalist,
      disabled,
      className,
      children,
      type
    } = this.props;

    const buttonClasses = `${ style.btn } ${ inverse ? 'inverse' : '' }` +
      ` ${ className } ${ minimalist ? 'minimalist' : '' }`;

    return (
      <button
        type={ type }
        className={ buttonClasses }
        onClick={ onClick }
        disabled={ disabled }
      >
        { text || children }
      </button>
    );
  }

}
