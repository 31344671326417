// @ts-check
import React from 'react';
import ImageSelect from 'c/ImageSelect/index';
import { connect } from 'react-redux';
import styl from './index.styl';

const TemplateSelect = connect(
  ( /** @type { redux.store } */s ) => ( {
    value: s.projectFor3d.roomTemplate,
    optionClass: 'big',
    styles: {
      valueContainer: (
        /** @type { any } */ base
      ) => ( { ...base, height: '65px' } ),
      control: (
        /** @type { any } */ base
      ) => ( { ...base, borderRadius: '10px' } ),
      menuList: ( /** @type { any } */ base ) => ( {
        ...base, maxHeight: '180px', overflow: 'auto'
      } )
    }
  } ),
  (
    /**
     * @type { redux.d<redux.actions['setRoomTemplate']>}
     */
    d
  ) => ( {
    onChange: (
      /** @type { redux.store['projectFor3d']['roomTemplate'] } */value
    ) => d( { type: 'SET_ROOM_TEMPLATE', value } )
  } )
)( ImageSelect );

/**
 * @typedef {{
 *  floorplans: redux.store['projectFor3d']['roomTemplate'][];
 * }} Props
 */
/** @augments { React.PureComponent<Props> } */
export default class RoomTemplate extends React.PureComponent {
  /** @type { RoomTemplate['props'] } */
  static defaultProps = {
    floorplans: [
      {
        value: 'single',
        label: 'Single wall floor plan',
        baseMap: '/img/square.jpg'
      },
      {
        value: 'L-shape',
        label: 'L-Shape floor plan',
        baseMap: '/img/U-shape.jpg'
      },
      {
        value: 'U-shape',
        label: 'U-Shape floor plan',
        baseMap: '/img/U-shape.jpg'
      },
      {
        value: 'full',
        label: 'Rectangular floor plan',
        baseMap: '/img/square.jpg'
      }
    ]
  }

  render() {
    const { props: { floorplans } } = this;

    return (
      <div className={ styl.roomTemplate }>
        <p>Template for floor plan</p>
        <TemplateSelect options={ floorplans } />
      </div>
    );
  }
}
