// @ts-check
import React, { PureComponent } from 'react';

/**
 * @typedef {{
 *  onChange: (value: string) => void;
 *  value: string;
 *  className?: string;
 *  type?: string
 * }} Props
 */

/** @augments { PureComponent<Props>} */
export default class SimpleInput extends PureComponent {
  onChange = (
    /** @type { React.FormEvent<HTMLInputElement>} */{
      currentTarget: { value }
    } ) => this.props.onChange( value )

  onBlur = ( /** @type { React.FormEvent<HTMLInputElement>} */ ) => {
    const { onBlur } = this.props;
    if( onBlur ) {
      onBlur();
    }
  };

  onKeyDown = ( e ) => {
    const { onBlur } = this.props;
    if( e.keyCode === 13 && onBlur ) {
      onBlur();
    }
  };

  render() {
    const {
      props: {
        value, className: clN = '', type = 'text', ...rest
      },
      onChange,
      onBlur,
      onKeyDown
    } = this;
    let className = `${ clN } simpleInput`;

    return (
      <input { ...{
        ...rest, value, type, onChange, onBlur, className, onKeyDown
      } }
      />
    );
  }
}
