/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import {
  LabeledField
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledField';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { degrees } from '@r/reducers/storeParts/selectedCabinet/doorsAngle';
import styled from 'styled-components';
import Storage from 'scr/utilitiesStorage';
import {
  GenericCabinet
} from 'cSrc/structured/singletonCabinet/core/GenericCabinet';
import SimpleInput from 'c/CalculationLayer/utils/SimpleInput';
import CheckChangedValue from 'c/shared/borderForChangedValue';


interface _IStateProps {
  angle: redux._IStore['selectedCabinet']['doorsAngle'];
}

interface _IDispatchProps {
  dispatch: Dispatch<redux._IActions['SET_SELECTED_CABINET_DOORS_ANGLE']>;
  angle: redux._IStore['selectedCabinet']['doorsAngle'];
}

const Degrees = styled.span`
  margin-left: 10px;
`;
const Wrap = styled.div`
  display: flex;
  align-items: center;
`;
const Description = styled.span`font-size: 10px;`;

const StyledLabeledField = styled( LabeledField )`
  margin-top: 10px;
`;

export const DoorsAngle = connect<
  _IStateProps,
  _IDispatchProps,
  {},
  redux._IStore
>(
  ( s ) => ( { angle: s.selectedCabinet.doorsAngle } )
)(
  class DoorsAngle extends PureComponent<_IStateProps & _IDispatchProps> {
    // eslint-disable-next-line react/state-in-constructor
    state = {
      newAngle: 0
    };

    componentDidMount(): void {
      const { angle } = this.props;
      this.setState( {
        newAngle: angle
      } );
    }

    componentDidUpdate( prevProps: _IDispatchProps ): void {
      const { angle } = this.props;

      if( angle !== prevProps.angle ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState( {
          newAngle: angle
        } );
      }
    }

    onChange = () => {
      const { newAngle } = this.state;
      const naturalNumber = String( newAngle );
      const value = ( Number( naturalNumber ) % 91 ) as degrees;

      this.props.dispatch( {
        type: 'SET_SELECTED_CABINET_DOORS_ANGLE',
        value
      } );
      ( Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet )
        .openAllDoors( value * Math.PI / 180 );
      Storage.get( 'viewer' ).renderOnDemand.set();
    }

    handleValueChange = ( value: string ) => {
      const checkRegExp = /^\d*$/;
      const newValue = String( Number( value ) );
      if( newValue.match( checkRegExp ) ) {
        this.setState( {
          newAngle: newValue
        } );
      }
      if( !value ) {
        this.setState( {
          newAngle: 0
        } );
      }
    }

    render() {
      const { props: { angle }, onChange } = this;
      const { newAngle } = this.state;

      return (
        <CheckChangedValue changed={ angle !== newAngle }>
          <StyledLabeledField name='Doors Angle'>
            <div>
              <Wrap>
                <SimpleInput
                  className='door-angle-input'
                  value={ String( newAngle ) }
                  onChange={ this.handleValueChange }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                  // @ts-ignore
                  onBlur={ onChange }
                />
                <Degrees>degrees</Degrees>
              </Wrap>
              <Description>possible values from 0 to 90 degrees</Description>
            </div>
          </StyledLabeledField>
        </CheckChangedValue>
      );
    }
  }
);
