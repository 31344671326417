const calcLegsCost = ( {
  legPackagesQty,
  leg: { name, costPerPackage, labourUnit },
  fixLabourCost
} ) => {

  console.log( `Leg:
    name: ${ name }
    cost per package: ${ costPerPackage }
    labout unit: ${ labourUnit }
  ` );

  console.log( `Legs quantity from cabinet table: leg packages quantity * 4 = ${ legPackagesQty * 4 }` );

  const labourCostNotDoubled = Number(
    ( legPackagesQty * fixLabourCost * ( labourUnit ? Number( labourUnit ) : 0 ) )
      .toFixed( 2 )
  );

  console.log( `Labour cost: ${ labourCostNotDoubled }` );

  // doubled prices
  const labourCost = 2 * labourCostNotDoubled;

  const costNotDoubled = Number(
    ( legPackagesQty * Number( costPerPackage ) ).toFixed( 2 )
  );

  console.log( `Cost (without labour): ${ costNotDoubled }` );

  // doubled prices
  const cost = 2 * costNotDoubled;

  const totalCost = Number(
    ( cost + labourCost ).toFixed( 2 )
  );

  console.log( `Total cost: ${ totalCost }` );

  return {
    leg: {
      name,
      count: legPackagesQty * 4,
      cost: totalCost,
      costWithoutLabour: cost,
      totalLabourCost: labourCost
    },
    labourCost
  };
};

export default ( {
  legPackagesQty,
  toeKickType,
  leg,
  totalLegs,
  fixLabourCost
} ) => {

  if ( toeKickType !== 'legLeveler' || !legPackagesQty ) {
    return { newTotalLegs: totalLegs, legLabourCost: 0 };
  }

  const newTotalLegs = totalLegs.slice();

  const {
    leg: newLeg,
    labourCost: legLabourCost
  } = calcLegsCost( { legPackagesQty, leg, fixLabourCost } );

  const {
    name: newName,
    count: newCount,
    cost: newCost,
    costWithoutLabour: newCostWithoutLabour,
    totalLabourCost: newTotalLabourCost
  } = newLeg;

  const legExists = newTotalLegs
    .find( ( item ) => item.name === newName );

  if ( legExists ) {
    for ( let i = 0; i < newTotalLegs.length; i++ ) {
      const {
        name, cost, costWithoutLabour, totalLabourCost
      } = newTotalLegs[ i ];

      if ( name === newName ) {
        newTotalLegs[ i ].count += newCount;
        newTotalLegs[ i ].cost = Number( ( cost + newCost ).toFixed( 2 ) );
        newTotalLegs[ i ].costWithoutLabour = Number(
          ( costWithoutLabour + newCostWithoutLabour ).toFixed( 2 )
        );
        newTotalLegs[ i ].totalLabourCost = Number(
          ( totalLabourCost + newTotalLabourCost ).toFixed( 2 )
        );

        break;
      }
    }
  } else {
    newTotalLegs.push( newLeg );
  }

  return { newTotalLegs, legLabourCost };
};
