/**
 * Manager for once actions.
 */
export default class OnceActionsMgr {
	run() {
		if (this.actionRefreshWallHoles) {
			this.actionRefreshWallHoles.forEach(wall3d => {
				if (wall3d) wall3d.rebuildGeometry()
			});
			delete this.actionRefreshWallHoles;
		}
	}

	registerRefreshWallHoles(wall3d) {
		if (!this.actionRefreshWallHoles) this.actionRefreshWallHoles = [];
		if (!this.actionRefreshWallHoles.includes(wall3d)) this.actionRefreshWallHoles.push(wall3d);
	}
}