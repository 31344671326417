/* eslint-disable no-alert */

// @ts-check
import {
  generalLogin
  // validateAccountData
} from '@r/actions/utils';
import host from '../../containers/apiHost.json';
/*
 * import {
 *   loginSignupConstants,
 *   modalTabsConstants as mTC
 *   myProjectsConstants as mPC,
 *   kitchenRowsHeight as kRH,
 *   calculationModeConstants as cMC
 * } from './constants';
 */


export function simpleActionCreator (
  /** @type { keyof redux.constants } */type
) {
  return ( /** @type { any } */ value ) => ( { type, value } );
}

export function logout () {
  window.localStorage.removeItem( 'feathers-jwt' );
  window.location.reload();
}


export function loginRequest (
  /** @type { redux.actions.generalLoginArg['feathers'] } */feathers
) {
  return (
    /**
     * @type {  redux.thunkD<
     *  redux.store,
     *  {},
     *  redux.actions.user.setUserError
     * > }
     */dispatch,
    /** @type { () => redux._IStore } */getState
  ) => {
    const { user: { login, password } } = getState();
    if ( !login.trim() || !password.trim() ) {
      return;
    }

    dispatch( {
      type: 'SET_USER_LOGIN_ERROR',
      value: null
    } );

    /*
     * Signalize that we start the process of login
     * dispatch( simpleActionCreator( LOGIN_REQUEST )() );
     */

    dispatch( generalLogin( {
      feathers, strategy: 'local', login, password
    } ) )
      .catch( ( /** @type { Error } */ e ) => {
        if ( e.message === 'Invalid login' ) {
          dispatch( {
            type: 'SET_USER_LOGIN_ERROR',
            value: 'Invalid credentials'
          } );
        } else console.error( e );
      } );
  };
}

export function signupRequest (
  /** @type { redux.actions.generalLoginArg['feathers'] } */feathers
) {
  return (
    /** @type {  import('redux-thunk').ThunkDispatch<{}, {}, import('redux').AnyAction> } */dispatch,
    /** @type { () => redux._IStore } */getState
  ) => {
    const {
      user: {
        login, password, confirmPassword, fullName
      }
    } = getState();
    dispatch( {
      type: 'SET_USER_SIGNUP_ERROR',
      value: null
    } );

    if ( !login.trim() || !password.trim() ) {
      return void dispatch( {
        type: 'SET_USER_SIGNUP_ERROR',
        value: 'Email and password cannot be blank.'
      } );
    }

    if( confirmPassword !== password ) {
      return void dispatch( {
        type: 'SET_USER_SIGNUP_ERROR',
        value: 'Passwords are not equal.'
      } );
    }

    return void fetch( `${ host }signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify( { email: login, password, fullName } )
    } )
      .then( ( r ) => r.json() )
      .then( ( res ) => {
        if ( res.ok ) {
          return void dispatch( loginRequest( feathers ) );
        }
        if ( res.message.match( 'Email already registered' ) ) {
          dispatch( {
            type: 'SET_USER_SIGNUP_ERROR',
            value: res.message
          } );
        }


        return void 1;
      } );
  };
}

/*
 * export function accountUpdateRequest () {
 *   const {
 *     ACCOUNT_UPDATE_REQUEST, ACCOUNT_UPDATE_SUCCESS, SET_ACCOUNT_UPDATE_ERROR
 *   } = loginSignupConstants;
 */

/*
 *   return ( d, gS ) => {
 *     const {
 *       email, password, oldPassword, confirmPassword, fullName
 *     } = gS();
 */

/*
 *     const errorOrType = validateAccountData( {
 *       email, oldPassword, password, confirmPassword
 *     } );
 */

/*
 *     if ( errorOrType && !errorOrType.match( /^\_\_/ ) ) {
 *       return void d( { type: SET_ACCOUNT_UPDATE_ERROR, value: errorOrType } );
 *     }
 */

//     d( simpleActionCreator( ACCOUNT_UPDATE_REQUEST )() );

/*
 *     return void fetch( `${ host }updateProfile`, {
 *       method: 'POST',
 *       headers: {
 *         'Content-Type': 'application/json; charset=utf-8'
 *       },
 *       body: JSON.stringify( {
 *         email,
 *         newPassword: password,
 *         oldPassword,
 *         fullName,
 *         type: errorOrType
 *       } )
 *     } )
 *       .then( ( r ) => r.json() )
 *       .then( ( res ) => {
 *         if ( res.ok ) {
 *           return void d( simpleActionCreator( ACCOUNT_UPDATE_SUCCESS )( res.message ) );
 *         }
 */

/*
 *         d( { type: SET_ACCOUNT_UPDATE_ERROR, value: res.message } );
 *       } );
 *   };
 * }
 */

/*
 * export function accountModalClear () {
 *   return ( d, gS ) => {
 *     const value = !( gS().accountModalVisible );
 */

/*
 *     d( batchActions( [
 *       { type: mTC.SET_ACCOUNT_MODAL_VISIBLE, value: false },
 *       { type: mTC.SET_ACCOUNT_MODAL_VISIBLE, value }
 *     ] ) );
 *   };
 * }
 */

// export function saveProject ( feathersClient ) {
//   return async ( d, gS ) => {
//     /*
//      * Const s = blueprint3d.model.exportSerialized();
//      * return;
//      */

/*
 *     const { projectName, projectTag, projectId } = gS();
 *     to3D();
 */

/*
 *     await new Promise( ( r ) => {
 *       setTimeout( r, 1500 );
 *     } );
 */


/*
 *     const formData = new FormData();
 *     let xhr = new XMLHttpRequest();
 */

/*
 *     xhr.onreadystatechange = function () { // (3)
 *       if ( xhr.readyState != 4 || xhr.status != 200 ) {
 *         return;
 *       }
 */

//       const [img] = JSON.parse( xhr.responseText );

/*
 *       if ( projectId !== '' && projectId != null ) {
 *         return void feathersClient.service( 'api/projects' ).patch( projectId, {
 *           $set: {
 *             projectName,
 *             img,
 *             projectTag,
 *             json: blueprint3d.model.exportSerialized()
 *           }
 *         } )
 *           .then( ( r ) => {
 *             d( { type: mPC.MY_PROJECTS_UPDATE_ONE, project: r } );
 *             alert( 'Project updated.' );
 *           } )
 *           .catch( ( r ) => ( alert( 'Error when updating project!' ), console.error( r ) ) );
 *       }
 */

/*
 *       feathersClient.service( 'api/projects' ).create( {
 *         projectName,
 *         img,
 *         json: blueprint3d.model.exportSerialized(),
 *         projectTag
 *       } )
 *         .then( ( r ) => {
 *           d( batchActions( [
 *             { type: mTC.SET_PROJECT_ID, value: r._id },
 *             { type: mPC.MY_PROJECTS_ADD_ONE, project: r }
 *           ] ) );
 *           alert( 'Project created.' );
 *         } )
 *         .catch( ( r ) => ( alert( 'Error when creating project!' ), console.error( r ) ) );
 *     };
 */

/*
 *     const canvasBlob = await new Promise( ( r ) => {
 *       document.querySelectorAll( 'canvas' )[ 1 ].toBlob( r );
 *     } );
 */

//     formData.append( 'imagedata', canvasBlob, `${ projectName.trim().replace( /\s+/, '_' ) }.png` );

/*
 *     xhr.open( 'POST', `${ host }api/mediaFile` );
 *     xhr.send( formData );
 *   };
 * }
 */

/*
 * export function duplicateProject ( id, projectName ) {
 *   return ( d ) => {
 *     return feathersClient.service( 'api/projects' ).get( id )
 *       .then( ( r ) => {
 *         return feathersClient.service( 'api/projects' ).create( {
 *           projectName,
 *           projectTag: r.projectTag,
 *           img: r.img,
 *           json: r.json
 *         } );
 *       } )
 *       .then( ( project ) => ( d( { type: mPC.MY_PROJECTS_ADD_ONE, project } ), Promise.resolve() ) );
 *   };
 * }
 */

/*
 * export function updateProjectTag ( projectTag, id ) {
 *   return ( d, gS ) => {
 *     if ( id === null ) {
 *       return Promise.resolve();
 *     }
 */

//     d( { type: mPC.MY_PROJECTS_UPDATE_ONE_TAG, projectTag, id } );

/*
 *     return feathersClient.service( 'api/projects' ).patch( id, {
 *       $set: { projectTag }
 *     } );
 *   };
 * }
 */

/*
 * export function renameProject ( projectName, id ) {
 *   return ( d, gS ) => {
 *     d( { type: mPC.MY_PROJECTS_UPDATE_ONE_NAME, projectName, id } );
 */

/*
 *     return feathersClient.service( 'api/projects' ).patch( id, {
 *       $set: { projectName }
 *     } );
 *   };
 * }
 */

// export function createProject () {
//   return ( d, gS ) => {
//     /*
//      * Const projectObj = createNew( {
//      *   state: gS(), confHash
//      * } );
//      */

//     // Blueprint3d.model.loadSerialized( JSON.stringify( projectObj ) );

//     /*
//      * Return {
//      *   isLShape: gS().roomTemplate.value == options[ 3 ].value
//      * };
//      */
//   };
// }

/*
 * export function toggleAccountModal () {
 *   return ( d, gS ) => {
 *     const value = !( gS().accountModalVisible );
 *     if ( value ) {
 *       return d( { type: mTC.SET_ACCOUNT_MODAL_VISIBLE, value } );
 *     }
 */

/*
 *     d( batchActions( [
 *       { type: mTC.SET_ACCOUNT_MODAL_VISIBLE, value },
 *       { type: loginSignupConstants.SET_PASSWORD, value: '' },
 *       { type: loginSignupConstants.SET_CONFIRM_PASSWORD, value: '' },
 *       { type: loginSignupConstants.SET_OLD_PASSWORD, value: '' },
 *       { type: loginSignupConstants.ACCOUNT_UPDATE_CLEAR_UP }
 *     ] ) );
 *   };
 * }
 */

/*
 * export function removeProject ( id ) {
 *   return ( d ) => {
 *     return feathersClient.service( 'api/projects' ).remove( id )
 *       .then( ( r ) => ( d( { type: mPC.MY_PROJECTS_REMOVE_ONE, id } ), Promise.resolve() ) );
 *   };
 */

// }

/*
 * export function loadMyProjects () {
 *   return async ( d, gS ) => {
 *     const { data, $limit, hasMore } = gS().myProjects;
 */

//     if ( !hasMore ) return;

/*
 *     const res = await feathersClient.service( 'api/projects' ).find( {
 *       query: {
 *         $limit,
 *         $skip: data.length,
 *         $select: ['projectName', 'img', 'createdAt', 'projectTag']
 *       }
 *     } );
 */

/*
 *     if ( !res.data.length ) {
 *       return void d( { type: mPC.LOAD_MY_PROJECTS_NO_MORE } );
 *     }
 */

/*
 *     d( { type: mPC.LOAD_MY_PROJECTS_SUCCESS, data: res.data } );
 *   };
 * }
 */

/*
 * export function CalculationModeLoadCabinets () {
 *   return async ( dispatch, getState ) => {
 */

/*
 *   };
 * }
 */

/*
 * export function openProject ( id ) {
 *   return ( d, gS ) => {
 *     id = gS().myProjects.chosen || id;
 *     if ( !id ) return;
 */

/*
 *     feathersClient.service( 'api/projects' ).get( id )
 *       .then( ( res ) => {
 *         blueprint3d.model.loadSerialized( res.json );
 */

/*
 *         d( batchActions( [
 *           { type: mTC.SET_PROJECT_ID, value: res._id },
 *           { type: mTC.SET_PROJECT_NAME, value: res.projectName },
 *           { type: mTC.SET_PROJECT_TAG, value: res.projectTag },
 *           { type: mTC.SET_MAIN_MODAL_OPEN, value: false }
 *         ] ) );
 *       } );
 *   };
 * }
 */

/*
 * function emailOnlyForCalc ( email ) {
 *   const calcModeOnlyEmails = [
 *     'calc'
 *   ];
 */

/*
 *   return calcModeOnlyEmails.indexOf( email ) !== -1;
 * }
 */

/*
 * function emailOnlyForCalcPreparations ( dispatch ) {
 *   dispatch( { type: mTC.SET_CALCULATION_MODE, value: true } );
 *   dispatch( { type: cMC.SET_ONLY_CALCULATION_MODE, value: true } );
 *   dispatch( { type: mTC.SET_MAIN_MODAL_OPEN, value: false } );
 * }
 */

/*
 * export function checkEmailForCalcMode () {
 *   return ( d, gS ) => {
 *     const { email } = gS();
 */

//     if ( !emailOnlyForCalc( email ) ) return;

/*
 *     emailOnlyForCalcPreparations( d );
 *   };
 * }
 */

/*
 * export function calculationModeMaterialsSetPush () {
 *   return ( dispatch, globalState ) => {
 *     const { counter, keys, sets } = globalState().calculationModeProps.projectSettings.materialsSets;
 */

/*
 *     const newCounter = counter + 1;
 *     const duplicateSetIndex = sets.length - 1;
 *     const newSets = sets.slice().concat( { ...sets[ duplicateSetIndex ] } );
 *     const newKeys = keys.slice().concat( newCounter );
 */

/*
 *     dispatch( batchActions( [
 *       { type: cMC.SET_CALCULATION_MATERIAL_SETS_SETS, value: newSets },
 *       { type: cMC.SET_CALCULATION_MATERIAL_SETS_KEYS, value: newKeys },
 *       { type: cMC.SET_CALCULATION_MATERIAL_SETS_COUNTER, value: newCounter }
 *     ] ) );
 *   };
 * }
 */

/*
 * function clearCabinetMaterialsAfterSetRemoval ( { dispatch, globalState, key } ) {
 *   const { arr } = globalState().calculationModeProps.cabinets;
 *   for ( let i = 0; i < arr.length; i++ ) {
 *     if ( arr[ i ].setOfMaterials === key ) {
 *       arr[ i ].setOfMaterials = -1;
 *     }
 *   }
 */

/*
 *   dispatch( batchActions( [{
 *     type: cMC.SET_CALCULATION_CABINETS_ARR,
 *     value: arr.slice().map( ( it ) => JSON.parse( JSON.stringify( it ) ) )
 *   },
 *   {
 *     type: cMC.SET_CALCULATION_FILTERED_CABINETS_ARR,
 *     value: arr.slice().map( ( it ) => JSON.parse( JSON.stringify( it ) ) )
 *   }] ) );
 * }
 */

/*
 * export function calculationModeMaterialsSetRemove ( key ) {
 *   return ( dispatch, globalState ) => {
 *     const { keys, sets } = globalState().calculationModeProps.projectSettings.materialsSets;
 *     let index;
 */

/*
 *     const newKeys = keys.filter( ( item, i ) => {
 *       if ( key !== item ) return true;
 */

/*
 *       index = i;
 *     } );
 */

/*
 *     const newSets = sets.filter( ( it, i ) => i !== index );
 *     clearCabinetMaterialsAfterSetRemoval( { dispatch, globalState, key } );
 */

/*
 *     dispatch( batchActions( [
 *       { type: cMC.SET_CALCULATION_MATERIAL_SETS_SETS, value: newSets },
 *       { type: cMC.SET_CALCULATION_MATERIAL_SETS_KEYS, value: newKeys }
 *     ] ) );
 */

/*
 *     updateCabinetsCosts( { dispatch, globalState } );
 *   };
 * }
 */


/*
 * export function calculationModeMaterialsSetsUpdate ( { newObj, key } ) {
 *   return ( dispatch, globalState ) => {
 *     const { sets, keys } = globalState().calculationModeProps.projectSettings.materialsSets;
 *     const index = keys.indexOf( key );
 */

/*
 *     sets.splice( index, 1, newObj );
 *     const newSets = sets.slice();
 */

/*
 *     dispatch( batchActions( [
 *       { type: cMC.SET_CALCULATION_MATERIAL_SETS_SETS, value: newSets }
 *     ] ) );
 */

/*
 *     updateCabinetsCosts( { dispatch, globalState } );
 *   };
 * }
 */

/*
 * export function getCabinetsFrom3D () {
 *   return ( d, gS ) => {
 *     let cabinets = gatherCabinets(
 *       getBp().model.scene.getItems().filter( ( it ) => it._isKitchen )
 *     );
 *     if ( cabinets.length === 0 ) return;
 */

/*
 *     const {
 *       calculationModeProps: {
 *         projectSettings: { pulls, hinges, countertops },
 *         cabinets: { content }
 *       }
 *     } = gS();
 */

//     const cabinetsToTypes = {};
//     /*
//      * We create mapping { 'cabinet mongodb name' : 'cabinet mongodb object' }
//      * for fast access
//      */
//     const calculationCabinetsMap = content
//       .reduce(
//         ( a, { id, name, items } ) => {
//           /*
//            * But also we create mapping
//            * { 'cabinet mongodb name' : { cabinetType.id, cabinetType.name } }
//            */
//           const idAndName = { id, name };
//           items.forEach( ( it ) => cabinetsToTypes[ it.name ] = idAndName );

/*
 *           return a.concat( items );
 *         },
 *         []
 *       )
 *       .reduce( ( a, it ) => ( a[ it.name ] = it, a ), {} );
 */

/*
 *     const mapping = {
 *       ClassicBaseCabinet: '1 Door Base',
 *       SingleDoorBaseCabinet: '1 Door 1 Drawer Base',
 *       TwoDoorBaseCabinet: '2 Door Base',
 *       OneDrawerTwoDoorBaseCabinet: '2 Door 1 Drawer Base',
 *       TwoDrawersTwoDoorBaseCabinet: '2 Door 2 Drawer Base',
 *       TwoDrawersBaseCabinet: '2 Drawer Base',
 *       ThreeDrawersBaseCabinet: '3 Drawer Base',
 *       ClassicWallCabinet: '1 Door Upper',
 *       HorizontallWallCabinet: 'Upper Starter Horizontal',
 *       DoubleDoorWallCabinet: '2 Door Upper',
 *       SingleDoorCabinet: '1 Door Tall',
 *       DoubleDoorUtilityCabinet: '2 Double Door Tall'
 *     };
 */

/*
 *     cabinets = cabinets
 *       .map( ( it ) => it.toJSON() )
 *       .filter( ( it ) => mapping[ it.name ] )
 *       .map( ( it ) => ( it.name = mapping[ it.name ], it ) );
 */

/*
 *     cabinets.forEach( ( cab ) => {
 *       d( calculationModalCabinetsPush( {
 *         ...calculationCabinetsMap[ cab.name ],
 *         pull: pulls,
 *         hinge: hinges,
 *         countertop: countertops,
 *         category: cabinetsToTypes[ cab.name ].name,
 *         categoryId: cabinetsToTypes[ cab.name ].id,
 *         width: rUM.b( cab.itemMock.w ),
 *         depth: rUM.b( cab.itemMock.d ),
 *         height: rUM.b( cab.itemMock.h )
 *       } ) );
 *     } );
 *   };
 * }
 */

/*
 * export function calculationModeCreateProject () {
 *   return ( d, gS ) => {
 *     // Console.log('PROJS state:', gS())
 *   };
 * }
 */
