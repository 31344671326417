import { inches } from '@npmrost/utils/lib';

// (\d+\.\d+|\d+)
// $1 as inches
export const CornerBase:
  Partial<CabD.A.ContainerConfigs.ContainerConfig> = {
    height: 36 as inches,
    depth: 36 as inches,
    width: 36 as inches,
    name: 'Corner Base',
    constrName: 'CornerWithCountertop',
    isTopLevel: true,
    children: [
      {
        name: 'ToeKickAssembly',
        layout: 'DEPTH',
        height: 3 as inches,
        children: [
          {
            name: 'ToeKickFinishEnds',
            layout: 'WIDTH',
            children: [
              {
                width: 0.5 as inches,
                type: 'Panel',
                name: 'ToeKickAssembly--LeftPanel'
              },
              {
                name: 'ToeKickAssembly--HollowCenter'
              }
            ]
          },
          {
            name: 'ToeKickAssembly--FrontPanel',
            type: 'Panel',
            depth: 0.5 as inches
          },
          {
            name: 'ToeKickOtherEnds',
            layout: 'WIDTH',
            depth: 14 as inches,
            children: [
              {
                name: 'ToeKickAssembly--HollowFront',
                width: 14 as inches
              },
              {
                width: 0.5 as inches,
                type: 'Panel',
                name: 'ToeKickAssembly--LeftPanel'
              },
              {
                name: 'RightPart',
                layout: 'DEPTH',
                children: [
                  {
                    name: 'ToeKickAssembly--HollowCenter'
                  },
                  {
                    depth: 0.5 as inches,
                    type: 'Panel',
                    name: 'ToeKickAssembly--RightPanel'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        name: 'BaseAssembly',
        layout: 'DEPTH',
        children: [
          {
            name: 'BaseLeftSide',
            layout: 'WIDTH',
            children: [
              {
                width: 0.5 as inches,
                type: 'Panel',
                name: 'CornerLeftSide'
              },
              {
                name: 'HollowCenterBody'
              }
            ]
          },
          {
            name: 'FrontExteriorAssembly',
            layout: 'WIDTH',
            depth: 0.5 as inches,
            children: [
              {
                name: 'LeftDoorOverlay',
                width: 0.25 as inches
              },
              {
                name: 'FrontAssembly',
                children: [
                  {
                    name: 'BottomReveal',
                    height: 0.25 as inches
                  },
                  {
                    handleOrientation: 'VERTICAL',
                    handlePosition: {
                      oX: {
                        type: 'toLeft',
                        value: 3 as inches
                      },
                      oY: {
                        type: 'toTop',
                        value: 3 as inches
                      },
                      oZ: 'onSurface'
                    },
                    type: 'Door',
                    name: 'Door--DoorPanel'
                  },
                  {
                    name: 'TopReveal',
                    height: 0.25 as inches
                  }
                ]
              },
              {
                name: 'RightDoorOverlay',
                width: 0.25 as inches
              }
            ]
          },
          {
            name: 'ToeKickOtherEnds',
            layout: 'WIDTH',
            depth: 12 as inches,
            children: [
              {
                name: 'BodyHollowFront',
                width: 12 as inches
              },
              {
                width: 0.5 as inches,
                name: 'LeftDoor',
                layout: 'DEPTH',
                children: [
                  {
                    name: 'LeftDoorOverlay',
                    depth: 0.25 as inches
                  },
                  {
                    name: 'FrontAssembly',
                    children: [
                      {
                        name: 'BottomReveal',
                        height: 0.25 as inches
                      },
                      {
                        type: 'Panel',
                        name: 'DoorPanel'
                      },
                      {
                        name: 'TopReveal',
                        height: 0.25 as inches
                      }
                    ]
                  },
                  {
                    name: 'RightDoorOverlay',
                    depth: 0.25 as inches
                  }
                ]
              },
              {
                name: 'RightPart',
                layout: 'DEPTH',
                children: [
                  {
                    name: 'ToeKickAssembly--HollowCenter'
                  },
                  {
                    depth: 0.5 as inches,
                    type: 'Panel',
                    name: 'ToeKickAssembly--RightPanel'
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    countertopMaterialName: 'marblewhite005'
  };
