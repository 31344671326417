/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PureComponent } from 'react';
import Storage from 'scr/utilitiesStorage';
import { connect } from 'react-redux';
import { Dimensions } from 'c/Object3dModal/Info/Dimensions';
import styled from 'styled-components';
import { Dispatch } from 'redux';
import { degrees } from '@r/reducers/storeParts/selectedCabinet/doorsAngle';
import { batchActions, BatchAction } from 'redux-batched-actions';
import { batchActionsWithTempl } from '@r/storeUtils';
import { cabinetConfig } from 'decl/cabinetdesigner/ContainerConfigs.d.ts';
import {
  GenericCabinet
} from 'cSrc/structured/singletonCabinet/core/GenericCabinet';
import {
  Filler
} from 'c/CabinetRightSideMenu/CabinetMenuWidgets/General/Filler';
import {
  FinishEnd
} from 'c/CabinetRightSideMenu/CabinetMenuWidgets/General/FinishEnd';
import style from '../index.styl';
import { AdditionalSettings } from './AdditionalSettings';
import { DoorsAngle } from './DoorsAngle';
import Name from './Name';
import { findShelvesQty, ShelvesQty } from './ShelvesQty';
import { findDoorSwing, Swing } from './Swing';
import { CrownMolding } from './Upper/CrownMolding';


const InputsWrap = styled.div`
  overflow: auto;
  max-height: calc(100vh - 200px);
  padding: 5px;
`;

const Header = styled.h3`
  font-weight: 400;
  text-align: center;
`;

interface _IProps {
  units: redux._IStore['projectFor3d']['units'];
}

interface _IDispatchProps {
  dispatch: Dispatch< BatchAction >;
}

const batch = batchActions as batchActionsWithTempl<
  redux._IActions['SET_SELECTED_CABINET_DOORS_ANGLE'] |
  redux._IActions['SET_SELECTED_CABINET_SHELVES_QTY'] |
  redux._IActions['SET_SELECTED_CABINET_SWING']
>;

export default connect<_IProps, never, any, redux._IStore>(
  ( s ) => ( { units: s.projectFor3d.units } )
)(
  class General extends PureComponent<_IProps & _IDispatchProps> {
    viewer = Storage.get( 'viewer' );

    constructor( p: General['props'] ) {
      super( p );

      const cabinet = this.getCabinet();
      if( cabinet ) {
        const config = cabinet.getConfig();

        const angleFrom0ToPi2InRadians = cabinet.getDoorsAngle();
        const swingArr = findDoorSwing( cabinet.getConfig() as cabinetConfig );

        this.props.dispatch(
          batch( [
            {
              type: 'SET_SELECTED_CABINET_DOORS_ANGLE',
              value: ( angleFrom0ToPi2InRadians * 180 / Math.PI ) as degrees
            },
            {
              type: 'SET_SELECTED_CABINET_SHELVES_QTY',
              value: findShelvesQty( config as cabinetConfig )
            },
            {
              type: 'SET_SELECTED_CABINET_SWING',
              value: swingArr.length === 1 ? swingArr[ 0 ] : null
            }
          ] )
        );
      }
    }

    getCabinet() {
      const [cabinet] = Storage.get( 'selectedObject' );
      if( cabinet === null ) {
        throw new Error( 'Selected cabinet is null.' );
      }

      return cabinet as CabD.A.GenericCabinet;
    }

    getConfig() {
      return this.getCabinet().getConfig() as
        CabD.A.ContainerConfigs.ContainerConfig;
    }

    toLeft = () => {
      this.getCabinet().fitByWidthToLeft();
    };

    toCenter = () => {
      this.getCabinet().centerByWidthToSpace();
    };

    toRight = () => {
      this.getCabinet().fitByWidthToRight();
    };

    fill = () => {
      this.getCabinet().fitByWidthToSpace();
    };

    render() {

      const cabinet = Storage.get( 'selectedObject' )[ 0 ] as GenericCabinet;
      let type = '';
      if( cabinet ) {
        type = cabinet.getType();
      } else {
        return null;
      }

      return (
        <div className={ style.general }>
          <InputsWrap>
            <h3>Name</h3>
            <Name />
            <h3>Dimensions</h3>
            <Dimensions />
            <DoorsAngle />
            <ShelvesQty />
            <Swing />
            <AdditionalSettings />
            { type === 'tall' &&
            (
              <>
                <Header>Crown molding</Header>
                <CrownMolding />
                <Filler />
                <FinishEnd />
              </>
            ) }
          </InputsWrap>
        </div>
      );
    }
  }
);
