import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ThunkDispatch } from 'redux-thunk';
import { loadProjectsFor3d, _IProject3d } from '@r/reducers/projectsFor3d.ts';
import { MongoObjectId } from '@npmrost/utils';
import Button from 'c/shared/ThemeButton';
import { loadProject } from '@r/reducers/projectsFor3d_loadProject';
import { Project } from './Project';

interface _IProps {
  projects: redux._IStore['projectsFor3d'];
  hidden: boolean;
}

interface _IDispatchProps {
  dispatch: ThunkDispatch<redux._IStore, {}, redux._IActions['SET_PROJECTS_FOR_3D']>;
}

interface _IState {
  search: string;
  selected: null | MongoObjectId;
  tags: Array<_IProject3d['projectTag']>;
}

const Wrap = styled.div<{ hidden?: boolean }>`
  ${ ( p ) => ( p.hidden ? 'display: none;' : '' ) }
  margin-top: 20px;
  align-items: center;
  flex-direction: column;

`;

const Loader = styled.h3<{ hidden?: boolean }>`
  ${ ( p ) => ( p.hidden ? 'display: none;' : '' ) }
`;

const ProjectsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  max-height: 500px;
  overflow: auto;
  padding: 5px;
`;

const Search = styled.input`
  border-radius: 3px;
  border: 1px solid rgba(0,0,0,0.3);
  padding: 3px 10px;
  flex-grow: 1;
  box-sizing: border-box;
  &:active, &:focus {
    outline: none;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;

class ProjectsWithSearch extends PureComponent<_IProps & _IDispatchProps, _IState> {
  constructor( p: ProjectsWithSearch['props'] ) {
    super( p );
    this.state = {
      search: '',
      selected: null,
      tags: ['completed', 'estimating', 'inProgress']
    };
  }

  componentDidMount() {
    this.props.dispatch( loadProjectsFor3d() );
  }

  updateSearch = ( e: React.FormEvent<HTMLInputElement> ) => {
    this.setState( { search: e.currentTarget.value } );
  }

  selectProject = ( id: MongoObjectId ) => {
    this.setState( ( state ) => ( {
      selected: state.selected === id ? null : id
    } ) );
  }

  openProject = () => {
    if( this.state.selected === null ) return;

    this.props.dispatch( loadProject( this.state.selected ) );
  }

  render() {
    const {
      props: { projects, hidden },
      state: { search, tags, selected },
      updateSearch,
      selectProject,
      openProject
    } = this;

    if( projects.state === 'loading' ) {
      return <Loader hidden={ hidden }>Loading...</Loader>;
    }

    const filtered = projects.projects
      .filter( ( p ) => p.projectName.match( new RegExp( search, 'i' ) ) )
      .filter( ( p ) => tags.indexOf( p.projectTag ) !== -1 );


    return (
      <Wrap hidden={ hidden }>
        <Search placeholder='search by project title...' type='text' value={ search } onChange={ updateSearch } />
        {
          projects.projects.length === 0 ? <h3>You don`t have any projects</h3>
            : filtered.length === 0
              ? <h3>No projects with such criteria</h3>
              : (
                <ProjectsWrap>
                  {
                    filtered
                      .map( ( project ) => (
                        <Project { ...{
                          key: project._id,
                          project,
                          selected: selected === project._id,
                          select: selectProject
                        } }
                        />
                      ) )
                  }
                </ProjectsWrap>
              )
        }
        <BtnWrap>
          <Button onClick={ openProject }>Open</Button>
        </BtnWrap>
      </Wrap>
    );
  }
}

export const ProjectsWithSearchConnected = connect<_IProps, _IDispatchProps, {}, redux._IStore>(
  ( s ) => ( {
    projects: s.projectsFor3d,
    hidden: s.modals.mainModalTab === 'newProject'
  } )
)( ProjectsWithSearch );

// import style from './index.styl';
// import Projects from "./Projects.jsx";
// import { projectTags as tags } from '@r/actions/constants.js';
// import { connect } from 'react-redux';
// import { simpleActionCreator as sAC, openProject } from '@r/actions/index.js';
// import {
//   modalTabsConstants as mTC,
//   myProjectsConstants as mPC
// } from '@r/actions/constants.js';
// import { getSearchSel } from '@r/selectors/myProjects.js';

// const ConnectedSearch = connect(
//  s => ({ search: getSearchSel(s) }),
//  { updateSearch: sAC(mPC.SET_MY_PROJECTS_SEARCH) }
// )(
//   class SearchInput extends React.PureComponent{
//     onChange = ({ target: { value }}) => {
//       this.props.updateSearch(value);
//     }

//     render(){
//       const { onChange, props: { search } } = this;

//       return(
//         <input
//           type="text" name="vestaProjectsSearch"
//           placeholder="search by project title..."
//           id="projectSearch"
//           value={ search } onChange={ onChange }
//         />
//       )
//     }
//   }
// )

// const ConnectedTags = connect(
//   ({ myProjects: { filters } }) => ({ filters }),
//   {
//     toggleTag: sAC(mPC.TOGGLE_MY_PROJECTS_FILTER)
//   }
// )(
//   class Tags extends React.PureComponent{
//     toggleTagFilter = ({ currentTarget: { dataset: { tag }}}) => {
//       this.props.toggleTag(tag);
//     }

//     render(){
//       const { props: { filters }, toggleTagFilter } = this;

//       return(
//         Object.keys(tags).map(i =>
//           <div
//             key={ tags[i] }
//             data-tag={ tags[i] }
//             onClick={ toggleTagFilter }
//             className={ filters.indexOf(tags[i]) != -1 ? "active" : "" }
//             >
//             <span>{ tags[i] }</span>
//           </div>
//         )
//       );
//     }
//   }
// )

// export default connect(
//   s => ({  }),
//   { openProject }
// )(class MyProjects extends React.PureComponent {
//   openProject = () => {
//     this.props.openProject();
//   }

//   render(){
//     const { props: { hidden }} = this;

//     return (
//       <div style={ hidden ? { display: "none" } : {} } className={ style.myProjects }>
//         <div className="topRow">
//           <ConnectedSearch />
//           <ConnectedTags />
//         </div>

//         <Projects />

//         <div className="openButton">
//           <Button onClick={ this.openProject }>Open</Button>
//         </div>
//       </div>
//     );
//   }
// })
