import * as THREE from 'three';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';

const CurveDefaultMaterial = new THREE.LineBasicMaterial({
    color: 'rgb(0, 0, 0)',
    opacity: 1,
    // vertexColors: THREE.VertexColors,
    depthTest: false,
    depthWrite: false,
})
const CurveDefaultMaterialEx = new LineMaterial({
    color: 'rgb(0, 0, 0)',
    opacity: 1,
    // vertexColors: THREE.VertexColors,
    depthTest: false,
    depthWrite: false,

    linewidth: 2, // in pixels
    // resolution:  // to be set by renderer, eventually

    // dashed: false,
    // dashScale: 1,
    // dashSize: 1,
    // gapSize: 1,
});
if (CurveDefaultMaterialEx.dashed) CurveDefaultMaterialEx.defines.USE_DASH = ""; else delete CurveDefaultMaterialEx.defines.USE_DASH;
// CurveDefaultMaterialEx.needsUpdate = true;

const CurveHighlightMaterial = new LineMaterial({
    color: '#0000ff',
    opacity: 1,
    // vertexColors: THREE.VertexColors,
    depthTest: false,
    depthWrite: false,

    linewidth: 2, // in pixels
    // resolution:  // to be set by renderer, eventually

    dashed: true,
    dashScale: 1,
    dashSize: 0.1,
    gapSize: 0.1,
});
if (CurveHighlightMaterial.dashed) CurveHighlightMaterial.defines.USE_DASH = ""; else delete CurveHighlightMaterial.defines.USE_DASH;
// CurveHighlightMaterial.needsUpdate = true;

export {CurveDefaultMaterial, CurveDefaultMaterialEx, CurveHighlightMaterial};