/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { batchActionsWithTempl, d } from '@r/storeUtils';
import { unitMapping } from 'scr/metricsConversion';
import { batchActions } from 'redux-batched-actions';
import {
  UnitsInputValue, UnitsInput
} from '@npmrost/storybook/stories/Vesta/Inputs/UnitsInput';
// import { quantity } from '@npmrost/utils';
import {
  LabeledField
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledField';
import { Triplet } from '@npmrost/storybook/stories/Vesta/Triplet';
// import {
//   NaturalInput
// } from '@npmrost/storybook/stories/Vesta/Inputs/NaturalInput';
import {
  updateCabinetsSettings
} from '@r/reducers/storeParts/calculationMode/projects/currentProject/cabinets';

type a = redux._IActions;

type labelActionTypes = a['setCalculationModeBaseCabinetHeightLabel']['type'] |
  a['setCalculationModeBaseCabinetDepthLabel']['type'] |
  a['setCalculationModeCurrentProjectBaseCabinetHeightLabel']['type'] |
  a['setCalculationModeCurrentProjectBaseCabinetDepthLabel']['type'];

type valueActionTypes = a['setCalculationModeBaseCabinetHeightValue']['type'] |
  a['setCalculationModeBaseCabinetDepthValue']['type'] |
  a['setCalculationModeCurrentProjectBaseCabinetHeightValue']['type'] |
  a['setCalculationModeCurrentProjectBaseCabinetDepthValue']['type'];

type batchAction = batchActionsWithTempl<
  a['setCalculationModeBaseCabinetHeightLabel'] |
  a['setCalculationModeBaseCabinetHeightValue'] |
  a['setCalculationModeBaseCabinetDepthLabel'] |
  a['setCalculationModeBaseCabinetDepthValue'] |
  a['setCalculationModeBaseCabinetShelvesQty'] |
  a['setCalculationModeCurrentProjectBaseCabinetHeightLabel'] |
  a['setCalculationModeCurrentProjectBaseCabinetHeightValue'] |
  a['setCalculationModeCurrentProjectBaseCabinetDepthLabel'] |
  a['setCalculationModeCurrentProjectBaseCabinetDepthValue'] |
  a['setCalculationModeCurrentProjectBaseCabinetShelvesQty']
>;

interface _IProps {
  texts: redux._IStore['texts']['calculationMode'];
  settings: redux._IStore['calculationMode']['settings']['dimensions'];
  units: redux._IStore['calculationMode']['settings']['information']['units'];
  currentProject: boolean;
  adminMode: boolean;
  dispatch: d<ReturnType<batchAction>>;
}

class Base extends PureComponent<_IProps> {

  changeMetric = (
    propName: string,
    labelAction: labelActionTypes,
    valueAction: valueActionTypes
  ) => ( value: UnitsInputValue ) => {
    const { units, dispatch, currentProject } = this.props;

    const val = units === 'ftAndInch'
      ? unitMapping.ftAndInch( value )
      : unitMapping[ units ]( Number( value ) );

    dispatch(
      ( batchActions as batchAction )( [
        { type: labelAction, value },
        { type: valueAction, value: val }
      ] )
    );

    if ( currentProject ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      dispatch( updateCabinetsSettings(
        propName,
        { label: value, value: val },
        'base'
      ) );
    }
  }

  // changeQty = ( n: string ) => {
  //   const { currentProject, adminMode, dispatch } = this.props;
  //   const value = Number( n );

  //   const typeText = currentProject
  //     ? '_CURRENT_PROJECT'
  //     : adminMode
  //       ? '_ADMIN_SETTINGS'
  //       : '';

  //   dispatch(
  //     ( batchActions as batchAction )( [
  //       {
  //         type: `SET_CALCULATION_MODE${ typeText }_BASE_CABINET_SHELVES_QTY`,
  //         value: value as quantity
  //       }
  //     ] )
  //   );

  //   if ( currentProject ) {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //     // @ts-ignore
  //     dispatch( updateCabinetsSettings(
  //       'shelvesQty',
  //       value,
  //       'base'
  //     ) );
  //   }
  // }

  render() {
    const {
      props: {
        texts: {
          dimensions: {
            baseCabinet: baseCabinetText
          }
        },
        settings: {
          cabinets: {
            base: {
              height: { label: hLabel },
              depth: { label: dLabel }
              // shelvesQty
            }
          }
        },
        currentProject,
        adminMode
      },
      changeMetric
      // changeQty
    } = this;

    const typeText = currentProject
      ? '_CURRENT_PROJECT'
      : adminMode
        ? '_ADMIN_SETTINGS'
        : '';

    return (
      <>
        <LabeledField name={ baseCabinetText }>
          <Triplet triple={ [
            <UnitsInput
              value={ hLabel }
              onChange={ changeMetric(
                'height',
                `SET_CALCULATION_MODE${ typeText }_BASE_CABINET_HEIGHT_LABEL`,
                `SET_CALCULATION_MODE${ typeText }_BASE_CABINET_HEIGHT_VALUE`
              ) }
            />,
            <UnitsInput
              value={ dLabel }
              onChange={ changeMetric(
                'depth',
                `SET_CALCULATION_MODE${ typeText }_BASE_CABINET_DEPTH_LABEL`,
                `SET_CALCULATION_MODE${ typeText }_BASE_CABINET_DEPTH_VALUE`
              ) }
            />,
            <></>
            // <NaturalInput
            //   value={ String( shelvesQty ) }
            //   onChange={ changeQty }
            // />
          ] }
          />
        </LabeledField>
      </>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: calculationModeTexts
    }
  }: redux._IStore ): Omit<
    _IProps,
    'settings' | 'units' | 'currentProject' | 'dispatch' | 'adminMode'
  > => {
    return {
      texts: calculationModeTexts
    };
  }
)( Base );
