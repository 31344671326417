import React, { PureComponent } from 'react';
import style from './index.styl';

interface _IProps {
  children: any;
  changed: boolean;
}

class CheckChangedValue extends PureComponent<_IProps> {
  render() {
    const { children, changed } = this.props;
    const cl = changed ? style.changedValue : '';

    return(
      <div className={ cl }>
        { children }
      </div>
    );
  }
}

export default CheckChangedValue;
