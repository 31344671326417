import { combineReducers } from 'redux';

import { value } from './value';
import { options } from './options';
import { searchText } from './searchText';

export const hinges = combineReducers( {
  value,
  options,
  searchText
} );
