import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  PercentInput
} from '@npmrost/storybook/stories/Vesta/Inputs/PercentInput';
import {
  TextInput
} from '@npmrost/storybook/stories/Vesta/Inputs/TextInput';
import { Close } from '@npmrost/storybook/stories/Vesta/Icons';

import {
  addItem, updateItem, removeItem
} from '@r/reducers/storeParts/calculationMode/adminSettings/projectSettings/percentageLoss';

const Wrapper = styled.div`
  margin-left: 30px;
  /* height: 100%; */
`;

const StyledPerfectScrollbar = styled( PerfectScrollbar )`
  max-height: 250px;
`;

const Content = styled.div`
  width: 350px;
  padding: 20px;
  ${ ( { theme: t } ) => `
    background-color: ${ t.arraySetsBackground };
    border-radius: ${ t.borderRadius };
  ` }
`;

const Title = styled.span`
  ${ ( { theme: t } ) => `
    color: ${ t.darkGreyColor };
  ` }
`;

const PlusWrapper = styled.div`
  width: 350px;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  svg {
    transform: rotate(45deg);
  }
  ${ ( { theme: t } ) => `
    background-color: ${ t.arraySetsBackground };
    border-radius: ${ t.borderRadius };
  ` }
`;

const RowsWrapper = styled.div`
  margin-top: 15px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const StyledTextInput = styled( TextInput )`
  width: 65%;
`;

const StyledPercentInput = styled( PercentInput )`
  width: 30%;
  margin-left: 10px;
`;

const CloseWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

class PercentageLoss extends PureComponent {

  add = () => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.dispatch( addItem() );
  }

  update = ( key, propName ) => ( value ) => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.dispatch( updateItem( key, propName, value ) );
  }

  remove = ( e ) => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.dispatch( removeItem( Number( e.currentTarget.dataset.key ) ) );
  }

  render() {
    const {
      props: {
        percentageLossTitle,
        percentageLossNamePlaceholder,
        percentageLossValuePlaceholder,
        percentageLoss: {
          data,
          keys
        }
      },
      add,
      update,
      remove
    } = this;

    return (
      <Wrapper>
        <StyledPerfectScrollbar>
          <Content>
            <Title>{ percentageLossTitle }</Title>
            <RowsWrapper>
              {
                data.map( ( { name, value: { label } }, index ) => (
                  <Row key={ keys[ index ] }>
                    <StyledTextInput
                      placeholder={ percentageLossNamePlaceholder }
                      value={ name }
                      onChange={ update( keys[ index ], 'name' ) }
                    />
                    <StyledPercentInput
                      placeholder={ percentageLossValuePlaceholder }
                      value={ label }
                      onChange={ update( keys[ index ], 'value' ) }
                    />
                    <CloseWrapper
                      data-key={ keys[ index ] }
                      onClick={ remove }
                    >
                      { Close }
                    </CloseWrapper>
                  </Row>
                ) )
              }
            </RowsWrapper>
          </Content>
        </StyledPerfectScrollbar>
        <PlusWrapper>
          <span onClick={ add }>
            { Close }
          </span>
        </PlusWrapper>
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        adminMode: {
          percentageLossTitle,
          percentageLossNamePlaceholder,
          percentageLossValuePlaceholder
        }
      }
    },
    calculationMode: {
      adminSettings: {
        projectSettings: {
          percentageLoss
        }
      }
    }
  } ) => {
    return {
      percentageLossTitle,
      percentageLossNamePlaceholder,
      percentageLossValuePlaceholder,
      percentageLoss
    };
  }
)( PercentageLoss );
