// @ts-check
import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { upper } from './upper';
import { base } from './base';
import { tall } from './tall';
import { defaultMaterial } from './defaultMaterialForCrownMolding';
import { defaultShape } from './defaultShapeForCrownMolding';
import { isPresented } from './isPresentedCrownMolding';

const cabinetsSettingsComb = combineReducers as combRed<
  redux._IStore['projectFor3d']['cabinetsSettings'],
  AllActions
>;

export const cabinetsSettings = cabinetsSettingsComb( {
  upper,
  base,
  tall,
  defaultMaterial,
  defaultShape,
  isPresented
} );
