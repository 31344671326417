/* eslint-disable react/no-danger */
import React from 'react';
import { connect } from 'react-redux';
import { batchActions as b } from 'redux-batched-actions';
import Storage from 'scr/utilitiesStorage';
import style from './index.styl';

class Circle extends React.PureComponent {
  render() {
    const { value } = this.props;

    return (
      <div className={ `circle ${ value ? 'checked' : '' }` } />
    );
  }
}

class ViewMenu extends React.PureComponent {
  rightAndCornerMenuVisibility = ( value, viewMode ) => {
    this.props.dispatch(
      b( [
        { type: 'SET_GENERAL_VIEW_MODE', value: viewMode },
        { type: 'SET_CORNER_MENU_VISIBLE', value },
        { type: 'SET_RIGHT_SIDE_CABINET_MENU_OPEN', value }
      ] )
    );
  }

  switchTo3d = () => {
    if ( this.props.generalViewMode === '3d' ) {
      return;
    }

    this.rightAndCornerMenuVisibility( true, '3d' );

    const viewer = Storage.get( 'viewer' );
    viewer.useOrbitControls();
  }

  switchToCalculationMode = () => {
    if ( this.props.generalViewMode === 'calculation' ) {
      return;
    }

    this.props.dispatch( b( [
      { type: 'SET_GENERAL_VIEW_MODE', value: 'calculation' },
      { type: 'SET_CORNER_MENU_VISIBLE', value: false },
      { type: 'SET_RIGHT_SIDE_CABINET_MENU_OPEN', value: false }
    ] ) );
  }

  render() {
    const {
      props: {
        hidden,
        generalViewMode,
        viewOptions: {
          '3d': view3d,
          calculationMode: viewCalculationMode
        }
      },
      switchTo3d,
      switchToCalculationMode
    } = this;

    return (
      <>
        <div
          style={ hidden ? { display: 'none' } : {} }
          className={ style.viewMenu }
        >

          <div>

            <div className='viewRow' onClick={ switchTo3d }>
              <Circle value={ generalViewMode === '3d' } />
              { view3d }
            </div>

            <div className='viewRow' onClick={ switchToCalculationMode }>
              <Circle value={ generalViewMode === 'calculation' } />
              { viewCalculationMode }
            </div>

          </div>

        </div>
      </>
    );
  }
}

export default connect(
  ( {
    flags,
    texts: {
      cornerMenu: {
        view: viewOptions
      }
    }
  } ) => {
    return {
      generalViewMode: flags.generalViewMode,
      viewOptions
    };
  }
)( ViewMenu );
