import React, { Fragment as F } from 'react';
import { connect } from 'react-redux';
import { cabinetTypesByItem } from 'cSrc/structured/bare/bareContainer';
import style from './index.styl';
import json from './items.json';
import Item from './Item';

const typesRegexp = new RegExp(
  Object.keys( cabinetTypesByItem ).map( ( it ) => cabinetTypesByItem[ it ] )
    .join( '|' )
);

const mapping = {
  placement: {
    'wall-floor-items': 'Wall-floor items',
    'wall-items': 'Wall items',
    'floor-items': 'Floor items',
    rest: 'Misc',
    'in-wall-items': 'In wall items',
    'in-wall-floor-items': 'In wall floor items',
    'on-floor-items': 'On floor items',
    kitchens: 'Kitchens',
    baseCabinets: 'Base cabinets',
    upperCabinets: 'Upper cabinets',
    tallCabinets: 'Tall cabinets',
    cornerCabinets: 'Corner Cabinets',
    appliances: 'Appliances'
  },
  object: {},
  room: {}
};

// eslint-disable-next-line react/prefer-stateless-function
class Menu extends React.Component {
  render() {
    const {
      type, selectType, tab, types
    } = this.props;

    return (
      <aside>
        {
          types.map( ( it ) => {
            let chosen = type === it;

            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <p
                key={ it }
                className={ chosen ? 'chosen' : '' }
                data-type={ it }
                onClick={ selectType }
              >
                <span className={ chosen ? 'chosen' : '' }>
                  { mapping[ tab ][ it ] }
                </span>
              </p>
            );
          } )
        }
      </aside>
    );
  }
}
class ItemsModalContent extends React.Component {
  constructor( p ) {
    super( p );

    const { tab, pref } = p,
      tabObj = json[ tab ],
      keys = Object.keys( tabObj );

    this.state = {
      type: pref || keys[ 0 ],
      search: ''
    };
  }

  selectType = ( { currentTarget: { dataset: { type } } } ) => {
    this.setState( { type } );
  }

  changeSearch = ( { target: { value: search } } ) => {
    this.setState( { search } );
  }

  resetChosenItem = ( e ) => {
    if( e.target !== e.currentTarget ) return;

    this.props.chooseItem( null );
  }

  render() {
    const {
        props: {
          tab, chooseItem, chosenItem, dblclickAdd, pref
        },
        state: { type, search },
        selectType, changeSearch, resetChosenItem
      } = this,
      indObj = json[ tab ],
      /*
       * make sure that our left menu tabs doesn`t include values like those
       * inside cabinetTypesByItem because those types of items are just for
       * insertion inside cabinets, and not for adding straight to the scene
       * NOTE their counterparts in corresponding tabs (e.g. thermador stove
       * inside floor-items) can be inserted
       */
      types = Object.keys( indObj )
        .filter( ( it ) => !it.match( typesRegexp ) );

    return (
      <>
        <div className={ style.topRow }>
          <form autoComplete='off' onSubmit={ ( e ) => e.preventDefault() }>
            <input
              type='text'
              name='vestaItemsSearch'
              id='itemsSearch'
              placeholder='search by object name...'
              value={ search }
              onChange={ changeSearch }
            />
          </form>

          <span />
        </div>

        <div className={ style.content }>
          <Menu { ...{
            selectType, types, type, pref, tab
          } }
          />
          <div className='itemsHolder' onClick={ resetChosenItem }>
            {
              indObj[ type ] && indObj[ type ].map( ( it ) => (
                <F key={ json.hash[ it ].id }>
                  <Item { ...{
                    item: json.hash[ it ],
                    search,
                    chooseItem,
                    chosenItem,
                    dblclickAdd
                  } }
                  />
                </F>
              ) )
            }
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  ( s ) => ( { pref: s.itemsMenuPreffered } )
)( ItemsModalContent );
