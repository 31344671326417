// @ts-check
import React from 'react';
import { withIcon } from './index.styl';

/*
 * export default class IconAndText extends React.PureComponent {
 *   render() {
 *     let {
 *       img, text, underText, wideMargin,
 *       chosen, onClick, className = '', imgText
 *     } = this.props;
 */

/*
 *     if( onClick === null ) className += ' disabled';
 *     if( !onClick ) onClick = mockup;
 */

/*
 *     return (
 *       <div
 *         className={
 *           `${ style.iconAndText } ${ chosen && 'chosen' || '' }${
 *             img === '/img/addItem.png' ? ' btnLike' : '' }${ className
 *           }${ imgText && ' centered' || '' }`
 *         }
 *         onClick={ onClick }
 *         data-val={ underText }
 *       >
 *         <div className={ `imgGroup ${ wideMargin && 'w' || '' }` }>
 *           <img src={ img } alt='' />
 *           { underText && <span>{ underText }</span> }
 *         </div>
 *         { imgText
 *           && (
 *             <span
 *               className={ wideMargin && 'smallFont' || '' }
 *             >
 *               <img src={ text } alt='' />
 *             </span>
 *           )
 *           || (
 *             <span
 *               className={ wideMargin && 'smallFont' || '' }
 *               dangerouslySetInnerHTML={ { __html: text } }
 *             />
 *           )}
 *       </div>
 *     );
 *   }
 * }
 */

/**
 * @typedef {{
 *   img: redux.img;
 * }} Props
 */
/** @augments { React.PureComponent<Props> } */
class WithIcon extends React.PureComponent {
  render() {
    const { img, children } = this.props;

    return (
      <div className={ `${ withIcon } withIcon` }>
        <img className='icon' src={ img } alt='' />
        { children }
      </div>
    );
  }
}

export default WithIcon;
