import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  TextInput
} from '@npmrost/storybook/stories/Vesta/Inputs/TextInput';
import {
  UnitsInput
} from '@npmrost/storybook/stories/Vesta/Inputs/UnitsInput';
import { Copy, Close } from '@npmrost/storybook/stories/Vesta/Icons';
import {
  copyCabinet, removeCabinet
} from '@r/reducers/storeParts/calculationMode/projects/currentProject/cabinets';
import Settings from './Settings';
import MaterialsSets from './Settings/Materials/MaterialsSets';
import { changeValue } from './utils';
import { pieCutCarcasses } from '../../../calculations';

export const Arrow = styled.span`
  width: 12px;
  height: 12px;
  margin-right: 10px;
  cursor: pointer;
  ${ ( { theme: t, isOpen } ) => `
    background-color: ${ t.generalBackground };
    border-right: 2px solid ${ isOpen ? t.secondaryColor : t.greyTextColor };
    border-bottom: 2px solid ${ isOpen ? t.secondaryColor : t.greyTextColor };
    transform: ${ isOpen ? 'rotate(45deg)' : 'rotate(-45deg)' };
  ` }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

class CabinetItem extends PureComponent {
  constructor( props ) {
    super( props );
    this.state = {
      isOpen: false
    };
  }

  toggleDetails = () => {
    this.setState( ( { isOpen } ) => ( {
      isOpen: !isOpen
    } ) );
  }

  copy = ( e ) => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.dispatch( copyCabinet( Number( e.currentTarget.dataset.key ) ) );
  }

  remove = ( e ) => {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.dispatch( removeCabinet( Number( e.currentTarget.dataset.key ) ) );
  }

  render() {

    const {
      props,
      props: {
        dispatch,
        index,
        dataKey,
        name,
        width: { label: width },
        height: { label: height },
        depth: { label: depth },
        fullDepth: { label: fullDepth } = {},
        comment,
        materialsSetKey,
        cost,
        carcasse,
        fullDepthIsShown
      },
      state: {
        isOpen
      },
      toggleDetails,
      copy,
      remove
    } = this;

    const isPieCutCorner = pieCutCarcasses.includes( carcasse );

    return (
      <>
        <div className='row' key={ dataKey }>
          <div className='column columnNumber'>
            <span>{ index + 1 }</span>
          </div>
          <div className='column columnName'>
            <span>{ name }</span>
            <Arrow isOpen={ isOpen } onClick={ toggleDetails } />
          </div>
          <div className='column columnSetOfMaterials'>
            <MaterialsSets
              dataKey={ dataKey }
              materialsSetKey={ materialsSetKey }
            />
          </div>
          <div className='column columnUnit'>
            <UnitsInput
              value={ width }
              onChange={ changeValue( dispatch, dataKey, 'width', 'unit' ) }
            />
          </div>
          <div className='column columnUnit'>
            <UnitsInput
              value={ height }
              onChange={ changeValue( dispatch, dataKey, 'height', 'unit' ) }
            />
          </div>
          <div className='column columnUnit'>
            <UnitsInput
              value={ depth }
              onChange={ changeValue( dispatch, dataKey, 'depth', 'unit' ) }
            />
          </div>
          {
            fullDepthIsShown && (
              <div className='column columnUnit'>
                {
                  isPieCutCorner && (
                    <UnitsInput
                      value={ fullDepth }
                      onChange={ changeValue( dispatch, dataKey, 'fullDepth', 'unit' ) }
                    />
                  )
                }
              </div>
            )
          }
          <div className='column columnComment'>
            <TextInput
              value={ comment }
              onChange={ changeValue( dispatch, dataKey, 'depth', 'text' ) }
            />
          </div>
          <div className='column columnCost'>
            <span>
              $
              { cost }
            </span>
          </div>
          <Actions className='column columnActions'>
            <div data-key={ dataKey } onClick={ copy }>{ Copy }</div>
            <div data-key={ dataKey } onClick={ remove }>{ Close }</div>
          </Actions>
        </div>
        {
          isOpen && <Settings { ...props } />
        }
      </>
    );
  }
}

export default connect( null )( CabinetItem );
