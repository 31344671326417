/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  TabsWithUnderline
} from '@npmrost/storybook/stories/Vesta/Tabs/TabsWithUnderline/index';
import NewProject from './NewProject';
import ExistingProjects from './ExistingProjects';

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${ ( { theme: t } ) => `
    font-size: ${ t.headerFontSize };
  ` }
`;

const Content = styled.div`
  margin-top: 35px;
  height: calc(100% - 70px);
  overflow-x: hidden;
`;

class Main extends PureComponent {

  chooseTab = ( value ) => {
    this.props.dispatch( {
      type: 'SET_CALCULATION_MODE_MAIN_MODAL_TAB',
      value
    } );
  }

  render() {

    const {
      props: {
        isOpen,
        texts,
        mainModalTabChosen,
        settings,
        close
      },
      chooseTab
    } = this;

    if ( !isOpen ) return null;

    const mainModalTabs = ['createNewProject', 'openExistingProject'];

    const options = texts.mainModalTabs.map( ( item, index ) => {
      return { label: item, value: mainModalTabs[ index ] };
    } );

    return (
      <>
        <TabsWrapper>
          <TabsWithUnderline
            options={ options }
            chosen={ [{ label: mainModalTabChosen, value: mainModalTabChosen }] }
            choose={ chooseTab }
          />
        </TabsWrapper>
        <Content>
          {
            mainModalTabChosen === 'createNewProject' && (
              <NewProject
                currentProject={ false }
                close={ close }
                settings={ settings }
              />
            )
          }
          {
            mainModalTabChosen === 'openExistingProject' &&
              <ExistingProjects close={ close } />
          }
        </Content>
      </>
    );
  }
}

export default connect(
  ( s ) => {
    return {
      mainModalTabChosen: s.calculationMode.tabs.mainModal,
      texts: s.texts.calculationMode,
      settings: s.calculationMode.settings
    };
  }
)( Main );
