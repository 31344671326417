import { WallCabinet } from './WallCabinet';
import { Vector3 } from 'three/src/math/Vector3';

export class WallWindow extends WallCabinet {
  constructor() {
    super();
    this.isWallWindow = true;
    this.removeCrownMolding();
  }
  getHoleShape() {
    // Return hole-shape in BCS.
    const {width, height} = this.getSizes();
    // Use Vector3 with z: 0(automatically) for further processing to make hole in Wall3D.
    let hole = [
      new Vector3(-width/2, height/2),
      new Vector3(-width/2, -height/2),
      new Vector3(width/2, -height/2),
      new Vector3(width/2, height/2),
    ];
    return hole;
  }
}
