// @ts-check
/* eslint-disable prefer-reflect */
/* eslint-disable import/prefer-default-export */

import { getRelatedObjects, toDoSetMaterialsByName } from 'c/ThreeJsWrap/Viewer/core/helpers/object3d';
import { Vector3, Matrix4 } from 'three';
import Storage from 'scr/utilitiesStorage';
import { getCabinetFromConfig } from '../../core/cabinetTypes';
import { FloorCabinet } from './FloorCabinet';
import Countertop from '../../core/Countertop';
import { BaseFiller } from '../BaseFiller';
import { RightFinishPanel } from '../RightFinishPanel';
import { LeftFinishPanel } from '../LeftFinishPanel';

export class WithCountertop extends FloorCabinet {
  constructor() {
    super();
    this.countertop = new Countertop(this, undefined, 1, 0, 0);

    this.setType('base');
    this.vestaObject.setType('cabinet/withCountetop');

    if (this.crownMolding) {
      this.remove(this.crownMolding);
    }
  }

  changeWidth(...args) {
    super.changeWidth(...args);
    this.countertop.rebuildGeometry();
  }

  changeDepth(...args) {
    super.changeDepth(...args);
    this.countertop.rebuildGeometry();
  }

  changeHeight(...args) {
    super.changeHeight(...args);
    this.countertop.rebuildGeometry();
  }

  toDoSetMaterialByNameForCabinetsUnderCountertop(material, part) {
    const countertop = this.countertop;
    const countertops = getRelatedObjects(countertop);
    const cabinets = countertops.map(item => item.cabinet);
    toDoSetMaterialsByName(cabinets, material, part, Storage.get('viewer').doMgr);
  }

  addFiller(
    /** @type { 'left' | 'right' } */side,
    /** @type { import('@npmrost/utils').inches } */width
  ) {
    const filler = getCabinetFromConfig({ config: BaseFiller });
    filler.vestaObject.changeWidth(width);
    filler.vestaObject.changeDepth(this.getSizes().height);
    filler.vestaObject.changeHeight(this.getSizes().depth);


    if (side === 'left') {
      filler.position.copy(
        this.position.clone()
          .add(
            new Vector3(-this.getSizes().width / 2 - width / 2, 0, 0)
              .applyMatrix4(new Matrix4()
                .makeRotationFromEuler(this.rotation)
              )
          )
      );
    }
    if (side === 'right') {
      filler.position.copy(
        this.position.clone()
          .add(
            new Vector3(this.getSizes().width / 2 + width / 2, 0, 0)
              .applyMatrix4(new Matrix4()
                .makeRotationFromEuler(this.rotation)
              )
          )
      );
    }

    filler.rotation.copy(this.rotation);
    this.parent.add(filler);
    filler.updateMatrixWorld();
    filler.countertop.updateAllMergedUvsOffset(filler.countertop);
    Storage.get('viewer').renderOnDemand.set();
  }

  addFillerEnd(
    /** @type { 'left' | 'right' } */side,
    /** @type { import('@npmrost/utils').inches } */width
  ) {


    let finishPanel;
    if (side === 'left') {
      finishPanel = getCabinetFromConfig({ config: LeftFinishPanel });
      finishPanel.vestaObject.changeWidth(width);
      finishPanel.vestaObject.changeDepth(this.getSizes().height);
      finishPanel.vestaObject.changeHeight(this.getSizes().depth);
      finishPanel.position.copy(
        this.position.clone()
          .add(
            new Vector3(-this.getSizes().width / 2 - width / 2, 0, 0)
              .applyMatrix4(new Matrix4()
                .makeRotationFromEuler(this.rotation)
              )
          )
      );
    }
    if (side === 'right') {
      finishPanel = getCabinetFromConfig({ config: RightFinishPanel });
      finishPanel.vestaObject.changeWidth(width);
      finishPanel.vestaObject.changeDepth(this.getSizes().height);
      finishPanel.vestaObject.changeHeight(this.getSizes().depth);
      finishPanel.position.copy(
        this.position.clone()
          .add(
            new Vector3(this.getSizes().width / 2 + width / 2, 0, 0)
              .applyMatrix4(new Matrix4()
                .makeRotationFromEuler(this.rotation)
              )
          )
      );
    }

    finishPanel.rotation.copy(this.rotation);

    // finishPanel.countertop.baseCountertop = this.countertop.baseCountertop || this.countertop;
    this.parent.add(finishPanel);
    finishPanel.updateMatrixWorld();

    finishPanel.countertop.updateAllMergedUvsOffset(finishPanel.countertop);
    Storage.get('viewer').renderOnDemand.set();
  }
}
