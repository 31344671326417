/* eslint-disable jsx-a11y/label-has-associated-control */
// @ts-check
import React from 'react';
import ImageSelect from 'c/ImageSelect/index';
import { connect } from 'react-redux';
import Storage from 'scr/utilitiesStorage';
import styl from './index.styl';

const styles = {
  valueContainer: (
    /** @type { any } */ base ) => ( { ...base, height: '40px' } ),
  control: (
    /** @type { any } */ base ) => ( { ...base, borderRadius: '10px' } )
};

const WallInput = connect(
  ( /** @type { redux.store } */ s ) => ( {
    value: s.projectFor3d.walls,
    styles
  } ),
  ( /** @type { redux.d<redux.actions['setWalls']> } */ d ) => ( {
    onChange: (
      /** @type { redux.store['projectFor3d']['walls'] } */ value
    ) => d( { type: 'SET_WALLS', value } )
  } )
)( ImageSelect );

const FloorInput = connect(
  ( /** @type { redux.store } */ s ) => ( {
    value: s.projectFor3d.floor,
    styles: {
      ...styles,
      menuList: (
        /** @type { any } */ base
      ) => ( { ...base, maxHeight: '100px', overflow: 'auto' } )
    }
  } ),
  ( /** @type { redux.d<redux.actions['setFloor']> } */ d ) => ( {
    onChange: (
      /** @type { redux.store['projectFor3d']['floor'] } */ value
    ) => d( { type: 'SET_FLOOR', value } )
  } )
)( ImageSelect );

const DoorInput = connect(
  ( /** @type { redux.store } */ s ) => ( {
    value: s.projectFor3d.doorMaterial,
    styles: {
      ...styles,
      menuList: (
        /** @type { any } */ base
      ) => ( { ...base, maxHeight: '100px', overflow: 'auto' } )
    }
  } ),
  ( /** @type { redux.d<redux.actions['setDoorMaterial']> } */ d ) => ( {
    onChange: (
      /** @type { redux.store['projectFor3d']['doorMaterial'] } */ value
    ) => d( { type: 'SET_DOOR_MATERIAL', value } )
  } )
)( ImageSelect );

const CabinetMaterialInput = connect(
  ( /** @type { redux.store } */ s ) => ( {
    value: s.projectFor3d.cabinetMaterial,
    styles: {
      ...styles,
      menuList: (
        /** @type { any } */ base
      ) => ( { ...base, maxHeight: '100px', overflow: 'auto' } )
    }
  } ),
  ( /** @type { redux.d<redux.actions['setCabinetMaterial']> } */ d ) => ( {
    onChange: (
      /** @type { redux.store['projectFor3d']['cabinetMaterial'] } */ value
    ) => d( { type: 'SET_CABINET_MATERIAL', value } )
  } )
)( ImageSelect );

export default class Materials extends React.PureComponent {
  componentWillMount() {
    const { wall, floor, cabinet } = Storage.get( 'materials' );
    this.wallsOptions = wall.map( ( {
      img: baseMap, uiName: label, name: value
    } ) => ( { value, label, baseMap } ) );

    this.floorOptions = floor.map( ( {
      img: baseMap, uiName: label, name: value
    } ) => ( { value, label, baseMap } ) );

    this.cabinetOptions = cabinet.map( ( {
      img: baseMap, uiName: label, name: value
    } ) => ( { value, label, baseMap } ) );
  }

  render() {
    return(
      <div className={ styl.materials }>
        <p>Default materials</p>
        <div className='wall'>
          <label htmlFor=''>Walls</label>
          <div className='selectWrap'>
            <WallInput options={ this.wallsOptions } />
          </div>
        </div>
        <div className='floor'>
          <label htmlFor=''>Floor</label>
          <div className='selectWrap'>
            <FloorInput options={ this.floorOptions } />
          </div>
        </div>
        <div className='cabinetMaterial'>
          <label htmlFor=''>Cabinet Material</label>
          <div className='selectWrap'>
            <CabinetMaterialInput options={ this.cabinetOptions } />
          </div>
        </div>
        <div className='doorMaterial'>
          <label htmlFor=''>Door Material</label>
          <div className='selectWrap'>
            <DoorInput options={ this.cabinetOptions } />
          </div>
        </div>
      </div>
    );
  }
}

/*
 * const defaultMaterialsStyles = {
 *   valueContainer: ( base ) => ( { ...base, height: '40px' } ),
 *   control: ( base ) => ( { ...base, borderRadius: '10px' } )
 * };
 */

/*
 * const WallInputSettings = connect(
 *   ( s ) => ( {
 *     value: s.walls,
 *     options: wallsOptions,
 *     styles: defaultMaterialsStyles
 *   } ),
 *   ( d ) => ( {
 *     onChange( val ) {
 *       d( simpleActionCreator( mTC.SET_WALLS )( val ) );
 *       // Const { baseMap: img, stretch, scale } = val;
 */

//       /*
//        * Blueprint3d.floorplanner.floorplan.rooms.forEach( ( i ) => i.setRoomWallsTexture( img, stretch, scale ) );
//        * Blueprint3d.three.controller.needsUpdate = true;
//        */
//     }
//   } )
// )( ImageSelect );

/*
 * const FloorInputSettings = connect(
 *   ( s ) => ( {
 *     value: s.floor,
 *     options: floorOptions,
 *     styles: {
 *       ...defaultMaterialsStyles,
 *       menuList: ( base ) => ( { ...base, maxHeight: '100px', overflow: 'auto' } )
 *     }
 *   } ),
 *   ( d ) => ( {
 *     onChange( val ) {
 *       d( simpleActionCreator( mTC.SET_FLOOR )( val ) );
 *       // Const { baseMap: img, stretch, scale } = val;
 */

//       /*
//        * Blueprint3d.floorplanner.floorplan.rooms.forEach( ( i ) => i.setTexture( img, stretch, scale ) );
//        * Blueprint3d.three.controller.needsUpdate = true;
//        */
//     }
//   } )
// )( ImageSelect );

/*
 * export class SettingsModalMaterials extends React.PureComponent {
 *   render() {
 *     return(
 *       <>
 *         <p>Default materials</p>
 *         <div>
 *           <label htmlFor=''>Walls</label>
 *           <WallInputSettings />
 *         </div>
 *         <div>
 *           <label htmlFor=''>Floor</label>
 *           <FloorInputSettings />
 *         </div>
 *       </>
 *     );
 *   }
 * }
 */
