import React, { PureComponent } from 'react';
import SimpleInput from 'c/CalculationLayer/utils/SimpleInput';
import Storage from 'scr/utilitiesStorage';
import style from './index.styl';
import CheckChangedValue from 'c/shared/borderForChangedValue';

interface _IState {
  grainDirection: string;
  grainDirectionNew: string;
}

class GrainDirection extends PureComponent {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    grainDirection: '0',
    grainDirectionNew: '0'
  } as _IState;

  componentDidMount(): void {
    const selectedObjects = Storage.get( 'selectedObject' );
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const grainDirection = selectedObjects[ 0 ].grainDirection;
    const newGrainDirection = grainDirection * 180 / Math.PI;

    if( grainDirection ) {
      this.setState( {
        grainDirection: String( newGrainDirection ),
        grainDirectionNew: String( newGrainDirection )
      } );
    }
  }

  handleOnChange = ( e: string ) => {
    const regExForNumber = /^\d+$/;
    let newValue = `${ Number( e ) }`;
    if( e[ e.length - 1 ] === '.' ) {
      newValue = `${ newValue }.`;
    }

    const value = Number( newValue );
    if( e.match( regExForNumber ) && value > -0.01 && value < 360 ) {

      this.setState( {
        grainDirectionNew: newValue
      } );
    }
    if( !e ) {
      this.setState( {
        grainDirectionNew: '0'
      } );
    }
  };

  handleApplyValue = () => {
    const { grainDirectionNew } = this.state;
    const selectedObjects = Storage.get( 'selectedObject' );

    if( selectedObjects[ 0 ] ) {
      selectedObjects.forEach( ( selectedObjects: any ) => {
        selectedObjects.setGrainDirection( Number( grainDirectionNew ) );
      } );

      this.setState( {
        grainDirection: grainDirectionNew
      } );
    }
  };

  render() {
    const {
      grainDirection,
      grainDirectionNew
    } = this.state;
    let newValue = String( Math.round( Number( grainDirectionNew ) ) );

    return (
      <div className={ style.grainDirectionBlock }>
        <CheckChangedValue changed={ grainDirectionNew !== grainDirection }>
          <SimpleInput
            onChange={ this.handleOnChange }
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            onBlur={ this.handleApplyValue }
            value={ newValue }
          />
        </CheckChangedValue>
      </div>
    );
  }
}

export default GrainDirection;
