const calcHingeCost = ( {
  hingeQty,
  hinge: { name, costPerFt, labourUnit },
  doorQty,
  fixLabourCost
} ) => {

  console.log( `Hinge:
    name: ${ name }
    cost per ft: ${ costPerFt }
    labout unit: ${ labourUnit }
  ` );

  console.log( `Hinge quantity from cabinet table: ${ hingeQty }` );
  console.log( `Doors quantity from cabinet table: ${ doorQty }` );

  const count = hingeQty || ( 2 * doorQty );

  console.log( `Quantity: ${ count }` );

  const labourCostNotDoubled = Number(
    ( count * fixLabourCost * ( labourUnit ? Number( labourUnit ) : 0 ) )
      .toFixed( 2 )
  );

  console.log( `Labour cost: ${ labourCostNotDoubled }` );

  // doubled prices
  const labourCost = 2 * labourCostNotDoubled;

  const costNotDoubled = Number(
    ( count * Number( costPerFt ) ).toFixed( 2 )
  );

  console.log( `Cost (without labour): ${ costNotDoubled }` );

  // doubled prices
  const cost = 2 * costNotDoubled;

  const totalCost = Number(
    ( cost + labourCost ).toFixed( 2 )
  );

  console.log( `Total cost: ${ totalCost }` );

  return {
    hinge: {
      name,
      count,
      cost: totalCost,
      costWithoutLabour: cost,
      totalLabourCost: labourCost
    },
    labourCost
  };
};

export default ( {
  hingeQty,
  hinge,
  doorQty,
  totalHinges,
  fixLabourCost
} ) => {

  if ( !hingeQty && !doorQty ) {
    return { newTotalHinges: totalHinges, hingeLabourCost: 0 };
  }

  const newTotalHinges = totalHinges.slice();

  const {
    hinge: newHinge, labourCost: hingeLabourCost
  } = calcHingeCost( {
    hingeQty, hinge, doorQty, fixLabourCost
  } );

  const {
    name: newName,
    count: newCount,
    cost: newCost,
    costWithoutLabour: newCostWithoutLabour,
    totalLabourCost: newTotalLabourCost
  } = newHinge;

  const hingeExists = newTotalHinges
    .find( ( item ) => item.name === newName );

  if ( hingeExists ) {
    for ( let i = 0; i < newTotalHinges.length; i++ ) {
      const {
        name, cost, costWithoutLabour, totalLabourCost
      } = newTotalHinges[ i ];

      if ( name === newName ) {
        newTotalHinges[ i ].count += newCount;
        newTotalHinges[ i ].cost = Number( ( cost + newCost ).toFixed( 2 ) );
        newTotalHinges[ i ].costWithoutLabour = Number(
          ( costWithoutLabour + newCostWithoutLabour ).toFixed( 2 )
        );
        newTotalHinges[ i ].totalLabourCost = Number(
          ( totalLabourCost + newTotalLabourCost ).toFixed( 2 )
        );

        break;
      }
    }
  } else {
    newTotalHinges.push( newHinge );
  }

  return { newTotalHinges, hingeLabourCost };
};
