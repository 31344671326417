import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  /* justify-content: flex-end; */
  height: 30%;
  padding: 20px;
`;

const Content = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  width: calc(100% / 3);
  padding: 0 20px;
  &:not(:last-child) {
    ${ ( { theme: t } ) => `
    border-right: 1px solid ${ t.darkGreyColor };
  ` }
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  ${ ( { bold } ) => `
    ${ bold ? 'font-weight: 700;' : '' }
  ` }
`;

const Text = styled.span`
  width: 80%;
`;

const Cost = styled.span`
  width: 20%;
  margin-left: 10px;
`;

class Summary extends PureComponent {
  render() {
    const {
      texts: {
        totalMaterialsCost: totalMaterialsCostText,
        totalLabourCost: totalLabourCostText,
        totalDoorsDrawersCost: totalDoorsDrawersCostText,
        shippingCost: shippingCostText,
        installationCost: installationCostText,
        totalCost: totalCostText,
        percent: percentText,
        finalCost: finalCostText
      },
      shippingCost,
      withInstallation,
      totalMaterialsCost,
      totalLabourCost,
      totalDoorsDrawersCost
    } = this.props;

    const salePrice = Number(
      ( totalMaterialsCost + totalLabourCost ).toFixed( 2 )
    );

    const priceOfShipping = salePrice
      ? Number(
        ( salePrice * 0.03 + shippingCost )
          .toFixed( 2 )
      )
      : 0;

    let priceOfInstallation = 0;

    if ( salePrice && withInstallation ) {
      priceOfInstallation = Number(
        ( salePrice * 0.15 ).toFixed( 2 )
      );

      if ( priceOfInstallation < 250 ) {
        priceOfInstallation = 250;
      }
    }

    const totalCost = Number(
      ( salePrice + priceOfShipping + priceOfInstallation )
        .toFixed( 2 )
    );

    const finalCost = totalCost;

    return (
      <Wrapper>
        <Content>
          <Item>
            <Text>{ totalMaterialsCostText }</Text>
            <Cost>{ `$${ totalMaterialsCost }` }</Cost>
          </Item>
          <Item>
            <Text>{ totalLabourCostText }</Text>
            <Cost>{ `$${ totalLabourCost }` }</Cost>
          </Item>
          <Item>
            <Text>{ totalDoorsDrawersCostText }</Text>
            <Cost>{ `$${ totalDoorsDrawersCost }` }</Cost>
          </Item>
        </Content>
        <Content>
          <Item>
            <Text>{ shippingCostText }</Text>
            <Cost>{ `$${ priceOfShipping }` }</Cost>
          </Item>
          <Item>
            <Text>{ installationCostText }</Text>
            <Cost>{ `$${ priceOfInstallation }` }</Cost>
          </Item>
        </Content>
        <Content>
          <Item>
            <Text>{ totalCostText }</Text>
            <Cost>{ `$${ totalCost }` }</Cost>
          </Item>
          <Item>
            <Text>{ percentText }</Text>
            <Cost>-</Cost>
          </Item>
          <Item bold>
            <Text>{ finalCostText }</Text>
            <Cost>{ `$${ finalCost }` }</Cost>
          </Item>
        </Content>
      </Wrapper>
    );
  }
}

export default connect(
  ( {
    texts: {
      calculationMode: {
        estimationPreview: { summary }
      }
    },
    calculationMode: {
      projects: {
        currentProject: {
          settings: {
            information: {
              shippingCostManual: { value: shippingCost },
              withInstallation
            }
          }
        }
      }
    }
  } ) => {
    return {
      texts: summary,
      shippingCost,
      withInstallation
    };
  }
)( Summary );
