import React, { PureComponent } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { _IProject3d } from '@r/reducers/projectsFor3d';
import { MongoObjectId } from '@npmrost/utils';
import { ProjectMenu } from './ProjectMenu';

const Wrap = styled.div<{ selected: boolean }>`
  cursor: pointer;
  padding: 5px;
  width: 30%;
  margin-right: 3%;
  height: 240px;
  display: flex;
  flex-direction: column;
  &:hover {
    box-shadow: 0px 0px 10px 5px rgba(155,155,155,0.4);
  }
  ${ ( p ) => ( p.selected ? 'background-color: #a3d0fd;' : '' ) }
`;

const ImgWrap = styled.div`
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(0,0,0,0.2);
  img {
    width: 100%;
    height: auto;
  }
  &:hover {
    .projectMenu {
      display: flex;
    }
  }
`;

const Name = styled.p`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  margin: 0;
`;

const Date = styled.p`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin: 0;
  color: #777777;
`;

interface _IProps {
  project: _IProject3d;
  select: ( id: MongoObjectId ) => void;
  selected: boolean;
}

export class Project extends PureComponent<_IProps> {
  choose = ( e: React.MouseEvent<HTMLDivElement & { dataset: { id: MongoObjectId } }> ) => {
    this.props.select( e.currentTarget.dataset.id );
  }

  render() {
    const {
      props: {
        project: {
          _id, projectName, createdAt,
          projectTag
        },
        selected
      },
      choose
    } = this;

    return (
      <Wrap selected={ selected } data-id={ _id } onClick={ choose }>
        <ImgWrap>
          <img src='/img/favicon.ico' alt='' />
          <ProjectMenu { ...{ id: _id, projectTag } } />
        </ImgWrap>
        <Name>{ projectName }</Name>
        <Date>{ `Created on ${ dayjs( createdAt ).format( 'MMMM DD, YYYY' ) }` }</Date>
      </Wrap>
    );
  }
}
// export default class Project extends React.PureComponent {
//   render() {
//     const {
//       img, projectName, createdAt, chosen, _id: id, projectTag
//     } = this.props;

//     return (
//       <div data-id={ id } className={ style.project + ( chosen === id ? ' chosen' : '' ) }>
//         <div>
//           <img src={ `${ host }${ img.replace( /^[^/]+\//, '' ) }` } alt='' />
//           <ProjectMenu { ...{ id, projectTag } } />
//         </div>
//         <p className='name'>{ projectName }</p>
//         <p className='date'>{ `Created on ${ dayjs( createdAt ).format( 'MMMM DD, YYYY (HH:mm A)' ) }` }</p>
//       </div>
//     );
//   }
// }
