const calcDrawerSystemCost = ( {
  drawerSystem: {
    name,
    costPerPair,
    labourUnit
  },
  drawerSlideQty,
  fixLabourCost
} ) => {

  console.log( `Drawer system:
    name: ${ name }
    cost per pair: ${ costPerPair }
    labour unit: ${ labourUnit }
  ` );

  const count = Number( drawerSlideQty );

  console.log( `Drawer slide quantity: ${ count }` );
  console.log( `Fix labour cost: ${ fixLabourCost }` );

  const labourCostNotDoubled = Number(
    ( count * fixLabourCost * ( labourUnit ? Number( labourUnit ) : 0 ) )
      .toFixed( 2 )
  );

  console.log( `Labour cost: ${ labourCostNotDoubled }` );

  // doubled prices
  const labourCost = 2 * labourCostNotDoubled;

  const costNotDoubled = Number( ( count * Number( costPerPair ) ).toFixed( 2 ) );

  console.log( `Cost (without labour): ${ costNotDoubled }` );

  // doubled prices
  const cost = 2 * costNotDoubled;

  const totalCost = Number(
    ( cost + labourCost ).toFixed( 2 )
  );

  console.log( `Total cost: ${ totalCost }` );

  return {
    drawerSystem: {
      name,
      count,
      cost: totalCost,
      costWithoutLabour: cost,
      totalLabourCost: labourCost
    },
    labourCost
  };
};

export default ( {
  drawerSlideQty,
  drawerSystem,
  totalDrawerSystems,
  fixLabourCost
} ) => {

  if ( !drawerSlideQty ) {
    return {
      newTotalDrawerSystems: totalDrawerSystems,
      drawerSystemLabourCost: 0
    };
  }

  const newTotalDrawerSystems = totalDrawerSystems.slice();

  const {
    drawerSystem: newDrawerSystem,
    labourCost: drawerSystemLabourCost
  } = calcDrawerSystemCost( {
    drawerSystem,
    drawerSlideQty,
    fixLabourCost
  } );

  const {
    name: newName,
    depth: newDepth,
    cost: newCost,
    costWithoutLabour: newCostWithoutLabour,
    totalLabourCost: newTotalLabourCost
  } = newDrawerSystem;

  const drawerSystemExists = newTotalDrawerSystems
    .find( ( item ) => item.name === newName );

  if ( drawerSystemExists ) {
    for ( let i = 0; i < newTotalDrawerSystems.length; i++ ) {
      const {
        name, cost, costWithoutLabour, totalLabourCost
      } = newTotalDrawerSystems[ i ];

      if ( name === newName ) {
        newTotalDrawerSystems[ i ].depth += newDepth;
        newTotalDrawerSystems[ i ].cost = Number(
          ( cost + newCost ).toFixed( 2 )
        );
        newTotalDrawerSystems[ i ].costWithoutLabour = Number(
          ( costWithoutLabour + newCostWithoutLabour ).toFixed( 2 )
        );
        newTotalDrawerSystems[ i ].totalLabourCost = Number(
          ( totalLabourCost + newTotalLabourCost ).toFixed( 2 )
        );

        break;
      }
    }
  } else {
    newTotalDrawerSystems.push( newDrawerSystem );
  }

  return { newTotalDrawerSystems, drawerSystemLabourCost };
};
