/* eslint-disable jsx-a11y/label-has-associated-control */
// @ts-check
import React, { PureComponent } from 'react';

/**
 * @typedef {{
 *  name: string;
 *  className?: string;
 * }} Props
 */
/** @augments { PureComponent<Props> } */
export default class LabeledField extends PureComponent {
  render() {
    const { name, children, className = '' } = this.props;
    const labeledClass = `${ className } labeledField`;

    return (
      <div className={ labeledClass }>
        <label htmlFor=''>{ name }</label>
        <div>{ children }</div>
      </div>
    );
  }
}
