import { simpleSet } from '@r/reducers/utils';
import feathersClient from 'scr/getFeathers';

export const existingProjects = simpleSet(
  'SET_CALCULATION_MODE_EXISTING_PROJECTS',
  {
    data: []
  }
);

export function getExistingProjects( { tags, name } = {} ) {
  return async ( dispatch ) => {
    const query = {
      $limit: -1,
      $sort: { updatedAt: -1, createdAt: -1 }
    };

    if ( tags && tags.length ) {
      query[ 'settings.information.tag' ] = { $in: tags };
    }

    if ( name ) {
      query[ 'settings.information.name' ] = new RegExp( name, 'i' );
    }

    const projects = await feathersClient
      .service( 'api/calculationProjects' )
      .find( {
        query
      } );

    dispatch( {
      type: 'SET_CALCULATION_MODE_EXISTING_PROJECTS',
      value: {
        data: projects
      }
    } );
  };
}

export function copyProject( projectId ) {
  return async ( dispatch ) => {
    try {
      const { settings, cabinets } = await feathersClient
        .service( 'api/calculationProjects' )
        .get( projectId );

      await feathersClient
        .service( 'api/calculationProjects' )
        .create( { settings, cabinets } );

      dispatch( getExistingProjects() );

      Promise.resolve();
    } catch ( err ) {
      Promise.reject( err );
    }
  };
}

export function removeProject( projectId ) {
  return async ( dispatch, getStore ) => {
    const {
      calculationMode: {
        projects: {
          currentProject: {
            projectId: currentProjectId
          }
        }
      }
    } = getStore();

    try {
      await feathersClient
        .service( 'api/calculationProjects' )
        .remove( projectId );

      if ( currentProjectId === projectId ) {
        dispatch( {
          type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_ID',
          value: ''
        } );
      }

      dispatch( getExistingProjects() );

      Promise.resolve();
    } catch ( err ) {
      Promise.reject( err );
    }
  };
}

export function openProject( projectId ) {
  return async ( dispatch ) => {
    try {
      const { settings, cabinets } = await feathersClient
        .service( 'api/calculationProjects' )
        .get( projectId );

      dispatch( {
        type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_SETTINGS',
        value: JSON.parse( JSON.stringify( settings ) )
      } );

      dispatch( {
        type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_CABINETS',
        value: JSON.parse( JSON.stringify( cabinets ) )
      } );

      dispatch( {
        type: 'SET_CALCULATION_MODE_CURRENT_PROJECT_ID',
        value: projectId
      } );

      return Promise.resolve();
    } catch ( err ) {
      return Promise.reject( err );
    }
  };
}
