import { simpleSet } from '@r/reducers/utils';
import { UnitsInputValue } from '@npmrost/storybook/stories/Vesta/Inputs/UnitsInput';

const setCalculationModeTopDrawerHeightLabel = simpleSet as
  redux.reducers.calculationMode.settings.dimensions.topDrawerHeight.label;

export const label = setCalculationModeTopDrawerHeightLabel(
  'SET_CALCULATION_MODE_TOP_DRAWER_HEIGHT_LABEL',
  '' as UnitsInputValue
);
