import React, { PureComponent } from 'react';
import Login from './Login';
import Signup from './Signup';
import style from './index.styl';

interface IState {
  tab: 'login' | 'signup';
}
interface IProps {
 tabs: Array<{
   name: IState['tab'];
   uiName: string;
   comp: typeof Login | typeof Signup;
 }>;
}


export default class Modal extends PureComponent< IProps, IState > {
  static defaultProps: IProps = {
    tabs: [
      { name: 'login', uiName: 'Log in', comp: Login },
      { name: 'signup', uiName: 'Sign up', comp: Signup }
    ]
  }

  constructor( p: IProps ) {
    super( p );

    this.state = {
      tab: 'login'
    };
  }

  chooseTab = ( { currentTarget: { dataset: { tab } } }: React.MouseEvent<HTMLDivElement> & {
    currentTarget: { dataset: { tab: IState['tab'] } }; } ) => {
    this.setState( { tab } );
  };

  render() {
    const {
      state: { tab },
      props: { tabs }
    } = this;
    let [{ comp: Login }, { comp: Signup }] = tabs;

    return (
      <div className={ style.modalWrap }>
        <div className='modal'>
          <div className='tabs'>
            {
              tabs.map( ( { name, uiName } ) => (
                <span
                  key={ name }
                  onClick={ this.chooseTab }
                  data-tab={ name }
                  className={ tab === name && ( 'chosen' ) || '' }
                >
                  { uiName }
                </span>
              ) )
            }
          </div>
          { tab === 'login' && <Login /> }
          { tab === 'signup' && <Signup /> }
        </div>
      </div>
    );
  }
}
