import {Step} from "./BaseCmd";
import DraftCmd from "./DraftCmd";
import * as THREE from 'three';
import {createCurveEntity} from '../entity/CurveEntityEx';
import DoAddRemoveEntity from "../UndoRedo/DoAddRemoveEntity";
import { DrawSnap, Constants, CommonUtil } from "../common";

class Arc1Cmd extends DraftCmd {
    draftEntity = null;
    
    vt0 = new THREE.Vector2();
    vt1 = new THREE.Vector2();
    vt2 = new THREE.Vector2();

    v0 = new THREE.Vector2();
    v1 = new THREE.Vector2();
    v2 = new THREE.Vector2();

    ubase = new THREE.Vector2(); // normalized
    udir = new THREE.Vector2(); // normalized
    accA = 0; // accumulated angle
    
    doActivate() {
        super.doActivate();
        this.setCursor('arc', true);
    }
    initCmd() {
        super.initCmd();
        this.draftEntity = null;
    }
    doEscape() {
        if (this.draftEntity) {
            this.draftEntity.dispose();
            this.updateRender();
        }
        super.doEscape();
    }
    onMouseMove(e) {
        super.onMouseMove(e);

        const pos = this.tracker.trackPos2d(true);
        if (!pos) return;

        switch(this.step) {
            case Step.step1: {
                this.vt1.copy(pos);
                break;
            }
            case Step.step2: {
                if (!this.equalsPoint(this.vt0, pos)) {
                    this.udir.copy(pos).sub(this.vt0).normalize();
                    
                    const angle = CommonUtil.calcAngle2d(this.udir, this.ubase);
                    this.accA += angle;
                    if (Constants.TwoPi < this.accA) this.accA -= Constants.TwoPi;
                    else if (this.accA < -Constants.TwoPi) this.accA += Constants.TwoPi;

                    this.ubase.copy(this.udir);
                    
                    const curve = this.draftEntity.curve;
                    curve.aEndAngle = curve.aStartAngle + this.accA;
                    curve.aClockwise = this.accA < 0;

                    this.vt2.copy(this.udir).multiplyScalar(curve.xRadius).add(this.vt0);

                    this.draftEntity.updateEntity();
    
                    this.updateRender();
                }
                break;
            }
        }
    }
    onMouseClick(e) {
        super.onMouseClick(e);
        
        const pos = this.tracker.trackPos2d(true);
        if (!pos) return;

        switch(this.step) {
            case Step.step0: {
                this.vt0.copy(pos);
                this.vt1.copy(this.vt0);

                this.step = Step.step1;
                break;
            }
            case Step.step1: {
                if (!this.equalsPoint(this.vt0, this.vt1)) {
                    this.ubase.subVectors(this.vt1, this.vt0);
                    const radius = this.ubase.length();
                    const angle = CommonUtil.calcAngle2d(this.ubase.normalize());
                    this.accA = 0;

                    this.draftEntity = createCurveEntity(new THREE.ArcCurve(this.vt0.x, this.vt0.y, radius, angle, angle));
                    this.entityMgr.draftG.add(this.draftEntity);
    
                    this.vt2.copy(this.vt1);

                    this.step = Step.step2;
                }
                break;
            }
            case Step.step2: {
                if (!this.equalsPoint(this.vt1, this.vt2)) {
                    // end with undo/redo
                    const doaction = new DoAddRemoveEntity(this.draftEntity, true, this.entityMgr.curveG);
                    this.doMgr.registerDo(doaction);
                    doaction.redo();

                    this.initCmd();
                }
                break;
            }
        }
    }
    drawSnap(ctx) {
        super.drawSnap(ctx);
        
        switch(this.step) {
            case Step.step1: {
                this.v0.copy(this.viewer.uds2view(this.vt0));
                this.v1.copy(this.viewer.uds2view(this.vt1));

                DrawSnap.drawStickerLine(ctx, this.v0, this.v1);

                DrawSnap.drawSticker(ctx, this.v0);
                DrawSnap.drawSticker(ctx, this.v1);
                break;
            }
            case Step.step2: {
                this.v0.copy(this.viewer.uds2view(this.vt0));
                this.v1.copy(this.viewer.uds2view(this.vt1));
                this.v2.copy(this.viewer.uds2view(this.vt2));

                DrawSnap.drawStickerLine(ctx, this.v0, this.v1);
                DrawSnap.drawStickerLine(ctx, this.v0, this.v2);

                DrawSnap.drawSticker(ctx, this.v0);
                DrawSnap.drawSticker(ctx, this.v1);
                DrawSnap.drawSticker(ctx, this.v2);
                break;
            }
        }
    }
}

export default Arc1Cmd;