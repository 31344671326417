import {
  Group, ExtrudeGeometry, Mesh, Geometry, MeshStandardMaterial
} from 'three';
import { createGeometry } from 'c/ThreeJsWrap/Viewer/core/helpers/geometry';
import Storage from 'scr/utilitiesStorage';

export default class CrownMolding extends Group {
  constructor( cabinet, shape, leftBevel = false, rightBevel = false ) {
    super();
    this.shape = shape;
    this.cabinet = cabinet;
    this.isCrownMolding = true;
    this.leftBevel = leftBevel;
    this.rightBevel = rightBevel;

    // here must be default material later
    const material = Storage.get( 'namesToMaterials' ).crownMolding[ Storage.get( 'crownMoldings' ).defaultMaterial ];

    this.frontPart = new Mesh(
      new Geometry(),
      material );

    this.leftPart = new Mesh(
      new Geometry(),
      material );

    this.rightPart = new Mesh(
      new Geometry(),
      material );

    this.frontPart.castShadow = true;
    this.frontPart.receiveShadow = true;
    this.leftPart.castShadow = true;
    this.leftPart.receiveShadow = true;
    this.rightPart.castShadow = true;
    this.rightPart.receiveShadow = true;

    this.rebuildGeometry();
    this.add( this.frontPart );
    // this.cabinet.add(this);

    this.vestaObject.setMaterialByName = function setMaterialByName( mat ) {
      const viewer = Storage.get( 'viewer' );
      const newMaterial = Storage.get( 'namesToMaterials' ).crownMolding[
        mat.crownMolding
      ];

      if ( !newMaterial ) {
        return;
      }

      for ( let i = 0; i < Storage.get( 'materials' ).crownMolding.length; i += 1 ) {

        /* if ( Storage.get( 'materials' ).crownMolding[ i ].material.map ) {
          Storage.get( 'materials' ).crownMolding[ i ].material.map.dispose();
        }
        if ( Storage.get( 'materials' ).crownMolding[ i ].material.roughnessMap ) {
          Storage.get( 'materials' ).countertop[ i ].material.roughnessMap.dispose();
        }
        if ( Storage.get( 'materials' ).countertop[ i ].material.normalMap ) {
          Storage.get( 'materials' ).countertop[ i ].material.normalMap.dispose();
        } */

        Storage.get( 'materials' ).crownMolding[ i ].material.dispose();

      }

      this.getParent().frontPart.material = newMaterial;
      this.getParent().leftPart.material = newMaterial;
      this.getParent().rightPart.material = newMaterial;
      viewer.renderOnDemand.set();

      if ( this.getParent().cabinet.updateMaterialInConfig ) {
        this.getParent().cabinet
          .updateMaterialInConfig( 'crownMoldingMaterialName', mat.crownMolding );
      }
    };

    this.vestaObject.getMaterialName = function getMaterialName() {
      return ( this.getParent().cabinet.getConfig().crownMoldingMaterialName ||
        'white' );
    };

    this.vestaObject.setType( 'crownMolding' );
  }

  rebuildGeometry() {

    this.frontPart.geometry.dispose();
    this.frontPart.geometry = createGeometry( new ExtrudeGeometry( this.shape.obj, {
      steps: 1,
      depth: this.cabinet.getSizes().width,
      bevelEnabled: false
    } ) );
    this.updateBevels();

    Storage.get( 'viewer' ).renderOnDemand.set();

    // console.log( this.frontPart.geometry );

  }

  changeShape( shape ) {
    this.shape = shape;
    this.rebuildGeometry();
  }

  changeWidth() {
    this.rebuildGeometry();
  }

  changeDepth() {
    this.rebuildGeometry();
  }

  setBevels( left, right ) {
    this.leftBevel = left;
    this.rightBevel = right;
    this.rebuildGeometry();
  }

  updateBevels() {

    const width = this.shape.maxX - this.shape.minX;
    const minX = this.shape.minX;
    const maxX = this.shape.maxX;

    if ( this.leftBevel ) {
      this.leftPart.geometry.dispose();
      this.leftPart.geometry =
        createGeometry( new ExtrudeGeometry( this.shape.obj, {
          steps: 1,
          depth: this.cabinet.vestaObject.getSizes().depth - 0.5,
          bevelEnabled: false
        } ) );
      this.leftPart.rotation.set( 0, -Math.PI / 2, 0 );
      this.leftPart.position.set( 0, 0, this.cabinet.getSizes().width );


      for ( let i = 0; i < this.leftPart.geometry.vertices.length / 2; i += 1 ) {
        if ( this.leftPart.geometry.vertices[ i ].z === 0 ) {
          this.leftPart.geometry.vertices[ i ].z -=
            ( width *
              ( this.leftPart.geometry.vertices[ i ].x - minX ) /
              ( maxX - minX ) + minX );
        }
      }

      this.add( this.leftPart );
    } else {
      this.remove( this.leftPart );
    }

    if ( this.rightBevel ) {
      this.rightPart.geometry.dispose();
      this.rightPart.geometry =
        createGeometry( new ExtrudeGeometry( this.shape.obj, {
          steps: 1,
          depth: this.cabinet.vestaObject.getSizes().depth - 0.5,
          bevelEnabled: false
        } ) );
      this.rightPart.rotation.set( 0, Math.PI / 2, 0 );
      this.rightPart.position.set( 0.5 - this.cabinet.vestaObject.getSizes().depth, 0, 0 );

      for ( let i = this.rightPart.geometry.vertices.length / 2; i < this.rightPart.geometry.vertices.length; i += 1 ) {
        if ( this.rightPart.geometry.vertices[ i ].z !== 0 ) {
          this.rightPart.geometry.vertices[ i ].z +=
            ( width *
              ( this.rightPart.geometry.vertices[ i ].x - minX ) /
              ( maxX - minX ) + minX );
        }
      }

      this.add( this.rightPart );
    } else {
      this.remove( this.rightPart );
    }

    for ( let i = 0; i < this.frontPart.geometry.vertices.length / 2; i += 1 ) {
      if ( this.rightBevel && this.frontPart.geometry.vertices[ i ].z === 0 ) {
        this.frontPart.geometry.vertices[ i ].z -=
          ( width *
            ( this.frontPart.geometry.vertices[ i ].x - minX ) /
            ( maxX - minX ) + minX );
      }
    }

    for ( let i = this.frontPart.geometry.vertices.length / 2; i < this.frontPart.geometry.vertices.length; i += 1 ) {
      if ( this.leftBevel && this.frontPart.geometry.vertices[ i ].z !== 0 ) {
        this.frontPart.geometry.vertices[ i ].z +=
          ( width *
            ( this.frontPart.geometry.vertices[ i ].x - minX ) /
            ( maxX - minX ) + minX );
      }
    }


  }

  disposePart( part ) {
    if ( part && part.parent && part.geometry ) {
      part.parent.remove( part );
      part.geometry.dispose();
    }

    part = null;
  }

}
