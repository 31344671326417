import feathersClient from 'scr/getFeathers';

export async function getMaterials ( lang ) {
  // eslint-disable-next-line no-useless-catch
  try {
    const data = await feathersClient.service( 'api/materials' ).find( {
      query: {
        $limit: '-1',
        $sort: {
          [ `${ lang }.type` ]: 1,
          [ `${ lang }.name` ]: 1
        }
      }
    } );

    return data;
  } catch ( error ) {
    throw error;
  }
}
