import DoAction from "./DoAction";
import store from '@r/store';
import { isHoleableWithWall, getWall3D } from "../core/helpers/object3d";

export default class DoAddRemoveEntity extends DoAction {
    constructor(entity, add_remove, parent, info) {
        super();

        this.entity = entity;
        this.parent = parent ? parent : entity.parent;
        this.add_remove = add_remove === undefined ? true : add_remove;

        if (info) {
            this.defaultLocationBefore = info.defaultLocationBefore;
            this.defaultLocationAfter = info.defaultLocationAfter;
        }
    }
	redo() {
		if (this.add_remove) this.addObject();
		else this.removeObject();
	}
	undo() {
		if (this.add_remove) this.removeObject();
		else this.addObject();
    }
    addObject() {
        // refered from addItemToScene() in ItemsModal.jsx
        this.parent.add(this.entity);
        if (isHoleableWithWall(this.entity)) this.viewer.onceActionsMgr.registerRefreshWallHoles(getWall3D(this.entity)); // refresh holes

        if (this.defaultLocationBefore) {
            // this.viewer.scene.addObjectDirectly(this.entity);
            this.viewer.scene.setDefaultLocation(this.add_remove ? this.defaultLocationAfter : this.defaultLocationBefore);
        }
        
        this.viewer.renderOnDemand.set();
    }
    removeObject() {
        // refered from deleteCabinet() in CabinetMenu.tsx
        const viewer = this.viewer;
        let sels = this.selectedObject;
        if (0 < sels.length && sels.includes(this.entity)) {
            // deselect
            sels.length = 0;
            viewer.transformControls && viewer.transformControls.detach();

            store.dispatch({
              type: 'SET_RIGHT_SIDE_CABINET_MENU_MODE',
              value: 'addCabinet'
            });
        }
        
        if (isHoleableWithWall(this.entity)) this.viewer.onceActionsMgr.registerRefreshWallHoles(getWall3D(this.entity)); // refresh holes
        this.parent.remove(this.entity);
        
        if (this.defaultLocationBefore) {
            // this.viewer.scene.addObjectDirectly(this.entity);
            this.viewer.scene.setDefaultLocation(this.add_remove ? this.defaultLocationBefore : this.defaultLocationAfter);
        }

        viewer.renderOnDemand.set();
    }
}