// @ts-check
import React from 'react';
import { connect } from 'react-redux';
import Storage from 'scr/utilitiesStorage';
import _ from 'lodash';
import RightMenuForFloorPlan from './RightMenuForFloorPlan';
/*
 * import CabinetSettingsBeforeInsert from './CabinetSettingsBeforeInsert';
 */
import style from './index.styl';
import Tabs from './Tabs';
import CabinetMenu from './CabinetMenu';

/**
 * @typedef {{
 *  visible: boolean;
 *  d: redux.d<redux.actions.rightSideCabinetMenu.SetMode>;
 *  mode: redux.store['flags']['rightSideCabinetMenuMode'];
 *  hideRightSideMenu: boolean;
 * }} Props
 */
export default connect(
  ( /** @type { redux.store } */
    { flags, selectedFloorPlanObject } ) => ( {
    visible: flags.rightSideCabinetMenuOpen,
    mode: flags.rightSideCabinetMenuMode,
    generalViewMode: flags.generalViewMode,
    selectedFloorPlanObject
  } ),
  ( d ) => ( { d } )
)(
  /** @augments { React.PureComponent< Props > } */
  class CabinetRightSideMenu extends React.PureComponent {
    componentDidMount () {
      const viewer = Storage.get( 'viewer' );

      viewer.addEventListener(
        'objectselected',
        this.processSelection
      );
    }

    componentWillUnmount () {
      const viewer = Storage.get( 'viewer' );

      viewer.removeEventListener(
        'objectselected',
        this.processSelection
      );
    }

    findModeValue = ( type ) => {
      switch ( type ) {
        case 'cabinet':
          return 'editCabinetOnScene';
        case 'wall3D':
          return 'editWall3D';
        case 'corner':
          return 'editCorner';
        default:
          return 'editCabinetOnScene';
      }
    };

    findFieldForFloorPlanObject = ( type, selectedObject ) => {
      const object = {};

      if( type === 'wall3D' ) {
        object.width = selectedObject.width;
        object.height = selectedObject.height;
        object.depth = selectedObject.depth;
        object.angle = selectedObject.angle;
      } else if( type === 'corner' ) {
        object.x = selectedObject.x;
        object.y = selectedObject.y;
      }

      return object;
    };

    processSelection = (
      /** @type { ThreeScene.viewerEvents.SelectObject3d | ThreeScene.viewerEvents.RemoveSelectionClick } */
      e
    ) => {
      const { props: { d, selectedFloorPlanObject } } = this;
      const { data, data: { object } } = e;

      if( data.object[ 0 ] === null ) {
        Storage.set( 'selectedObject', object );

        /** @type { redux.actions.rightSideCabinetMenu.SetMode } */
        const action = {
          type: 'SET_RIGHT_SIDE_CABINET_MENU_MODE',
          value: 'addCabinet'
        };
        d(
          action,
          {
            type: 'SET_SELECTED_FLOOR_PLAN_OBJECT',
            value: {}
          }
        );

        return void 0;
      }

      const nonNullable = /**
        @type { ThreeScene.viewerEvents.SelectObject3d['data'] }
      */( data );

      const firstSelected = nonNullable.object[ 0 ];
      if( firstSelected ) {
        const selectedObjectType = firstSelected.vestaObject.getType();

        if( !selectedObjectType.match( 'cabinet' ) &&
          !selectedObjectType.match( 'wall3D' ) &&
          !selectedObjectType.match( 'corner' )
        ) {
          return void 0;
        }

        const floorPlanSelectedObject =
          this.findFieldForFloorPlanObject( selectedObjectType, firstSelected );

        if(
          ( selectedObjectType.match( 'wall3D' ) ||
            selectedObjectType.match( 'corner' ) ) &&
          !_.isEqual( floorPlanSelectedObject, selectedFloorPlanObject )
        ) {
          d(
            {
              type: 'SET_SELECTED_FLOOR_PLAN_OBJECT',
              value: floorPlanSelectedObject
            } );
        }

        const value = this.findModeValue( selectedObjectType );


        Storage.set( 'selectedObject', nonNullable.object );
        d( {
          type: 'SET_RIGHT_SIDE_CABINET_MENU_MODE',
          value
        } );

        return void 0;
      }
    };

    render() {
      const {
        props: {
          visible, mode,
          generalViewMode,
          hideRightSideMenu
        }
      } = this;
      const className = `${ style.cabinetRightSideMenu }` +
        ` ${ visible ? '' : 'hidden' }` +
        ` ${ hideRightSideMenu ? 'hidden' : '' }`;

      return (
        <div className={ className }>
          <Tabs
            hidden={
              mode !== 'addCabinet' ||
              generalViewMode === 'floorPlan'
            }
          />
          <CabinetMenu
            hidden={
              mode !== 'editCabinetOnScene' ||
              generalViewMode === 'floorPlan'
            }
          />
          { mode === 'editCabinetOnCreation' && mode }
          { generalViewMode === 'floorPlan' &&
            <RightMenuForFloorPlan mode={ mode } />}
        </div>
      );
    }
  }
);
