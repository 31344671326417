import { PureComponent } from 'react';

export class Setting extends PureComponent {

  static defaultProps = {
    title: '',
    variant: false,
    onClick: () => {},
    className: ''
  };

}
