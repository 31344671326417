// @ts-check
import { batchActions } from 'redux-batched-actions';
/**
 * @typedef { redux.batchActionsWithTempl<
 *    redux.actions.user.setUserLogin |
 *    redux.actions.user.setUserFullName |
 *    redux.actions.user.setLoginSuccess
 *  >
 * } batchForLogin
 */
const bForLogin = /** @type { batchForLogin } */( batchActions );

export function validateAccountData (
  /** @type { Partial<redux.actions.validateAccountDataArgument> } */
  {
    email, oldPassword, password, confirmPassword
  }
) {
  if( !email ) return 'Email cannot be empty.';
  if( !password && !oldPassword && !confirmPassword ) {
    return '__simple';
  }

  if( !oldPassword ) return 'Cannot set new password without old one.';
  if( !password ) return 'New Password cannot be empty.';

  if( oldPassword === password ) {
    return 'New password cannot be equal to the old one.';
  }

  if( password !== confirmPassword ) {
    return 'Conirm password doesn`t match new one.';
  }

  return '__complex';
}

/**
 * @type { (arg: redux.actions.generalLoginArg) =>
 *  import('redux-thunk').ThunkAction<
 *    Promise<feathersForAuth.usersService>,
 *    redux.store,
 *    {},
 *    import('redux-batched-actions').BatchAction
 *  >
 * }
 */
export const generalLogin = function generalLogin ( arg ) {
  return ( dispatch ) => {
    const { strategy, feathers } = arg;

    /** @type { ReturnType<feathersForAuth.feathers['authenticate']> } */
    let promise;
    /** @type { feathersForAuth.feathersAccessToken } */
    let accToken;

    if( arg.strategy === 'jwt' ) {
      promise = feathers.authenticate( { strategy: arg.strategy } );
    } else {
      const { login, password } = arg;
      promise = feathers.authenticate( { strategy, email: login, password } );
    }

    return promise
      .then( ( r ) => {
        accToken = r.accessToken;

        return feathers.passport.verifyJWT( accToken );
      } )
      .then( ( p ) => {
      /** @type { feathersForAuth.serviceObject<feathersForAuth.usersService> } */
        const usersService = feathers.service( 'api/users' );

        return usersService.get( p.userId );
      } )
      .then( ( user ) => {
        const { email, fullName = '' } = user;

        dispatch( bForLogin( [
          {
            type: 'SET_USER_LOGIN',
            value: /** @type { redux.store['user']['login'] } */ ( email )
          },
          {
            type: 'SET_USER_FULL_NAME',
            value: /** @type { redux.store['user']['fullName'] } */ ( fullName )
          },
          {
            type: 'SET_LOGIN_SUCCESS',
            value: /** @type { redux.store['user']['loginSuccess'] } */ (
              localStorage.getItem( 'feathers-jwt' )
            )
          }
        ] ) );

        return Promise.resolve( user );
      } );
  };
};
