import { simpleSet } from '@r/reducers/utils';
import { _ISimpleSetObj, AllActions } from '@r/storeUtils';

const setCabinetsMeasurePoint = simpleSet as _ISimpleSetObj<
  redux._IConstants['SET_CABINETS_MEASURE_POINT'],
  redux._IStore['flags']['cabinetsMeasurePoint'],
  AllActions
>;

export const cabinetsMeasurePoint = setCabinetsMeasurePoint(
  'SET_CABINETS_MEASURE_POINT',
  'toEdge'
);
