// @ts-check
import React, { PureComponent } from 'react';
import ThemeButton from 'c/shared/ThemeButton';
import { connect } from 'react-redux';

/**
 * @typedef {{
 *  dispatch: redux.d< redux.actions['setSingletonCabinetJsonModalOpen'] >
 * }} Props
 */
export default connect()(
  /** @augments { PureComponent<Props> } */
  class Json extends PureComponent {
    openJsonModal = () => this.props.dispatch( {
      type: 'SET_SINGLETON_CABINET_JSON_MODAL_OPEN',
      value: true
    } )

    render() {
      return (
        <div style={ { marginTop: '20px' } }>
          <ThemeButton onClick={ this.openJsonModal }>
            Edit config
          </ThemeButton>
        </div>
      );
    }
  }
);
