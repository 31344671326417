import { simpleSet } from '@r/reducers/utils';

export const shippingCost = simpleSet(
  'SET_CALCULATION_MODE_ADMIN_SETTINGS_SHIPPING_COST',
  []
);

export function updateItem( index, propName, value ) {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        adminSettings: {
          projectSettings: {
            shippingCost
          }
        }
      }
    } = getStore();

    const newData = shippingCost.slice();

    if ( propName === 'name' ) {
      newData[ index ][ propName ] = value;
    } else {
      newData[ index ][ propName ] = {
        label: value,
        value: Number( value )
      };
    }

    dispatch( {
      type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_SHIPPING_COST',
      value: newData
    } );
  };
}
