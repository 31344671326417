import { createPhysicalMaterial } from
  'c/ThreeJsWrap/Viewer/core/helpers/material';
import {
  MeshBasicMaterial,
  MeshStandardMaterial,
  Shape,
  Math as TMath,
  DoubleSide
} from 'three';
import AssetsLoader from '../core/loaders/AssetsLoader';
import EventEmitter from '../core/loaders/EventEmitter';

export default class Loader extends EventEmitter {
  constructor() {
    super();
    this.assets = {};
    this.initShapes();

    AssetsLoader.loadAssets( './assets/textures/wall/walltextures.json' )
      .then( ( assets ) => {
        this.assets.wall = assets;

        // eslint-disable-next-line max-len
        return AssetsLoader.loadAssets( './assets/textures/floor/floortextures.json' );
      } )
      .then( ( assets ) => {
        this.assets.floor = assets;

        // eslint-disable-next-line max-len
        return AssetsLoader.loadAssets( './assets/textures/countertop/countertoptextures.json' );
      } )
      .then( ( assets ) => {
        this.assets.countertop = assets;

        // eslint-disable-next-line max-len
        return AssetsLoader.loadAssets( './assets/textures/cabinet/cabinettextures.json' );
      } )
      .then( ( assets ) => {
        this.assets.cabinet = assets;

        // eslint-disable-next-line max-len
        return AssetsLoader.loadAssets( './assets/textures/handle/handletextures.json' );
      } )
      .then( ( assets ) => {
        this.assets.handle = assets;
        this.initMaterials();

        return AssetsLoader.loadAssets( './assets/textures/background/backgroundtextures.json' );

      } )
      .then( ( assets ) => {
        this.assets.background = assets;

        return AssetsLoader.loadAssets( './assets/models/items/items.json' );
      } )
      .then( ( assets ) => {
        this.assets.item = assets;
        this.dispatch( {
          type: 'load',
          data: { assets: this.assets, materials: this.materials, shapes: this.shapes }
        } );
      } );

  }

  initShapes() {
    this.shapes = {};
    this.shapes.crownMoldings = [
      {
        name: 'CM01',
        uiName: 'CM01',
        img: '/assets/textures/shape/CM01/CM01.png',
        shape: {
          obj: new Shape()
            .moveTo( 0, 0 )
            .lineTo( -0.625, 0 )
            .lineTo( -0.625, 0.625 )
            .lineTo( 1.25, 2.5 )
            .lineTo( 1.875, 2.5 )
            .lineTo( 1.875, 1.875 )
            .absarc( 2.8158, -0.9408, 2.9688, TMath.degToRad( 108.5 ), TMath.degToRad( 161.5 ), false ),
          minX: -0.625,
          maxX: 1.875,
          minY: 0,
          maxY: 2.5
        }
      },
      {
        name: 'CM303',
        uiName: 'CM303',
        img: '/assets/textures/shape/CM303/CM303.png',
        shape: {
          obj: new Shape()
            .moveTo( 0, 0 )
            .lineTo( -0.3125, 0 )
            .lineTo( -0.3125, 0.8125 )
            .lineTo( 1.05356, 2 )
            .lineTo( 1.5625, 2 )
            .absarc( 0.53789, 1.83886, 1.0372, TMath.degToRad( 9 ), TMath.degToRad( -82 ), true )
            .lineTo( 0.6875, 0.6875 )
            .absarc( 0.6875, 0, 0.6875, TMath.degToRad( 90 ), TMath.degToRad( 180 ), false ),
          minX: -0.3125,
          maxX: 1.57477,
          minY: 0,
          maxY: 2
        }
      },
      {
        name: 'Rectangle',
        uiName: 'Rectangle',
        img: '/assets/textures/shape/rectangle/rectangle.png',
        shape: {
          obj: new Shape()
            .moveTo( 0, 0 )
            .lineTo( -0.2, 0 )
            .lineTo( -0.2, 2 )
            .lineTo( 0, 2 )
            .lineTo( 0, 0 ),
          minX: -0.2,
          maxX: 0,
          minY: 0,
          maxY: 2
        }
      }
    ];
  }

  initModels() {
    this.models = {};
    this.models.inWallItems = [
      {
        name: 'simpleWindow',
        model: this.assets.inWallItems[ 0 ]
      }
    ];
  }

  initMaterials() {
    this.materials = {};

    const glassOpacity = 0.2;
    this.materials.glass = [
      {
        name: 'white',
        uiName: 'White',
        img: '/assets/textures/crownMolding/white.png',
        material: new MeshStandardMaterial( { color: 0xdddddd, transparent: true, opacity:glassOpacity  } )
      },
      {
        name: 'green',
        uiName: 'Green',
        img: '/assets/textures/crownMolding/green.png',
        material: new MeshStandardMaterial( { color: 0x00aa00, transparent: true, opacity:glassOpacity } )
      },
      {
        name: 'blue',
        uiName: 'Blue',
        img: '/assets/textures/crownMolding/blue.png',
        material: new MeshStandardMaterial( { color: 0x0000aa, transparent: true, opacity:glassOpacity } )
      }
    ];

    this.materials.crownMolding = [
      {
        name: 'white',
        uiName: 'White',
        img: '/assets/textures/crownMolding/white.png',
        material: new MeshStandardMaterial( { color: 0xdddddd } )
      },
      {
        name: 'green',
        uiName: 'Green',
        img: '/assets/textures/crownMolding/green.png',
        material: new MeshStandardMaterial( { color: 0x00aa00 } )
      },
      {
        name: 'red',
        uiName: 'Red',
        img: '/assets/textures/crownMolding/red.png',
        material: new MeshStandardMaterial( { color: 0xaa0000 } )
      },
      {
        name: 'blue',
        uiName: 'Blue',
        img: '/assets/textures/crownMolding/blue.png',
        material: new MeshStandardMaterial( { color: 0x0000aa } )
      }
    ];


    this.materials.service = [
      {
        name: 'invisible',
        material: new MeshBasicMaterial( { side: DoubleSide, visible: false } )
      }
    ];

    this.materials.wall = [
      {
        name: 'lightBrick',
        uiName: 'Light brick',
        img: '/assets/textures/wall/light_brick/light_brick.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 0 ].image,
          this.assets.wall[ 1 ].image,
          this.assets.wall[ 2 ].image
        )
      },
      {
        name: 'marbletiles',
        uiName: 'Marble tiles',
        img: '/assets/textures/wall/marbletiles/marbletiles_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 3 ].image,
          this.assets.wall[ 4 ].image,
          this.assets.wall[ 5 ].image
        )
      },
      {
        name: 'brickwall002',
        uiName: 'Bricks 002',
        img: '/assets/textures/wall/brickwall002/brickwall002_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 6 ].image,
          this.assets.wall[ 7 ].image,
          this.assets.wall[ 8 ].image
        )
      },
      {
        name: 'brickwall007',
        uiName: 'Bricks 007',
        img: '/assets/textures/wall/brickwall007/brickwall007_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 9 ].image,
          this.assets.wall[ 10 ].image,
          this.assets.wall[ 11 ].image
        )
      },
      {
        name: 'brickwall010',
        uiName: 'Bricks 010',
        img: '/assets/textures/wall/brickwall010/brickwall010_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 12 ].image,
          this.assets.wall[ 13 ].image,
          this.assets.wall[ 14 ].image
        )
      },
      {
        name: 'brickwall012',
        uiName: 'Bricks 012',
        img: '/assets/textures/wall/brickwall012/brickwall012_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 15 ].image,
          this.assets.wall[ 16 ].image,
          this.assets.wall[ 17 ].image
        )
      },
      {
        name: 'ogwall001',
        uiName: 'Old graffity 001',
        img: '/assets/textures/wall/ogwall001/ogwall001_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 18 ].image,
          this.assets.wall[ 19 ].image,
          this.assets.wall[ 20 ].image
        )
      },
      {
        name: 'walldamaged001',
        uiName: 'Damaged wall 001',
        img: '/assets/textures/wall/walldamaged001/walldamaged001_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 21 ].image,
          this.assets.wall[ 22 ].image,
          this.assets.wall[ 23 ].image
        )
      },
      {
        name: 'wallstone003',
        uiName: 'Stone 003',
        img: '/assets/textures/wall/wallstone003/wallstone003_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 24 ].image,
          this.assets.wall[ 25 ].image,
          this.assets.wall[ 26 ].image
        )
      },
      {
        name: 'wallstone004',
        uiName: 'Stone 004',
        img: '/assets/textures/wall/wallstone004/wallstone004_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 27 ].image,
          this.assets.wall[ 28 ].image,
          this.assets.wall[ 29 ].image
        )
      },
      {
        name: 'wallstone008',
        uiName: 'Stone 008',
        img: '/assets/textures/wall/wallstone008/wallstone008_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 30 ].image,
          this.assets.wall[ 31 ].image,
          this.assets.wall[ 32 ].image
        )
      },
      {
        name: 'wallstone009',
        uiName: 'Stone 009',
        img: '/assets/textures/wall/wallstone009/wallstone009_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 33 ].image,
          this.assets.wall[ 34 ].image,
          this.assets.wall[ 35 ].image
        )
      },
      {
        name: 'wallstone010',
        uiName: 'Stone 010',
        img: '/assets/textures/wall/wallstone010/wallstone010_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 36 ].image,
          this.assets.wall[ 37 ].image,
          this.assets.wall[ 38 ].image
        )
      },
      {
        name: 'painted_wall',
        uiName: 'Painted white',
        img: '/assets/textures/wall/painted_wall/painted_wall_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 39 ].image,
          this.assets.wall[ 40 ].image,
          this.assets.wall[ 41 ].image
        )
      },
      {
        name: 'painted_wall_yellow',
        uiName: 'Painted yellow',
        img: '/assets/textures/wall/painted_wall_yellow/painted_wall_yellow_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.wall[ 42 ].image,
          this.assets.wall[ 43 ].image,
          this.assets.wall[ 44 ].image
        )
      }
    ];


    this.materials.floor = [
      {
        name: 'fineWood',
        uiName: 'Fine Wood',
        img: '/assets/textures/floor/fine_wood/fine_wood.resources/gp5202-light_fine_wood.png',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 0 ].image,
          this.assets.floor[ 1 ].image,
          this.assets.floor[ 2 ].image
        )
      },
      {
        name: 'hardWood',
        uiName: 'Hard Wood',
        img: '/assets/textures/floor/hardwood/hardwood.resources/yX5202-hardwood.png',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 3 ].image,
          this.assets.floor[ 4 ].image,
          this.assets.floor[ 5 ].image
        )
      },
      {
        name: 'wood001',
        uiName: 'Wood 001',
        img: '/assets/textures/floor/wood001/wood001_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 6 ].image,
          this.assets.floor[ 7 ].image,
          this.assets.floor[ 8 ].image
        )
      },
      {
        name: 'wood002',
        uiName: 'Wood 002',
        img: '/assets/textures/floor/wood002/wood002_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 9 ].image,
          this.assets.floor[ 10 ].image,
          this.assets.floor[ 11 ].image
        )
      },
      {
        name: 'wood003',
        uiName: 'Wood 003',
        img: '/assets/textures/floor/wood003/wood003_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 12 ].image,
          this.assets.floor[ 13 ].image,
          this.assets.floor[ 14 ].image
        )
      },
      {
        name: 'wood004',
        uiName: 'Wood 004',
        img: '/assets/textures/floor/wood004/wood004_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 15 ].image,
          this.assets.floor[ 16 ].image,
          this.assets.floor[ 17 ].image
        )
      },
      {
        name: 'wood005',
        uiName: 'Wood 005',
        img: '/assets/textures/floor/wood005/wood005_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 18 ].image,
          this.assets.floor[ 19 ].image,
          this.assets.floor[ 20 ].image
        )
      },
      {
        name: 'wood006',
        uiName: 'Wood 006',
        img: '/assets/textures/floor/wood006/wood006_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 21 ].image,
          this.assets.floor[ 22 ].image,
          this.assets.floor[ 23 ].image
        )
      },
      {
        name: 'wood007',
        uiName: 'Wood 007',
        img: '/assets/textures/floor/wood007/wood007_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 24 ].image,
          this.assets.floor[ 25 ].image,
          this.assets.floor[ 26 ].image
        )
      },
      {
        name: 'wood008',
        uiName: 'Wood 008',
        img: '/assets/textures/floor/wood008/wood008_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 27 ].image,
          this.assets.floor[ 28 ].image,
          this.assets.floor[ 29 ].image
        )
      },
      {
        name: 'sahara_sand',
        uiName: 'Sahara Sand',
        img: '/assets/textures/floor/sahara_sand/sahara_sand_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 30 ].image,
          this.assets.floor[ 31 ].image,
          this.assets.floor[ 32 ].image
        )
      },
      {
        name: 'sand_hard',
        uiName: 'Sand Hard',
        img: '/assets/textures/floor/sand_hard/sand_hard_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 33 ].image,
          this.assets.floor[ 34 ].image,
          this.assets.floor[ 35 ].image
        )
      },
      {
        name: 'sWood1',
        uiName: 'Wood S',
        img: '/assets/textures/floor/sWood1/sWood1_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 36 ].image,
          this.assets.floor[ 37 ].image,
          this.assets.floor[ 38 ].image
        )
      },
      {
        name: 'terrazzo001',
        uiName: 'Terrazzo',
        img: '/assets/textures/floor/terrazzo001/terrazzo001_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 39 ].image,
          this.assets.floor[ 40 ].image,
          this.assets.floor[ 41 ].image
        )
      },
      {
        name: 'slateflooring001',
        uiName: 'Slate Flooring 001',
        img: '/assets/textures/floor/slateflooring001/slateflooring001_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 42 ].image,
          this.assets.floor[ 43 ].image,
          this.assets.floor[ 44 ].image
        )
      },
      {
        name: 'stone003',
        uiName: 'Stone 003',
        img: '/assets/textures/floor/stone003/stone003_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 45 ].image,
          this.assets.floor[ 46 ].image,
          this.assets.floor[ 47 ].image
        )
      },
      {
        name: 'asphalt002',
        uiName: 'Asphalt 002',
        img: '/assets/textures/floor/asphalt002/asphalt002_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 48 ].image,
          this.assets.floor[ 49 ].image,
          this.assets.floor[ 50 ].image
        )
      },
      {
        name: 'terracota001',
        uiName: 'Terracota 001',
        img: '/assets/textures/floor/terracota001/terracota001_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 51 ].image,
          this.assets.floor[ 52 ].image,
          this.assets.floor[ 53 ].image
        )
      },
      {
        name: 'pebbles007',
        uiName: 'Pebbles 007',
        img: '/assets/textures/floor/pebbles007/pebbles007_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.floor[ 54 ].image,
          this.assets.floor[ 55 ].image,
          this.assets.floor[ 56 ].image
        )
      }
    ];


    this.materials.countertop = [
      {
        name: 'wood009',
        uiName: 'Wood 009',
        img: '/assets/textures/countertop/wood009/wood009_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 0 ].image,
          this.assets.countertop[ 1 ].image,
          this.assets.countertop[ 2 ].image
        )
      },
      {
        name: 'marbleblue003',
        uiName: 'Marble Blue 003',
        img: '/assets/textures/countertop/marbleblue003/marbleblue003_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 3 ].image,
          this.assets.countertop[ 4 ].image,
          this.assets.countertop[ 5 ].image
        )
      },
      {
        name: 'marblered003',
        uiName: 'Marble Red 003',
        img: '/assets/textures/countertop/marblered003/marblered003_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 6 ].image,
          this.assets.countertop[ 7 ].image,
          this.assets.countertop[ 8 ].image
        )
      },
      {
        name: 'marblewhite005',
        uiName: 'Marble White 005',
        img: '/assets/textures/countertop/marblewhite005/marblewhite005_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 9 ].image,
          this.assets.countertop[ 10 ].image,
          this.assets.countertop[ 11 ].image
        )
      },
      {
        name: 'alpinawhite',
        uiName: 'Alpina White',
        img: '/assets/textures/countertop/alpinawhite/alpinawhite_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 12 ].image,
          this.assets.countertop[ 13 ].image,
          this.assets.countertop[ 14 ].image
        )
      },
      {
        name: 'ariel',
        uiName: 'Ariel',
        img: '/assets/textures/countertop/ariel/ariel_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 15 ].image,
          this.assets.countertop[ 16 ].image,
          this.assets.countertop[ 17 ].image
        )
      },
      {
        name: 'aura',
        uiName: 'Aura',
        img: '/assets/textures/countertop/aura/aura_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 18 ].image,
          this.assets.countertop[ 19 ].image,
          this.assets.countertop[ 20 ].image
        )
      },
      {
        name: 'aura15',
        uiName: 'Aura 15',
        img: '/assets/textures/countertop/aura15/aura15_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 21 ].image,
          this.assets.countertop[ 22 ].image,
          this.assets.countertop[ 23 ].image
        )
      },
      {
        name: 'biancoantico',
        uiName: 'Bianco Antico',
        img: '/assets/textures/countertop/biancoantico/biancoantico_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 24 ].image,
          this.assets.countertop[ 25 ].image,
          this.assets.countertop[ 26 ].image
        )
      },
      {
        name: 'biancoriver',
        uiName: 'Bianco River',
        img: '/assets/textures/countertop/biancoriver/biancoriver_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 27 ].image,
          this.assets.countertop[ 28 ].image,
          this.assets.countertop[ 29 ].image
        )
      },
      {
        name: 'blancconcrete',
        uiName: 'Blanc Concrete',
        img: '/assets/textures/countertop/blancconcrete/blancconcrete_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 30 ].image,
          this.assets.countertop[ 31 ].image,
          this.assets.countertop[ 32 ].image
        )
      },
      {
        name: 'blancomaple',
        uiName: 'Blanco Maple',
        img: '/assets/textures/countertop/blancomaple/blancomaple_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 33 ].image,
          this.assets.countertop[ 34 ].image,
          this.assets.countertop[ 35 ].image
        )
      },
      {
        name: 'blancoorion',
        uiName: 'Blanco Orion',
        img: '/assets/textures/countertop/blancoorion/blancoorion_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 36 ].image,
          this.assets.countertop[ 37 ].image,
          this.assets.countertop[ 38 ].image
        )
      },
      {
        name: 'caprilimestone',
        uiName: 'Capri Limestone',
        img: '/assets/textures/countertop/caprilimestone/caprilimestone_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 39 ].image,
          this.assets.countertop[ 40 ].image,
          this.assets.countertop[ 41 ].image
        )
      },
      {
        name: 'classicwhite',
        uiName: 'Classic White',
        img: '/assets/textures/countertop/classicwhite/classicwhite_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 42 ].image,
          this.assets.countertop[ 43 ].image,
          this.assets.countertop[ 44 ].image
        )
      },
      {
        name: 'colonialwhite',
        uiName: 'Colonial White',
        img: '/assets/textures/countertop/colonialwhite/colonialwhite_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 45 ].image,
          this.assets.countertop[ 46 ].image,
          this.assets.countertop[ 47 ].image
        )
      }
      // {
      //   name: 'entzo',
      //   uiName: 'Entzo',
      //   img: '/assets/textures/countertop/entzo/entzo_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[48].image,
      //     this.assets.countertop[49].image,
      //     this.assets.countertop[50].image
      //   )
      // },
      // {
      //   name: 'etcalacattagold',
      //   uiName: 'Et Calacatta Gold',
      //   img: '/assets/textures/countertop/etcalacattagold/etcalacattagold_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[51].image,
      //     this.assets.countertop[52].image,
      //     this.assets.countertop[53].image
      //   )
      // },
      // {
      //   name: 'etstatuario',
      //   uiName: 'Et Statuario',
      //   img: '/assets/textures/countertop/etstatuario/etstatuario_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[54].image,
      //     this.assets.countertop[55].image,
      //     this.assets.countertop[56].image
      //   )
      // },
      // {
      //   name: 'fiord',
      //   uiName: 'Fiord',
      //   img: '/assets/textures/countertop/fiord/fiord_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[57].image,
      //     this.assets.countertop[58].image,
      //     this.assets.countertop[59].image
      //   )
      // },
      // {
      //   name: 'gada',
      //   uiName: 'Gada',
      //   img: '/assets/textures/countertop/gada/gada_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[60].image,
      //     this.assets.countertop[61].image,
      //     this.assets.countertop[62].image
      //   )
      // },
      // {
      //   name: 'glacier',
      //   uiName: 'Glacier',
      //   img: '/assets/textures/countertop/glacier/glacier_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[63].image,
      //     this.assets.countertop[64].image,
      //     this.assets.countertop[65].image
      //   )
      // },
      // {
      //   name: 'halo',
      //   uiName: 'Halo',
      //   img: '/assets/textures/countertop/halo/halo_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[66].image,
      //     this.assets.countertop[67].image,
      //     this.assets.countertop[68].image
      //   )
      // },
      // {
      //   name: 'iceblue',
      //   uiName: 'Ice Blue',
      //   img: '/assets/textures/countertop/iceblue/iceblue_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[69].image,
      //     this.assets.countertop[70].image,
      //     this.assets.countertop[71].image
      //   )
      // },
      // {
      //   name: 'iconicwhite',
      //   uiName: 'Iconic White',
      //   img: '/assets/textures/countertop/iconicwhite/iconicwhite_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[72].image,
      //     this.assets.countertop[73].image,
      //     this.assets.countertop[74].image
      //   )
      // },
      // {
      //   name: 'irok',
      //   uiName: 'Irok',
      //   img: '/assets/textures/countertop/irok/irok_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[75].image,
      //     this.assets.countertop[76].image,
      //     this.assets.countertop[77].image
      //   )
      // },
      // {
      //   name: 'ironbark',
      //   uiName: 'Iron Bark',
      //   img: '/assets/textures/countertop/ironbark/ironbark_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[78].image,
      //     this.assets.countertop[79].image,
      //     this.assets.countertop[80].image
      //   )
      // },
      // {
      //   name: 'kairos',
      //   uiName: 'Kairos',
      //   img: '/assets/textures/countertop/kairos/kairos_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[81].image,
      //     this.assets.countertop[82].image,
      //     this.assets.countertop[83].image
      //   )
      // },
      // {
      //   name: 'lagoon',
      //   uiName: 'Lagoon',
      //   img: '/assets/textures/countertop/lagoon/lagoon_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[84].image,
      //     this.assets.countertop[85].image,
      //     this.assets.countertop[86].image
      //   )
      // },
      // {
      //   name: 'luna',
      //   uiName: 'Luna',
      //   img: '/assets/textures/countertop/luna/luna_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[87].image,
      //     this.assets.countertop[88].image,
      //     this.assets.countertop[89].image
      //   )
      // },
      // {
      //   name: 'lusso',
      //   uiName: 'Lusso',
      //   img: '/assets/textures/countertop/lusso/lusso_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[90].image,
      //     this.assets.countertop[91].image,
      //     this.assets.countertop[92].image
      //   )
      // },
      // {
      //   name: 'lyra',
      //   uiName: 'Lyra',
      //   img: '/assets/textures/countertop/lyra/lyra_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[93].image,
      //     this.assets.countertop[94].image,
      //     this.assets.countertop[95].image
      //   )
      // },
      // {
      //   name: 'makai',
      //   uiName: 'Makai',
      //   img: '/assets/textures/countertop/makai/makai_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[96].image,
      //     this.assets.countertop[97].image,
      //     this.assets.countertop[98].image
      //   )
      // },
      // {
      //   name: 'merope',
      //   uiName: 'Merope',
      //   img: '/assets/textures/countertop/merope/merope_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[99].image,
      //     this.assets.countertop[100].image,
      //     this.assets.countertop[101].image
      //   )
      // },
      // {
      //   name: 'nayla',
      //   uiName: 'Nayla',
      //   img: '/assets/textures/countertop/nayla/nayla_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[102].image,
      //     this.assets.countertop[103].image,
      //     this.assets.countertop[104].image
      //   )
      // },
      // {
      //   name: 'orinoco',
      //   uiName: 'Orinoco',
      //   img: '/assets/textures/countertop/orinoco/orinoco_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[105].image,
      //     this.assets.countertop[106].image,
      //     this.assets.countertop[107].image
      //   )
      // },
      // {
      //   name: 'snowyibiza',
      //   uiName: 'Snowy Ibiza',
      //   img: '/assets/textures/countertop/snowyibiza/snowyibiza_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[108].image,
      //     this.assets.countertop[109].image,
      //     this.assets.countertop[110].image
      //   )
      // },
      // {
      //   name: 'tundra',
      //   uiName: 'Tundra',
      //   img: '/assets/textures/countertop/tundra/tundra_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[111].image,
      //     this.assets.countertop[112].image,
      //     this.assets.countertop[113].image
      //   )
      // },
      // {
      //   name: 'vienna',
      //   uiName: 'Vienna',
      //   img: '/assets/textures/countertop/vienna/vienna_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[114].image,
      //     this.assets.countertop[115].image,
      //     this.assets.countertop[116].image
      //   )
      // },
      // {
      //   name: 'vortium',
      //   uiName: 'Vortium',
      //   img: '/assets/textures/countertop/vortium/vortium_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[117].image,
      //     this.assets.countertop[118].image,
      //     this.assets.countertop[119].image
      //   )
      // },
      // {
      //   name: 'whiteplatinum',
      //   uiName: 'White Platinum',
      //   img: '/assets/textures/countertop/whiteplatinum/whiteplatinum_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[120].image,
      //     this.assets.countertop[121].image,
      //     this.assets.countertop[122].image
      //   )
      // },
      // {
      //   name: 'whitezeusextreme',
      //   uiName: 'White Zeus Extreme',
      //   img: '/assets/textures/countertop/whitezeusextreme/whitezeusextreme_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[123].image,
      //     this.assets.countertop[124].image,
      //     this.assets.countertop[125].image
      //   )
      // },
      // {
      //   name: 'yukonblanco',
      //   uiName: 'Yukon Blanco',
      //   img: '/assets/textures/countertop/yukonblanco/yukonblanco_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[126].image,
      //     this.assets.countertop[127].image,
      //     this.assets.countertop[128].image
      //   )
      // },
      // {
      //   name: 'zenith',
      //   uiName: 'Zenith',
      //   img: '/assets/textures/countertop/zenith/zenith_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.countertop[129].image,
      //     this.assets.countertop[130].image,
      //     this.assets.countertop[131].image
      //   )
      // }
    ];


    this.materials.cabinet = [
      {
        name: 'wood011',
        uiName: 'Wood 011',
        img: '/assets/textures/cabinet/wood011/wood011_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 0 ].image,
          this.assets.cabinet[ 1 ].image,
          this.assets.cabinet[ 2 ].image
        )
      },
      {
        name: 'oldwood001',
        uiName: 'Old Wood 001',
        img: '/assets/textures/cabinet/oldwood001/oldwood001_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 3 ].image,
          this.assets.cabinet[ 4 ].image,
          this.assets.cabinet[ 5 ].image
        )
      },
      {
        name: 'particleboard001',
        uiName: 'Particle Board 001',
        img: '/assets/textures/cabinet/particleboard001/particleboard001_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 6 ].image,
          this.assets.cabinet[ 7 ].image,
          this.assets.cabinet[ 8 ].image
        )
      },
      {
        name: 'wood007',
        uiName: 'Wood 007',
        img: '/assets/textures/cabinet/wood007/wood007_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 9 ].image,
          this.assets.cabinet[ 10 ].image,
          this.assets.cabinet[ 11 ].image
        )
      },
      {
        name: 'wood008',
        uiName: 'Wood 008',
        img: '/assets/textures/cabinet/wood008/wood008_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 12 ].image,
          this.assets.cabinet[ 13 ].image,
          this.assets.cabinet[ 14 ].image
        )
      },
      {
        name: 'H56_Chill_Frisquet',
        uiName: 'H56 Chill Frisquet',
        img: '/assets/textures/cabinet/aura/H56_Chill_Frisquet/H56_Chill_Frisquet_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 15 ].image,
          this.assets.cabinet[ 16 ].image,
          this.assets.cabinet[ 17 ].image
        )
      },
      {
        name: 'H57_Haze_Brume',
        uiName: 'H57 Haze Brume',
        img: '/assets/textures/cabinet/aura/H57_Haze_Brume/H57_Haze_Brume_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 18 ].image,
          this.assets.cabinet[ 19 ].image,
          this.assets.cabinet[ 20 ].image
        )
      },
      {
        name: 'H58_Storm_Tempete',
        uiName: 'H58 Storm Tempete',
        img: '/assets/textures/cabinet/aura/H58_Storm_Tempete/H58_Storm_Tempete_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 21 ].image,
          this.assets.cabinet[ 22 ].image,
          this.assets.cabinet[ 23 ].image
        )
      },
      {
        name: 'H59_Twilight_Crepuscule',
        uiName: 'H59 Twilight Crepuscule',
        img: '/assets/textures/cabinet/aura/H59_Twilight_Crepuscule/H59_Twilight_Crepuscule_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 24 ].image,
          this.assets.cabinet[ 25 ].image,
          this.assets.cabinet[ 26 ].image
        )
      },
      {
        name: 'H67_Kindle_Feu',
        uiName: 'H67 Kindle Feu',
        img: '/assets/textures/cabinet/aura/H67_Kindle_Feu/H67_Kindle_Feu_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 27 ].image,
          this.assets.cabinet[ 28 ].image,
          this.assets.cabinet[ 29 ].image
        )
      },
      {
        name: 'H72_Ginger_Root_Gingembre',
        uiName: 'H72 Ginger Root Gingembre',
        img: '/assets/textures/cabinet/aura/H72_Ginger_Root_Gingembre/H72_Ginger_Root_Gingembre_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 30 ].image,
          this.assets.cabinet[ 31 ].image,
          this.assets.cabinet[ 32 ].image
        )
      },
      {
        name: 'H73_Cocoa_Bean_Graine_de_Cacao',
        uiName: 'H73 Cocoa Bean Graine de Cacao',
        img: '/assets/textures/cabinet/aura/H73_Cocoa_Bean_Graine_de_Cacao/H73_Cocoa_Bean_Graine_de_Cacao_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 33 ].image,
          this.assets.cabinet[ 34 ].image,
          this.assets.cabinet[ 35 ].image
        )
      },
      {
        name: 'H74_Blackwood_BoisNoir',
        uiName: 'H74 Blackwood BoisNoir',
        img: '/assets/textures/cabinet/aura/H74_Blackwood_BoisNoir/H74_Blackwood_BoisNoir_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 36 ].image,
          this.assets.cabinet[ 37 ].image,
          this.assets.cabinet[ 38 ].image
        )
      },
      {
        name: 'H75_Tundra_Tundra',
        uiName: 'H75 Tundra Tundra',
        img: '/assets/textures/cabinet/aura/H75_Tundra_Tundra/H75_Tundra_Tundra_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 39 ].image,
          this.assets.cabinet[ 40 ].image,
          this.assets.cabinet[ 41 ].image
        )
      },
      {
        name: 'K01_Nutmeg_Muscade',
        uiName: 'K01 Nutmeg Muscade',
        img: '/assets/textures/cabinet/aura/K01_Nutmeg_Muscade/K01_Nutmeg_Muscade_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 42 ].image,
          this.assets.cabinet[ 43 ].image,
          this.assets.cabinet[ 44 ].image
        )
      },
      {
        name: 'K02_Sesame_Sesame',
        uiName: 'K02 Sesame Sesame',
        img: '/assets/textures/cabinet/aura/K02_Sesame_Sesame/K02_Sesame_Sesame_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 45 ].image,
          this.assets.cabinet[ 46 ].image,
          this.assets.cabinet[ 47 ].image
        )
      },
      {
        name: 'K03_Cardamom_Cardamome',
        uiName: 'K03 Cardamom Cardamome',
        img: '/assets/textures/cabinet/aura/K03_Cardamom_Cardamome/K03_Cardamom_Cardamome_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 48 ].image,
          this.assets.cabinet[ 49 ].image,
          this.assets.cabinet[ 50 ].image
        )
      },
      {
        name: 'K07_DewDro_PerledeRosee',
        uiName: 'K07 DewDro PerledeRosee',
        img: '/assets/textures/cabinet/aura/K07_DewDro_PerledeRosee/K07_DewDro_PerledeRosee_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 51 ].image,
          this.assets.cabinet[ 52 ].image,
          this.assets.cabinet[ 53 ].image
        )
      },
      {
        name: 'G50_Legno_Tabac_Legno_Tabac',
        uiName: 'G50 Legno Tabac Legno Tabac',
        img: '/assets/textures/cabinet/bistro/G50_Legno_Tabac_Legno_Tabac/G50_Legno_Tabac_Legno_Tabac_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 54 ].image,
          this.assets.cabinet[ 55 ].image,
          this.assets.cabinet[ 56 ].image
        )
      },
      {
        name: 'G51_Legno_Light_Legno_Pale',
        uiName: 'G51 Legno Light Legno Pale',
        img: '/assets/textures/cabinet/bistro/G51_Legno_Light_Legno_Pale/G51_Legno_Light_Legno_Pale_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 57 ].image,
          this.assets.cabinet[ 58 ].image,
          this.assets.cabinet[ 59 ].image
        )
      },
      {
        name: '123_Charcoal_Charbon',
        uiName: '123 Charcoal',
        img: '/assets/textures/cabinet/couleurs_unis/123_Charcoal_Charbon/123_Charcoal_Charbon_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 60 ].image
        )
      },
      {
        name: '300_Classic_White_Blanc',
        uiName: '300 Classic White Blanc',
        img: '/assets/textures/cabinet/couleurs_unis/300_Classic_White_Blanc/300_Classic_White_Blanc_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 61 ].image
        )
      },
      {
        name: '555_NovaWhite_NovaBlanc',
        uiName: '555 NovaWhite',
        img: '/assets/textures/cabinet/couleurs_unis/555_NovaWhite_NovaBlanc/555_NovaWhite_NovaBlanc_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 62 ].image
        )
      },
      {
        name: '888_NovaBlack_NovaNoir',
        uiName: '888 NovaBlack',
        img: '/assets/textures/cabinet/couleurs_unis/888_NovaBlack_NovaNoir/888_NovaBlack_NovaNoir_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 63 ].image
        )
      },
      {
        name: '934_Platinum_Platinum',
        uiName: '934 Platinum',
        img: '/assets/textures/cabinet/couleurs_unis/934_Platinum_Platinum/934_Platinum_Platinum_basecolor.jpg',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.cabinet[ 64 ].image,
          this.assets.cabinet[ 65 ].image,
          this.assets.cabinet[ 66 ].image
        )
      }


      // {
      //   name: 'G52_Legno_Dark_Legno_Fonce',
      //   uiName: 'G52 Legno Dark Legno Fonce',
      //   img: '/assets/textures/cabinet/bistro/G52_Legno_Dark_Legno_Fonce/G52_Legno_Dark_Legno_Fonce_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 60 ].image,
      //     this.assets.cabinet[ 61 ].image,
      //     this.assets.cabinet[ 62 ].image
      //   )
      // },
      // {
      //   name: 'G55_Dark_Oak_Chene_Fonce',
      //   uiName: 'G55 Dark Oak Chene Fonce',
      //   img: '/assets/textures/cabinet/bistro/G55_Dark_Oak_Chene_Fonce/G55_Dark_Oak_Chene_Fonce_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 63 ].image,
      //     this.assets.cabinet[ 64 ].image,
      //     this.assets.cabinet[ 65 ].image
      //   )
      // },
      // {
      //   name: 'G57_Dark_Mountain_Oak_Chene_Montagne_Fonce',
      //   uiName: 'G57 Dark Mountain Oak Chene Montagne Fonce',
      //   img: '/assets/textures/cabinet/bistro/G57_Dark_Mountain_Oak_Chene_Montagne_Fonce/G57_Dark_Mountain_Oak_Chene_Montagne_Fonce_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 66 ].image,
      //     this.assets.cabinet[ 67 ].image,
      //     this.assets.cabinet[ 68 ].image
      //   )
      // },
      // {
      //   name: 'G63_Acacia_Acacia',
      //   uiName: 'G63 Acacia Acacia',
      //   img: '/assets/textures/cabinet/bistro/G63_Acacia_Acacia/G63_Acacia_Acacia_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 69 ].image,
      //     this.assets.cabinet[ 70 ].image,
      //     this.assets.cabinet[ 71 ].image
      //   )
      // },
      // {
      //   name: 'K33_DoratoGrey_DoratoGris',
      //   uiName: 'K33 DoratoGrey DoratoGris',
      //   img: '/assets/textures/cabinet/bistro/K33_DoratoGrey_DoratoGris/K33_DoratoGrey_DoratoGris_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 72 ].image,
      //     this.assets.cabinet[ 73 ].image,
      //     this.assets.cabinet[ 74 ].image
      //   )
      // },
      // {
      //   name: 'K34_DoratoNatural_DoratoNaturel',
      //   uiName: 'K34 DoratoNatural DoratoNaturel',
      //   img: '/assets/textures/cabinet/bistro/K34_DoratoNatural_DoratoNaturel/K34_DoratoNatural_DoratoNaturel_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 75 ].image,
      //     this.assets.cabinet[ 76 ].image,
      //     this.assets.cabinet[ 77 ].image
      //   )
      // },
      // {
      //   name: 'K35_DoratoBrown_DoratoBrun',
      //   uiName: 'K35 DoratoBrown DoratoBrun',
      //   img: '/assets/textures/cabinet/bistro/K35_DoratoBrown_DoratoBrun/K35_DoratoBrown_DoratoBrun_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 78 ].image,
      //     this.assets.cabinet[ 79 ].image,
      //     this.assets.cabinet[ 80 ].image
      //   )
      // },
      // {
      //   name: 'H50_Silva_Silva',
      //   uiName: 'H50 Silva Silva',
      //   img: '/assets/textures/cabinet/brushedelm/H50_Silva_Silva/H50_Silva_Silva_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 81 ].image,
      //     this.assets.cabinet[ 82 ].image,
      //     this.assets.cabinet[ 83 ].image
      //   )
      // },
      // {
      //   name: 'H51_Zahara_Zahara',
      //   uiName: 'H51 Zahara Zahara',
      //   img: '/assets/textures/cabinet/brushedelm/H51_Zahara_Zahara/H51_Zahara_Zahara_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 84 ].image,
      //     this.assets.cabinet[ 85 ].image,
      //     this.assets.cabinet[ 86 ].image
      //   )
      // },
      // {
      //   name: 'H52_Riva_Riva',
      //   uiName: 'H52 Riva Riva',
      //   img: '/assets/textures/cabinet/brushedelm/H52_Riva_Riva/H52_Riva_Riva_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 87 ].image,
      //     this.assets.cabinet[ 88 ].image,
      //     this.assets.cabinet[ 89 ].image
      //   )
      // },
      // {
      //   name: 'H53_Ember_Cendre',
      //   uiName: 'H53 Ember Cendre',
      //   img: '/assets/textures/cabinet/brushedelm/H53_Ember_Cendre/H53_Ember_Cendre_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 90 ].image,
      //     this.assets.cabinet[ 91 ].image,
      //     this.assets.cabinet[ 92 ].image
      //   )
      // },
      // {
      //   name: 'H54_Skye_Skye',
      //   uiName: 'H54 Skye Skye',
      //   img: '/assets/textures/cabinet/brushedelm/H54_Skye_Skye/H54_Skye_Skye_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 93 ].image,
      //     this.assets.cabinet[ 94 ].image,
      //     this.assets.cabinet[ 95 ].image
      //   )
      // },
      // {
      //   name: 'H55_Mystic_Mystique',
      //   uiName: 'H55 Mystic Mystique',
      //   img: '/assets/textures/cabinet/brushedelm/H55_Mystic_Mystique/H55_Mystic_Mystique_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 96 ].image,
      //     this.assets.cabinet[ 97 ].image,
      //     this.assets.cabinet[ 98 ].image
      //   )
      // },
      // {
      //   name: 'H70_Driftwood_Derive',
      //   uiName: 'H70 Driftwood Derive',
      //   img: '/assets/textures/cabinet/brushedelm/H70_Driftwood_Derive/H70_Driftwood_Derive_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 99 ].image,
      //     this.assets.cabinet[ 100 ].image,
      //     this.assets.cabinet[ 101 ].image
      //   )
      // },
      // {
      //   name: 'H71_Luxe_Luxe',
      //   uiName: 'H71 Luxe Luxe',
      //   img: '/assets/textures/cabinet/brushedelm/H71_Luxe_Luxe/H71_Luxe_Luxe_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 102 ].image,
      //     this.assets.cabinet[ 103 ].image,
      //     this.assets.cabinet[ 104 ].image
      //   )
      // }
      // {
      //   name: 'G20_Sheer_Linen_Lin_Pur',
      //   uiName: 'G20 Sheer Linen Lin Pur',
      //   img: '/assets/textures/cabinet/calico/G20_Sheer_Linen_Lin_Pur/G20_Sheer_Linen_Lin_Pur_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 105 ].image,
      //     this.assets.cabinet[ 106 ].image,
      //     this.assets.cabinet[ 107 ].image
      //   )
      // },
      // {
      //   name: 'K19_Chino_Chino',
      //   uiName: 'K19 Chino Chino',
      //   img: '/assets/textures/cabinet/calico/K19_Chino_Chino/K19_Chino_Chino_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 108 ].image,
      //     this.assets.cabinet[ 109 ].image,
      //     this.assets.cabinet[ 110 ].image
      //   )
      // },
      // {
      //   name: 'K20_Frequency_Frequence',
      //   uiName: 'K20 Frequency Frequence',
      //   img: '/assets/textures/cabinet/calico/K20_Frequency_Frequence/K20_Frequency_Frequence_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 111 ].image,
      //     this.assets.cabinet[ 112 ].image,
      //     this.assets.cabinet[ 113 ].image
      //   )
      // },
      // {
      //   name: 'K21_Canvas_Canvas',
      //   uiName: 'K21 Canvas Canvas',
      //   img: '/assets/textures/cabinet/calico/K21_Canvas_Canvas/K21_Canvas_Canvas_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 114 ].image,
      //     this.assets.cabinet[ 115 ].image,
      //     this.assets.cabinet[ 116 ].image
      //   )
      // },
      // {
      //   name: '204_Bourbon_Cherry_Cerisier_Bourbon',
      //   uiName: '204 Bourbon Cherry Cerisier Bourbon',
      //   img: '/assets/textures/cabinet/dolomite/204_Bourbon_Cherry_Cerisier_Bourbon/204_Bourbon_Cherry_Cerisier_Bourbon_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 117 ].image,
      //     this.assets.cabinet[ 118 ].image,
      //     this.assets.cabinet[ 119 ].image
      //   )
      // },
      // {
      //   name: '205_TobaccoCherry_CerisierTabac',
      //   uiName: '205 TobaccoCherry CerisierTabac',
      //   img: '/assets/textures/cabinet/dolomite/205_TobaccoCherry_CerisierTabac/205_TobaccoCherry_CerisierTabac_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 120 ].image,
      //     this.assets.cabinet[ 121 ].image,
      //     this.assets.cabinet[ 122 ].image
      //   )
      // },
      // {
      //   name: '206_Crema_Cherry_Cerisier_Crema',
      //   uiName: '206 Crema Cherry Cerisier Crema',
      //   img: '/assets/textures/cabinet/dolomite/206_Crema_Cherry_Cerisier_Crema/206_Crema_Cherry_Cerisier_Crema_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 123 ].image,
      //     this.assets.cabinet[ 124 ].image,
      //     this.assets.cabinet[ 125 ].image
      //   )
      // },
      // {
      //   name: '207_ChocoCherry_CerisierChoco',
      //   uiName: '207 ChocoCherry CerisierChoco',
      //   img: '/assets/textures/cabinet/dolomite/207_ChocoCherry_CerisierChoco/207_ChocoCherry_CerisierChoco_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 126 ].image,
      //     this.assets.cabinet[ 127 ].image,
      //     this.assets.cabinet[ 128 ].image
      //   )
      // },
      // {
      //   name: '221_RubyCherry_CerisierRubis',
      //   uiName: '221 RubyCherry CerisierRubis',
      //   img: '/assets/textures/cabinet/dolomite/221_RubyCherry_CerisierRubis/221_RubyCherry_CerisierRubis_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 129 ].image,
      //     this.assets.cabinet[ 130 ].image,
      //     this.assets.cabinet[ 131 ].image
      //   )
      // },
      // {
      //   name: '241_Audacity_Audace',
      //   uiName: '241 Audacity Audace',
      //   img: '/assets/textures/cabinet/dolomite/241_Audacity_Audace/241_Audacity_Audace_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 132 ].image,
      //     this.assets.cabinet[ 133 ].image,
      //     this.assets.cabinet[ 134 ].image
      //   )
      // },
      // {
      //   name: '262_Sunrise_Maple_Erable_Lever_de_Soleil',
      //   uiName: '262 Sunrise Maple Erable Lever de Soleil',
      //   img: '/assets/textures/cabinet/dolomite/262_Sunrise_Maple_Erable_Lever_de_Soleil/262_Sunrise_Maple_Erable_Lever_de_Soleil_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 135 ].image,
      //     this.assets.cabinet[ 136 ].image,
      //     this.assets.cabinet[ 137 ].image
      //   )
      // },
      // {
      //   name: '263_Sunset_Maple_Erable_Coucher_de_Soleil',
      //   uiName: '263 Sunset Maple Erable Coucher de Soleil',
      //   img: '/assets/textures/cabinet/dolomite/263_Sunset_Maple_Erable_Coucher_de_Soleil/263_Sunset_Maple_Erable_Coucher_de_Soleil_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 138 ].image,
      //     this.assets.cabinet[ 139 ].image,
      //     this.assets.cabinet[ 140 ].image
      //   )
      // },
      // {
      //   name: '290_ClassicMaple_ErableClassique',
      //   uiName: '290 ClassicMaple ErableClassique',
      //   img: '/assets/textures/cabinet/dolomite/290_ClassicMaple_ErableClassique/290_ClassicMaple_ErableClassique_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 141 ].image,
      //     this.assets.cabinet[ 142 ].image,
      //     this.assets.cabinet[ 143 ].image
      //   )
      // },
      // {
      //   name: '313_ClassicChocolate_ChocolatClassique',
      //   uiName: '313 ClassicChocolate ChocolatClassique',
      //   img: '/assets/textures/cabinet/dolomite/313_ClassicChocolate_ChocolatClassique/313_ClassicChocolate_ChocolatClassique_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 144 ].image,
      //     this.assets.cabinet[ 145 ].image,
      //     this.assets.cabinet[ 146 ].image
      //   )
      // },
      // {
      //   name: '385_SapelliMahogany_AcajouSapelli',
      //   uiName: '385 SapelliMahogany AcajouSapelli',
      //   img: '/assets/textures/cabinet/dolomite/385_SapelliMahogany_AcajouSapelli/385_SapelliMahogany_AcajouSapelli_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 147 ].image,
      //     this.assets.cabinet[ 148 ].image,
      //     this.assets.cabinet[ 149 ].image
      //   )
      // },
      // {
      //   name: '388_Finnish_Oak_Chene_Finlandais',
      //   uiName: '388 Finnish Oak Chene Finlandais',
      //   img: '/assets/textures/cabinet/dolomite/388_Finnish_Oak_Chene_Finlandais/388_Finnish_Oak_Chene_Finlandais_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 150 ].image,
      //     this.assets.cabinet[ 151 ].image,
      //     this.assets.cabinet[ 152 ].image
      //   )
      // },
      // {
      //   name: '510_Cocoa_Cacao_Dolomite',
      //   uiName: '510 Cocoa Cacao Dolomite',
      //   img: '/assets/textures/cabinet/dolomite/510_Cocoa_Cacao_Dolomite/510_Cocoa_Cacao_Dolomite_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 153 ].image,
      //     this.assets.cabinet[ 154 ].image,
      //     this.assets.cabinet[ 155 ].image
      //   )
      // },
      // {
      //   name: '511_HiTeak_HiTeck',
      //   uiName: '511 HiTeak HiTeck',
      //   img: '/assets/textures/cabinet/dolomite/511_HiTeak_HiTeck/511_HiTeak_HiTeck_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 156 ].image,
      //     this.assets.cabinet[ 157 ].image,
      //     this.assets.cabinet[ 158 ].image
      //   )
      // },
      // {
      //   name: '513_Sandbank_Banc_de_Sable',
      //   uiName: '513 Sandbank Banc de Sable',
      //   img: '/assets/textures/cabinet/dolomite/513_Sandbank_Banc_de_Sable/513_Sandbank_Banc_de_Sable_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 159 ].image,
      //     this.assets.cabinet[ 160 ].image,
      //     this.assets.cabinet[ 161 ].image
      //   )
      // },
      // {
      //   name: '515_Cosmic_Cherry_Cerisier_Cosmique',
      //   uiName: '515 Cosmic Cherry Cerisier Cosmique',
      //   img: '/assets/textures/cabinet/dolomite/515_Cosmic_Cherry_Cerisier_Cosmique/515_Cosmic_Cherry_Cerisier_Cosmique_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 162 ].image,
      //     this.assets.cabinet[ 163 ].image,
      //     this.assets.cabinet[ 164 ].image
      //   )
      // },
      // {
      //   name: '557_WinterOak_ChenedHiver',
      //   uiName: '557 WinterOak ChenedHiver',
      //   img: '/assets/textures/cabinet/dolomite/557_WinterOak_ChenedHiver/557_WinterOak_ChenedHiver_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 165 ].image,
      //     this.assets.cabinet[ 166 ].image,
      //     this.assets.cabinet[ 167 ].image
      //   )
      // },
      // {
      //   name: '687_Bryan_Maple_Erable_Naturel',
      //   uiName: '687 Bryan Maple Erable Naturel',
      //   img: '/assets/textures/cabinet/dolomite/687_Bryan_Maple_Erable_Naturel/687_Bryan_Maple_Erable_Naturel_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 168 ].image,
      //     this.assets.cabinet[ 169 ].image,
      //     this.assets.cabinet[ 170 ].image
      //   )
      // },
      // {
      //   name: '744_Inspiration_Inspiration',
      //   uiName: '744 Inspiration Inspiration',
      //   img: '/assets/textures/cabinet/dolomite/744_Inspiration_Inspiration/744_Inspiration_Inspiration_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 171 ].image,
      //     this.assets.cabinet[ 172 ].image,
      //     this.assets.cabinet[ 173 ].image
      //   )
      // },
      // {
      //   name: '745_Finesse_Finesse',
      //   uiName: '745 Finesse Finesse',
      //   img: '/assets/textures/cabinet/dolomite/745_Finesse_Finesse/745_Finesse_Finesse_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 174 ].image,
      //     this.assets.cabinet[ 175 ].image,
      //     this.assets.cabinet[ 176 ].image
      //   )
      // },
      // {
      //   name: '748_Elegance_Elegance',
      //   uiName: '748 Elegance Elegance',
      //   img: '/assets/textures/cabinet/dolomite/748_Elegance_Elegance/748_Elegance_Elegance_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 177 ].image,
      //     this.assets.cabinet[ 178 ].image,
      //     this.assets.cabinet[ 179 ].image
      //   )
      // },
      // {
      //   name: '762_Shadow_Oak_Chene_Ombrage',
      //   uiName: '762 Shadow Oak Chene Ombrage',
      //   img: '/assets/textures/cabinet/dolomite/762_Shadow_Oak_Chene_Ombrage/762_Shadow_Oak_Chene_Ombrage_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 180 ].image,
      //     this.assets.cabinet[ 181 ].image,
      //     this.assets.cabinet[ 182 ].image
      //   )
      // },
      // {
      //   name: '764_SugarMaple_SucredErable',
      //   uiName: '764 SugarMaple SucredErable',
      //   img: '/assets/textures/cabinet/dolomite/764_SugarMaple_SucredErable/764_SugarMaple_SucredErable_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 183 ].image,
      //     this.assets.cabinet[ 184 ].image,
      //     this.assets.cabinet[ 185 ].image
      //   )
      // },
      // {
      //   name: '765_Port_Maple_Porto_a_lErable',
      //   uiName: '765 Port Maple Porto a lErable',
      //   img: '/assets/textures/cabinet/dolomite/765_Port_Maple_Porto_a_lErable/765_Port_Maple_Porto_a_lErable_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 186 ].image,
      //     this.assets.cabinet[ 187 ].image,
      //     this.assets.cabinet[ 188 ].image
      //   )
      // },
      // {
      //   name: '766_Cayenne_Maple_Cayenne_a_lErable',
      //   uiName: '766 Cayenne Maple Cayenne a lErable',
      //   img: '/assets/textures/cabinet/dolomite/766_Cayenne_Maple_Cayenne_a_lErable/766_Cayenne_Maple_Cayenne_a_lErable_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 189 ].image,
      //     this.assets.cabinet[ 190 ].image,
      //     this.assets.cabinet[ 191 ].image
      //   )
      // },
      // {
      //   name: '785_Siam_Teak_Teck_Siam',
      //   uiName: '785 Siam Teak Teck Siam',
      //   img: '/assets/textures/cabinet/dolomite/785_Siam_Teak_Teck_Siam/785_Siam_Teak_Teck_Siam_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 192 ].image,
      //     this.assets.cabinet[ 193 ].image,
      //     this.assets.cabinet[ 194 ].image
      //   )
      // },
      // {
      //   name: '838_Urban_Mahogany_Acajou_Urbain',
      //   uiName: '838 Urban Mahogany Acajou Urbain',
      //   img: '/assets/textures/cabinet/dolomite/838_Urban_Mahogany_Acajou_Urbain/838_Urban_Mahogany_Acajou_Urbain_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 195 ].image,
      //     this.assets.cabinet[ 196 ].image,
      //     this.assets.cabinet[ 197 ].image
      //   )
      // },
      // {
      //   name: '938_Forever_Walnut_Noyer_Eternel',
      //   uiName: '938 Forever Walnut Noyer Eternel',
      //   img: '/assets/textures/cabinet/dolomite/938_Forever_Walnut_Noyer_Eternel/938_Forever_Walnut_Noyer_Eternel_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 198 ].image,
      //     this.assets.cabinet[ 199 ].image,
      //     this.assets.cabinet[ 200 ].image
      //   )
      // },
      // {
      //   name: '961_Stainless_Aluminium',
      //   uiName: '961 Stainless Aluminium',
      //   img: '/assets/textures/cabinet/dolomite/961_Stainless_Aluminium/961_Stainless_Aluminium_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 201 ].image,
      //     this.assets.cabinet[ 202 ].image,
      //     this.assets.cabinet[ 203 ].image
      //   )
      // },
      // {
      //   name: '965_Port_Porto',
      //   uiName: '965 Port Porto',
      //   img: '/assets/textures/cabinet/dolomite/965_Port_Porto/965_Port_Porto_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 204 ].image,
      //     this.assets.cabinet[ 205 ].image,
      //     this.assets.cabinet[ 206 ].image
      //   )
      // },
      // {
      //   name: '971_Pear_Poire',
      //   uiName: '971 Pear Poire',
      //   img: '/assets/textures/cabinet/dolomite/971_Pear_Poire/971_Pear_Poire_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 207 ].image,
      //     this.assets.cabinet[ 208 ].image,
      //     this.assets.cabinet[ 209 ].image
      //   )
      // },
      // {
      //   name: '992_Hardrock_Maple_Erable_Hardrock',
      //   uiName: '992 Hardrock Maple Erable Hardrock',
      //   img: '/assets/textures/cabinet/dolomite/992_Hardrock_Maple_Erable_Hardrock/992_Hardrock_Maple_Erable_Hardrock_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 210 ].image,
      //     this.assets.cabinet[ 211 ].image,
      //     this.assets.cabinet[ 212 ].image
      //   )
      // },
      // {
      //   name: 'E19_Chocolate_Apple_Pomme_Chocolat',
      //   uiName: 'E19 Chocolate Apple Pomme Chocolat',
      //   img: '/assets/textures/cabinet/dolomite/E19_Chocolate_Apple_Pomme_Chocolat/E19_Chocolate_Apple_Pomme_Chocolat_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 213 ].image,
      //     this.assets.cabinet[ 214 ].image,
      //     this.assets.cabinet[ 215 ].image
      //   )
      // },
      // {
      //   name: 'G15_VelvetWenge',
      //   uiName: 'G15 VelvetWenge',
      //   img: '/assets/textures/cabinet/dolomite/G15_VelvetWenge/G15_VelvetWenge_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 216 ].image,
      //     this.assets.cabinet[ 217 ].image,
      //     this.assets.cabinet[ 218 ].image
      //   )
      // },
      // {
      //   name: 'G16_Milano_Oak_Chene_Milano',
      //   uiName: 'G16 Milano Oak Chene Milano',
      //   img: '/assets/textures/cabinet/dolomite/G16_Milano_Oak_Chene_Milano/G16_Milano_Oak_Chene_Milano_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 219 ].image,
      //     this.assets.cabinet[ 220 ].image,
      //     this.assets.cabinet[ 221 ].image
      //   )
      // },
      // {
      //   name: 'G17_Grigio_Grigio',
      //   uiName: 'G17 Grigio Grigio',
      //   img: '/assets/textures/cabinet/dolomite/G17_Grigio_Grigio/G17_Grigio_Grigio_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 222 ].image,
      //     this.assets.cabinet[ 223 ].image,
      //     this.assets.cabinet[ 224 ].image
      //   )
      // },
      // {
      //   name: 'G18_Caramello_Caramello',
      //   uiName: 'G18 Caramello Caramello',
      //   img: '/assets/textures/cabinet/dolomite/G18_Caramello_Caramello/G18_Caramello_Caramello_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 225 ].image,
      //     this.assets.cabinet[ 226 ].image,
      //     this.assets.cabinet[ 227 ].image
      //   )
      // },
      // {
      //   name: 'G21_Viva_Walnut_Noyer_Viva',
      //   uiName: 'G21 Viva Walnut Noyer Viva',
      //   img: '/assets/textures/cabinet/dolomite/G21_Viva_Walnut_Noyer_Viva/G21_Viva_Walnut_Noyer_Viva_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 228 ].image,
      //     this.assets.cabinet[ 229 ].image,
      //     this.assets.cabinet[ 230 ].image
      //   )
      // },
      // {
      //   name: 'G87_Gingersnap_Sorbier_Gingembre',
      //   uiName: 'G87 Gingersnap Sorbier Gingembre',
      //   img: '/assets/textures/cabinet/dolomite/G87_Gingersnap_Sorbier_Gingembre/G87_Gingersnap_Sorbier_Gingembre_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 231 ].image,
      //     this.assets.cabinet[ 232 ].image,
      //     this.assets.cabinet[ 233 ].image
      //   )
      // },
      // {
      //   name: 'H60_Classic_Spice_Epice_Classique',
      //   uiName: 'H60 Classic Spice Epice Classique',
      //   img: '/assets/textures/cabinet/dolomite/H60_Classic_Spice_Epice_Classique/H60_Classic_Spice_Epice_Classique_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 234 ].image,
      //     this.assets.cabinet[ 235 ].image,
      //     this.assets.cabinet[ 236 ].image
      //   )
      // },
      // {
      //   name: 'H63_Mosaic_Mosaique',
      //   uiName: 'H63 Mosaic Mosaique',
      //   img: '/assets/textures/cabinet/dolomite/H63_Mosaic_Mosaique/H63_Mosaic_Mosaique_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 237 ].image,
      //     this.assets.cabinet[ 238 ].image,
      //     this.assets.cabinet[ 239 ].image
      //   )
      // },
      // {
      //   name: 'K26_Pixel_Pixel',
      //   uiName: 'K26 Pixel Pixel',
      //   img: '/assets/textures/cabinet/dolomite/K26_Pixel_Pixel/K26_Pixel_Pixel_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 240 ].image,
      //     this.assets.cabinet[ 241 ].image,
      //     this.assets.cabinet[ 242 ].image
      //   )
      // },
      // {
      //   name: 'K27_Rhytm_Rythme',
      //   uiName: 'K27 Rhytm Rythme',
      //   img: '/assets/textures/cabinet/dolomite/K27_Rhytm_Rythme/K27_Rhytm_Rythme_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 243 ].image,
      //     this.assets.cabinet[ 244 ].image,
      //     this.assets.cabinet[ 245 ].image
      //   )
      // },
      // {
      //   name: 'K28_Echo_Echo',
      //   uiName: 'K28 Echo Echo',
      //   img: '/assets/textures/cabinet/dolomite/K28_Echo_Echo/K28_Echo_Echo_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 246 ].image,
      //     this.assets.cabinet[ 247 ].image,
      //     this.assets.cabinet[ 248 ].image
      //   )
      // },
      // {
      //   name: 'K29_Nickel_Nickel',
      //   uiName: 'K29 Nickel Nickel',
      //   img: '/assets/textures/cabinet/dolomite/K29_Nickel_Nickel/K29_Nickel_Nickel_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 249 ].image,
      //     this.assets.cabinet[ 250 ].image,
      //     this.assets.cabinet[ 251 ].image
      //   )
      // },
      // {
      //   name: 'K30_Spectra',
      //   uiName: 'K30 Spectra',
      //   img: '/assets/textures/cabinet/dolomite/K30_Spectra/K30_Spectra_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 252 ].image,
      //     this.assets.cabinet[ 253 ].image,
      //     this.assets.cabinet[ 254 ].image
      //   )
      // },
      // {
      //   name: 'K31_Odeon_Odeon',
      //   uiName: 'K31 Odeon Odeon',
      //   img: '/assets/textures/cabinet/dolomite/K31_Odeon_Odeon/K31_Odeon_Odeon_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 255 ].image,
      //     this.assets.cabinet[ 256 ].image,
      //     this.assets.cabinet[ 257 ].image
      //   )
      // },
      // {
      //   name: 'K32_Equinox_Equinoxe',
      //   uiName: 'K32 Equinox Equinoxe',
      //   img: '/assets/textures/cabinet/dolomite/K32_Equinox_Equinoxe/K32_Equinox_Equinoxe_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 258 ].image,
      //     this.assets.cabinet[ 259 ].image,
      //     this.assets.cabinet[ 260 ].image
      //   )
      // },
      // {
      //   name: 'G22_Ribbon_White_Blanc_Rubban',
      //   uiName: 'G22 Ribbon White Blanc Rubban',
      //   img: '/assets/textures/cabinet/highgloss/G22_Ribbon_White_Blanc_Rubban/G22_Ribbon_White_Blanc_Rubban_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 261 ].image,
      //     this.assets.cabinet[ 262 ].image,
      //     this.assets.cabinet[ 263 ].image
      //   )
      // },
      // {
      //   name: 'G23_Coastal_Pear_Poirier_de_la_Cote',
      //   uiName: 'G23 Coastal Pear Poirier de la Cote',
      //   img: '/assets/textures/cabinet/highgloss/G23_Coastal_Pear_Poirier_de_la_Cote/G23_Coastal_Pear_Poirier_de_la_Cote_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 264 ].image,
      //     this.assets.cabinet[ 265 ].image,
      //     this.assets.cabinet[ 266 ].image
      //   )
      // },
      // {
      //   name: 'H61_Intrigue_Intrigue',
      //   uiName: 'H61 Intrigue Intrigue',
      //   img: '/assets/textures/cabinet/rio/H61_Intrigue_Intrigue/H61_Intrigue_Intrigue_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 267 ].image,
      //     this.assets.cabinet[ 268 ].image,
      //     this.assets.cabinet[ 269 ].image
      //   )
      // },
      // {
      //   name: 'H64_Strata_Strata',
      //   uiName: 'H64 Strata Strata',
      //   img: '/assets/textures/cabinet/rio/H64_Strata_Strata/H64_Strata_Strata_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 270 ].image,
      //     this.assets.cabinet[ 271 ].image,
      //     this.assets.cabinet[ 272 ].image
      //   )
      // },
      // {
      //   name: 'H65_Canyon_Canyon',
      //   uiName: 'H65 Canyon Canyon',
      //   img: '/assets/textures/cabinet/rio/H65_Canyon_Canyon/H65_Canyon_Canyon_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 273 ].image,
      //     this.assets.cabinet[ 274 ].image,
      //     this.assets.cabinet[ 275 ].image
      //   )
      // },
      // {
      //   name: 'H66_Vintage_Vintage',
      //   uiName: 'H66 Vintage Vintage',
      //   img: '/assets/textures/cabinet/rio/H66_Vintage_Vintage/H66_Vintage_Vintage_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 276 ].image,
      //     this.assets.cabinet[ 277 ].image,
      //     this.assets.cabinet[ 278 ].image
      //   )
      // },
      // {
      //   name: 'H76_Memento_Memento',
      //   uiName: 'H76 Memento Memento',
      //   img: '/assets/textures/cabinet/rio/H76_Memento_Memento/H76_Memento_Memento_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 279 ].image,
      //     this.assets.cabinet[ 280 ].image,
      //     this.assets.cabinet[ 281 ].image
      //   )
      // },
      // {
      //   name: 'K04_SmokeWood_BoisFume',
      //   uiName: 'K04 SmokeWood BoisFume',
      //   img: '/assets/textures/cabinet/rio/K04_SmokeWood_BoisFume/K04_SmokeWood_BoisFume_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 282 ].image,
      //     this.assets.cabinet[ 283 ].image,
      //     this.assets.cabinet[ 284 ].image
      //   )
      // },
      // {
      //   name: 'K05_BarnWood_BoisdeGrange',
      //   uiName: 'K05 BarnWood BoisdeGrange',
      //   img: '/assets/textures/cabinet/rio/K05_BarnWood_BoisdeGrange/K05_BarnWood_BoisdeGrange_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 285 ].image,
      //     this.assets.cabinet[ 286 ].image,
      //     this.assets.cabinet[ 287 ].image
      //   )
      // },
      // {
      //   name: 'K06_SilverWood_BoisArgente',
      //   uiName: 'K06 SilverWood BoisArgente',
      //   img: '/assets/textures/cabinet/rio/K06_SilverWood_BoisArgente/K06_SilverWood_BoisArgente_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 288 ].image,
      //     this.assets.cabinet[ 289 ].image,
      //     this.assets.cabinet[ 290 ].image
      //   )
      // },
      // {
      //   name: 'R60_Fresco_Fresco',
      //   uiName: 'R60 Fresco Fresco',
      //   img: '/assets/textures/cabinet/rio/R60_Fresco_Fresco/R60_Fresco_Fresco_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 291 ].image,
      //     this.assets.cabinet[ 292 ].image,
      //     this.assets.cabinet[ 293 ].image
      //   )
      // },
      // {
      //   name: 'R61_Carnaval_Carnaval',
      //   uiName: 'R61 Carnaval Carnaval',
      //   img: '/assets/textures/cabinet/rio/R61_Carnaval_Carnaval/R61_Carnaval_Carnaval_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 294 ].image,
      //     this.assets.cabinet[ 295 ].image,
      //     this.assets.cabinet[ 296 ].image
      //   )
      // },
      // {
      //   name: 'R62_Amazona_Amazona',
      //   uiName: 'R62 Amazona Amazona',
      //   img: '/assets/textures/cabinet/rio/R62_Amazona_Amazona/R62_Amazona_Amazona_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 297 ].image,
      //     this.assets.cabinet[ 298 ].image,
      //     this.assets.cabinet[ 299 ].image
      //   )
      // },
      // {
      //   name: 'R63_Samba_Samba',
      //   uiName: 'R63 Samba Samba',
      //   img: '/assets/textures/cabinet/rio/R63_Samba_Samba/R63_Samba_Samba_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 300 ].image,
      //     this.assets.cabinet[ 301 ].image,
      //     this.assets.cabinet[ 302 ].image
      //   )
      // },
      // {
      //   name: 'K13_Nizza_Nizza',
      //   uiName: 'K13 Nizza Nizza',
      //   img: '/assets/textures/cabinet/rivieraoak/K13_Nizza_Nizza/K13_Nizza_Nizza_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 303 ].image,
      //     this.assets.cabinet[ 304 ].image,
      //     this.assets.cabinet[ 305 ].image
      //   )
      // },
      // {
      //   name: 'K14_Mistral_Mistral',
      //   uiName: 'K14 Mistral Mistral',
      //   img: '/assets/textures/cabinet/rivieraoak/K14_Mistral_Mistral/K14_Mistral_Mistral_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 306 ].image,
      //     this.assets.cabinet[ 307 ].image,
      //     this.assets.cabinet[ 308 ].image
      //   )
      // },
      // {
      //   name: 'K15_Cannes_Cannes',
      //   uiName: 'K15 Cannes Cannes',
      //   img: '/assets/textures/cabinet/rivieraoak/K15_Cannes_Cannes/K15_Cannes_Cannes_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 309 ].image,
      //     this.assets.cabinet[ 310 ].image,
      //     this.assets.cabinet[ 311 ].image
      //   )
      // },
      // {
      //   name: 'K16_Esterel_Esterel',
      //   uiName: 'K16 Esterel Esterel',
      //   img: '/assets/textures/cabinet/rivieraoak/K16_Esterel_Esterel/K16_Esterel_Esterel_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 312 ].image,
      //     this.assets.cabinet[ 313 ].image,
      //     this.assets.cabinet[ 314 ].image
      //   )
      // },
      // {
      //   name: 'K17_Cassis_Cassis',
      //   uiName: 'K17 Cassis Cassis',
      //   img: '/assets/textures/cabinet/rivieraoak/K17_Cassis_Cassis/K17_Cassis_Cassis_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 315 ].image,
      //     this.assets.cabinet[ 316 ].image,
      //     this.assets.cabinet[ 317 ].image
      //   )
      // },
      // {
      //   name: 'K18_Monaco_Monaco',
      //   uiName: 'K18 Monaco Monaco',
      //   img: '/assets/textures/cabinet/rivieraoak/K18_Monaco_Monaco/K18_Monaco_Monaco_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 318 ].image,
      //     this.assets.cabinet[ 319 ].image,
      //     this.assets.cabinet[ 320 ].image
      //   )
      // },
      // {
      //   name: 'K22_Reflexion_Reflexion',
      //   uiName: 'K22 Reflexion Reflexion',
      //   img: '/assets/textures/cabinet/sequoia/K22_Reflexion_Reflexion/K22_Reflexion_Reflexion_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 321 ].image,
      //     this.assets.cabinet[ 322 ].image,
      //     this.assets.cabinet[ 323 ].image
      //   )
      // },
      // {
      //   name: 'K23_Shanshu_Shanshu',
      //   uiName: 'K23 Shanshu Shanshu',
      //   img: '/assets/textures/cabinet/sequoia/K23_Shanshu_Shanshu/K23_Shanshu_Shanshu_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 324 ].image,
      //     this.assets.cabinet[ 325 ].image,
      //     this.assets.cabinet[ 326 ].image
      //   )
      // },
      // {
      //   name: 'K24_Dalia_Dalia',
      //   uiName: 'K24 Dalia Dalia',
      //   img: '/assets/textures/cabinet/sequoia/K24_Dalia_Dalia/K24_Dalia_Dalia_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 327 ].image,
      //     this.assets.cabinet[ 328 ].image,
      //     this.assets.cabinet[ 329 ].image
      //   )
      // },
      // {
      //   name: 'K25_Mimosa_Mimosa',
      //   uiName: 'K25 Mimosa Mimosa',
      //   img: '/assets/textures/cabinet/sequoia/K25_Mimosa_Mimosa/K25_Mimosa_Mimosa_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 330 ].image,
      //     this.assets.cabinet[ 331 ].image,
      //     this.assets.cabinet[ 332 ].image
      //   )
      // },
      // {
      //   name: 'K36_Soho',
      //   uiName: 'K36 Soho',
      //   img: '/assets/textures/cabinet/sequoia/K36_Soho/K36_Soho_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 333 ].image,
      //     this.assets.cabinet[ 334 ].image,
      //     this.assets.cabinet[ 335 ].image
      //   )
      // },
      // {
      //   name: 'H62_Angora_Angora',
      //   uiName: 'H62 Angora Angora',
      //   img: '/assets/textures/cabinet/supermat/H62_Angora_Angora/H62_Angora_Angora_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 336 ].image,
      //     this.assets.cabinet[ 337 ].image,
      //     this.assets.cabinet[ 338 ].image
      //   )
      // },
      // {
      //   name: 'H68_Kohl_Khol',
      //   uiName: 'H68 Kohl Khol',
      //   img: '/assets/textures/cabinet/supermat/H68_Kohl_Khol/H68_Kohl_Khol_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 339 ].image,
      //     this.assets.cabinet[ 340 ].image,
      //     this.assets.cabinet[ 341 ].image
      //   )
      // },
      // {
      //   name: 'H69_Carbon_Carbone',
      //   uiName: 'H69 Carbon Carbone',
      //   img: '/assets/textures/cabinet/supermat/H69_Carbon_Carbone/H69_Carbon_Carbone_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 342 ].image,
      //     this.assets.cabinet[ 343 ].image,
      //     this.assets.cabinet[ 344 ].image
      //   )
      // },
      // {
      //   name: 'K08_Luna_Luna',
      //   uiName: 'K08 Luna Luna',
      //   img: '/assets/textures/cabinet/supermat/K08_Luna_Luna/K08_Luna_Luna_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 345 ].image,
      //     this.assets.cabinet[ 346 ].image,
      //     this.assets.cabinet[ 347 ].image
      //   )
      // },
      // {
      //   name: 'K37_Endgrain',
      //   uiName: 'K37 Endgrain',
      //   img: '/assets/textures/cabinet/supermat/K37_Endgrain/K37_Endgrain_basecolor.jpg',
      //   material: createPhysicalMaterial(
      //     0xffffff,
      //     this.assets.cabinet[ 348 ].image,
      //     this.assets.cabinet[ 349 ].image,
      //     this.assets.cabinet[ 350 ].image
      //   )
      // }


    ];
    this.materials.handle = [
      {
        name: 'steelbrushed',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.handle[ 0 ].image,
          null,
          this.assets.handle[ 2 ].image,
          this.assets.handle[ 3 ].image
        )
      },
      {
        name: 'wood009',
        material: createPhysicalMaterial(
          0xffffff,
          this.assets.countertop[ 0 ].image,
          this.assets.countertop[ 1 ].image,
          this.assets.countertop[ 2 ].image
        )
      }
    ];
  }
}
