/**
 * Device types
 * @type {Object}
 * @property {number} DeviceTypes.Desktop Desktop device
 * @property {number} DeviceTypes.MobileLandscape Mobile device (landscape orientation)
 * @property {number} DeviceTypes.MobilePortrait Mobile device (portrait orientation)
 */
export const deviceTypes = {
  Desktop: 0x0,
  Mobile: 0x1,
};

/**
 *  All textures represent x cm x x cm piece of material
 */
export const textureSize = 200 / 2.54;
