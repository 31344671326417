import React, { PureComponent } from 'react';
import Storage from 'scr/utilitiesStorage';
import style from './index.styl';

interface IShape {
  img: string;
  name: string;
  uiName: string;
  shape: object;
}

class Shapes extends PureComponent {

  chooseShape = ( shape: object ) => () => {
    const selectedObject = Storage.get( 'selectedObject' );
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if ( selectedObject && selectedObject.length && selectedObject[ 0 ]._vestaObject.type === 'crownMolding' ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      selectedObject[ 0 ].changeShape( shape );
    }
  };

  render() {
    const selectedObject = Storage.get( 'selectedObject' );
    if ( !selectedObject ) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const type = selectedObject[ 0 ]._vestaObject.type;
    if ( type !== 'crownMolding' ) {
      return null;
    }

    const shapes = Storage.get( 'shapes' ).crownMoldings;

    const { chooseShape } = this;

    return (
      <div className={ style.shapesBlock }>
        <div className='shapesHolder'>
          { shapes.map( ( shapeObject: IShape ) => {
            const {
              img,
              name,
              uiName,
              shape
            } = shapeObject;

            return (
              <div
                key={ name }
                data-name={ name }
                onClick={ chooseShape( shape ) }
              >
                <img src={ img } alt='' />
                { uiName }
              </div>
            );
          } ) }
        </div>
      </div>
    );
  }
}

export default Shapes;
