import DoAction from "./DoAction";
import DoBaseManager from "./DoBaseManager";

/**
 * Manager for Undo/Redo history.
 */
class DoManager extends DoBaseManager {
    registerDo(doaction, group) {
		doaction = super.registerDo(doaction, group);
		if (doaction) {
			// customize docation here.
		}
		return doaction;
    }
}

export default DoManager;