import { combRed, AllActions } from '@r/storeUtils';
import { combineReducers } from 'redux';
import { doorsAngle } from './doorsAngle';
import { shelves } from './shelves';
import { swing } from './swing';
import { to4Walls } from './to4Walls';
import { crownMolding } from './crownMolding';

type selectedCabinet = redux.store._ISelectedCabinet;

declare global {
  namespace redux {
    interface _IStore {
      selectedCabinet: selectedCabinet;
    }
  }
}


const projectFor3dComb = combineReducers as
  combRed<redux._IStore['selectedCabinet'], AllActions>;

export const selectedCabinet = projectFor3dComb( {
  doorsAngle,
  shelves,
  swing,
  to4Walls,
  crownMolding
} );
