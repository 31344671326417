/* eslint-disable no-array-constructor */
/* eslint-disable no-restricted-syntax */
import Konva from 'konva';
import { roundForUi } from 'scr/metricsConversion';
import {
  _IDrawLine,
  _IDrawLabeledLine,
  _IDrawLabeledLines,
  _IDrawCabinetsDimensions,
  _IDrawLabel,
  _IDrawNotch,
  _IFindOrthogonalVector,
  _IFindNormalizedVector,
  _IConvertToVector,
  _ICanvasInput,
  _ILabeledLine,
  _IBoundingCoords,
  _IPoint3DProjection
} from './requirements';


export const findOrthogonalVector: _IFindOrthogonalVector = ( { i, j } ) => {
  return {
    i: j,
    j: -i
  };
};


export const findNormalizedVector: _IFindNormalizedVector = ( { i, j } ) => {
  const length = Math.hypot( i, j );

  return {
    i: i / length,
    j: j / length
  };
};


export const convertToVector: _IConvertToVector = ( p1, p2 ) => {
  if ( !p2 ) {
    // eslint-disable-next-line no-param-reassign
    p2 = p1;
    // eslint-disable-next-line no-param-reassign
    p1 = { x: 0, y: 0 };
  }

  return {
    i: p2.x - p1.x,
    j: p2.y - p1.y
  };
};


export const drawLine: _IDrawLine = ( layer, p1, p2, stroke = 'black' ) => {
  const line = new Konva.Line( {
    points: [p1.x, p1.y, p2.x, p2.y],
    stroke,
    strokeWidth: 2
  } );

  layer.add( line );
};


export const drawNotch: _IDrawNotch = (
  layer, p1, p2, type, position = 'start', length = 10
) => {
  const vector = convertToVector( p1, p2 );
  const ortVector = findOrthogonalVector( vector );
  const normV = findNormalizedVector( ortVector );

  const point = position === 'start' ? p1 : p2;

  const startNotchPosition = {
    x: point.x + ( normV.i * length / 2 ),
    y: point.y + ( normV.j * length / 2 )
  };
  const endNotchPosition = {
    x: point.x + ( normV.i * -1 * length / 2 ),
    y: point.y + ( normV.j * -1 * length / 2 )
  };

  drawLine( layer, startNotchPosition, endNotchPosition );
};


export const drawLabel: _IDrawLabel = (
  layer, p1, p2, text, settings = {}
) => {
  const {
    fill = 'white',
    fontFamily = 'Calibri',
    fontSize = 16,
    padding = 4,
    rotation = 0
  } = settings;

  const textComp = new Konva.Text( {
    text,
    fontFamily,
    fontSize,
    padding
  } );

  const widthOffset = textComp.width() / 2;
  const heightOffset = textComp.height() / 2;

  let dx = p2.x - p1.x;
  let dy = p2.y - p1.y;
  let p = p1;

  const xCent = p.x + dx / 2;
  const yCent = p.y + dy / 2;
  const obj = {
    x: xCent - widthOffset * Math.cos( rotation ) + heightOffset * Math.sin( rotation ),
    y: yCent - heightOffset * Math.cos( rotation ) - widthOffset * Math.sin( rotation ),
    rotation: rotation * 180 / Math.PI
  };

  const label = new Konva.Label( obj );

  label.add( new Konva.Tag( { fill } ) );
  label.add( textComp );
  layer.add( label );
};


export const drawLabeledLine: _IDrawLabeledLine = (
  layer, { start, end, label }, labelSettings
) => {
  drawLine( layer, start, end );
  drawLabel( layer, start, end, label, labelSettings );
};


export const drawLabeledLines: _IDrawLabeledLines = (
  layer, lines, settings
) => {
  if( lines.length === 0 ) return;

  drawNotch( layer, lines[ 0 ].start, lines[ 0 ].end, 'outer' );
  drawLabeledLine( layer, lines[ 0 ], settings );

  const length = lines.length;

  for ( let i = 1; i < length; i++ ) {
    drawLabeledLine( layer, lines[ i ], settings );

    if ( i === length - 1 ) {
      drawNotch( layer, lines[ i ].start, lines[ i ].end, 'inner' );

    } else {
      drawNotch( layer, lines[ i ].start, lines[ i ].end, 'outer' );
    }
  }

  drawNotch(
    layer, lines[ length - 1 ].start, lines[ length - 1 ].end, 'outer', 'end'
  );
};


export const drawCabinetsDimensions: _IDrawCabinetsDimensions = (
  layer, cabinetsDimensions, settings
) => {
  for( let i = 0; i < cabinetsDimensions.length; i++ ) {
    drawLabeledLines( layer, cabinetsDimensions[ i ], settings );
  }
};


function extractLabeldLine( {
  xS, yS, xE, yE, label
}: {
    xS: number;
    yS: number;
    xE: number;
    yE: number;
    label: string;
  }
): _ILabeledLine {
  return {
    start: { x: xS, y: yS },
    end: { x: xE, y: yE },
    label
  };
}

function extractPointsFromBoundingCoords(
  c: _IBoundingCoords, dir: 'x' | 'y' = 'x'
):
  [ _IPoint3DProjection, _IPoint3DProjection ] {
  const { leftBottom: l, rightTop: r } = c;

  if( dir === 'x' ) {
    return [
      {
        x: l.x, u: l.u, y: r.y, v: r.v
      },
      {
        x: r.x, u: r.u, y: r.y, v: r.v
      }
    ];
  }

  return [
    {
      x: l.x, u: l.u, y: l.y, v: l.v
    },
    {
      x: l.x, u: l.u, y: r.y, v: r.v
    }
  ];
}

function getLabelForTwoPoints(
  p1: _IPoint3DProjection,
  p2: _IPoint3DProjection,
  dir: 'x' | 'y' = 'x'
) {
  return String(
    roundForUi( Math.abs(
      dir === 'x' ? p1.x - p2.x
        : p1.y - p2.y
    ), 'inch' )
  );
}

function generateLine(
  start: _IPoint3DProjection,
  boundingCoordsLine: _IBoundingCoords[],
  end: _IPoint3DProjection,
  elevateFunc: ( a: _IPoint3DProjection ) => _IPoint3DProjection,
  dir: 'x' | 'y' = 'x'
): _ILabeledLine[] {
  return [start]
    .concat(
      boundingCoordsLine.reduce<_IPoint3DProjection[]>(
        ( a, coords ) => a.concat(
          extractPointsFromBoundingCoords( coords, dir )
        ), [] )
    )
    .concat( end )
    .map( elevateFunc )
    .reduce< Array<
      _ILabeledLine & { p1: _IPoint3DProjection; p2: _IPoint3DProjection }
    > >( ( acc, point, i, arr ) => {
      if( i === 0 ) return acc;

      return acc.concat( {
        start: {
          x: arr[ i - 1 ].u,
          y: arr[ i - 1 ].v
        },
        end: {
          x: point.u,
          y: point.v
        },
        label: getLabelForTwoPoints( arr[ i - 1 ], arr[ i ], dir ),
        p1: arr[ i - 1 ],
        p2: arr[ i ]
      } );
    }, [] )
    .filter( ( { p1, p2 } ) => (
      Math.abs( dir === 'x' ? p1.x - p2.x : p1.y - p2.y ) > 0.1
    ) )
    .map( ( p ) => ( {
      ...p,
      p1: undefined,
      p2: undefined
    } ) );
}

export function toCabinetsDimensionsWall(
  dimensions: _ICanvasInput
): { up: _ILabeledLine[][]; left: _ILabeledLine[][] } {
  const { root, cabinets: { upper, base, tall } } = dimensions;
  const firstLine = upper.concat( tall )
    .sort( ( a, b ) => a.rightTop.x - b.leftBottom.x );
  const secondLine = base.concat( tall )
    .sort( ( a, b ) => a.rightTop.x - b.leftBottom.x );

  if( root.type !== 'wall' ) throw new TypeError( 'Cannot process non-wall.' );

  const {
    // canvas is built from left-top corner, so min is effectively
    // lowest V coordinate although in canvas coordinates it`s the biggest
    leftBottom, leftBottom: { v: minV },
    // same thing here, max is actualy lowest in canvas coords
    leftTop, leftTop: { v: maxV, u: minU },
    rightBottom, rightBottom: { u: maxU },
    rightTop
  } = root.vertices;

  const topLineForCabinets = firstLine.length === 0
    ? []
    : generateLine( leftTop, firstLine, rightTop,
      ( a ) => ( { ...a, v: maxV - 20 as typeof maxV } )
    );
  const bottomLineForCabinets = secondLine.length === 0
    ? []
    : generateLine( leftBottom, secondLine, rightBottom,
      ( a ) => ( { ...a, v: minV + 10 as typeof minV } )
    );

  const leftHeights: _IBoundingCoords[] = new Array<_IBoundingCoords>()
    .concat( secondLine[ 0 ] || [] )
    .concat( secondLine[ 0 ] && firstLine[ 0 ] && {
      leftBottom: {
        x: secondLine[ 0 ].leftBottom.x,
        y: secondLine[ 0 ].rightTop.y,
        u: secondLine[ 0 ].leftBottom.u,
        v: secondLine[ 0 ].rightTop.v
      },
      rightTop: {
        x: firstLine[ 0 ].leftBottom.x,
        y: firstLine[ 0 ].leftBottom.y,
        u: firstLine[ 0 ].leftBottom.u,
        v: firstLine[ 0 ].leftBottom.v
      }
    } || [] )
    .concat( firstLine[ 0 ] || [] );
  // =

  const leftLineForCabinets = leftHeights.length === 0
    ? []
    : generateLine( leftBottom, leftHeights, leftTop,
      ( a ) => ( { ...a, u: minU - 20 as typeof minU } ),
      'y'
    );


  return {
    up: [
      [
        extractLabeldLine( {
          xS: minU,
          yS: maxV - 40,
          xE: maxU,
          yE: maxV - 40,
          label: getLabelForTwoPoints( rightTop, leftTop )
        } )
      ],
      topLineForCabinets,
      bottomLineForCabinets
    ],
    left: [
      [
        extractLabeldLine( {
          xS: minU - 50,
          yS: minV,
          xE: minU - 50,
          yE: maxV,
          label: String(
            roundForUi( Math.abs( leftBottom.y - leftTop.y ), 'inch' )
          )
        } )
      ],
      leftLineForCabinets
    ]
  };
}


// !( async () => {
//   const width = window.innerWidth;
//   const height = window.innerHeight;

//   const stage = new Konva.Stage( {
//     container: 'tutorial',
//     width,
//     height
//   } );

//   const layer = new Konva.Layer();

//   const line1: _ILabeledLine = {
//     start: { x: 75, y: 25 },
//     end: { x: 250, y: 25 },
//     label: '157 mm'
//   };
//   const line2: _ILabeledLine = {
//     start: { x: 250, y: 25 },
//     end: { x: 350, y: 25 },
//     label: '153 mm'
//   };
//   const line3: _ILabeledLine = {
//     start: { x: 50, y: 10 },
//     end: { x: 50, y: 95 },
//     label: '157 mm'
//   };
//   const line4: _ILabeledLine = {
//     start: { x: 50, y: 95 },
//     end: { x: 50, y: 275 },
//     label: '153 mm'
//   };
//   const line5: _ILabeledLine = {
//     start: { x: 50, y: 275 },
//     end: { x: 50, y: 365 },
//     label: '214 mm'
//   };
//   const specialLine = {
//     start: { x: 150, y: 90 },
//     end: { x: 350, y: 190 },
//     label: '317 cm'
//   };
//   const lines1: _ILabeledLine[] = [line1, line2];
//   const lines2: _ILabeledLine[] = [line3, line4, line5];
//   const cabinetsDimensions: _ILabeledLine[][] = [lines1, lines2, [specialLine]];

//   drawCabinetsDimensions( layer, cabinetsDimensions );

//   stage.add( layer );
// } )();
