/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'c/shared/ThemeButton';
import feathersClient from 'scr/getFeathers';
import modalStyl from 'c/CalculationLayer/Modal/index.styl';
// import { loginRequest } from '@r/actions';
import LabeledField from 'c/CalculationLayer/utils/LabeledField';
import SimpleInput from 'c/CalculationLayer/utils/SimpleInput';
import { d } from '@r/storeUtils';
import { signupRequest } from '@r/actions';
import { ThunkDispatch } from 'redux-thunk';
import { isValidEmail } from 'scr/isValidEmail';
import styl from './index.styl';
/*
 * // @ts-check
 * import React, { PureComponent } from 'react';
 * import { connect } from 'react-redux';
 */

// import Button from 'c/shared/ThemeButton';
// /*
//  * import { FormGroup } from 'c/shared/form-group';
//  * import { InputControl } from 'c/shared/input-control';
//  */

// import feathersClient from 'scr/getFeathers';

/*
 * import { signupRequest, simpleActionCreator } from '@r/actions';
 * import { constants } from '@r/actions/constants';
 */

/*
 * const {
 *   SET_EMAIL,
 *   SET_PASSWORD,
 *   SET_FULL_NAME,
 *   SET_CONFIRM_PASSWORD
 * } = constants;
 */

// class SignupForm extends PureComponent {

//   signupRequestInner = ( /** @type { React.FormEvent } */ event ) => {
//     event.preventDefault();
//     this.props.signupRequest( feathersClient );
//   };

//   render() {
//     /*
//      * const {
//      *   signUpError, email, password, changeEmail, changePassword,
//      *   changeFullName, error, fullName, changeConfirmPassword, confirmPassword
//      * } = this.props;
//      */

//     return (
//       <form onSubmit={ this.signupRequestInner }>
//         {/* { signUpError && <p className='signUpError'>{ signUpError }</p> } */}
//         <div className='btns'>
//           <Button
//             type='submit'
//             onClick={ this.signupRequestInner }
//             text='Sign up'
//           />
//         </div>
//       </form>
//     );
//   }
// }

// export default connect(
//   ( /** @type { redux.store } */state ) => ( {
//     error: state.error,
//     email: state.email,
//     fullName: state.fullName,
//     password: state.password,
//     signUpError: state.signUpError,
//     confirmPassword: state.confirmPassword
//   } ),
//   {
//     signupRequest,
//     changeEmail: simpleActionCreator( SET_EMAIL ),
//     changePassword: simpleActionCreator( SET_PASSWORD ),
//     changeFullName: simpleActionCreator( SET_FULL_NAME ),
//     changeConfirmPassword: simpleActionCreator( SET_CONFIRM_PASSWORD )
//   }
// )( SignupForm );


// // {/*<FormGroup> */}
// //          // {/* <InputControl */}
// //            value={ email }
// //            error={ !!error }
// //            rowLabel
// //            errorText={ error }
// //            change={ ( /** @type { any } */value ) => changeEmail( value ) }
// //            placeholder='E-mail'
// //            label='E-mail'
// //            type='text'
// //          />
// //        // {/* </FormGroup> */}
// //        <FormGroup>
// //          // {/* <InputControl */}
// //            value={ fullName }
// //            error={ !!error }
// //            errorText={ error }
// //            rowLabel
// //            change={ ( /** @type { any } */value ) => changeFullName( value ) }
// //            placeholder='Full name'
// //            label='Full name'
// //            type='test'
// //          />
// //        // {/* </FormGroup> */}
// //        <FormGroup>
// //          // {/* <InputControl */}
// //            value={ password }
// //            error={ !!error }
// //            errorText={ error }
// //            rowLabel
// //            change={ ( /** @type { any } */value ) => changePassword( value ) }
// //            placeholder='Password'
// //            label='Password'
// //            type='password'
// //          />
// //        // {/* </FormGroup> */}
// //        <FormGroup className='last-group'>
// //          // {/* <InputControl */}
// //            value={ confirmPassword }
// //            error={ !!error }
// //            errorText={ error }
// //            rowLabel
// //            change={ ( /** @type { any } */value ) => changeConfirmPassword( value ) }
// //            placeholder='Confirm password'
// //            label='Confirm password'
// //            type='password'
// //          />
// //        // {/* </FormGroup> */}
// //

interface _IProps {
  login: redux._IStore['user']['login'];
  password: redux._IStore['user']['password'];
  confirmPassword: redux._IStore['user']['confirmPassword'];
  fullName: redux._IStore['user']['fullName'];
  error: redux._IStore['user']['signupError'];
  dispatch: ThunkDispatch<
    redux._IStore,
    {},
    | redux._IActions['setUserLogin']
    | redux._IActions['setUserPassword']
    | redux._IActions['setUserConfirmPassword']
    | redux._IActions['setUserFullName']
    | redux._IActions['setUserSignupError']
  >;
}

export default connect(
  ( { user }: redux._IStore ) => ( {
    error: user.signupError,
    login: user.login,
    password: user.password,
    confirmPassword: user.confirmPassword,
    fullName: user.fullName
  } )
)(
  class SignupModal extends PureComponent<_IProps> {
    setLogin = ( login: string ) => {
      this.props.dispatch( {
        type: 'SET_USER_LOGIN',
        value: login as redux._IStore['user']['login']
      } );
      this.props.dispatch( { type: 'SET_USER_SIGNUP_ERROR', value: null } );
    }

    setPassword = ( password: string ) => this.props.dispatch( {
      type: 'SET_USER_PASSWORD',
      value: password as redux._IStore['user']['password']
    } );

    setConfirmPassword = ( confirmPassword: string ) => this.props.dispatch( {
      type: 'SET_USER_CONFIRM_PASSWORD',
      value: confirmPassword as redux._IStore['user']['confirmPassword']
    } );

    setFullName = ( fullName: string ) => this.props.dispatch( {
      type: 'SET_USER_FULL_NAME',
      value: fullName as redux._IStore['user']['fullName']
    } );

    signupRequestInner = ( event: React.FormEvent ) => {
      event.preventDefault();

      if( isValidEmail( this.props.login ) ) {
        this.props.dispatch( signupRequest( feathersClient ) );
      } else {
        this.props.dispatch( { type: 'SET_USER_SIGNUP_ERROR', value: 'Invalid email' } );
      }
    };

    render() {
      const {
        props: {
          login, password, error,
          confirmPassword, fullName
        },
        setLogin,
        setPassword,
        setConfirmPassword,
        setFullName
      } = this;


      return (
        <form onSubmit={ this.signupRequestInner } className={ `${ styl.loginModal } form` }>
          <div className='inputs'>
            <LabeledField
              name='Email'
              className={ modalStyl.commonLabeledField }
            >
              <SimpleInput
                className={ modalStyl.commonInput }
                value={ login }
                onChange={ setLogin }
              />
            </LabeledField>

            <LabeledField
              name='Password'
              className={ modalStyl.commonLabeledField }
            >
              <SimpleInput
                className={ modalStyl.commonInput }
                value={ password }
                onChange={ setPassword }
                type='password'
              />
            </LabeledField>

            <LabeledField
              name='Confirm password'
              className={ modalStyl.commonLabeledField }
            >
              <SimpleInput
                className={ modalStyl.commonInput }
                value={ confirmPassword }
                onChange={ setConfirmPassword }
                type='password'
              />
            </LabeledField>

            <LabeledField
              name='Full Name'
              className={ modalStyl.commonLabeledField }
            >
              <SimpleInput
                className={ modalStyl.commonInput }
                value={ fullName }
                onChange={ setFullName }
              />
            </LabeledField>

            { error && <p className='signUpError'>{ error }</p> }
          </div>

          <div className='btns'>
            <Button type='submit' text='Sign Up' />
          </div>

        </form>
      );
    }
  }
);
