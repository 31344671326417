import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { label } from './label';
import { value } from './value';

const markupComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['settings']['materials']['markup'],
    AllActions
  >;

export const markup = markupComb( {
  label,
  value
} );
