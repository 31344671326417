import { simpleSet } from '@r/reducers/utils';
import { inches } from '@npmrost/utils';

const setCalculationModeAdminSettingsToeKickHeightValue = simpleSet as
  redux.reducers.calculationMode.adminSettings.projectSettings.dimensions.toeKickHeight.value;

export const value = setCalculationModeAdminSettingsToeKickHeightValue(
  'SET_CALCULATION_MODE_ADMIN_SETTINGS_TOE_KICK_HEIGHT_VALUE',
  0 as inches
);
