// @ts-check
import {
  LineBasicMaterial, Group, Vector3, Texture, MeshBasicMaterial, LinearFilter, DoubleSide,
  PlaneGeometry, Mesh, DirectionalLight, LinearMipmapLinearFilter
} from 'three';
import DimensionHelper from './DimensionHelper';

export default class Dimension extends Group {
    isFloorplanDimension = true;

    isTopLevel = true;

  dimensionHelper: DimensionHelper;

  labelMesh: Mesh;

  value: number;

  direction: Vector3;

  origin: Vector3;

  rotatedNumber: boolean;

  constructor( origin: Vector3, direction: Vector3, value: number, rotatedNumber: boolean ) {
    super();
    this.origin = origin;
    this.direction = direction;
    this.value = value;
    this.rotatedNumber = rotatedNumber;

    this.dimensionHelper = new DimensionHelper(
      direction.clone().normalize(),
      origin,
      value,
      new LineBasicMaterial( {
        color: 0x000000
      } )
    );
    this.add( this.dimensionHelper );
    this.update( value );

  }

  update ( value: number ) {
    this.value = value;
    if ( this.labelMesh && this.labelMesh.geometry ) {
      this.labelMesh.geometry.dispose();
    }

    this.dimensionHelper.setLength( value );
    const labelCanvas = document.createElement( 'canvas' );
    const context = labelCanvas.getContext( '2d' );
    if ( context === null ) {
      throw new Error( 'Context is null.' );
    }

    context.font = 'bold 90px Times';
    const labelWidth = context.measureText( value.toString() ).width;
    labelCanvas.width = labelWidth;

    // 25 to account for g, p, etc.
    labelCanvas.height = 250;
    context.font = 'bold 90px Times';
    context.fillText( value.toString(), 0, labelWidth );

    let labelTexture = new Texture( labelCanvas );
    labelTexture.magFilter = LinearFilter;
    labelTexture.minFilter = LinearFilter;
    labelTexture.needsUpdate = true;
    let material = new MeshBasicMaterial( {
      map: labelTexture,
      side: DoubleSide,
      transparent: true
    } );

    const labelPlane = new PlaneGeometry( 15 * labelWidth / 250, 15 );
    if ( this.labelMesh ) {
      this.labelMesh.geometry = labelPlane;
      this.labelMesh.material = material;
    }else {
      this.labelMesh = new Mesh( labelPlane, material );
      this.labelMesh.isRaycastedInFloorplanMode = true;
      this.labelMesh.isFloorplanDimension = true;
      this.add( this.labelMesh );
    }

    this.labelMesh.rotation.set( this.rotatedNumber ? Math.PI : 0, this.rotatedNumber ? Math.PI : 0, 0 );
    this.labelMesh.position.set( value / 2 * this.direction.x, this.rotatedNumber ? -15 * labelWidth / 250 + 5 : 15 * labelWidth / 250 - 5, 0 );
  }

  setMaterialToSelected () {
    // here will be UI menu handling
  }

  setMaterialToUnselected () {
    // here will be UI menu handling
  }

}

