import { inches } from '@npmrost/utils/lib';

// (\d+\.\d+|\d+)
// $1 as inches

export const FinishPanel:
  Partial<CabD.A.ContainerConfigs.ContainerConfig> = {
    height: 36 as inches,
    depth: 24 as inches,
    width: 0.75 as inches,
    name: 'Finish Panel',
    layout: 'WIDTH',
    isTopLevel: true,
    children: [
      {
        name: 'FinishPanel',
        type: 'Panel',
        panelParent: 'door'
      }
    ],
    countertopMaterialName: 'marblewhite005'
  };
