import { combineReducers } from 'redux';
import { combRed, AllActions } from '@r/storeUtils';
import { label } from './label';
import { value } from './value';

const topDrawerHeightComb = combineReducers as
  combRed<
    redux._IStore['calculationMode']['adminSettings']['projectSettings']['dimensions']['topDrawerHeight'],
    AllActions
  >;

export const topDrawerHeight = topDrawerHeightComb( {
  label,
  value
} );
