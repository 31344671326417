/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import styled from 'styled-components';
import {
  LabeledField
} from '@npmrost/storybook/stories/Vesta/LabeledField/LabeledField';
import { Triplet } from '@npmrost/storybook/stories/Vesta/Triplet';
import Base from './Base';
import Tall from './Tall';
import Upper from './Upper';

const Wrapper = styled.div`
  padding-right: 30px;
  & > * {
    margin-bottom: 15px;
  }
`;

const StyledLabeledField = styled( LabeledField )`
  white-space: nowrap;
`;

interface _IProps {
  texts: redux._IStore['texts']['calculationMode'];
  settings: redux._IStore['calculationMode']['settings']['dimensions'];
  units: redux._IStore['calculationMode']['settings']['information']['units'];
  currentProject: boolean;
  adminMode: boolean;
}

class Cabinets extends PureComponent<_IProps> {
  render() {
    const {
      props: {
        texts: {
          dimensions: {
            height: heightText,
            depth: depthText,
            shelvesQty: shelvesQtyText
          }
        },
        settings,
        units,
        currentProject,
        adminMode
      }
    } = this;

    return (
      <Wrapper>
        <StyledLabeledField name=''>
          {/* <Triplet triple={ [heightText, depthText, shelvesQtyText] } /> */}
          <Triplet triple={ [heightText, depthText, ''] } />
        </StyledLabeledField>
        <Base { ...{
          settings, units, currentProject, adminMode
        } }
        />
        <Tall { ...{
          settings, units, currentProject, adminMode
        } }
        />
        <Upper { ...{
          settings, units, currentProject, adminMode
        } }
        />
      </Wrapper>
    );
  }
}

export default connect(
  ( s: redux._IStore ): Omit<
    _IProps,
    'settings' | 'units' | 'currentProject' | 'dispatch' | 'adminMode'
  > => {
    return {
      texts: s.texts.calculationMode
    };
  }
)( Cabinets );
