import {
  Group, Vector3, Matrix4, Quaternion
} from 'three';
import { Mesh } from 'three/src/objects/Mesh';
import { BoxGeometry } from 'three/src/geometries/Geometries';
import Storage from 'scr/utilitiesStorage';
import { parseAttributes, createObjectFromConfig, calculateValue } from './helpers';
import Panel from './Panel';
import Countertop from './Countertop';
import Part from './Part';
import { getTopLevelObject } from '../helpers/object3d';
/* const defaultSizes = { width: 20, height: 20, depth: 20 };


type mountTypes = 'wall' | 'floor';

export interface _ISnapable {
  isSnapable : boolean;
  snap() : void;
  unSnap(): void;
}

export interface _ISizable {
  isSizable : boolean;
 getSizes() : {width : number, height : number, depth : number}
 changeWidth(width : number): void;
 changeWidth(width : number): void;
 changeWidth(width : number): void;
}

export interface _ICollidable {
  isCollidable : boolean;
}

export interface _IDragable {
  isDragable : boolean;
  mountTypes : mountTypes[];
  addTo(obj : _IDropable) {};
}

export interface _IDropable {
  isDropable : boolean;
  mountSlotTypes : mountTypes[];
}

export interface _ISceneObject {
 sceneObject : Group;
}
*/

/* const shape4: Vector4[] = [
  new Vector4( new Vector2( 0, 0 ), new Vector2( 0, 0 ) ),
  new Vector4( new Vector2( 1, 0 ), new Vector2( 0, 0 ) ),
  new Vector4( new Vector2( 1, 1 ), new Vector2( 0, 0 ) ),
  new Vector4( new Vector2( 0, 1 ), new Vector2( 0, 0 ) )
];
console.log( shape4 );
window.addEventListener( 'keydown', ( event ) => {
  if ( event.keyCode === 49 ) {
    const item = new Item( 100, 50, 20 );
    item.add( new Panel( shape4, new Vector6( new Vector3( 0, 0, 0 ), new Vector3( 0, 0, 0 ) ), new Vector3( 20, 10, 5 ) ) );
    window.viewer.scene.add( item );

  }
} ); */

export default class Item extends Group {
  size: Vector3 = new Vector3( 1, 1, 1 );

  components: ( Panel| Countertop | Part )[] = [];

  isCabinet = true;

  constructor( config ) {
    super();
    this.config = config;
    this.fromJSON( config );
    this.rebuildGeometry();

    // OLD APPROACH COMPATIBILITY/////////////////////////////////////

    this.mountTypes = ['floor'];
    this._isCabinet = true;
    this.isTopLevel = true;
    this.initialRotation = Math.PI / 2;
    this._isSingletonCabinet = true;
    this.rotation.x = Math.PI / 2;
    this.getType = () => {
      return 'base';
    };
    this.vestaObject.getType = () => {
      return 'cabinet';
    };
    this.getSizes = () => {
      return {
        width: this.size.x,
        height: this.size.z,
        depth: this.size.y
      };
    };
    this.vestaObject.getSizes = () => {
      return {
        width: this.size.x,
        height: this.size.y,
        depth: this.size.z
      };
    };
    this.getConfig = () => {
      return {};
    };

    this.fitByWidthToSpace = () => {

    };

    this.fitByWidthToRight = () => {

    };

    this.fitByWidthToLeft = () => {

    };

    this.centerByWidthToSpace = () => {

    };

    this.vestaObject.setMaterialByName = ( mat ) => {
      const newMaterial = Storage.get( 'namesToMaterials' ).cabinet[
        mat.cabinet || mat.door || ''
      ];

      if ( !newMaterial ) {
        return;
      }

      for ( let i = 0; i < Storage.get( 'materials' ).cabinet.length; i += 1 ) {
        Storage.get( 'materials' ).cabinet[ i ].material.dispose();
        if ( Storage.get( 'materials' ).cabinet[ i ].material.map ) {
          Storage.get( 'materials' ).cabinet[ i ].material.map.dispose();
        }
        if ( Storage.get( 'materials' ).cabinet[ i ].material.roughnessMap ) {
          Storage.get( 'materials' ).cabinet[ i ].material.roughnessMap.dispose();
        }
        if ( Storage.get( 'materials' ).cabinet[ i ].material.normalMap ) {
          Storage.get( 'materials' ).cabinet[ i ].material.normalMap.dispose();
        }

      }

      if ( mat.cabinet ) {
        // Cabinet material set
        this.traverse( ( /** @type { THREE.Object3D } */obj ) => {

          const type = obj.vestaObject && obj.vestaObject.getType();
          if ( type !== 'panel/body' ) {
            return;
          }


          /** @type { THREE.Mesh } */ ( obj ).mesh.material = newMaterial;
        } );

        Storage.get( 'viewer' ).renderOnDemand.set();
        /* this.getParent().updateMaterialInConfig(
          'cabinetMaterialName',
          mat.cabinet
        ); */

        return;
      }

      this.traverse( ( /** @type { THREE.Object3D } */obj ) => {
        const type = obj.vestaObject && obj.vestaObject.getType();
        if ( type !== 'panel/door' ) {
          return;
        }

        /** @type { THREE.Mesh } */ ( obj ).mesh.material = newMaterial;
      } );

      Storage.get( 'viewer' ).renderOnDemand.set();

      /* this.getParent().updateMaterialInConfig(
        'doorMaterialName',
        mat.door || ''
      ); */
    };
    this._doorsAngle = 0;
    this.getDoorsAngle = () => {
      return this._doorsAngle;
    };

    this.openAllDoors = ( angle ) => {
      for ( let i = 0; i < this.components.length; i += 1 ) {
        if ( this.components[ i ].isPanel && this.components[ i ].vestaObject && this.components[ i ].vestaObject.getType() === 'panel/door' ) {
          this.components[ i ].rotation.y = this.components[ i ]._r.y + angle;
          this._doorsAngle = angle;
        }
      }
    };
    this.vestaObject.getDistanceToWallEnds = ( toPoint ) => {
      if ( !this.parent || ( toPoint !== 'center' && toPoint !== 'left' && toPoint !== 'right' ) ) {
        return null;
      }

      const snapping = this.vestaObject.updateSnapping( /** @type { inches } */( 1e6 ) );
      if ( snapping &&
        snapping.plusY &&
        snapping.plusY.object ) {
        const topLevelObject = getTopLevelObject( snapping.plusY.object );
        if ( topLevelObject.isWall &&
          Math.abs( snapping.plusY.distance ) < ( 0.1 + this.getSizes().height / 2 )
        ) {

          let leftCorrectionMergedWalls2D = 0;
          let rightCorrectionMergedWalls2D = 0;
          if ( topLevelObject.relatedWalls2D ) {
            const index = topLevelObject.relatedWalls2D.indexOf( topLevelObject );
            for ( let i = 0; i < index; i += 1 ) {
              leftCorrectionMergedWalls2D += topLevelObject.relatedWalls2D[ i ].width;
            }

            for ( let i = index + 1, l = topLevelObject.relatedWalls2D.length; i < l; i += 1 ) {
              rightCorrectionMergedWalls2D += topLevelObject.relatedWalls2D[ i ].width;
            }
          }

          if ( toPoint === 'center' ) {
            const localPos = this.position.clone()
              .applyMatrix4( this.parent.matrixWorld )
              .applyMatrix4(
                new Matrix4().getInverse( snapping.plusY.object.matrixWorld )
              );

            return ( {
              snapping,
              minusX: localPos.x + leftCorrectionMergedWalls2D,
              plusX: topLevelObject.width - localPos.x + rightCorrectionMergedWalls2D,
              minusY: null,
              plusY: null
            } );
          }
          if ( toPoint === 'left' ) {
            const localPos = ( this.position.clone().add( new Vector3( -this.getSizes().width / 2, 0, 0 ).applyMatrix4( new Matrix4().makeRotationFromEuler( this.rotation ) ) ) )
              .applyMatrix4( this.parent.matrixWorld )
              .applyMatrix4(
                new Matrix4().getInverse( snapping.plusY.object.matrixWorld )
              );

            return ( {
              snapping,
              minusX: localPos.x + leftCorrectionMergedWalls2D,
              plusX: topLevelObject.width - localPos.x + rightCorrectionMergedWalls2D,
              minusY: null,
              plusY: null
            } );
          }
          if ( toPoint === 'right' ) {
            const localPos = ( this.position.clone().add( new Vector3( this.getSizes().width / 2, 0, 0 ).applyMatrix4( new Matrix4().makeRotationFromEuler( this.rotation ) ) ) )
              .applyMatrix4( this.parent.matrixWorld )
              .applyMatrix4(
                new Matrix4().getInverse( snapping.plusY.object.matrixWorld )
              );

            return ( {
              snapping,
              minusX: localPos.x + leftCorrectionMergedWalls2D,
              plusX: topLevelObject.width - localPos.x + rightCorrectionMergedWalls2D,
              minusY: null,
              plusY: null
            } );
          }
        }

        return ( {
          minusX: null,
          plusX: null,
          minusY: null,
          plusY: null
        } );

      }

      return ( {
        minusX: null,
        plusX: null,
        minusY: null,
        plusY: null
      } );


    };

    this.vestaObject.setDistanceToWallEnd = ( side, toPoint, distance ) => {
      const distances = this.vestaObject.getDistanceToWallEnds( 'center' );
      if ( !this.parent || ( toPoint !== 'center' && toPoint !== 'left' && toPoint !== 'right' ) ) {
        return null;
      }

      if ( ( side !== 'left' && side !== 'right' ) ||
        distance < 0 ||
        !this.parent ||
        ( !distances.minusX || !distances.plusX ) ) {
        return;
      }

      const topLevelObject = getTopLevelObject( distances.snapping.plusY.object );
      if ( side === 'left' ) {
        let localPos = this.position.clone()
          .applyMatrix4( this.parent.matrixWorld ).applyMatrix4(
            new Matrix4()
              .getInverse( distances.snapping.plusY.object.matrixWorld )
          );
        if ( toPoint === 'center' ) {
          localPos.x += distance - distances.minusX;
        }
        if ( toPoint === 'left' ) {
          localPos.x += distance - distances.minusX + this.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          localPos.x += distance - distances.minusX - this.getSizes().width / 2;
        }

        this.position.copy( localPos.clone()
          .applyMatrix4( distances.snapping.plusY.object.matrixWorld )
          .applyMatrix4( new Matrix4()
            .getInverse( this.parent.matrixWorld ) ) );
      }

      if ( side === 'right' ) {
        let localPos = this.position.clone()
          .applyMatrix4( this.parent.matrixWorld ).applyMatrix4(
            new Matrix4()
              .getInverse( distances.snapping.plusY.object.matrixWorld )
          );
        if ( toPoint === 'center' ) {
          localPos.x += -distance + distances.plusX;
        }
        if ( toPoint === 'left' ) {
          localPos.x += -distance + distances.plusX + this.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          localPos.x += -distance + distances.plusX - this.getSizes().width / 2;
        }

        this.position.copy( localPos.clone()
          .applyMatrix4( distances.snapping.plusY.object.matrixWorld )
          .applyMatrix4( new Matrix4()
            .getInverse( this.parent.matrixWorld ) ) );
      }
    };

    this.vestaObject.getDistanceToWalls = ( toPoint ) => {
      if ( ['center', 'edge'].indexOf( toPoint ) === -1 ) {
        return;
      }

      const result = {};
      const intersectionObjects = [];
      Storage.get( 'viewer' ).scene.traverseVisible( ( obj ) => {
        if ( obj.isWall ) {
          intersectionObjects.push( obj.mesh );
        }
      } );

      const snapping = this.vestaObject.updateSnapping( /** @type { inches } */( 1e6 ), intersectionObjects );
      result.snapping = snapping;
      if ( snapping ) {
        if ( snapping.plusX &&
          snapping.plusX.object ) {
          const topLevelObject = getTopLevelObject( snapping.plusX.object );
          if ( topLevelObject.isWall ) {
            result.plusX = Math.abs( snapping.plusX.distance ) - this.getSizes().width / 2;
          }
        }
        if ( snapping.minusX &&
          snapping.minusX.object ) {
          const topLevelObject = getTopLevelObject( snapping.minusX.object );
          if ( topLevelObject.isWall ) {
            result.minusX = Math.abs( snapping.minusX.distance ) - this.getSizes().width / 2;
          }
        }
        if ( snapping.plusY &&
          snapping.plusY.object ) {
          const topLevelObject = getTopLevelObject( snapping.plusY.object );
          if ( topLevelObject.isWall ) {
            result.plusY = Math.abs( snapping.plusY.distance ) - this.getSizes().height / 2;
          }
        }
        if ( snapping.minusY &&
          snapping.minusY.object ) {
          const topLevelObject = getTopLevelObject( snapping.minusY.object );
          if ( topLevelObject.isWall ) {
            result.minusY = Math.abs( snapping.minusY.distance ) - this.getSizes().height / 2;
          }
        }
      }

      if ( toPoint === 'center' ) {
        result.plusX += this.getSizes().width / 2;
        result.minusX += this.getSizes().width / 2;
        result.plusY += this.getSizes().height / 2;
        result.minusY += this.getSizes().height / 2;
      }

      return result;
    };

    this.vestaObject.setDistanceToWall = ( side, toPoint, distance ) => {
      const distances = this.vestaObject.getDistanceToWalls( 'edge' );

      if ( ['left', 'right', 'top', 'bottom'].indexOf( side ) === -1 ||
        distance < 0 ||
        !this.parent ||
        ( !distances.minusX || !distances.plusX || !distances.minusY || !distances.plusY ) ) {

        return;

      }

      if ( !this.parent || (
        toPoint !== 'center' &&
          toPoint !== 'left' &&
          toPoint !== 'right' &&
          toPoint !== 'top' &&
          toPoint !== 'bottom'
      ) ) {
        return null;
      }

      let localPos = this.position.clone()
        .applyMatrix4( this.parent.matrixWorld ).applyMatrix4(
          new Matrix4()
            .getInverse( this.matrixWorld )
        );

      if ( side === 'left' ) {
        if ( toPoint === 'left' ) {
          localPos.x += distance - distances.minusX;
        }
        if ( toPoint === 'center' ) {
          localPos.x += distance - distances.minusX - this.vestaObject.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          localPos.x += distance - distances.minusX - this.vestaObject.getSizes().width;
        }
      }
      if ( side === 'right' ) {
        if ( toPoint === 'left' ) {
          localPos.x += -distance + distances.plusX + this.vestaObject.getSizes().width;
        }
        if ( toPoint === 'center' ) {
          localPos.x += -distance + distances.plusX + this.vestaObject.getSizes().width / 2;
        }
        if ( toPoint === 'right' ) {
          localPos.x += -distance + distances.plusX;
        }
      }
      if ( side === 'top' ) {
        if ( toPoint === 'bottom' ) {
          localPos.z += -distance + distances.minusY + this.vestaObject.getSizes().depth;
        }
        if ( toPoint === 'center' ) {
          localPos.z += -distance + distances.minusY + this.vestaObject.getSizes().depth / 2;
        }
        if ( toPoint === 'top' ) {
          localPos.z += -distance + distances.minusY;
        }
      }
      if ( side === 'bottom' ) {
        if ( toPoint === 'bottom' ) {
          localPos.z += distance - distances.plusY;
        }
        if ( toPoint === 'center' ) {
          localPos.z += distance - distances.plusY - this.vestaObject.getSizes().depth / 2;
        }
        if ( toPoint === 'top' ) {
          localPos.z += distance - distances.plusY - this.vestaObject.getSizes().depth;
        }
      }

      this.position.copy( localPos.clone()
        .applyMatrix4( this.matrixWorld )
        .applyMatrix4( new Matrix4()
          .getInverse( this.parent.matrixWorld ) ) );

    };
    // ////////////////////////////////////////////////////////////////
  }

  fromJSON( config ) {
    this.mesh = new Mesh( new BoxGeometry( config.width, config.height, config.depth ), Storage.get( 'materials' ).service[ 0 ].material );
    this.add( this.mesh );
    this.mesh.position.set( 0, config.height / 2, 0 );
    this.mesh.isCollidable = true;
    this.mesh.isSnapable = true;

    this._attributes = Reflect.apply( parseAttributes, this, [config.attributes] );
    this.setName( config.name );
    this.setSize( config.width, config.height, config.depth );

    for ( let i = 0; i < config.children.length; i += 1 ) {
      this.addComponent( createObjectFromConfig( config.children[ i ] ) );
    }
  }

  getAttributeValue( attributeName: string ) {
    return Reflect.apply( calculateValue, this, [this._attributes[ attributeName ]] );
  }

  setAttributeValue( attributeName: string, value ) {
    this._attributes[ attributeName ].value = value;
    this.rebuildGeometry();
  }


  setName( name: string ) {
    this.name = name;
  }

  changeWidth( width: number ) {
    this.size.x = width;
    this.rebuildGeometry();
  }

  changeHeight( height: number ) {
    this.size.y = height;
    this.rebuildGeometry();
  }

  changeDepth( depth: number ) {
    this.size.z = depth;
    this.rebuildGeometry();
  }

  setSize( width: number, height: number, depth: number ) {
    this.size.set( width, height, depth );
    this.rebuildGeometry();
  }

  getSize () {
    return this.size.clone();
  }

  addComponent( component: Panel | Countertop | Part ) {
    if ( component.isCountertop ) {
      component.cabinet = this;
      this.countertop = component;
    }

    this.components.push( component );
    this.add( component );
  }

  removeComponent() {

  }

  rebuildGeometry() {
    for ( let i = 0; i < this.components.length; i += 1 ) {
      this.components[ i ].rebuildGeometry();
    }
  }
}
