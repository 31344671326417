import { simpleSet } from '@r/reducers/utils';

export const percentageLoss = simpleSet(
  'SET_CALCULATION_MODE_ADMIN_SETTINGS_PERCENTAGE_LOSS',
  {
    data: [],
    counter: 0,
    keys: []
  }
);

export function addItem() {

  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        adminSettings: {
          projectSettings: {
            percentageLoss: {
              data,
              counter,
              keys
            }
          }
        }
      }
    } = getStore();

    const newCounter = counter + 1;
    const newKeys = [...keys, newCounter];
    const newData = [...data, { name: '', value: { label: '', value: 0 } }];

    dispatch( {
      type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_PERCENTAGE_LOSS',
      value: {
        data: newData,
        counter: newCounter,
        keys: newKeys
      }
    } );
  };
}

export function updateItem( key, propName, value ) {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        adminSettings: {
          projectSettings: {
            percentageLoss: {
              data,
              counter,
              keys
            }
          }
        }
      }
    } = getStore();

    const keyIndex = keys.indexOf( key );

    if ( keyIndex === -1 ) return;

    const newData = data.slice();

    if ( propName === 'name' ) {
      newData[ keyIndex ][ propName ] = value;
    } else {
      newData[ keyIndex ][ propName ] = {
        label: value, value: Number( value )
      };
    }

    dispatch( {
      type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_PERCENTAGE_LOSS',
      value: {
        data: newData,
        counter,
        keys
      }
    } );
  };
}

export function removeItem( key ) {
  return ( dispatch, getStore ) => {
    const {
      calculationMode: {
        adminSettings: {
          projectSettings: {
            percentageLoss: {
              data,
              counter,
              keys
            }
          }
        }
      }
    } = getStore();

    const keyIndex = keys.indexOf( key );

    if ( keyIndex === -1 ) return;

    const newData = data.slice();
    const newKeys = keys.slice();

    newData.splice( keyIndex, 1 );
    newKeys.splice( keyIndex, 1 );

    dispatch( {
      type: 'SET_CALCULATION_MODE_ADMIN_SETTINGS_PERCENTAGE_LOSS',
      value: {
        data: newData,
        counter,
        keys: newKeys
      }
    } );
  };
}
