// @ts-check
import {
  Group, Mesh, Shape, ShapeGeometry
} from 'three';
import { createGeometry } from 'c/ThreeJsWrap/Viewer/core/helpers/geometry';
import Storage from 'scr/utilitiesStorage';
import MountPlane from './MountPlane';

export default class Wall extends Group {
  constructor( name, shape, materialName ) {
    const shape_ = new Shape();
    let minX = shape[ 0 ].x;
    let maxX = shape[ 0 ].x;
    let minY = shape[ 0 ].y;
    let maxY = shape[ 0 ].y;

    shape_.moveTo( shape[ 0 ].x, shape[ 0 ].y );
    for ( let i = 1; i < shape.length; i += 1 ) {
      shape_.lineTo( shape[ i ].x, shape[ i ].y );
      if ( shape[ i ].x < minX ) {
        minX = shape[ i ].x;
      }
      if ( shape[ i ].y < minY ) {
        minY = shape[ i ].y;
      }
      if ( shape[ i ].x > maxX ) {
        maxX = shape[ i ].x;
      }
      if ( shape[ i ].y > maxY ) {
        maxY = shape[ i ].y;
      }
    }

    shape_.lineTo( shape[ 0 ].x, shape[ 0 ].y );

    super();
    this.width = maxX - minX;
    this.height = maxY - minY;
    this.isWall = true;
    let mesh;
    if ( materialName === 'invisible' ) {
      mesh = new Mesh(
        createGeometry( new ShapeGeometry( shape_ ) ),
        Storage.get( 'namesToMaterials' ).service[ materialName ]
      );
    } else {
      mesh = new Mesh(
        createGeometry( new ShapeGeometry( shape_ ) ),
        Storage.get( 'namesToMaterials' ).wall[ materialName ]
      );
      this.mountPlane = new MountPlane( 'wall', shape_ );
      this.add( this.mountPlane );
    }

    mesh.isCollidable = true;
    mesh.isSnapable = true;
    mesh.name = name;
    // Mesh.castShadow = true;
    mesh.receiveShadow = true;

    this.add( mesh );
    this.mesh = mesh;
    this.name = name;


    this.vestaObject.setMaterialByName = ( mat ) => {
      const viewer = Storage.get( 'viewer' );
      const newMaterial = Storage.get( 'namesToMaterials' ).wall[ mat.wall ];
      if ( !newMaterial ) {
        return;
      }

      for ( let i = 0; i < Storage.get( 'materials' ).wall.length; i += 1 ) {
        Storage.get( 'materials' ).wall[ i ].material.dispose();
        if ( Storage.get( 'materials' ).wall[ i ].material.map ) {
          Storage.get( 'materials' ).wall[ i ].material.map.dispose();
        }
        if ( Storage.get( 'materials' ).wall[ i ].material.roughnessMap ) {
          Storage.get( 'materials' ).wall[ i ].material.roughnessMap.dispose();
        }
        if ( Storage.get( 'materials' ).wall[ i ].material.normalMap ) {
          Storage.get( 'materials' ).wall[ i ].material.normalMap.dispose();
        }

      }

      this.mesh.material = newMaterial;
      viewer.renderOnDemand.set();
      viewer.cubeCamera.flag = true;

      this.vestaObject.setMaterialByNameDirty( mat.wall );
    };

    this.vestaObject.setMaterialByNameDirty( materialName );
    this.vestaObject.setType( 'wall' );
  }
}
